import { Box, Flex, IconProps, Radio, Text } from '@chakra-ui/react';
import { WALLETS_TABS } from 'services/user/modules/currencies';

type Item = {
  name: string;
  isInstant?: boolean;
  iconContent?: React.ReactNode;
  Icon: React.FunctionComponent<IconProps>;
};

type Props = {
  children: React.ReactNode;
  list: Item[];
  active: number;
  onSelect: (index: number) => void;
};

export const CurrenciesList: React.FC<Props> = ({
  children,
  onSelect,
  active,
  list = WALLETS_TABS,
}) => (
  <Flex direction="column">
    {list.map((item: Item, index) => {
      const isActive = index === active;
      const borderGray = '1px solid var(--chakra-colors-truffle-border)';
      return (
        <Box
          key={item.name}
          overflow="hidden"
          _hover={{
            bg: isActive ? undefined : 'sugar-dust',
          }}
          bg={isActive ? 'sugar-dust' : 'none'}
        >
          <Flex
            justifyContent="space-between"
            align="center"
            px="5"
            {...(isActive
              ? {
                  color: 'vanilla-text',
                  borderLeft: '1px solid',
                  borderColor: 'sherbet-purple',
                  paddingLeft: '19px',
                }
              : {})}
            h="40px"
            cursor="pointer"
            onClick={() => onSelect(index)}
          >
            <Flex align="center" gap="2">
              {item.iconContent ||
                (item.Icon && <item.Icon w="14px" h="14px" />)}
              <Text fontWeight="500">{item.name}</Text>
            </Flex>
            <Flex gap="2" align="center">
              <Radio isChecked={isActive} />
            </Flex>
          </Flex>
          {isActive && (
            <Box
              borderBottom={borderGray}
              borderTop="1px solid"
              borderColor="truffle-border"
              overflow="hidden"
            >
              {children}
            </Box>
          )}
        </Box>
      );
    })}
  </Flex>
);
