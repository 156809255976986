import { produce } from 'immer';

type ReducersType<T> = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: (state: T, action: any) => void;
};

export function createReducer<T>(initialState: T, reducers: ReducersType<T>) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return (state: T, action: any) => {
    if (!state) {
      return initialState;
    }

    if (reducers[action.type]) {
      let newState;
      const result = produce(state, draftState => {
        newState = reducers[action.type](draftState as T, action);
      });

      if (!newState) {
        newState = result;
      }

      return newState;
    }

    return state;
  };
}
