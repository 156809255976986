import { useCallback, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import {
  getUserIsAuthorized,
  getUserIsLoaded,
} from 'services/user/modules/user';
import {
  AUTHORIZED_REQUIRED_MODALS,
  PAGE_MODALS,
  UNAUTHORIZED_REQUIRED_MODALS,
} from './constants';

export const useUrlModal = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const isUserLoaded = useSelector(getUserIsLoaded);
  const isAuthorized = useSelector(getUserIsAuthorized);

  const onClose = useCallback(() => {
    setSearchParams('');
  }, [setSearchParams]);

  const { modal } = Object.fromEntries(searchParams);

  useEffect(() => {
    if (
      (modal && !PAGE_MODALS.includes(modal)) ||
      (isAuthorized && UNAUTHORIZED_REQUIRED_MODALS.includes(modal))
    ) {
      setSearchParams('');
    }
  }, [isAuthorized, modal, setSearchParams]);

  useEffect(() => {
    if (
      isUserLoaded &&
      !isAuthorized &&
      AUTHORIZED_REQUIRED_MODALS.includes(modal)
    ) {
      window.sessionStorage?.setItem?.(
        'returlUrl',
        window.location.pathname + window.location.search,
      );
      setSearchParams('?modal=auth');
    }
  }, [isUserLoaded, isAuthorized, modal, setSearchParams]);

  return useMemo(() => {
    const { modal: _, ...rest } = Object.fromEntries(searchParams);

    if (!modal || !PAGE_MODALS.includes(modal)) {
      return null;
    }

    if (isAuthorized && UNAUTHORIZED_REQUIRED_MODALS.includes(modal)) {
      return null;
    }

    if (!isAuthorized && AUTHORIZED_REQUIRED_MODALS.includes(modal)) {
      return null;
    }

    return {
      type: modal,
      contentProps: rest,
      onClose,
    };
  }, [isAuthorized, modal, searchParams, onClose]);
};
