import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { CountdownExpire } from 'components/countdown-expire';
import { generateUrl } from 'helpers/image';
import { useToastError } from 'hooks/use-toast-error';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { getSlotsGamesData } from 'services/games/modules/slots';
import { startFreeSpin } from 'services/user/modules/user';
import { useActions } from 'store';
import { NotificationTip } from 'uikit/notification-tip';

export const FreeSpinCard = ({
  games: game,
  id,
  status,
  quantity,
  allocationExpiryDate,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const games = useSelector(getSlotsGamesData);
  const actions = useActions({ startFreeSpin });
  const navigate = useNavigate();
  const toastErrorCommon = useToastError();

  const gameData = games[game];
  const gameName = gameData?.title;
  const imageUrl = gameData ? generateUrl(gameData.image, { w: 300 }) : '';

  const onStart = async () => {
    try {
      setIsLoading(true);
      await actions.startFreeSpin(id);
      navigate(`/casino/game/${game}`);
    } catch (errors) {
      toastErrorCommon(errors);
    }
    setIsLoading(false);
  };

  const endTime = allocationExpiryDate ? allocationExpiryDate * 1000 : null;

  return (
    <Flex flexDirection="column">
      <Flex flexDirection="column">
        <Box
          bg={`url(${imageUrl})`}
          bgPos="center"
          bgColor="sugar-dust"
          bgSize="cover"
          rounded="xl"
          w="full"
          h="32"
          pos="relative"
          mb="2"
        >
          <Flex
            zIndex="2"
            pointerEvents="none"
            position="absolute"
            bottom="0"
            left="0"
            w="full"
            h="full"
            align="start"
            flexDirection="column"
            justifyContent="flex-end"
            rounded="xl"
            bg="linear-gradient(to top, rgb(63, 81, 128) 0%, transparent 85%)"
          />

          <NotificationTip
            notifications="Free Spins"
            bg="rgb(0, 131, 138)"
            position="absolute"
            top="3"
            color="vanilla-text"
            left="3"
          />
        </Box>
        <Flex align="center" justifyContent="space-between" mb="1">
          <Text color="candy-floss-text">
            {t('bonus.expire-in')} <CountdownExpire endTime={endTime} />
          </Text>
        </Flex>
        <Text mb="1" fontWeight="500">
          {quantity} {t('bonus.free-spins')} on {gameName}
        </Text>
        <Text color="candy-floss-text">{t('bonus.free-spins-desc')}</Text>
      </Flex>
      <Box w="full" mt="3">
        <Flex align="center" gap="2">
          {status === 'created' ? (
            <Button
              colorScheme="purple"
              rounded="full"
              onClick={onStart}
              isLoading={isLoading}
            >
              Claim Now
            </Button>
          ) : (
            <Button
              colorScheme="purple"
              rounded="full"
              onClick={() => navigate(`/casino/game/${game}`)}
            >
              Play Game
            </Button>
          )}
        </Flex>
      </Box>
    </Flex>
  );
};
