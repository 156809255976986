import { Box, Flex, Image, Tooltip } from '@chakra-ui/react';
import { generateUrl } from 'helpers/image';
import { LEVEL_IMAGES, LEVEL_NAMES } from 'services/user/modules/user';
import { BadgeType } from 'types';
import { Link } from 'uikit/link';
import { UserAvatar } from 'uikit/user-avatar';

type Props = {
  avatar?: string;
  username: string;
  nftId?: string;
  badges?: BadgeType[];
  mr?: string;
  level?: number;
};

export const ChatAvatar: React.FC<Props> = ({
  avatar,
  username,
  nftId,
  badges,
  level,
  mr = '1',
}) => {
  let BadgeWrapper: typeof Box | typeof Link = Box;
  let badgeWrapperProps: { to?: string } = {};
  const hasBadge = !!badges && badges?.length > 0;

  if (hasBadge && badges[0]?.name === 'Max') {
    BadgeWrapper = Link;
    badgeWrapperProps = {
      to: '/max',
    };
  }

  return (
    <Flex align="center" gap="1" mr={mr}>
      {avatar && (
        <UserAvatar icon={avatar} name={username} size="xs" nftId={nftId} />
      )}
      {hasBadge && badges[0]?.imageUrl ? (
        <Tooltip
          gutter={10}
          hasArrow
          fontSize="md"
          rounded="md"
          placement="top"
          bg="vanilla-text"
          fontWeight="500"
          py="1"
          px="2"
          color="toffee-base"
          label={badges[0]?.name}
        >
          <BadgeWrapper {...badgeWrapperProps}>
            <Flex
              cursor={badgeWrapperProps.to ? 'pointer' : 'help'}
              rounded="base"
              overflow="hidden"
              h="18px"
              w="18px"
              fontSize="xs"
              fontWeight="800"
              align="center"
              justifyContent="center"
              flexShrink="0"
            >
              <Image
                src={badges[0]?.imageUrl}
                w="full"
                alt={badges[0]?.name}
                draggable="false"
              />
            </Flex>
          </BadgeWrapper>
        </Tooltip>
      ) : (
        <Tooltip
          gutter={10}
          hasArrow
          fontSize="md"
          rounded="md"
          placement="top"
          bg="vanilla-text"
          fontWeight="500"
          py="1"
          px="2"
          color="toffee-base"
          label={LEVEL_NAMES[level as keyof typeof LEVEL_NAMES]}
        >
          <Image
            cursor="help"
            rounded="base"
            flexShrink="0"
            src={generateUrl(LEVEL_IMAGES[level as keyof typeof LEVEL_IMAGES])}
            h="18px"
            w="18px"
            alt={`level-${level}`}
          />
        </Tooltip>
      )}
    </Flex>
  );
};
