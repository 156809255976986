import { Button, Flex } from '@chakra-ui/react';
import { DEFAULT_LANG, GET_IS_TELEGRAM, URL_LANGS } from 'constants/general';
import dayjs from 'dayjs';
import { useIdentify } from 'helpers/heap';
import {
  applyLangToUrl,
  getLangFromPathname,
  getPathnameWithoutLang,
} from 'helpers/lang';
import { logAction } from 'helpers/newrelic';
import { useSupportLazyInit } from 'hooks/use-support';
import { MODAL_TYPE, useModal } from 'modals';
import {
  changeRoute,
  websocketConnect as chatWebsocketConnect,
} from 'modules/chat';
import {
  getHandlerIsBlocked,
  getHandlerIsLoaded,
  getHandlerIsReady,
  getUserCountry,
  loadHandlers,
  loadUserCountry,
} from 'modules/handler';
import { memo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { telegramAuth } from 'services/auth/modules/auth';
import { loadGames } from 'services/games/modules/slots';
import {
  getUserIsAuthorized,
  getUserIsAuthorizedReady,
  getUserIsLoaded,
  loadUser,
  websocketConnect as userWebsocketConnect,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { toastError, toastSuccess } from 'toasts';
import { usePersistState } from '../../hooks/use-persist-state';

const Content = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();

  return (
    <Flex mt="3" gap="3" align="center">
      <Button variant="link" onClick={onClose} opacity="0.75">
        {t('actions.dismiss')}
      </Button>
      <Button
        as="a"
        target="_blank"
        href="https://help.sherbet.com"
        variant="link"
        color="candy-floss-text"
      >
        {t('footer.help-center')}
      </Button>
    </Flex>
  );
};

const extractDynamicStag = (path: string) => {
  const match = path.match(/\/affiliate\/([^/]+)/);
  if (match && match[1]) {
    return match[1];
  }
  return null;
};

export const SideEffects = memo(() => {
  const location = useLocation();
  const isAuthorizedReady = useSelector(getUserIsAuthorizedReady);
  const isAuthorized = useSelector(getUserIsAuthorized);
  const [searchParams, setSearchParams] = useSearchParams();
  const { i18n, t } = useTranslation();
  const isUserLoaded = useSelector(getUserIsLoaded);
  const isHandlerLoaded = useSelector(getHandlerIsLoaded);
  const isHandlerReady = useSelector(getHandlerIsReady);
  const isLoading = !isUserLoaded || !isHandlerLoaded;
  const isBlocked = useSelector(getHandlerIsBlocked);
  const country = useSelector(getUserCountry);

  const navigate = useNavigate();

  const { language, changeLanguage } = i18n;

  const actions = useActions({
    loadHandlers,
    loadUser,
    userWebsocketConnect,
    chatWebsocketConnect,
    changeRoute,
    loadGames,
    loadUserCountry,
    telegramAuth,
  });

  usePersistState();

  useEffect(() => {
    document.getElementById('initialLoader')?.remove?.();
  }, []);

  useEffect(() => {
    let prevTitle: string;
    let interval: ReturnType<typeof setInterval>;
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'hidden') {
        prevTitle = document.title;
        clearInterval(interval);
        const newTitle = 'Sherbet misses you 💔';

        interval = setInterval(() => {
          if (document.title === prevTitle) {
            document.title = newTitle;
          } else if (prevTitle) {
            document.title = prevTitle;
          }
        }, 3000);
        document.title = newTitle;
      } else {
        clearInterval(interval);
        document.title = prevTitle;
      }
    };

    logAction('PAGE_LOADED');

    document.addEventListener('visibilitychange', handleVisibilityChange);

    return () => {
      clearInterval(interval);
      document.removeEventListener('visibilitychange', handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    dayjs.locale(language);
  }, [language]);

  useEffect(() => {
    const langFromUrl = getLangFromPathname(location.pathname);
    if (language !== langFromUrl) {
      if (URL_LANGS.includes(langFromUrl)) {
        changeLanguage(langFromUrl);
      } else if (language !== DEFAULT_LANG) {
        navigate(
          applyLangToUrl(
            `${getPathnameWithoutLang(location.pathname)}${location.search}`,
            language,
          ),
          { replace: true },
        );
      }
    }
  }, [navigate, location.pathname, changeLanguage, language, location.search]);

  useEffect(() => {
    if (GET_IS_TELEGRAM()) {
      if (location.key !== 'default') {
        window.Telegram.WebApp.BackButton.show();
      } else {
        window.Telegram.WebApp.BackButton.hide();
      }
    }
  }, [location.key]);

  useEffect(() => {
    const interval = setInterval(() => {
      actions.loadUserCountry();
    }, 20000);

    return () => clearInterval(interval);
  }, [actions]);

  useEffect(() => {
    if (isAuthorizedReady) {
      actions.userWebsocketConnect();
      actions.chatWebsocketConnect();
    }
  }, [isAuthorizedReady, actions]);

  const {
    onOpen: onOpenSearch,
    modal: searchModal,
    onClose: onCloseSearch,
  } = useModal(MODAL_TYPE.GLOBAL_SEARCH);

  const { onOpen: onOpenTelegramAuth } = useModal(MODAL_TYPE.AUTH_TELEGRAM);

  const handleKeydown = useCallback(
    (event: KeyboardEvent) => {
      if (event.metaKey && event.key === 'k') {
        if (searchModal?.isOpen) {
          onCloseSearch();
        } else {
          onOpenSearch();
        }
      }
    },
    [onOpenSearch, searchModal, onCloseSearch],
  );

  useIdentify();

  useEffect(() => {
    actions.loadHandlers().catch(() => {
      logAction('API_GLOBAL_ERROR');
    });
    if (GET_IS_TELEGRAM()) {
      const stag = extractDynamicStag(window.location.pathname);
      if (stag) {
        localStorage.setItem('stag', stag);
      }

      actions
        .telegramAuth()
        .then(({ requireDisplayName }: { requireDisplayName: boolean }) => {
          if (requireDisplayName) {
            onOpenTelegramAuth();
          }
        });
    } else {
      actions.loadUser();
    }
    actions.loadGames();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actions]);

  useEffect(() => {
    if (isAuthorized && isAuthorizedReady) {
      actions.changeRoute(location.pathname).catch(console.warn);
    }
  }, [location.pathname, isAuthorized, actions, isAuthorizedReady]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeydown);

    return () => document.removeEventListener('keydown', handleKeydown);
  }, [handleKeydown]);

  useEffect(() => {
    if (GET_IS_TELEGRAM()) {
      try {
        window.Telegram.WebApp.SettingsButton.show();

        window.Telegram.WebApp.expand();

        window.Telegram.WebApp.enableClosingConfirmation();

        const settingsCallback = () => {
          navigate('/user/settings');
        };

        const backCallback = () => {
          navigate(-1);
        };

        window.Telegram.WebApp.onEvent('backButtonClicked', backCallback);
        window.Telegram.WebApp.onEvent(
          'settingsButtonClicked',
          settingsCallback,
        );
      } catch (e) {
        console.warn(e);
      }
    }
  }, [navigate]);

  useEffect(() => {
    if (!isLoading) {
      const { confirmemail, error, tgWebAppStartParam } =
        Object.fromEntries(searchParams);

      if (confirmemail) {
        if (confirmemail === 'false') {
          toastError({
            title: 'Your email address is not verified',
            description: t(`errors.BE.${error}`),
          });
        } else {
          toastSuccess({
            title: 'Email address verified',
            description: `Your email address has been verified`,
          });
        }

        setSearchParams('', { replace: true });
      }

      if (tgWebAppStartParam) {
        navigate(tgWebAppStartParam, { replace: true });
      }
    }
  }, [searchParams, isLoading, t, setSearchParams, navigate]);

  useSupportLazyInit();

  useEffect(() => {
    if (isBlocked && isHandlerReady) {
      toastError({
        title: `Sherbet isn't available in ${country}`,
        description: 'If you are using a VPN, please disable it and try again',
        duration: null,
        Content,
      });
      fetch('https://api.country.is')
        .then(res => {
          if (!res.ok) {
            throw new Error('Network response was not ok');
          }
          return res.json();
        })
        .then((info: { country: string }) => {
          logAction('IS_BLOCKED', { apiCountry: info.country, country });
        })
        .catch(() => {
          logAction('API_COUNTRY_ERROR');
          logAction('IS_BLOCKED', { apiCountry: 'error', country });
        });
    }
  }, [isBlocked, t, isHandlerReady, country]);

  return null;
});
