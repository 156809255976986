import {
  Box,
  Button,
  Code,
  Flex,
  Grid,
  GridItem,
  Text,
} from '@chakra-ui/react';
import { Loading } from 'components/loading';
import { PlayerRow } from 'components/player-row';
import { ShowMore } from 'components/show-more';
import { GET_IS_TELEGRAM } from 'constants/general';
import dayjs from 'dayjs';
import { CustomInput } from 'forms/custom-input';
import { applyLangToUrl } from 'helpers/lang';
import { formatCredit } from 'helpers/numeral';
import { useCopy } from 'hooks/use-copy';
import { useToastError } from 'hooks/use-toast-error';
import { CopyIcon } from 'icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getAffiliateCode,
  getAffiliatesList,
  getAffiliatesStats,
  getAffiliatesStatsLoaded,
  loadAffiliates,
  loadAffiliatesStats,
  redeemAffiliate,
} from 'services/user/modules/affiliates';
import { useActions } from 'store';
import { toastSuccess } from 'toasts';
import { Errors } from 'types';
import { Link } from 'uikit/link';
import { StatsGrid } from 'uikit/stats-grid';
import { Table } from 'uikit/table';

type Props = {
  type: 'general' | 'commission' | 'referred' | 'earnings';
};

export const ProfileAffiliates: React.FC<Props> = ({ type = 'general' }) => {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const actions = useActions({
    loadAffiliatesStats,
    loadAffiliates,
    redeemAffiliate,
  });
  const isLoaded = useSelector(getAffiliatesStatsLoaded);
  const stats = useSelector(getAffiliatesStats);
  const list = useSelector(getAffiliatesList);
  const affiliateCode = useSelector(getAffiliateCode);
  const toastError = useToastError();

  const fields = [
    {
      key: 'player',
      title: t('affiliates.player'),
      textAlign: 'left' as const,
      minWidth: '120px',
    },
    {
      key: 'date',
      title: t('affiliates.date'),
      textAlign: 'center' as const,
    },
    {
      key: 'wager',
      title: t('affiliates.wagered'),
      textAlign: 'center' as const,
    },
    {
      key: 'deposited',
      title: t('affiliates.deposited'),
      textAlign: 'center' as const,
    },
    {
      key: 'commission',
      title: t('affiliates.commission'),
      textAlign: 'right' as const,
    },
  ];

  useEffect(() => {
    if (!isLoaded) {
      actions.loadAffiliatesStats();
      actions.loadAffiliates();
    }
  }, [isLoaded, actions]);

  const loadMore = async () => {
    const last = list.at(-1);
    try {
      setIsLoadingMore(true);
      await actions.loadAffiliates({
        after: last.id,
        wagerAmount: last.wagerAmount,
      });
    } catch (e) {
      console.warn(e);
    } finally {
      setIsLoadingMore(false);
    }
  };

  let link = `${window.location.origin}${applyLangToUrl(
    `/affiliate/${affiliateCode}`,
    i18n.language,
  )}`;

  if (GET_IS_TELEGRAM()) {
    const {
      user: { id },
    } = window.Telegram.WebApp.initDataUnsafe;
    link = `${window.location.origin}/tg?start=${id}`;
  }

  const { onCopy } = useCopy(link, {
    description: t('affiliates.has-copied'),
  });

  const handleClaim = async () => {
    try {
      setIsLoading(true);
      await actions.redeemAffiliate(stats.affiliateAmountAvailable);
      toastSuccess({
        description: t('affiliates.claimed'),
      });
    } catch (errors) {
      toastError(errors as Errors);
    } finally {
      setIsLoading(false);
    }
  };

  if (!stats || !isLoaded) {
    return <Loading pos="relative" my="14" />;
  }

  const data = list.map(item => ({
    player: <PlayerRow user={item} />,
    wager: `$${formatCredit(item.wagerAmount)}`,
    date: dayjs(item.accountCreationTimestamp * 1000).format('MM/DD/YYYY'),
    deposited: `$${formatCredit(item.depositedAmount)}`,
    commission: `$${formatCredit(item.commissionAmount)}`,
  }));

  const overviewItems = [
    {
      title: t('affiliates.referred-users'),
      content: stats.totalRefereesCount,
    },
    {
      title: t('affiliates.total-deposited'),
      content: `$${formatCredit(
        list.reduce((acc, item) => acc + Number(item.depositedAmount), 0) || 0,
      )}`,
    },
    {
      title: t('affiliates.total-wagered'),
      content: `$${formatCredit(stats.totalWagerAmount)}`,
    },
  ];

  const earningsItems = [
    {
      title: t('affiliates.total-claimed'),
      content: `$${formatCredit(stats.affiliateAmountClaimed)}`,
    },
    {
      title: t('affiliates.claimable'),
      isClaim: true,
      content: `$${formatCredit(stats.affiliateAmountAvailable)}`,
    },
  ];

  return (
    <Flex flexDirection="column" my="5">
      {type === 'general' && (
        <Box>
          <StatsGrid options={overviewItems} />
          <Box bg="sugar-dust" p="5" mb="5" mt="5" rounded="xl">
            <Flex
              align="end"
              w={{ base: 'full', md: 'auto' }}
              flexDir={{ base: 'column', md: 'row' }}
              gap="2"
            >
              <CustomInput
                rightElement={<CopyIcon color="candy-floss-text" />}
                _hover={{ cursor: 'copy' }}
                onClick={onCopy}
                name="multiplier"
                w="100%"
                readOnly
                value={link}
                label={t('affiliates.link')}
                placeholder={t('affiliates.link')}
              />
            </Flex>
          </Box>
          <Flex bg="sugar-dust" p="5" rounded="xl" mb="5">
            <Flex flexDirection="column" justifyContent="space-between">
              <Box>
                <Text color="candy-floss-text">
                  {t('affiliates.desc1')}
                  <br />
                  <br />
                  {t('affiliates.desc2')}{' '}
                  <Link
                    href="https://help.sherbet.com/en/articles/6880724-affiliates"
                    rel="nofollow"
                    isExternal
                    color="sherbet-purple"
                    _hover={{ opacity: '0.9' }}
                  >
                    {t('settings.learn-more')}
                  </Link>
                </Text>
                <br />
                <Text color="candy-floss-text" mb="5">
                  {t('affiliates.commission.casino.description')}
                </Text>
                <Code w="full" p="2" rounded="xl" bg="sugar-dust">
                  {t('affiliates.commission.casino.formula')}
                </Code>
              </Box>
            </Flex>
          </Flex>
        </Box>
      )}

      {type === 'referred' && (
        <Box>
          <Table fields={fields} data={data} />
          <ShowMore
            isLoading={isLoadingMore}
            onClick={loadMore}
            current={data.length}
            total={stats.totalRefereesCount}
          />
        </Box>
      )}
      {type === 'earnings' && (
        <Box>
          <Grid
            templateColumns={{
              base: 'repeat(1, 1fr)',
              lg: 'repeat(2, 1fr)',
            }}
            columnGap="3"
            rowGap="5"
            w="full"
          >
            {earningsItems.map(({ title, content, isClaim }) => (
              <GridItem key={title}>
                <Flex
                  bg="sugar-dust"
                  p="5"
                  rounded="xl"
                  justifyContent="space-between"
                  align="end"
                  w={{ base: 'full', md: 'auto' }}
                  gap="2"
                >
                  <Box>
                    <Text color="candy-floss-text" mb="1" fontWeight="500">
                      {title}
                    </Text>
                    <Text fontWeight="500" fontSize="xl">
                      {content}
                    </Text>
                  </Box>
                  {isClaim && stats.affiliateAmountAvailable > 0 && (
                    <Button
                      w={{ base: 'full', md: 'auto' }}
                      onClick={handleClaim}
                      isLoading={isLoading}
                      colorScheme="purple"
                    >
                      {t('actions.claim')} $
                      {formatCredit(stats.affiliateAmountAvailable)}
                    </Button>
                  )}
                </Flex>
              </GridItem>
            ))}
          </Grid>
        </Box>
      )}
    </Flex>
  );
};
