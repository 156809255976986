import { createIcon } from '@chakra-ui/icons';

export const FRFlagIcon = createIcon({
  displayName: 'FRFlagIcon',
  viewBox: '0 0 512 512',
  path: (
    <>
      <circle cx="256" cy="256" r="256" fill="#eee" />
      <mask id="fr-flag-mask">
        <circle cx="256" cy="256" r="256" fill="#fff" />
      </mask>
      <g mask="url(#fr-flag-mask)">
        <path fill="#eee" d="M167 0h178l25.9 252.3L345 512H167l-29.8-253.4z" />
        <path fill="#0052b4" d="M0 0h167v512H0z" />
        <path fill="#d80027" d="M345 0h167v512H345z" />
      </g>
    </>
  ),
});
