import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
  getUserAvatar,
  getUserBalance,
  getUserEmail,
  getUserId,
  getUserLevel,
  getUserName,
} from 'services/user/modules/user';

export const useIdentify = () => {
  const userId = useSelector(getUserId);
  const email = useSelector(getUserEmail);
  const balance = useSelector(getUserBalance);
  const username = useSelector(getUserName);
  const level = useSelector(getUserLevel);
  const avatar = useSelector(getUserAvatar);
  useEffect(() => {
    try {
      if (username) {
        window.heap.identify(username);
        window.heap.addUserProperties({
          userId,
          email,
          balance,
          level,
          nftAvatar: !!avatar,
        });
      }
    } catch (e) {
      console.warn(e);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [username]);
};

export const track = (action: string, params = {}) => {
  try {
    window.heap.track(action, params);
  } catch (e) {
    console.warn(e);
  }
};
