import { Flex } from '@chakra-ui/react';
import { Content } from 'components/content';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CasinoSearch } from 'services/games/components/casino-search/casino-search';
import { SearchContent } from './components/search-content';

export const Search = ({ onClose }) => {
  const [search, setSearch] = useState('');
  const { t } = useTranslation();
  const [sort, setSort] = useState('featured'); // Added
  const [providers, setProviders] = useState([]); // Added
  const [isSearchFocused, setIsSearchFocused] = useState(false); // Added

  return (
    <Content p="5" maxW="6xl" mx="auto" w="full">
      <Flex flexDirection="column" gap="5">
        <CasinoSearch
          search={search}
          onChangeSearch={setSearch}
          sort={sort}
          onChangeSort={setSort}
          providers={providers}
          onChangeProviders={setProviders}
          placeholder={t('casino.search.placeholder')}
          hasLucky={false}
          isFullWidth={false}
          onFocus={() => setIsSearchFocused(true)}
          isOpened={isSearchFocused}
          autoFocus
        />
        <SearchContent search={search} setSearch={setSearch} />
      </Flex>
    </Content>
  );
};
