import { createIcon } from '@chakra-ui/icons';

export const CNFlagIcon = createIcon({
  displayName: 'CNFlagIcon',
  viewBox: '0 0 512 512',
  path: (
    <>
      <mask id="ChinaFlag0.34762454642708174">
        <circle cx="256" cy="256" r="256" fill="#fff" />
      </mask>
      <g mask="url(#ChinaFlag0.34762454642708174)">
        <path fill="#d80027" d="M0 0h512v512H0z" />
        <path
          fill="#ffda44"
          d="m140.1 155.8 22.1 68h71.5l-57.8 42.1 22.1 68-57.9-42-57.9 42 22.2-68-57.9-42.1H118zm163.4 240.7-16.9-20.8-25 9.7 14.5-22.5-16.9-20.9 25.9 6.9 14.6-22.5 1.4 26.8 26 6.9-25.1 9.6zm33.6-61 8-25.6-21.9-15.5 26.8-.4 7.9-25.6 8.7 25.4 26.8-.3-21.5 16 8.6 25.4-21.9-15.5zm45.3-147.6L370.6 212l19.2 18.7-26.5-3.8-11.8 24-4.6-26.4-26.6-3.8 23.8-12.5-4.6-26.5 19.2 18.7zm-78.2-73-2 26.7 24.9 10.1-26.1 6.4-1.9 26.8-14.1-22.8-26.1 6.4 17.3-20.5-14.2-22.7 24.9 10.1z"
        />
      </g>
    </>
  ),
});
