import { client, parseApiError } from 'helpers/client';

export const loadAffiliatesStats = (params: { [key: string]: unknown } = {}) =>
  client
    .post('/core/user/affiliate-stats-total', params)
    .then(({ data }) => data.payload);

export const loadAffiliates = (params: { [key: string]: unknown } = {}) =>
  client
    .post('/core/user/affiliate-stats', params)
    .then(({ data }) => data.payload.referees);

export const redeemAffiliate = () =>
  client
    .post('/core/user/affiliate-redeem')
    .then(({ data }) => data.payload)
    .catch(parseApiError);
