import { removeTrailingSlash } from 'helpers/lang';
import { useSeoMeta } from 'hooks/use-seo-meta';
import { useUrlModal } from 'modals';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

type Props = {
  title?: string;
  description?: string;
  image?: string;
  schema?: object;
};

export const Seo: React.FC<Props> = ({
  title: propTitle,
  description: propDescription,
  image = 'https://sherbet.com/meta-image.png',
  schema,
}) => {
  // eslint-disable-next-line prefer-const
  let { title, description } = useSeoMeta();

  const location = useLocation();
  const { i18n } = useTranslation();
  const pathname = removeTrailingSlash(location.pathname);
  const pageModal = useUrlModal();

  if (propTitle) {
    title = propTitle;
    description = propTitle;
  }
  if (propDescription) {
    description = propDescription;
  }

  return (
    <Helmet>
      <html lang={i18n.language} />
      <title>{title}</title>
      <meta name="title" content={title} />
      <meta property="og:title" content={title} />
      <meta property="og:site_name" content={title} />
      <meta property="twitter:title" content={title} />
      <meta property="og:description" content={description} />
      <meta name="description" content={description} />
      <meta property="twitter:description" content={description} />
      <meta property="og:url" content={`https://sherbet.com${pathname}`} />
      <meta property="twitter:url" content={`https://sherbet.com${pathname}`} />
      <meta property="og:image" content={image} />
      <meta property="twitter:card" content="summary_large_image" />
      <meta property="twitter:image" content={image} />
      {pageModal ? (
        <meta name="robots" content="noindex,nofollow" />
      ) : (
        <meta
          name="robots"
          content="index, follow, max-image-preview:large, max-snippet:-1, max-video-preview:-1"
        />
      )}
      {schema && (
        <script type="application/ld+json">{JSON.stringify(schema)}</script>
      )}
    </Helmet>
  );
};
