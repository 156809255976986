import { WS_MESSAGE } from 'helpers/ws';
import { userWebsocket } from 'services/user/modules/user';
import { createReducer } from 'store';
import { addReaction, loadFeed } from './actions';
import { SocialType } from './types';

const initialState: SocialType = {
  isLoaded: false,
  posts: [],
  users: {},
  reactions: {},
  totalCount: 0,
};

export const reducer = createReducer(initialState, {
  [loadFeed.toString()]: (state, { payload, meta }) => {
    if (meta?.before) {
      state.posts = [...state.posts, ...payload.data.posts];
      state.reactions = {
        ...state.reactions,
        ...payload.data.reactions,
      };
      state.users = { ...state.users, ...payload.users };
    } else {
      state.posts = payload.data.posts;
      state.reactions = payload.data.reactions;
      state.users = payload.users;
      state.isLoaded = true;
      state.totalCount = payload.data.totalCount;
    }
  },
  [addReaction.toString()]: (
    state,
    { meta: { postId: id, userId, reaction, isAdd } },
  ) => {
    if (!state.reactions[id]) {
      state.reactions[id] = {};
    }

    if (isAdd) {
      state.reactions[id][userId] = reaction;
    } else {
      delete state.reactions[id][userId];
    }
  },
  [WS_MESSAGE(userWebsocket, 'SOCIAL_POST')]: (state, { payload }) => {
    state.posts = [
      { ...payload, timestamp: Math.floor(Date.now() / 1000) },
      ...state.posts,
    ];
    state.totalCount += 1;
  },
});
