import { createIcon } from '@chakra-ui/icons';

export const QuestsIcon = createIcon({
  displayName: 'QuestsIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path
        fill="currentColor"
        d="M5 2.5a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Zm11 11a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.567 2a1 1 0 0 1 1-1h.044c.044 0 .087.003.13.008A1.01 1.01 0 0 1 7.87 1h.571a1 1 0 0 1-.002 2h-.568a1.01 1.01 0 0 1-.13-.008A1.01 1.01 0 0 1 7.61 3h-.044a1 1 0 0 1-1-1Zm4.07 3.768a1 1 0 0 1-.237 1.394c-.138.098-.281.2-.43.304l-.406.288A1 1 0 1 1 8.409 6.12l.407-.287.428-.303a1 1 0 0 1 1.394.237ZM7.365 8.085a1 1 0 0 1-.239 1.394l-.406.287-.428.303a1 1 0 1 1-1.157-1.631c.138-.098.281-.2.43-.304l.406-.288a1 1 0 0 1 1.394.239Zm-1.27 5.514a1 1 0 0 1 1.001-1l.525.001h.093c.097 0 .191.014.28.04.09-.026.183-.04.28-.04h.094a1 1 0 1 1 0 2h-.093a.998.998 0 0 1-.28-.04.998.998 0 0 1-.28.04h-.621a1 1 0 0 1-.999-1.001Zm4.522-11.693a1 1 0 0 1 1.247-.668 2.12 2.12 0 0 1 1.081.713 1 1 0 0 1 .174.954l-.518-.189-1.018.678a.997.997 0 0 1-.184-.174.592.592 0 0 0-.035-.039.183.183 0 0 1 .008.004l.003.002s-.011-.007-.028-.023a.583.583 0 0 1 .017.017.612.612 0 0 0-.08-.029 1 1 0 0 1-.667-1.246Zm.91 1.787a.633.633 0 0 1-.048.07 1 1 0 1 0 1.578 1.228 2.12 2.12 0 0 0 .47-1.206 1 1 0 0 0-.408-.88l-.518-.189-1.018.678a1.002 1.002 0 0 0-.05.249.59.59 0 0 1-.006.05Zm-.004.025a.684.684 0 0 0 .004-.025l.004-.008.002-.002s-.006.012-.01.035Z"
        clipRule="evenodd"
      />
      <path fill="currentColor" d="m12 3 .98-1c.167.167.82 1 .52 2L12 3Z" />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.957 13.69a1 1 0 0 1-1.247.667 2.119 2.119 0 0 1-1.08-.713 1 1 0 0 1-.175-.954l.518.189 1.019-.678a.999.999 0 0 1 .184.174.63.63 0 0 0 .034.039.204.204 0 0 1-.008-.004l-.003-.002s.012.007.028.023a.737.737 0 0 1-.017-.017.63.63 0 0 0 .08.029 1 1 0 0 1 .667 1.246Zm-.91-1.788a.609.609 0 0 1 .048-.07 1 1 0 1 0-1.578-1.228 2.119 2.119 0 0 0-.47 1.206 1 1 0 0 0 .408.88l.518.189 1.019-.678a1.07 1.07 0 0 0 .05-.248.602.602 0 0 1 .005-.051Zm.004-.024a.62.62 0 0 0-.004.024.183.183 0 0 0-.004.008l-.001.002s.005-.012.01-.035Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="m3.574 12.595-.98 1c-.166-.167-.82-1-.52-2l1.5 1Z"
      />
    </>
  ),
});
