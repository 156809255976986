import { createAction } from 'store';
import * as api from './api';

export const loadRandomNfts = createAction(
  'nft/loadRandomNfts',
  () => () => api.loadRandomNfts(),
);

export const loadNftInfo = createAction(
  'nft/loadNftInfo',
  () => () => api.loadNftInfo(),
);

export const loadNfts = createAction(
  'nft/loadNfts',
  () => () => api.loadNfts(),
);

export const loadOwnedNfts = createAction(
  'nft/loadOwnedNfts',
  () => () => api.loadOwnedNfts(),
);

export const loadVerifiedAddresses = createAction(
  'nft/loadVerifiedAddresses',
  () => () => api.loadVerifiedAddresses(),
);

export const selectNft = createAction(
  'nft/selectNft',
  nftId => () => api.selectNft(nftId),
  nftId => ({ nftId }),
);

export const loadNFTDetails = createAction(
  'user/loadNFTDetails',
  id => () => api.loadNFTDetails(id),
  id => ({ id }),
);

export const addVerifyAddress = createAction('nft/addVerifyAddress');

export const stakeNfts = createAction('nft/stakeNfts');

export const loadNftSales = createAction(
  'nft/loadNftSales',
  () => () => api.loadNftSales(),
);
