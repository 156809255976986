import { Input, InputGroup, InputLeftElement } from '@chakra-ui/react';
import { useMobile } from 'hooks/use-mobile';
import { SearchIcon } from 'icons';

type Props = {
  value: string | number;
  autoFocus?: boolean;
  onSubmit?: () => void;
  onBlur?: (event: React.FocusEvent<HTMLElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLElement>) => void;
  onChange: (string: string) => void;
  placeholder?: string;
  readOnly?: boolean;
};

export const SearchInput: React.FC<Props> = ({
  value,
  onChange,
  placeholder,
  onFocus,
  onBlur,
  onSubmit,
  autoFocus,
  readOnly,
}) => {
  const isMobile = useMobile();
  return (
    <InputGroup minW="40%">
      <InputLeftElement
        pointerEvents="none"
        w="auto"
        left="4"
        color="candy-floss-text"
      >
        <SearchIcon />
      </InputLeftElement>
      <Input
        variant="primary"
        bg="transparent"
        value={value}
        onChange={e => onChange(e.target.value)}
        placeholder={placeholder}
        type="search"
        inputMode="search"
        onFocus={onFocus}
        onBlur={onBlur}
        autoComplete="on"
        readOnly={readOnly}
        onKeyDown={e => {
          if (onSubmit && e.keyCode === 13 && e.shiftKey === false) {
            onSubmit();
          }
        }}
        autoFocus={isMobile ? undefined : autoFocus}
      />
    </InputGroup>
  );
};
