import { Button, Flex, FormControl } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { CustomInput } from 'forms/custom-input';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  changeEmail,
  getUserEmail,
  getUserEmailConfirmed,
  resendConfirmEmail,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { toastError, toastSuccess } from 'toasts';
import { FormSubmit } from 'types';
import * as Yup from 'yup';

type ErrorsType = {
  [key: string]: string;
};

export const EmailForm = () => {
  const { t } = useTranslation();
  const actions = useActions({ changeEmail, resendConfirmEmail });

  const [isResending, setIsResending] = useState(false);

  const userEmail = useSelector(getUserEmail);
  const emailConfirmed = useSelector(getUserEmailConfirmed);

  const handleErrors = (errors: ErrorsType) => {
    const error = errors.email || errors.global;
    toastError({
      title: t(`errors.BE.${error}`),
      description: t(`errors.BE.${error}_DESCRIPTION`, ''),
    });
  };

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      email: userEmail ?? '',
    },
    validationSchema: Yup.object({
      email: Yup.string().email().required(),
    }),
    onSubmit: async ({ email }) => {
      try {
        await actions.changeEmail(email);
        toastSuccess({
          title: t('settings.email-updated'),
          description: t('settings.email-updated-description', { email }),
        });
      } catch (errors) {
        handleErrors(errors as ErrorsType);
      }
    },
  });

  const handleResendConfirmEmail = async () => {
    setIsResending(true);
    try {
      await actions.resendConfirmEmail();
      toastSuccess({
        title: t('settings.verification-sent'),
        description: t('settings.verification-sent-description', {
          email: userEmail,
        }),
      });
    } catch (errors) {
      handleErrors(errors as ErrorsType);
    } finally {
      setIsResending(false);
    }
  };

  return (
    <FormControl as="form" onSubmit={formik.handleSubmit as FormSubmit}>
      <Flex direction="column" gap="5">
        <CustomInput
          placeholder="Email Address"
          disabled={emailConfirmed}
          type="email"
          cursor={emailConfirmed ? 'not-allowed' : 'text'}
          name="email"
          inputMode="email"
          autoComplete="email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
          isInvalid={!!(formik.errors.email && formik.touched.email)}
          errorMessage={formik.errors.email}
          size="md"
          boxProps={{ w: 'full' }}
        />
        {!emailConfirmed && (
          <Flex
            align="center"
            w={{ base: 'full', md: 'auto' }}
            flexDir={{ base: 'column', md: 'row' }}
            gap="2"
          >
            <Button
              rounded="full"
              w={{ base: 'full', md: 'auto' }}
              isLoading={isResending}
              onClick={handleResendConfirmEmail}
            >
              {t('errors.verify-email-resend')}
            </Button>
            <Button
              w={{ base: 'full', md: 'auto' }}
              type="submit"
              colorScheme="purple"
              isDisabled={!formik.dirty}
              isLoading={formik.isSubmitting}
            >
              {t('profile.save-changes')}
            </Button>
          </Flex>
        )}
      </Flex>
    </FormControl>
  );
};
