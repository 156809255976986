/* eslint-disable react/no-unescaped-entities */
/* eslint-disable max-lines */
import { TriangleDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Image,
  Img,
  keyframes,
  Text,
  VStack,
} from '@chakra-ui/react';
import { Content } from 'components/content';
import {
  BCGameLogo,
  ChipsLogo,
  DuelbitsLogo,
  QuoteIcon,
  RollbitLogo,
  RoobetLogo,
  StakeLogo,
  SupportIcon,
} from 'icons';
import { FiveHundredLogo } from 'icons/five-hundred-logo';
import { GamdomLogo } from 'icons/gamdom-logo';
import { PreloaderIcon } from 'icons/preloader-icon';
import { ShuffleLogo } from 'icons/shuffle-logo';
import { useState } from 'react';
import { PromoSlider } from 'services/cms/components/promo-slider';
import { Wagers } from 'services/games/pages/casino/components/wagers';

const scrollAnimation = keyframes`
  0% { transform: translateX(0); }
  100% { transform: translateX(-50%); }
`;

const contentMap = {
  'Rank Match': {
    title: "Diamond? Platinum? Everyone's welcome.",
    description:
      "You worked hard for your rank, so why should you have to grind up the ladder again? With Sherbet Status Swap, you start at the same level you've achieved elsewhere.",
    image: 'https://i.imgur.com/uoDII5T.png',
  },
  'Welcome Package': {
    title: 'A little something to kickstart your Sherbet journey.',
    description: [
      'New players on Sherbet receive an exclusive Welcome Package, containing three deposit bonuses on your first three deposits and up to 20 free spins on Gates of Olympus.',
      'Plus, for 24 hours after signing up, receive a temporary 26% boost to your Rakeback level.',
    ],
    image: 'https://i.imgur.com/uoDII5T.png',
  },
  'VIP Host': {
    title: 'Your very own point of contact, available 24/7/365.',
    description:
      'Enjoy dedicated support from a personal VIP host, ensuring a tailored experience on Sherbet - no matter the time you play.',
    image: 'https://i.imgur.com/uoDII5T.png',
  },
  'Tailored Bonuses': {
    title: 'Bonuses tailored to your playstyle.',
    description:
      'Receive personalized bonuses and promotions designed specifically for your playstyle and preferences.',
    image: 'https://i.imgur.com/uoDII5T.png',
  },
};

type ContentKey = keyof typeof contentMap;

export const StatusSwap = () => {
  const [selectedItem, setSelectedItem] = useState<ContentKey>('Rank Match');

  const handleItemClick = (item: ContentKey) => {
    if (item !== selectedItem) {
      setSelectedItem(item);
    }
  };

  const selectedContent = selectedItem
    ? contentMap[selectedItem as keyof typeof contentMap]
    : contentMap['Rank Match'];

  const logoSet = [
    { icon: StakeLogo, key: 'sherbet1' },
    { icon: FiveHundredLogo, key: '500' },
    { icon: ChipsLogo, key: 'chips' },
    { icon: ShuffleLogo, key: 'sherbet2' },
    { icon: GamdomLogo, key: 'sherbet3' },
    { icon: DuelbitsLogo, key: 'duelbits' },
    { icon: RollbitLogo, key: 'rollbit' },
    { icon: RoobetLogo, key: 'Roobet' },
    { icon: BCGameLogo, key: 'BCGame' },
  ];

  const duplicatedLogos = [...logoSet, ...logoSet];

  const slideUpAnimation = keyframes`
    0% { transform: translateY(0); }
    100% { transform: translateY(-50%); }
  `;

  return (
    <Content
      direction="column"
      flexGrow="1"
      w="full"
      overflow="hidden"
      position="relative"
    >
      <Flex flexDir="column" maxW="6xl" mx="auto" w="full" p="5">
        <Flex justifyContent="center" flexDirection="column" align="center">
          <Box
            bg="sugar-dust"
            p={{ base: '5', md: '7' }}
            pb={{ base: '5', md: '7' }}
            rounded="xl"
            position="relative"
            w="full"
          >
            <Box
              roundedTop="xl"
              w="full"
              mb="7"
              h={{ base: '32', md: '60' }}
              bgSize="cover"
              bgRepeat="no-repeat"
              bgPos="center"
              // bg="sherbet-purple"
              bgImage="https://i.imgur.com/wUL7Iwz.png"
              position="relative"
              _after={{
                content: '""',
                position: 'absolute',
                bottom: 0,
                left: 0,
                right: 0,
                height: '100%',
                backgroundImage:
                  'linear-gradient(to top, oklch(24.4% 0.023 280.8), rgba(0,0,0,0))',
                pointerEvents: 'none',
              }}
            />

            <Flex
              alignItems="start"
              flexDir={{ base: 'column', md: 'row' }}
              justifyContent="space-between"
              w="full"
              gap="7"
            >
              <Flex flexDir="column" w={{ base: 'full', md: '50%' }} gap="5">
                <Box
                  as="span"
                  lineHeight="1.2"
                  fontSize={{ base: '2xl', md: '4xl' }}
                  fontWeight="400"
                >
                  <Box
                    as="span"
                    verticalAlign="middle"
                    lineHeight="1.2"
                    fontWeight="500"
                  >
                    Sherbet Status Swap{' '}
                  </Box>
                  <Img
                    src="https://pbs.twimg.com/profile_images/1775043578366160896/wZOBMoVW_400x400.jpg"
                    display="inline"
                    verticalAlign="middle"
                    rounded="full"
                    h={{ base: '24px', md: '32px' }}
                    w={{ base: '24px', md: '32px' }}
                  />
                  <br></br>
                  <Box
                    as="span"
                    color="candy-floss-text"
                    verticalAlign="middle"
                    lineHeight="1.2"
                  >
                    Switch to Sherbet and bring your existing
                  </Box>{' '}
                  <Flex verticalAlign="middle" display="inline-flex">
                    <Img
                      src="https://spinkick.imgix.net/badges/level/bronze-2.svg"
                      display="inline"
                      verticalAlign="middle"
                      h={{ base: '24px', md: '32px' }}
                      rounded="xl"
                      w={{ base: '24px', md: '32px' }}
                      mr="-12px"
                    />
                    <Img
                      src="https://spinkick.imgix.net/badges/level/silver-2.svg"
                      display="inline"
                      verticalAlign="middle"
                      h={{ base: '24px', md: '32px' }}
                      rounded="xl"
                      w={{ base: '24px', md: '32px' }}
                      mr="-12px"
                    />
                    <Img
                      src="https://spinkick.imgix.net/badges/level/gold-2.svg"
                      display="inline"
                      verticalAlign="middle"
                      h={{ base: '24px', md: '32px' }}
                      rounded="xl"
                      w={{ base: '24px', md: '32px' }}
                    />
                  </Flex>{' '}
                  <Box
                    as="span"
                    color="candy-floss-text"
                    verticalAlign="middle"
                    lineHeight="1.2"
                  >
                    {' '}
                    VIP rank + progress with you.{' '}
                  </Box>
                </Box>
                {/* <Box
                  display={{ base: 'none', md: 'block' }}
                  w="fit-content"
                  px="4"
                  pt="2"
                  fontSize="md"
                  bg="toffee-base"
                  color="candy-floss-text"
                  roundedTop="xl"
                >
                  We're Accepting Users From
                </Box> */}
              </Flex>

              <Flex
                align="end"
                gap="3"
                flexDir="column"
                w={{ base: 'full', md: '33.333333%' }}
              >
                <Flex
                  justifyContent="start"
                  borderBottom="1px solid"
                  borderColor="truffle-border"
                  w="full"
                  pb="2"
                >
                  <Flex align="center" gap="2">
                    <Text w="full" fontWeight="500" fontSize="xl">
                      Life's sweeter on Sherbet
                    </Text>
                  </Flex>
                </Flex>
                <Text w="full" color="candy-floss-text">
                  Apply for a Status Swap using the button below. You'll be
                  taken to our live support widget to complete your application.
                </Text>
                <Flex w="full" justifyContent="start" alignItems="start" mt="2">
                  <Button colorScheme="purple" rightIcon={<SupportIcon />}>
                    Switch To Sherbet
                  </Button>
                </Flex>
              </Flex>
            </Flex>
          </Box>
          <Box w="full" overflow="hidden" position="relative">
            <Flex
              py="7"
              align="center"
              w="fit-content"
              animation={`${scrollAnimation} 50s linear infinite`}
            >
              {duplicatedLogos.map(({ icon: Icon, key }, index) => (
                <Box key={`${key}-${index}`} mx="5">
                  <Icon opacity="0.5" h="7" w="auto" />
                </Box>
              ))}
              {duplicatedLogos.map(({ icon: Icon, key }, index) => (
                <Box key={`${key}-${index}-duplicate`} mx="5">
                  <Icon opacity="0.5" h="7" w="auto" />
                </Box>
              ))}
            </Flex>
            <Grid
              templateColumns={{ base: '1fr', md: '1fr 1fr' }}
              gap="3"
              margin="0 auto"
            >
              <GridItem bg="sugar-dust" p="5" rounded="xl">
                <Flex
                  flexDirection="column"
                  justifyContent="space-between"
                  h="full"
                >
                  <Box mb="5">
                    <Button rounded="full">What We Offer</Button>
                  </Box>
                  <VStack align="stretch" spacing={2}>
                    {[
                      'Rank Match',
                      'Welcome Package',
                      'VIP Host',
                      'Tailored Bonuses',
                    ].map(item => (
                      <HStack
                        key={item}
                        justify="space-between"
                        fontSize="xl"
                        pb="2"
                        borderBottom="1px solid"
                        borderColor={
                          selectedItem === item
                            ? 'vanilla-text'
                            : 'truffle-border'
                        }
                        onClick={() => handleItemClick(item as ContentKey)}
                        cursor="pointer"
                        _hover={{ opacity: 0.9 }}
                      >
                        <Text
                          fontWeight="500"
                          color={
                            selectedItem === item
                              ? 'vanilla-text'
                              : 'candy-floss-text'
                          }
                          fontSize="xl"
                        >
                          {item}
                        </Text>
                        <TriangleDownIcon
                          transform="rotate(-90deg)"
                          color={
                            selectedItem === item
                              ? 'vanilla-text'
                              : 'truffle-border'
                          }
                        />
                      </HStack>
                    ))}
                  </VStack>
                </Flex>
              </GridItem>

              <GridItem bg="sugar-dust" p="5" rounded="xl">
                <Flex flexDir="column" h="full" justifyContent="start">
                  <Heading mb="2" fontSize="xl">
                    {selectedContent.title}
                  </Heading>
                  {Array.isArray(selectedContent.description) ? (
                    selectedContent.description.map((paragraph, index) => (
                      <Text
                        key={index}
                        color="candy-floss-text"
                        mb={
                          index < selectedContent.description.length - 1 ? 4 : 0
                        }
                      >
                        {paragraph}
                      </Text>
                    ))
                  ) : (
                    <Text color="candy-floss-text">
                      {selectedContent.description}
                    </Text>
                  )}
                  {/* <Img
                    mt="5"
                    rounded="xl"
                    src={selectedContent.image}
                    w="full"
                    h="auto"
                  /> */}
                </Flex>
              </GridItem>
            </Grid>
            <Box mt="7">
              <Grid
                templateColumns={{
                  base: 'repeat(1, 1fr)',
                  md: 'repeat(2, 1fr)',
                  xl: 'repeat(3, 1fr)',
                }}
                columnGap={{ base: '3', md: '3' }}
                rowGap="3"
                w="full"
              >
                <GridItem
                  justifyContent="space-between"
                  bg="sugar-dust"
                  rounded="xl"
                  p="5"
                  display="flex"
                  flexDir="column"
                >
                  <Box>
                    <QuoteIcon h="8" w="8" color="candy-floss-text" mb="3" />
                    <Text color="candy-floss-text" maxW="xs" mb="5">
                      The team values feedback and does their best to meet your
                      suggestions. The bonuses are plenty, and being a VIP here
                      is the best.
                    </Text>
                  </Box>
                  <Flex gap="3">
                    <Image
                      src="https://spinkick.imgix.net/tokens/099aea6b-481d-484d-8323-24c773e9293a.png"
                      h="10"
                      w="10"
                      rounded="full"
                      alt="Exclusive Bonuses"
                      draggable="false"
                    />
                    <Box>
                      <Text fontWeight="500">Trouble</Text>
                      <Text color="candy-floss-text">
                        Diamond 3, Switched from Stake
                      </Text>
                    </Box>
                  </Flex>
                </GridItem>
                <GridItem
                  bg="sugar-dust"
                  justifyContent="space-between"
                  rounded="xl"
                  p="5"
                  display="flex"
                  flexDir="column"
                >
                  <Box>
                    <QuoteIcon h="8" w="8" color="candy-floss-text" mb="3" />
                    <Text color="candy-floss-text" maxW="xs" mb="5">
                      An awesome experience. Genuine atmosphere that makes you
                      feel like you're at the finest casino in Vegas from the
                      heart of your home.
                    </Text>
                  </Box>
                  <Flex gap="3">
                    <Image
                      src="https://spinkick.imgix.net/badges/level/platinum-1.svg"
                      h="10"
                      w="10"
                      rounded="xl"
                      alt="Exclusive Bonuses"
                      draggable="false"
                    />
                    <Box>
                      <Text fontWeight="500">Phillipe L.</Text>
                      <Text color="candy-floss-text">
                        Platinum 1, Switched from Rollbit
                      </Text>
                    </Box>
                  </Flex>
                </GridItem>
                <GridItem
                  justifyContent="space-between"
                  bg="sugar-dust"
                  rounded="xl"
                  p="5"
                  display="flex"
                  flexDir="column"
                >
                  <Box>
                    <QuoteIcon h="8" w="8" color="candy-floss-text" mb="3" />
                    <Text color="candy-floss-text" maxW="xs" mb="5">
                      I had a fantastic experience at Sherbet! The website
                      layout is great, and the customer service, especially with
                      Athony, was top-notch.
                    </Text>
                  </Box>
                  <Flex gap="3">
                    <Image
                      src="https://spinkick.imgix.net/tokens/171cc5c6-7239-4b7c-8cfd-8f8ea46c7d85.png"
                      h="10"
                      w="10"
                      rounded="full"
                      alt="Exclusive Bonuses"
                      draggable="false"
                    />
                    <Box>
                      <Text fontWeight="500">Rovrt</Text>
                      <Text color="candy-floss-text">
                        Diamond 2, Switched from Stake
                      </Text>
                    </Box>
                  </Flex>
                </GridItem>
              </Grid>
            </Box>
            <Flex
              my="7"
              align="center"
              justifyContent="center"
              bg="sugar-dust"
              rounded="xl"
              w="full"
              px="5"
              py="7"
              overflow="hidden"
              position="relative"
              flexDir="column"
            >
              <Flex
                pointerEvents="none"
                w="full"
                h="full"
                justifyContent="center"
                alignItems="center"
                transform="scale(1.18)"
                position="absolute"
                zIndex="1"
                opacity="0.025"
                overflow="hidden"
              >
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  flexDir="column"
                  position="absolute"
                  w="full"
                  h="200%"
                  animation={`${slideUpAnimation} 40s linear infinite`}
                >
                  {[...Array(40)].map((_, rowIndex) => (
                    <Box key={rowIndex} display="flex">
                      {[...Array(20)].map((_, colIndex) => (
                        <Box
                          key={`${rowIndex}-${colIndex}`}
                          width="10"
                          m="2"
                          my="0.5"
                          height="10"
                          display="flex"
                          alignItems="center"
                          justifyContent="center"
                        >
                          <PreloaderIcon
                            width="10"
                            height="10"
                            color="candy-floss-text"
                          />
                        </Box>
                      ))}
                    </Box>
                  ))}
                </Flex>
              </Flex>
              <Heading
                mb="2"
                fontSize={{ base: '2xl', md: '4xl' }}
                textAlign="center"
              >
                But wait, there's more...
              </Heading>
              <Text
                mb="5"
                color="candy-floss-text"
                maxW="md"
                textAlign="center"
              >
                Add, message, and play with friends. Complete Quests. Climb
                Tournament leaderboards. It's all here on Sherbet.
              </Text>
              <Button colorScheme="purple" rightIcon={<SupportIcon />}>
                Switch To Sherbet
              </Button>
            </Flex>
            <PromoSlider />
          </Box>
        </Flex>
      </Flex>
      <Wagers />
    </Content>
  );
};
