import { createIcon } from '@chakra-ui/icons';

export const RainIcon = createIcon({
  displayName: 'RainIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="M5.323 15.26c.854-2.66 1.784-3.59 4.44-4.445a.334.334 0 0 0 0-.64c-2.667-.854-3.597-1.784-4.44-4.44a.338.338 0 0 0-.643 0C3.816 8.39 2.885 9.32.237 10.174a.334.334 0 0 0 0 .64c2.656.854 3.586 1.785 4.44 4.445a.338.338 0 0 0 .646 0Zm8.449-.953c.407-1.138.895-1.627 2.035-2.037a.286.286 0 0 0 0-.541c-1.14-.411-1.628-.896-2.035-2.038a.29.29 0 0 0-.544 0c-.407 1.142-.898 1.627-2.035 2.04a.286.286 0 0 0 0 .542c1.14.41 1.628.899 2.035 2.037a.29.29 0 0 0 .544-.003ZM10.88 7.23c.57-1.594 1.253-2.277 2.85-2.852a.4.4 0 0 0 0-.758c-1.597-.575-2.28-1.254-2.85-2.852a.405.405 0 0 0-.76 0c-.57 1.598-1.258 2.277-2.85 2.856a.4.4 0 0 0 0 .758c1.597.575 2.28 1.259 2.85 2.852a.405.405 0 0 0 .76-.004Z"
    />
  ),
});
