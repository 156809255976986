import { createIcon } from '@chakra-ui/icons';

export const MarketplaceIcon = createIcon({
  displayName: 'MarketplaceIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="M14.027 9.067a5.084 5.084 0 0 1-1.048.113 4.67 4.67 0 0 1-2.492-.694 4.798 4.798 0 0 1-4.968 0 4.67 4.67 0 0 1-2.492.694 5.085 5.085 0 0 1-1.048-.113 4.346 4.346 0 0 1-1.048-.355l-.186-.089v4.202a2.266 2.266 0 0 0 2.258 2.266h2.734v-2.952a.605.605 0 0 1 .605-.604h3.322a.605.605 0 0 1 .605.604v2.92h2.71a2.266 2.266 0 0 0 2.282-2.234V8.623l-.161.089c-.34.164-.701.284-1.073.355ZM2.777.914h1.315v5.395c0 .524-.321.681-1.065.677a3.509 3.509 0 0 1-1.048-.153 2.833 2.833 0 0 1-.516-.194 2.298 2.298 0 0 1-.718-.524 2.685 2.685 0 0 1-.685-2.42l.467-1.2A2.194 2.194 0 0 1 2.777.913Zm3.121 0h4.21v5.354a3.508 3.508 0 0 1-4.21 0V.914Zm9.363 5.193a2.323 2.323 0 0 1-.693.516 2.836 2.836 0 0 1-.517.194c-.34.104-.693.155-1.048.153-.742.009-1.064-.347-1.064-.661V.914h1.314a2.194 2.194 0 0 1 2.226 1.564l.468 1.178a.324.324 0 0 1 0 .088 2.647 2.647 0 0 1-.686 2.363Z"
    />
  ),
});
