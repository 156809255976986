import { createIcon } from '@chakra-ui/icons';

export const EyeIcon = createIcon({
  displayName: 'EyeIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2.05 8c.375.59 1.1 1.595 2.123 2.45 1.037.865 2.321 1.528 3.827 1.528 1.506 0 2.79-.663 3.827-1.528C12.85 9.596 13.575 8.59 13.95 8c-.375-.59-1.1-1.595-2.123-2.45C10.79 4.685 9.506 4.022 8 4.022c-1.506 0-2.79.663-3.827 1.528C3.15 6.405 2.425 7.41 2.05 8Zm.842-3.985C4.154 2.962 5.876 2.022 8 2.022c2.124 0 3.847.94 5.108 1.993 1.26 1.05 2.12 2.263 2.55 2.944l.018.029c.084.13.217.34.284.63.053.236.053.528 0 .764a1.942 1.942 0 0 1-.302.66c-.43.68-1.29 1.892-2.55 2.943-1.261 1.053-2.984 1.993-5.108 1.993-2.124 0-3.846-.94-5.108-1.993-1.26-1.05-2.12-2.263-2.55-2.944l-.018-.029a1.912 1.912 0 0 1-.283-.63 1.822 1.822 0 0 1 0-.764c.066-.29.2-.5.283-.63l.018-.03c.43-.68 1.29-1.892 2.55-2.943ZM8 6.867a1.133 1.133 0 1 0 0 2.266 1.133 1.133 0 0 0 0-2.266ZM4.867 8a3.133 3.133 0 1 1 6.266 0 3.133 3.133 0 0 1-6.266 0Z"
      clipRule="evenodd"
    />
  ),
});
