import {
  BNBIcon,
  BTCIcon,
  DOGEIcon,
  ETHIcon,
  LTCIcon,
  SOLIcon,
  TONIcon,
  TRXIcon,
  USDCIcon,
  USDTIcon,
  XRPIcon,
} from 'icons';

export const WITHDRAWAL_ASSETS = [
  'ETH',
  'BTC',
  'LTC',
  'SOL',
  'USDT',
  'USDC',
  'DOGE',
  'XRP',
  'BNB',
  'TRX',
  'TON',
];

export const NETWORK_NAMES = {
  Ethereum: 'ETH - Ethereum (ERC20)',
};

export const WALLETS_TABS = [
  {
    Icon: ETHIcon,
    shortcut: 'ETH',
    name: 'Ethereum',
    network: 'ERC-20',
    isInstant: true,
  },
  {
    Icon: USDCIcon,
    shortcut: 'USDC',
    name: 'USD Coin',
    network: 'ERC-20',
  },
  {
    Icon: USDTIcon,
    shortcut: 'USDT',
    name: 'Tether',
    network: 'ERC-20',
  },
  {
    Icon: TONIcon,
    shortcut: 'TON',
    name: 'TON',
    network: 'TON Network',
  },
  {
    Icon: BTCIcon,
    shortcut: 'BTC',
    name: 'Bitcoin',
    network: 'BTC',
  },
  {
    Icon: SOLIcon,
    shortcut: 'SOL',
    name: 'Solana',
    network: 'SOL',
  },
  {
    Icon: LTCIcon,
    shortcut: 'LTC',
    name: 'Litecoin',
    network: 'LTC',
  },
  {
    Icon: TRXIcon,
    shortcut: 'TRX',
    name: 'TRX',
    network: 'TRC20',
  },
  {
    Icon: DOGEIcon,
    shortcut: 'DOGE',
    name: 'Dogecoin',
    network: 'DOGE',
  },
  {
    Icon: XRPIcon,
    shortcut: 'XRP',
    name: 'XRP',
    fields: [{ label: 'Tag', name: 'tag' }],
    network: 'XRP',
  },
  {
    Icon: BNBIcon,
    shortcut: 'BNB',
    name: 'BNB',
    network: 'BSC',
  },
];

export const NETWORKS_TO_CURRENCY = {
  'BNB Smart Chain': 'bnb',
  Ethereum: 'eth',
  Polygon: 'matic',
  TRON: 'trx',
};

export const NETWORKS_TO_NETWORK_DISPLAY_NAME = {
  'BNB Smart Chain': 'BSC',
  Ethereum: 'ERC20',
  Polygon: 'Matic Network',
  Tron: 'TRC20',
};
