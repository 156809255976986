import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toastError } from 'toasts';
import { Errors } from 'types';

export const useToastError = () => {
  const { t } = useTranslation();

  return useMemo(
    () => (errors: Errors) => {
      let error = errors?.global || Object.values(errors || {})[0];

      if (!error) {
        error = 'SOMETHING_WRONG';
      }

      toastError({
        title: t(`errors.BE.${error}`),
        description: t(`errors.BE.${error}_DESCRIPTION`, ''),
      });
    },
    [t],
  );
};
