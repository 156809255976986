import { createReducer } from 'store';
import { loadHandlers, loadUserCountry } from './actions';
import { HandlerItemType, HandlerType } from './types';

const initialState: HandlerType = {
  isLoaded: false,
  isReady: false,
  handlers: {},
  handlerTypeIds: {},
  blocked: false,
  isFatalError: false,
  countryCode: '',
};

export const reducer = createReducer(initialState, {
  [loadHandlers.toString()]: (state, { payload, meta: { error } }) => {
    if (error) {
      state.isFatalError = true;
      return state;
    }

    const newState: HandlerType = {
      handlers: {},
      handlerTypeIds: {},
      isLoaded: true,
      isReady: true,
      countryCode: payload.countryCode,
      blocked: payload.block || false,
      isFatalError: false,
    };

    const handlers = payload.handlers ? payload.handlers : payload;

    for (let i = 0; i < handlers.length; i += 1) {
      const handler: HandlerItemType = handlers[i];

      newState.handlers[handler.handlerId] = { ...handler };

      if (!newState.handlerTypeIds[handler.type]) {
        newState.handlerTypeIds[handler.type] = [handler.handlerId];
      } else {
        newState.handlerTypeIds[handler.type].push(handler.handlerId);
      }
    }

    return newState;
  },
  [loadUserCountry.toString()]: (state, { payload }) => {
    state.countryCode = payload.countryCode;
    state.blocked = payload.block;
  },
});
