import { createReducer } from 'store';
import { joinTournament, loadTournaments } from './actions';
import { TournamentType, TournamentsType } from './types';

const initialState: TournamentsType = {
  list: [],
  isLoaded: false,
};

const TYPES = {
  604800: 'WEEKLY',
  86400: 'DAILY',
  2415000: 'DOWNBUTNOTOUT',
};

const keepUniqueAndSortByDate = (arr: { result: TournamentType }[]) => {
  const uniqueItems: { [key: string]: TournamentType } = {};

  arr.forEach(({ result: item }) => {
    if (!uniqueItems[item.durationInSeconds]) {
      uniqueItems[item.durationInSeconds] = item;
    } else if (
      item.roundStartDate > uniqueItems[item.durationInSeconds].roundStartDate
    ) {
      uniqueItems[item.durationInSeconds] = item;
    }
  });

  return Object.values(uniqueItems) as TournamentType[];
};

export const reducer = createReducer(initialState, {
  [loadTournaments.toString()]: (state, { payload }) => {
    state.list = keepUniqueAndSortByDate(payload.data).map(
      (item: TournamentType) => ({
        ...item,
        feType: TYPES[item.durationInSeconds as keyof typeof TYPES],
        entries: item.entries.sort((a, b) => a.rank - b.rank),
      }),
    );
    state.isLoaded = true;
  },
  [joinTournament.toString()]: (state, { meta: { roundId } }) => {
    const tournament = state.list.find(item => item.roundId === roundId);
    if (tournament) {
      tournament.joined = true;
    }
  },
});
