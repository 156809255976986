import { createIcon } from '@chakra-ui/icons';

export const ReplyIcon = createIcon({
  displayName: 'ReplyIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="m.467 8.075 5.873 4.85c.395.329.955.37 1.339.164.442-.203.732-.657.732-1.137v-1.705a6.92 6.92 0 0 1 5.355 4.685.948.948 0 0 0 .896.657.97.97 0 0 0 .91-.644c1.701-5.412-1.867-10.767-7.161-11.53v-1.15c0-.492-.29-.934-.732-1.149a1.263 1.263 0 0 0-1.34.177L.468 6.13a1.246 1.246 0 0 0 0 1.945Z"
    />
  ),
});
