import {
  JACKPOT_HIGH_HANDLER_ID,
  JACKPOT_LOW_HANDLER_ID,
  JACKPOT_MEDIUM_HANDLER_ID,
  ROULETTE_HANDLER_ID,
  SETTINGS_ENUM,
  VERSUS_HANDLER_ID,
  WALLET_HANDLER_ID,
} from 'constants/general';
import { trackEvent } from 'helpers/analytics';
import { isFlagChecked } from 'helpers/bit';
import { formatCredit } from 'helpers/numeral';
import { WS_HANDLER_MESSAGE, WS_MESSAGE } from 'helpers/ws';
import {
  ProfileIcon,
  QuestsIcon,
  SettingsIcon,
  VerifiedIcon,
  WalletIcon,
} from 'icons';
import { chatWebsocket, getFriendById } from 'modules/chat';
import { Translation } from 'react-i18next';
import { Middleware } from 'redux';
import {
  LEVEL_NAMES,
  getUserLevel,
  getUserSettingsFlags,
  userWebsocket,
} from 'services/user/modules/user';
import { toastError, toastInfo, toastSuccess } from 'toasts';

const originalMessages = {
  [ROULETTE_HANDLER_ID]: 'toasts.won.roulette',
  [VERSUS_HANDLER_ID]: 'toasts.won.versus',
  [JACKPOT_HIGH_HANDLER_ID]: 'toasts.won.jackpot',
  [JACKPOT_MEDIUM_HANDLER_ID]: 'toasts.won.jackpot',
  [JACKPOT_LOW_HANDLER_ID]: 'toasts.won.jackpot',
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const toastsMiddleware: Middleware = store => next => (action: any) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case WS_MESSAGE(chatWebsocket, 'CHAT_SEND_FRIEND_INVITE'):
      if (
        !isFlagChecked(
          getUserSettingsFlags(store.getState()),
          SETTINGS_ENUM.ONLINE_FRIENDS,
        )
      ) {
        toastInfo({
          title: (
            <Translation>{t => t('friends.request-received')}</Translation>
          ),
          description: (
            <Translation>
              {t =>
                t('friends.request-received.desc', {
                  name: action.payload.user.name,
                })
              }
            </Translation>
          ),
          image: action.payload.user.icon,
          name: action.payload.user.name,
        });
      }

      break;
    case WS_MESSAGE(chatWebsocket, 'CHAT_ACCEPT_FRIEND_INVITE'):
      if (
        !isFlagChecked(
          getUserSettingsFlags(store.getState()),
          SETTINGS_ENUM.ONLINE_FRIENDS,
        )
      ) {
        toastSuccess({
          title: (
            <Translation>{t => t('friends-request-accepted')}</Translation>
          ),
          description: (
            <Translation>
              {t =>
                t('friends-request-accepted.desc', {
                  name: action.payload.user.name,
                })
              }
            </Translation>
          ),
          icon: action.payload.user.icon,
          name: action.payload.user.name,
        });
      }
      break;
    case WS_MESSAGE(userWebsocket, 'LEVEL'):
      if (action.payload?.level?.level > getUserLevel(store.getState())) {
        toastSuccess({
          title: <Translation>{t => t('toasts.level-up')}</Translation>,
          description: (
            <Translation>
              {t =>
                t('toasts.level-up.desc', {
                  level:
                    LEVEL_NAMES[
                      action.payload?.level?.level as keyof typeof LEVEL_NAMES
                    ],
                })
              }
            </Translation>
          ),
          iconNode: <ProfileIcon color="vanilla-text" />,
        });
      }
      break;
    case WS_HANDLER_MESSAGE(userWebsocket, 'TIP', 'TIP'):
      toastSuccess({
        title: <Translation>{t => t('toasts.tip-received')}</Translation>,
        description: (
          <Translation>
            {t =>
              t('toasts.tip-received.desc', {
                name: action.payload.user.name,
                amount: formatCredit(action.payload.totalAmount),
              })
            }
          </Translation>
        ),
        icon: action.payload.user.icon,
        name: action.payload.user.name,
      });
      break;
    case WS_MESSAGE(chatWebsocket, 'FRIEND-ONLINE'):
      if (
        action.payload.status &&
        !isFlagChecked(
          getUserSettingsFlags(store.getState()),
          SETTINGS_ENUM.ONLINE_FRIENDS,
        )
      ) {
        const friend = getFriendById(action.payload.id)(store.getState());
        if (friend && !friend.online) {
          toastSuccess({
            title: <Translation>{t => t('friends.is-online')}</Translation>,
            description: (
              <Translation>
                {t => t('friends.is-online-desc', { name: friend.user.name })}
              </Translation>
            ),
            icon: friend.user.icon,
            name: friend.user.name,
          });
        }
      }
      break;
    case WS_MESSAGE(userWebsocket, 'INVENTORY'):
      if (
        action.payload.creditChange > 0 &&
        [
          JACKPOT_LOW_HANDLER_ID,
          JACKPOT_MEDIUM_HANDLER_ID,
          JACKPOT_HIGH_HANDLER_ID,
          VERSUS_HANDLER_ID,
          ROULETTE_HANDLER_ID,
        ].includes(action.payload.handlerId)
      ) {
        toastSuccess({
          title: (
            <Translation>
              {t =>
                t('toasts.won', {
                  amount: formatCredit(action.payload.creditChange),
                })
              }
            </Translation>
          ),
          description: (
            <Translation>
              {t =>
                t(
                  originalMessages[
                    action.payload.handlerId as keyof typeof originalMessages
                  ],
                )
              }
            </Translation>
          ),
          iconNode: <WalletIcon color="vanilla-text" />,
        });
      }
      if (
        action.payload.creditChange > 0 &&
        action.payload.handlerId === WALLET_HANDLER_ID
      ) {
        toastSuccess({
          title: <Translation>{t => t('toasts.deposit-credit')}</Translation>,
          description: (
            <Translation>
              {t =>
                t('toasts.deposit-credit.desc', {
                  amount: formatCredit(action.payload.creditChange),
                })
              }
            </Translation>
          ),
          iconNode: <WalletIcon color="vanilla-text" />,
        });
        trackEvent('deposit', { amount: action.payload.creditChange });
        window.coinzilla_performance.push({ event: 'sale' });
        fetch(`
        https://ads.trafficjunky.net/tj_ads_pt?a=1000396061&member_id=1004977441&cb=${Date.now()}&cti=${Date.now()}&ctv=${
          action.payload.creditChange
        }&ctd=USD`);
      }
      break;
    case WS_HANDLER_MESSAGE(userWebsocket, 'WALLETSERVICE', 'DEPOSIT'):
      if (action.payload.status === 'CONFIRMING') {
        toastSuccess({
          title: <Translation>{t => t('toasts.deposit-confirm')}</Translation>,
          description: (
            <Translation>
              {t =>
                t('toasts.deposit-confirm.desc', {
                  amount: formatCredit(action.payload.amountUsd),
                })
              }
            </Translation>
          ),

          iconNode: <WalletIcon color="vanilla-text" />,
        });
      }
      break;

    case WS_MESSAGE(userWebsocket, 'QUEST'):
      toastSuccess({
        title: <Translation>{t => t('toasts.quest')}</Translation>,
        description: (
          <Translation>
            {t =>
              t('toasts.quest.desc', {
                multiplier: action.payload.multiplier,
                bet: formatCredit(action.payload.minBetAmount),
                won: formatCredit(action.payload.prizeAmount),
              })
            }
          </Translation>
        ),
        iconNode: <QuestsIcon color="vanilla-text" />,
      });
      break;
    case WS_MESSAGE(userWebsocket, 'ERROR'):
      toastError({
        title: (
          <Translation>
            {t => t(action.payload?.errorCodes?.global, 'ERROR')}
          </Translation>
        ),
        description: (
          <Translation>
            {t => t(`${action.payload?.errorCodes?.global}_DESCRIPTION`, '')}
          </Translation>
        ),
        iconNode: <SettingsIcon color="vanilla-text" />,
      });

      break;
    case WS_MESSAGE(userWebsocket, 'kyc-status-update'):
      if (action.payload === 3) {
        toastSuccess({
          title: <Translation>{t => t('toasts.verification')}</Translation>,
          description: '',
          iconNode: <VerifiedIcon color="vanilla-text" />,
        });
      }
      break;
  }
  return next(action);
};
