import { createIcon } from '@chakra-ui/icons';

export const StatisticsIcon = createIcon({
  displayName: 'StatisticsIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 2.5a2 2 0 1 1 4 0v11a2 2 0 1 1-4 0v-11Zm-6 3a2 2 0 1 1 4 0v8a2 2 0 1 1-4 0v-8Zm14 2a2 2 0 0 0-2 2v4a2 2 0 1 0 4 0v-4a2 2 0 0 0-2-2Z"
      clipRule="evenodd"
    />
  ),
});
