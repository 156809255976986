import { createIcon } from '@chakra-ui/icons';

export const CalculatorIcon = createIcon({
  displayName: 'CalculatorIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6 2.5C6 1.39543 6.89543 0.5 8 0.5C9.10457 0.5 10 1.39543 10 2.5V13.5C10 14.6046 9.10457 15.5 8 15.5C6.89543 15.5 6 14.6046 6 13.5V2.5ZM0 5.5C0 4.39543 0.895431 3.5 2 3.5C3.10457 3.5 4 4.39543 4 5.5V13.5C4 14.6046 3.10457 15.5 2 15.5C0.895431 15.5 0 14.6046 0 13.5V5.5ZM14 7.5C12.8954 7.5 12 8.39543 12 9.5V13.5C12 14.6046 12.8954 15.5 14 15.5C15.1046 15.5 16 14.6046 16 13.5V9.5C16 8.39543 15.1046 7.5 14 7.5Z"
      fill="currentColor"
    />
  ),
});
