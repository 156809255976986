import { Box, Text } from '@chakra-ui/react';
import { IS_PRERENDER } from 'constants/general';
import { getImageLoaded, setImageLoaded } from 'helpers/image';
import { imageBuilder } from 'helpers/sanity';
import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ImageFallback } from 'uikit/image-fallback';
import { Link } from 'uikit/link';
import { Skeleton } from 'uikit/skeleton';

export const BlogPreview = ({
  mainImage,
  slug: randomPostSlug,
  description,
  title,
  isSkeleton = false,
  className,
  prefix = '/blog',
  style,
  isPromoSlider = false, // Add this line
}) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);
  const url =
    mainImage && `${imageBuilder.image(mainImage)}?q=80&auto=format&w=1000`;
  return (
    <Box h="full" className={className} style={style} p="1">
      {isSkeleton ? (
        <ImageFallback type="post" rounded="xl" />
      ) : (
        mainImage && (
          <Link
            to={`${prefix}/${randomPostSlug}`}
            rounded="xl"
            transitionProperty="all"
            transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
            transitionDuration=".15s"
            _hover={{
              transform: 'translateY(-0.125rem)',
              opacity: '0.9',
            }}
            _active={{
              transform: 'translateY(0px)',
            }}
            overflow="hidden"
            display="flex"
            pos="relative"
          >
            <LazyLoadImage
              threshold={100}
              visibleByDefault={IS_PRERENDER || getImageLoaded(url)}
              effect="blur"
              src={url}
              alt={title}
              draggable="false"
              placeholder={<ImageFallback type="post" rounded="xl" />}
              onLoad={() => {
                setImageLoaded(url);
                setIsImageLoaded(true);
              }}
              width="100%"
              style={
                isImageLoaded
                  ? {}
                  : {
                      position: 'absolute',
                      inset: 0,
                    }
              }
            />
            {!isImageLoaded && <ImageFallback type="post" rounded="xl" />}
          </Link>
        )
      )}
      {!isPromoSlider && ( // Add this condition
        <Box mt="2" flexGrow="1">
          <Skeleton isLoaded={!isSkeleton} rounded="xl">
            <Link
              to={`${prefix}/${randomPostSlug}`}
              fontWeight="500"
              fontSize={{ base: 'lg', md: 'xl' }}
              color="vanilla-text"
              noOfLines="1"
            >
              {title || '...'}
            </Link>
          </Skeleton>
          <Skeleton isLoaded={!isSkeleton} rounded="xl" h="42px">
            <Text noOfLines="2" color="candy-floss-text">
              {description || '...'}
            </Text>
          </Skeleton>
        </Box>
      )}
    </Box>
  );
};
