export const isLocalhost = () => window.location.hostname === 'localhost';

export const isDevSite = () =>
  window.location.hostname === 'dev.sherbet.com' || isLocalhost();

export const isSafari = () =>
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1;

export const isWindows = () => navigator.platform.indexOf('Win') > -1;

const isUrlAbsoluteRegex = /^(?:[a-z]+:)?\/\//i;

const isUrlExternalRegex = /^https?:\/\/(www\.)?(.*)?\/?(.)*/;

export const isUrlExternal = (url: string) => isUrlExternalRegex.test(url);

export const isUrlRelative = (url: string) => !isUrlAbsoluteRegex.test(url);

export const getFunctioningUrl = (
  relativeOrAbsoluteUrl: string,
  protocol = 'https:',
) => {
  const isRelativeLink = isUrlRelative(relativeOrAbsoluteUrl);
  let url;

  if (isRelativeLink) {
    let host = window.location.hostname;

    if (host === 'localhost') {
      host = 'dev.sherbet.com';
    } else if (host === 'staging.sherbet.com') {
      host = 'sherbet.com';
    }

    url = `${protocol}//${host}${relativeOrAbsoluteUrl}`;
  } else {
    url = relativeOrAbsoluteUrl;
  }

  return url;
};
