import { Box, Flex, Text } from '@chakra-ui/react';
import { CountdownExpire } from 'components/countdown-expire';
import { useTranslation } from 'react-i18next';
import { NotificationTip } from 'uikit/notification-tip';

export const ReloadSundayCard = ({ bonus }) => {
  const { t } = useTranslation();
  return (
    <Flex
      flexDirection="column"
      // ml={isActivated ? '0' : '-5'}
      // borderLeft={isActivated ? 'none' : '1px solid'}
      // borderColor={isActivated ? 'transparent' : 'sherbet-green.200'}
      // paddingLeft={isActivated ? '0' : '19px'}
    >
      <Flex flexDirection="column">
        <Box
          bgColor="sugar-dust"
          bgImage="https://i.imgur.com/xYA1aZJ.png"
          bgPos="top"
          bgSize="cover"
          rounded="xl"
          w="full"
          h="32"
          pos="relative"
          mb="2"
        >
          <NotificationTip
            notifications={t('bonus-popup.deposit-bonus')}
            bg="rgb(0, 131, 138)"
            position="absolute"
            top="3"
            color="vanilla-text"
            left="3"
          />
        </Box>
        <Flex align="center" justifyContent="space-between" mb="1">
          <Text color="candy-floss-text">
            {t('bonus.expire-in')} <CountdownExpire endTime={bonus.endTime} /> •
            Wagers: {bonus.wager}
          </Text>
        </Flex>
        <Text mb="1" fontWeight="500">
          {t('bonus.reload-sundae')}
        </Text>
        <Text color="candy-floss-text">
          {bonus?.description || t('bonus.reload-sundae-desc')}. It has a
          minimum deposit requirement of{' '}
          {bonus.minDeposit === 0 ? 10 : bonus.minDeposit}, and a maximum of{' '}
          {bonus.maxBonus}.
        </Text>
      </Flex>
      <Box w="full" mt="3">
        <Flex align="center" gap="2">
          {bonus.action}
        </Flex>
      </Box>
    </Flex>
  );
};
