import { LiveCasinoIcon } from 'icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GameSlider } from 'services/games/components/game-slider';
import {
  getSlotsIsLoaded,
  getSlotsLiveCasinoGames,
} from 'services/games/modules/slots';

export const LiveCasinoSlider = () => {
  const games = useSelector(getSlotsLiveCasinoGames);
  const { t } = useTranslation();
  const isLoaded = useSelector(getSlotsIsLoaded);

  return (
    <GameSlider
      icon={<LiveCasinoIcon color="candy-floss-text" />}
      isLoaded={isLoaded}
      titleLink="/casino/category/live-casino"
      slides={games}
      title={t('casino.slider.live.title')}
    />
  );
};
