import { langForContent } from 'helpers/lang';
import { sanityClient } from 'helpers/sanity';

export const loadPosts = (lang: string) =>
  sanityClient.fetch(
    `*[_type == "post" && lang == $lang] {
    'authorName': author->name,
    'authorImage': author->image,
    'authorSlug': author->slug.current,
    'tags': tags[] {
      _type == 'reference' => @->,
      _type != 'reference' => @
    },
    'slug': slug.current,
    description,
    title,
    mainImage,
    publishedAt,
    endAt,
    _id
  }`,
    { lang: langForContent(lang) },
  );

export const loadPost = (slug: string, lang: string) =>
  sanityClient.fetch(
    `*[_type == "post" && lang == $lang && slug.current == $slug] {
  'authorName': author->name,
  'authorImage': author->image,
  'authorSlug': author->slug.current,
  body,
  'tags': tags[] {
    _type == 'reference' => @->,
    _type != 'reference' => @
  },
  'slug': slug.current,
  description,
  title,
  mainImage,
  publishedAt,
  endAt,
  _id
}`,
    { lang: langForContent(lang), slug },
  );
