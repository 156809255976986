import { Box } from '@chakra-ui/react';
import { ShowMore } from 'components/show-more';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { loadRouletteHistory } from 'services/games/modules/roulette';
import { useActions } from 'store';
import { Link } from 'uikit/link';
import { Table } from 'uikit/table';

type ListType = {
  gameId: string;
  timestamp: string;
  hash: string;
  bonusNumber: string;
  ticket: string;
  details: {
    serverSecret: string;
    serverSalt: string;
  };
  complete: {
    roll: string;
    rollNumber: number;
    bonusRoll: string;
    bonusRollNumber: number;
  };
}[];

export const RouletteHistory = () => {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [list, setList] = useState<ListType>([]);
  const actions = useActions({ loadRouletteHistory });
  const { t } = useTranslation();

  const fields = [
    {
      key: 'id',
      title: 'ID',
      textAlign: 'left' as const,
    },
    {
      key: 'hash',
      title: t('roulette.hash'),
      textAlign: 'center' as const,
    },
    {
      key: 'bonus',
      title: t('roulette.bonus'),
      textAlign: 'center' as const,
    },
    {
      key: 'ticketId',
      title: t('roulette.ticket-id'),
      textAlign: 'center' as const,
    },
    {
      key: 'seed',
      title: t('roulette.seed'),
      textAlign: 'center' as const,
    },
    {
      key: 'salt',
      title: t('roulette.salt'),
      textAlign: 'center' as const,
    },
    {
      key: 'roll',
      title: t('roulette.roll'),
      textAlign: 'center' as const,
    },
    {
      key: 'bonusRoll',
      title: t('roulette.bonus-roll'),
      textAlign: 'right' as const,
    },
  ];

  useEffect(() => {
    setIsLoaded(false);
    actions.loadRouletteHistory().then((payload: { data: ListType }) => {
      setList(payload.data);
      setIsLoaded(true);
    });
  }, [actions]);

  const loadMore = async () => {
    setIsLoading(true);
    try {
      const { data } = await actions.loadRouletteHistory({
        id: list.at(-1)?.gameId,
        before: list.at(-1)?.timestamp,
      });
      setList(prev => prev.concat(data));
    } finally {
      setIsLoading(false);
    }
  };

  const data = list.map(item => ({
    id: item.gameId,
    hash: item.hash,
    bonus: item.bonusNumber,
    ticketId: (
      <Link href={`https://api.random.org/tickets/form?ticket=${item.ticket}`}>
        {item.ticket}
      </Link>
    ),
    seed: item.details.serverSecret,
    salt: item.details.serverSalt,
    roll: item.complete
      ? `${item.complete.roll} (${item.complete.rollNumber})`
      : '-',
    bonusRoll: item.complete
      ? `${item.complete.bonusRoll} (${item.complete.bonusRollNumber})`
      : '-',
  }));

  return (
    <Box>
      <Table fields={fields} data={data} isLoading={!isLoaded} />
      <ShowMore
        current={data.length}
        total={Infinity}
        onClick={loadMore}
        isLoading={isLoading}
      />
    </Box>
  );
};
