import { createReducer } from 'store';

import {
  changeSeedPairAction,
  clearActiveGameAction,
  eatCookieAction,
  endGameAction,
  getActiveGameAction,
  getMultipliersAction,
  getSeedsAction,
  startGameAction,
} from './actions';
import {
  CasinoSeedPair,
  CookieCrashGame,
  CookieCrashMultiplier,
  CookieCrashType,
} from './types';

const initialState: CookieCrashType = {
  seedPair: null,
  isMultipliersLoaded: false,
  multipliers: [],
  activeGame: null,
};

export const reducer = createReducer(initialState, {
  [getSeedsAction.toString()]: (
    state,
    { payload }: { payload: CasinoSeedPair },
  ) => {
    state.seedPair = payload;
  },
  [changeSeedPairAction.toString()]: (
    state,
    { payload }: { payload: CasinoSeedPair },
  ) => {
    state.seedPair = payload;
  },
  [getMultipliersAction.toString()]: (
    state,
    { payload }: { payload: CookieCrashMultiplier[] },
  ) => {
    state.isMultipliersLoaded = true;
    state.multipliers = [...payload];
  },
  [startGameAction.toString()]: (
    state,
    { payload }: { payload: CookieCrashGame },
  ) => {
    state.activeGame = payload;
  },
  [getActiveGameAction.toString()]: (
    state,
    { payload }: { payload: CookieCrashGame },
  ) => {
    state.activeGame = payload;
  },
  [clearActiveGameAction.toString()]: state => {
    state.activeGame = null;
  },
  [eatCookieAction.toString()]: (
    state,
    { payload }: { payload: CookieCrashGame },
  ) => {
    state.activeGame = {
      ...state.activeGame,
      ...payload,
    };
  },
  [endGameAction.toString()]: (
    state,
    { payload }: { payload: CookieCrashGame },
  ) => {
    state.activeGame = {
      ...state.activeGame,
      ...payload,
    };
  },
});
