import { createIcon } from '@chakra-ui/icons';

export const PreloaderIcon = createIcon({
  displayName: 'PreloaderIcon',
  viewBox: '0 0 44 36',
  path: (
    <>
      <path
        d="M21.8509 16.2597C22.1429 16.1649 22.3138 15.8632 22.2463 15.564C22.1459 15.1205 21.982 14.4549 21.765 13.7874C19.9047 8.06275 14.922 2.9804 7.53819 5.32318C0.472975 7.56528 -1.06597 14.6955 0.631043 19.9175C0.813667 20.4794 1.20408 21.5581 1.52206 22.2995C1.64269 22.5806 1.95882 22.7221 2.24979 22.6276L21.8509 16.2597ZM9.2128 10.4289C13.0602 9.1789 14.4143 13.108 14.4143 13.108L6.34576 15.7294C6.34576 15.7294 5.36544 11.6788 9.2128 10.4289Z"
        fill="currentColor"
      />
      <path
        d="M43.4897 8.96826L37.5301 8.13075L38.4635 1.49035C38.5091 1.16596 38.2831 0.865828 37.9583 0.820426L32.3692 0.0351084C32.0447 -0.0103216 31.7447 0.215536 31.6988 0.539926L30.7654 7.18032L24.8703 6.35205C24.5459 6.30662 24.2457 6.53251 24.2003 6.85687L23.6153 11.0179C23.5695 11.3423 23.7957 11.6425 24.1201 11.6879L30.0155 12.5162L29.0819 19.1566C29.0362 19.481 29.2625 19.7811 29.5868 19.8268L35.1764 20.6121C35.5009 20.6579 35.8009 20.4317 35.8466 20.1073L36.7802 13.4669L42.7395 14.3044C43.064 14.3501 43.364 14.1239 43.4094 13.7996L43.9946 9.63849C44.0402 9.3141 43.8139 9.01367 43.4897 8.96826Z"
        fill="currentColor"
      />
      <path
        d="M21.5948 35.9707C15.4568 35.9707 9.68775 33.0535 6.0402 28.1362C5.84069 27.8675 5.90452 27.4858 6.17954 27.295L9.71629 24.8409C9.97779 24.6597 10.3351 24.7174 10.5269 24.9712C13.1453 28.4327 17.2407 30.4828 21.5948 30.4828C25.9489 30.4828 30.0442 28.4329 32.6628 24.9712C32.8547 24.7174 33.2117 24.6597 33.4732 24.8409L37.01 27.295C37.285 27.4858 37.349 27.8675 37.1494 28.1362C33.5018 33.0532 27.7328 35.9707 21.5948 35.9707Z"
        fill="currentColor"
      />
    </>
  ),
});
