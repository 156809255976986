import { createAction } from 'store';
import * as api from './api';

export const loadLiveWagers = createAction(
  'wagers/loadLiveWagers',
  (type: string, userId: string) => () =>
    api.loadLiveWagers(type === 'individual' ? userId : type),
  type => ({ type, _error: true }),
);

export const loadWagerTotal = createAction(
  'wagers/loadWagerTotal',
  () => () => api.loadWagerTotal(),
);
