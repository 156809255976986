import { DEFAULT_LANG, LANGS } from 'constants/general';
import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-chained-backend';
import LocalStorageBackend from 'i18next-localstorage-backend';
import LocizeBackend from 'i18next-locize-backend';
import { initReactI18next } from 'react-i18next';

i18n
  .use(Backend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: DEFAULT_LANG,
    supportedLngs: LANGS,
    interpolation: {
      escapeValue: false,
    },
    detection: {
      caches: ['localStorage'],
    },
    backend: {
      backends: [
        LocalStorageBackend, // primary
        LocizeBackend, // fallback
      ],
      backendOptions: [
        {},
        {
          projectId: '37655c02-59bc-489b-bc3b-2e0a685ae2ae',
          apiKey:
            process.env.NODE_ENV === 'production'
              ? undefined
              : 'df07b6a0-eff6-4edf-b582-bd9832df510c',
          referenceLng: DEFAULT_LANG,
        },
      ],
      cacheHitMode: 'refresh',
    },
  });

export default i18n;
