import { createIcon } from '@chakra-ui/icons';

export const VIPIcon = createIcon({
  displayName: 'VIPIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.474 1.524a2.53 2.53 0 0 0-.487-.839C8.772.455 8.457.25 8.055.25c-.401 0-.717.205-.931.435-.212.228-.37.522-.488.839L5.406 4.8a.245.245 0 0 1-.011.024.247.247 0 0 1-.026-.015L2.65 2.864c-.287-.206-.586-.372-.884-.453-.289-.08-.704-.108-1.063.161-.36.27-.447.678-.452.978-.004.31.072.644.191.978a.75.75 0 0 0 .012.032l2.89 7.086c.114.307.33.545.555.704.229.162.532.288.866.288h6.575c.333 0 .635-.126.86-.281.224-.154.444-.386.563-.685l2.825-6.908a.688.688 0 0 0 .013-.034c.127-.361.207-.718.202-1.044-.005-.318-.098-.737-.464-1.014-.367-.277-.796-.252-1.104-.168-.315.085-.635.261-.946.484l-.001.001-2.556 1.823a.245.245 0 0 1-.017.01.248.248 0 0 1-.01-.021l-1.23-3.277Zm-4.171 7.88a1 1 0 0 1 1-1H9.8a1 1 0 0 1 0 2H6.303a1 1 0 0 1-1-1ZM4.204 14a1 1 0 1 0 0 2H11.9a1 1 0 0 0 0-2H4.204Z"
      clipRule="evenodd"
    />
  ),
});
