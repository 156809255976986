import { createIcon } from '@chakra-ui/icons';

export const ProfileIcon = createIcon({
  displayName: 'ProfileIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.76 5.975a3.5 3.5 0 1 0-4.949-4.95 3.5 3.5 0 0 0 4.95 4.95Zm2.28 4.842C11.97 9.745 9.517 9.143 8 9.143s-3.969.602-5.04 1.674a5.714 5.714 0 0 0-1.674 4.04c0 .631.511 1.143 1.143 1.143H13.57c.632 0 1.143-.512 1.143-1.143a5.714 5.714 0 0 0-1.673-4.04Z"
      clipRule="evenodd"
    />
  ),
});
