import { RouterTabs } from 'components/router-tabs';
import {
  CalculatorIcon,
  EyeCloseIcon,
  InfoIcon,
  ProfileIcon,
  ResponsibleGamblingIcon,
} from 'icons';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { ContentPage } from 'services/cms/components/content-page';
import { getNftIsLoaded, loadNfts } from 'services/nft/modules/nft';

import { useActions } from 'store';

export const SelfAssessment: React.FC = () => {
  const isLoaded = useSelector(getNftIsLoaded);
  const actions = useActions({ loadNfts });

  useEffect(() => {
    if (!isLoaded) {
      actions.loadNfts();
    }
  }, [isLoaded, actions]);

  return (
    <>
      <RouterTabs
        tabs={[
          {
            children: 'Sherbet Safe',
            to: '/responsible-gambling/sherbet-safe',
            Icon: ResponsibleGamblingIcon,
          },
          {
            children: 'FAQ',
            to: '/responsible-gambling/faq',
            Icon: InfoIcon,
          },
          {
            children: 'Recognizing Signs',
            Icon: EyeCloseIcon,
            to: '/responsible-gambling/recognizing-signs',
          },
          {
            children: 'Self-Assessment',
            Icon: ProfileIcon,
            to: '/responsible-gambling/self-assessment',
          },
          {
            children: 'Budget Calculator',
            Icon: CalculatorIcon,
            to: '/responsible-gambling/budget-calculator',
          },
        ]}
      />
      <ContentPage type="self-assessment" title="Self Assessment" />
    </>
  );
};
