import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { Howl } from 'howler';
import { useTranslation } from 'react-i18next';
import { VERSUS_COLORS } from 'services/games/pages/versus/constants';

const colorChangeSound = new Howl({
  src: ['/assets/audio/bet-change.wav'],
});

export const ColorSelect = ({ colorIdx, setColorIdx, playerCount }) => {
  const { t } = useTranslation();
  return (
    <Flex flexDirection="column" gap="2" w="full">
      <Text color="candy-floss-text" fontWeight="500">
        {t('versus.color')}
      </Text>
      <Flex gap="2" w="full">
        {VERSUS_COLORS.slice(0, playerCount).map((currentColor, i) => (
          <Box key={currentColor} w="full">
            <Button
              w="full"
              rounded="full"
              bg={currentColor}
              colorScheme="custom"
              opacity={i === colorIdx ? 1 : 0.5}
              _hover={{ bg: currentColor, opacity: i === colorIdx ? 1 : 0.7 }}
              _focus={{ bg: currentColor }}
              aria-label={`Select color ${currentColor}`}
              onClick={() => {
                setColorIdx(i);
                colorChangeSound.play();
              }}
            />
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};
