import { createIcon } from '@chakra-ui/icons';

export const HeartIcon = createIcon({
  displayName: 'HeartIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M11.84.8a4.018 4.018 0 0 0-2.312.744A4.336 4.336 0 0 0 8 3.514a4.336 4.336 0 0 0-1.53-1.972A4.017 4.017 0 0 0 4.155.8C1.86.8 0 3.205 0 5.622 0 10.905 6.933 15.2 8 15.2c1.067 0 8-4.295 8-9.578C16 3.205 14.139.8 11.84.8Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </>
  ),
});
