import { isLocalhost } from 'helpers/env';
import {
  DEFlagIcon,
  ENFlagIcon,
  ESFlagIcon,
  FRFlagIcon,
  JAFlagIcon,
  NNFlagIcon,
  PTFlagIcon,
} from 'icons';

export const IS_PRERENDER = /(Prerender)/i.test(window.navigator.userAgent);

export const IS_PIP_AVAILABLE =
  'documentPictureInPicture' in window && !isLocalhost();

export const GET_IS_TELEGRAM = () => !!window.Telegram?.WebApp?.initData;

export const JACKPOT_LOW_HANDLER_ID = 2;
export const JACKPOT_MEDIUM_HANDLER_ID = 3;
export const JACKPOT_HIGH_HANDLER_ID = 4;

export const JACKPOT_HANDLER_IDS = [
  JACKPOT_LOW_HANDLER_ID,
  JACKPOT_MEDIUM_HANDLER_ID,
  JACKPOT_HIGH_HANDLER_ID,
];

export const VERSUS_HANDLER_ID = 5;

export const TIP_HANDLER_ID = 9;

export const COUPON_HANDLER_ID = 10;

export const WALLET_HANDLER_ID = 11;

export const ROULETTE_HANDLER_ID = 16;

export const NFT_HANDLER_ID = 19;

export const SLOTS_HANDLER_ID = 20;

export const SPORTS_HANDLER_ID = 21;

export const INTERCOM_APP_ID = 'yv4qir2y';

export const GHOST_USER = {
  icon: 'https://spinkick.imgix.net/avatars/ghost-mode.png',
  isGhost: true,
  badges: [],
  chatColor: undefined,
  nftId: undefined,
};

// up to 64
export const SETTINGS_ENUM = {
  HIDE_2FA: 0,
  EXTRA_GAME_INFO: 2,
  BET_CONFIRM: 3,
  ROULETTE_COLOURBLIND: 4,
  ROULETTE_CLASSIC: 5,
  BIG_WINS: 14,
  STAT_PRIVACY: 15,
  GHOST_MODE: 16,
  ONLINE_FRIENDS: 17,
};

export const EMAIL_CONFIRMATION_LINK = `/user/settings?confirmemail=true`;

export const LANGS = ['en', 'ja', 'pt', 'de', 'nn', 'fr', 'es'];
export const URL_LANGS = LANGS.slice(1);
export const DEFAULT_LANG = 'en';

export const LANGS_LIST = [
  {
    value: 'en',
    title: 'English',
    icon: ENFlagIcon,
  },
  {
    value: 'ja',
    title: '日本語',
    icon: JAFlagIcon,
  },
  {
    value: 'pt',
    title: 'Português',
    icon: PTFlagIcon,
  },
  {
    value: 'de',
    title: 'Deutsch',
    icon: DEFlagIcon,
  },
  {
    value: 'nn',
    title: 'Norsk',
    icon: NNFlagIcon,
  },
  {
    value: 'fr',
    title: 'Français',
    icon: FRFlagIcon,
  },
  {
    value: 'es',
    title: 'Español',
    icon: ESFlagIcon,
  },
];

export const MIN_WAGER_CHAT = 50;

export const DISCORD_ID = '905686816567492618';

export const MONDAY = 1689516000000;

export const IS_RIGHT_SIDEBAR_HIDE_POSSIBLE = true;

export const GOOGLE_CLIENT_ID =
  '877138191453-rur23clo1se96ic4mv7bh7apit8de9tf.apps.googleusercontent.com';

export const LOCAL_STORAGE_WALLET_LAST_SHORTCUT = 'WALLET_LAST_SHORTCUT';

export const IS_DEPOSIT_MAINTENANCE = false;
export const IS_WITHDRAWAL_MAINTENANCE = false;

export const LOCAL_STATE = 'LOCAL_STATE';
export const LOCAL_STATE_VERSION = 'LOCAL_STATE_VERSION';

export const LOCAL_VERSION = process.env.VERSION || '';
