import { CustomInputProps } from 'forms/custom-input/custom-input';
import { EyeCloseIcon, EyeIcon } from 'icons';
import React, { useState } from 'react';
import { CustomInput } from '../custom-input';

type Props = CustomInputProps & {
  onChange?: (e: React.FormEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FormEvent<HTMLInputElement>) => void;
  value: string;
  isValid?: boolean;
  errorMessage?: string;
  label?: string | React.ReactNode;
  name?: string;
  hideLabel?: boolean;
};

export const PasswordInput: React.FC<Props> = ({
  onChange,
  onBlur,
  value,
  isValid,
  errorMessage,
  label = 'form.label.password',
  name = 'password',
  hideLabel,
  ...rest
}) => {
  const [open, setOpen] = useState(false);
  return (
    <CustomInput
      rounded="xl"
      label={!hideLabel ? label : undefined}
      type={open ? 'text' : 'password'}
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      isInvalid={isValid}
      errorMessage={errorMessage}
      rightElementProps={{ onClick: () => setOpen(o => !o) }}
      rightElement={
        open ? (
          <EyeCloseIcon
            cursor="pointer"
            color="candy-floss-text"
            _hover={{ opacity: '0.9' }}
          />
        ) : (
          <EyeIcon
            cursor="pointer"
            color="candy-floss-text"
            _hover={{ opacity: '0.9' }}
          />
        )
      }
      {...rest}
    />
  );
};
