import { Box, Flex, Text } from '@chakra-ui/react';
import { PlayerRow } from 'components/player-row';
import { ROULETTE_HANDLER_ID } from 'constants/general';
import { formatCredit } from 'helpers/numeral';
import { RakebackBoostIcon } from 'icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSlotsGamesData } from 'services/games/modules/slots';
import { getUserHasMax } from 'services/user/modules/user';
import { UserType } from 'types';
import { Link } from 'uikit/link';
import { Skeleton } from 'uikit/skeleton';
import { SlotPreview } from 'uikit/slot-preview';

type Props = {
  isSkeleton?: boolean;
  style: React.CSSProperties;
  handler_Id: number;
  minBetAmount: number;
  multiplier: number;
  subGameType: string;
  prizeAmount: number;
  winner: UserType;
};

export const QuestPreview: React.FC<Props> = ({
  minBetAmount,
  multiplier,
  subGameType,
  prizeAmount,
  winner,
  handler_Id: handlerId,
  isSkeleton = false,
  style,
}) => {
  const { t } = useTranslation();
  const isMax = useSelector(getUserHasMax);
  const games = useSelector(getSlotsGamesData);
  const name = {
    [ROULETTE_HANDLER_ID]: 'roulette',
  }[handlerId];
  const gameName = subGameType || name;
  const { image, id, bg, title, provider } =
    (gameName && games[gameName]) || {};

  const Element = isSkeleton ? Box : Link;

  const adjustedPrizeAmount = isMax ? prizeAmount * 1.1 : prizeAmount;

  const elementsProps = isSkeleton
    ? {}
    : {
        to: `/casino/game/${id}`,
        display: 'block',
        allowPrevent: true,
      };

  return (
    <Box style={style} p="1">
      <Element height="100%" {...elementsProps}>
        <Box
          height="100%"
          _hover={{
            opacity: '0.75',
          }}
        >
          <SlotPreview
            isSkeleton={isSkeleton}
            hasTags={false}
            image={image}
            id={id}
            bg={bg}
            title={title}
            provider={provider}
            isLink={false}
            p="0"
          />
          <Flex
            direction="column"
            justifyContent="space-between"
            gap="2"
            mt="2"
          >
            <Skeleton isLoaded={!isSkeleton}>
              <Text color="candy-floss-text">
                {t('quests.rules', {
                  x: formatCredit(multiplier),
                  bet: formatCredit(minBetAmount),
                })}
              </Text>
            </Skeleton>
            <Skeleton isLoaded={!isSkeleton}>
              {isMax ? (
                <Flex justifyContent="space-between">
                  <Text color="candy-floss-text">{t('quests.reward')}</Text>
                  <Flex align="center" gap="1">
                    <RakebackBoostIcon
                      fontSize="sm"
                      color="sherbet-orange.200"
                    />
                    <Text fontWeight="500" color="sherbet-orange.200">
                      ${formatCredit(adjustedPrizeAmount)}
                    </Text>
                  </Flex>
                </Flex>
              ) : (
                <Flex justifyContent="space-between">
                  <Text color="candy-floss-text">{t('quests.reward')}</Text>
                  <Text fontWeight="500" color="sherbet-green.200">
                    ${formatCredit(adjustedPrizeAmount)}
                  </Text>
                </Flex>
              )}
              {winner && (
                <Flex justifyContent="space-between" mt="1">
                  <Flex align="center" gap="2">
                    <Text color="candy-floss-text">{t('quests.winner')}</Text>
                  </Flex>
                  <Flex
                    align="center"
                    gap="1"
                    onClick={e => {
                      e.stopPropagation();
                      e.preventDefault();
                    }}
                  >
                    <PlayerRow user={winner} hasLink={false} />
                  </Flex>
                </Flex>
              )}
            </Skeleton>
          </Flex>
        </Box>
      </Element>
    </Box>
  );
};
