import { Box, Flex } from '@chakra-ui/react';
import { VERSUS_HANDLER_ID } from 'constants/general';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GameLayout } from 'services/games/layout/game-layout';
import { loadGames, loadVersusHistory } from 'services/games/modules/versus';
import { BlockedSessionScreen } from 'services/user/components/blocked-session-screen';
import { getUserBonusSessionActive } from 'services/user/modules/user';
import { useActions } from 'store';
import { GamesList } from './components/games-list';
import { GameCreateForm } from './components/games-list/components/game-create-form';
import { VersusHistory } from './components/versus-history';

export const Versus = () => {
  const actions = useActions({ loadGames, loadVersusHistory });
  const isBonusSession = useSelector(getUserBonusSessionActive);

  useEffect(() => {
    actions.loadGames();
    actions.loadVersusHistory();
  }, [actions]);

  if (isBonusSession) {
    return <BlockedSessionScreen />;
  }

  return (
    <GameLayout
      hasFullScreen={false}
      gameId="versus"
      handlerId={VERSUS_HANDLER_ID}
      historyElement={<VersusHistory />}
    >
      <Flex p="5" pb="0" w="full" maxW="6xl" mx="auto">
        <Flex
          flexDirection="column"
          minW="0"
          w="full"
          alignItems="center"
          userSelect="none"
          position="relative"
          rounded="xl"
        >
          <Flex
            flexDirection={{ base: 'column-reverse', md: 'row' }}
            bgPos="center"
            bg="toffee-base"
            border="1px solid"
            borderColor="truffle-border"
            flexGrow="1"
            w="full"
            minW="300px"
            rounded="xl"
          >
            <Flex
              flexDirection="column"
              w={{ base: 'full', md: '300px' }}
              borderColor="truffle-border !important"
              borderTop={{ base: '1px solid', md: 'none' }}
              borderRight={{ base: 'none', md: '1px solid' }}
              roundedTopLeft={{ md: 'xl' }}
              bg="toffee-base"
              roundedBottomRight={{ base: 'xl', md: 'none' }}
              roundedBottomLeft="xl"
              minW="300px"
            >
              <Box p="5">
                <GameCreateForm />
              </Box>
            </Flex>
            <Flex
              flexDirection="column"
              w="full"
              minH={{ base: '280px', md: '610px' }}
              maxH="610px"
              flexGrow="1"
              userSelect="none"
              position="relative"
              overflow="auto"
              m="0 auto"
              roundedTopRight="xl"
            >
              <Flex
                flexDirection="column"
                flexGrow="1"
                w="full"
                p="5"
                position="relative"
                alignItems="center"
              >
                <GamesList />
              </Flex>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </GameLayout>
  );
};
