import { createIcon } from '@chakra-ui/icons';

export const TournamentIcon = createIcon({
  displayName: 'TournamentIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="M14.541 14h-.67l-.404-.686c0-.017-.793-1.093-1.467-1.093H4c-.673 0-1.467 1.056-1.467 1.093L2.13 14h-.67c-.369 0-.667.632-.667 1s.298.999.667.999c13.02 0 .062.002 13.082 0 .369 0 .667-.63.667-.999 0-.368-.298-1-.667-1ZM12.02.147C9.588 2.087 6.975 2.76 4.048 2.2a.667.667 0 0 0-.787.727l.914 7.573h7.54L13.101.754a.666.666 0 0 0-1.08-.607ZM8.18 8.581a1.454 1.454 0 0 1-1.453-1.46 1.453 1.453 0 1 1 2.907 0c0 .806-.647 1.46-1.453 1.46Z"
    />
  ),
});
