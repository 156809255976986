import { Box, Button, CircularProgress, Flex, Text } from '@chakra-ui/react';
import { formatCredit } from 'helpers/numeral';
import { useEffect } from 'react';
import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTournamentPrize } from 'services/games/hooks/use-tournament-prize';
import {
  getTournamentsIsLoaded,
  getTournamentsList,
  loadTournaments,
} from 'services/games/modules/tournaments';
import { TournamentType } from 'services/games/modules/tournaments/types';
import { useActions } from 'store';
import { Link } from 'uikit/link';
import { Odometer } from 'uikit/odometer';
import { Skeleton } from 'uikit/skeleton';

const feType = 'WEEKLY';

export const TournamentBanner = () => {
  const tournaments = useSelector(getTournamentsList);
  const isLoaded = useSelector(getTournamentsIsLoaded);
  const { t } = useTranslation();
  const tournament = tournaments.find(tour => tour?.feType === feType);
  const {
    roundEndDate,
    roundStartDate,
    currentPrizePool,
    initialPrizePool,
    maxPossiblePrizePool,
  } = tournament || ({} as TournamentType);

  const actualPrize = useTournamentPrize(true, {
    currentPrizePool,
    maxPossiblePrizePool,
    roundEndDate,
  });

  const actions = useActions({ loadTournaments });

  useEffect(() => {
    if (!isLoaded) {
      actions.loadTournaments();
    }
  }, [actions, isLoaded]);

  const isTournamentExist = !!tournament;

  const totalRange = maxPossiblePrizePool - initialPrizePool;
  const currentProgress = actualPrize - initialPrizePool;
  const percentage = (currentProgress / totalRange) * 100;

  const calcValue = () => {
    const start = Number(roundStartDate) * 1000;
    const end = Number(roundEndDate) * 1000;

    const now = Date.now();

    const value = 100 - 100 / ((end - start) / (now - start));

    return value;
  };

  return (
    <Box>
      <Flex gap="3">
        <Link
          to="/tournaments/weekly-race"
          w={{ base: '100%', lg: '50%' }}
          _hover={{ opacity: '0.9' }}
          rounded="xl"
        >
          <Flex
            h="172px"
            bg="sherbet-purple"
            rounded="xl"
            overflow="hidden"
            flexDir="column"
            px="5"
            py="5"
          >
            <Flex justifyContent="space-between" gap="5">
              <Box>
                <Skeleton isLoaded={isTournamentExist}>
                  <Text as="div" fontSize="xl" fontWeight="500">
                    $<Odometer value={actualPrize} />
                  </Text>
                </Skeleton>
                <Text opacity="0.75">{t('tournaments.prize-pool')}:</Text>
              </Box>
              <Skeleton isLoaded={isTournamentExist}>
                <Text fontSize="xl" fontWeight="500">
                  +{formatCredit(percentage)}%
                </Text>
              </Skeleton>
            </Flex>
            <Flex align="end" justifyContent="space-between" gap="5" mt="auto">
              <Flex align="center" gap="3">
                <Box>
                  <Skeleton isLoaded={isTournamentExist}>
                    <Text fontWeight="500" fontSize="lg">
                      {!isTournamentExist ? (
                        '...'
                      ) : (
                        <Countdown
                          date={Number(roundEndDate) * 1000}
                          key={roundEndDate}
                        />
                      )}
                    </Text>
                  </Skeleton>
                  <Text opacity="0.75">{t('tournaments.time-remaining')}:</Text>
                </Box>
              </Flex>
              <CircularProgress
                color="vanilla-text"
                trackColor="sherbet-purple"
                size="32px"
                value={calcValue()}
              />
            </Flex>
          </Flex>
        </Link>
        <Link
          display={{ base: 'none', lg: 'flex' }}
          to="/tournaments/weekly-race"
          w="full"
          rounded="xl"
          _hover={{ opacity: '0.9' }}
        >
          <Flex
            h="172px"
            flexDir="column"
            bgImage="https://spinkick.imgix.net/banners/beams.png"
            w="full"
            bgSize="cover"
            bgRepeat="no-repeat"
            bgColor="sherbet-purple"
            p="5"
            rounded="xl"
          >
            <Text fontSize="xl" fontWeight="500" mb="1">
              {t('tournaments.banner.title')}
            </Text>
            <Text opacity="0.75" maxW="md">
              {t('tournaments.banner.description')}
            </Text>
            <Box mt="auto">
              <Button
                rounded="full"
                colorScheme="custom"
                bg="white"
                _hover={{ opacity: 0.9 }}
                size="sm"
                color="sherbet-purple"
              >
                {t('actions.learn-more')}
              </Button>
            </Box>
          </Flex>
        </Link>
      </Flex>
    </Box>
  );
};
