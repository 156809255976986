import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { PreviewGrid } from 'components/preview-grid';
import { ShowMore } from 'components/show-more';
import { IS_PRERENDER } from 'constants/general';
import { useMobile } from 'hooks/use-mobile';
import { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { getSlotsIsLoaded } from 'services/games/modules/slots';
import { GameType } from 'types';
import { SlotPreview } from 'uikit/slot-preview';

type Props = {
  games: GameType[];
  isLoaded?: boolean;
  onGameClick?: (id: string) => void;
  gridSize?: 'm' | 'l' | 'xl' | 'md';
  meta?: object;
  icon?: React.ReactNode;
  title2?: React.ReactNode;
  hasHeader?: boolean;
  hasShowMore?: boolean;
  description?: string;
  titleLink?: string;
};

export const SlotsGrid: React.FC<Props> = ({
  games = [],
  isLoaded: propIsLoaded = true,
  onGameClick,
  icon,
  gridSize,
  title2,
  meta,
  hasHeader,
  hasShowMore,
  description,
}) => {
  const [page, setPage] = useState(1);
  const isMobile = useMobile();
  const isLoaded = useSelector(getSlotsIsLoaded);

  let limit = 28;

  if (IS_PRERENDER) {
    limit = 100;
  } else if (isMobile) {
    limit = 14;
  }

  const handleShowMore = () => {
    setPage(prev => prev + 1);
  };

  const list = useMemo(() => {
    const array = limit ? games.slice(0, page * limit) : games;

    return array.map(({ image, id, title, provider, bg }) => ({
      id,
      image,
      alt: title,
      title,
      provider,
      bg,
      onGameClick,
      meta,
    }));
  }, [limit, page, games, meta, onGameClick]);

  return (
    <Box>
      {hasHeader && (
        <Flex
          justify="space-between"
          align={{ base: 'center', md: 'end' }}
          mb={description ? '5' : '3'}
          gap="5"
        >
          <Box w="full">
            <Flex justifyContent="space-between" alignItems="center">
              <Flex gap="5">
                <Flex align="center" gap="2">
                  {icon}
                  <Heading
                    lineHeight="32px"
                    noOfLines={1}
                    fontWeight="500"
                    fontSize="xl"
                  >
                    {title2}
                  </Heading>
                </Flex>
              </Flex>
            </Flex>
            {description && <Text color="candy-floss-text">{description}</Text>}
          </Box>
        </Flex>
      )}

      <PreviewGrid
        Item={SlotPreview}
        isLoaded={propIsLoaded && isLoaded}
        list={list}
        size={gridSize}
      />
      {hasShowMore && (
        <ShowMore
          current={list.length}
          total={games.length}
          onClick={handleShowMore}
        />
      )}
    </Box>
  );
};
