import { createSelector } from '@reduxjs/toolkit';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { WITHDRAWAL_ASSETS } from './constants';
import { CurrenciesType } from './types';

type State = {
  currencies: CurrenciesType;
};

export const getRateIsLoaded = (state: State) => state.currencies.isRateLoaded;

export const getCurrencyByNetwork = (type: string) => (state: State) =>
  state.currencies.currenciesByNetworks[type];

export const getCurrencyByNetworks = (state: State) =>
  state.currencies.currenciesByNetworks;

export const getRateByType = (type: string) => (state: State) =>
  getCurrencyByNetwork(type)(state)?.rate;

export const getCryptoRateByType = (type: string) => (state: State) =>
  getCurrencyByNetwork(type)(state)?.cryptoRate;

export const getFeeByType = (type: string) => (state: State) =>
  getCurrencyByNetwork(type)(state)?.feeInUsd;

export const getDepositAddressByType = (type: string) => (state: State) =>
  getCurrencyByNetwork(type)(state)?.depositAddress;

export const getDepositAddressLoadingByType =
  (type: string) => (state: State) =>
    getCurrencyByNetwork(type)(state)?.depositAddressLoading;

export const getCurrencyNetworks = (currency: string, isWithdrawal?: boolean) =>
  createSelector(
    (state: State) => state.currencies.currencies[currency]?.networks,
    getCurrencyByNetworks,
    (networks, currenciesByNetworks) => {
      const list =
        networks?.map((network: string) => currenciesByNetworks[network]) || [];

      if (isWithdrawal) {
        return list.filter(
          value =>
            value.assetName && WITHDRAWAL_ASSETS.includes(value.assetName),
        );
      }

      return list;
    },
  );

export const useGetCurrencyNetworks = (
  shortcut: string,
  isWithdrawal?: boolean,
) => {
  const getCurrencyNetworksSelector = useMemo(
    () => getCurrencyNetworks(shortcut, isWithdrawal),
    [shortcut, isWithdrawal],
  );
  const networks = useSelector(getCurrencyNetworksSelector);

  return networks;
};

export const getBillingAddress = (state: State) =>
  state.currencies.billingAddress;
