import { Error } from 'components/error';

type Props = {
  isActive: boolean;
  children: React.ReactNode;
};

export const MaintenanceWrapper: React.FC<Props> = ({ isActive, children }) => {
  if (isActive && !localStorage?.test18924) {
    return (
      <Error
        title="Sherbet is down for maintenance"
        description="We will be back soon"
        hasButton={false}
      />
    );
  }

  return children;
};
