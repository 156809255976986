import { createIcon } from '@chakra-ui/icons';

export const CheckIcon = createIcon({
  displayName: 'CheckIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm3.707-9.293a1 1 0 0 0-1.414-1.414L6.875 8.71 5.707 7.543a1 1 0 0 0-1.414 1.414l1.875 1.875a1 1 0 0 0 1.414 0l4.125-4.125Z"
      clipRule="evenodd"
    />
  ),
});
