import { Flex } from '@chakra-ui/react';
import { Content } from 'components/content';
import { telegramAuth } from 'services/auth/modules/auth';
import { useActions } from 'store';
import { Step3 } from '../auth/components/step3';

type Props = {
  onClose: () => void;
};

export const AuthTelegram: React.FC<Props> = ({ onClose }) => {
  const actions = useActions({ telegramAuth });

  const onSubmit = async ({ username }: { username: string }) => {
    await actions.telegramAuth({
      displayName: username,
      // stag,
      // afdp,
    });
    onClose();
  };

  const content = <Step3 setParams={onSubmit} />;

  return (
    <Content w="full" maxW="6xl" mx="auto" flexGrow="1">
      <Flex
        mt={{ base: 'auto', lg: 'initial' }}
        p="5"
        flexDirection="column"
        minH={{
          base:
            'auto' ||
            'calc(100vh - var(--header-height) - var(--footer-height))',
          lg: 'calc(100vh - var(--header-height))',
        }}
        gap="5"
        justifyContent={{ lg: 'center' }}
      >
        {content}
      </Flex>
    </Content>
  );
};
