import React, { createContext, useContext, useState } from 'react';

interface StatsContextType {
  isStatsVisible: boolean;
  toggleStatsElement: () => void;
}

const StatsContext = createContext<StatsContextType | undefined>(undefined);

export const StatsProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const [isStatsVisible, setIsStatsVisible] = useState(false);

  const toggleStatsElement = () => {
    setIsStatsVisible(!isStatsVisible);
  };

  return (
    <StatsContext.Provider value={{ isStatsVisible, toggleStatsElement }}>
      {children}
    </StatsContext.Provider>
  );
};

export const useStats = () => {
  const context = useContext(StatsContext);
  if (context === undefined) {
    throw new Error('useStats must be used within a StatsProvider');
  }
  return context;
};
