/* eslint-disable @typescript-eslint/no-explicit-any */ // FIXME
import { Box, Flex, Image, Switch, Text, Tooltip } from '@chakra-ui/react';
import { generateUrl } from 'helpers/image';
import {
  changeTimestampVisible,
  getChatIsTimestampVisible,
} from 'modules/chat';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSelectedNft } from 'services/nft/modules/nft';
import {
  CHAT_COLORS,
  LEVEL_IMAGES,
  LEVEL_NAMES,
  MAX_CHAT_COLORS,
  changeChatColor,
  changeUserBadge,
  getUserBadge,
  getUserBadges,
  getUserChatColor,
  getUserHasMax,
  getUserId,
  getUserLevel,
  getUserUseLevelOverNFT,
  loadUserBadge,
  loadUserBadges,
  removeUserBadges,
  setUseLevelOverNft,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { toastSuccess } from 'toasts';

type Props = {
  onClose: () => void;
};

export const ChatSettings: React.FC<Props> = () => {
  const userColor = useSelector(getUserChatColor);
  const userId = useSelector(getUserId);
  const userBadge = useSelector(getUserBadge);
  const userBadges = useSelector(getUserBadges);
  const actions = useActions({
    changeChatColor,
    setUseLevelOverNft,
    changeUserBadge,
    loadUserBadges,
    loadUserBadge,
    changeTimestampVisible,
    removeUserBadges,
  });
  const selectedNft = useSelector(getSelectedNft);

  const { t } = useTranslation();
  const isUseLevelOverNFT = useSelector(getUserUseLevelOverNFT);
  const isTimestampVisible = useSelector(getChatIsTimestampVisible);
  const isMax = useSelector(getUserHasMax);
  const level = useSelector(getUserLevel);

  const handleChangeTimestampVisible = (visible: boolean) => {
    actions.changeTimestampVisible(visible);
    if (visible) {
      localStorage.setItem('CHAT_TIMESTAMPS', 'true');
    } else {
      localStorage.removeItem('CHAT_TIMESTAMPS');
    }
  };

  useEffect(() => {
    actions.loadUserBadge();
    actions.loadUserBadges();
  }, [actions]);

  return (
    <Box borderTop="1px solid" borderColor="truffle-border" bg="toffee-base">
      <Flex flexDirection="column" my="5" px="5" gap="5">
        {userBadges.length > 0 && (
          <Box>
            <Text fontWeight="500">{t('chat.badge')}</Text>
            <Text color="candy-floss-text">{t('chat.badge-description')}</Text>
            <Flex gap="2" flexWrap="wrap" mt="3">
              {[
                {
                  name: LEVEL_NAMES[level as keyof typeof LEVEL_NAMES],
                  badgeId: '',
                  imageUrl: generateUrl(
                    LEVEL_IMAGES[level as keyof typeof LEVEL_IMAGES],
                  ),
                },
              ]
                .concat(userBadges)
                .map(({ name: badge, badgeId, imageUrl }: any) => {
                  const isActive = badgeId ? userBadge === badge : !userBadge;
                  return (
                    <Tooltip
                      key={badgeId}
                      gutter={10}
                      hasArrow
                      fontSize="md"
                      rounded="md"
                      placement="top"
                      bg="vanilla-text"
                      fontWeight="500"
                      py="1"
                      px="2"
                      color="toffee-base"
                      label={badge}
                    >
                      <Box
                        cursor="pointer"
                        rounded="md"
                        onClick={() => {
                          if (badgeId) {
                            actions.changeUserBadge(
                              badge,
                              userId,
                              badgeId,
                              imageUrl,
                            );
                          } else {
                            actions.removeUserBadges();
                          }
                        }}
                        border={
                          isActive
                            ? '2px solid var(--chakra-colors-toffee-base)'
                            : undefined
                        }
                        boxShadow={
                          isActive
                            ? ' 0px 0px 0px 2px var(--chakra-colors-sherbet-green-200)'
                            : undefined
                        }
                        _hover={{
                          opacity: '0.9',
                        }}
                        h="24px"
                        w="24px"
                        overflow="hidden"
                      >
                        <Image
                          w="full"
                          src={imageUrl}
                          alt={badge}
                          draggable="false"
                        />
                      </Box>
                    </Tooltip>
                  );
                })}
            </Flex>
          </Box>
        )}
        <Box>
          <Text fontWeight="500">{t('chat.color')}</Text>
          <Text color="candy-floss-text">{t('chat.select-color')}</Text>
          <Flex gap="2" flexWrap="wrap" mt="3">
            {Object.entries(
              isMax ? { ...CHAT_COLORS, ...MAX_CHAT_COLORS } : CHAT_COLORS,
            ).map(([key, value]) => (
              <Box
                key={key}
                cursor="pointer"
                w="24px"
                h="24px"
                rounded="full"
                bg={value}
                onClick={() => actions.changeChatColor(key, userId)}
                border={
                  userColor === key
                    ? '2px solid var(--chakra-colors-toffee-base)'
                    : undefined
                }
                boxShadow={
                  userColor === key
                    ? ' 0px 0px 0px 2px var(--chakra-colors-sherbet-green-200)'
                    : undefined
                }
                _hover={{
                  opacity: '0.9',
                }}
              />
            ))}
          </Flex>
        </Box>
        {selectedNft && (
          <Flex justifyContent="space-between" align="center">
            <Text fontWeight="500">{t('chat.hide-nft')}</Text>
            <Switch
              colorScheme="purple"
              isChecked={isUseLevelOverNFT}
              onChange={async () => {
                await actions.setUseLevelOverNft(!isUseLevelOverNFT);
                toastSuccess({
                  title: t('settings.settings-updated'),
                  description: t('settings.settings-updated-description'),
                });
              }}
            />
          </Flex>
        )}
        <Flex justifyContent="space-between" align="center">
          <Text fontWeight="500">{t('chat.timestamps')}</Text>
          <Switch
            colorScheme="purple"
            isChecked={isTimestampVisible}
            onChange={() => {
              handleChangeTimestampVisible(!isTimestampVisible);
              toastSuccess({
                title: t('settings.settings-updated'),
                description: t('settings.settings-updated-description'),
              });
            }}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
