import { createIcon } from '@chakra-ui/icons';

export const TwitterIcon = createIcon({
  displayName: 'TwitterIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <g clip-path="url(#a)">
        <path
          fill="currentColor"
          d="M12.6.769h2.454l-5.36 6.126L16 15.231h-4.937l-3.867-5.056-4.425 5.056H.316L6.049 8.68 0 .769h5.063L8.558 5.39 12.601.77Zm-.86 12.994h1.36L4.323 2.16H2.865l8.875 11.603Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </>
  ),
});
