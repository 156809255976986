import { createAction } from 'store';
import * as api from './api';

export const loadTournaments = createAction(
  'tournaments/loadTournaments',
  () => () => api.loadTournaments(),
);

export const joinTournament = createAction(
  'tournaments/joinTournament',
  roundId => () => api.joinTournament(roundId),
  roundId => ({ roundId }),
);
