import { RainIcon } from 'icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GameSlider } from 'services/games/components/game-slider';
import {
  getSlotsIsLoaded,
  getSlotsNewReleaseGames,
} from 'services/games/modules/slots';

const meta = { isNewReleases: true };

export const NewReleasesSlider = () => {
  const { t } = useTranslation();
  const games = useSelector(getSlotsNewReleaseGames);
  const isLoaded = useSelector(getSlotsIsLoaded);

  return (
    <GameSlider
      icon={<RainIcon color="candy-floss-text" />}
      titleLink="/casino/category/new-releases"
      slides={games}
      isLoaded={isLoaded}
      title={t('casino.slider.new-releases.title')}
      meta={meta}
    />
  );
};
