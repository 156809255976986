import { createIcon } from '@chakra-ui/icons';

export const WalletIcon = createIcon({
  displayName: 'WalletIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M9.687 2.286c.125-.06.226-.109.311-.148.002.108.002.243.002.412V4H6.126l3.561-1.714ZM.01 7.14A1.009 1.009 0 0 1 0 7v-.111c-.001-.482-.002-.942.142-1.365a2.62 2.62 0 0 1 .607-.988c.317-.322.731-.52 1.149-.72l.098-.048L8.82.484l.034-.017a9.19 9.19 0 0 1 .735-.33c.229-.084.564-.182.945-.114.485.086.903.37 1.167.772.205.312.257.647.278.892.021.237.021.525.021.827V4c.51 0 .938.003 1.293.032.406.034.78.105 1.13.285.543.279.985.724 1.262 1.271.179.353.25.73.283 1.138.032.394.032.877.032 1.462v3.624c0 .585 0 1.069-.032 1.462-.033.409-.104.785-.283 1.138a2.898 2.898 0 0 1-1.262 1.27c-.35.18-.724.252-1.13.286-.39.032-.87.032-1.452.032H4.159c-.581 0-1.061 0-1.452-.032-.406-.034-.78-.105-1.13-.285a2.899 2.899 0 0 1-1.262-1.271c-.179-.353-.25-.73-.283-1.138C0 12.881 0 12.397 0 11.812V8.188c0-.394 0-.742.01-1.048ZM14 9.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
      clipRule="evenodd"
    />
  ),
});
