import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import { AnimationContext } from 'contexts/animation-context';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  isClosing: boolean;
  onClose: () => void;
};

export const Step6: React.FC<Props> = ({ isClosing, onClose }) => {
  const { t } = useTranslation();
  const sentences = [
    t('auth.sentence.curating'),
    t('auth.sentence.activating'),
    t('auth.sentence.getting'),
    t('auth.sentence.unlocking'),
    t('auth.sentence.preparing'),
  ];

  const [currentSentence, setCurrentSentence] = useState(sentences[0]);
  const { setIsPageAnimation } = useContext(AnimationContext);

  useEffect(() => {
    const updateSentence = () => {
      setCurrentSentence(prev => {
        const currentIndex = sentences.indexOf(prev);
        const nextIndex = (currentIndex + 1) % sentences.length;
        return sentences[nextIndex];
      });
    };

    const intervalId = setInterval(updateSentence, 1750);

    return () => clearInterval(intervalId);
  }, [sentences]);

  return (
    <AnimatePresence>
      {!isClosing && (
        <motion.div
          initial={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.75 }} // Duration of the animation in seconds
          onAnimationComplete={() => {
            setIsPageAnimation(true);
            onClose();
          }} // Callback function for animation exit complete
        >
          <Box w="full" pos="relative">
            <Flex
              flexDirection="column"
              justifyContent="start"
              w="full"
              bg="toffee-base"
              mx="auto"
            >
              <Heading fontSize="2xl" mb="2">
                {t('welcome.sherbet')}
              </Heading>
              <Text fontSize="lg" color="candy-floss-text" maxW="md" mb="5">
                {currentSentence}
              </Text>
            </Flex>
          </Box>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
