import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useSearchParams } from 'react-router-dom';
import { verifySocial } from 'services/user/modules/user';
import { useActions } from 'store';
import { toastSuccess } from 'toasts';

export const SocialRedirect = () => {
  const [searchParams] = useSearchParams();
  const actions = useActions({ verifySocial });
  const { t } = useTranslation();

  const { code } = Object.fromEntries(searchParams);

  useEffect(() => {
    if (code) {
      actions.verifySocial('discord', code);
      toastSuccess({
        title: t('settings.discord-connected'),
        description: t('settings.discord-connected-description'),
      });
    }
  }, [code, actions, t]);

  return <Navigate to="/user/settings" />;
};
