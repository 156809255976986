import { createAction } from 'store';

import * as api from './api';

export const getSeedsAction = createAction(
  'cookieCrash/getSeeds',
  () => () => api.getSeeds(),
);

export const changeSeedPairAction = createAction(
  'cookieCrash/changeSeedPair',
  ({ clientSeed }) =>
    () =>
      api.changeSeedPair({ clientSeed }),
);

export const getMultipliersAction = createAction(
  'cookieCrash/getMultipliers',
  () => () => api.getMultipliers(),
);

export const startGameAction = createAction(
  'cookieCrash/startGame',
  ({ betAmount, difficulty }) =>
    () =>
      api.startGame({ betAmount, difficulty }),
);

export const getActiveGameAction = createAction(
  'cookieCrash/getActiveGame',
  () => () => api.getActiveGame(),
);

export const clearActiveGameAction = createAction(
  'cookieCrash/clearActiveGame',
  () => {},
);

export const eatCookieAction = createAction(
  'cookieCrash/eatCookie',
  gameId => () => api.eatCookie(gameId),
);

export const endGameAction = createAction(
  'cookieCrash/endGame',
  gameId => () => api.endGame(gameId),
);
