import { Box, Grid, GridItem, GridProps, Text } from '@chakra-ui/react';

type Option = {
  title: React.ReactNode;
  content: React.ReactNode;
};

type Props = GridProps & {
  options: Option[];
};

export const StatsGrid: React.FC<Props> = ({ options = [], ...props }) => (
  <Grid
    templateColumns={{
      base: 'repeat(1, 1fr)',
      lg: 'repeat(3, 1fr)',
    }}
    columnGap="3"
    rowGap="3"
    w="full"
    {...props}
  >
    {options.map(({ title, content }, index) => (
      <GridItem key={index}>
        <Box bg="sugar-dust" p="5" rounded="xl">
          <Text color="candy-floss-text" fontWeight="500" mb="1">
            {title}
          </Text>
          <Text fontWeight="500" fontSize="xl">
            {content}
          </Text>
        </Box>
      </GridItem>
    ))}
  </Grid>
);
