/* eslint-disable max-lines */
/* eslint-disable no-inner-declarations */
import { Box, Flex, Heading, Text } from '@chakra-ui/react';
import cn from 'classnames';
import { CountdownExpire } from 'components/countdown-expire';
import { GET_IS_TELEGRAM, IS_PRERENDER } from 'constants/general';
import { generateUrl, getImageLoaded, setImageLoaded } from 'helpers/image';
import { formatCredit } from 'helpers/numeral';
import { MyBetsIcon } from 'icons';
import { getUserCountry } from 'modules/handler';
import { getFeedItemByID } from 'modules/wagers';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useSelector } from 'react-redux';
import {
  getSlotsFavoritesGamesIds,
  getSlotsGame,
} from 'services/games/modules/slots';
import { toastInfo } from 'toasts';
import { UserType, WagerItemType } from 'types';
import { ImageFallback } from 'uikit/image-fallback';
import { Link } from 'uikit/link';
import { NotificationTip } from 'uikit/notification-tip';
import { ProviderImage } from 'uikit/provider-image';
import { Skeleton } from 'uikit/skeleton';
import styles from './style.module.scss';

const DEFAULT_SLOT_BG = 'rgb(63, 81, 128)';

type Props = {
  id?: string;
  link?: string;
  image?: string;
  isSkeleton?: boolean;
  isLink?: boolean;
  className?: string;
  style?: object;
  title?: string;
  provider?: string;
  bg?: string;
  onGameClick?: (id?: string) => void;
  countdown?: number;
  p?: string;
  meta?: { [key: string]: unknown };
  users?: UserType[];
  releasedOn?: number;
  hasTags?: boolean;
};

export const SlotPreview: React.FC<Props> = ({
  id,
  link,
  image,
  isSkeleton = false,
  isLink = true,
  hasTags = true,
  className,
  style,
  title = '',
  provider,
  bg: propBG,
  onGameClick,
  releasedOn,
  meta,
  p = '1',
}) => {
  const { t } = useTranslation();
  const [animate, setAnimate] = useState<WagerItemType | null>(null);
  const [isImageError, setIsImageError] = useState(false);
  const countryCode = useSelector(getUserCountry);
  const game = useSelector(getSlotsGame(id || ''));
  const feedItem = useSelector(getFeedItemByID(id), (a, b) => a === b);
  const popularSlots = useSelector(getSlotsFavoritesGamesIds);

  const isPopular =
    !meta?.isPopularFriends && !!id && popularSlots.includes(id);

  const isComingSoon: boolean = !!meta?.isComingSoon;

  let disabled = false;

  const [isStateImageLoaded, setIsImageLoaded] = useState(false);

  const isNewRelease =
    !meta?.isNewReleases && game && !!game.newRelease && !isComingSoon; // new-releases
  const isOriginal = game && game.categories?.includes('originals'); // originals
  const isDropsWins = game && game.categories?.includes('drops-and-wins'); // drops-wins
  const finalImage = image
    ? generateUrl(image, { w: provider === 'sherbet' ? 500 : 300 })
    : '';

  const isImageLoaded = isStateImageLoaded || getImageLoaded(finalImage);

  const bg = propBG || DEFAULT_SLOT_BG;

  useEffect(() => {
    if (feedItem) {
      setAnimate(feedItem);
    }
  }, [feedItem]);

  if (game && game.disabledCountries?.includes(countryCode)) {
    disabled = true;
  }

  const LinkComponent = isSkeleton || !isLink || !id ? Box : Link;
  const linkProps = {
    allowPrevent: true,
    to: link || `/casino/game/${id}`,
    'aria-label': title,
  };

  let imageContent;

  if (isSkeleton) {
    imageContent = (
      <Skeleton
        pos="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        w="100%"
        rounded="xl"
      />
    );
  } else if (isImageError) {
    imageContent = (
      <Box
        pos="absolute"
        top="0"
        left="0"
        right="0"
        bottom="0"
        rounded="xl"
        bg={bg}
      />
    );
  } else {
    imageContent = (
      <>
        {!isImageLoaded && (
          <Skeleton
            pos="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            w="100%"
            rounded="xl"
          />
        )}
        <LazyLoadImage
          threshold={100}
          visibleByDefault={IS_PRERENDER || getImageLoaded(finalImage)}
          effect="blur"
          src={finalImage}
          alt={`slot-${id || '0'}`}
          placeholder={<ImageFallback type="square" />}
          onError={() => setIsImageError(true)}
          onLoad={() => {
            setImageLoaded(finalImage);
            setIsImageLoaded(true);
          }}
          style={{
            width: '100%',
            zIndex: '1',
            position: 'absolute',
            height: '100%',
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            objectFit: 'cover',
            objectPosition: 'center',
            pointerEvents: 'none',
          }}
        />
      </>
    );
  }

  const isTagsExist =
    hasTags &&
    (isOriginal === true || // {{ edit_1 }}
      isDropsWins === true || // {{ edit_2 }}
      isPopular === true || // {{ edit_3 }}
      (isComingSoon === true && !!releasedOn) || // {{ edit_4 }}
      meta?.isTest === true); // {{ edit_5 }}

  return (
    <Box
      p={p}
      style={style}
      transitionProperty="all"
      pointerEvents={meta?.isTest ? 'none' : undefined} // {{ edit_1 }}
      transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
      transitionDuration=".15s"
      _hover={{
        transform: 'translateY(-0.125rem)',
        opacity: '0.9',
      }}
      _active={{
        transform: 'translateY(0px)',
      }}
    >
      <Box
        rounded="xl"
        roundedBottom="none"
        overflow="hidden"
        position="relative"
        opacity={meta?.isTest || disabled ? 0.5 : undefined} // {{ edit_1 }}
      >
        {bg && !isSkeleton && !isImageError && isImageLoaded && (
          <Flex
            zIndex="2"
            pointerEvents="none"
            position="absolute"
            bottom="0"
            left="0"
            w="full"
            h="full"
            align="start"
            flexDirection="column"
            justifyContent="flex-end"
            bg={`linear-gradient(to top, ${bg} 27%, transparent 85%)`}
          />
        )}
        <LinkComponent
          className={cn(className, styles.general)}
          display="block"
          tabIndex={-1}
          onTouchStart={() => {
            if (GET_IS_TELEGRAM()) {
              window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
            }
          }}
          {...(LinkComponent === Link ? linkProps : {})}
          onClick={(e: React.MouseEvent<HTMLElement>) => {
            if (disabled) {
              e.preventDefault();
              toastInfo({
                title: t('casino.errors.153'),
                description: t('casino.errors.153_description'),
              });
            }
            if (onGameClick) {
              e.preventDefault();
              onGameClick(id);
            }
          }}
          minH={{ base: '144px', md: '172px' }}
          maxH={{ base: '144px', md: '172px' }}
        >
          {imageContent}
        </LinkComponent>
        {bg && (
          <Flex
            pointerEvents="none"
            position="absolute"
            bottom="0"
            left="0"
            w="full"
            h="full"
            align="start"
            flexDirection="column"
            justifyContent="flex-end"
            bg="linear-gradient(to top, #171824 7%, rgba(23, 24, 36, 0.25) 50%, transparent 100%)"
            zIndex="2"
          />
        )}

        <Box pos="absolute" bottom="0" left="0" right="0" zIndex="3">
          <Box position="relative" px="3">
            {!!animate && (
              <NotificationTip
                className={styles.appear}
                key={animate.timestamp}
                bg="sherbet-green.200"
                mb="1.5"
                notifications={
                  <>
                    <MyBetsIcon color="vanilla-text" fontSize="xs" />
                    <Text fontWeight="500" color="vanilla-text" fontSize="sm">
                      ${formatCredit(animate.winAmount)}
                    </Text>
                  </>
                }
              />
            )}
            {isSkeleton ? (
              <Skeleton mb="1" w="70px">
                <Heading fontWeight="500" fontSize={{ base: 'xl', md: '22px' }}>
                  |
                </Heading>
              </Skeleton>
            ) : (
              <Heading
                as={LinkComponent}
                fontFamily="thumb"
                textTransform="uppercase"
                fontWeight="500"
                color="vanilla-text"
                letterSpacing="0.5px"
                fontSize={{ base: 'xl', md: '22px' }}
                noOfLines={3}
                mb="1"
                lineHeight="1"
                {...(LinkComponent === Link ? linkProps : {})}
                onClick={e => {
                  if (disabled) {
                    e.preventDefault();
                    toastInfo({
                      title: t('casino.errors.153'),
                      description: t('casino.errors.153_description'),
                    });
                  }
                  if (onGameClick) {
                    e.preventDefault();
                    onGameClick(id);
                  }
                }}
              >
                {title}
              </Heading>
            )}
          </Box>
        </Box>
      </Box>

      <Box px="3">
        {isSkeleton ? (
          <Skeleton w="70px">
            <Text color="candy-floss-text">|</Text>
          </Skeleton>
        ) : (
          provider && (
            <Flex align="center" gap="1.5" _hover={{ opacity: '0.9' }}>
              <Link
                to={`/casino/provider/${provider}`}
                flexShrink="0"
                onClick={e => {
                  if (!isLink) {
                    e.stopPropagation();
                  }
                }}
              >
                <ProviderImage provider={provider} w="14px" h="14px" />
              </Link>
              <Text
                as={Link}
                to={`/casino/provider/${provider}`}
                _hover={{ textDecoration: 'underline' }}
                color="candy-floss-text"
                noOfLines={1}
                onClick={e => {
                  if (!isLink) {
                    e.stopPropagation();
                  }
                }}
              >
                {t(`casino.provider.${provider}`)}
              </Text>
            </Flex>
          )
        )}

        {isTagsExist &&
          (isSkeleton ? (
            <Skeleton mt="1.5" w="70px">
              <Text color="candy-floss-text">|</Text>
            </Skeleton>
          ) : (
            <Flex align="center" gap="1" overflow="scroll" mt="1.5">
              {isNewRelease && (
                <NotificationTip
                  p="0 .25rem"
                  bg="rgb(0, 131, 138)"
                  color="toffee-base"
                  notifications={t('tags.new') as React.ReactNode} // {{ edit_1 }}
                />
              )}
              {isPopular && (
                <NotificationTip
                  p="0 .25rem"
                  bg="sherbet-purple"
                  color="toffee-base"
                  notifications={t('tags.trending') as React.ReactNode} // {{ edit_2 }}
                />
              )}
              {isDropsWins && (
                <NotificationTip
                  p="0 .25rem"
                  bg="#E19881"
                  color="toffee-base"
                  notifications={
                    t('casino.category.drops-and-wins') as React.ReactNode
                  } // {{ edit_3 }}
                />
              )}
              {isComingSoon && releasedOn && (
                <NotificationTip
                  p="0 .25rem"
                  bg="truffle-border"
                  color="candy-floss-text"
                  notifications={<CountdownExpire endTime={releasedOn} />} // {{ edit_4 }}
                />
              )}
              {meta?.isTest === true && (
                <NotificationTip
                  p="0 .25rem"
                  bg="truffle-border"
                  color="candy-floss-text"
                  notifications="Coming Soon" // {{ edit_5 }}
                />
              )}
            </Flex>
          ))}
      </Box>
    </Box>
  );
};
