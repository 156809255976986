import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react';
import { CustomSelect } from 'forms/custom-select';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { KYCRequiredForm } from 'services/user/components/kyc-required-form';
import { getUserRequiredVerify } from 'services/user/modules/user';
import { Link } from 'uikit/link';

const list = [10, 25, 50, 100, 250, 500];

const links = {
  10: 'https://www.kinguin.net/category/207070/sherbet-10-gift-card?referrer=sherbet.com',
  25: 'https://www.kinguin.net/category/207071/sherbet-25-gift-card?referrer=sherbet.com',
  50: 'https://www.kinguin.net/category/207072/sherbet-50-gift-card?referrer=sherbet.com',
  100: 'https://www.kinguin.net/category/207074/sherbet-100-gift-card?referrer=sherbet.com',
  250: 'https://www.kinguin.net/category/207075/sherbet-250-gift-card?referrer=sherbet.com',
  500: 'https://www.kinguin.net/category/207076/sherbet-500-gift-card?referrer=sherbet.com',
};

export const GiftCardView = () => {
  const [value, setValue] = useState('');
  const { t } = useTranslation();

  const selectOptions = list.map(i => ({ value: i, title: `$${i}` }));

  const isVerifyRequired = useSelector(getUserRequiredVerify);

  if (isVerifyRequired) {
    return <KYCRequiredForm isRounded={false} />;
  }

  return (
    <Flex direction="column" gap="5">
      <Box p="5" pb="0">
        <Text color="candy-floss-text">{t('gift.desc')}</Text>
      </Box>
      <Divider
        borderColor="truffle-border"
        borderBottomWidth="1px"
        opacity="1"
      />
      <Box p="5" pt="0" pb="0">
        <CustomSelect
          name="amount"
          required
          label={t('gift.label')}
          placeholder={t('gift.select')}
          value={value}
          onChange={e => setValue(+e.target.value)}
          options={selectOptions}
        />
      </Box>
      <Divider
        borderColor="truffle-border"
        borderBottomWidth="1px"
        opacity="1"
      />
      <Box p="5" pt="0">
        <Button
          as={Link}
          to={links[value] || links[5]}
          _active={{ opacity: 0.9 }}
          bg="sherbet-purple"
          color="vanilla-text"
          rounded="full"
          _hover={{ opacity: 0.9 }}
          colorScheme="custom"
          isDisabled={!value}
        >
          {t('nft.buy-now')}
        </Button>
      </Box>
    </Flex>
  );
};
