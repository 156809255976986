import { createIcon } from '@chakra-ui/icons';

export const DiscordIcon = createIcon({
  displayName: 'DiscordIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="M13.553 2.952a13.233 13.233 0 0 0-3.3-1.015 9.067 9.067 0 0 0-.423.86 12.292 12.292 0 0 0-3.664 0 9.11 9.11 0 0 0-.422-.86A13.316 13.316 0 0 0 2.44 2.955C.351 6.045-.215 9.06.068 12.03a13.306 13.306 0 0 0 4.048 2.032c.328-.44.618-.908.867-1.398a8.613 8.613 0 0 1-1.365-.652c.115-.083.227-.168.335-.252a9.51 9.51 0 0 0 8.094 0c.11.09.222.175.335.252a8.638 8.638 0 0 1-1.368.653c.249.49.539.957.867 1.397a13.245 13.245 0 0 0 4.051-2.03c.332-3.447-.568-6.433-2.379-9.08Zm-8.21 7.252c-.79 0-1.442-.717-1.442-1.597 0-.881.63-1.604 1.439-1.604s1.456.723 1.442 1.604c-.014.88-.636 1.597-1.44 1.597Zm5.315 0c-.79 0-1.44-.717-1.44-1.597 0-.881.63-1.604 1.44-1.604.81 0 1.452.723 1.438 1.604-.014.88-.634 1.597-1.438 1.597Z"
    />
  ),
});
