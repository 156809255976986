import { createIcon } from '@chakra-ui/icons';

export const BlogIcon = createIcon({
  displayName: 'BlogIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M0 3a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3v10a3 3 0 0 1-3 3H3a3 3 0 0 1-3-3V3Zm3 1a1 1 0 0 1 1-1h8a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V4Zm1 7a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2H4Z"
      clipRule="evenodd"
    />
  ),
});
