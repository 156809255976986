import { createReducer } from 'store';
import { loadQuests } from './actions';
import { QuestType, QuestsType } from './types';

const initialState: QuestsType = {
  quests: [],
  isLoaded: false,
};

type QuestsResponse = { quests: QuestType[]; totalCount: number };

export const reducer = createReducer(initialState, {
  [loadQuests.toString()]: (
    state,
    { payload }: { payload: QuestsResponse },
  ) => {
    state.quests = payload.quests;
    state.isLoaded = true;
  },
});
