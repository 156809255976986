import { Flex } from '@chakra-ui/react';
import { Content } from 'components/content';
import { formatCredit } from 'helpers/numeral';
import { CasinoIcon, CategoriesIcon } from 'icons';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { SlotsGrid } from 'services/games/components/slots-grid';
import { getSlotsGamesList } from 'services/games/modules/slots';
import { SlotsSlider } from 'services/games/pages/casino/components/slots-slider';
import { EmptyResults } from 'uikit/empty-results';

const SEARCH_HISTORY_KEY = 'SEARCH_HISTORY';

export const SearchContent = ({
  search,
  onGameClick,
  gridSize,
  hideSlotsSlider,
}) => {
  const [prevSearch, setPrevSearch] = useState(() => {
    if (localStorage.getItem(SEARCH_HISTORY_KEY)?.length) {
      return localStorage.getItem(SEARCH_HISTORY_KEY).split(',');
    }

    return [];
  });
  const games = useSelector(getSlotsGamesList);

  const isValidSearch = search.length >= 3;

  const filteredGames = useMemo(() => {
    const searchText = search.toLowerCase().trim().replace(/\s+/g, '');

    if (isValidSearch) {
      const filtered = games.filter(
        x =>
          x.title?.toLowerCase().replace(/\s+/g, '').includes(searchText) ||
          x.provider?.toLowerCase().replace(/\s+/g, '').includes(searchText),
      );

      // Sort featured games to the top
      const featuredGames = filtered.filter(g => g.featured);
      const nonFeaturedGames = filtered.filter(g => !g.featured);

      return [...featuredGames, ...nonFeaturedGames];
    }

    return [];
  }, [games, search, isValidSearch]);

  useEffect(() => {
    if (search.length >= 3) {
      setPrevSearch(prev =>
        [search, ...prev.filter(i => !search.includes(i))].slice(0, 3),
      );
    }
  }, [search]);

  useEffect(() => {
    localStorage.setItem(SEARCH_HISTORY_KEY, prevSearch.join(','));
  }, [prevSearch]);

  return (
    <Flex flexDirection="column">
      {search === '' && !hideSlotsSlider && (
        <Flex flexDirection="column" gap="7" mt="2">
          <SlotsSlider />
        </Flex>
      )}
      {isValidSearch && !filteredGames.length && (
        <EmptyResults mt="2" icon={CasinoIcon} />
      )}
      {isValidSearch && filteredGames.length > 0 && (
        <Content mt="2">
          <SlotsGrid
            hasHeader
            icon={<CategoriesIcon color="candy-floss-text" />}
            title2={`${formatCredit(filteredGames.length)} results`}
            hasShowMore
            games={filteredGames.slice(0, 40)}
            onGameClick={onGameClick}
            gridSize={gridSize}
          />
        </Content>
      )}
    </Flex>
  );
};
