import { getPathnameWithoutLang, removeTrailingSlash } from 'helpers/lang';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

export const useSeoMeta = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const { search } = location;
  const pathname = getPathnameWithoutLang(
    removeTrailingSlash(location.pathname),
  );

  let title = t(`seo.${pathname}.title`, '');
  let description = t(`seo.${pathname}.description`, '');

  if (search) {
    const searchTitle = t(`seo./${search}.title`, '');
    if (searchTitle) {
      title = searchTitle;
    }
    const searchDescription = t(`seo./${search}.title`, '');
    if (searchDescription) {
      description = searchDescription;
    }
  }

  if (!title) {
    title = t('seo.default.title');
    description = t('seo.default.description');
  } else if (!description) {
    description = title;
  }

  return {
    title,
    description,
  };
};
