import { createIcon } from '@chakra-ui/icons';

export const ExternalLinkIcon = createIcon({
  displayName: 'ExternalLinkIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.694 0h1.75a1 1 0 0 1 0 2h-1.71c-.67 0-1.113 0-1.452.028-.327.027-.469.074-.554.117a1.34 1.34 0 0 0-.583.583c-.043.085-.09.227-.117.554-.027.34-.028.781-.028 1.451v6.534c0 .67 0 1.112.028 1.45.027.328.074.47.117.555.128.25.332.455.583.583.085.043.227.09.554.117.34.027.781.028 1.451.028h6.534c.67 0 1.112 0 1.45-.028.328-.027.47-.074.555-.117a1.34 1.34 0 0 0 .583-.583c.043-.085.09-.227.117-.554.027-.34.028-.781.028-1.451V9.556a1 1 0 1 1 2 0v1.75c0 .62 0 1.145-.035 1.575-.037.45-.117.885-.328 1.299a3.334 3.334 0 0 1-1.457 1.457c-.414.21-.848.291-1.3.328-.43.035-.954.035-1.574.035H4.694c-.62 0-1.145 0-1.575-.035-.45-.037-.885-.117-1.299-.328A3.333 3.333 0 0 1 .363 14.18c-.21-.414-.291-.848-.328-1.3C0 12.45 0 11.927 0 11.307V4.694c0-.62 0-1.145.035-1.575.037-.45.117-.885.328-1.299.32-.627.83-1.137 1.457-1.457.414-.21.848-.291 1.3-.328C3.55 0 4.073 0 4.693 0Zm4.64 1a1 1 0 0 1 1-1H15a1 1 0 0 1 1 1v4.667a1 1 0 1 1-2 0V3.414L8.707 8.707a1 1 0 0 1-1.414-1.414L12.586 2h-2.253a1 1 0 0 1-1-1Z"
      clipRule="evenodd"
    />
  ),
});
