import { createIcon } from '@chakra-ui/icons';

export const RafflesIcon = createIcon({
  displayName: 'RafflesIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6 4a2 2 0 1 1 4 0v1c0 .412-.124.794-.338 1.112a2 2 0 0 1 1.25 2.477 1 1 0 0 1-.205 1.118L9.414 11H10a1 1 0 1 1 0 2H6a1 1 0 1 1 0-2h.586L5.293 9.707a1 1 0 0 1-.205-1.118 2 2 0 0 1 1.25-2.477A1.99 1.99 0 0 1 6 5V4Zm5.5 0v1.017h2.246a.71.71 0 0 0 .609-1.075l-.015-.024a.606.606 0 0 1-.086-.312.606.606 0 0 0-.606-.606h-2.293a3.5 3.5 0 0 1 .145 1ZM4.645 3A3.5 3.5 0 0 0 4.5 4v1.017H2.264a.702.702 0 0 1-.596-1.073l.015-.024a.602.602 0 0 0 .09-.318c0-.333.27-.602.603-.602h2.269ZM1.75 7A1.75 1.75 0 0 0 0 8.75v3.5a1.75 1.75 0 1 0 3.5 0v-3.5A1.75 1.75 0 0 0 1.75 7Zm12.5 0a1.75 1.75 0 0 0-1.75 1.75v3.5a1.75 1.75 0 1 0 3.5 0v-3.5A1.75 1.75 0 0 0 14.25 7Z"
      clipRule="evenodd"
    />
  ),
});
