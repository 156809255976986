import { Box } from '@chakra-ui/react';
import cn from 'classnames';
import { ErrorBoundary } from 'components/error-boundary';
import { Fragment, cloneElement } from 'react';
import { RemoveScroll } from 'react-remove-scroll';
import styles from './style.module.scss';

type Props = {
  isMobile: boolean;
  children: React.ReactNode;
  direction?: 'left' | 'right';
  open: boolean;
};

export const SidebarWrapper: React.FC<Props> = ({
  isMobile,
  children,
  direction = 'left',
  open,
}) => {
  const isMobileOpened = isMobile && open;
  const Wrapper = isMobileOpened ? RemoveScroll : Fragment;

  return (
    <Wrapper>
      <Box
        position="fixed"
        zIndex={isMobile ? '1000' : '1002'}
        right={
          direction === 'right'
            ? 'var(--removed-body-scroll-bar-size, 0)'
            : undefined
        }
        left={direction === 'left' ? '0' : undefined}
        top={isMobile ? 'var(--header-height)' : 'var(--header-height-second)'}
        bottom={isMobile ? 'var(--footer-height)' : '0'}
        className={cn(styles.general, styles[direction], {
          [styles.open]: open,
        })}
      >
        <ErrorBoundary>
          {cloneElement(
            children as React.ReactElement<{ [key: string]: unknown }>,
            {
              open,
            },
          )}
        </ErrorBoundary>
      </Box>
    </Wrapper>
  );
};
