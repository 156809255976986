import { TriangleDownIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserIsAuthorized } from 'services/user/modules/user';

type Props = {
  children: React.ReactNode;
};

export const SeoFooter: React.FC<Props> = ({ children }) => {
  const [open, setOpen] = useState(false);
  const isAuthorized = useSelector(getUserIsAuthorized);

  if (isAuthorized) {
    return null;
  }

  return (
    <Box
      maxH={open ? '100%' : { base: '144px', md: '172px' }}
      pos="relative"
      overflow="hidden"
      color="vanilla-text"
      borderTop="1px solid"
      borderColor="truffle-border"
      py="5"
    >
      <Box maxW="6xl" mx="auto" px="5">
        {children}
        {!open && (
          <Box
            pos="absolute"
            zIndex="1"
            top="0"
            left="0"
            width="100%"
            height="100%"
            bg="linear-gradient(0deg, var(--chakra-colors-toffee-base) 0%,rgba(15,33,46,0) 100%)"
          />
        )}
        <Flex pos="sticky" bottom="0" justifyContent="center" zIndex="2" mt="5">
          {open ? (
            <IconButton
              aria-label="Show Less"
              color="candy-floss-text"
              size="sm"
              onClick={() => setOpen(false)}
              rounded="full !important"
            >
              <TriangleDownIcon transform="rotate(180deg)" />
            </IconButton>
          ) : (
            <IconButton
              aria-label="Show More"
              color="candy-floss-text"
              size="sm"
              onClick={() => setOpen(true)}
              rounded="full !important"
            >
              <TriangleDownIcon />
            </IconButton>
          )}
        </Flex>
      </Box>
    </Box>
  );
};
