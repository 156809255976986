import reduxWebsocket from '@giantmachines/redux-websocket';
import { configureStore } from '@reduxjs/toolkit';
import {
  LOCAL_STATE,
  LOCAL_STATE_VERSION,
  LOCAL_VERSION,
} from 'constants/general';
import { addRefreshInterceptor } from 'helpers/client';
import { send, wsMiddleware } from 'helpers/ws';
import { chatWebsocket } from 'modules/chat';
import { thunk as thunkMiddleware } from 'redux-thunk';
import { refreshToken } from 'services/auth/modules/auth';
import { userWebsocket } from 'services/user/modules/user';
import { dingMiddleware } from './middlewares/ding-middleware';
import { reduxThunkPayloadMiddleware } from './middlewares/redux-thunk-payload-middleware';
import { reduxThunkPromiseMiddleware } from './middlewares/redux-thunk-promise-middleware';
import { toastsMiddleware } from './middlewares/toasts-middleware';
import { reducers } from './reducers';

const userWebsocketMiddleware = reduxWebsocket({
  prefix: userWebsocket,
  reconnectOnClose: true,
  reconnectInterval: 2000,
});

const chatWebsocketMiddleware = reduxWebsocket({
  prefix: chatWebsocket,
  reconnectOnClose: true,
  reconnectInterval: 2000,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const middleware: any = [
  thunkMiddleware,
  userWebsocketMiddleware,
  chatWebsocketMiddleware,
  wsMiddleware,
  dingMiddleware,
  toastsMiddleware,
  reduxThunkPayloadMiddleware,
  reduxThunkPromiseMiddleware,
];

const createStore = () => {
  let preloadedState = {};

  try {
    const localState = localStorage.getItem(LOCAL_STATE);
    if (
      localStorage.getItem(LOCAL_STATE_VERSION) === LOCAL_VERSION &&
      localState
    ) {
      preloadedState = JSON.parse(localState);
    }
  } catch (e) {
    console.warn(e);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const store: any = configureStore({
    reducer: reducers,
    preloadedState,
    middleware: () => middleware,
  });

  addRefreshInterceptor(() => store.dispatch(refreshToken()));

  setInterval(() => {
    if (document.visibilityState === 'hidden') {
      return;
    }

    if (document.hidden) {
      return;
    }

    store.dispatch(send(userWebsocket, {}));
    store.dispatch(send(chatWebsocket, {}));
  }, 1000 * 30);

  // for easy debug from console
  window.store = store;

  return store;
};

export { createStore };
