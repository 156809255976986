/* eslint-disable max-lines */
/* eslint-disable react/style-prop-object */
import { Box, Flex, IconButton, StatUpArrow, Text } from '@chakra-ui/react';
import cn from 'classnames';
import { Error } from 'components/error';
import { ErrorBoundary } from 'components/error-boundary';
import { Loading } from 'components/loading';
import { Seo } from 'components/seo';
import { AnimationContext } from 'contexts/animation-context';
import { BonusPopupContext } from 'contexts/bonus-popup-context';
import { LayoutContext } from 'contexts/layout-context';
import { LayoutContextType } from 'contexts/layout-context/layout-context';
import { useStats } from 'contexts/stats-context/stats-context';
import { getPathnameWithoutLang } from 'helpers/lang';
import { useSupport } from 'hooks/use-support';
import { SupportIcon } from 'icons';
import { Footer } from 'layout/footer';
import { Routes } from 'layout/routes';
import { MODAL_TYPE, useModal } from 'modals';
import { useUrlModal } from 'modals/use-url-modal';
import { getHandlerIsLoaded } from 'modules/handler';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
import { useLocation, useSearchParams } from 'react-router-dom';
import { GoogleTap } from 'services/auth/components/google-tap';
import { Auth } from 'services/auth/pages/auth';
import { AuthGoogle } from 'services/auth/pages/auth-google';
import { AuthTelegram } from 'services/auth/pages/auth-telegram';
import { Stats } from 'services/games/layout/game-layout/components/stats';
import { Search } from 'services/games/pages/search';
import { NftDetails } from 'services/nft/pages/nft-details';
import { SubSumSdk } from 'services/user/components/subsum-sdk';
import {
  getUserAccountFlags,
  getUserIsAuthorized,
  getUserIsLoaded,
  getUserIsSelfExcluded,
} from 'services/user/modules/user';
import { Bonuses } from 'services/user/pages/bonuses';
import { Profile } from 'services/user/pages/profile';
import { Tip } from 'services/user/pages/tip';
import { Wallet } from 'services/user/pages/wallet';
import { Link } from 'uikit/link';
import { Header } from './components/header/header';
import { LeftSidebar } from './components/left-sidebar';
import { Meta } from './components/meta';
import { NavigationBar } from './components/navigation-bar';
import { OneSignal } from './components/one-signal';
import { RightSidebar } from './components/right-sidebar';
import { SideEffects } from './components/side-effects';
import { SidebarWrapper } from './components/sidebar-wrapper';
import styles from './style.module.scss';

const MOBILE_BREAKPOINT = 992;
const IS_MAINTANANCE = false;

const PAGE_MODALS = {
  [MODAL_TYPE.PROFILE_MODAL]: Profile,
  [MODAL_TYPE.WALLET_MODAL]: Wallet,
  [MODAL_TYPE.AUTH]: Auth,
  [MODAL_TYPE.GLOBAL_SEARCH]: Search,
  [MODAL_TYPE.NFT]: NftDetails,
  [MODAL_TYPE.BONUSES]: Bonuses,
  [MODAL_TYPE.TIP]: Tip,
  [MODAL_TYPE.AUTH_GOOGLE]: AuthGoogle,
  [MODAL_TYPE.AUTH_TELEGRAM]: AuthTelegram,
};

export const Layout = () => {
  const [open, setOpen] = useState('');
  const [layout, setLayout] = useState(localStorage.getItem('LAYOUT') || '1-0');
  const [isHidden, setIsHidden] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(
    !!localStorage.getItem('bonus_popup_toggled'),
  );
  const [isAvailable, setIsAvailable] = useState(
    !!localStorage.getItem('bonus_displayed'),
  );
  const [isMobile, setIsMobile] = useState(
    () => window.innerWidth <= MOBILE_BREAKPOINT,
  );
  const location = useLocation();
  const isAuthorized = useSelector(getUserIsAuthorized);
  const isUserLoaded = useSelector(getUserIsLoaded);
  const isHandlerLoaded = useSelector(getHandlerIsLoaded);
  const accountFlags = useSelector(getUserAccountFlags);
  const isLoading = !isUserLoaded || !isHandlerLoaded;
  const pageModal = useUrlModal();
  const isSelfExcluded = useSelector(getUserIsSelfExcluded);
  const [isPageAnimation, setIsPageAnimation] = useState(false);
  const [rightSiderbarActive, setRightSiderbarActive_] = useState('');
  const [rightSidebarParams, setRightSidebarParams] = useState({});
  const [leftSidebarCollapsed, setLeftSidebarCollapsed] = useState(false);
  const { isStatsVisible, toggleStatsElement } = useStats();

  const { onOpen: onOpenSearch } = useModal(MODAL_TYPE.GLOBAL_SEARCH);
  const { toggle: toggleSupport } = useSupport();

  const [showScrollToTop, setShowScrollToTop] = useState(false);

  useLayoutEffect(() => {
    const observer = new ResizeObserver(() => {
      const newMobile = window.innerWidth <= MOBILE_BREAKPOINT;
      if (isMobile !== newMobile) {
        setIsMobile(newMobile);
      }
    });
    observer?.observe(window.document.body);
    return () => {
      observer?.disconnect();
    };
  }, [isMobile]);

  useEffect(() => {
    if (open && isMobile) {
      setOpen('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname, location.search]);

  useEffect(() => {
    if (!location.state?.disableScroll) {
      window.scrollTo(0, 0);
    }
  }, [location.pathname, location.state?.disableScroll, pageModal]);

  const handleOpenChat = useCallback(() => {
    setOpen(prev => (prev === 'chat' ? '' : 'chat'));
    setRightSiderbarActive_('');
  }, []);

  const handleOpenFriends = useCallback(() => {
    setOpen(prev => (prev === 'chat' ? '' : 'chat'));
  }, []);

  const handleOpenMenu = useCallback(() => {
    setOpen(prev => (prev === 'menu' ? '' : 'menu'));
  }, []);

  const shouldCollapseSidebar = useCallback(
    () =>
      location.pathname.startsWith('/casino/game') ||
      location.pathname === '/promotions/status-swap' ||
      location.pathname === '/vip',
    [location.pathname],
  );

  useMemo(() => {
    if (shouldCollapseSidebar()) {
      setLeftSidebarCollapsed(true);
    } else {
      setLeftSidebarCollapsed(false);
    }
  }, [shouldCollapseSidebar]);

  // eslint-disable-next-line prefer-const
  let [hasLeft, hasRight] = isMobile
    ? [true, true]
    : layout.split('-').map(i => Boolean(Number(i)));

  hasLeft = hasLeft && !leftSidebarCollapsed;

  if (!isAuthorized) {
    hasRight = false;
  }

  const changeRightLayout = useCallback(
    (force?: boolean) => {
      const r = force !== undefined ? force : !hasRight;
      if (!r) {
        setRightSiderbarActive_('');
      }
      const newLayout = `${Number(hasLeft)}-${Number(r)}`;
      localStorage.setItem('LAYOUT', newLayout);
      setLayout(newLayout);
    },
    [hasRight, hasLeft, setRightSiderbarActive_],
  );

  const setRightSiderbarActive = (name: string) => {
    if (name !== rightSiderbarActive) {
      changeRightLayout(true);
      setRightSiderbarActive_(name);
      if (isMobile) {
        setOpen('chat');
      }
    } else {
      changeRightLayout(false);
      setOpen('');
      setRightSiderbarActive_('');
    }
  };

  const [searchParams, setSearchParams] = useSearchParams();

  const { right, ...rest } = Object.fromEntries(searchParams);

  useEffect(() => {
    if (right) {
      setRightSiderbarActive_(right);
      setRightSidebarParams(rest);
      changeRightLayout(true);
      setSearchParams('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [right, changeRightLayout, setSearchParams]);

  const changeLeftLayout = useCallback(() => {
    const l = !hasLeft;
    const newLayout = `${Number(l)}-${Number(hasRight)}`;
    localStorage.setItem('LAYOUT', newLayout);
    setLayout(newLayout);
    setLeftSidebarCollapsed(false);
  }, [hasRight, hasLeft]);

  const PageModal = pageModal ? PAGE_MODALS[pageModal.type] : null;

  const memoPopupValue = useMemo(
    () => ({
      isHidden,
      setIsHidden,
      isCollapsed,
      setIsCollapsed,
      isAvailable,
      setIsAvailable,
    }),
    [
      isHidden,
      setIsHidden,
      isCollapsed,
      setIsCollapsed,
      isAvailable,
      setIsAvailable,
    ],
  );

  useEffect(() => {
    if (isPageAnimation) {
      setTimeout(() => {
        setIsPageAnimation(false);
      }, 5000);
    }
  }, [isPageAnimation]);

  const animationContext = useMemo(
    () => ({ isPageAnimation, setIsPageAnimation }),
    [isPageAnimation],
  );

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop =
        window.pageYOffset || document.documentElement.scrollTop;
      setShowScrollToTop(scrollTop > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleScrollToTop = useCallback(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  if (IS_MAINTANANCE && !localStorage?.test18924) {
    return (
      <Error
        title="Maintenance mode"
        description="We will be back soon"
        hasButton={false}
        minHeight="100vh"
      />
    );
  }

  if (accountFlags?.and(1073741824)?.eq(1073741824)) {
    return (
      <Error
        title="Your account has been suspended/blocked"
        description="This may be due to KYC requirements or a violation of our TOS. For any questions, please contact support@sherbet.com"
        hasButton={false}
        minHeight="100vh"
      />
    );
  }

  if (isSelfExcluded) {
    return (
      <Error
        title="Access restricted"
        description="Sherbet is not available due to your request for self exclusion. For any questions, please contact support@sherbet.com"
        hasButton={false}
        minHeight="100vh"
      />
    );
  }

  const pathNameWithoutLang = getPathnameWithoutLang(location.pathname);

  if (pathNameWithoutLang === '/verification' && (isLoading || isAuthorized)) {
    return (
      <>
        <Helmet>
          {/* @ts-ignore */}
          <body style="background-color: #fff !important" />
        </Helmet>
        <SideEffects />
        <SubSumSdk />
      </>
    );
  }

  return (
    <>
      <SideEffects />
      <LayoutContext.Provider value={layout as LayoutContextType}>
        <AnimationContext.Provider value={animationContext}>
          <BonusPopupContext.Provider value={memoPopupValue}>
            <Flex flexGrow="1" flexShrink="0">
              {!isMobile && (
                <LeftSidebar
                  hasLeft={hasLeft}
                  changeLeftLayout={changeLeftLayout}
                />
              )}
              <Flex
                direction="column"
                flexGrow="1"
                w={{
                  base: 'full',
                  lg: hasLeft
                    ? 'calc(100% - 66px - 240px)'
                    : 'calc(100% - 66px - 60px)',
                }}
              >
                <Meta />
                {window.location.hostname === 'sherbet.com' && <OneSignal />}
                {!isAuthorized && !isLoading && <GoogleTap />}
                <Header
                  hasRight={hasRight}
                  isMobile={isMobile}
                  toggleRightMenu={changeRightLayout}
                  setRightSiderbarActive={setRightSiderbarActive}
                />
                <Seo />
                {isMobile && (
                  <SidebarWrapper
                    isMobile={isMobile}
                    open={open === 'menu'}
                    direction="left"
                  >
                    <LeftSidebar hasLeft isMobile />
                  </SidebarWrapper>
                )}
                <Flex
                  flexDir="column"
                  className={cn(styles.wrapper, {
                    [styles.hasRight]: hasRight,
                    [styles.hasLeft]: hasLeft,
                  })}
                  bg="toffee-base"
                  pos="relative"
                >
                  {isLoading ? (
                    <Loading />
                  ) : (
                    <>
                      <Flex
                        direction="column"
                        flexShrink="0"
                        transition="opacity 750ms ease"
                        {...(PageModal
                          ? {
                              pos: 'absolute',
                              overflow: 'hidden',
                              zIndex: '-1',
                              top: '0',
                              left: '0',
                              right: '0',
                              bottom: '0',
                              className: styles.contentWrapper,
                            }
                          : {
                              pos: 'relative',
                              minH: '200px',

                              className: isPageAnimation
                                ? cn(
                                    styles.contentAnimation,
                                    styles.contentWrapper,
                                  )
                                : styles.contentWrapper,
                            })}
                      >
                        <ErrorBoundary>
                          <Flex
                            w="full"
                            bg="sherbet-green.100"
                            p="5"
                            display={{ base: 'none', lg: 'flex' }}
                            gap="5"
                            alignItems="center"
                          >
                            <Flex maxW="6xl" mx="auto" px="5">
                              <Text w="full" color="sherbet-green.200">
                                We've got some exciting news: Sherbet is being
                                acquired by Shuffle.com. From the 21st of
                                October 2024, Sherbet.com will remain in
                                withdrawal-only mode. Your balance is safe, and
                                you have 30 days to withdraw.{' '}
                                <Link
                                  to="https://x.com/noahdummett/status/1848159309546557806"
                                  textDecoration="underline"
                                  _hover={{ opacity: '0.9' }}
                                >
                                  Learn more
                                </Link>
                              </Text>
                            </Flex>
                          </Flex>
                          <Routes />
                        </ErrorBoundary>
                      </Flex>
                      {PageModal && (
                        <Flex
                          direction="column"
                          pos="relative"
                          className={styles.contentWrapper}
                          minH="200px"
                          flexGrow="1"
                        >
                          <ErrorBoundary>
                            {/* @ts-ignore */}
                            <PageModal {...pageModal} />
                          </ErrorBoundary>
                        </Flex>
                      )}
                      {!PageModal && <Footer />}
                    </>
                  )}
                </Flex>

                {showScrollToTop && (
                  <Box
                    pos="fixed"
                    bottom={isAuthorized ? '20' : '5'}
                    right={
                      hasRight
                        ? 'calc(var(--right-sidebar-width) + 20px)'
                        : '20px'
                    }
                    display={{ base: 'none', lg: 'block' }}
                    zIndex="100"
                  >
                    <IconButton
                      aria-label="Scroll to Top"
                      rounded="full"
                      colorScheme="purple"
                      onClick={handleScrollToTop}
                    >
                      <StatUpArrow color="vanilla-text" />
                    </IconButton>
                  </Box>
                )}
                {isAuthorized && (
                  <Box
                    pos="fixed"
                    bottom="5"
                    right={
                      hasRight
                        ? 'calc(var(--right-sidebar-width) + 20px)'
                        : '20px'
                    }
                    display={{ base: 'none', lg: 'block' }}
                    zIndex="100"
                  >
                    <IconButton
                      aria-label="Support"
                      rounded="full"
                      colorScheme="purple"
                      onClick={toggleSupport}
                    >
                      <SupportIcon />
                    </IconButton>
                  </Box>
                )}

                <SidebarWrapper
                  isMobile={isMobile}
                  open={
                    isMobile
                      ? open === 'chat' || !!rightSiderbarActive
                      : hasRight
                  }
                  direction="right"
                >
                  <RightSidebar
                    rightSiderbarActive={rightSiderbarActive}
                    rightSidebarParams={rightSidebarParams}
                    toggleRightMenu={changeRightLayout}
                    setRightSiderbarActive={setRightSiderbarActive}
                  />
                </SidebarWrapper>
                <NavigationBar
                  openChat={handleOpenChat}
                  openFriends={handleOpenFriends}
                  openMenu={handleOpenMenu}
                  openSearch={onOpenSearch}
                  open={open}
                  closeOpen={() => {
                    setOpen('');
                    setRightSiderbarActive_('');
                  }}
                  rightSidebarActive={rightSiderbarActive}
                  setRightSiderbarActive={setRightSiderbarActive}
                />
              </Flex>
              <Stats isVisible={isStatsVisible} onClose={toggleStatsElement} />
            </Flex>
          </BonusPopupContext.Provider>
        </AnimationContext.Provider>
      </LayoutContext.Provider>
    </>
  );
};
