import { Box, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { PreviewSlider } from 'components/preview-slider';
import { generateUrl } from 'helpers/image';
import { t } from 'i18next';
import { PlaylistsIcon, RainIcon } from 'icons';
import { getUserCountry } from 'modules/handler';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  getSlotsTopGamesList,
  loadRecommendationsUserLobby,
  loadTopGames,
} from 'services/games/modules/slots';
import { getUserIsAuthorized } from 'services/user/modules/user';
import { Link } from 'uikit/link';

const grid = [
  { pixels: 0, count: 2 },
  { pixels: 540, count: 3 },
  { pixels: 816, count: 4 },
  { pixels: 1134, count: 5 },
];

const capitalizeFirstLetter = username => {
  if (username && /^[a-zA-Z]/.test(username)) {
    return username.charAt(0).toUpperCase() + username.slice(1);
  }
  return username;
};

const Preview = ({
  style,
  id,
  image1,
  image2,
  bg,
  bgImage,
  title,
  link,
  curator,
  textColor,
  curatorImage,
  description,
  icon,
}) => {
  const isCountrySvg = bgImage && bgImage.includes('mapsicon');

  return (
    <Box style={style} p="1" className="frame">
      <Link
        to={link}
        display="block"
        _hover={{ opacity: '0.9' }}
        rounded="xl"
        overflow="hidden"
      >
        <Box
          bg={bg}
          p="3"
          w="full"
          minH={{ base: '144px', md: '172px' }}
          maxH={{ base: '144px', md: '172px' }}
          backgroundImage={generateUrl(bgImage, { w: 800 })}
          bgSize={isCountrySvg ? '120%' : 'cover'}
          bgPos={isCountrySvg ? '30px 30px' : 'left'}
          bgRepeat="no-repeat"
          alt={id}
          pos="relative"
        >
          {bgImage ? null : (
            <Flex
              flexDir="column"
              align="center"
              gap="2"
              position="absolute"
              top="2"
              right="3"
              zIndex="0"
              transform="rotate(45deg)"
            >
              <Box
                opacity="0.75"
                h="28"
                backgroundImage={generateUrl(image1, { w: 800 })}
                w="28"
                bgColor="sugar-dust"
                rounded="xl"
                bgSize="cover"
                bgPos="center"
                bgRepeat="no-repeat"
              />
              <Box
                opacity="0.75"
                h="28"
                backgroundImage={generateUrl(image2, { w: 800 })}
                w="28"
                bgColor="sugar-dust"
                rounded="xl"
                bgSize="cover"
                bgPos="center"
                bgRepeat="no-repeat"
              />
              <Box
                h="28"
                opacity="0.75"
                backgroundImage={generateUrl(image1, { w: 800 })}
                w="28"
                bgColor="sugar-dust"
                rounded="xl"
                bgSize="cover"
                bgPos="center"
                bgRepeat="no-repeat"
              />
            </Flex>
          )}
          <Heading
            fontSize="xl"
            pos="relative"
            fontWeight="500"
            zIndex="1"
            color={textColor}
          >
            {title}
          </Heading>
        </Box>
      </Link>
      <Flex mt="2" align="center" gap="1.5">
        {icon || (
          <Image
            h="14px"
            w="14px"
            src={curatorImage}
            name="Ryhs"
            size="xs"
            rounded={
              curatorImage !==
              'https://spinkick.imgix.net/providers/small/sherbet.svg'
                ? 'full'
                : undefined
            }
          />
        )}
        <Text color="candy-floss-text">{description}</Text>
      </Flex>
    </Box>
  );
};

export const PlaylistsSlider = ({ isLoaded }) => {
  const dispatch = useDispatch();
  const isAuthorized = useSelector(getUserIsAuthorized);
  const topGames = useSelector(getSlotsTopGamesList);
  const userCountry = useSelector(getUserCountry);

  useEffect(() => {
    if (isAuthorized) {
      dispatch(loadRecommendationsUserLobby());
      dispatch(loadTopGames());
    }
  }, [dispatch, isAuthorized]);

  const dayOfWeek = new Date().getDay();
  const dayPlaylists = {
    0: {
      id: 14,
      title: t('playlists.sunday-scaries'),
      curator: 'Sherbet',
      description: t('playlists.curated-by-sherbet'),
      textColor: 'vanilla-text',
      curatorImage: 'https://spinkick.imgix.net/providers/small/sherbet.svg',
      category: [],
      link: '/playlist/sunday-scaries',
      image1:
        'https://spinkick.imgix.net/game-thumbnails/playngo/3-clown-monty.png?auto=format&w=300',
      image2: 'https://cdn.hub88.io/spinomenal/spm_bookofhorror.jpg',
      bg: '#3FA2F6',
    },
    1: {
      id: 8,
      title: t('playlists.monday-megaways'),
      curator: 'Sherbet',
      description: t('playlists.curated-by-sherbet'),
      textColor: 'vanilla-text',
      curatorImage: 'https://spinkick.imgix.net/providers/small/sherbet.svg',
      category: [],
      link: '/playlist/monday-megaways',
      image1:
        'https://spinkick.imgix.net/game-thumbnails/pragmatic-square/buffalo-king-megaways.png?auto=format&w=300',
      image2:
        'https://cdn.hub88.io/pragmatic/pgp_floatingdragonholdspinmegaways.jpg',
      bg: '#3FA2F6',
    },
    2: {
      id: 9,
      title: t('playlists.table-game-tuesday'),
      curator: 'Sherbet',
      description: t('playlists.curated-by-sherbet'),
      textColor: 'vanilla-text',
      curatorImage: 'https://spinkick.imgix.net/providers/small/sherbet.svg',
      category: [],
      link: '/casino/category/table-games',
      image1:
        'https://spinkick.imgix.net/game-thumbnails/evolution/square-freebet-blackjack.png?auto=format&w=300',
      image2:
        'https://spinkick.imgix.net/game-thumbnails/evolution/square-infinite-blackjack.png?auto=format&w=300',
      bg: '#3FA2F6',
    },
    3: {
      id: 10,
      title: t('playlists.hump-day-hits'),
      curator: 'Sherbet',
      description: t('playlists.curated-by-sherbet'),
      textColor: 'vanilla-text',
      curatorImage: 'https://spinkick.imgix.net/providers/small/sherbet.svg',
      category: [],
      link: '/casino/category/fantasy',
      image1:
        'https://spinkick.imgix.net/game-thumbnails/pragmatic-square/curse-of-the-werewolf-megaways.png?auto=format&w=300',
      image2:
        'https://spinkick.imgix.net/game-thumbnails/netent2/finn-and-the-swirly-spin.png?auto=format&w=300',
      bg: '#3FA2F6',
    },
    4: {
      id: 11,
      title: t('playlists.throwback-thursday'),
      curator: 'Sherbet',
      description: t('playlists.curated-by-sherbet'),
      textColor: 'vanilla-text',
      curatorImage: 'https://spinkick.imgix.net/providers/small/sherbet.svg',
      category: [],
      link: '/playlist/throwback-thursday',
      image1:
        'https://spinkick.imgix.net/game-thumbnails/quick-spin/sticky-bandits.png?auto=format&w=300',
      image2: 'https://cdn.hub88.io/redtiger/rtg_hothotchillipot.jpg',
      bg: '#3FA2F6',
    },
    5: {
      id: 12,
      title: t('playlists.new-release-friday'),
      curator: 'Sherbet',
      description: t('playlists.curated-by-sherbet'),
      textColor: 'vanilla-text',
      curatorImage: 'https://spinkick.imgix.net/providers/small/sherbet.svg',
      category: [],
      link: '/casino/category/new-releases',
      image1: 'https://cdn.hub88.io/hacksawgaming/hsg_holyheist.jpg',
      image2: 'https://cdn.hub88.io/bgaming/bgm_alienfruits2.jpg',
      // bgImage: 'https://ik.imagekit.io/slowlydev/covers/pink_blue_neon.png',
      bg: '#3FA2F6',
    },
    6: {
      id: 13,
      title: t('playlists.saturday-caturday'),
      curator: 'Sherbet',
      description: t('playlists.curated-by-sherbet'),
      textColor: 'vanilla-text',
      curatorImage: 'https://spinkick.imgix.net/providers/small/sherbet.svg',
      category: [],
      link: '/playlist/saturday-caturday',
      image1:
        'https://spinkick.imgix.net/game-thumbnails/playngo/big-win-cat.png?auto=format&w=300',
      image2: 'https://cdn.hub88.io/push/psh_djcat.jpg',
      bg: '#3FA2F6',
    },
  };

  const items = [
    ...(isAuthorized
      ? [
          {
            id: 1,
            title: t('playlists.daily-mix'),
            curator: 'Sherbet',
            description: t('playlists.curated-for-you'),
            textColor: 'vanilla-text',
            curatorImage:
              'https://spinkick.imgix.net/providers/small/sherbet.svg',
            icon: <RainIcon color="candy-floss-text" />,
            category: [],
            link: '/casino/category/curated-for-you',
            image1:
              'https://spinkick.imgix.net/tokens/5ccc0686-8d08-443e-807e-22abccb08834.png',
            image2:
              'https://spinkick.imgix.net/tokens/5ccc0686-8d08-443e-807e-22abccb08834.png',
            bg: 'sherbet-purple',
            bgImage: 'https://spinkick.imgix.net/banners/daily-mix.gif',
          },
        ]
      : []),
    {
      id: 2,
      title: `Popular In ${userCountry}`,
      curator: 'Sherbet',
      description: t('playlists.curated-by-sherbet'),
      textColor: 'vanilla-text',
      curatorImage: 'https://spinkick.imgix.net/providers/small/sherbet.svg',
      category: [],
      link: '/casino/category/slots',
      image1:
        'https://spinkick.imgix.net/game-thumbnails/pragmatic/square-gates-of-olympus.png?auto=format&w=300',
      image2:
        'https://spinkick.imgix.net/game-thumbnails/pragmatic/square-sweet-bonanza.png?auto=format&w=300',
      bg: '#ff6b35',
      bgImage: `https://github.com/djaiss/mapsicon/blob/master/all/${userCountry.toLowerCase()}/vector.svg?raw=true`,
    },
    dayPlaylists[dayOfWeek], // Added the playlist for the current day of the week
    {
      id: 3,
      title: t('playlists.community-made'),
      curator: 'Sherbet',
      description: t('playlists.curated-by-sherbet'),
      textColor: 'vanilla-text',
      curatorImage: 'https://spinkick.imgix.net/providers/small/sherbet.svg',
      category: [],
      link: '/playlist/community-made',
      image1:
        'https://spinkick.imgix.net/game-thumbnails/pushgaming/retro-tapes.png?auto=format&w=300',
      image2:
        'https://spinkick.imgix.net/game-thumbnails/relax/square-money-train.png?auto=format&w=300',
      bg: '#ff4d6d',
    },
    ...(isAuthorized
      ? [
          {
            id: 4,
            title: t('playlists.lucky-charms'),
            curator: 'Sherbet',
            description: t('playlists.curated-for-you'),
            textColor: 'vanilla-text',
            curatorImage:
              'https://spinkick.imgix.net/providers/small/sherbet.svg',
            icon: <RainIcon color="candy-floss-text" />,
            category: [],
            link: '/casino/category/recent-wins',
            image1: 'https://cdn.hub88.io/hacksawgaming/Xpander_Thumbnail.jpg',
            image2:
              'https://spinkick.imgix.net/game-thumbnails/relax/square-toshi-video-club.png?auto=format&w=300',
            bg: 'sherbet-purple',
            bgImage: '',
            games: topGames,
          },
        ]
      : []),
    {
      id: 5,
      title: t('playlists.ryhs-regulars'),
      curator: 'Ryhs',
      description: t('playlists.curated-by-ryhs'),
      textColor: 'vanilla-text',
      curatorImage:
        'https://spinkick.imgix.net/tokens/9a1f6b6f-4c7a-45ac-8865-5bb90ace0a87.png',
      category: [],
      link: '/playlist/ryhs-regulars',
      image1:
        'https://spinkick.imgix.net/tokens/9a1f6b6f-4c7a-45ac-8865-5bb90ace0a87.png',
      image2: 'https://cdn.hub88.io/nolimitcity/fire-in-the-hole-thumbnail.jpg',
      bg: '#B4A3AD',
    },
    {
      id: 6,
      title: t('playlists.sit-back-and-relax'),
      curator: 'Sherbet',
      description: t('playlists.curated-by-sherbet'),
      textColor: 'vanilla-text',
      curatorImage: 'https://spinkick.imgix.net/providers/small/sherbet.svg',
      category: [],
      link: '/playlist/sit-back-and-relax',
      image1: 'https://cdn.hub88.io/hacksawgaming/hsg_franksfarm.jpg',
      image2:
        'https://spinkick.imgix.net/game-thumbnails/netent2/koi-princess.png?auto=format&w=300',
      bg: '#8B8D4D',
    },
    {
      id: 7,
      title: t('playlists.pragmatic-picks'),
      curator: 'Sherbet',
      description: t('playlists.curated-by-sherbet'),
      textColor: 'vanilla-text',
      curatorImage: 'https://spinkick.imgix.net/providers/small/sherbet.svg',
      category: [],
      link: '/casino/playlist/pragmatic-picks',
      image1:
        'https://spinkick.imgix.net/game-thumbnails/pragmatic/square-gates-of-olympus.png?auto=format&w=300',
      image2:
        'https://spinkick.imgix.net/game-thumbnails/pragmatic/square-sweet-bonanza.png?auto=format&w=300',
      bg: '#B82BFF',
    },
    {
      id: 8,
      title: t('playlists.wild-wild-west'),
      curator: 'Sherbet',
      description: t('playlists.curated-by-sherbet'),
      textColor: 'vanilla-text',
      curatorImage: 'https://spinkick.imgix.net/providers/small/sherbet.svg',
      category: [],
      link: '/playlist/wild-wild-west',
      image1:
        'https://spinkick.imgix.net/game-thumbnails/relax/wanted-dead-or-a-wild.png?auto=format&w=300',
      image2:
        'https://spinkick.imgix.net/game-thumbnails/relax/square-money-train-2.png?auto=format&w=300',
      bg: 'rgb(210,68,82)',
    },
  ];

  return (
    <PreviewSlider
      titleLink="/playlists"
      isLoaded={isLoaded}
      icon={<PlaylistsIcon color="candy-floss-text" />}
      title={t('casino.slider.top-picks.title')}
      slides={items}
      SlideComponent={Preview}
      grid={grid}
    />
  );
};
