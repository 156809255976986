import { createIcon } from '@chakra-ui/icons';

export const StartingSoonIcon = createIcon({
  displayName: 'StartingSoonIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.316 0a.842.842 0 0 0 0 1.684h.842v.894a6.738 6.738 0 1 0 1.684 0v-.894h.842a.842.842 0 0 0 0-1.684H6.316ZM10.7 7.753a.842.842 0 1 0-1.191-1.19L8.436 7.635a1.684 1.684 0 1 0 1.191 1.191l1.074-1.074Zm-7.104-5.67c-.243-.396-.763-.523-1.135-.245-.329.245-.64.511-.934.798-.332.324-.287.858.066 1.16.353.3.88.255 1.22-.063.17-.16.347-.312.532-.455.366-.285.494-.799.25-1.195Zm8.806 0c.243-.396.763-.523 1.135-.245.329.245.64.511.934.798.332.324.287.858-.066 1.16-.353.3-.88.255-1.22-.063a7.61 7.61 0 0 0-.532-.455c-.366-.285-.494-.799-.251-1.195Z"
      clipRule="evenodd"
    />
  ),
});
