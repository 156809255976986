import { Box, Button, Divider, Flex, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { Link } from 'uikit/link';

export const KYCRequiredForm = ({ isRounded = true, isChat = false }) => {
  const { t } = useTranslation();
  return (
    <Box
      rounded={isRounded ? 'xl' : 'none'}
      bg={isChat ? 'toffee-base' : 'sugar-dust'}
      borderTop={isChat ? '1px solid' : undefined}
      borderColor={isChat ? 'truffle-border' : undefined}
    >
      <Box p="5">
        {isRounded && (
          <Text fontSize={{ base: 'base', md: 'lg' }} fontWeight="500">
            {t('kyc.verification-required')}
          </Text>
        )}
        <Text color="candy-floss-text">
          {t('kyc.verification-required-description')}
        </Text>
      </Box>
      <Divider
        borderColor="truffle-border"
        borderBottomWidth="1px"
        opacity="1"
      />
      <Flex p="5" align={{ base: 'center', md: 'start' }}>
        <Button as={Link} to="/user/settings/verify" colorScheme="purple">
          {t('actions.continue')}
        </Button>
      </Flex>
    </Box>
  );
};
