import {
  Button,
  Flex,
  FlexProps,
  Heading,
  Image,
  Text,
} from '@chakra-ui/react';
import { generateUrl } from 'helpers/image';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'uikit/link';

type Props = FlexProps & {
  title: React.ReactNode;
  description: React.ReactNode;
  hasButton?: boolean;
};

export const Error: React.FC<Props> = ({
  title,
  description,
  hasButton = true,
  ...props
}) => {
  const { t } = useTranslation();
  return (
    <Flex justifyContent="center" alignItems="center" flexGrow="1" {...props}>
      <Flex
        flexDirection="column"
        justifyContent="center"
        align="center"
        p="5"
        py="14"
      >
        <Image
          alt="Crying Sherbet Character"
          draggable="false"
          w="20"
          mb="4"
          src={generateUrl('/hero-images/crying-character.png')}
        />
        <Heading
          fontWeight="500"
          fontSize={{ base: 'base', md: 'lg' }}
          alignItems="center"
          mb="1"
        >
          {title}
        </Heading>
        <Text textAlign="center" color="candy-floss-text" maxW="sm" mb="5">
          {description}
        </Text>
        {hasButton && (
          <Button
            as={Link}
            to="/"
            w={{ base: 'full', md: 'auto' }}
            colorScheme="purple"
            rounded="full"
          >
            {t('app.return-dashboard')}
          </Button>
        )}
      </Flex>
    </Flex>
  );
};
