import { WS_HANDLER_MESSAGE } from 'helpers/ws';
import { userWebsocket } from 'services/user/modules/user';
import { createReducer } from 'store';
import {
  getLatestBillingAddress,
  loadDepositAddress,
  loadRate,
} from './actions';
import { CurrenciesType, CurrencyType } from './types';

const initialState: CurrenciesType = {
  isRateLoaded: false,
  currencies: {},
  currenciesByNetworks: {},
  billingAddress: null,
};

export const reducer = createReducer(initialState, {
  [loadRate.toString()]: (state, { payload }: { payload: CurrencyType[] }) => {
    state.isRateLoaded = true;

    if (payload) {
      Object.values(payload).forEach(value => {
        if (!state.currencies[value.baseAssetType]) {
          state.currencies[value.baseAssetType] = {
            asset: value.baseAssetType,
            networks: [],
          };
        }

        state.currencies[value.baseAssetType].networks.push(value.assetName);
        state.currenciesByNetworks[value.assetName] = Object.assign(
          state.currenciesByNetworks[value.assetName] || {},
          value,
        );
      });
    }
  },
  [loadDepositAddress.toString()]: (
    state,
    { payload, meta: { currencyKey, loading } },
  ) => {
    if (!state.currenciesByNetworks[currencyKey]) {
      state.currenciesByNetworks[currencyKey] = {};
    }
    state.currenciesByNetworks[currencyKey].depositAddressLoading = loading;
    if (payload) {
      state.currenciesByNetworks[currencyKey].depositAddress = payload.address;
      if (payload.tag) {
        state.currenciesByNetworks[currencyKey].tag = payload.tag;
      }
    }
  },
  [WS_HANDLER_MESSAGE(userWebsocket, 'WALLETSERVICE', 'RATE')]: (
    state,
    { payload },
  ) => {
    if (state.currenciesByNetworks[payload.assetName]) {
      state.currenciesByNetworks[payload.assetName] = Object.assign(
        state.currenciesByNetworks[payload.assetName],
        payload,
      );
    }
  },
  [WS_HANDLER_MESSAGE(userWebsocket, 'WALLETSERVICE', 'WALLETREADY')]: (
    state,
    { payload: { asset, result } },
  ) => {
    if (state.currenciesByNetworks[asset]) {
      state.currenciesByNetworks[asset].depositAddress = result.address;
      if (result.tag) {
        state.currenciesByNetworks[asset].tag = result.tag;
      }
    }
  },
  [getLatestBillingAddress.toString()]: (state, { payload }) => {
    state.billingAddress = payload;
  },
});
