import { createAction } from 'store';
import * as api from './api';

export const sendTip = createAction(
  'tip/sendTip',
  ({
    username,
    amount,
    password,
    token,
    twoFactorCode,
    loginCode,
    loginCodeId,
  }) =>
    api.sendTip({
      username,
      amount,
      password,
      token,
      loginCode,
      loginCodeId,
      twoFactorCode,
    }),
);

export const sendRainTip = createAction(
  'tip/sendRainTip',
  ({
    numberOfUsers,
    amount,
    password,
    loginCode,
    loginCodeId,
    twoFactorCode,
    channelId,
  }) =>
    api.sendRainTip({
      amount,
      numberOfUsers,
      password,
      loginCode,
      loginCodeId,
      twoFactorCode,
      channelId,
    }),
);
