import { createAction } from 'store';
import * as api from './api';

export const loadCurrentGame = createAction(
  'jackpot/loadCurrentGame',
  handlerId => () => api.loadCurrentGame(handlerId),
  handlerId => ({ handlerId }),
);

export const placeWager = createAction(
  'jackpot/placeWager',
  ({ handlerId, gameId, total, amount }) =>
    () =>
      api.placeWager({ handlerId, gameId, total, amount }),
  ({ handlerId }) => ({ handlerId, _loading: true }),
);

export const loadJackpotHistory = createAction(
  'jackpot/loadJackpotHistory',
  ({ handlerId, limit = 10, before = null, id = null }) =>
    () =>
      api.loadJackpotHistory({ handlerId, limit, before, id }),
);
