import { client, parseApiError } from 'helpers/client';

export const loadTournaments = () =>
  client
    .get(`/handler/coupon/10?command=races`)
    .then(({ data }) => data.payload);

export const joinTournament = (roundId: string) =>
  client
    .post(`/handler/coupon/10/join-round`, {
      roundId,
    })
    .catch(parseApiError);
