import { Box, Flex, Grid } from '@chakra-ui/react';
import { Seo } from 'components/seo';
import { generateUrl } from 'helpers/image';
import { SearchIcon } from 'icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { getSlotsGame, getSlotsIsLoaded } from 'services/games/modules/slots';
import { EmptyResults } from 'uikit/empty-results';
import { SearchInput } from 'uikit/search-input';
import { SearchContent } from '../search/components/search-content';
import { Game } from './components/game';

const BottomIcons = ({ isMulti, onMulti, buttonRef }) => (
  <Flex align="center" gap="5">
    {game?.hasDemo && (
      <Flex
        alignItems="center"
        cursor="pointer"
        onClick={handleChangeDemo}
        color="candy-floss-text"
        _hover={{ opacity: 0.9 }}
      >
        <Text
          mr="2"
          color="candy-floss-text"
          lineHeight="14px"
          fontWeight="500"
        >
          {t('casino.demo-mode')}
        </Text>
        <CheckIcon color={isReal ? 'candy-floss-text' : 'green.500'} />
      </Flex>
    )}
    {IS_PIP_AVAILABLE && (
      <Tooltip
        gutter="10"
        hasArrow
        fontSize="md"
        rounded="md"
        placement="top"
        bg="vanilla-text"
        fontWeight="500"
        px="2"
        color="toffee-base"
        label={t('actions.pop-out')}
      >
        <IconButton
          aria-label="Pop Out"
          ref={buttonRef}
          size="sm"
          minW="0"
          color="candy-floss-text"
          variant="link"
          rounded="full"
          icon={<PopoutIcon />}
        />
      </Tooltip>
    )}
    {!isMulti && (
      <IconButton
        aria-label="Multi View"
        size="sm"
        minW="0"
        color="candy-floss-text"
        variant="link"
        rounded="full"
        icon={<MultiViewIcon />}
        onClick={onMulti}
      />
    )}
  </Flex>
);

export const CasinoGame = () => {
  const { gameId } = useParams();
  const isGamesLoaded = useSelector(getSlotsIsLoaded);
  const game = useSelector(getSlotsGame(gameId));
  const [games, setGames] = useState([]);
  const [isMulti, setIsMulti] = useState(false);
  const [search, setSearch] = useState('');
  const { t } = useTranslation();

  const handleSetIsMulti = () => {
    if (isMulti) {
      setIsMulti(false);
      setGames([]);
    } else {
      setIsMulti(true);
      setGames([gameId]);
    }
  };

  const handleGameClick = id => {
    if (!games.includes(id)) {
      setGames([...games, id]);
    }
  };

  const handleGameClose = id => () => {
    if (games.length === 1) {
      handleSetIsMulti();
    } else {
      setGames(prev => prev.filter(item => item !== id));
    }
  };

  useEffect(() => {
    setIsMulti(false);
    setGames([]);
  }, [gameId]);

  if (!game && isGamesLoaded) {
    return <Navigate to="/" />;
  }

  let seoContent = null;

  if (isGamesLoaded) {
    const title = t('seo.game.title', {
      game: game.title,
      provider: t(`casino.provider.${game.provider}`, game.provider),
    });
    const description = t('seo.game.description', {
      game: game.title,
      provider: t(`casino.provider.${game.provider}`, game.provider),
    });
    seoContent = (
      <Seo
        title={title}
        description={description}
        image={generateUrl(game.image, { w: 500 })}
        schema={{
          '@type': 'WebPage',
          '@id': window.location.href,
          url: window.location.href,
          name: title,
          isPartOf: {
            '@id': 'https://sherbet.com/#website',
          },
          primaryImageOfPage: {
            '@id': `${window.location.href}/#primaryimage`,
          },
          image: {
            '@id': `${window.location.href}/#primaryimage`,
          },
          thumbnailUrl: game.image || game.providerImage,
          description,
          breadcrumb: {
            '@id': `${window.location.href}/#breadcrumb`,
          },
          potentialAction: [
            {
              '@type': 'ReadAction',
              target: [window.location.href],
            },
          ],
        }}
      />
    );
  }

  return (
    <>
      {seoContent}
      {isMulti ? (
        <Flex
          flexDirection="column"
          gap="7"
          maxW="6xl"
          p="5"
          w="full"
          mx="auto"
        >
          <Grid
            templateColumns={{
              base: 'repeat(1, 1fr)',
              xl: 'repeat(2, 1fr)',
            }}
            gap="5"
            gridAutoRows="1fr"
          >
            {games.map(id => (
              <Box key={id} width="full" id={id}>
                <Game
                  gameId={id}
                  onClose={handleGameClose(id)}
                  isMulti
                  onMulti={handleSetIsMulti}
                />
              </Box>
            ))}
            {games.length < 4 && (
              <Flex bg="sugar-dust" align="center" justifyContent="center">
                <EmptyResults
                  icon={SearchIcon}
                  title="Search for a game"
                  description="Add a game below"
                />
              </Flex>
            )}
          </Grid>
          {games.length < 4 && (
            <Box>
              <SearchInput
                value={search}
                onChange={setSearch}
                placeholder={t('casino.search')}
              />
              {search.length >= 3 && <Box mt="5" />}
              <SearchContent
                search={search}
                onGameClick={handleGameClick}
                hideSlotsSlider={true}
              />
            </Box>
          )}
        </Flex>
      ) : (
        <Game
          gameId={gameId}
          onMulti={handleSetIsMulti}
          isLoaded={isGamesLoaded}
        />
      )}
    </>
  );
};
