import { PreviewSlider } from 'components/preview-slider';
import { BlogIcon } from 'icons';
import shuffle from 'lodash.shuffle';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getBlogIsLoaded,
  getBlogPosts,
  loadPosts,
} from 'services/cms/modules/blog';
import { useActions } from 'store';
import { BlogPreview } from '../blog-preview';

export const BlogSlider = () => {
  const postsList = useSelector(getBlogPosts);
  const isLoaded = useSelector(getBlogIsLoaded);
  const actions = useActions({ loadPosts });
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (!isLoaded) {
      actions.loadPosts(i18n.language).then(posts => {
        if (posts.length === 0 && i18n.language !== 'en') {
          actions.loadPosts('en');
        }
      });
    }
  }, [isLoaded, i18n.language, actions]);

  const shuffledPostsList = useMemo(
    () => shuffle([...postsList]),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [postsList.length],
  );

  return (
    <PreviewSlider
      autoplay
      icon={<BlogIcon color="candy-floss-text" />}
      isLoaded={isLoaded}
      title={t('sidebar.blog')}
      titleLink="/blog"
      isIgnoreGap
      slides={shuffledPostsList
        .slice(0, 6)
        .map(item => ({ ...item, prefix: '/blog' }))}
      SlideComponent={BlogPreview}
      gridSize="1-3"
    />
  );
};
