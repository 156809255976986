import { Button, extendTheme } from '@chakra-ui/react';
import { BeatLoader } from 'uikit/beat-loader';
import { ButtonTheme } from './buttons';

Button.defaultProps = {
  ...Button.defaultProps,
  spinner: <BeatLoader />,
};

export const theme = extendTheme({
  breakpoints: {
    xxl: '1750px',
  },
  config: {
    initialColorMode: 'dark',
    useSystemColorMode: false,
  },
  fonts: {
    heading: 'Inter, sans-serif',
    body: 'Inter, sans-serif',
    thumb: 'Jockey, sans-serif',
  },
  fontSizes: {
    '4xl': '32px',
    '2xl': '24px',
    xl: '18px',
    lg: '16px',
    md: '14px',
    sm: '12px',
    xs: '10px',
  },
  semanticTokens: {
    colors: {
      'toffee-base': {
        default: 'var(--colors-toffee-base)',
      },
      'marshmallow-accent': {
        default: 'var(--colors-marshmallow-accent)',
      },
      'sugar-dust': {
        default: 'var(--colors-sugar-dust)',
      },
      'burnt-sugar': 'var(--colors-burnt-sugar)',
      'chocolate-input': {
        default: 'var(--colors-chocolate-input)',
      },
      'vanilla-text': {
        default: 'var(--colors-vanilla-text)',
      },
      'candy-floss-text': {
        default: 'var(--colors-candy-floss-text)',
      },
      'truffle-border': {
        default: 'var(--colors-truffle-border)',
      },
      'truffle-border-hover': 'var(--colors-truffle-border-hover)',
    },
  },
  colors: {
    'sherbet-purple': 'var(--colors-sherbet-purple)',
    'sherbet-green': {
      100: 'var(--colors-sherbet-green-100)',
      200: 'var(--colors-sherbet-green-200)',
    },
    'sherbet-orange': {
      100: 'var(--colors-sherbet-orange-100)',
      200: 'var(--colors-sherbet-orange-200)',
    },
    'sherbet-red': 'var(--colors-sherbet-red)',
  },
  shadows: {
    outline: `var(--colors-shadows-outline-color) 0px 0px 0px 4px`,
    sherb: {
      default: `
        var(--colors-shadows-default-color) 0px 0px 0px 1px,
        var(--colors-shadows-default-color) 0px 2px 8px,
        var(--colors-shadows-inset-color) 0px 0px 0px 1px inset
      `,
      _light: `
        var(--colors-shadows-light-color) 0px 0px 0px 1px,
        var(--colors-shadows-light-color) 0px 2px 8px,
        var(--colors-shadows-inset-color) 0px 0px 0px 1px inset
      `,
    },
    tip: {
      default: `
        var(--colors-shadows-tip-color) 0px 0px 0px 1px,
        var(--colors-shadows-tip-inset-color) 0px 0px 0px 1px inset
      `,
      _light: `
        var(--colors-shadows-light-color) 0px 0px 0px 1px inset
      `,
    },
  },
  radii: {
    m: '4px',
    ms: '6px',
    l: '8px',
    xl: '8px',
  },
  transition: {
    property: {
      common: 'none',
    },
    duration: {
      ultraFast: '0s',
      fast: '0s',
      normal: '0s',
      slow: '0s',
      ultraSlow: '0s',
    },
  },
  components: {
    Heading: {
      baseStyle: {
        fontSize: 'xl',
        fontWeight: 500,
        color: 'vanilla-text',
      },
    },
    Radio: {
      baseStyle: {
        control: {
          rounded: 'full',
          backgroundColor: 'sugar-dust',
          border: '1px solid',
          borderColor: 'truffle-border',
          _checked: {
            bg: 'sherbet-purple',
            color: 'vanilla-text',
            border: 'none',
            _hover: { opacity: '0.9', bg: 'sherbet-purple' },
          },
        },
      },
    },
    Select: {
      variants: {
        secondary: {
          field: {
            cursor: 'pointer',
            backgroundColor: 'sugar-dust',
            color: 'vanilla-text',
            border: '1px solid',
            borderColor: 'truffle-border',
            _hover: { borderColor: 'truffle-border-hover' },
            _focus: {
              borderColor: 'sherbet-purple',
              boxShadow:
                'var(--colors-shadow-default) 0px 0px 0px 4px !important',
            },
            rounded: 'xl',
            _placeholder: { color: 'candy-floss-text' },
            _disabled: {
              opacity: 0.5,
            },
          },
        },
      },
      baseStyle: {
        field: {
          cursor: 'pointer',
          backgroundColor: 'sugar-dust',
          color: 'vanilla-text',

          border: '1px solid',
          borderColor: 'truffle-border',
          _hover: { borderColor: 'milkshake-hover' },
          _focus: {
            borderColor: 'sherbet-purple',
            boxShadow: 'var(--colors-shadow-default) 0px 0px 0px 4px',
          },
          rounded: 'xl',
          _placeholder: { color: 'candy-floss-text' },
        },
      },
    },
    Input: {
      variants: {
        primary: {
          field: {
            backgroundColor: 'chocolate-input',
            color: 'vanilla-text',

            border: '1px solid',
            borderColor: 'truffle-border',
            _hover: { borderColor: 'truffle-border-hover' },
            _focus: {
              borderColor: 'sherbet-purple',
            },
            rounded: 'xl',
            _placeholder: { color: 'candy-floss-text' },
            _disabled: {
              opacity: 0.5,
            },
          },
        },
        secondary: {
          field: {
            backgroundColor: 'sugar-dust',
            color: 'vanilla-text',

            border: '1px solid',
            borderColor: 'truffle-border',
            _hover: { borderColor: 'truffle-border-hover' },
            _focus: {
              borderColor: 'sherbet-purple',
            },
            rounded: 'xl',
            _placeholder: { color: 'candy-floss-text' },
            _disabled: {
              opacity: 0.5,
            },
          },
        },
      },
      baseStyle: {
        field: {
          backgroundColor: 'chocolate-input',
          color: 'vanilla-text',

          border: '1px solid',
          borderColor: 'truffle-border',
          _hover: { borderColor: 'milkshake-hover' },
          _focus: {
            borderColor: 'sherbet-purple',
          },
          rounded: 'xl',
          _placeholder: { color: 'candy-floss-text' },
        },
      },
    },
    Text: {
      baseStyle: {
        color: 'vanilla-text',
        fontSize: 'md',
      },
    },
    Button: ButtonTheme,
    Badge: {
      baseStyle: {
        borderRadius: 'xl',
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          borderRadius: {
            base: 'none',
            lg: 'md',
          },
          backgroundColor: '#1B1C30',
          overflow: 'hidden',
        },
      },
      sizes: {
        full: {
          dialogContainer: {
            '@supports(height: -webkit-fill-available)': {},
            bottom: 0,
            height: 'auto',
            alignItems: 'flex-start',
          },
          dialog: {
            '@supports(min-height: -webkit-fill-available)': {
              minHeight: '100%',
            },
            borderRadius: 'md',
            height: 'calc(100% - 40px)',
            maxHeight: 'none',
          },
        },
      },
    },
    Switch: {
      baseStyle: {
        track: {
          border: '1px solid',
          borderColor: 'truffle-border',
          backgroundColor: 'sugar-dust',
          '&[data-checked]': {
            background: 'sherbet-green.200',
          },
          _hover: {
            opacity: '0.9',
          },
        },
        thumb: {
          backgroundColor: '',
          '&[data-checked]': {
            background: '#efeff1',
          },
        },
      },
    },
    Checkbox: {
      baseStyle: {
        control: {
          borderWidth: '1px',
          borderColor: 'truffle-border',
          borderRadius: 'full',
          backgroundColor: 'transparent',
          '&[data-checked]': {
            color: 'vanilla-text',
            borderColor: 'sherbet-purple',
            bg: 'sherbet-purple',
            _hover: {
              borderColor: 'sherbet-purple',
              bg: 'sherbet-purple',
              opacity: '0.9',
            },
          },
          _hover: {
            borderColor: 'sherbet-purple',
            opacity: '0.9',
          },
        },
        icon: {
          display: 'none',
        },
      },
    },
    Skeleton: {
      baseStyle: {
        rounded: 'xl',
      },
    },
    Menu: {
      parts: ['button', 'list', 'item'],
      baseStyle: {
        item: {
          rounded: '8px',
          color: 'vanilla-text',
          _hover: { bg: 'sugar-dust' },
          height: '40px',
          bg: 'transparent',
          _active: {
            bg: 'sherbetPurple',
          },
          fontWeight: '500',
        },
        list: {
          overflow: 'auto',
          border: '1px solid',
          borderColor: 'truffle-border',
          background: 'marshmallow-accent',
          rounded: 'xl',
          padding: '2',
          boxShadow: 'none',
          maxH: '300px',
          transform: 'scale(1) !important',
          opacity: '1 !important',
        },
        button: {
          fontWeight: '500',
          height: '40px',
          px: '4',
          bg: 'sugar-dust',
          rounded: 'xl',
          color: 'vanilla-text',
          _hover: {
            opacity: '0.9',
            bg: 'sugar-dust',
          },
          _disabled: {
            opacity: '0.5',
          },
          _active: {
            opacity: '0.9',
            bg: 'sugar-dust',
          },
        },
      },
    },
  },
});
