import { createReducer } from 'store';

import { WS_HANDLER_MESSAGE } from 'helpers/ws';
import { userWebsocket } from 'services/user/modules/user';
import { addRoll, loadGame, loadPreviousRolls, placeBet } from './actions';
import { RouletteType } from './types';

const initialState: RouletteType = {
  game: null,
  rolls: [],
  isLoaded: false,
  placeBetLoading: {},
};

export const reducer = createReducer(initialState, {
  [loadGame.toString()]: (state, { payload }) => {
    state.game = { ...payload };

    if (state.game && payload.time !== undefined) {
      state.game.data.time = new Date().getTime() + payload.time;
    }

    state.isLoaded = true;
  },

  [loadPreviousRolls.toString()]: (state, { payload }) => {
    state.rolls = payload;
  },

  [addRoll.toString()]: (state, { payload: { roll } }) => {
    if (state.rolls) {
      if (state.rolls.some(x => x.gameId === roll.gameId)) {
        return;
      }

      state.rolls = [roll, ...state.rolls.slice(0, -1)];
    }
  },

  [placeBet.toString()]: (state, { meta: { pickedGroup, loading } }) => {
    state.placeBetLoading = {
      ...state.placeBetLoading,
      [pickedGroup]: loading,
    };
  },

  [WS_HANDLER_MESSAGE(userWebsocket, 'ROULETTE', 'NEWROULETTE')]: (
    state,
    { payload },
  ) => {
    if (state.rolls) {
      if (state.game) {
        const game = state.game.data;

        if (game && game.complete) {
          if (!state.rolls.some(x => x.gameId === game.gameId)) {
            const roll = {
              gameId: game.gameId,
              rollNumber: game.complete.rollNumber,
              bonusNumber:
                game.complete.bonusRollNumber === game.bonusNumber &&
                game.complete.bonusRollNumber === game.complete.rollNumber
                  ? game.bonusNumber
                  : undefined,
            };

            state.rolls = [roll, ...state.rolls.slice(0, -1)];
          }
        }
      }
    }

    state.game = payload;

    if (state.game && payload.time !== undefined) {
      state.game.data.time = new Date().getTime() + payload.time;
    }
  },

  [WS_HANDLER_MESSAGE(userWebsocket, 'ROULETTE', 'ROULETTEOVER')]: (
    state,
    { payload },
  ) => {
    state.game = payload;

    if (state.game && payload.time !== undefined) {
      state.game.data.time = new Date().getTime() + payload.time;
    }
  },

  [WS_HANDLER_MESSAGE(userWebsocket, 'ROULETTE', 'WAGER')]: (
    state,
    { payload },
  ) => {
    state.game = payload;

    if (state.game && payload.time !== undefined) {
      state.game.data.time = new Date().getTime() + payload.time;
    }
  },
});
