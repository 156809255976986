import { createIcon } from '@chakra-ui/icons';

export const ArrowDownIcon = createIcon({
  displayName: 'ArrowDownIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M.335 3.763a1.143 1.143 0 0 1 1.616 0L8 9.813l6.049-6.05a1.143 1.143 0 0 1 1.616 1.617l-6.857 6.857a1.143 1.143 0 0 1-1.616 0L.335 5.38a1.143 1.143 0 0 1 0-1.617Z"
      clipRule="evenodd"
    />
  ),
});
