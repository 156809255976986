import { createReducer } from 'store';
import { loadBanner, loadPage, loadSidebar } from './actions';
import { BannerType, PageType, PagesType } from './types';

const initialState: PagesType = {
  pages: {},
  banners: {},
  sidebar: [],
  isSidebarLoaded: false,
};

export const reducer = createReducer(initialState, {
  [loadPage.toString()]: (
    state,
    { payload: [page] }: { payload: [PageType] },
  ) => {
    if (page) {
      state.pages[page.type] = page;
    }
  },
  [loadBanner.toString()]: (
    state,
    { payload: [banner] }: { payload: [BannerType] },
  ) => {
    if (banner) {
      state.banners[banner.id] = banner;
    }
  },
  [loadSidebar.toString()]: (state, { payload: sidebar }) => {
    state.sidebar = sidebar;
    state.isSidebarLoaded = true;
  },
});
