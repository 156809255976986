import { Box, Button, Flex, Grid } from '@chakra-ui/react';
import { PlayerRow } from 'components/player-row';
import { VERSUS_HANDLER_ID } from 'constants/general';
import { formatCredit } from 'helpers/numeral';
import { MODAL_TYPE, useModal } from 'modals';
import { getHandlerSettings } from 'modules/handler';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getVersusUsers,
  placeWager,
  setFinished,
} from 'services/games/modules/versus';
import { VERSUS_COLORS } from 'services/games/pages/versus/constants';
import { getUserId, getUserIsAuthorized } from 'services/user/modules/user';
import { useActions } from 'store';
import { toastError } from 'toasts';
import { ConfirmButton } from 'uikit/confirm-button';
import { UserAvatarGroup } from 'uikit/user-avatar-group';
import { HorizontalSpinner } from './components/horizontal-spinner';

export const Game = ({ game, isFinished }) => {
  const { t } = useTranslation();
  const actions = useActions({ placeWager, setFinished });
  const { onOpen: loginModalOpen } = useModal(MODAL_TYPE.AUTH);
  const isAuthorized = useSelector(getUserIsAuthorized);
  const settings = useSelector(getHandlerSettings(VERSUS_HANDLER_ID));
  const userId = useSelector(getUserId);
  const users = useSelector(getVersusUsers);
  const [loading, setLoading] = useState(-1);
  const { gameId } = game;

  const wageredUsers = useMemo(
    () =>
      game.wagers.map(w => ({
        ...w,
        user: users[w.userId],
      })),
    [users, game.wagers],
  );

  useEffect(() => {
    if (!isFinished && game.details.winner) {
      const timeout = setTimeout(
        () => actions.setFinished(gameId),
        settings.spinTime + settings.leavableAfter,
      );
      return () => clearTimeout(timeout);
    }

    return undefined;
  }, [
    game.details.winner,
    isFinished,
    gameId,
    actions,
    settings.spinTime,
    settings.leavableAfter,
  ]);

  const handlePlaceBet =
    ({ position, isBotInvite = false }) =>
    async () => {
      if (!isAuthorized) {
        loginModalOpen();
        return;
      }

      setLoading(position);

      try {
        await actions.placeWager({
          gameId,
          amount: game.value,
          position,
          isBotInvite,
        });
      } catch (errors) {
        toastError({
          title: t(`errors.BE.${errors?.global}`),
          description: t(`errors.BE.${errors?.global}_DESCRIPTION`, ''),
        });
      } finally {
        setLoading(-1);
      }
    };

  const hasMyWager = game.wagers.some(w => w.userId === userId);
  const isMyGame = game.creatorId === userId;

  return (
    <Box bg="sugar-dust" p="2" rounded="xl">
      <Flex
        rounded="xl"
        bg="sugar-dust"
        p="5"
        gap="5"
        direction="column"
        opacity={isFinished ? '0.5' : undefined}
        _hover={isFinished ? { opacity: '1' } : undefined}
      >
        <Flex align="center" gap="2" justifyContent="space-between">
          <Flex align="center" gap="2">
            <UserAvatarGroup
              size="sm"
              max="4"
              users={wageredUsers.map(({ user }) => user)}
            />
          </Flex>
          <Flex align="center" gap="2">
            <Button size="sm" rounded="full">
              ${formatCredit(game.value)}
            </Button>
          </Flex>
        </Flex>
        <Flex
          justifyContent="space-between"
          direction="column"
          gap="5"
          flexGrow="1"
        >
          <HorizontalSpinner
            maxPlayers={game.players}
            users={wageredUsers}
            totalValue={game.value * game.players}
            winner={game.details.winner}
            spinTime={settings.spinTime}
          />

          <Grid
            templateColumns="repeat(2, 1fr)"
            mt="auto"
            columnGap="2"
            rowGap="2"
          >
            {VERSUS_COLORS.slice(0, game.players).map((color, index) => {
              const item = wageredUsers.find(
                ({ position }) => position === index,
              );

              if (item) {
                return (
                  <Flex
                    h="40px"
                    px="4"
                    align="center"
                    bg="sugar-dust"
                    rounded="full"
                    gap="2"
                    key={color}
                  >
                    <Box w="14px" h="14px" rounded="full" bg={color} />
                    <PlayerRow user={item.user} key={item.position} />
                  </Flex>
                );
              }

              if (!isMyGame && hasMyWager) {
                return (
                  <ConfirmButton
                    isDisabled
                    key={color}
                    size="md"
                    rounded="full"
                  >
                    {t('actions.join-game')}
                  </ConfirmButton>
                );
              }

              return (
                <ConfirmButton
                  key={color}
                  colorScheme="purple"
                  rounded="full"
                  onClick={handlePlaceBet({
                    position: index,
                    isBotInvite: isMyGame,
                  })}
                  isLoading={loading === index}
                >
                  {isMyGame ? t('actions.invite-bot') : t('actions.join-game')}
                </ConfirmButton>
              );
            })}
            {game.players < 3 ? <Box h="40px" /> : null}
          </Grid>
        </Flex>
      </Flex>
    </Box>
  );
};
