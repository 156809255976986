import { SlotsIcon } from 'icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GameSlider } from 'services/games/components/game-slider';
import {
  getSlotsCasinoGames,
  getSlotsIsLoaded,
} from 'services/games/modules/slots';

export const SlotsSlider = () => {
  const { t } = useTranslation();
  const games = useSelector(getSlotsCasinoGames);
  const isLoaded = useSelector(getSlotsIsLoaded);

  return (
    <GameSlider
      icon={<SlotsIcon color="candy-floss-text" />}
      slides={games}
      isLoaded={isLoaded}
      title={t('casino.slider.slots.title')}
      titleLink="/casino/category/slots"
    />
  );
};
