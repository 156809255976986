import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { CustomInput } from 'forms/custom-input';
import { CustomSelect } from 'forms/custom-select';
import { trackEvent } from 'helpers/analytics';
import { formatCredit, toNumber } from 'helpers/numeral';
import { useToastError } from 'hooks/use-toast-error';
import { ConnectionIcon } from 'icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { KYCRequiredForm } from 'services/user/components/kyc-required-form';
import { VerifyEmailForm } from 'services/user/components/verify-email-form';
import {
  NETWORK_NAMES,
  getFeeByType,
  getRateIsLoaded,
  loadRate,
  useGetCurrencyNetworks,
  withdraw,
} from 'services/user/modules/currencies';
import {
  get2faInfo,
  getUser2FAEnabled,
  getUserEmailConfirmed,
  getUserRequiredVerify,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { toastError, toastSuccess } from 'toasts';
import * as Yup from 'yup';

export const WithdrawView = ({ name, shortcut, fields = [], onClose }) => {
  const [emailCodeId, setEmailCodeId] = useState(false);
  const is2FAEnabled = useSelector(getUser2FAEnabled);

  const networks = useGetCurrencyNetworks(shortcut, true);
  const rateIsLoaded = useSelector(getRateIsLoaded);
  // const currenciesNetworks = useSelector(getCurrencyByNetworks);
  const { t } = useTranslation();
  const actions = useActions({ withdraw, get2faInfo, loadRate });
  const toastErrorCommon = useToastError();

  useEffect(() => {
    if (!rateIsLoaded) {
      actions.loadRate();
    }
  }, [actions, rateIsLoaded]);

  useEffect(() => {
    actions.get2faInfo().then(console.warn);
  }, [actions]);

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      walletAddress: '',
      amount: '',
      network: '',
    },
    validationSchema: Yup.object({
      walletAddress: Yup.string().required('errors.wallet.required'),
      ...fields.reduce((acc, field) => {
        if (field.required) {
          return {
            ...acc,
            [field.name]: Yup.string().required('errors.required'),
          };
        }
        return {};
      }, {}),
      amount: Yup.string().required('required'),
      ...(is2FAEnabled
        ? {
            twoFactorCode: Yup.string().required(t('validations.required')),
          }
        : {}),
      ...(emailCodeId
        ? {
            emailCode: Yup.string().required(t('validations.required')),
          }
        : {}),
      network: Yup.string().required('required'),
    }),
    onSubmit: async (
      {
        walletAddress,
        amount: _amount,
        password,
        twoFactorCode,
        emailCode,
        network,
        ...rest
      },
      { resetForm },
    ) => {
      try {
        let result;
        const amount = toNumber(_amount);
        if (emailCodeId) {
          result = await actions.withdraw({
            loginCode: emailCode,
            loginCodeId: emailCodeId,
          });
        } else if (is2FAEnabled) {
          result = await actions.withdraw({
            amount,
            assetId: network,
            address: walletAddress,
            password,
            twoFactorCode,
            ...rest,
          });
        } else {
          result = await actions.withdraw({
            amount,
            assetId: network,
            address: walletAddress,
            password,
            ...rest,
          });
        }

        if (result?.isSentLoginCode) {
          setEmailCodeId(result.loginCodeId);
        } else {
          toastSuccess({
            title: t('withdraw.requested'),
            description: t('withdraw.requested-description', { amount }),
          });
          setEmailCodeId(false);
          resetForm();
          trackEvent('withdraw', { amount, currency: network });
        }
      } catch (errors) {
        if (errors?.global === 'NOT_ENOUGH_BALANCE_BY_WITHDRAW_HOLD') {
          const { withdrawhold } = errors;
          toastError({
            title: t('errors.BE.NOT_ENOUGH_BALANCE_BY_WITHDRAW_HOLD'),
            description: t(
              'errors.BE.NOT_ENOUGH_BALANCE_BY_WITHDRAW_HOLD_DESCRIPTION',
              {
                amount: `$${formatCredit(Number(withdrawhold))}`,
              },
            ),
          });
        } else if (errors) {
          toastErrorCommon(errors);
        }
      }
    },
  });

  const firstNetwork = networks[0]?.assetName;

  useEffect(() => {
    if (!formik.values.network && firstNetwork) {
      formik.setValues({ network: firstNetwork });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.network, firstNetwork, formik.setValues]);

  const fee = useSelector(getFeeByType(formik.values.network || ''));

  const isEmailConfirmed = useSelector(getUserEmailConfirmed);
  const isVerifyRequired = useSelector(getUserRequiredVerify);

  if (!isEmailConfirmed) {
    return <VerifyEmailForm isRounded={false} />;
  }
  if (isVerifyRequired) {
    return <KYCRequiredForm isRounded={false} />;
  }

  return (
    <Flex direction="column">
      <Flex direction="column">
        <FormControl as="form" onSubmit={formik.handleSubmit} w="full">
          <Box p="5">
            <Flex gap="3" direction="column" w="full">
              {emailCodeId ? (
                <CustomInput
                  key="emailCodeId"
                  label={t('form.label.verification-code')}
                  placeholder={t('form.label.verification-code')}
                  type="text"
                  inputMode="numeric"
                  name="emailCode"
                  required
                  autoComplete="one-time-code"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.emailCode}
                  isInvalid={
                    formik.errors.emailCode && formik.touched.emailCode
                  }
                  errorMessage={formik.errors.emailCode}
                />
              ) : (
                <>
                  <CustomSelect
                    name="network"
                    label={t('nft.network')}
                    value={formik.values.network}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    options={networks.map(({ assetName, blockchainName }) => ({
                      value: assetName,
                      title: NETWORK_NAMES[blockchainName] || blockchainName,
                    }))}
                  />
                  {!!formik.values.network && (
                    <CustomInput
                      label={t('withdraw-form.receiving-address', { name })}
                      placeholder={t('withdraw-form.receiving-address', {
                        name,
                      })}
                      type="text"
                      name="walletAddress"
                      required
                      boxProps={{
                        w: 'full',
                      }}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.walletAddress}
                      isInvalid={
                        formik.errors.walletAddress &&
                        formik.touched.walletAddress
                      }
                      errorMessage={formik.errors.walletAddress}
                      isRequired
                    />
                  )}

                  {fields.map(field => (
                    <CustomInput
                      key={field.name}
                      label={field.label}
                      placeholder={field.label}
                      type="text"
                      name={field.name}
                      required={field.required}
                      autoComplete="one-time-code"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values[field.name]}
                      isInvalid={
                        formik.errors[field.name] && formik.touched[field.name]
                      }
                      errorMessage={formik.errors[field.name]}
                    />
                  ))}
                  <CustomInput
                    label={t('withdraw-form.amount')}
                    placeholder={t('withdraw-form.amount')}
                    type="text"
                    inputMode="decimal"
                    name="amount"
                    required
                    autoComplete="one-time-code"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.amount}
                    isInvalid={formik.errors.amount && formik.touched.amount}
                    errorMessage={formik.errors.amount}
                  />
                  {is2FAEnabled && (
                    <CustomInput
                      key="twoFactorCode"
                      label={t('form.label.two-factor-code')}
                      placeholder={t('form.label.two-factor-code')}
                      type="text"
                      inputMode="numeric"
                      name="twoFactorCode"
                      required
                      autoCorrect="off"
                      spellCheck="false"
                      autoCapitalize="none"
                      autoComplete="one-time-code"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.twoFactorCode}
                      isInvalid={
                        formik.errors.twoFactorCode &&
                        formik.touched.twoFactorCode
                      }
                      errorMessage={formik.errors.twoFactorCode}
                    />
                  )}
                </>
              )}
            </Flex>
          </Box>
          <Divider
            borderColor="truffle-border"
            borderBottomWidth="1px"
            opacity="1"
          />
          <Flex textAlign="left" w="full" p="5">
            <Flex align="center" gap="5">
              <Button
                type="submit"
                colorScheme="purple"
                isLoading={formik.isSubmitting}
                w="auto"
                flexShrink="0"
              >
                {t('actions.withdraw')}
              </Button>
              {fee && (
                <Flex align="center" gap="2" color="candy-floss-text">
                  <ConnectionIcon />
                  <Text color="candy-floss-text">
                    {t('wallet.network-fee')}:{' '}
                    {fee < 0.01
                      ? `~$${formatCredit(0.01)}`
                      : `$${formatCredit(fee)}`}
                  </Text>
                </Flex>
              )}
            </Flex>
          </Flex>
        </FormControl>
      </Flex>
    </Flex>
  );
};
