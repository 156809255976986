import { Box, Button, Flex, Heading, Image, Text } from '@chakra-ui/react';
import { PlayerRow } from 'components/player-row';
import { formatCredit } from 'helpers/numeral';
import { useToastError } from 'hooks/use-toast-error';
import { RecentIcon } from 'icons';
import { MODAL_TYPE, useModal } from 'modals';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useTournamentPrize } from 'services/games/hooks/use-tournament-prize';
import {
  TOURNAMENT_CONTENT_BY_TYPE,
  joinTournament,
} from 'services/games/modules/tournaments';
import { getUserIsAuthorized } from 'services/user/modules/user';
import { useActions } from 'store';
import { Errors, UserType } from 'types';
import { Countdown } from 'uikit/countdown';
import { Link } from 'uikit/link';
import { Odometer } from 'uikit/odometer';
import { Skeleton } from 'uikit/skeleton';
import { Table } from 'uikit/table';

const prizePoolPercentages = [0.4, 0.2, 0.1];

type Props = {
  style: React.CSSProperties;
  isSkeleton?: boolean;
  currentPrizePool: number;
  feType: string;
  joined?: boolean;
  roundEndDate: string;
  maxPossiblePrizePool: number;
  isOptIn: boolean;
  roundId: string;
  entries: {
    user: UserType;
    score: number;
    prize: number;
    rank: number;
  }[];
};

export const TournamentPreview: React.FC<Props> = ({
  style,
  isSkeleton,
  currentPrizePool,
  feType,
  entries = [],
  joined = true,
  roundEndDate,
  maxPossiblePrizePool,
  isOptIn,
  roundId,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const actions = useActions({ joinTournament });
  const isAuthorized = useSelector(getUserIsAuthorized);

  const { image, isMultiplier, color, title, description, link, isAnimate } =
    TOURNAMENT_CONTENT_BY_TYPE[
      (feType ?? 'DAILY') as keyof typeof TOURNAMENT_CONTENT_BY_TYPE
    ];

  const actualPrize = useTournamentPrize(isAnimate && !isSkeleton, {
    currentPrizePool,
    maxPossiblePrizePool,
    roundEndDate,
  });

  const toastError = useToastError();

  const { onOpen } = useModal(MODAL_TYPE.AUTH);

  const handleJoin = async () => {
    if (!isAuthorized) {
      onOpen();
      return;
    }
    setIsLoading(true);
    try {
      await actions.joinTournament(roundId);
    } catch (error) {
      toastError(error as Errors);
    } finally {
      setIsLoading(false);
    }
  };

  const fields = [
    {
      key: 'player',
      title: t('race.player'),
      textAlign: 'left' as const,
    },
    {
      key: 'wager',
      title: (() => {
        if (feType === 'DOWNBUTNOTOUT') return t('race.points');
        if (isMultiplier) return t('race.multiplier');
        return t('race.points');
      })(),
      textAlign: 'right' as const,
    },
    {
      key: 'prize',
      title: t('race.prize'),
      textAlign: 'right' as const,
      display: { base: 'none', lg: 'table-cell' },
    },
  ];

  const data = useMemo(() => {
    const listOfData = [];
    if (!entries) {
      return [];
    }
    for (let i = 0; i < Math.min(3, entries.length); i += 1) {
      const item = entries[i];

      if (item) {
        const { user, score, prize, rank } = item;
        listOfData[i] = {
          place: (
            <Text align="center" color="candy-floss-text">
              {t('number.ordinal', {
                count: rank,
                ordinal: true,
              })}
            </Text>
          ),
          player: (
            <PlayerRow
              user={user}
              hasAvatar={!!user?.icon}
              isProfileAvailable={false}
            />
          ),
          wager: (() => {
            if (feType === 'DOWN_BUT_NOT_OUT') {
              return formatCredit(score, { minimumFractionDigits: 0 });
            }
            if (isMultiplier) {
              return `${formatCredit(score, { minimumFractionDigits: 0 })}x`;
            }
            return formatCredit(score);
          })(),
          prize: (
            <Text color="sherbet-green.200" fontWeight="500">
              $
              {feType === 'WEEKLY'
                ? formatCredit(
                    actualPrize * (prizePoolPercentages[i] || 0.042857),
                  )
                : formatCredit(prize)}
            </Text>
          ),
        };
      } else {
        listOfData[i] = {
          place: (
            <Text align="center" color="candy-floss-text">
              {t('number.ordinal', {
                count: i + 1,
                ordinal: true,
              })}
            </Text>
          ),
          player: '-',
          wager: '-',
          prize: (
            <Text color="sherbet-green.200" fontWeight="500">
              $
              {feType === 'WEEKLY'
                ? formatCredit(
                    actualPrize * (prizePoolPercentages[i] || 0.042857),
                  )
                : formatCredit(0)}
            </Text>
          ),
        };
      }
    }

    return listOfData;
  }, [entries, t, isMultiplier, feType, actualPrize]);

  const hasX1000 = isMultiplier && entries?.[0]?.score >= 1000;

  return (
    <Flex p="1" style={style} direction="column" w="full">
      <Skeleton isLoaded={!isSkeleton}>
        <Flex
          pos="relative"
          transitionProperty="all"
          transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
          transitionDuration=".15s"
          _hover={{
            transform: 'translateY(-0.125rem)',
            opacity: '0.9',
          }}
          _active={{
            transform: 'translateY(0px)',
          }}
        >
          <Link
            display="flex"
            allowPrevent
            to={link}
            bg={color}
            rounded="xl"
            position="relative"
            overflow="hidden"
            width="full"
          >
            <Image
              src={image}
              alt={title}
              objectPosition="center"
              objectFit="cover"
              w="full"
            />
          </Link>
        </Flex>
      </Skeleton>

      <Flex
        mb="1"
        mt="3"
        align="start"
        justifyContent="space-between"
        w="full"
        gap="5"
      >
        <Box>
          <Flex gap="2" mb="1" align="center">
            <Skeleton isLoaded={!isSkeleton}>
              <Heading
                as={Link}
                fontWeight="500"
                fontSize="lg"
                noOfLines={1}
                to={link}
                allowPrevent
                _hover={{
                  opacity: '0.9',
                }}
              >
                {t(title, title, { amount: formatCredit(currentPrizePool) })}
              </Heading>
            </Skeleton>
          </Flex>
          <Flex align="center" color="candy-floss-text">
            <Flex align="center">
              <RecentIcon mr="1.5" />
              <Countdown
                date={Number(roundEndDate) * 1000}
                key={roundEndDate}
              />
            </Flex>
            <Skeleton isLoaded={!isSkeleton}>
              <Flex alignItems="center">
                <Box as="span" fontSize="3xs" color="candy-floss-text" mx="1">
                  •
                </Box>
                <Text
                  as="span"
                  color={
                    isMultiplier && hasX1000
                      ? 'sherbet-orange.200'
                      : 'candy-floss-text'
                  }
                >
                  $
                  {isAnimate ? (
                    <Odometer value={actualPrize} />
                  ) : (
                    formatCredit(currentPrizePool)
                  )}
                </Text>
              </Flex>
            </Skeleton>
          </Flex>
        </Box>
        {isOptIn ? (
          <Button
            colorScheme="purple"
            size="sm"
            rounded="full"
            isDisabled={joined}
            isLoading={isLoading}
            onClick={handleJoin}
          >
            {joined ? t('tournaments.joined') : t('tournaments.join')}
          </Button>
        ) : (
          <Button colorScheme="purple" size="sm" rounded="full" isDisabled>
            {t('tournaments.joined')}
          </Button>
        )}
      </Flex>
      <Skeleton isLoaded={!isSkeleton}>
        <Text color="candy-floss-text" noOfLines={2}>
          {t(description, description, {
            amount: formatCredit(currentPrizePool),
          })}
        </Text>
      </Skeleton>
      {data.length > 0 && (
        <Box mt="3">
          <Table fields={fields} data={data} />
        </Box>
      )}
    </Flex>
  );
};
