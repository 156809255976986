import { createSelector } from '@reduxjs/toolkit';
import { StateType as State } from './types';

type StateType = {
  chat: State;
};

export const getChannelId = (state: StateType) => state.chat.channel;
export const getChatBlocked = (state: StateType) => state.chat.blocked;

export const getChannels = (state: StateType) => state.chat.channels;
export const getNewMessages = (state: StateType) => state.chat.newMessages;

export const getChannel = (state: StateType) => {
  const { channel, channels } = state.chat;

  return channels[channel];
};

export const getIsValidChannel = (state: StateType) =>
  Boolean(getChannel(state));

export const getChannelMessages = (state: StateType) => {
  const { channel, channelMessages } = state.chat;
  return channelMessages[channel];
};

export const getUsers = (state: StateType) => state.chat.users || {};

export const getMessages = createSelector(
  getChannelMessages,
  getChatBlocked,
  getUsers,
  (messages, blocked, users) =>
    messages?.filter(m => !blocked.includes(users[m.userId]?.name)) || [],
);

export const getMessagesIsLoaded = (state: StateType) => {
  const { channel, channels } = state.chat;
  return channels[channel]?.loaded;
};

export const getIsFriendsLoaded = (state: StateType) =>
  state.chat.friendsLoaded;

export const getFriendsList = (state: StateType) => state.chat.friends || [];

export const getFriendsRequestsList = createSelector(getFriendsList, friends =>
  friends.filter(f => f.status === 'request'),
);

export const getFriendsExistsList = createSelector(getFriendsList, friends =>
  friends.filter(f => f.status === 'friends'),
);

export const getPendingFriendsCount = (state: StateType) =>
  getFriendsList(state).filter(friend => friend.status === 'request').length;

export const getFriendsOnline = createSelector(getFriendsExistsList, list =>
  list.filter(f => f.online),
);

export const getFriendsOnlineCount = (state: StateType) =>
  getFriendsOnline(state).length;

export const getFriendById = (id: string) => (state: StateType) =>
  getFriendsExistsList(state).find(f => f.user.id === id);

export const getChatIsTimestampVisible = (state: StateType) =>
  state.chat.isTimestampVisible;

export const getChatNotification = (state: StateType) =>
  state.chat.notification;

export const getChatNotificationLoaded = (state: StateType) =>
  state.chat.notificationIsLoaded;
