import { GameShowsIcon } from 'icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GameSlider } from 'services/games/components/game-slider';
import { getSlotsGameShowsGames } from 'services/games/modules/slots';

type Props = {
  isLoaded?: boolean;
};

export const GameShowsSlider: React.FC<Props> = ({ isLoaded }) => {
  const { t } = useTranslation();
  const games = useSelector(getSlotsGameShowsGames);

  return (
    <GameSlider
      icon={<GameShowsIcon color="candy-floss-text" />}
      titleLink="/casino/category/game-shows"
      slides={games}
      isLoaded={isLoaded}
      title={t('casino.slider.game-shows.title')}
    />
  );
};
