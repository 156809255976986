import { Box, Flex, Grid, Image, Tooltip } from '@chakra-ui/react';
import { EMOTES_LIST } from 'helpers/emotes';
import { Fragment, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserHasMax } from 'services/user/modules/user';

type TooltipWrapperProps = {
  children: React.ReactNode;
  tooltip: string;
};

const TooltipWrapper: React.FC<TooltipWrapperProps> = ({
  children,
  tooltip,
}) => (
  <Tooltip
    gutter={10}
    hasArrow
    fontSize="md"
    rounded="md"
    placement="top"
    bg="vanilla-text"
    fontWeight="500"
    py="1"
    px="2"
    color="toffee-base"
    label={tooltip}
  >
    {children}
  </Tooltip>
);

type Props = {
  addEmote: (value: string) => void;
};

type EmoteType = {
  display: React.ReactNode;
  tooltip: string;
  value: string;
  text: string;
};

export const EmotePicker: React.FC<Props> = ({ addEmote }) => {
  const [tab] = useState(0);
  const isMax = useSelector(getUserHasMax);

  const renderList = (list: EmoteType[]) => (
    <Grid templateColumns="repeat(10, 1fr)" gap="2">
      {list.map(({ display, value, tooltip }, index) => {
        const Wrapper = tooltip ? TooltipWrapper : Fragment;

        return (
          <Wrapper tooltip={tooltip} key={index}>
            <Flex
              align="center"
              justifyContent="center"
              textAlign="center"
              borderRadius="4px"
              _hover={{ opacity: '0.9' }}
              cursor="pointer"
              lineHeight="1"
              onClick={() => addEmote(value)}
              fontSize="18px"
              minH="18px"
            >
              {display}
            </Flex>
          </Wrapper>
        );
      })}
    </Grid>
  );

  return (
    <Flex
      bg="toffee-base"
      overflow="auto"
      p="5"
      gap="5"
      borderTop="1px solid"
      borderColor="truffle-border"
      direction="column"
    >
      {(tab === 0 || tab === 2) && (
        <Box>
          {renderList(
            EMOTES_LIST.filter(({ isOnlyMax }) => isMax || !isOnlyMax).map(
              emote => ({
                display: (
                  <Image
                    draggable="false"
                    src={emote.icon}
                    alt={emote.display}
                    h="18px"
                  />
                ),
                tooltip: emote.display,
                value: `:(${emote.display})`,
                text: emote.display,
              }),
            ),
          )}
        </Box>
      )}
    </Flex>
  );
};
