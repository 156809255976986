import { createIcon } from '@chakra-ui/icons';

export const DuelbitsLogo = createIcon({
  displayName: 'DuelbitsLogo',
  viewBox: '0 0 109 25',
  path: (
    <svg
      width="109"
      height="25"
      viewBox="0 0 109 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="styles_HeaderLogoBig__8qf4R"
    >
      <path
        d="M42.5969 15.6545C42.9887 14.6991 43.1931 13.563 43.1931 12.2719C43.1931 10.9292 42.9887 9.75857 42.5969 8.80316C42.2051 7.84776 41.626 7.05589 40.8935 6.43617C40.161 5.82506 39.2668 5.36888 38.2447 5.08484C37.2142 4.8008 36.0558 4.65448 34.7953 4.65448C34.1991 4.65448 33.4752 4.6803 32.6405 4.73195C31.8229 4.78359 31.0308 4.87826 30.2813 5.01598V19.5622C31.0308 19.7171 31.8059 19.8204 32.5894 19.8635C33.39 19.9151 34.0969 19.9409 34.6846 19.9409C35.9877 19.9409 37.1801 19.7774 38.2277 19.4675C39.2753 19.1577 40.1696 18.6757 40.902 18.0473C41.6345 17.4104 42.2051 16.6099 42.5969 15.6545ZM38.6195 15.8439C37.8018 16.7046 36.5413 17.135 34.8635 17.135C34.5654 17.135 34.3269 17.135 34.1225 17.1264C33.9181 17.1178 33.7307 17.1091 33.5689 17.0919L33.5178 17.0833V7.49487L33.5604 7.48626C33.7733 7.46044 34.0118 7.44323 34.2502 7.43462C34.4972 7.42601 34.7357 7.42601 34.9571 7.42601C36.635 7.42601 37.887 7.83916 38.662 8.65684C39.4371 9.47453 39.8374 10.6882 39.8374 12.2719C39.8459 13.7781 39.4371 14.9832 38.6195 15.8439Z"
        fill="#9494a5"
      ></path>
      <path
        d="M45.8759 18.538C46.2421 18.9942 46.7446 19.3643 47.3664 19.6139C47.9966 19.8721 48.7887 20.0012 49.7341 20.0012C50.6709 20.0012 51.5737 19.9324 52.4254 19.7946C53.26 19.6655 53.9499 19.5192 54.4865 19.3643V8.44171H51.3949V17.2383L51.3523 17.2469C51.1564 17.2727 50.9264 17.2985 50.6624 17.3244C50.4069 17.3502 50.1599 17.3588 49.93 17.3588C49.1719 17.3588 48.6439 17.1178 48.3628 16.6358C48.0903 16.171 47.9455 15.3963 47.9455 14.3549V8.44171H44.8539V14.7422C44.8539 15.5168 44.9305 16.2312 45.0924 16.8768C45.2457 17.5137 45.5097 18.0732 45.8759 18.538Z"
        fill="#9494a5"
      ></path>
      <path
        d="M61.3341 8.14044C60.6612 8.14044 59.9884 8.26955 59.3496 8.52777C58.7109 8.78598 58.1317 9.1647 57.6377 9.66392C57.1437 10.1631 56.7435 10.7915 56.4368 11.5403C56.1302 12.2891 55.9769 13.1757 55.9769 14.1741C55.9769 15.0004 56.0962 15.7837 56.3261 16.4981C56.5561 17.2125 56.9223 17.8322 57.4078 18.3572C57.8932 18.8737 58.515 19.2954 59.2559 19.5881C59.9969 19.8893 60.8827 20.0356 61.8877 20.0356C62.288 20.0356 62.6882 20.0098 63.08 19.9668C63.4803 19.9238 63.8551 19.8721 64.2042 19.8033C64.5534 19.743 64.8686 19.6655 65.1496 19.5795C65.4136 19.502 65.6266 19.4159 65.7884 19.3385L65.3796 16.8251C65.0304 16.9715 64.579 17.1006 64.0509 17.2039C63.4888 17.3158 62.9012 17.3674 62.3135 17.3674C61.3937 17.3674 60.6442 17.1694 60.0991 16.7735C59.5455 16.3776 59.2304 15.8267 59.1537 15.1553L59.1452 15.0951H66.4016C66.4186 14.9316 66.4272 14.7508 66.4442 14.5528C66.4612 14.329 66.4612 14.1225 66.4612 13.9417C66.4612 12.0395 66.0013 10.5849 65.0815 9.61228C64.1787 8.63966 62.9097 8.14044 61.3341 8.14044ZM59.1537 12.9949L59.1623 12.9347C59.2048 12.6592 59.273 12.3838 59.3666 12.1084C59.4603 11.8329 59.5966 11.5919 59.7755 11.3854C59.9543 11.1788 60.1758 11.0067 60.4398 10.8862C60.7038 10.757 61.0189 10.6968 61.3852 10.6968C61.7599 10.6968 62.0835 10.7657 62.339 10.8948C62.5946 11.0239 62.8075 11.196 62.9778 11.4026C63.1482 11.6092 63.2759 11.8502 63.3526 12.117C63.4292 12.3838 63.4803 12.6506 63.4973 12.9347V12.9949H59.1537Z"
        fill="#9494a5"
      ></path>
      <path
        d="M88.98 8.44171H85.8883V19.7344H88.98V8.44171Z"
        fill="#9494a5"
      ></path>
      <path
        d="M71.2563 4.32745H68.1646V19.7344H71.2563V4.32745Z"
        fill="#9494a5"
      ></path>
      <path
        d="M91.4583 17.135C91.5946 17.7289 91.8331 18.2453 92.1652 18.6671C92.4974 19.0802 92.9573 19.4159 93.5194 19.6483C94.0815 19.8807 94.8055 20.0012 95.6657 20.0012C96.347 20.0012 96.9262 19.941 97.3861 19.8291C97.8375 19.7172 98.2548 19.5881 98.6295 19.4331L98.1952 17.0231C97.9056 17.1264 97.5905 17.2125 97.2498 17.2727C96.8836 17.3416 96.5259 17.3674 96.1767 17.3674C95.4442 17.3674 94.9502 17.1522 94.7032 16.7305C94.4648 16.3173 94.3455 15.7665 94.3455 15.0779V11.196H98.1867V8.44171H94.3455V4.32745H91.2539V15.1295C91.2539 15.8611 91.322 16.5411 91.4583 17.135Z"
        fill="#9494a5"
      ></path>
      <path
        d="M103.885 20.0443C105.401 20.0443 106.585 19.7602 107.402 19.1921C108.22 18.6327 108.637 17.7805 108.637 16.6874C108.637 16.2226 108.586 15.8009 108.475 15.448C108.373 15.0951 108.186 14.7766 107.93 14.484C107.666 14.1913 107.309 13.9159 106.857 13.6663C106.406 13.4167 105.818 13.1498 105.128 12.8916C104.787 12.7625 104.498 12.642 104.276 12.5301C104.055 12.4182 103.876 12.315 103.74 12.2203C103.604 12.117 103.51 12.0137 103.467 11.9018C103.425 11.7985 103.399 11.6694 103.399 11.5317C103.399 10.9378 103.919 10.6365 104.949 10.6365C105.494 10.6365 105.988 10.6882 106.431 10.7915C106.849 10.8862 107.24 10.998 107.59 11.1185L108.126 8.70852C107.76 8.5708 107.275 8.44169 106.678 8.3298C106.057 8.2093 105.392 8.14905 104.711 8.14905C103.357 8.14905 102.275 8.45891 101.508 9.07003C100.742 9.68114 100.35 10.516 100.35 11.5661C100.35 12.1084 100.427 12.5732 100.58 12.9519C100.733 13.3306 100.955 13.6663 101.236 13.9417C101.517 14.2172 101.858 14.4581 102.266 14.6561C102.675 14.8541 103.127 15.0521 103.621 15.2414C104.268 15.491 104.753 15.7148 105.069 15.9042C105.401 16.1107 105.563 16.3517 105.563 16.6358C105.563 16.9887 105.426 17.2297 105.171 17.3588C104.915 17.4793 104.506 17.5395 103.902 17.5395C103.34 17.5395 102.769 17.4793 102.198 17.3502C101.645 17.2297 101.1 17.0661 100.571 16.8596L100.06 19.3815C100.316 19.4934 100.767 19.6311 101.398 19.7946C102.062 19.9582 102.897 20.0443 103.885 20.0443Z"
        fill="#9494a5"
      ></path>
      <path
        d="M22.2245 17.2899L20.47 15.5082L15.3173 20.7156L17.0718 22.4973L18.5793 20.9738L21.8924 24.3221L24.0301 22.153L20.717 18.8048L22.2245 17.2899Z"
        fill="#9494a5"
      ></path>
      <path
        d="M8.71673 4.49115L4.61158 0.342468H0.310547V4.69772L4.41569 8.8464L8.71673 4.49115Z"
        fill="#9494a5"
      ></path>
      <path
        d="M12.2342 21.2062L20.9555 12.3924L12.2342 3.57861L3.52142 12.3924L12.2342 21.2062ZM13.9546 15.8697C13.7928 16.696 13.0689 17.2899 12.2342 17.2899C11.2718 17.2899 10.4797 16.4981 10.4797 15.5168C10.4797 15.0348 10.6586 14.5873 10.9907 14.2516C11.3229 13.9159 11.7658 13.7351 12.2257 13.7351C13.1881 13.7351 13.9802 14.527 13.9802 15.5082C13.9887 15.6373 13.9802 15.7579 13.9546 15.8697ZM10.4882 9.66392C10.4882 9.19052 10.6671 8.74295 10.9993 8.41587C11.3314 8.08019 11.7658 7.89944 12.2342 7.89944C13.1966 7.89944 13.9887 8.69131 13.9887 9.67254C13.9887 9.79304 13.9802 9.91354 13.9546 10.0254C13.7928 10.8517 13.0689 11.4456 12.2342 11.4456C11.2718 11.4456 10.4797 10.6538 10.4797 9.67254V9.66392H10.4882Z"
        fill="#9494a5"
      ></path>
      <path
        d="M15.7602 4.48237L20.0697 8.83763L24.1749 4.68895V0.333679H19.8653L15.7602 4.48237Z"
        fill="#9494a5"
      ></path>
      <path
        d="M5.88913 20.9736L7.39664 22.4971L9.15113 20.7154L3.99836 15.5082L2.24386 17.2899L3.74285 18.8047L0.446777 22.1442L2.58454 24.3131L5.88913 20.9736Z"
        fill="#9494a5"
      ></path>
      <path
        d="M75.6425 19.829C76.0513 19.8893 76.4686 19.9323 76.8859 19.9667C77.3033 20.0012 77.7121 20.0184 78.0953 20.0184C79.0152 20.0184 79.8413 19.8807 80.5653 19.5966C81.2807 19.3212 81.8939 18.9167 82.3879 18.4088C82.8819 17.901 83.2651 17.2727 83.5206 16.5411C83.7847 15.8094 83.9124 14.9918 83.9124 14.088C83.9124 13.2014 83.8017 12.3838 83.5888 11.6608C83.3758 10.9378 83.0607 10.318 82.6519 9.8016C82.2431 9.29377 81.7321 8.88923 81.1274 8.6138C80.5227 8.33837 79.8158 8.19204 79.0237 8.19204C78.5893 8.19204 78.172 8.23508 77.7887 8.32115C77.4055 8.40722 77.0392 8.52773 76.6815 8.68266L76.6049 8.71708V4.31018H73.5303V19.3642C73.8028 19.4503 74.1265 19.5278 74.4757 19.6052C74.8419 19.6999 75.2337 19.7688 75.6425 19.829ZM76.6219 11.3079L76.6475 11.2906C76.8519 11.1615 77.1159 11.0496 77.4481 10.955C77.7717 10.8689 78.1039 10.8259 78.436 10.8259C79.9776 10.8259 80.7611 11.8931 80.7611 14.0019C80.7611 15.009 80.5312 15.8267 80.0883 16.4378C79.6369 17.0575 78.9556 17.3673 78.0698 17.3673C77.7802 17.3673 77.5162 17.3587 77.2692 17.3329C77.0222 17.3071 76.8178 17.2813 76.6645 17.2555L76.6219 17.2469V11.3079V11.3079Z"
        fill="#9494a5"
      ></path>
      <path
        d="M88.98 4.32745H85.8713V7.03873H88.98V4.32745Z"
        fill="#9494a5"
      ></path>
    </svg>
  ),
});
