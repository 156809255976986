import { RecentIcon } from 'icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GameSlider } from 'services/games/components/game-slider';
import {
  getSlotsIsLoaded,
  getSlotsRecentGamesList,
  loadRecentGames,
} from 'services/games/modules/slots';
import { useActions } from 'store';

export const RecentlyPlayedSlider = () => {
  const { t } = useTranslation();
  const games = useSelector(getSlotsRecentGamesList);
  const isLoaded = useSelector(getSlotsIsLoaded);
  const actions = useActions({ loadRecentGames });

  useEffect(() => {
    if (!isLoaded) {
      actions.loadRecentGames();
    }
  }, [isLoaded, actions]);

  return (
    <GameSlider
      slides={games}
      icon={<RecentIcon color="candy-floss-text" />}
      isLoaded={isLoaded}
      title={t('casino.slider.recently-played.title')}
      titleLink="/casino/category/recent"
    />
  );
};
