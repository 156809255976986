import { SkeletonProps } from '@chakra-ui/react';
import { Skeleton } from 'uikit/skeleton';

const placeholders = {
  square: 100,
  banner: 100 / (1883 / 1134),
  post: 100 / (1354 / 716),
};

type Props = SkeletonProps & {
  type?: 'square' | 'banner' | 'post';
};

export const ImageFallback: React.FC<Props> = ({ type, ...props }) => {
  const paddingBottom = (type && placeholders[type]) || 100;
  return (
    <Skeleton
      w="full"
      rounded="xl"
      {...props}
      paddingBottom={!props.w ? `${paddingBottom}%` : undefined}
    />
  );
};
