import { createIcon } from '@chakra-ui/icons';

export const SOLIcon = createIcon({
  displayName: 'SOLIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <g clipPath="url(#a)">
        <path fill="#66F9A1" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z" />
        <path
          fill="#fff"
          d="M4.963 9.844a.295.295 0 0 1 .207-.085h7.183a.145.145 0 0 1 .104.248l-1.42 1.408a.295.295 0 0 1-.207.085H3.647a.146.146 0 0 1-.136-.09.146.146 0 0 1 .032-.159l1.42-1.407Zm0-5.259A.295.295 0 0 1 5.17 4.5h7.183c.13 0 .196.157.104.249l-1.42 1.408a.295.295 0 0 1-.207.085H3.647a.145.145 0 0 1-.103-.249l1.419-1.408Zm6.074 2.612a.295.295 0 0 0-.207-.085H3.647a.145.145 0 0 0-.103.25l1.419 1.407c.054.054.13.085.207.085h7.183a.146.146 0 0 0 .104-.249l-1.42-1.408Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </>
  ),
});
