import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { CustomInput } from 'forms/custom-input';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { VerifyEmailForm } from 'services/user/components/verify-email-form';
import { redeemCoupon } from 'services/user/modules/coupon';
import { getUserEmailConfirmed } from 'services/user/modules/user';
import { useActions } from 'store';
import { toastError, toastSuccess } from 'toasts';
import * as Yup from 'yup';

export const RedeemView = ({ defaultCode = '', onClose = () => {} }) => {
  const { t } = useTranslation();
  const isEmailConfirmed = useSelector(getUserEmailConfirmed);
  const actions = useActions({ redeemCoupon });

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      code: defaultCode,
    },
    validationSchema: Yup.object({
      code: Yup.string()
        .min(2, t('validations.min-characters', { n: 2 }))
        .required(t('validations.required')),
    }),
    onSubmit: async ({ code }) => {
      try {
        await actions.redeemCoupon(code);
        toastSuccess({
          title: t('redeem.success'),
          description: t('redeem.success-description', { code }),
        });
        onClose?.();
      } catch (errors) {
        const error =
          {
            NOT_FOUND: 'COUPON_NOT_FOUND',
          }[errors.global] || errors.global;
        toastError({
          title: t(`errors.BE.${error}`),
          description: t(`errors.BE.${error}_DESCRIPTION`, ''),
        });
      }
    },
  });

  if (!isEmailConfirmed) {
    return <VerifyEmailForm isRounded={false} isRedeem />;
  }

  return (
    <Box>
      <FormControl as="form" onSubmit={formik.handleSubmit}>
        <Flex direction="column">
          <Flex display="column" p="5">
            <Heading fontWeight="500" fontSize="md" mb="1">
              {t('redeem.title')}
            </Heading>
            <Text color="candy-floss-text">{t('redeem.description')}</Text>
          </Flex>
          <Divider
            borderColor="truffle-border"
            borderBottomWidth="1px"
            opacity="1"
          />
          <Flex p="5" flexDirection="column">
            <CustomInput
              placeholder="form.label.code"
              label="form.label.code"
              type="text"
              name="code"
              required
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.code}
              isInvalid={formik.errors.code && formik.touched.code}
              errorMessage={formik.errors.code}
            />
          </Flex>
          <Divider
            borderColor="truffle-border"
            borderBottomWidth="1px"
            opacity="1"
          />
          <Flex p="5" align={{ base: 'center', md: 'start' }}>
            <Button
              w={{ base: 'full', md: 'auto' }}
              type="submit"
              colorScheme="purple"
              isLoading={formik.isSubmitting}
            >
              {t('redeem.submit')}
            </Button>
          </Flex>
        </Flex>
      </FormControl>
    </Box>
  );
};
