import { Flex } from '@chakra-ui/react';
import { Content } from 'components/content';
import { trackEvent } from 'helpers/analytics';
import { useSeon } from 'hooks/use-seon';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { googleAuth } from 'services/auth/modules/auth';
import { useActions } from 'store';
import { Step3 } from '../auth/components/step3';
import { Step5 } from '../auth/components/step5';

type Props = {
  onClose: () => void;
  contentProps: { step?: string; accessToken?: string };
};

export const AuthGoogle: React.FC<Props> = ({
  onClose,
  contentProps: { step: propStep = '1', accessToken },
}) => {
  const [params, setParams] = useState<{ username?: string }>({});
  const actions = useActions({ googleAuth });
  const [searchParams, setSearchParams] = useSearchParams();

  const step = Number(propStep);

  const setStep = (nextStep: number) => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      step: String(nextStep),
    });
  };

  useEffect(() => {
    if (step > 1 && !Object.keys(params).length) {
      setStep(1);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getSeonSession } = useSeon();

  const onChangeParams = (next: object) => {
    setParams(prev => Object.assign(prev, next));
  };

  const onSubmit = async () => {
    let afdp = null;
    try {
      afdp = await getSeonSession();
    } catch (e) {
      console.warn(e);
    }
    const stag = localStorage.getItem('stag') || '';
    trackEvent('register', { username: params.username as string });
    window.coinzilla_performance.push({ event: 'register' });
    await actions.googleAuth({
      accessToken,
      displayName: params.username,
      stag,
      afdp,
    });
  };

  let content;

  if (step === 1) {
    content = <Step3 setStep={setStep} setParams={onChangeParams} />;
  } else if (step === 5) {
    content = <Step5 onSubmit={onSubmit} onClose={onClose} />;
  }

  return (
    <Content w="full" maxW="6xl" mx="auto" flexGrow="1" key={step}>
      <Flex
        mt={{ base: 'auto', lg: 'initial' }}
        p="5"
        flexDirection="column"
        minH={{
          base:
            'auto' ||
            'calc(100vh - var(--header-height) - var(--footer-height))',
          lg: 'calc(100vh - var(--header-height))',
        }}
        gap="5"
        justifyContent={{ lg: 'center' }}
      >
        {content}
      </Flex>
    </Content>
  );
};
