import { createIcon } from '@chakra-ui/icons';

export const BaccaratIcon = createIcon({
  displayName: 'BaccaratIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7.365 0a2 2 0 0 0-2 2v8.8a2 2 0 0 0 2 2h5.65a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2h-5.65Zm2.944 4.362a.28.28 0 0 1 .098.083l1.335 1.774a.287.287 0 0 1 0 .336L10.407 8.33a.28.28 0 0 1-.447 0L8.625 6.555a.288.288 0 0 1 0-.336L9.96 4.445a.28.28 0 0 1 .349-.083Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M10.184 11.192H7.386a.4.4 0 0 1-.4-.4V1.999a.4.4 0 0 1 .4-.4h5.596a.4.4 0 0 1 .4.4v7.594a.8.8 0 1 0 1.598 0V1.599A1.599 1.599 0 0 0 13.381 0H6.986a1.599 1.599 0 0 0-1.599 1.599v1.159l-3.198.751a1.599 1.599 0 0 0-.99.72c-.211.365-.269.8-.16 1.207l1.214 9.33a1.599 1.599 0 0 0 1.55 1.222c.125.016.252.016.376 0l3.11-.8a.799.799 0 1 0-.368-1.55l-2.726.648a.408.408 0 0 1-.304-.048.4.4 0 0 1-.175-.248l-1.03-8.538a.4.4 0 0 1 .295-.48l1.91-.455a.408.408 0 0 1 .496.391v6.284a1.599 1.599 0 0 0 1.599 1.599h3.198a.8.8 0 0 0 0-1.6Z"
      />
    </>
  ),
});
