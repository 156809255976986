import { client, parseApiError } from 'helpers/client';
import { query } from 'helpers/query-string';

import { sanityClient } from 'helpers/sanity';

export const loadChannelMessages = (
  channel: string,
  params: { [key: string]: string },
) =>
  client
    .get(`/chat/channels/${channel}/messages${query.stringify(params)}`)
    .then(({ data }) => data.payload);

export const loadFriends = () =>
  client.get('/chat/friends').then(({ data }) => data.payload);

export const changeRoute = (route: string) =>
  client.post('/chat/route', { route }).then(({ data }) => data);

export const deleteMessages = (params: { [key: string]: string }) =>
  client.post(`/chat/messages/delete`, params).catch(parseApiError);

export const mute = (params: { [key: string]: string }) =>
  client.post('/chat/mute', params).catch(parseApiError);

export const removeMute = (userName: string) =>
  client.post('/chat/mute/remove', { userName }).catch(parseApiError);

export const timeout = (params: { [key: string]: string }) =>
  client.post('/chat/timeout', params).catch(parseApiError);

export const removeTimeout = (userName: string) =>
  client.post('/chat/timeout/remove', { userName }).catch(parseApiError);

export const unpinMessages = (params: { [key: string]: string }) =>
  client.post('/chat/messages/unpin', params).catch(parseApiError);

export const friendInvite = (userName: string) =>
  client
    .post('/chat/friend/invite', { userName })
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const acceptFriendInvite = (userName: string) =>
  client
    .post('/chat/friend/invite/accept', { userName })
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const removeFriend = (userName: string) =>
  client.post('/chat/friend/remove', { userName }).catch(parseApiError);

export const addBlock = (userName: string) =>
  client.post('/chat/block/add', { userName }).catch(parseApiError);

export const removeBlock = (userName: string) =>
  client.post('/chat/block/remove', { userName }).catch(parseApiError);

export const createPublicGroup = (params: { [key: string]: string }) =>
  client.post(`/chat/group/public/create`, params);

export const loadChatNotification = (lang: string) =>
  sanityClient.fetch(
    `*[_type == "chatNotification"] {
      'text': text.${lang}
  }`,
  );
