import { ChakraProvider } from '@chakra-ui/react';
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_CLIENT_ID, IS_PRERENDER } from 'constants/general';
import { StatsProvider } from 'contexts/stats-context/stats-context';
import { ModalProvider } from 'modals/modal-provider';
import { Suspense } from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { createStore } from 'store/store';
import { theme } from 'theme';
import { ToastContainer } from 'toasts';
import { Layout } from '../layout';
import './style.scss';

const store = createStore();

export const App = () => {
  const content = (
    <ChakraProvider theme={theme}>
      <Provider store={store}>
        <BrowserRouter>
          <ModalProvider>
            <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
              <Suspense fallback={<div />}>
                <StatsProvider>
                  <Layout />
                </StatsProvider>
              </Suspense>
            </GoogleOAuthProvider>
          </ModalProvider>
        </BrowserRouter>
      </Provider>
      <ToastContainer />
    </ChakraProvider>
  );

  if (IS_PRERENDER) {
    return (
      <CacheProvider value={createCache({ key: 'sherbet-css', speedy: false })}>
        {content}
      </CacheProvider>
    );
  }

  return content;
};
