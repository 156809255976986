import { createIcon } from '@chakra-ui/icons';

export const AddFriendIcon = createIcon({
  displayName: 'AddFriendIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="M6 7.02c.796 0 1.559-.31 2.121-.86A2.909 2.909 0 0 0 9 4.082c0-.78-.316-1.527-.879-2.078A3.032 3.032 0 0 0 6 1.143c-.796 0-1.559.31-2.121.86A2.909 2.909 0 0 0 3 4.083c0 .779.316 1.527.879 2.078.562.55 1.325.86 2.121.86Zm0 1.96c1.591 0 3.117.619 4.243 1.721a5.862 5.862 0 0 1 1.413 2.196c.376 1.039-.551 1.96-1.656 1.96H2c-1.105 0-2.032-.921-1.656-1.96a5.861 5.861 0 0 1 1.413-2.196A6.064 6.064 0 0 1 6 8.98Zm8-3.919a.97.97 0 0 0-.293-.692 1.01 1.01 0 0 0-1.414 0A.97.97 0 0 0 12 5.06v.98h-1c-.265 0-.52.103-.707.287A.97.97 0 0 0 10 7.02c0 .26.105.51.293.693.187.184.442.287.707.287h1v.98c0 .26.105.509.293.692a1.01 1.01 0 0 0 1.414 0A.97.97 0 0 0 14 8.98V8h1c.265 0 .52-.103.707-.287A.97.97 0 0 0 16 7.02a.97.97 0 0 0-.293-.692A1.01 1.01 0 0 0 15 6.04h-1v-.98Z"
    />
  ),
});
