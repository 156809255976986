import { BigInteger } from 'big-integer/BigInteger';

export const commandWithArgs = (command: string, args: string[]) =>
  `/${command} ${args?.map(arg => `{${arg}}` ?? []).join(' ')}`;

export const isCommandAvailable = (
  userFlags: BigInteger,
  commandFlag: BigInteger,
) =>
  userFlags &&
  (commandFlag === null || userFlags.and(commandFlag).eq(commandFlag));

export const parseUserName = (user: string) =>
  user.match(/.*?\[([^)]*)\].*/)?.[1] ?? user;
