import { Avatar, Flex } from '@chakra-ui/react';
import { GHOST_USER } from 'constants/general';
import { UserType } from 'types';
import { resolveAvatarUrl } from 'uikit/user-avatar/utils';
import styles from './style.module.scss';

type Props = {
  size: 'xs' | 'sm';
  max?: number;
  users: UserType[];
};

const sizes = {
  xs: '24',
  sm: '32',
};

export const UserAvatarGroup: React.FC<Props> = ({ users, size, max = 3 }) => (
  <Flex
    sx={{
      '--size': sizes[size],
    }}
  >
    {users.slice(0, max).map((user, index) => {
      let avatar;

      if (user) {
        if (user.icon) {
          avatar = user.icon;
        } else {
          avatar = 'https://spinkick.imgix.net/avatars/default-avatar.png';
        }
      } else {
        avatar = GHOST_USER.icon;
      }

      return (
        <Avatar
          size={size}
          key={index}
          name={user?.name || 'Ghost'}
          src={resolveAvatarUrl(avatar)}
          className={styles.avatar}
        />
      );
    })}
  </Flex>
);
