import { sanityClient } from 'helpers/sanity';

export const loadPromos = (lang: string) =>
  sanityClient.fetch(
    `*[_type == "promo2"] {
    'tags': tags[] {
      _type == 'reference' => @->,
      _type != 'reference' => @
    },
    'authorName': author->name,
    'authorImage': author->image,
    'authorSlug': author->slug.current,
    button,
    'slug': slug.current,
    'description': description.${lang},
    'title': title.${lang},
    'mainImage': mainImage2.${lang},
    weight,
    publishedAt,
    endAt,
    _id
  }`,
    { lang },
  );

export const loadPromo = (slug: string, lang: string) =>
  sanityClient.fetch(
    `*[_type == "promo2" && slug.current == $slug] {
    'tags': tags[] {
      _type == 'reference' => @->,
      _type != 'reference' => @
    },
    button,
    'authorName': author->name,
    'authorImage': author->image,
    'authorSlug': author->slug.current,
    'body': body.${lang},
    'slug': slug.current,
    'description': description.${lang},
    'title': title.${lang},
    'mainImage': mainImage2.${lang},
    weight,
    publishedAt,
    endAt,
    _id
  }`,
    { lang, slug },
  );
