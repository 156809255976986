import { createIcon } from '@chakra-ui/icons';

export const XRPIcon = createIcon({
  displayName: 'XRPIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <g clipPath="url(#a)">
        <path fill="#23292F" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z" />
        <path
          fill="#fff"
          d="M11.535 4h1.445L9.972 6.979a2.81 2.81 0 0 1-3.944 0L3.018 4h1.447L6.75 6.261a1.778 1.778 0 0 0 2.498 0L11.535 4Zm-7.088 8.281H3l3.027-2.996a2.81 2.81 0 0 1 3.946 0L13 12.281h-1.447L9.25 10a1.778 1.778 0 0 0-2.498 0l-2.305 2.281Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </>
  ),
});
