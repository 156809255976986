import { Box, Flex, FlexProps, Image, Text, Tooltip } from '@chakra-ui/react';
import { GHOST_USER } from 'constants/general';
import { MODAL_TYPE, useModal } from 'modals';
import { useTranslation } from 'react-i18next';
import { MERGED_CHAT_COLORS } from 'services/user/modules/user';
import { UserType } from 'types';
import { Link } from 'uikit/link';
import { UserAvatar, sizes } from 'uikit/user-avatar';

type Props = FlexProps & {
  user?: UserType;
  isOnlyAvatar?: boolean;
  size?: keyof typeof sizes;
  hasLink?: boolean;
  isProfileAvailable?: boolean;
  hasAvatar?: boolean;
};

export const PlayerRow: React.FC<Props> = ({
  user,
  isOnlyAvatar,
  size = 'xs',
  hasLink = true,
  hasAvatar = true,
  isProfileAvailable = true,
  ...props
}) => {
  const { onOpen: profileModalOpen } = useModal(MODAL_TYPE.PROFILE_MODAL);
  const { t } = useTranslation();
  const { icon, badges, chatColor, nftId } = user || GHOST_USER;
  let { name } = user || {};

  const isGhost = !user || user.isGhost;

  if (isGhost) {
    name = t('user.hidden');
  }

  const handleOpenProfile = () => {
    if (!isGhost) {
      profileModalOpen({ username: name });
    }
  };

  const wrapTooltip = (children: React.ReactNode) => {
    if (isGhost) {
      return (
        <Tooltip
          gutter={10}
          hasArrow
          fontSize="md"
          rounded="md"
          placement="top"
          bg="vanilla-text"
          fontWeight="500"
          py="1"
          px="2"
          color="toffee-base"
          label={t('player.ghost-mode-enabled')}
        >
          {children}
        </Tooltip>
      );
    }

    return children;
  };

  let LinkComponent: typeof Text | typeof Link = Text;
  let linkProps = {};
  if (isProfileAvailable) {
    if (hasLink && !isGhost) {
      LinkComponent = Link;
      linkProps = {
        to: `?modal=profile&username=${name}`,
      };
    } else {
      linkProps = {
        onClick: handleOpenProfile,
      };
    }
  }

  const color =
    (isProfileAvailable &&
      MERGED_CHAT_COLORS[chatColor as keyof typeof MERGED_CHAT_COLORS]) ||
    'vanilla-text';

  let BadgeWrapper: typeof Box | typeof Link = Box;
  let badgeWrapperProps: { to?: string } = {};
  const hasBadge = badges && badges?.length > 0;

  if (hasBadge && badges[0]?.name === 'Max') {
    BadgeWrapper = Link;
    badgeWrapperProps = {
      to: '/max',
    };
  }

  return (
    <Flex align="center" gap="1.5" {...props}>
      {(hasAvatar || hasBadge) && (
        <Flex gap="1">
          {hasBadge && (
            <Tooltip
              gutter={10}
              hasArrow
              fontSize="md"
              rounded="md"
              placement="top"
              bg="vanilla-text"
              fontWeight="500"
              py="1"
              px="2"
              color="toffee-base"
              label={badges[0]?.name}
            >
              <BadgeWrapper {...badgeWrapperProps}>
                <Flex
                  cursor={badgeWrapperProps.to ? 'pointer' : 'help'}
                  rounded="base"
                  overflow="hidden"
                  h="18px"
                  w="18px"
                  fontSize="xs"
                  fontWeight="800"
                  align="center"
                  justifyContent="center"
                  flexShrink="0"
                >
                  <Image
                    draggable="false"
                    src={badges[0]?.imageUrl}
                    w="full"
                    alt={badges[0]?.name}
                  />
                </Flex>
              </BadgeWrapper>
            </Tooltip>
          )}

          {hasAvatar && (
            <UserAvatar
              icon={icon}
              isGhost={isGhost}
              name={name}
              size={size}
              onClick={handleOpenProfile}
              nftId={nftId}
            />
          )}
        </Flex>
      )}
      {!isOnlyAvatar &&
        wrapTooltip(
          <LinkComponent
            noOfLines={1}
            cursor={isGhost ? 'help' : 'pointer'}
            color={isGhost ? 'candy-floss-text' : color}
            _hover={!isGhost && isProfileAvailable ? { opacity: '0.9' } : {}}
            fontWeight="500"
            {...linkProps}
          >
            {name}
          </LinkComponent>,
        )}
    </Flex>
  );
};
