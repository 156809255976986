import { AffiliatesType } from './types';

type UserType = {
  profile: {
    affiliateCode: string;
  };
};

type StateType = {
  affiliates: AffiliatesType;
  user: UserType;
};

export const getAffiliatesStats = (state: StateType) => state.affiliates.stats;

export const getAffiliatesStatsLoaded = (state: StateType) =>
  state.affiliates.isStatsLoaded;

export const getAffiliateCode = (state: StateType) =>
  state.user.profile.affiliateCode;

export const getAffiliatesList = (state: StateType) => state.affiliates.list;
