import { createClient } from '@sanity/client';
import imageUrlBuilder from '@sanity/image-url';

export const sanityClient = createClient({
  projectId: '3zrp5797',
  dataset: 'production',
  apiVersion: '2022-03-25',
  token: '',
  useCdn: false, // `false` if you want to ensure fresh data
});

export const imageBuilder = imageUrlBuilder(sanityClient);
