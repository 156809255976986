import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { PasswordInput } from 'forms/password-input';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { validateField } from 'services/auth/modules/auth/api';
import { Errors, FormSubmit } from 'types';
import * as Yup from 'yup';

type Props = {
  setStep: (step: number) => void;
  setParams: (params: object) => void;
  username: string;
};

export const Step4: React.FC<Props> = ({ setParams, setStep, username }) => {
  const { t } = useTranslation();
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, 'errors.password.min')
        .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/, 'errors.password.rules')
        .required('errors.password.required'),
    }),
    onSubmit: async (params, { setErrors }) => {
      try {
        await validateField('password', params.password);
        setParams(params);
        setStep(5);
      } catch (errors) {
        setErrors({ password: (errors as Errors).global });
      }
    },
  });

  const navigate = useNavigate();

  return (
    <FormControl as="form" onSubmit={formik.handleSubmit as FormSubmit}>
      <Box w="full" pos="relative">
        <Flex
          flexDirection="column"
          justifyContent="start"
          w="full"
          bg="toffee-base"
          mx="auto"
        >
          <Heading fontSize="2xl" mb="2">
            {t('signup.step5.title')}, {username}
          </Heading>
          <Text fontSize="lg" color="candy-floss-text" maxW="md" mb="5">
            {t('signup.step5.desc')}
          </Text>
          <Flex
            mb="5"
            w="full"
            maxW="md"
            gap="5"
            justifyContent="space-between"
            align="center"
          >
            <PasswordInput
              placeholder={t('form.label.password')}
              name="password"
              hideLabel
              autoComplete="new-password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              isInvalid={!!formik.errors.password}
              errorMessage={formik.errors.password}
            />
          </Flex>
          <Flex align="center" gap="2">
            <Button onClick={() => navigate(-1)} rounded="full">
              {t('actions.back')}
            </Button>
            <Button
              type="submit"
              colorScheme="purple"
              isLoading={formik.isSubmitting}
            >
              {t('actions.continue')}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </FormControl>
  );
};
