import { Box, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { ChatAvatar } from 'layout/layout/components/right-sidebar/components/chat/components/chat-avatar';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { SocialPostType } from 'services/user/modules/social';
import { MERGED_CHAT_COLORS, getUserId } from 'services/user/modules/user';
import { UserType } from 'types';
import { Link } from 'uikit/link';

type Props = {
  user: UserType;
  post: SocialPostType;
  bg?: string;
  description?: string;
  title?: string;
  image?: string;
  won?: React.ReactNode;
};

export const Post: React.FC<Props> = ({ user, post, title, won }) => {
  const { t } = useTranslation();
  const myUserId = useSelector(getUserId);

  return (
    <Box>
      <Box
        px="5"
        py="2"
        data-group
        position="relative"
        _hover={{
          zIndex: '1',
          bg: 'sugar-dust',
        }}
        borderLeft={`1px solid ${
          MERGED_CHAT_COLORS[
            user.chatColor as keyof typeof MERGED_CHAT_COLORS
          ] || '#7B54E9'
        }`}
        bg="sugar-dust"
      >
        <Box
          alignItems="center"
          fontSize="14px"
          overflow="hidden"
          whiteSpace="nowrap"
          textOverflow="ellipsis"
          display="inline-flex"
          mr="1"
          verticalAlign="middle"
          maxW="full"
        >
          <ChatAvatar
            avatar={user.icon}
            badges={user.badges}
            username={user.name}
            nftId={user.nftId}
            level={user.level}
          />
          <Link
            to={`?modal=profile&username=${user.name}`}
            fontWeight="500"
            color={
              MERGED_CHAT_COLORS[
                user.chatColor as keyof typeof MERGED_CHAT_COLORS
              ] || 'vanilla-text'
            }
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            _hover={{ opacity: '0.9' }}
          >
            {`${user.id === myUserId ? t('social-feed.you') : user.name}:`}
          </Link>
        </Box>
        <Text as="span" verticalAlign="middle">
          {won}
          {title}{' '}
          <Text as="span" color="candy-floss-text">
            {dayjs(post.timestamp * 1000).fromNow()}
          </Text>
        </Text>
      </Box>
    </Box>
  );
};
