import { query } from 'helpers/query-string';

const DOMAIN = 'https://spinkick.imgix.net';

export const generateUrl = (src?: string, params = {}) =>
  src?.startsWith?.('/')
    ? `${DOMAIN}${src}${query.stringify({ auto: 'format', ...params })}`
    : src || '';

export const downloadImage = (url: string, name: string) => {
  const xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function onload() {
    const urlCreator = window.URL || window.webkitURL;
    const imageUrl = urlCreator.createObjectURL(this.response);
    const tag = document.createElement('a');
    tag.href = imageUrl;
    tag.download = name;
    document.body.appendChild(tag);
    tag.click();
    document.body.removeChild(tag);
  };
  xhr.send();
};

const loadedImages: { [key: string]: boolean } = {};

export const setImageLoaded = (url: string) => {
  loadedImages[url] = true;
};

export const getImageLoaded = (url: string) => !!loadedImages[url];
