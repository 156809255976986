import { Box, Flex } from '@chakra-ui/react';
import { Content } from 'components/content';
import { TipIcon } from 'icons';
import { useTranslation } from 'react-i18next';
import { Tabs } from 'uikit/tabs';
import { TipView } from './components/tip-view';

type Props = {
  onClose: () => void;
  contentProps: { tip?: string; username?: string };
};

export const Tip: React.FC<Props> = ({
  onClose,
  contentProps: { tip, username },
}) => {
  const { t } = useTranslation();
  return (
    <Box>
      <Flex align="center" w="full">
        <Box w="full">
          <Tabs
            index={0}
            tabs={[
              {
                children: t('wallet.tip'),
                Icon: TipIcon,
              },
            ]}
            onClose={onClose}
            hasClose
          />
        </Box>
      </Flex>
      <Content w="full" maxW="6xl" mx="auto" flexGrow="1" p="5">
        <TipView onClose={onClose} tip={tip} username={username} />
      </Content>
    </Box>
  );
};
