import { RouletteType } from './types';

type StateType = {
  roulette: RouletteType;
};

const defaultObj = {};

export const getRoulettePlaceBetLoading = (state: StateType) =>
  state.roulette.placeBetLoading;

export const getRouletteRolls = (state: StateType) => state.roulette.rolls;

export const getRouletteGame = (state: StateType) =>
  state.roulette.game || defaultObj;

export const getRouletteIsLoaded = (state: StateType) =>
  state.roulette.isLoaded;
