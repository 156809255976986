import { createContext } from 'react';

type BonusPopupContextType = {
  isHidden: boolean;
  setIsCollapsed: (arg: boolean) => void;
  setIsHidden: (arg: boolean) => void;
  isCollapsed: boolean;
  isAvailable: boolean;
};

export const BonusPopupContext = createContext<BonusPopupContextType>({
  isHidden: false,
  isAvailable: false,
  setIsCollapsed: () => {},
  setIsHidden: () => {},
  isCollapsed: false,
});
