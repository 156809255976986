/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  ListItem,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { getPathnameWithoutLang } from 'helpers/lang';
import { useSupport } from 'hooks/use-support';
import {
  BNBIcon,
  BTCIcon,
  DOGEIcon,
  ETHIcon,
  LTCIcon,
  SOLIcon,
  SSLEncryptionIcon,
  TONIcon,
  TRXIcon,
  USDCIcon,
  USDTIcon,
  XRPIcon,
} from 'icons';
import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { LazyImage } from 'uikit/lazy-image';
import { Link } from 'uikit/link';

export const Footer = () => {
  const { toggle: toggleSupport } = useSupport();
  const location = useLocation();
  const { t } = useTranslation();

  const [title, setTitle] = useState(document.title);

  const currencies = [
    {
      to: '?right=withdrawal&shortcut=USD',
      label: 'Buy with Visa',
      image: 'https://spinkick.imgix.net/footer-logos/visa.svg',
      alt: 'Visa',
    },
    {
      to: '?right=withdrawal&shortcut=USD',
      label: 'Buy with Mastercard',
      image: 'https://spinkick.imgix.net/footer-logos/mastercard.svg',
      alt: 'Mastercard',
    },
    {
      to: '?right=withdrawal&shortcut=ETH',
      label: t('footer.deposit', { currency: 'ETH' }),
      icon: ETHIcon,
    },
    {
      to: '?right=withdrawal&shortcut=USDC',
      label: t('footer.deposit', { currency: 'USDC' }),
      icon: USDCIcon,
    },
    {
      to: '?right=withdrawal&shortcut=USDT',
      label: t('footer.deposit', { currency: 'USDT' }),
      icon: USDTIcon,
    },
    {
      to: '?right=withdrawal&shortcut=TON',
      label: t('footer.deposit', { currency: 'TON' }),
      icon: TONIcon,
    },
    {
      to: '?right=withdrawal&shortcut=BTC',
      label: t('footer.deposit', { currency: 'BTC' }),
      icon: BTCIcon,
    },
    {
      to: '?right=withdrawal&shortcut=SOL',
      label: t('footer.deposit', { currency: 'SOL' }),
      icon: SOLIcon,
    },
    {
      to: '?right=withdrawal&shortcut=LTC',
      label: t('footer.deposit', { currency: 'LTC' }),
      icon: LTCIcon,
    },
    {
      to: '?right=withdrawal&shortcut=TRX',
      label: t('footer.deposit', { currency: 'TRX' }),
      icon: TRXIcon,
    },
    {
      to: '?right=withdrawal&shortcut=DOGE',
      label: t('footer.deposit', { currency: 'DOGE' }),
      icon: DOGEIcon,
    },
    {
      to: '?right=withdrawal&shortcut=XRP',
      label: t('footer.deposit', { currency: 'XRP' }),
      icon: XRPIcon,
    },
    {
      to: '?right=withdrawal&shortcut=BNB',
      label: t('footer.deposit', { currency: 'BNB' }),
      icon: BNBIcon,
    },
  ];
  const pathname = getPathnameWithoutLang(location.pathname);

  return (
    <Box
      w="full"
      borderTop="1px"
      borderColor="truffle-border"
      sx={{
        'a:hover': {
          textDecoration: 'underline',
        },
      }}
    >
      <Helmet onChangeClientState={newState => setTitle(newState.title)} />

      <Box w="full" maxW="6xl" p="5" mx="auto" flexGrow="1">
        {pathname !== '/' && (
          <Text as="h1" color="candy-floss-text" mb="5">
            Discover the Excitement: {title}
          </Text>
        )}
        <Grid
          templateColumns={{
            base: 'repeat(3, 1fr)',
            lg: 'repeat(4, 1fr)',
            xl: 'repeat(5, 1fr)',
          }}
          columnGap="3"
          rowGap="5"
          w="full"
          mb="7"
          sx={{
            'li a': {
              paddingX: '8px',
            },
            'li button': {
              paddingX: '8px',
            },
            li: {
              display: 'flex',
              marginLeft: '-8px',
            },
          }}
        >
          <GridItem>
            <Heading fontSize="md" mb="2">
              {t('sidebar.casino')}
            </Heading>
            <UnorderedList listStyleType="none" m="0" color="candy-floss-text">
              <ListItem>
                <Link to="/">{t('footer.games')}</Link>
              </ListItem>
              <ListItem>
                <Link to="/casino/category/slots">
                  {t('casino.category.slots')}
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/casino/category/live-casino">
                  {t('casino.category.live-casino')}
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/casino/category/roulette">
                  {t('casino.category.roulette')}
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/casino/category/blackjack">
                  {t('casino.category.blackjack')}
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/casino/providers">{t('casino.providers')}</Link>
              </ListItem>
              <ListItem>
                <Link to="/promotions">{t('sidebar.promotions')}</Link>
              </ListItem>
            </UnorderedList>
          </GridItem>
          <GridItem>
            <Heading fontSize="md" mb="2">
              NFT
            </Heading>
            <UnorderedList listStyleType="none" m="0" color="candy-floss-text">
              {/* <ListItem>
                <Link to="/max">{t('max.sherbet')}</Link>
              </ListItem> */}
              <ListItem>
                <Link to="/nft">{t('footer.marketplace')}</Link>
              </ListItem>
              <ListItem>
                <Link to="https://blur.io/eth/collection/playsherbet">
                  Blur
                </Link>
              </ListItem>
              <ListItem>
                <Link to="https://nft.coinbase.com/collection/sherbet">
                  Coinbase NFT
                </Link>
              </ListItem>
              <ListItem>
                <Link to="https://magiceden.io/collections/ethereum/0xa54e6b99f58b6165228abcdc681e9a16bcefdbe1">
                  Magic Eden
                </Link>
              </ListItem>
            </UnorderedList>
          </GridItem>
          <GridItem>
            <Heading fontSize="md" mb="2">
              {t('sidebar.support')}
            </Heading>
            <UnorderedList listStyleType="none" m="0" color="candy-floss-text">
              <ListItem>
                <Link to="/policies/fairness">{t('casino-game-fair')}</Link>
              </ListItem>
              <ListItem>
                <Link to="/">{t('profile.affiliates')}</Link>
              </ListItem>
              <ListItem>
                <Link to="/responsible-gambling/sherbet-safe">
                  {t('footer.responsible-gambling')}
                </Link>
              </ListItem>
              <ListItem>
                <Link href="https://www.begambleaware.org/" isTextOutline>
                  {t('footer.gamble-aware')}
                </Link>
              </ListItem>
              <ListItem>
                <Button
                  variant="link"
                  fontWeight="400"
                  onClick={e => {
                    e.preventDefault();
                    toggleSupport();
                  }}
                >
                  {t('support.live-support')}
                </Button>
              </ListItem>
              <ListItem>
                <Link
                  href="https://help.sherbet.com"
                  isTextOutline
                  rel="nofollow"
                >
                  {t('footer.help-center')}
                </Link>
              </ListItem>
              <ListItem>
                <Link href="/blog/category/how-to-guides">
                  {t('footer.how-to-guides')}
                </Link>
              </ListItem>
            </UnorderedList>
          </GridItem>
          <GridItem>
            <Heading fontSize="md" mb="2">
              {t('footer.community')}
            </Heading>
            <UnorderedList listStyleType="none" m="0" color="candy-floss-text">
              <ListItem>
                <Link to="/blog">{t('sidebar.blog')}</Link>
              </ListItem>
              <ListItem>
                <Link href="https://discord.gg/sherbet" isTextOutline>
                  Discord
                </Link>
              </ListItem>
              <ListItem>
                <Link href="https://x.com/sherbet" isTextOutline>
                  X/Twitter
                </Link>
              </ListItem>
              <ListItem>
                <Link href="#" isTextOutline>
                  Facebook
                </Link>
              </ListItem>
              <ListItem>
                <Link href="https://t.me/Sherbet" isTextOutline>
                  Telegram
                </Link>
              </ListItem>
            </UnorderedList>
          </GridItem>
          <GridItem>
            <Heading fontSize="md" mb="2">
              {t('footer.about-us')}
            </Heading>
            <UnorderedList listStyleType="none" m="0" color="candy-floss-text">
              <ListItem>
                <Link to="/policies/privacy">
                  {t('sidebar.privacy-policy')}
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/licenses">{t('footer.licenses')}</Link>
              </ListItem>
              <ListItem>
                <Link to="/policies/aml">{t('sidebar.aml')}</Link>
              </ListItem>
              <ListItem>
                <Link to="/policies/terms">
                  {t('sidebar.terms-conditions')}
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/policies/self-exclusion">
                  {t('settings.self-exclusion')}
                </Link>
              </ListItem>
              <ListItem>
                <Link to="/policies/nft-terms">{t('sidebar.nft-terms')}</Link>
              </ListItem>
            </UnorderedList>
          </GridItem>
        </Grid>
        <Grid
          templateColumns={{
            base: 'repeat(4, 1fr)',
            xl: 'repeat(18, 1fr)',
          }}
          rowGap="5"
          columnGap="3"
          mb="5"
        >
          {currencies.map(({ to, label, image, alt, icon: Icon }) => (
            <GridItem key={label} h="21px" display="flex">
              <Link
                to={to}
                opacity="0.5"
                _hover={{ opacity: '1' }}
                aria-label={label}
                display="flex"
              >
                {Icon ? (
                  <Icon h="21px" w="21px" />
                ) : (
                  <LazyImage src={image} alt={alt} h="21px" />
                )}
              </Link>
            </GridItem>
          ))}
        </Grid>
        <Box mb="5">
          <Text fontSize="sm" color="candy-floss-text">
            {t('footer.disclaimer')}
          </Text>
        </Box>
        <Grid
          templateColumns={{
            base: 'repeat(3, 1fr)',
            xl: 'repeat(8, 1fr)',
          }}
          rowGap="5"
          columnGap="3"
        >
          <GridItem h="8" display="flex">
            <Box position="relative">
              <SSLEncryptionIcon h="8" w="auto" />
            </Box>
          </GridItem>
          <GridItem h="8" display="flex">
            <Link
              href="https://www.responsiblegambling.org"
              _hover={{ opacity: '0.9' }}
              display="flex"
            >
              <LazyImage
                src="https://spinkick.imgix.net/footer-logos/responsible-gambling.svg"
                alt="Responsible Gambling"
                h="8"
              />
            </Link>
          </GridItem>
          <GridItem h="8" display="flex">
            <Link
              to="/policies/terms"
              _hover={{ opacity: '0.9' }}
              display="flex"
            >
              <LazyImage
                src="https://spinkick.imgix.net/footer-logos/18-plus.svg"
                alt="18+"
                h="8"
              />
            </Link>
          </GridItem>
        </Grid>
      </Box>
    </Box>
  );
};
