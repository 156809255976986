import { sanityClient } from 'helpers/sanity';

export const loadPage = (type: string) =>
  sanityClient.fetch(`*[_type == "page" && type == $type && lang == $lang]`, {
    type,
    lang: 'en',
  });

export const loadBanner = (id: string) =>
  sanityClient.fetch(`*[_type == "banner" && id == $id]`, { id });

export const loadSidebar = () => sanityClient.fetch(`*[_type == "sidebar"]`);
