import { createIcon } from '@chakra-ui/icons';

export const FriendsIcon = createIcon({
  displayName: 'FriendsIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="M7.111 4.444a2.667 2.667 0 1 1-5.333 0 2.667 2.667 0 0 1 5.333 0Zm7.111 0a2.667 2.667 0 1 1-5.334 0 2.667 2.667 0 0 1 5.334 0Zm-2.726 9.778c-.47 0-.83-.418-.83-.889a6.196 6.196 0 0 0-1.333-3.849A4.445 4.445 0 0 1 16 13.334c0 .49-.398.888-.889.888h-3.615ZM4.444 8.89a4.445 4.445 0 0 1 4.445 4.444.89.89 0 0 1-.889.89H.889a.889.889 0 0 1-.889-.89A4.445 4.445 0 0 1 4.444 8.89Z"
    />
  ),
});
