import { PagesType } from './types';

type StateType = {
  pages: PagesType;
};

export const getPage = (id: string) => (state: StateType) =>
  state.pages.pages[id];

export const getBanner = (id: string) => (state: StateType) =>
  state.pages.banners[id];

export const getSidebar = (state: StateType) => state.pages.sidebar;

export const getIsSidebarLoaded = (state: StateType) =>
  state.pages.isSidebarLoaded;
