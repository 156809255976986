import { Box } from '@chakra-ui/react';
import { RefObject, memo, useLayoutEffect, useRef, useState } from 'react';
import { PieChart } from 'react-minimal-pie-chart';
import { ENTRIES, rouletteColors, toColor } from '../../utils';

const easing = 'cubic-bezier(1, 0.05, 0.05, 1)';

const SPIN_TIMES = 10;

type AnimateParamsType = {
  complete: {
    rollNumber: number;
    bonusRollNumber: number;
  };
  gameId: string;
  bonusNumber: number;
  onRollFinished: ({
    gameId,
    complete,
    bonusNumber,
  }: {
    gameId: string;
    bonusNumber: number;
    complete: {
      rollNumber: number;
      bonusRollNumber: number;
    };
  }) => void;
  settings: {
    spinTime: number;
  };
  spinnerRef: RefObject<HTMLDivElement>;
  setEntry: (params: { number: number; rotate: number }) => void;
  entry: {
    number: number;
    rotate: number;
  };
};

const animate = ({
  spinnerRef,
  complete,
  onRollFinished,
  settings,
  bonusNumber,
  gameId,
  setEntry,
  entry: { number: initEntry, rotate },
}: AnimateParamsType) => {
  // const complete = { rollNumber: 0 };
  const duration = settings.spinTime - 100;
  const entryIndex = ENTRIES.findIndex(entry => entry === complete.rollNumber);
  const keyframes = [
    {
      transform: `rotate(${rotate}deg)`,
    },
  ];
  let initialRotate = rotate;

  const degPerItem = 360 / ENTRIES.length; // 24

  // const initEntry = 6; // default number visible
  const initEntryIndex = ENTRIES.findIndex(entry => entry === initEntry);
  const degPerIterate = 2;
  const totalKeyframes = SPIN_TIMES * (360 / degPerIterate); // must divide by 30 if step is 12
  let offset = 0;

  if (entryIndex > initEntryIndex) {
    offset -= (entryIndex - initEntryIndex) * (degPerItem / degPerIterate); // if 24 to 24 * 1 | 12 to 24 * 2
  } else if (entryIndex < initEntryIndex) {
    offset += (initEntryIndex - entryIndex) * (degPerItem / degPerIterate);
  }
  const calculatedTotalKeyframes = totalKeyframes + offset;

  let nextEntry:
    | {
        number: number;
        rotate: number;
      }
    | undefined;

  for (let i = 0; i < calculatedTotalKeyframes; i += 1) {
    initialRotate += degPerIterate;

    if (i + 1 === calculatedTotalKeyframes) {
      nextEntry = {
        number: complete.rollNumber,
        rotate: initialRotate,
      };
    }

    keyframes.push({
      transform: `rotate(${initialRotate}deg)`,
    });
  }

  if (nextEntry) {
    setEntry(nextEntry);
  }

  if (spinnerRef.current) {
    const spinnerAnimation = spinnerRef.current.animate(keyframes, {
      duration,
      easing,
    });

    spinnerAnimation.onfinish = () => {
      onRollFinished({ gameId, complete, bonusNumber });
    };
  }
};

type Props = {
  animatingGameId: string;
  complete?: {
    rollNumber: number;
    bonusRollNumber: number;
  };
  time: number;
  gameId: string;
  bonusNumber: number;
  onRollFinished: ({
    gameId,
    complete,
    bonusNumber,
  }: {
    gameId: string;
    bonusNumber: number;
    complete: {
      rollNumber: number;
      bonusRollNumber: number;
    };
  }) => void;
  settings: {
    spinTime: number;
  };
};

export const CircleSpinner: React.FC<Props> = memo(
  ({
    animatingGameId,
    complete,
    time,
    gameId,
    bonusNumber,
    onRollFinished,
    settings,
  }) => {
    const [entry, setEntry] = useState({
      number: 6,
      rotate: -6, // 'default deg
    });
    const spinnerRef = useRef<HTMLDivElement>(null);
    useLayoutEffect(() => {
      if (animatingGameId && complete && time) {
        animate({
          gameId,
          complete,
          bonusNumber,
          onRollFinished,
          settings,
          spinnerRef,
          setEntry,
          entry,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [animatingGameId]);

    return (
      <Box>
        <Box w="full" pos="relative" pt="3" transform="rotate(180deg)">
          <Box
            bg={
              (complete?.bonusRollNumber &&
                rouletteColors[complete.bonusRollNumber]) ||
              'candy-floss-text'
            }
            pos="absolute"
            left="calc(50% - 10px)"
            top="0"
            rounded="full"
            w="20px"
            h="20px"
            bottom="0"
            zIndex="2"
          />
          <Box pos="relative" paddingBottom="38%">
            <Box
              pos="absolute"
              left="0"
              right="0"
              top="0"
              ref={spinnerRef}
              transform={`rotate(${entry.rotate}deg)`}
            >
              <PieChart
                data={ENTRIES.map(entr => ({
                  value: 1,
                  color: toColor(entr),
                  number: entr,
                  label: entr === 6 ? rouletteColors[6] : null,
                }))}
              />
              <PieChart
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  zIndex: 2,
                }}
                data={ENTRIES.map(entr => ({
                  value: 1,
                  color:
                    entr === bonusNumber
                      ? rouletteColors[bonusNumber]
                      : toColor(entr),
                }))}
                lineWidth={3}
              />
              <Box
                top="50%"
                left="50%"
                transform="translate(-50%, -50%)"
                pos="absolute"
                rounded="full"
                w="75%"
                h="75%"
                bg="toffee-base"
              />
            </Box>
          </Box>
        </Box>
      </Box>
    );
  },
  (prevProps, nextProps) =>
    !(
      prevProps.gameId !== nextProps.gameId ||
      prevProps.complete !== nextProps.complete ||
      prevProps.animatingGameId !== nextProps.animatingGameId
    ),
);
