import { Box, Flex, FormControl, Heading, Text } from '@chakra-ui/react';
import { Content } from 'components/content';
import { RouterTabs } from 'components/router-tabs';
import { CustomInput } from 'forms/custom-input';
import { formatCredit } from 'helpers/numeral';
import {
  CalculatorIcon,
  EyeCloseIcon,
  InfoIcon,
  ProfileIcon,
  ResponsibleGamblingIcon,
} from 'icons';
import React, { useState } from 'react';

export const BudgetCalculator: React.FC = () => {
  const [wages, setWages] = useState(0);
  const [pensions, setPensions] = useState(0);
  const [benefits, setBenefits] = useState(0);
  const [otherIncome, setOtherIncome] = useState(0);
  const [rent, setRent] = useState(0);
  const [utilities, setUtilities] = useState(0);
  const [loans, setLoans] = useState(0);
  const [otherExpenses, setOtherExpenses] = useState(0);

  const [wagesDisplay, setWagesDisplay] = useState('0.00');
  const [pensionsDisplay, setPensionsDisplay] = useState('0.00');
  const [benefitsDisplay, setBenefitsDisplay] = useState('0.00');
  const [otherIncomeDisplay, setOtherIncomeDisplay] = useState('0.00');
  const [rentDisplay, setRentDisplay] = useState('0.00');
  const [utilitiesDisplay, setUtilitiesDisplay] = useState('0.00');
  const [loansDisplay, setLoansDisplay] = useState('0.00');
  const [otherExpensesDisplay, setOtherExpensesDisplay] = useState('0.00');

  const totalIncome = wages + pensions + benefits + otherIncome;
  const totalExpenses = rent + utilities + loans + otherExpenses;
  const disposableIncome = totalIncome - totalExpenses;

  const formatCurrency = (value: number) => value.toFixed(2);

  const handleBlur = (
    value: string,
    setValue: React.Dispatch<React.SetStateAction<number>>,
    setDisplay: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    const parsedValue = parseFloat(value) || 0;
    setValue(parsedValue);
    setDisplay(formatCurrency(parsedValue));
  };

  function renderDisposableIncome() {
    if (totalIncome > 0 || totalExpenses > 0) {
      if (disposableIncome > 0) {
        return (
          <Box
            bg="sugar-dust"
            border="1px dashed"
            borderColor="truffle-border"
            p="5"
            py="3"
            rounded="xl"
            w="full"
          >
            <Text color="candy-floss-text">
              Based on your data above, you should have $
              {formatCredit(disposableIncome)} in disposable income per month.
              This could be used to play on Sherbet.
            </Text>
          </Box>
        );
      }
      return (
        <Box
          bg="sherbet-orange.100"
          border="1px dashed"
          borderColor="sherbet-orange.200"
          p="5"
          py="3"
          rounded="xl"
          w="full"
        >
          <Text color="sherbet-orange.200">
            Based on your data above, your monthly expenses exceed your monthly
            income. We recommend reading the Sherbet Safe tab to learn how to
            manage your money and gamble on Sherbet responsibly.
          </Text>
        </Box>
      );
    }
    return (
      <Box
        bg="sugar-dust"
        border="1px dashed"
        borderColor="truffle-border"
        p="5"
        py="3"
        rounded="xl"
        w="full"
      >
        <Text color="candy-floss-text">
          Based on your data above, you should have $
          {formatCredit(disposableIncome)} in disposable income per month. This
          could be used to play on Sherbet.
        </Text>
      </Box>
    );
  }

  return (
    <>
      <RouterTabs
        tabs={[
          {
            children: 'Sherbet Safe',
            to: '/responsible-gambling/sherbet-safe',
            Icon: ResponsibleGamblingIcon,
          },
          {
            children: 'FAQ',
            to: '/responsible-gambling/faq',
            Icon: InfoIcon,
          },
          {
            children: 'Recognizing Signs',
            Icon: EyeCloseIcon,
            to: '/responsible-gambling/recognizing-signs',
          },
          {
            children: 'Self-Assessment',
            Icon: ProfileIcon,
            to: '/responsible-gambling/self-assessment',
          },
          {
            children: 'Budget Calculator',
            Icon: CalculatorIcon,
            to: '/responsible-gambling/budget-calculator',
          },
        ]}
      />
      <Content w="full" maxW="6xl" mx="auto" flexGrow="1">
        <Flex p="5" flexDirection="column">
          <Flex
            gap={{ base: '5', xl: '14' }}
            align="start"
            flexDir={{ base: 'column-reverse', xl: 'row' }}
          >
            <Box w="full">
              <Box bg="sugar-dust" rounded="xl">
                <Flex
                  justifyContent="space-between"
                  align="center"
                  px="5"
                  h="45px"
                  roundedTop="xl"
                  bg="sugar-dust"
                  border="1px solid var(--chakra-colors-truffle-border)"
                >
                  <Flex align="center" gap="2">
                    <CalculatorIcon
                      color="candy-floss-text"
                      w="14px"
                      h="14px"
                    />
                    <Text fontWeight="500">Budget Calculator</Text>
                  </Flex>
                </Flex>
                <FormControl
                  as="form"
                  border="1px solid"
                  borderTop="none"
                  borderColor="truffle-border"
                  roundedBottom="xl"
                >
                  <Flex direction="column">
                    <Flex p="5" flexDirection="column">
                      <Flex
                        gap="5"
                        flexDirection={{ base: 'column', md: 'row' }}
                      >
                        <Box w="full">
                          <Heading fontSize="lg" mb="3">
                            Income
                          </Heading>
                          <Flex flexDirection="column" gap="3">
                            <CustomInput
                              placeholder="Wages After Deductions"
                              label="Wages After Deductions"
                              type="text"
                              value={wagesDisplay}
                              onChange={e => setWagesDisplay(e.target.value)}
                              onBlur={e =>
                                handleBlur(
                                  e.target.value,
                                  setWages,
                                  setWagesDisplay,
                                )
                              }
                              required
                            />
                            <CustomInput
                              placeholder="Pensions"
                              label="Pensions"
                              type="text"
                              value={pensionsDisplay}
                              onChange={e => setPensionsDisplay(e.target.value)}
                              onBlur={e =>
                                handleBlur(
                                  e.target.value,
                                  setPensions,
                                  setPensionsDisplay,
                                )
                              }
                              required
                            />
                            <CustomInput
                              placeholder="Benefits"
                              label="Benefits"
                              type="text"
                              value={benefitsDisplay}
                              onChange={e => setBenefitsDisplay(e.target.value)}
                              onBlur={e =>
                                handleBlur(
                                  e.target.value,
                                  setBenefits,
                                  setBenefitsDisplay,
                                )
                              }
                              required
                            />
                            <CustomInput
                              placeholder="Other Income"
                              label="Other Income"
                              type="text"
                              value={otherIncomeDisplay}
                              onChange={e =>
                                setOtherIncomeDisplay(e.target.value)
                              }
                              onBlur={e =>
                                handleBlur(
                                  e.target.value,
                                  setOtherIncome,
                                  setOtherIncomeDisplay,
                                )
                              }
                              required
                            />
                          </Flex>
                        </Box>
                        <Box w="full">
                          <Heading fontSize="lg" mb="3">
                            Expenses
                          </Heading>
                          <Flex flexDirection="column" gap="3">
                            <CustomInput
                              placeholder="Rent/Mortgage"
                              label="Rent/Mortgage"
                              type="text"
                              value={rentDisplay}
                              onChange={e => setRentDisplay(e.target.value)}
                              onBlur={e =>
                                handleBlur(
                                  e.target.value,
                                  setRent,
                                  setRentDisplay,
                                )
                              }
                              required
                            />
                            <CustomInput
                              placeholder="Utility Bills"
                              label="Utility Bills"
                              type="text"
                              value={utilitiesDisplay}
                              onChange={e =>
                                setUtilitiesDisplay(e.target.value)
                              }
                              onBlur={e =>
                                handleBlur(
                                  e.target.value,
                                  setUtilities,
                                  setUtilitiesDisplay,
                                )
                              }
                              required
                            />
                            <CustomInput
                              placeholder="Loans/Credit"
                              label="Loans/Credit"
                              type="text"
                              value={loansDisplay}
                              onChange={e => setLoansDisplay(e.target.value)}
                              onBlur={e =>
                                handleBlur(
                                  e.target.value,
                                  setLoans,
                                  setLoansDisplay,
                                )
                              }
                              required
                            />
                            <CustomInput
                              placeholder="Other Expenses"
                              label="Other Expenses"
                              type="text"
                              value={otherExpensesDisplay}
                              onChange={e =>
                                setOtherExpensesDisplay(e.target.value)
                              }
                              onBlur={e =>
                                handleBlur(
                                  e.target.value,
                                  setOtherExpenses,
                                  setOtherExpensesDisplay,
                                )
                              }
                              required
                            />
                          </Flex>
                        </Box>
                      </Flex>
                      <Box w="full" mt="5">
                        {renderDisposableIncome()}
                      </Box>
                    </Flex>
                  </Flex>
                </FormControl>
              </Box>
            </Box>
          </Flex>
        </Flex>
      </Content>
    </>
  );
};
