import { createIcon } from '@chakra-ui/icons';

export const BlockIcon = createIcon({
  displayName: 'BlockIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.11 4.523a6 6 0 0 0 8.367 8.367L3.108 4.524ZM4.522 3.11l8.368 8.367A6 6 0 0 0 4.524 3.11ZM0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8Z"
      clipRule="evenodd"
    />
  ),
});
