import { createIcon } from '@chakra-ui/icons';

export const MuteIcon = createIcon({
  displayName: 'MuteIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path
        fill="currentColor"
        d="M1 6.32c0-.392 0-.588.076-.738a.7.7 0 0 1 .306-.306c.15-.076.346-.076.738-.076h1.216c.171 0 .257 0 .337-.02a.7.7 0 0 0 .203-.083c.07-.043.131-.104.252-.225l2.216-2.216c.3-.3.45-.45.579-.46a.35.35 0 0 1 .293.122c.084.098.084.31.084.734v9.896c0 .424 0 .636-.084.734a.35.35 0 0 1-.293.122c-.13-.01-.28-.16-.579-.46l-2.216-2.216a1.513 1.513 0 0 0-.252-.225.704.704 0 0 0-.203-.084c-.08-.019-.166-.019-.337-.019H2.12c-.392 0-.588 0-.738-.076a.7.7 0 0 1-.306-.306C1 10.268 1 10.072 1 9.68V6.32Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.3 4.114 4.835 5.58l-.025.026c-.09.09-.234.236-.412.344a1.7 1.7 0 0 1-.491.204 2.197 2.197 0 0 1-.535.047H2v3.6h1.372c.129 0 .333-.002.535.047.173.042.34.11.491.204.178.108.321.253.412.344l.025.026L6.3 11.886V4.114Zm-.008 9.246v-.002ZM6.844 1.2c.43-.035.852.14 1.133.468.26.306.296.666.309.806.014.164.014.362.014.546v9.96c0 .184 0 .382-.014.546-.013.14-.048.5-.31.806a1.35 1.35 0 0 1-1.132.469c-.401-.032-.68-.261-.788-.352a6.442 6.442 0 0 1-.397-.375 5.978 5.978 0 0 1-.022-.023L3.42 11.835l-.035-.035H2.092c-.171 0-.355 0-.513-.013a1.714 1.714 0 0 1-.65-.172 1.7 1.7 0 0 1-.744-.743 1.714 1.714 0 0 1-.172-.65C0 10.061 0 9.878 0 9.707V6.292c0-.171 0-.355.013-.513.014-.179.05-.411.172-.65a1.7 1.7 0 0 1 .743-.744c.24-.122.472-.158.65-.172.16-.013.343-.013.514-.013h1.294l.035-.035 2.216-2.216.022-.023c.13-.13.27-.27.397-.375.107-.09.387-.32.788-.352Zm3.249 3.993a1 1 0 0 1 1.414 0L12.9 6.586l1.393-1.393a1 1 0 1 1 1.414 1.414L14.314 8l1.393 1.393a1 1 0 0 1-1.414 1.414L12.9 9.414l-1.393 1.393a1 1 0 0 1-1.414-1.414L11.486 8l-1.393-1.393a1 1 0 0 1 0-1.414Z"
        clipRule="evenodd"
      />
    </>
  ),
});
