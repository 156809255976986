/* eslint-disable react/no-unescaped-entities */
import { Box, Flex } from '@chakra-ui/react';
import { Content } from 'components/content';
import { trackEvent } from 'helpers/analytics';
import { useSeon } from 'hooks/use-seon';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { registerUser } from 'services/auth/modules/auth';
import { loadPage } from 'services/cms/modules/pages';
import { getUserIsAuthorized } from 'services/user/modules/user';
import { useActions } from 'store';
import { LoginForm } from './components/login-form';
import { ResetPasswordForm } from './components/reset-password-form';
import { ResetPasswordRequestForm } from './components/reset-password-request-form';
import { Step1 } from './components/step1';
import { Step2 } from './components/step2';
import { Step3 } from './components/step3';
import { Step4 } from './components/step4';
import { Step5 } from './components/step5';
import { Step6 } from './components/step6';

const AUTH_PARAMS = 'AUTH_PARAMS';

type Props = {
  onClose: () => void;
  contentProps: { step?: string; tab?: string };
};

export const Auth: React.FC<Props> = ({
  onClose,
  contentProps: { step: propStep = '1', tab = 'login', ...props },
}) => {
  const isAuthorized = useSelector(getUserIsAuthorized);
  const [isMount, setIsMount] = useState(false);
  const [params, setParams] = useState<{
    email?: string;
    password?: string;
    username?: string;
  }>(() => {
    const storage = localStorage.getItem(AUTH_PARAMS);
    if (storage) {
      try {
        return JSON.parse(storage);
      } catch (e) {
        console.warn('invalid params');
      }
    }

    return {};
  });
  const [items, setItems] = useState<string[]>([]);
  const actions = useActions({ registerUser, loadPage });
  const [searchParams, setSearchParams] = useSearchParams();

  const step = Number(propStep);

  useEffect(() => {
    if (isAuthorized && step !== 7) {
      onClose();
    }
  }, [isAuthorized, step, onClose]);

  useEffect(() => {
    if (tab === 'registration') {
      actions.loadPage('terms');
    }
  }, [actions, tab]);

  const setStep = (nextStep: number) => {
    if (nextStep === 2 && !items.length) {
      setItems(['Stake', 'Rollbit', 'Roobet']);
    }

    setSearchParams({
      ...Object.fromEntries(searchParams),
      step: String(nextStep),
    });
  };

  useEffect(() => {
    if (step > 1) {
      setStep(1);
    }
    setIsMount(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getSeonSession } = useSeon();

  const onChangeParams = (next: object) => {
    setParams(prev => ({ ...prev, ...next }));
  };

  useEffect(() => {
    const { password, ...rest } = params;
    try {
      localStorage.setItem(AUTH_PARAMS, JSON.stringify(rest));
    } catch (e) {
      console.warn(e);
    }
  }, [params]);

  const onSubmit = async () => {
    let afdp = null;
    try {
      afdp = await getSeonSession();
    } catch (e) {
      console.warn(e);
    }
    await actions.registerUser({ ...params, afdp });
    localStorage?.removeItem?.('GET_STARTED_COMPLEATE');
    if (params.email) {
      trackEvent('register', { email: params.email });
    }
    window.coinzilla_performance.push({ event: 'register' });
    localStorage?.removeItem?.(AUTH_PARAMS);
    // setStep('8');
    // onClose();
    // openDepositModal();
  };

  let content;

  if (!isMount && step > 1) {
    return null;
  }

  if (tab === 'login') {
    content = (
      <Box maxW="md">
        <LoginForm {...props} onClose={onClose} />
      </Box>
    );
  } else if (tab === 'forgotPassword') {
    content = (
      <Box maxW="md">
        <ResetPasswordRequestForm {...props} />
      </Box>
    );
  } else if (tab === 'resetPassword') {
    content = (
      <Box maxW="md">
        <ResetPasswordForm {...props} />
      </Box>
    );
  } else if (tab === 'registration') {
    content = (
      <>
        {step === 1 && <Step1 setStep={setStep} setParams={onChangeParams} />}
        {step === 2 && (
          <Step2
            setStep={setStep}
            setParams={onChangeParams}
            {...props}
            email={params.email}
          />
        )}
        {step === 3 && (
          <Step3
            setStep={setStep}
            setParams={onChangeParams}
            username={params.username}
          />
        )}
        {step === 4 && (
          <Step4
            setStep={setStep}
            setParams={onChangeParams}
            username={params.username || ''}
          />
        )}
        {step === 5 && <Step5 onSubmit={onSubmit} onClose={onClose} />}
        {step === 6 && <Step6 onClose={onClose} isClosing={false} />}
      </>
    );
  }

  return (
    <Content w="full" maxW="6xl" mx="auto" flexGrow="1" key={`${tab}_${step}`}>
      <Flex
        mt={{ base: 'auto', lg: 'initial' }}
        p="5"
        flexDirection="column"
        minH={{
          base:
            'auto' ||
            'calc(100vh - var(--header-height) - var(--footer-height))',
          lg: 'calc(100vh - var(--header-height))',
        }}
        gap="5"
        justifyContent={{ lg: 'center' }}
      >
        {content}
      </Flex>
    </Content>
  );
};
