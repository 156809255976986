import { createIcon } from '@chakra-ui/icons';

export const PlaylistsIcon = createIcon({
  displayName: 'PlaylistsIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      d="M13.6933 4.95706L5.64418 0.441718C4.66259 -0.147239 3.28835 -0.147239 2.30676 0.441718C1.32516 1.03067 0.539886 2.20859 0.539886 3.3865V12.6135C0.539886 13.7914 1.12884 14.9693 2.30676 15.5583C3.28835 16.1472 4.66259 16.1472 5.64418 15.5583L13.6933 11.0429C14.6749 10.454 15.4601 9.27608 15.4601 8.09816C15.4601 6.92025 14.8712 5.54601 13.6933 4.95706Z"
      fill="currentColor"
    />
  ),
});
