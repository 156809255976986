import { createIcon } from '@chakra-ui/icons';

export const LobbyIcon = createIcon({
  displayName: 'LobbyIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path
        fill="currentColor"
        d="m.267 8.336 6.2-6.674a2.107 2.107 0 0 1 3.066 0l6.2 6.674c.598.643.127 1.668-.766 1.668H1.033c-.893 0-1.364-1.025-.766-1.668Z"
      />
      <path
        fill="currentColor"
        d="M14.188 8.005H1.812v4.996c0 1.104.923 1.999 2.062 1.999H6.5v-2.998c0-.552.43-1 1-1h1c.57 0 1 .448 1 1V15h2.626c1.139 0 2.062-.895 2.062-1.998V8.005Z"
      />
    </>
  ),
});
