import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Text,
} from '@chakra-ui/react';
import { Content } from 'components/content';
import { PreviewSlider } from 'components/preview-slider';
import { Seo } from 'components/seo';
import dayjs from 'dayjs';
import { langForContent } from 'helpers/lang';
import { imageBuilder } from 'helpers/sanity';
import { PromotionsIcon, TwitterIcon } from 'icons';
import shuffle from 'lodash.shuffle';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { BlogPreview } from 'services/cms/components/blog-preview';
import { HtmlContent } from 'services/cms/components/html-content';
import { Accordion } from 'services/cms/components/html-content/components/accordion';
import styles from 'services/cms/components/html-content/style.module.scss';
import {
  getPromoIsLoaded,
  getPromoPost,
  getPromoPosts,
  loadPromo,
  loadPromos,
} from 'services/cms/modules/promo';
import { useActions } from 'store';
import { ImageFallback } from 'uikit/image-fallback';
import { Link } from 'uikit/link';
import { Skeleton } from 'uikit/skeleton';
import { SkeletonList } from 'uikit/skeleton-list';

export const PromoPost = props => {
  let { slug } = useParams();
  const { propSlug } = props;
  if (propSlug) {
    slug = propSlug;
  }
  let post = useSelector(getPromoPost(slug));
  const actions = useActions({ loadPromos, loadPromo });
  const isLoaded = useSelector(getPromoIsLoaded);
  const posts = useSelector(getPromoPosts);
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (!post) {
      actions.loadPromo(slug, i18n.language);
    }
  }, [slug, actions, post, i18n.language]);

  useEffect(() => {
    if (!isLoaded) {
      actions.loadPromos(langForContent(i18n.language));
    }
  }, [isLoaded, i18n.language, actions]);

  const randomPosts = useMemo(
    () =>
      shuffle(
        posts.filter(
          p =>
            p.slug !== slug &&
            p.mainImage &&
            (!p.endAt || new Date(p.endAt) > new Date()),
        ),
      ).slice(0, 6),
    [slug, posts],
  );

  const isPostLoaded = post !== undefined;

  if (post === null) return <Navigate to="/" />;

  const accordion =
    isPostLoaded && post?.body?.find(i => i._type === 'accordion');

  if (accordion) {
    post = {
      ...post,
      body: post.body?.filter(i => i._type !== 'accordion'),
    };
  }

  return (
    <>
      {isPostLoaded && (
        <Seo
          title={post.title}
          image={
            post.mainImage
              ? `${imageBuilder.image(post.mainImage)}?q=80&auto=format&w=1200`
              : undefined
          }
        />
      )}
      <Content w="full" flexGrow="1">
        <Box w="full" borderBottom="1px solid" borderColor="truffle-border">
          <Flex
            flexDir="column"
            justifyContent="end"
            p="5"
            maxW="6xl"
            w="full"
            mx="auto"
            mt="auto"
            h="full"
          >
            <Flex
              justifyContent="space-between"
              gap="5"
              align={{ base: 'start', md: 'end' }}
              flexDirection={{ base: 'column', md: 'row' }}
            >
              <Box>
                <Skeleton isLoaded={isPostLoaded} maxW="sm">
                  <Heading fontWeight="500" fontSize="xl">
                    {post?.title || '|'}
                  </Heading>
                </Skeleton>
                <Skeleton isLoaded={isPostLoaded} mt="1" maxW="sm">
                  <Text mt="1" color="candy-floss-text" maxW="sm" noOfLines="1">
                    Posted{' '}
                    {post?.publishedAt
                      ? dayjs(post.publishedAt * 1000).fromNow()
                      : 'a while ago'}
                  </Text>
                </Skeleton>
              </Box>
              <Flex align="center" gap="2">
                {post?.button && (
                  <Button colorScheme="purple" as={Link} to={post.button.link}>
                    {t(post.button.title, post.button.title)}
                  </Button>
                )}
                <Link
                  rounded="full"
                  href={
                    slug === 'welcome-package'
                      ? `https://x.com/intent/tweet?text=Have%20you%20seen%20the%20@Sherbet%20${encodeURIComponent(
                          post?.title,
                        )}%3F%0A%0ASign%20up%20now%20to%20receive%20three%20deposit%20bonuses%20and%20up%20to%20twenty%20free%20spins%20on%20Gates%20of%20Olympus%21%0A%0Ahttps%3A%2F%2Fsherbet.com%2Fpromotions%2F${encodeURIComponent(
                          slug,
                        )}`
                      : `https://x.com/intent/tweet?text=Have%20you%20seen%20the%20@Sherbet%20${encodeURIComponent(
                          post?.title,
                        )}%20promotion%3F%0A%0A${encodeURIComponent(
                          `https://sherbet.com/promotions/${slug}`,
                        )}`
                  }
                  isExternal
                >
                  <IconButton rounded="full" icon={<TwitterIcon />} />
                </Link>
              </Flex>
            </Flex>
          </Flex>
        </Box>
        <Box p="5" maxW="6xl" w="full" mx="auto">
          <Flex
            gap={{ base: '5', md: '14' }}
            align="start"
            flexDir={{ base: 'column-reverse', md: 'row' }}
          >
            <Box w="full" mx="auto">
              {isPostLoaded ? (
                post.body && <HtmlContent content={post.body} />
              ) : (
                <Flex direction="column" gap="2">
                  <SkeletonList>
                    <Skeleton h="20px" />
                  </SkeletonList>
                </Flex>
              )}
            </Box>
            <Box
              w="full"
              mx="auto"
              top="calc(var(--header-height) + 20px)"
              position={{ md: 'sticky' }}
            >
              {isPostLoaded ? (
                post.mainImage && (
                  <Image
                    draggable="false"
                    src={`${imageBuilder.image(
                      post.mainImage,
                    )}?q=80&auto=format&w=1200`}
                    mx="auto"
                    rounded="xl"
                    alt={post.title}
                    fallback={<ImageFallback type="post" rounded="xl" />}
                  />
                )
              ) : (
                <ImageFallback type="post" rounded="xl" maxH="400px" />
              )}
            </Box>
          </Flex>
        </Box>

        {accordion && (
          <Box className={styles.content}>
            <Accordion {...accordion} />
          </Box>
        )}

        {isPostLoaded && randomPosts.length > 0 && (
          <Box borderTop="1px solid" borderColor="truffle-border">
            <Flex
              p="5"
              w="full"
              maxW="6xl"
              mx="auto"
              flexGrow="1"
              gap="7"
              flexDir="column"
            >
              <PreviewSlider
                titleLink="/promotions"
                isLoaded={isLoaded}
                icon={<PromotionsIcon color="candy-floss-text" />}
                title={t('promotions.more-promotions')}
                slides={randomPosts.map(item => ({
                  ...item,
                  prefix: '/promotions',
                }))}
                SlideComponent={BlogPreview}
                gridSize="1-3"
              />
            </Flex>
          </Box>
        )}
      </Content>
    </>
  );
};
