import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { CustomInput } from 'forms/custom-input';
import { clean } from 'helpers/bad-words';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { validateField } from 'services/auth/modules/auth/api';
import { Errors, FormSubmit } from 'types';
import * as Yup from 'yup';

type Props = {
  username?: string;
  setStep?: (step: number) => void;
  setParams: (params: { username: string }) => void;
};

export const Step3: React.FC<Props> = ({ setStep, setParams, username }) => {
  const { t } = useTranslation();
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      username: username || '',
    },
    validationSchema: Yup.object({
      username: Yup.string()
        .min(6, 'errors.username.min')
        .max(14, 'errors.username.max')
        .required('errors.username.required')
        .test('spaces', 'errors.spaces', val => !val.includes(' '))
        .test('bad-words', 'errors.username.not-allowed', val => {
          // Replace underscores with spaces before checking
          const preprocessedVal = val.replace(/[_]+/g, ' ');
          return preprocessedVal === clean(preprocessedVal);
        }),
    }),
    onSubmit: async (params, { setErrors }) => {
      try {
        await validateField('username', params.username);
        setParams(params);
        setStep?.(4);
      } catch (errors) {
        setErrors({
          username: (errors as Errors).global || (errors as Errors).username,
        });
      }
    },
  });

  const navigate = useNavigate();

  return (
    <FormControl as="form" onSubmit={formik.handleSubmit as FormSubmit}>
      <Box w="full" pos="relative">
        <Flex
          flexDirection="column"
          justifyContent="start"
          w="full"
          bg="toffee-base"
          mx="auto"
        >
          <Heading fontSize="2xl" mb="2">
            {t('signup.step4.title')}
          </Heading>
          <Text fontSize="lg" color="candy-floss-text" maxW="md" mb="5">
            {t('signup.step4.desc')}
          </Text>
          <Flex
            w="full"
            maxW="md"
            gap="5"
            mb="5"
            justifyContent="space-between"
            align="center"
          >
            <CustomInput
              placeholder={t('form.label.username')}
              name="username"
              type="text"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.username}
              isInvalid={!!formik.errors.username}
              errorMessage={formik.errors.username}
            />
          </Flex>
          <Flex align="center" gap="2">
            <Button onClick={() => navigate(-1)} rounded="full">
              {t('actions.back')}
            </Button>
            <Button
              type="submit"
              colorScheme="purple"
              isLoading={formik.isSubmitting}
            >
              {t('actions.continue')}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </FormControl>
  );
};
