import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { CheckIcon } from 'icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { KYC_STATUSES, getUserKYCFlags } from 'services/user/modules/user';
import { Link } from 'uikit/link';

export const ProfileVerify = () => {
  const { t } = useTranslation();
  const kycFlags = useSelector(getUserKYCFlags);

  const kycRequired = kycFlags === KYC_STATUSES.REQUIRED;
  const kycPending = kycFlags === KYC_STATUSES.PENDING;
  const kycVerified = kycFlags === KYC_STATUSES.VERIFIED;
  const kycDenied = kycFlags === KYC_STATUSES.DENIED;
  const kycDuplicated = kycFlags === KYC_STATUSES.DUPLICATED;

  return (
    <Box flexDirection="column" gap={{ base: '3', md: '5' }}>
      <Box bg="sugar-dust" p="5" rounded="xl">
        <Flex gap="2" align="center" mb="5">
          <Text fontWeight="500">{t('settings.kyc')}</Text>
          {kycRequired && (
            <Flex
              justifyContent="center"
              alignItems="center"
              whiteSpace="nowrap"
              flexShrink="0"
              display="inline-flex"
              align="center"
              gap="1"
              zIndex="1"
              bg="sherbet-orange.100"
              color="sherbet-orange.200"
              borderRadius="4px"
              px="1.5"
              minW="20px"
              maxW="full"
              h="22px"
              rounded="4px"
              fontWeight="500"
              fontSize="sm"
            >
              {t('profile.required')}
            </Flex>
          )}
          {kycPending && (
            <Flex
              justifyContent="center"
              alignItems="center"
              whiteSpace="nowrap"
              flexShrink="0"
              display="inline-flex"
              align="center"
              gap="1"
              zIndex="1"
              bg="sherbet-orange.100"
              color="sherbet-orange.200"
              borderRadius="4px"
              px="1.5"
              minW="20px"
              maxW="full"
              h="22px"
              rounded="4px"
              fontWeight="500"
              fontSize="sm"
            >
              {t('profile.pending')}
            </Flex>
          )}
          {(kycDenied || kycDuplicated) && (
            <Flex
              justifyContent="center"
              alignItems="center"
              whiteSpace="nowrap"
              flexShrink="0"
              display="inline-flex"
              align="center"
              gap="1"
              zIndex="1"
              bg="sherbet-red"
              borderRadius="4px"
              px="1.5"
              minW="20px"
              maxW="full"
              h="22px"
              rounded="4px"
              fontWeight="500"
              fontSize="sm"
            >
              {t('profile.denied')}
            </Flex>
          )}
          {kycVerified && <CheckIcon color="sherbet-green.200" />}
        </Flex>
        <Flex direction="column" align="flex-start">
          <Flex
            align="start"
            w={{ base: 'full', md: 'auto' }}
            flexDir="column"
            gap="3"
          >
            <Button
              as={Link}
              w={{ base: 'full', md: 'auto' }}
              href="/verification"
              pointerEvents={kycVerified ? 'none' : undefined}
              colorScheme={kycDenied ? undefined : 'purple'}
              isDisabled={kycVerified}
            >
              {kycDenied
                ? t('profile.retry-verification')
                : t('profile.start-verification')}
            </Button>
            <Text color="candy-floss-text">
              {t('profile.verify-description')}
            </Text>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};
