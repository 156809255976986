import { Box, Button, Flex } from '@chakra-ui/react';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ProfileAffiliates } from 'services/user/pages/profile-affiliates';

export const Affiliates = () => {
  const { t } = useTranslation();
  const [tabIndex, setTabIndex] = useState(0);
  const tabs = [
    {
      children: t('profile.affiliates.overview'),
    },

    {
      children: t('profile.affiliates.referred-users'),
    },
    {
      children: t('profile.affiliates.earnings'),
    },
  ];

  let type: 'general' | 'referred' | 'earnings' = 'general';

  if (tabIndex === 1) {
    type = 'referred';
  } else if (tabIndex === 2) {
    type = 'earnings';
  } else if (tabIndex === 3) {
    type = 'general';
  }

  return (
    <>
      <Flex
        gap="2"
        align="center"
        overflow="auto"
        zIndex="2"
        maxW="6xl"
        mx="auto"
        width="100%"
        px="5"
        pt="5"
        pb="2"
      >
        {tabs.map(({ children }, index) => {
          const isActive = tabIndex === index;
          return (
            <Button
              colorScheme="custom"
              border="1px solid"
              color={isActive ? 'sherbet-purple' : 'candy-floss-text'}
              borderColor={isActive ? 'sherbet-purple' : 'truffle-border'}
              rounded="full"
              _hover={isActive ? {} : { borderColor: 'truffle-border-hover' }}
              bg="toffee-base"
              flexShrink="0"
              gap="2"
              draggable="false"
              onClick={() => setTabIndex(index)}
              key={index}
            >
              {children}
            </Button>
          );
        })}
      </Flex>
      <Box maxW="6xl" mx="auto" width="100%" px="5">
        <ProfileAffiliates type={type} />
      </Box>
    </>
  );
};
