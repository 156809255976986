import { useGoogleOneTapLogin } from '@react-oauth/google';
import { useToastError } from 'hooks/use-toast-error';
import { MODAL_TYPE, useModal } from 'modals';
import { googleAuth } from 'services/auth/modules/auth';
import { useActions } from 'store';
import { Errors } from 'types';

export const GoogleTap = () => {
  const actions = useActions({ googleAuth });
  const { onOpen } = useModal(MODAL_TYPE.AUTH_GOOGLE);
  const { onOpen: onOpenLogin } = useModal(MODAL_TYPE.AUTH);
  const toastError = useToastError();

  useGoogleOneTapLogin({
    onSuccess: async ({ credential }) => {
      try {
        const data = await actions.googleAuth({
          idToken: credential,
        });

        if (data.requireDisplayName) {
          onOpen({ accessToken: data.accessToken });
        } else if (data.requireLoginWith2FA) {
          onOpenLogin({ identityCookie: data.identityCookie });
        }
      } catch (errors) {
        toastError(errors as Errors);
      }
    },
    onError: () => {
      // console.log('Login Failed');
    },
  });

  return null;
};
