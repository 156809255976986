import { createIcon } from '@chakra-ui/icons';

export const SherbetLogoIcon = createIcon({
  displayName: 'SherbetLogoIcon',
  viewBox: '0 0 184 42',
  path: (
    <>
      <path
        d="M158.292 19.2559C158.628 19.1477 158.821 18.7991 158.749 18.4505C158.628 17.9336 158.448 17.1763 158.196 16.3949C156.044 9.79536 150.298 3.9291 141.787 6.63383C133.637 9.21835 131.858 17.4528 133.817 23.4753C134.033 24.1244 134.478 25.3626 134.851 26.2281C134.995 26.5527 135.356 26.721 135.692 26.6007L158.292 19.2559ZM143.71 12.5362C148.146 11.0936 149.709 15.6256 149.709 15.6256L140.405 18.6549C140.393 18.6428 139.263 13.9667 143.71 12.5362Z"
        fill="#fcf8f9"
      />
      <path
        d="M183.247 10.8413L176.371 9.87959L177.453 2.2222C177.501 1.84955 177.249 1.50094 176.876 1.45285L170.433 0.551276C170.06 0.503192 169.711 0.755632 169.663 1.12828L168.581 8.78568L161.777 7.824C161.405 7.77591 161.056 8.02835 161.008 8.401L160.335 13.1974C160.287 13.57 160.539 13.9187 160.912 13.9667L167.716 14.9284L166.634 22.5858C166.586 22.9585 166.838 23.3071 167.211 23.3552L173.654 24.2567C174.027 24.3048 174.376 24.0524 174.424 23.6797L175.506 16.0223L182.382 16.984C182.754 17.0321 183.103 16.7797 183.151 16.407L183.824 11.6106C183.884 11.238 183.62 10.9014 183.247 10.8413Z"
        fill="#fcf8f9"
      />
      <path
        d="M99.1123 8.76186C99.1123 8.38921 98.8118 8.07666 98.4271 8.07666H97.7179C91.479 8.07666 89.1589 12.849 89.1589 12.849V9.36291C89.1589 8.99026 88.8584 8.67771 88.4737 8.67771H81.9704C81.5977 8.67771 81.2852 8.97824 81.2852 9.36291V33.8858C81.2852 34.2585 81.5857 34.571 81.9704 34.571H88.4858C88.8584 34.571 89.171 34.2705 89.171 33.8858C89.171 31.0969 89.171 20.7348 89.171 20.7348C89.171 17.3569 91.4309 15.4936 94.2558 15.4936H98.4512C98.8238 15.4936 99.1364 15.1931 99.1364 14.8084L99.1123 8.76186Z"
        fill="#fcf8f9"
      />
      <path
        d="M65.4536 8.07666C56.9067 8.07666 52.6753 15.2893 52.6753 21.6244C52.6753 28.837 57.4116 35.1721 66.1869 35.1721C72.2575 35.1721 75.1787 32.1187 76.2966 30.8445C76.5611 30.556 76.5491 30.0992 76.2605 29.8227L73.063 26.6011C72.7865 26.3246 72.3417 26.3005 72.0412 26.565C71.0795 27.3824 68.8676 28.7648 66.2591 28.7648C61.679 28.7648 60.489 24.6537 60.489 24.6537H77.3665C77.7391 24.6537 78.0517 24.3531 78.0517 23.9685C78.0517 23.4155 78.0517 22.6702 78.0517 22.4177C78.0517 15.4936 74.6858 8.07666 65.4536 8.07666ZM60.4409 19.0759C60.4409 19.0759 60.8135 14.2795 65.4777 14.2795C70.1418 14.2795 70.226 19.0759 70.226 19.0759H60.4409Z"
        fill="#fcf8f9"
      />
      <path
        d="M8.37732 15.686C8.37732 14.7243 9.53134 14.0391 11.1902 14.0391C13.318 14.0391 15.5539 14.9888 16.6237 15.5057C16.9724 15.674 17.4051 15.5177 17.5494 15.157L19.4367 10.7333C19.5809 10.3847 19.4246 9.988 19.0881 9.84375C17.8138 9.27876 14.6643 8.07666 10.7455 8.07666C4.45846 8.07666 0.551631 11.3945 0.551631 15.7701C0.551631 23.6679 13.342 23.139 13.342 27.0218C13.342 28.1037 12.224 29.0894 10.1564 29.0894C7.58393 29.0894 4.50655 27.4546 3.2203 26.7092C2.88371 26.5169 2.45095 26.6371 2.27064 26.9857L0.0828119 31.157C-0.0854825 31.4696 0.0106843 31.8422 0.299189 32.0466C1.6215 32.9361 5.3961 35.1841 9.86793 35.1841C17.0204 35.1841 21.1917 31.4816 21.1917 27.0218C21.1797 18.5951 8.37732 18.6672 8.37732 15.686Z"
        fill="#fcf8f9"
      />
      <path
        d="M157.991 42.0001C150.911 42.0001 144.251 38.6342 140.044 32.9603C139.815 32.6478 139.887 32.215 140.2 31.9866L144.275 29.1496C144.576 28.9453 144.984 29.0054 145.213 29.3059C148.23 33.2969 152.954 35.665 157.979 35.665C163.004 35.665 167.728 33.2969 170.745 29.3059C170.962 29.0174 171.382 28.9453 171.683 29.1496L175.758 31.9866C176.071 32.203 176.155 32.6478 175.914 32.9603C171.731 38.6342 165.071 42.0001 157.991 42.0001Z"
        fill="#fcf8f9"
      />
      <path
        d="M118.935 8.07638C112.6 8.07638 109.823 12.6804 109.823 12.6804V1.14026C109.823 0.671438 109.366 0.346867 108.921 0.49112L102.406 2.60682C102.13 2.70299 101.937 2.95543 101.937 3.25596V33.8855C101.937 34.2582 102.238 34.5707 102.622 34.5707H109.138C109.511 34.5707 109.823 34.2702 109.823 33.8855V30.724C109.823 30.724 112.456 35.1718 118.466 35.1718C121.604 35.1718 124.537 33.8374 126.737 31.4332C128.997 28.9449 130.235 25.5309 130.235 21.8164C130.235 13.9907 125.378 8.07638 118.935 8.07638ZM116.038 28.7886C112.552 28.7886 109.715 25.579 109.715 21.6361C109.715 17.6932 112.552 14.4836 116.038 14.4836C119.524 14.4836 122.361 17.6932 122.361 21.6361C122.361 25.579 119.524 28.7886 116.038 28.7886Z"
        fill="#fcf8f9"
      />
      <path
        d="M40.4619 8.07664C34.4994 8.07664 31.9029 12.837 31.9029 12.837V0.683708C31.9029 0.214888 31.4461 -0.109675 31.0013 0.0345771L24.4859 2.15027C24.2094 2.24644 24.0171 2.49889 24.0171 2.79941V33.8978C24.0171 34.2705 24.3176 34.583 24.7023 34.583H31.2177C31.5903 34.583 31.9029 34.2825 31.9029 33.8978V21.1435C31.9029 17.1525 34.4874 14.4959 37.1561 14.4959C40.8345 14.4959 41.5798 17.7536 41.5798 20.3261V33.8978C41.5798 34.2705 41.8803 34.583 42.265 34.583H48.7564C49.129 34.583 49.4416 34.2825 49.4416 33.8978V18.547C49.4416 11.8753 45.5107 8.07664 40.4619 8.07664Z"
        fill="#fcf8f9"
      />
    </>
  ),
});
