import { createIcon } from '@chakra-ui/icons';

export const FriendRequestedIcon = createIcon({
  displayName: 'FriendRequestedIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="M6 7.02c.796 0 1.559-.31 2.121-.86A2.909 2.909 0 0 0 9 4.082c0-.78-.316-1.527-.879-2.078A3.032 3.032 0 0 0 6 1.143c-.796 0-1.559.31-2.121.86A2.909 2.909 0 0 0 3 4.083c0 .779.316 1.527.879 2.078.562.55 1.325.86 2.121.86Zm0 1.96c1.591 0 3.117.619 4.243 1.721a5.862 5.862 0 0 1 1.413 2.196c.376 1.039-.551 1.96-1.656 1.96H2c-1.105 0-2.032-.921-1.656-1.96a5.861 5.861 0 0 1 1.413-2.196A6.064 6.064 0 0 1 6 8.98Zm9.87-3.817-2.425 3.514-.033.046a1 1 0 0 1-.616.397 1.082 1.082 0 0 1-.746-.111.977.977 0 0 1-.268-.223L10.215 6.9A.633.633 0 0 1 10 6.431c0-.176.077-.344.215-.469a.776.776 0 0 1 .52-.194.78.78 0 0 1 .519.194l1.149.796 2.293-2.37a.793.793 0 0 1 1.007-.114.657.657 0 0 1 .287.42.612.612 0 0 1-.116.483l-.003-.014Z"
    />
  ),
});
