import { Error } from 'components/error';
import { useTranslation } from 'react-i18next';

export const BlockedSessionScreen = () => {
  const { t } = useTranslation();
  return (
    <Error
      title={t('bonus-session.during-blocked')}
      description={t('bonus-session.during-blocked-description')}
    />
  );
};
