/* eslint-disable max-lines */
import providers from './providers.json';

export const ORIGINALS_GAMES_TYPES = ['roulette', 'jackpot', 'versus'];

export const HANDLERS_ORIGIN_GAMES = {
  16: 'roulette',
  2: 'jackpot',
  3: 'jackpot',
  4: 'jackpot',
  5: 'versus',
};

export const PROVIDERS = providers;
