import { Middleware } from 'redux';

export const reduxThunkPayloadMiddleware: Middleware =
  ({ dispatch, getState }) =>
  next =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (action: any) => {
    if (typeof action === 'object' && typeof action.payload === 'function') {
      const payload = action.payload(dispatch, getState);
      return next({
        ...action,
        payload,
      });
    }

    return next(action);
  };
