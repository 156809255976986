import { createIcon } from '@chakra-ui/icons';

export const SettingsIcon = createIcon({
  displayName: 'SettingsIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="m6.358 14.948-.475-1.051a1.95 1.95 0 0 0-.815-.893 2 2 0 0 0-1.194-.25l-1.16.122a1.822 1.822 0 0 1-1.005-.184A1.787 1.787 0 0 1 .97 12a1.748 1.748 0 0 1 .102-1.926l.688-.93a1.924 1.924 0 0 0 0-2.287l-.688-.93a1.752 1.752 0 0 1 .638-2.62 1.82 1.82 0 0 1 1.004-.183l1.165.122a2 2 0 0 0 1.193-.25 1.95 1.95 0 0 0 .812-.893l.475-1.051c.141-.313.371-.58.663-.766a1.816 1.816 0 0 1 1.958 0c.292.187.522.453.663.766l.478 1.051c.168.375.453.688.812.893a2 2 0 0 0 1.194.25l1.16-.122a1.82 1.82 0 0 1 1.005.183c.31.156.567.396.74.693a1.749 1.749 0 0 1-.102 1.926l-.688.93A1.924 1.924 0 0 0 13.864 8c-.002.412.129.814.374 1.148l.687.93a1.752 1.752 0 0 1 .103 1.926 1.78 1.78 0 0 1-.74.693 1.82 1.82 0 0 1-1.005.183l-1.16-.122a2 2 0 0 0-1.194.25 1.951 1.951 0 0 0-.812.889l-.475 1.051a1.777 1.777 0 0 1-.663.766 1.816 1.816 0 0 1-1.958 0 1.777 1.777 0 0 1-.663-.766ZM10.182 8a2.182 2.182 0 1 1-4.364 0 2.182 2.182 0 0 1 4.364 0Z"
      clipRule="evenodd"
    />
  ),
});
