import { Skeleton as ChakraSkeleton, SkeletonProps } from '@chakra-ui/react';

export const Skeleton: React.FC<SkeletonProps> = props => (
  <ChakraSkeleton
    startColor="sugar-dust"
    endColor="sugar-dust"
    {...props}
    {...props}
  />
);
