import { TypedObject } from '@portabletext/types';
import uniq from 'lodash.uniq';
import { createReducer } from 'store';
import {
  addFavorites,
  loadFavorites,
  loadGameSettings,
  loadGames,
  loadPopular,
  loadPopularWithFriends,
  loadRecentGames,
  loadRecommendationsSimilar,
  loadRecommendationsUserLobby,
  loadTopGames,
  removeFavorites,
} from './actions';
import { loadRecommendationsLobby } from './api';
import { normalizeGame } from './normalizer';
import { GamePayloadType, SlotsType } from './types';

const initialState: SlotsType = {
  isLoaded: false,
  games: {
    data: {},
    ids: [],
  },
  popular: [],
  isPopularLoaded: false,
  friendsPopular: [],
  isFriendsPopularLoaded: false,
  recentGames: [],
  topGames: [],
  favorites: [],
  isFavoritesLoaded: false,
  isRecentLoaded: false,
  isTopLoaded: false,
  liveCasinoIds: [],
  sherbetOriginalsIds: [],
  featureBuyInIds: [],
  megawaysIds: [],
  slotsIds: [],
  tableIds: [],
  gameShowsIds: [],
  dropsAndWinsIds: [],
  newReleaseIds: [],
  comingSoonIds: [],
  categories: [],
  gamesCountByProvider: {},
  playlists: {
    'pragmatic-picks': {
      name: 'Pragmatic Picks',
      author: 'Sherbet',
      ids: [
        'pragmaticexternal:BuffaloKingMegaways1',
        'pragmaticexternal:ChilliHeat',
        'pgp_floatingdragonholdspinmegaways',
        'pgp_bigbassfloatsmyboat',
        'pgp_fireportals',
        'pgp_thedoghouse-dogoralive',
        'pgp_bigbasssecretsofthegoldenlake',
        'pragmaticexternal:WildSpells',
        'pragmaticexternal:MustangGold',
        'pragmaticexternal:WildHopDrop',
        'pragmaticexternal:JungleGorilla1',
        'pragmaticexternal:GreedyWolf',
      ],
    },
    'ryhs-regulars': {
      name: 'Ryhs Regulars',
      author: 'Ryhs',
      ids: [
        'pgp_bigbassmissionfishin',
        'netent:steamtower_not_mobile_sw',
        'netent:koiprincess_not_mobile_sw',
        'relax:ChaosCrew',
        'relax:WantedDeadoraWild',
        'relax:ChaosCrewII96',
        'booming:BuffaloHoldandWin',
        'evolution:lightning_roulette',
        'nolimit:FireInTheHolexBomb',
      ],
    },
    'sit-back-and-relax': {
      name: 'Sit Back & Relax',
      author: 'Sherbet',
      ids: [
        'softswiss:LuckyBlue',
        'hsg_sleepygrandpa',
        'softswiss:LuckyLadyMoon',
        'relax:StackEm',
        'netent:koiprincess_not_mobile_sw',
        'pragmaticexternal:CountryFarming',
        'oak_littlefarm',
        'pragmaticexternal:GemsBonanza1',
      ],
    },
    'wild-wild-west': {
      name: 'Wild Wild West',
      author: 'Sherbet',
      ids: [
        'relax:WantedDeadoraWild',
        'pragmaticexternal:WildWestGold',
        'relax:MoneyTrain',
        'pgs_wildbountyshowdown',
        'wazdan:WildGuns',
        'playngo:WildTrigger',
        'pragmaticexternal:WildWestDuels',
        'relax:MoneyTrain4',
        'pragmaticexternal:CowboyCoins',
        'relax:MoneyTrain2',
      ],
    },
    'monday-megaways': {
      name: 'Monday Megaways',
      author: 'Sherbet',
      ids: [
        'pragmaticexternal:BuffaloKingMegaways1',
        'pragmaticexternal:5LionsMegaways1',
        'pragmaticexternal:MadameDestinyMegaways1',
        'pgp_floatingdragonholdspinmegaways',
        'pgp_bewarethedeepmegaways',
        'pragmaticexternal:CurseoftheWerewolfMegaways',
        'pgp_barnyardmegahaysmegaways',
        'bgm_bookofpandamegaways',
        'pgp_buffalokinguntamedmegaways',
        'oak_buddhamegaways',
        'bpt_templeoftreasuresmegaways',
        'softswiss:BookOfCatsMegaways',
        'relax:GreatPigsbyMegaways',
        'pragmaticexternal:TheDogHouseMegaways1',
        'pragmaticexternal:BigBassBonanzaMegaways',
        'booming:TheRodfatherMegaways',
        'pragmaticexternal:MuertosMultiplierMegaways',
        'softswiss:LuckyLadyMoonMegaways',
        'booming:MegahopsMegaways',
        'pragmaticexternal:PowerofMerlinMegaways',
        'netent:wishmasteroct_not_mobile_sw',
        'pragmaticexternal:BigBassHoldSpinnerMegaways',
      ],
    },
    'table-game-tuesday': {
      name: 'Table Game Tuesday',
      author: 'Sherbet',
      ids: [
        // Add game IDs for Tuesday
      ],
    },
    'hump-day-hits': {
      name: 'Hump Day Hits',
      author: 'Sherbet',
      ids: [
        // Add game IDs for Wednesday
      ],
    },
    'throwback-thursday': {
      name: 'Throwback Thursday',
      author: 'Sherbet',
      ids: [
        'booming:BurningClassics',
        'pragmaticexternal:GatesofValhalla',
        'redtiger:HotHotChilliPot',
        'relax:MegaFlip',
        'redtiger:MultiplierRiches',
        'pragmaticexternal:TreeofRiches',
        'pragmaticexternal:TripleTigers',
        'wazdan:VegasReelsII',
        'wazdan:VegasHot81',
        'playngo:MysteryJoker',
      ],
    },
    'new-release-friday': {
      name: 'New Release Friday',
      author: 'Sherbet',
      ids: [
        // Add game IDs for Friday
      ],
    },
    'saturday-caturday': {
      name: 'Saturday = Caturday',
      author: 'Sherbet',
      ids: [
        'playngo:BigWinCat',
        'softswiss:BookOfCats',
        'psh_djcat',
        'bpt_lucky8fortunecat',
        'pgp_thecatfather',
        'playngo:CatWildeandtheLostChapter',
        'thunderkick:FortuneCatsGoldenStacks',
        'playngo:CatWildeandtheDoomofDead',
        'pragmaticexternal:Cleocatra',
        'png_catwildeandtheincanquest',
        'playngo:CatWildeandthePyramidsofDead',
        'softswiss:BookOfCatsMegaways',
      ],
    },
    'sunday-scaries': {
      name: 'Sunday Scaries',
      author: 'Sherbet',
      ids: [
        'relax:HorrorHotel',
        'spinomenal:BookofHorror',
        'pgs_zombieoutbreak',
        'relax:ZombieCircus',
        'pragmaticexternal:ZombieCarnival',
        'png_oasisofdead',
        'pragmaticexternal:DayofDead',
        'playngo:CatWildeandtheDoomofDead',
        'redtiger:MonstersUnchained',
        'playngo:ThreeClownMonty',
      ],
    },
    'community-made': {
      name: 'Community Made',
      author: 'Sherbet',
      ids: [
        'pushgaming:retrotapes-01',
        'pragmaticexternal:BookofGoldenSands',
        'relax:CursedSeas96',
        'pragmaticexternal:SugarSupremePowernudge',
        'relax:MoneyTrain',
        'nlc_punkrocker2',
        'relax:XmasDrop96',
        'relax:ChaosCrew',
        'pgp_sweetbonanza1000',
        'relax:RoninStackways96',
        'pgp_buffalokinguntamedmegaways',
        'softswiss:AlienFruits',
        'relax:ChaosCrewII96',
        'relax:MayanStackways96',
        'hsg_slayersinc',
        'playngo:CandyIslandPrincess',
        'pragmaticexternal:GatesOfOlympus1',
      ],
    },
  },
  recommendationsLobby: { ids: [], isLoaded: false },
  recommendationsUserLobby: { ids: [], isLoaded: false },
  recommendationsSimilar: { ids: [], isLoaded: false },
  gameSettings: {},
  gameSettingsIsLoaded: false,
};

const prioritizedSlotsIds = [
  'pgp_sweetbonanza1000',
  'relax:WantedDeadoraWild',
  'pragmaticexternal:GatesOfOlympus1',
  'pgp_sugarrush1000',
  'relax:LeBandit96',
  'pragmaticexternal:ZeusvsHadesGodsofWar',
  'pgp_fireportals',
  'pragmaticexternal:SweetBonanza',
  'pragmaticexternal:GatesofOlympus1000',
  'relax:RIPCity96',
];

const prioritizedLiveCasinoIds = [
  'evolution:blackjack',
  'evolution:roulette',
  'bombaylive:BombayLiveBaccarat',
  'bombaylive:BombayLiveBlackjack',
  'evolution:dragon_tiger',
  'ezg_andarbahar',
];

const prioritizedGameShowsIds = [
  'evolution:crazytime',
  'evo_lightningstorm',
  'evolution:lightning_roulette',
  'evo_stockmarket',
  'evo_balloonrace',
  'evolution:XXXTremeLightningRoulette',
  'evolution:monopoly',
];

export const reducer = createReducer(initialState, {
  [loadGames.toString()]: (
    state,
    { payload }: { payload: { games: { [key: string]: GamePayloadType } } },
  ) => {
    state.isLoaded = true;
    const liveCasinoIds: string[] = [];
    const sherbetOriginalsIds: string[] = [];
    const featureBuyInIds: string[] = [];
    const megawaysIds: string[] = [];
    const slotsIds: string[] = [];
    const tableIds: string[] = [];
    const gameShowsIds: string[] = [];
    const dropsAndWinsIds: string[] = [];
    const newReleaseIds: string[] = [];
    const comingSoonIds: string[] = [];
    const categories: { [key: string]: boolean } = {};
    const gamesCountByProvider: { [key: string]: number } = {};

    Object.entries(payload.games)
      .sort(([, a], [, b]) => (b.popularity || 0) - (a.popularity || 0))
      // .sort(
      //   ([, a], [, b]) => (Number(b.featured) || 0) - (Number(a.featured) || 0),
      // )
      .forEach(([key, value]) => {
        // if (key === 'jackpot') {
        //   return;
        // }

        const game = normalizeGame(value);

        state.games.data[key] = game;

        if (game.displayed) {
          state.games.ids.push(key);

          if (gamesCountByProvider[game.provider]) {
            gamesCountByProvider[game.provider] += 1;
          } else {
            gamesCountByProvider[game.provider] = 1;
          }

          if (game.featured) {
            if (game.categories?.includes?.('video-slots')) {
              slotsIds.push(key);
            }

            if (game.categories?.includes?.('game-shows')) {
              gameShowsIds.push(key);
            }
            if (
              game.categories?.includes?.('live-casino') &&
              !gameShowsIds.includes(key)
            ) {
              liveCasinoIds.push(key);
            }
          }

          if (game.categories?.includes?.('drops-and-wins')) {
            dropsAndWinsIds.push(key);
          }

          if (game.categories?.includes?.('table-games')) {
            tableIds.push(key);
          }

          if (
            game.categories?.includes?.('originals') &&
            game.id !== 'cookiecrash'
          ) {
            sherbetOriginalsIds.push(key);
          }

          if (
            game.categories?.includes?.('bonus-buy') &&
            !gameShowsIds.includes(key) &&
            !liveCasinoIds.includes(key)
          ) {
            featureBuyInIds.push(key);
          }
          if (
            game.categories?.includes?.('megaways') &&
            !gameShowsIds.includes(key) &&
            !liveCasinoIds.includes(key) &&
            !featureBuyInIds.includes(key)
          ) {
            megawaysIds.push(key);
          }

          if (game.newRelease) {
            newReleaseIds.push(key);
          }

          if (game.categories?.[0] && !categories[game.categories[0]]) {
            categories[game.categories[0]] = true;
          }
        } else if (
          game.releasedOn &&
          game.releasedOn > Date.now() &&
          game.providerImage
        ) {
          comingSoonIds.push(key);
        }
      });

    state.liveCasinoIds = [
      ...prioritizedLiveCasinoIds.filter(id => state.games.data[id]),
      ...liveCasinoIds.filter(id => !prioritizedLiveCasinoIds.includes(id)),
    ];
    state.sherbetOriginalsIds = sherbetOriginalsIds;
    state.featureBuyInIds = featureBuyInIds;
    state.megawaysIds = megawaysIds;
    state.slotsIds = [
      ...prioritizedSlotsIds.filter(id => state.games.data[id]),
      ...slotsIds.filter(id => !prioritizedSlotsIds.includes(id)),
    ];
    state.tableIds = tableIds;
    state.gameShowsIds = [
      ...prioritizedGameShowsIds.filter(id => state.games.data[id]),
      ...gameShowsIds.filter(id => !prioritizedGameShowsIds.includes(id)),
    ];
    state.dropsAndWinsIds = dropsAndWinsIds;
    state.newReleaseIds = newReleaseIds;
    state.categories = Object.keys(categories);
    state.games.ids = uniq(state.games.ids);
    state.gamesCountByProvider = gamesCountByProvider;
    state.comingSoonIds = comingSoonIds;
  },
  [loadRecentGames.toString()]: (state, { payload: { payload } }) => {
    if (Array.isArray(payload)) {
      state.recentGames = payload;
    }
    state.isRecentLoaded = true;
  },
  [loadTopGames.toString()]: (state, { payload: { payload } }) => {
    if (Array.isArray(payload)) {
      state.topGames = payload;
    }
    state.isTopLoaded = true;
  },
  [loadFavorites.toString()]: (state, { payload: { payload } }) => {
    if (Array.isArray(payload)) {
      state.favorites = payload;
      state.isFavoritesLoaded = true;
    }
  },
  [addFavorites.toString()]: (state, { meta: { gameType } }) => {
    state.favorites.push({ gameType });
  },
  [removeFavorites.toString()]: (state, { meta: { gameType } }) => {
    const index = state.favorites.findIndex(g => g.gameType === gameType);
    state.favorites.splice(index, 1);
  },
  [loadPopular.toString()]: (state, { payload }) => {
    state.popular = payload;
    state.isPopularLoaded = true;
  },
  [loadPopularWithFriends.toString()]: (state, { payload }) => {
    state.friendsPopular = payload;
    state.isFriendsPopularLoaded = true;
  },
  [loadRecommendationsLobby.toString()]: (state, { payload }) => {
    state.recommendationsLobby.ids = payload;
    state.recommendationsLobby.isLoaded = true;
  },
  [loadRecommendationsUserLobby.toString()]: (state, { payload }) => {
    if (state.slotsIds.length) {
      state.recommendationsUserLobby.ids = payload.filter(
        (id: string) => !state.slotsIds.includes(id),
      );
    } else {
      state.recommendationsUserLobby.ids = payload;
    }

    state.recommendationsUserLobby.isLoaded = true;
  },
  [loadRecommendationsSimilar.toString()]: (state, { payload }) => {
    if (state.slotsIds.length) {
      state.recommendationsSimilar.ids = payload.filter(
        (id: string) => !state.slotsIds.includes(id),
      );
    } else {
      state.recommendationsSimilar.ids = payload;
    }

    state.recommendationsSimilar.isLoaded = true;
  },
  [loadGameSettings.toString()]: (
    state,
    {
      payload,
    }: {
      payload: { id: string; description: { [key: string]: TypedObject } }[];
    },
  ) => {
    state.gameSettingsIsLoaded = true;
    state.gameSettings = payload.reduce(
      (acc, item) => ({ ...acc, [item.id]: item }),
      {},
    );
  },
});
