import {
  Box,
  Button,
  Flex,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
} from '@chakra-ui/react';
import { Content } from 'components/content';
import { Loading } from 'components/loading';
import { FriendsIcon, SearchIcon } from 'icons';
import throttle from 'lodash.throttle';
import {
  FriendType,
  changeChannel,
  getFriendsExistsList,
  getFriendsList,
  getFriendsRequestsList,
  loadFriends,
} from 'modules/chat';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import { FriendFeed } from 'services/user/components/friend-feed';
import { searchUsers } from 'services/user/modules/user';
import { useActions } from 'store';
import { UserType } from 'types';
import { CircleIndicator } from 'uikit/circle-indicator';
import { EmptyResults } from 'uikit/empty-results';
import { Skeleton } from 'uikit/skeleton';
import { SkeletonList } from 'uikit/skeleton-list';
import { Friend } from './components/friend';

const LIMIT = 20;

type CallbackType = () => void;

type ListProps = {
  list: FriendType[];
  handleMessage: (channel: number | undefined) => CallbackType;
};

const List: React.FC<ListProps> = ({ list, handleMessage }) => {
  const [page, setPage] = useState(1);

  const filteredList = list.slice(0, page * LIMIT);

  return (
    <InfiniteScroll
      dataLength={filteredList.length}
      next={() => setPage(prev => prev + 1)}
      hasMore={filteredList.length < list.length}
      loader={null}
      scrollableTarget="scrollableDiv"
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      {filteredList.map(
        ({
          user: { icon, name },
          channel,
          status,
          online,
          currentRoute,
          lastUpdatedAt,
        }) => (
          <Friend
            key={name}
            avatar={icon}
            online={online}
            route={currentRoute}
            username={name}
            status={status}
            onMessage={handleMessage(channel)}
            lastUpdatedAt={lastUpdatedAt}
          />
        ),
      )}
    </InfiniteScroll>
  );
};

type Props = {
  isFriendsLoaded: boolean;
  changeTab: (tab: number) => void;
};

export const FriendsList: React.FC<Props> = ({
  isFriendsLoaded,
  changeTab,
}) => {
  const [search, setSearch] = useState('');
  const [tab, setTab] = useState('friends');
  const actions = useActions({ changeChannel, searchUsers, loadFriends });
  const { t } = useTranslation();
  const friends = useSelector(getFriendsList);
  const friendsRequestsList = useSelector(getFriendsRequestsList);
  const friendsExists = useSelector(getFriendsExistsList);

  const [users, setUsers] = useState<UserType[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    actions.loadFriends();
  }, [actions]);

  const throttled = useRef(
    throttle(value => {
      if (value.length > 2) {
        actions.searchUsers(value).then((payload: UserType[]) => {
          setUsers(payload);
          setIsLoading(false);
        });
      } else {
        setUsers([]);
      }
    }, 500),
  );

  const friendsExistsList = useMemo(
    () =>
      [...friendsExists]
        .sort((a, b) => b.lastUpdatedAt - a.lastUpdatedAt)
        .sort((a, b) => Number(b.online) - Number(a.online)),
    [friendsExists],
  );

  const handleMessage = (channel: number | undefined) => () => {
    if (channel) {
      actions.changeChannel(channel);
      changeTab(0);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    throttled.current(search);
  }, [search]);

  const ref = useRef(null);
  const stickRef = useRef(null);

  if (!isFriendsLoaded) {
    return (
      <Stack spacing="2" width="full" h="full" overflow="auto" p="5">
        <SkeletonList count={5}>
          <Skeleton height="56px" colorScheme="dark" rounded="xl" />
        </SkeletonList>
      </Stack>
    );
  }

  let content;

  if (tab === 'friends') {
    if (friendsExistsList.length) {
      content = <List list={friendsExistsList} handleMessage={handleMessage} />;
    } else {
      content = (
        <Flex h="full" align="center" justifyContent="center">
          <EmptyResults
            icon={FriendsIcon}
            title="friends.empty-list"
            description="friends.propose-add"
          />
        </Flex>
      );
    }
  } else if (tab === 'requests') {
    if (friendsRequestsList.length) {
      content = (
        <List list={friendsRequestsList} handleMessage={handleMessage} />
      );
    } else {
      content = (
        <Flex h="full" align="center" justifyContent="center">
          <EmptyResults
            icon={FriendsIcon}
            title="friends.requests.not-found"
            description="friends.requests.not-found.desc"
          />
        </Flex>
      );
    }
  }

  return (
    <Flex flexDirection="column" width="full" h="full">
      <Box p="5">
        <InputGroup mb={!search ? '5' : '0'}>
          <InputLeftElement
            pointerEvents="none"
            w="auto"
            left="4"
            color="candy-floss-text"
          >
            <SearchIcon />
          </InputLeftElement>
          <Input
            bg="transparent"
            variant="secondary"
            value={search}
            onChange={e => setSearch(e.target.value)}
            placeholder={t('friends.search')}
            type="text"
            inputMode="search"
          />
        </InputGroup>
        {!search && (
          <Flex gap="2" align="center">
            <Button
              flexShrink="0"
              draggable="false"
              colorScheme="custom"
              border="1px solid"
              color={tab === 'friends' ? 'sherbet-purple' : 'candy-floss-text'}
              borderColor={
                tab === 'friends' ? 'sherbet-purple' : 'truffle-border'
              }
              rounded="full"
              _hover={
                tab === 'friends' ? {} : { borderColor: 'truffle-border-hover' }
              }
              gap="2"
              bg="toffee-base"
              onClick={() => setTab('friends')}
            >
              {t('friends.all')}
            </Button>
            <Button
              flexShrink="0"
              draggable="false"
              colorScheme="custom"
              border="1px solid"
              color={tab === 'activity' ? 'sherbet-purple' : 'candy-floss-text'}
              borderColor={
                tab === 'activity' ? 'sherbet-purple' : 'truffle-border'
              }
              rounded="full"
              _hover={
                tab === 'activity'
                  ? {}
                  : { borderColor: 'truffle-border-hover' }
              }
              gap="2"
              bg="toffee-base"
              onClick={() => setTab('activity')}
            >
              {t('friends.activity')}
            </Button>
            <Button
              flexShrink="0"
              draggable="false"
              colorScheme="custom"
              border="1px solid"
              color={tab === 'requests' ? 'sherbet-purple' : 'candy-floss-text'}
              borderColor={
                tab === 'requests' ? 'sherbet-purple' : 'truffle-border'
              }
              rounded="full"
              _hover={
                tab === 'requests'
                  ? {}
                  : { borderColor: 'truffle-border-hover' }
              }
              gap="2"
              bg="toffee-base"
              onClick={() => setTab('requests')}
            >
              {t('friends.requests')}
              {friendsRequestsList.length > 0 && (
                <CircleIndicator color="sherbet-purple" />
              )}
            </Button>
          </Flex>
        )}
      </Box>

      <Flex
        flexDirection="column"
        w="full"
        overflow="auto"
        h="full"
        py="3"
        ref={ref}
        borderTop="1px solid var(--chakra-colors-truffle-border)"
        id="scrollableDiv"
      >
        <Box ref={stickRef} pos="relative" top="0" />

        {search ? (
          <Box h="full">
            {search.length < 3 && (
              <Flex h="full" align="center" justifyContent="center">
                <Text textAlign="center" color="candy-floss-text" p="1">
                  {t('search.min3')}
                </Text>
              </Flex>
            )}
            <Flex flexDirection="column" gap="2" p="1" h="full">
              {search.length >= 3 && (
                <>
                  {isLoading && (
                    <Flex h="full" align="center" justifyContent="center">
                      <Loading pos="relative" />
                    </Flex>
                  )}
                  {!isLoading && !users.length && (
                    <Flex h="full" align="center" justifyContent="center">
                      <EmptyResults
                        icon={FriendsIcon}
                        title={t('friends.not-found')}
                        description={t('friends.not-found.desc')}
                      />
                    </Flex>
                  )}
                  {!isLoading && users.length > 0 && (
                    <List
                      list={users.map(user => {
                        const friend = friends.find(f => f.user.id === user.id);
                        if (friend) {
                          return friend;
                        }
                        return { user } as FriendType;
                      })}
                      handleMessage={handleMessage}
                    />
                  )}
                </>
              )}
            </Flex>
          </Box>
        ) : (
          <Content flexDirection="column" gap="2" key={tab} h="full">
            {tab !== 'activity' && content}
            {tab === 'activity' && <FriendFeed />}
          </Content>
        )}
      </Flex>
    </Flex>
  );
};
