import { client } from 'helpers/client';

export const loadLiveWagers = (type: string) =>
  client
    .get(`/core/handler/livewagers${type !== 'all' ? `/${type}` : ''}`)
    .then(({ data: { payload } }) => payload);

export const loadWagerTotal = () =>
  client
    .get('/core/handler/totalwagers')
    .then(({ data: { payload } }) => payload);
