/* eslint-disable react/jsx-no-useless-fragment */
import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import { AnimationContext } from 'contexts/animation-context';
import { useFormik } from 'formik';
import { CustomInput } from 'forms/custom-input';
import { FormGlobalError } from 'forms/form-global-error';
import { PasswordInput } from 'forms/password-input';
import { logAction } from 'helpers/newrelic';
import { getHandlerIsBlocked, getUserCountry } from 'modules/handler';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { GoogleAuth } from 'services/auth/components/google-auth';
import {
  loginUser,
  loginUser2fa,
  loginUser2faRecoveryCode,
} from 'services/auth/modules/auth';
import { useActions } from 'store';
import { Errors, FormSubmit } from 'types';
import { Link } from 'uikit/link';
import * as Yup from 'yup';

type Props = {
  onClose: () => void;
  identityCookie?: string;
};

export const LoginForm: React.FC<Props> = ({
  onClose,
  identityCookie: propIdentityCookie = '',
}) => {
  const { t } = useTranslation();
  const [isRequireLoginCode, setIsRequireLoginCode] =
    useState(!!propIdentityCookie);
  const [recovery, { toggle: toggleRecovery }] = useBoolean(false);
  const isBlocked = useSelector(getHandlerIsBlocked);
  const country = useSelector(getUserCountry);

  const isGoogle2FA = !!propIdentityCookie;

  const actions = useActions({
    loginUser,
    loginUser2fa,
    loginUser2faRecoveryCode,
  });

  const navigate = useNavigate();

  const url = window.sessionStorage?.getItem?.('returlUrl');
  const { setIsPageAnimation } = useContext(AnimationContext);

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      email: '',
      password: '',

      identityCookie: propIdentityCookie,
      twoFactorCode: '',
      recoveryCode: '',

      emailCode: '',
      loginCode: '',

      global: '',
    },
    validationSchema: Yup.object({
      ...(isGoogle2FA
        ? {}
        : {
            email: Yup.string()
              .email('errors.email.format')
              .required('errors.email.required'),
            password: Yup.string().required('errors.password.required'),
          }),

      identityCookie: Yup.string(),
      twoFactorCode: Yup.string(),
      recoveryCode: Yup.string(),
      loginCode: Yup.string(),
    }),
    onSubmit: async (
      {
        email,
        password,
        identityCookie,
        twoFactorCode,
        recoveryCode,
        loginCode,
      },
      { setFieldError, setFieldValue, setFieldTouched, setErrors },
    ) => {
      if (isBlocked) {
        setErrors({ global: t('errors.RESTRICTED_COUNTRY', { country }) });
        logAction('IS_BLOCKED_LOGIN', {
          email,
          country,
        });
        return;
      }
      try {
        if (identityCookie) {
          if (twoFactorCode)
            await actions.loginUser2fa({
              twoFactorCode,
              identityCookie,
            });
          else
            await actions.loginUser2faRecoveryCode({
              recoveryCode,
              identityCookie,
            });
          onClose();
        } else {
          // const token = await getToken();
          const result = await actions.loginUser({
            email,
            password,
            // token,
            ...(loginCode ? { loginCode } : {}),
          });

          if (result?.requireLoginCode) {
            setIsRequireLoginCode(true);
          } else if (result?.identityCookie) {
            setFieldValue('identityCookie', result.identityCookie);
            setFieldError('identityCookie', '');
            setFieldTouched('identityCookie', false);
          } else {
            window.sessionStorage?.removeItem?.('returlUrl');
            setIsPageAnimation(true);
            if (url) {
              navigate(url);
            } else {
              onClose();
            }
          }
        }
      } catch (errors) {
        setErrors(errors as Errors);
      }
    },
  });

  useEffect(() => {
    if (propIdentityCookie) {
      formik.setFieldValue('identityCookie', propIdentityCookie);
      formik.setFieldError('identityCookie', '');
      formik.setFieldTouched('identityCookie', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [propIdentityCookie]);

  useEffect(() => {
    formik.setFieldValue('twoFactorCode', '');
    formik.setFieldValue('recoveryCode', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recovery]);

  return (
    <Box>
      <Heading fontSize="2xl" mb="2">
        {t('auth-modal.welcome-back')}
      </Heading>
      <Text fontSize="lg" color="candy-floss-text" mb="5">
        {t('signup-form.description')}
      </Text>

      <FormControl as="form" onSubmit={formik.handleSubmit as FormSubmit}>
        <Flex direction="column" gap="3" mb="5">
          {formik.values.identityCookie ? (
            <>
              {recovery ? (
                <CustomInput
                  key="recovery"
                  label={t('auth-modal.login.field.two-factor-recovery-code')}
                  placeholder={t(
                    'auth-modal.login.field.two-factor-recovery-code',
                  )}
                  type="text"
                  name="recoveryCode"
                  autoFocus
                  required
                  autoComplete="one-time-code"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.recoveryCode}
                  isInvalid={
                    !!formik.errors.recoveryCode && formik.touched.recoveryCode
                  }
                  errorMessage={formik.errors.recoveryCode}
                />
              ) : (
                <CustomInput
                  key="twoFactorCode"
                  label="2FA Code"
                  placeholder="2FA Code"
                  type="text"
                  inputMode="numeric"
                  name="twoFactorCode"
                  autoFocus
                  required
                  autoComplete="one-time-code"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.twoFactorCode}
                  isInvalid={
                    !!formik.errors.twoFactorCode &&
                    formik.touched.twoFactorCode
                  }
                  errorMessage={formik.errors.twoFactorCode}
                  labelRightContent={
                    <Button
                      variant="link"
                      tabIndex={-1}
                      fontWeight="500"
                      colorScheme="purple"
                      onClick={toggleRecovery}
                    >
                      {t(
                        recovery
                          ? 'auth-modal.login.use-auth-code'
                          : 'auth-modal.login.use-recovery-code',
                      )}
                    </Button>
                  }
                />
              )}
            </>
          ) : (
            <>
              {isRequireLoginCode ? (
                <CustomInput
                  key="loginCode"
                  autoFocus
                  label={t('form.label.verification-code')}
                  placeholder={t('form.label.verification-code')}
                  type="text"
                  inputMode="numeric"
                  name="loginCode"
                  autoComplete="one-time-code"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.loginCode}
                  isInvalid={
                    !!formik.errors.loginCode && formik.touched.loginCode
                  }
                  errorMessage={formik.errors.loginCode}
                />
              ) : (
                <>
                  <CustomInput
                    key="email"
                    placeholder={t('form.label.email')}
                    label={t('form.label.email')}
                    type="email"
                    name="email"
                    autoFocus
                    inputMode="email"
                    autoComplete="email"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.email}
                    isInvalid={!!formik.errors.email && formik.touched.email}
                    errorMessage={formik.errors.email}
                  />
                  <PasswordInput
                    placeholder="form.label.password"
                    name="password"
                    labelRightContent={
                      <Button
                        tabIndex={-1}
                        as={Link}
                        variant="link"
                        to="?modal=auth&tab=forgotPassword"
                      >
                        {t('auth-modal.login.forgot')}
                      </Button>
                    }
                    autoComplete="current-password"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.password}
                    isInvalid={
                      !!formik.errors.password && formik.touched.password
                    }
                    errorMessage={formik.errors.password}
                  />
                </>
              )}
            </>
          )}
          <FormGlobalError fontSize="md" error={formik.errors.global} />
        </Flex>
        <Flex gap="2" align="center">
          {!isGoogle2FA && <GoogleAuth isWelcome onClose={onClose} url={url} />}
          <Box>
            <Button
              w="auto"
              type="submit"
              rounded="full"
              colorScheme="purple"
              isLoading={formik.isSubmitting}
            >
              {t('actions.continue')}
            </Button>
          </Box>
        </Flex>
      </FormControl>
    </Box>
  );
};
