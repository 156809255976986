import { createIcon } from '@chakra-ui/icons';

export const ETHIcon = createIcon({
  displayName: 'ETHIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <g clipPath="url(#a)">
        <path fill="#627EEA" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z" />
        <path
          fill="#fff"
          fillOpacity=".602"
          d="M8.249 2v4.435l3.748 1.675L8.25 2Z"
        />
        <path fill="#fff" d="M8.249 2 4.5 8.11l3.749-1.675V2Z" />
        <path
          fill="#fff"
          fillOpacity=".602"
          d="M8.249 10.984v3.014L12 8.808l-3.751 2.176Z"
        />
        <path fill="#fff" d="M8.249 13.998v-3.014L4.5 8.807l3.749 5.19Z" />
        <path
          fill="#fff"
          fillOpacity=".2"
          d="m8.249 10.287 3.748-2.177L8.25 6.436v3.85Z"
        />
        <path
          fill="#fff"
          fillOpacity=".602"
          d="m4.5 8.11 3.749 2.177V6.436L4.5 8.11Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </>
  ),
});
