import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { GET_IS_TELEGRAM } from 'constants/general';
import { applyLangToUrl } from 'helpers/lang';
import {
  BonusesIcon,
  ChatIcon,
  LobbyIcon,
  MenuIcon,
  ProfileIcon,
  SearchIcon,
} from 'icons';
import { MODAL_TYPE, useModal } from 'modals';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import {
  getUserBonusesLength,
  getUserIsAuthorized,
} from 'services/user/modules/user';
import { CircleIndicator } from 'uikit/circle-indicator';
import styles from './style.module.scss';

type Props = {
  openMenu: () => void;
  openChat: () => void;
  openFriends: () => void;
  openSearch: () => void;
  open?: string;
  closeOpen: () => void;
  setRightSiderbarActive: (s: string) => void;
  rightSidebarActive: string;
};

export const NavigationBar: React.FC<Props> = ({
  openMenu,
  openChat,
  openSearch,
  open,
  closeOpen,
  setRightSiderbarActive,
  rightSidebarActive,
}) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t, i18n } = useTranslation();
  const { onOpen: openAuthModal } = useModal(MODAL_TYPE.AUTH);
  const isAuthorized = useSelector(getUserIsAuthorized);
  const bonusesLength = useSelector(getUserBonusesLength);

  const searchObj = Object.fromEntries(searchParams);
  const isSearchOpened = searchObj.modal === 'search';
  const isAuthOpened = searchObj.modal === 'auth' && !searchObj.tab;

  const menuItems = [
    {
      icon: <MenuIcon />,
      text: t('nav-bar.menu'),
      onClick: openMenu,
      active: open === 'menu',
    },
    {
      icon: <SearchIcon />,
      text: t('nav-bar.search'),
      onClick: () => {
        if (open) {
          closeOpen();
          openSearch();
        } else if (isSearchOpened) {
          setSearchParams('');
        } else {
          openSearch();
        }
      },
      active: !open && isSearchOpened,
    },
    {
      icon: <LobbyIcon />,
      text: t('nav-bar.lobby'),
      active:
        !open &&
        !searchObj.modal &&
        (pathname.startsWith(applyLangToUrl('/casino', i18n.language)) ||
          pathname === '/'),
      onClick: () => {
        if (open) {
          closeOpen();
        }
        navigate(applyLangToUrl('/', i18n.language));
      },
    },

    {
      icon: (
        <Flex align="center" position="relative" gap="1.5">
          <BonusesIcon />
          {bonusesLength > 0 && (
            <CircleIndicator color="sherbet-red" bg="red" />
          )}
        </Flex>
      ),
      active:
        rightSidebarActive === 'bonuses' || rightSidebarActive === 'redeem',
      text: 'Bonuses',
      onClick: () => {
        setRightSiderbarActive('bonuses');
      },
    },

    isAuthorized
      ? {
          icon: <ChatIcon />,
          text: t('nav-bar.chat'),
          onClick: openChat,
          active: open === 'chat' && rightSidebarActive === '',
        }
      : {
          icon: <ProfileIcon />,
          text: t('actions.sign-in'),
          active: !open && isAuthOpened,
          onClick: () => {
            if (open) {
              closeOpen();
            }
            openAuthModal();
          },
        },
  ];

  return (
    <Flex
      className={styles.general}
      py="3"
      bg="rgb(23 24 36 / 90%)"
      backdropFilter="blur(10px)"
      borderTop="1px"
      borderColor="truffle-border"
      justify="space-between"
      align="center"
      position="fixed"
      bottom="0"
      w="full"
      zIndex="1100"
      px="5"
    >
      {menuItems.map(({ text, icon, onClick, active }) => (
        <Flex justifyContent="center" pos="relative" w="40px" key={text}>
          {active && (
            <Box
              pos="absolute"
              left="0"
              right="0"
              top="-13px"
              height="1px"
              bg="sherbet-purple"
            />
          )}
          <IconButton
            variant="unstyled"
            aria-label={text}
            onClick={() => {
              if (GET_IS_TELEGRAM()) {
                window.Telegram.WebApp.HapticFeedback.impactOccurred('light');
              }
            }}
          >
            <Flex
              direction="column"
              onClick={onClick}
              color={active ? 'sherbet-purple' : 'candy-floss-text'}
              align="center"
              gap="1.5"
              lineHeight="normal"
            >
              {icon}
              <Text fontSize="sm" fontWeight="400" color="inherit">
                {text}
              </Text>
            </Flex>
          </IconButton>
        </Flex>
      ))}
    </Flex>
  );
};
