import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Text,
} from '@chakra-ui/react';
import { Content } from 'components/content';
import { Loading } from 'components/loading';
import { useToastError } from 'hooks/use-toast-error';
import { TwitterIcon } from 'icons';
import { MODAL_TYPE, useModal } from 'modals';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router';
import { Accordion } from 'services/cms/components/html-content/components/accordion';
import { SlotsGrid } from 'services/games/components/slots-grid';
import { TournamentsSlider } from 'services/games/components/tournaments-slider';
import { getSlotsGamesList } from 'services/games/modules/slots';
import {
  TOURNAMENT_CONTENT_BY_TYPE,
  getTournamentsIsLoaded,
  getTournamentsList,
  joinTournament,
  loadTournaments,
} from 'services/games/modules/tournaments';
import { getUserIsAuthorized, getUserName } from 'services/user/modules/user';
import { useActions } from 'store';
import { Countdown } from 'uikit/countdown';
import { ImageFallback } from 'uikit/image-fallback';
import { Link } from 'uikit/link';
import { RaceTable } from '../../components/race-table';
import { Details } from './components/details';

const feType = 'DAILY';

export const DailyScramble = () => {
  const isLoaded = useSelector(getTournamentsIsLoaded);
  const actions = useActions({ loadTournaments, joinTournament });
  const tournaments = useSelector(getTournamentsList);
  const [tabIndex, setTabIndex] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const isAuthorized = useSelector(getUserIsAuthorized);
  const username = useSelector(getUserName);

  const { t } = useTranslation();

  useEffect(() => {
    actions.loadTournaments();
  }, [actions]);

  const toastError = useToastError();

  const games = useSelector(getSlotsGamesList);

  const { onOpen } = useModal(MODAL_TYPE.AUTH);

  if (!isLoaded) {
    return <Loading />;
  }

  const tournament = tournaments.find(tour => tour?.feType === feType);

  if (!tournament) {
    return <Navigate to="/" />;
  }

  const {
    entries: tournamentEntries = [],
    joined = true,
    roundEndDate,
    isOptIn,
    currentPrizePool,
    maxWinnersPerRound,
    roundId,
    rank,
    score,
  } = tournament;

  const handleJoin = async () => {
    if (!isAuthorized) {
      onOpen();
      return;
    }
    setIsLoading(true);
    try {
      await actions.joinTournament(roundId);
    } catch (error) {
      toastError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const { isMultiplier, title } = TOURNAMENT_CONTENT_BY_TYPE[feType];

  let entries = tournamentEntries;

  if (rank && rank > maxWinnersPerRound) {
    entries = entries.concat([
      { prize: '0', rank, score, user: { name: username } },
    ]);
  }

  return (
    <Content w="full">
      {tabIndex === 0 ? (
        <Content w="full">
          <Box w="full" borderBottom="1px solid" borderColor="truffle-border">
            <Flex
              flexDir="column"
              justifyContent="end"
              p="5"
              maxW="6xl"
              w="full"
              mx="auto"
              mt="auto"
              h="full"
            >
              <Flex
                justifyContent="space-between"
                gap="5"
                align={{ base: 'start', md: 'end' }}
                flexDirection={{ base: 'column', md: 'row' }}
              >
                <Box>
                  <Heading fontWeight="500" fontSize={{ base: 'xl', md: 'xl' }}>
                    {t(title, title)}
                  </Heading>
                  <Text
                    mt="1"
                    color="candy-floss-text"
                    fontSize=""
                    maxW="sm"
                    noOfLines="1"
                  >
                    {t('tournaments.resets-daily')}
                  </Text>
                </Box>
                <Flex align="center" gap="2">
                  <Button
                    colorScheme="custom"
                    rounded="full"
                    color="candy-floss-text"
                    border="1px solid"
                    borderColor="truffle-border"
                  >
                    <Countdown date={roundEndDate * 1000} />
                  </Button>
                  {isOptIn ? (
                    <Button
                      isDisabled={joined}
                      colorScheme="purple"
                      onClick={handleJoin}
                      isLoading={isLoading}
                    >
                      {joined ? t('tournaments.joined') : t('tournaments.join')}
                    </Button>
                  ) : (
                    <Button opacity="0.5" pointerEvents="none">
                      {t('tournaments.joined')}
                    </Button>
                  )}
                  <IconButton
                    as={Link}
                    href={`https://x.com/intent/tweet?text=Have%20you%20seen%20the%20@Sherbet%20${encodeURIComponent(
                      title,
                    )}%3F%0A%0A${encodeURIComponent(
                      `https://sherbet.com/tournaments/daily-scramble`,
                    )}`}
                    isExternal
                    rounded="full"
                    icon={<TwitterIcon />}
                  />
                </Flex>
              </Flex>
            </Flex>
          </Box>
          <Box p="5" maxW="6xl" w="full" mx="auto">
            <Flex
              gap={{ base: '5', md: '14' }}
              align="start"
              flexDir={{ base: 'column-reverse', md: 'row' }}
            >
              <Box w="full" mx="auto">
                <Image
                  mb="5"
                  draggable="false"
                  src="https://spinkick.imgix.net/tournaments/daily-scramble-3.png"
                  mx="auto"
                  rounded="xl"
                  alt="Weekly Race"
                  fallback={<ImageFallback mb="5" type="post" rounded="xl" />}
                />
                <Details />
              </Box>
              <Box
                w="full"
                mx="auto"
                top="calc(var(--header-height) + 20px)"
                position={{ md: 'sticky' }}
              >
                <RaceTable
                  entries={entries}
                  isMultiplier={isMultiplier}
                  currentPrizePool={currentPrizePool}
                  maxWinnersPerRound={maxWinnersPerRound}
                />
              </Box>
            </Flex>
          </Box>
        </Content>
      ) : (
        <Box p="5" maxW="6xl" w="full" mx="auto">
          <SlotsGrid games={games} />
        </Box>
      )}
      <Accordion
        title={t('tournaments.terms')}
        options={[
          'A minimum bet requirement of $0.20 applies. Bets less than $0.20 will not contribute toward the tournament.',
          'Players must place at least 20 eligible bets before their multipliers are shown on the leaderboard.',
          'Only real money wagers qualify. Bonus money balance is excluded from wagering.',
          'By opting in, the player accepts to receive emails with any winnings from this campaign.',
          "If a player is involved in any suspicious or fraudulent activity, Sherbet reserves the right to undertake a KYC process, cancel the player's bets, remove the player from the current promotion, exclude them from all future promotions, and not credit any winnings.",
          'Should Sherbet detect fraud, foul play, manipulation of the promotion rules, or abuse of the promotion, the relevant player and their related accounts will be suspended from current and future promotions.',
          "These terms and conditions are in addition to Sherbet's Terms Of Service.",
        ]}
      />
      <Box borderTop="1px solid" borderColor="truffle-border">
        <Flex
          p="5"
          w="full"
          maxW="6xl"
          mx="auto"
          flexGrow="1"
          gap="7"
          flexDir="column"
        >
          <TournamentsSlider />
        </Flex>
      </Box>
    </Content>
  );
};
