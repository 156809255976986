/* eslint-disable react/no-unescaped-entities */
import { Flex, Text } from '@chakra-ui/react';
import { t } from 'i18next';
import cookies from 'js-cookie';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getUserEmailConfirmed,
  getUserIsAuthorized,
} from 'services/user/modules/user';
import { Link } from 'uikit/link';

const VERIFICATION_EMAIL_CLOSED = 'VERIFICATION_EMAIL_CLOSED';

type Props = {
  hasRight?: boolean;
};

export const UnverifiedNotification: React.FC<Props> = () => {
  const [isOpened] = useState(!cookies.get(VERIFICATION_EMAIL_CLOSED));
  const isEmailConfirmed = useSelector(getUserEmailConfirmed);
  const isAuthorized = useSelector(getUserIsAuthorized);

  if (!isOpened || isEmailConfirmed || !isAuthorized) {
    return null;
  }

  return (
    <Flex
      w="full"
      bg="sherbet-green.100"
      px="5"
      display={{ base: 'none', lg: 'flex' }}
      h="40px"
      gap="5"
      alignItems="center"
    >
      <Flex maxW="6xl" px="5" mx="auto">
        <Text w="full" color="sherbet-green.200" noOfLines={1}>
          {t('errors.verify-email')}{' '}
          <Link
            to="/user/settings"
            textDecoration="underline"
            _hover={{ opacity: '0.9' }}
          >
            {t('errors.verify-email-resend')}
          </Link>
        </Text>
      </Flex>
    </Flex>
  );
};
