import { TriangleDownIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, Heading, IconButton, Text } from '@chakra-ui/react';
import { GridItemType, generateBreakpoints } from 'helpers/slider';
import { useLayout } from 'hooks/use-layout';
import { useCallback, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'uikit/link';
import { NotificationTip } from 'uikit/notification-tip';
import { SkeletonList } from 'uikit/skeleton-list';
import styles from './styles.module.scss';

type AdditionalProps = Record<string, unknown>;

type SlideType = {
  id?: string | number;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PreviewComponent?: React.FunctionComponent<any>;
} & AdditionalProps;

type Props = {
  isLoaded?: boolean;
  isModal?: boolean;
  hasHeader?: boolean;
  slides: SlideType[];
  title?: React.ReactNode;
  titleRightContent?: React.ReactNode;
  description?: React.ReactNode;
  icon?: React.ReactNode;
  titleLink?: string;
  titleOnClick?: () => void;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  SlideComponent: React.FunctionComponent<any>;
  isIgnoreGap?: boolean;
  hasButtons?: boolean;
  grid?: GridItemType[];
  gap?: number;
  onGameClick?: (id: string) => void;
  disabledScroll?: boolean;
  autoplay?: boolean;
  hasPreviewPadding?: boolean;
  isReverse?: boolean;
  size?: string;
  gridSize?: string;
  isBeta?: boolean; // Added this line
};

const gridSizes = {
  '1-3': [
    { pixels: 0, count: 1 },
    { pixels: 768, count: 2 },
    { pixels: 1024, count: 3 },
  ],
};

export const PreviewSlider: React.FC<Props> = ({
  isLoaded = true,
  isModal = false,
  hasHeader = true,
  slides,
  icon,
  title,
  titleLink,
  titleRightContent,
  titleOnClick,
  SlideComponent,
  isIgnoreGap = false,
  hasButtons = true,
  grid: propGrid,
  gridSize,
  gap: propGap = 1,
  onGameClick,
  disabledScroll = false,
  autoplay = false,
  hasPreviewPadding = true,
  isReverse = false,
  size = 'm',
  description,
  isBeta = false, // Added this line
}) => {
  const scrollPosition = useRef(0);
  const [hasLeftScroll, setHasLeftScroll] = useState(false);
  const [hasRightScroll, setHasRightScroll] = useState(true);
  const isScrollingRef = useRef(false);
  const containerRef = useRef<HTMLDivElement>(null);
  const { gap } = useLayout();
  const { t } = useTranslation();

  const grid = propGrid ||
    gridSizes[gridSize as keyof typeof gridSizes] || [
      { pixels: 0, count: 3 },
      { pixels: 540, count: 4 },
      { pixels: 816, count: 6 },
      { pixels: 1134, count: size === 'md' ? 7 : 7 },
    ];

  const breakpoints = generateBreakpoints(
    grid.filter(g => (isModal ? g.pixels < 974 : true)),
    isIgnoreGap || isModal ? 0 : gap,
  );

  const keys = Object.keys(breakpoints);

  const key =
    keys.reverse().find(k => Number(k) < window.innerWidth) || keys[0];

  const { slidesPerView } = breakpoints[key as keyof typeof breakpoints];

  const slidesCount = Math.ceil(slidesPerView);
  const width = `calc((100% - ${
    (slidesCount - 1) * (propGap * 4)
  }px) / ${slidesPerView})`;

  useLayoutEffect(() => {
    if (containerRef.current && !disabledScroll && hasHeader) {
      const element = containerRef.current;
      let timeoutId: ReturnType<typeof setTimeout>;

      const onScroll = () => {
        if (isScrollingRef.current) {
          clearTimeout(timeoutId);

          timeoutId = setTimeout(() => {
            // Your callback function to be executed when scrolling has finished
            isScrollingRef.current = false;
          }, 400);
          return;
        }

        if (element.scrollLeft === 0) {
          setHasLeftScroll(false);
        } else {
          setHasLeftScroll(true);
        }

        if (
          element.scrollWidth <=
          element.offsetWidth + element.scrollLeft + 5
        ) {
          setHasRightScroll(false);
        } else {
          setHasRightScroll(true);
        }

        scrollPosition.current = element.scrollLeft;
      };

      element.addEventListener('scroll', onScroll, {
        passive: true,
      });

      return () => {
        if (element) {
          element.removeEventListener('scroll', onScroll);
        }
      };
    }

    return undefined;
  }, [disabledScroll, hasHeader]);

  const handlePrev = useCallback(() => {
    const element = containerRef.current;
    if (element) {
      isScrollingRef.current = true;
      scrollPosition.current =
        element.scrollLeft - (element.offsetWidth + propGap * 4);

      if (scrollPosition.current <= 0) {
        setHasLeftScroll(false);
      } else {
        setHasLeftScroll(true);
      }

      if (
        element.scrollWidth <=
        element.offsetWidth + scrollPosition.current + 5
      ) {
        setHasRightScroll(false);
      } else {
        setHasRightScroll(true);
      }
      element.scrollLeft = scrollPosition.current;
    }
  }, [propGap]);

  const handleNext = useCallback(() => {
    const element = containerRef.current;
    if (element) {
      isScrollingRef.current = true;
      scrollPosition.current =
        element.scrollLeft + element.offsetWidth + propGap * 4;

      if (scrollPosition.current <= 0) {
        setHasLeftScroll(false);
      } else {
        setHasLeftScroll(true);
      }

      if (
        element.scrollWidth <=
        element.offsetWidth + scrollPosition.current + 5
      ) {
        setHasRightScroll(false);
      } else {
        setHasRightScroll(true);
      }

      element.scrollLeft = scrollPosition.current;
    }
  }, [propGap]);

  const hasRightDisabled = slides.length < slidesCount || !hasRightScroll;

  useLayoutEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }
    if (isReverse) {
      containerRef.current.scrollLeft = containerRef.current.scrollWidth;
    }

    if (autoplay) {
      const interval = setInterval(() => {
        if (!containerRef.current) {
          return;
        }
        if (isReverse) {
          if (containerRef.current.scrollLeft === 0) {
            containerRef.current.scrollLeft = containerRef.current.scrollWidth;
          } else {
            handlePrev();
          }
        } else {
          // eslint-disable-next-line no-lonely-if
          if (
            containerRef.current.scrollWidth <=
            containerRef.current.offsetWidth +
              containerRef.current.scrollLeft +
              5
          ) {
            containerRef.current.scrollLeft = 0;
          } else {
            handleNext();
          }
        }
      }, 15000);

      return () => clearInterval(interval);
    }

    return undefined;
  }, [autoplay, isReverse, handlePrev, handleNext]);

  let content;

  if (!isLoaded) {
    content = (
      <SkeletonList count={slidesCount}>
        <SlideComponent
          isSkeleton
          style={{
            width,
            flexShrink: 0,
          }}
        />
      </SkeletonList>
    );
  } else {
    content = slides
      // .slice(step * slidesCount, step * slidesCount + slidesCount)
      .map((slide, index) => {
        const SlideComponentToRender = slide.PreviewComponent || SlideComponent;
        return (
          <SlideComponentToRender
            {...slide}
            onGameClick={onGameClick}
            slidesPerView={slidesPerView}
            isLast={index === slides.length - 1}
            isFirst={index === 0}
            key={slide.id || index}
            index={index}
            style={{
              width,
              flexShrink: 0,
            }}
          />
        );
      });
  }

  let linkProps = {};

  if (titleLink) {
    linkProps = {
      as: Link,
      to: titleLink,
      _hover: { opacity: 0.9 },
    };
  }

  return (
    <Box>
      {hasHeader && (
        <Flex
          justify="space-between"
          align={{ base: 'center', md: 'end' }}
          mb={description ? '5' : '3'}
          gap="5"
        >
          <Box w="full">
            <Flex justifyContent="space-between" alignItems="center">
              <Flex gap="5">
                <Flex align="center" gap="2">
                  {icon}
                  <Heading
                    lineHeight="32px"
                    noOfLines={1}
                    fontWeight="500"
                    fontSize="xl"
                    {...linkProps}
                  >
                    {title}
                  </Heading>
                  {isBeta && (
                    <NotificationTip
                      bg="sherbet-green.200"
                      color="toffee-base"
                      notifications="Beta"
                    />
                  )}
                </Flex>
              </Flex>
              {titleRightContent}
            </Flex>
            {description && <Text color="candy-floss-text">{description}</Text>}
          </Box>
          <Flex gap="5" align="center">
            {titleLink && (
              <Button variant="link" as={Link} to={titleLink} size="sm">
                {t('actions.browse-all')}
              </Button>
            )}
            {titleOnClick && (
              <Button
                variant="link"
                size="sm"
                onClick={titleOnClick}
                display={{ base: 'none', md: 'flex' }}
              >
                {t('actions.browse-all')}
              </Button>
            )}
            {hasButtons && (
              <Flex
                gap="2"
                align="center"
                display={{ base: 'none', md: 'flex' }}
              >
                <IconButton
                  rounded="full"
                  aria-label="Left"
                  size="sm"
                  onClick={handlePrev}
                  isDisabled={!hasLeftScroll}
                >
                  <TriangleDownIcon transform="rotate(90deg)" />
                </IconButton>
                <IconButton
                  rounded="full"
                  aria-label="Right"
                  size="sm"
                  onClick={handleNext}
                  isDisabled={hasRightDisabled}
                >
                  <TriangleDownIcon transform="rotate(-90deg)" />
                </IconButton>
              </Flex>
            )}
          </Flex>
        </Flex>
      )}
      <Box
        overflow={disabledScroll ? 'hidden' : 'auto'}
        m={hasPreviewPadding ? '-4px' : undefined}
        className={styles.container}
        ref={containerRef}
      >
        <Flex gap={propGap}>{content}</Flex>
      </Box>
    </Box>
  );
};
