export const convertToOriginal = (urlText: string) => {
  let originalText = urlText.replace(/-/g, ' ');

  originalText = originalText.replace(/\b\w/g, match => match.toUpperCase());

  return originalText;
};

export const convertToUrl = (text: string) => {
  const lowercaseText = text.toLowerCase();

  let urlText = lowercaseText.replace(/[^a-z0-9]+/g, '-');

  urlText = urlText.replace(/^-+|-+$/g, '');

  return urlText;
};
