import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { Box, Flex, IconButton, Text } from '@chakra-ui/react';
import { BlogIcon } from 'icons';
import { useState } from 'react';
import styles from '../../style.module.scss';

export const Accordion = ({ title, options }) => {
  const [isOpened, setIsOpened] = useState(false);

  return (
    <Box
      className={styles.content}
      borderTop="1px solid"
      borderColor="truffle-border"
      onClick={() => setIsOpened(prev => !prev)}
      _hover={{ opacity: '0.9' }}
      cursor="pointer"
    >
      <Box maxW="6xl" mx="auto" px="5">
        <Box my="5">
          <Flex align="center" gap="5" justifyContent="space-between">
            <Flex gap="2" align="center">
              <BlogIcon color="candy-floss-text" />
              <Text as="h2" m="0 !important">
                {title}
              </Text>
            </Flex>
            <IconButton
              size="sm"
              rounded="full"
              icon={
                isOpened ? (
                  <TriangleDownIcon color="vanilla-text" />
                ) : (
                  <TriangleUpIcon transform="rotate(90deg)" />
                )
              }
            />
          </Flex>
          {isOpened && (
            <Box mt="3">
              <ul>
                {options.map((text, index) => (
                  <li key={index}>{text}</li>
                ))}
              </ul>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};
