import { PreviewSlider } from 'components/preview-slider';
import { GridItemType } from 'helpers/slider';
import { useMemo } from 'react';
import { GameType, UserType } from 'types';
import { SlotPreview } from 'uikit/slot-preview';

type Props = {
  isLoaded?: boolean;
  isModal?: boolean;
  meta?: object; // Ensure meta is included
  slides: ({
    countdown?: number;
    link?: string;
    users?: UserType[];
    meta?: object; // Ensure meta is included
  } & GameType)[];
  title?: React.ReactNode;
  icon?: React.ReactNode;
  image?: string;
  titleLink?: string;
  description?: string;
  grid?: GridItemType[];
  onGameClick?: (id: string) => void;
  gridSize?: string;
};

export const GameSlider: React.FC<Props> = ({
  isLoaded = true,
  isModal = false,
  slides,
  title: propTitle,
  icon,
  titleLink,
  description,
  grid,
  onGameClick,
  gridSize,
}) => {
  const list = useMemo(
    () =>
      slides.map(
        ({
          id,
          image,
          title,
          provider,
          bg,
          countdown,
          link,
          users,
          releasedOn,
          meta, // Ensure meta is included
        }) => ({
          id,
          image,
          title,
          alt: title,
          provider,
          link,
          bg,
          countdown,
          meta, // Ensure meta is included
          users,
          releasedOn,
        }),
      ),
    [slides],
  );

  if (!list.length && isLoaded) {
    return null;
  }

  return (
    <PreviewSlider
      icon={icon}
      title={propTitle}
      titleLink={titleLink}
      description={description}
      SlideComponent={SlotPreview}
      isModal={isModal}
      isLoaded={isLoaded}
      slides={list}
      grid={grid}
      onGameClick={onGameClick}
      gridSize={gridSize}
    />
  );
};
