import { Box, Flex } from '@chakra-ui/react';
import { Content } from 'components/content';
import { PreviewGrid } from 'components/preview-grid';
import { ShowMore } from 'components/show-more';
import { IS_PRERENDER } from 'constants/general';
import { useMobile } from 'hooks/use-mobile';
import { QuestsIcon } from 'icons';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { QuestPreview } from 'services/games/components/quest-preview';
import {
  getQuests,
  getQuestsIsLoaded,
  loadQuests,
} from 'services/games/modules/quests';
import {
  getSlotsGamesData,
  getSlotsIsLoaded,
} from 'services/games/modules/slots';
import { useActions } from 'store';
import { EmptyResults } from 'uikit/empty-results';

export const Quests = () => {
  const [search] = useState('');
  const [page, setPage] = useState(1);
  const isLoaded = useSelector(getQuestsIsLoaded);
  const isSlotsLoaded = useSelector(getSlotsIsLoaded);
  const quests = useSelector(getQuests);
  const games = useSelector(getSlotsGamesData);
  const actions = useActions({ loadQuests });
  const isMobile = useMobile();

  let limit = 28;

  if (IS_PRERENDER) {
    limit = 100;
  } else if (isMobile) {
    limit = 14;
  }

  const list = useMemo(() => {
    let items = quests.filter(quest => !quest.winner);
    if (search) {
      items = items.filter(c =>
        games[c.subGameType]?.title
          ?.toLocaleLowerCase()
          .includes(search.toLocaleLowerCase()),
      );
    }
    return items.slice(0, page * limit);
  }, [quests, search, games, page, limit]);

  useEffect(() => {
    if (!isLoaded) {
      actions.loadQuests();
    }
  }, [actions, isLoaded]);

  const isPageLoaded = isSlotsLoaded && isLoaded;

  const handleShowMore = () => {
    setPage(prev => prev + 1);
  };

  return (
    <Content>
      <Flex
        flexDirection="column"
        gap="7"
        maxW="6xl"
        mx="auto"
        flexGrow="1"
        w="full"
        p="5"
      >
        <Box>
          <PreviewGrid
            Item={QuestPreview}
            isLoaded={isPageLoaded}
            list={list}
          />
          {isPageLoaded && list.length > 0 && (
            <ShowMore
              current={list.length}
              total={quests.filter(quest => !quest.winner).length}
              onClick={handleShowMore}
            />
          )}
        </Box>
        {isPageLoaded && !list.length && <EmptyResults icon={QuestsIcon} />}
      </Flex>
    </Content>
  );
};
