import {
  Button,
  CircularProgress,
  Flex,
  IconButton,
  Spinner,
} from '@chakra-ui/react';
import { applyLangToUrl } from 'helpers/lang';
import { formatCredit } from 'helpers/numeral';
import {
  ChatIcon,
  PreloaderIcon,
  ProfileIcon,
  RakebackIcon,
  SherbetLogoIcon,
  WalletIcon,
} from 'icons';
import { MODAL_TYPE, useModal } from 'modals';
import { changeRightSidebarTab, getRightSidebarTab } from 'modules/app';
import { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { matchPath, useLocation, useSearchParams } from 'react-router-dom';
import { ORIGINALS_GAMES_TYPES } from 'services/games/modules/slots';
import {
  LEVELS,
  getUserAvatar,
  getUserBalance,
  getUserBonusesLength,
  getUserDepositStatus,
  getUserHasMax,
  getUserIsAuthorized,
  getUserLevelInfo,
  getUserName,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { Link } from 'uikit/link';
import { UserAvatar } from 'uikit/user-avatar';

type Props = {
  isMobile: boolean;
  hasRight: boolean;
  toggleRightMenu: () => void;
  setRightSiderbarActive: (i: string) => void;
};

export const Header: React.FC<Props> = memo(
  ({ isMobile, hasRight, toggleRightMenu, setRightSiderbarActive }) => {
    const isMax = useSelector(getUserHasMax);
    const openBonusesModal = () => {
      setRightSiderbarActive('bonuses');
    };
    const openDepositModal = () => {
      setRightSiderbarActive('withdrawal');
    };
    const { onOpen: loginModalOpen } = useModal(MODAL_TYPE.AUTH, {
      tab: 'login',
    });
    const { onOpen: registrationModalOpen } = useModal(MODAL_TYPE.AUTH, {
      tab: 'registration',
    });
    const location = useLocation();
    const isAuthorizedState = useSelector(getUserIsAuthorized);
    const balance = useSelector(getUserBalance);
    const { i18n, t } = useTranslation();
    const username = useSelector(getUserName);
    const avatar = useSelector(getUserAvatar);
    const [searchParams] = useSearchParams();
    const { modal, step, tab } = Object.fromEntries(searchParams);
    const chatTab = useSelector(getRightSidebarTab);
    const levelInfo = useSelector(getUserLevelInfo);
    const depositStatus = useSelector(getUserDepositStatus);
    const bonusesLength = useSelector(getUserBonusesLength);
    const isRegisterModal = modal === 'auth' && tab === 'registration';
    const isAuthorized = !isRegisterModal && isAuthorizedState;

    const actions = useActions({
      changeRightSidebarTab,
    });

    const DEFAULT_MIN_PROGRESS = 1;
    const DEFAULT_MAX_PROGRESS = 100;
    const DEFAULT_STARTING_PROGRESS = 10000;

    const min = levelInfo
      ? Math.max(
          Number(levelInfo.levelEndExp) - Number(levelInfo.levelExp),
          DEFAULT_MIN_PROGRESS,
        )
      : DEFAULT_MIN_PROGRESS;
    const max = levelInfo
      ? Number(levelInfo.levelEndExp)
      : DEFAULT_MAX_PROGRESS;
    const value = levelInfo
      ? Math.max(Number(levelInfo.exp), DEFAULT_STARTING_PROGRESS)
      : DEFAULT_STARTING_PROGRESS;

    const result = matchPath(
      { path: applyLangToUrl('/casino/game/:gameId', i18n.language) },
      location.pathname,
    );
    const gameId = result?.params?.gameId;
    const isPlaying =
      !isMobile && gameId && !ORIGINALS_GAMES_TYPES.includes(gameId);

    const chatButton = (
      <IconButton
        icon={<ChatIcon />}
        variant="link"
        minW="none"
        rounded="full"
        onClick={() => {
          if (!hasRight) {
            toggleRightMenu();
            actions.changeRightSidebarTab(0);
          } else if (chatTab === 1) {
            actions.changeRightSidebarTab(0);
          } else {
            setRightSiderbarActive('');
          }
        }}
        aria-label="Chat"
        color="candy-floss-text"
      />
    );

    return (
      <Flex
        flexDir="column"
        position="sticky"
        top="0"
        bg="rgb(23 24 36 / 90%)"
        backdropFilter="blur(10px)"
        borderBottom="1px"
        borderColor="truffle-border"
        w="full"
        zIndex="1001"
      >
        <Flex
          bg="sugar-dust"
          py="3"
          display={{ base: 'none', lg: 'flex' }}
          pr={!isMobile && hasRight ? 'var(--right-sidebar-width)' : '0'}
          transition="padding-right 300ms ease-out"
          pos="relative"
        >
          <Flex
            w="full"
            maxW="6xl"
            px="5"
            mx={isMobile ? '0' : 'auto'}
            align="center"
            justifyContent="space-between"
          >
            <Flex align="center" gap="5">
              {/* {isAuthorized && (
                <Button
                  variant="link"
                  onClick={() => {
                    setRightSiderbarActive('redeem');
                  }}
                  aria-label="Redeem"
                >
                  {t('redeem.submit')}
                </Button>
              )} */}
              {/* {!isMax && (
                <Button
                  as={Link}
                  variant="link"
                  to="/max"
                  aria-label={t('max.sherbet')}
                >
                  {t('max.sherbet')}
                </Button>
              )} */}
              <Button
                as={Link}
                variant="link"
                to="https://t.me/SherbetCasinoBot"
                aria-label={t('sidebar.promotions')}
              >
                Telegram
              </Button>
            </Flex>
            <Flex align="center" gap="5">
              {!isAuthorized && (
                <Button
                  variant="link"
                  onClick={() => {
                    loginModalOpen();
                  }}
                  aria-label={t('actions.sign-in')}
                >
                  {t('actions.sign-in')}
                </Button>
              )}
            </Flex>
          </Flex>
          {isAuthorized && (
            <Flex pos="absolute" right="5" align="center" top="0" bottom="0">
              {chatButton}
            </Flex>
          )}
        </Flex>
        <Flex
          h="calc(var(--header-height-main) - 1px)"
          pr={!isMobile && hasRight ? 'var(--right-sidebar-width)' : '0'}
          transition="padding-right 300ms ease-out"
        >
          <Flex
            flexGrow="1"
            flexBasis="0"
            w="full"
            maxW="6xl"
            px="5"
            mx={isMobile ? '0' : 'auto'}
            align="center"
            justifyContent="space-between"
          >
            <Flex flexGrow="1" flexBasis="0" align="center">
              {!isMobile ? (
                <Button
                  as={Link}
                  to="/"
                  h="32px"
                  variant="unstyled"
                  _hover={{
                    opacity: '0.9',
                  }}
                  aria-label="Home"
                  onClick={() => {
                    // Clear search and return to lobby
                    if (typeof window !== 'undefined') {
                      window.dispatchEvent(
                        new CustomEvent('clearCasinoSearch'),
                      );
                    }
                  }}
                >
                  <SherbetLogoIcon color="vanilla-text" h="32px" w="auto" />
                </Button>
              ) : (
                <Button
                  as={Link}
                  to="/"
                  variant="unstyled"
                  _hover={{
                    opacity: '0.9',
                  }}
                  aria-label="Home"
                  onClick={() => {
                    if (typeof window !== 'undefined') {
                      window.dispatchEvent(
                        new CustomEvent('clearCasinoSearch'),
                      );
                    }
                  }}
                >
                  <PreloaderIcon color="vanilla-text" h="40px" w="auto" />
                </Button>
              )}
            </Flex>
            <Flex flexGrow="1" flexBasis="0" justifyContent="end" gap="2">
              {isAuthorized && (
                <Flex align="center" gap="2">
                  <Button
                    rounded="full"
                    leftIcon={
                      depositStatus ? (
                        <Spinner
                          color="candy-floss-text"
                          width="16px"
                          height="16px"
                        />
                      ) : (
                        <RakebackIcon />
                      )
                    }
                    onClick={openDepositModal}
                    color={isPlaying ? 'candy-floss-text' : undefined}
                  >
                    {isPlaying && !modal
                      ? t('header.playing')
                      : `$${formatCredit(balance)}`}{' '}
                  </Button>
                  {!isMobile ? (
                    <Button
                      leftIcon={<WalletIcon />}
                      colorScheme="purple"
                      flexShrink="0"
                      onClick={openDepositModal}
                      rounded="full"
                    >
                      {t('wallet.cashier')}
                    </Button>
                  ) : (
                    <IconButton
                      colorScheme="purple"
                      icon={<WalletIcon />}
                      onClick={openDepositModal}
                      aria-label={t('wallet.cashier')}
                      rounded="full"
                    />
                  )}
                  {/* {!isMobile && (
                    <Box position="relative">
                      {bonusesLength > 0 && (
                        <CircleIndicator
                          color="sherbet-red"
                          position="absolute"
                          right="0"
                          bg="red"
                        />
                      )}
                      <IconButton
                        bg="sherbet-orange.100"
                        color="sherbet-orange.200"
                        _hover={{ opacity: 0.9 }}
                        _active={{ opacity: 0.9 }}
                        icon={<BonusesIcon />}
                        onClick={() => {
                          openBonusesModal();
                        }}
                        aria-label="Bonuses"
                        rounded="full"
                      />
                    </Box>
                  )} */}
                </Flex>
              )}
              {/* {!isAuthorized && tab !== 'registration' && (
                <Flex align="center" gap="2">
                  <GoogleAuth isWelcome />
                  <Button
                    colorScheme="purple"
                    rounded="full"
                    onClick={() => {
                      registrationModalOpen();
                    }}
                    aria-label={t('actions.sign-up')}
                  >
                    {t('actions.sign-up')}
                  </Button>
                </Flex>
              )} */}
              {isRegisterModal && (
                <CircularProgress
                  pointerEvents="none"
                  trackColor="truffle-border"
                  color="sherbet-purple"
                  size="40px"
                  min={1}
                  max={5}
                  value={Number(step || 1 + 1)}
                />
              )}
              {isAuthorized && (
                <Flex align="center" justifyContent="center">
                  <Link
                    to={`?modal=profile&username=${username}`}
                    maxW="full"
                    isTextOutline
                    aria-label="Profile"
                    pos="relative"
                    w="40px"
                    bg="sugar-dust"
                    _hover={{ opacity: '0.9' }}
                    rounded="full"
                    h="40px"
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    overflow="hidden"
                  >
                    <CircularProgress
                      pos="absolute"
                      aria-label="Level Progress"
                      pointerEvents="none"
                      trackColor="truffle-border"
                      color={
                        levelInfo?.level
                          ? LEVELS[levelInfo.level - 1]?.color
                          : 'sherbet-green.200'
                      }
                      size="46px"
                      thickness="2px"
                      min={min}
                      max={max}
                      value={value}
                    />
                    {isMax ? (
                      <UserAvatar icon={avatar} name={username} size="sm" />
                    ) : (
                      <ProfileIcon color="candy-floss-text" />
                    )}
                  </Link>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    );
  },
);
