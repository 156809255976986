import {
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  Heading,
  HStack,
  Icon,
  Image,
  Progress,
  Text,
  Tooltip,
  VStack,
} from '@chakra-ui/react';
import { Content } from 'components/content';
import { CheckIcon, InfoIcon, VIPIcon } from 'icons'; // Replace with your actual icon imports
import { useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getUserAvatar,
  getUserHasMax,
  getUserIsAuthorized,
  getUserLevel,
  getUserLevelInfo,
  getUserName,
  LEVEL_NAMES,
} from 'services/user/modules/user';

import { TriangleDownIcon } from '@chakra-ui/icons';
import { MODAL_TYPE, useModal } from 'modals'; // Add this import
import styles from './style.module.scss';

const getIconName = title => {
  const [tier, level] = title.toLowerCase().split(' ');
  if (level) {
    // For tiers like "Bronze I-III", use the first level
    return `${tier}-1.svg`;
  }
  // For tiers like "Grand Champion", use the full name
  return `${title.toLowerCase().replace(/\s+/g, '-')}.svg`;
};

const VIPTier = ({
  title,
  wager,
  benefits,
  iconColor,
  isActive,
  isCompleted,
  userAvatar,
  userName,
}) => (
  <GridItem position="relative">
    <Flex direction="column" height="100%" position="relative">
      <Flex justifyContent="start" mb="5" position="relative" zIndex="1">
        <Box
          bg="burnt-sugar"
          rounded="full"
          p="2"
          display="inline-flex"
          alignItems="center"
          justifyContent="center"
        >
          {isCompleted ? (
            <CheckIcon boxSize="5" color={iconColor} />
          ) : (
            <Image
              src={`https://spinkick.imgix.net/badges/level/${getIconName(title)}`}
              alt={title}
              rounded="full"
              boxSize={5}
            />
          )}
        </Box>
      </Flex>

      <Box
        opacity={isCompleted ? 0.5 : 1}
        bg="sugar-dust"
        p="5"
        h="full"
        borderRadius="md"
        border={isActive ? '1px solid' : 'none'}
        borderColor={isActive ? iconColor : 'none'}
      >
        <Text color={iconColor} fontWeight="500" mb="3">
          {title}
        </Text>
        <Text fontSize="xl" fontWeight="500">
          {wager}
        </Text>
        <Text color="candy-floss-text" mb="5">
          Wager Amount
        </Text>
        <VStack align="start" spacing={2}>
          {benefits
            .filter(benefit => benefit !== 'VIP Host')
            .map((benefit, index) => (
              <HStack key={index}>
                <CheckIcon color="sherbet-green.200" />
                <Text>{benefit}</Text>
              </HStack>
            ))}
        </VStack>
        {benefits.includes('VIP Host') && (
          <HStack mt="5">
            <VIPIcon color="candy-floss-text" />
            <Text>VIP Host</Text>
          </HStack>
        )}
      </Box>
    </Flex>
  </GridItem>
);

const BenefitCard = ({ icon, title, description }) => (
  <Flex bg="sugar-dust" p="5" borderRadius="xl">
    <Box>
      <Icon as={icon} boxSize="5" color="orange.400" mb="5" />
      <Heading fontSize="lg" mb={2}>
        {title}
      </Heading>
      <Text color="gray.400">{description}</Text>
    </Box>
  </Flex>
);

const contentMap = {
  'Instant Rakeback': {
    title: 'Get a percentage of your bets back.',
    description:
      "Instantly claim up to 35% of the house edge back via Sherbet's industry-leading Rakeback system. No more waiting, claim and play.",
  },
  'Weekly Bonus': {
    title: 'Start your week off right.',
    description: [
      "Once a week, receive a tailored bonus based on your prior week's activity.",
    ],
  },
  'Level-Up Bonuses': {
    title: 'Get rewarded for reaching the next level.',
    description:
      'Receive a one-time bonus for reaching a new level. Level-up bonuses start at Bronze 1.',
  },
  'Tier-Up Bonus': {
    title: 'Get rewarded for reaching the next tier.',
    description:
      'Receive personalized bonuses and promotions designed specifically for your playstyle and preferences.',
  },
  'Monthly Bonus': {
    title: 'Bonuses for you - month in, month out.',
    description:
      "Once a month, receive a tailored bonus based on your prior months' activity",
  },
  'Tailored Bonuses': {
    title: 'Bonuses tailored to your playstyle.',
    description:
      'Receive personalized bonuses and promotions designed specifically for your playstyle and preferences.',
  },
  'Lossback Bonus': {
    title: "It's never an unlucky day on Sherbet.",
    description:
      'Rough session? Jump back into play with a personalised lossback bonus - designed to give you the most bang for your buck.',
  },
  'VIP Host': {
    title: 'Your very own point of contact, available 24/7/365.',
    description:
      'Enjoy dedicated support from a personal VIP host, ensuring a tailored experience on Sherbet - no matter the time you play.',
  },
  'Invitation to Sherbet Events': {
    title: "Experience what it's like to be a VIP on Sherbet.",
    description:
      'Unlock access to exclusive VIP events we hold throughout the year.',
  },
};

export const ProfileVip = () => {
  const { t } = useTranslation();
  const level = useSelector(getUserLevel);
  const avatar = useSelector(getUserAvatar);
  const username = useSelector(getUserName);
  const isAuthorized = useSelector(getUserIsAuthorized);
  const { onOpen: registrationModalOpen } = useModal(MODAL_TYPE.AUTH, {
    tab: 'registration',
  }); // Add this line

  // Define all VIP tiers with specific benefits
  const allTiers = [
    {
      title: 'Bronze I-III',
      wager: '$2,500 - $15,000',
      iconColor: '#EF8E3D',
      benefits: [
        'Instant Rakeback',
        'Weekly Bonus',
        'Level-Up Bonuses',
        'Tier-Up Bonus',
      ],
    },
    {
      title: 'Silver I-III',
      wager: '$15,000 - $80,000',
      iconColor: '#D9D9D9',
      benefits: [
        'Instant Rakeback',
        'Weekly Bonus',
        'Level-Up Bonuses',
        'Tier-Up Bonus',
        'Monthly Bonuses',
      ],
    },
    {
      title: 'Gold I-III',
      wager: '$80,000 - $250,000',
      iconColor: '#E6CA38',
      benefits: [
        'Instant Rakeback',
        'Weekly Bonus',
        'Level-Up Bonuses',
        'Tier-Up Bonus',
        <Text as="span">
          Monthly Bonus{' '}
          <Text as="span" opacity="0.5">
            (Soon)
          </Text>
        </Text>,
      ],
    },
    {
      title: 'Platinum I-III',
      wager: '$250,000 - $1.5M',
      iconColor: '#BF85F5',
      benefits: [
        'Instant Rakeback',
        'Weekly Bonus',
        'Level-Up Bonuses',
        'Tier-Up Bonus',
        <Text as="span">
          Monthly Bonus{' '}
          <Text as="span" opacity="0.5">
            (Soon)
          </Text>
        </Text>,
        'Lossback Bonus',
      ],
    },
    {
      title: 'Diamond I-III',
      wager: '$1.5M - $12M',
      iconColor: '#68ACF5',
      benefits: [
        'Instant Rakeback',
        'Weekly Bonus',
        'Level-Up Bonuses',
        'Tier-Up Bonus',
        <Text as="span">
          Monthly Bonus{' '}
          <Text as="span" opacity="0.5">
            (Soon)
          </Text>
        </Text>,
        'Tailored Bonuses',
        'Lossback Bonus',
        'VIP Host',
      ],
    },
    {
      title: 'Champion I-III',
      wager: '$12M - $500M',
      iconColor: '#E84239',
      benefits: [
        'Instant Rakeback',
        'Weekly Bonus',
        'Level-Up Bonuses',
        'Tier-Up Bonus',
        'Monthly Bonuses',
        'Tailored Bonuses',
        'Lossback Bonus',
        'VIP Host',
      ],
    },
    {
      title: 'Grand Champion',
      wager: '$500M',
      iconColor: 'teal.400',
      benefits: [
        'Instant Rakeback',
        'Weekly Bonus',
        'Level-Up Bonuses',
        'Tier-Up Bonus',
        <Text as="span">
          Monthly Bonus{' '}
          <Text as="span" opacity="0.5">
            (Soon)
          </Text>
        </Text>,
        'Tailored Bonuses',
        'Lossback Bonus',
        'VIP Host',
        'Invitation to Sherbet Events',
      ],
    },
    {
      title: 'Master Champion',
      wager: '$1B',
      iconColor: 'red.400',
      benefits: [
        'Instant Rakeback',
        'Weekly Bonus',
        'Level-Up Bonuses',
        'Tier-Up Bonus',
        <Text as="span">
          Monthly Bonus{' '}
          <Text as="span" opacity="0.5">
            (Soon)
          </Text>
        </Text>,
        'Tailored Bonuses',
        'Lossback Bonus',
        'VIP Host',
        'Invitation to Sherbet Events',
      ],
    },
  ];

  // Function to get the current tier index based on the user's level
  const getCurrentTierIndex = level => {
    if (level <= 3) return 0; // Bronze
    if (level <= 6) return 1; // Silver
    if (level <= 9) return 2; // Gold
    if (level <= 12) return 3; // Platinum
    if (level <= 15) return 4; // Diamond
    if (level <= 18) return 5; // Champion
    if (level === 19) return 6; // Grand Champion
    return 7; // Master Champion
  };

  // Get the current tier index
  const currentTierIndex = getCurrentTierIndex(level);

  // Calculate which tiers to display (always show 4 tiers)
  const startIndex = Math.max(
    0,
    Math.min(currentTierIndex - 1, allTiers.length - 4),
  );
  const displayTiers = allTiers.slice(startIndex, startIndex + 4);

  const levelInfo = useSelector(getUserLevelInfo);
  const [active, setActive] = useState(level || 1);
  const activeRef = useRef();

  useLayoutEffect(() => {
    activeRef.current?.scrollIntoView?.({
      block: 'nearest',
      inline: 'start',
    });
  }, []);

  const nextLevel = LEVEL_NAMES[level + 1];

  const min = levelInfo ? levelInfo.levelEndExp - levelInfo.levelExp : 0;
  const max = levelInfo ? levelInfo.levelEndExp : 0;
  const value = levelInfo ? levelInfo.exp : 0;
  const isMax = useSelector(getUserHasMax);

  const [selectedItem, setSelectedItem] = useState('Instant Rakeback');

  const handleItemClick = item => {
    if (item !== selectedItem) {
      setSelectedItem(item);
    }
  };

  const selectedContent =
    contentMap[selectedItem] || contentMap['Instant Rakeback'];

  // Calculate progress percentage to the next level
  const calculateProgressPercentage = () => {
    if (!levelInfo) return 0;

    const currentExp = Number(levelInfo.exp);
    const levelStartExp = Number(levelInfo.levelExp);
    const levelEndExp = Number(levelInfo.levelEndExp);

    if (levelEndExp === levelStartExp) return 100; // Avoid division by zero

    const progress =
      ((currentExp - levelStartExp) / (levelEndExp - levelStartExp)) * 100;
    return Math.min(Math.max(progress, 0), 100); // Ensure the percentage is between 0 and 100
  };

  const progressPercentage = calculateProgressPercentage();

  return (
    <Content>
      <Flex
        py="5"
        pt="14"
        position="relative"
        bgSize="cover"
        bgPos="center"
        bgImage="../assets/images/sherbet-gradient.svg"
        _after={{
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background:
            'linear-gradient(to top, var(--chakra-colors-toffee-base) 0%, rgba(0,0,0,0) 100%)',
          pointerEvents: 'none',
        }}
      >
        <Flex
          align="center"
          flexDir="column"
          justifyContent="end"
          textAlign="center"
          zIndex="1"
          w="full"
          h="full"
        >
          <Box>
            <Heading
              fontSize={{ base: '2xl', md: '4xl' }}
              color="vanilla-text"
              mb="2"
              fontWeight="500"
            >
              An Unmatched VIP Experience
            </Heading>
            <Text
              opacity="0.75"
              fontSize={{ base: 'md', md: 'lg' }}
              mb="5"
              maxW="md"
            >
              Unlock exclusive benefits and receive instantly withdrawable
              bonuses with no strings attached
            </Text>
            {!isAuthorized && (
              <Button colorScheme="purple" onClick={registrationModalOpen}>
                Get Started
              </Button>
            )}
          </Box>
          <Box w="full" mt="7">
            <Box maxW="6xl" mx="auto" w="full" px="5" zIndex="1">
              <Flex
                flexDirection="column"
                justifyContent="end"
                bgSize="cover"
                rounded="xl"
                bg="sugar-dust"
                w="full"
                p="2"
              >
                <Box mt="auto">
                  <Flex gap="3" direction={{ base: 'column', xl: 'row' }}>
                    <Flex
                      bg="sugar-dust"
                      rounded="xl"
                      overflow="hidden"
                      w="full"
                    >
                      <Flex
                        p="5"
                        direction="column"
                        justifyContent="center"
                        w="full"
                      >
                        <Flex direction="column" justifyContent="center">
                          <Flex
                            alignItems="center"
                            gap="2"
                            justifyContent="space-between"
                          >
                            <Text color="candy-floss-text">
                              {progressPercentage.toFixed(2)}%
                            </Text>
                            <Tooltip
                              gutter="10"
                              hasArrow
                              fontSize="md"
                              rounded="md"
                              placement="top"
                              bg="gray.200"
                              fontWeight="600"
                              py="1"
                              px="2"
                              color="gray.700"
                              label="Wager $1.00 = 1 XP"
                            >
                              <InfoIcon
                                color="gray.200"
                                cursor="help"
                                opacity="0.5"
                                _hover={{ opacity: 1 }}
                              />
                            </Tooltip>
                          </Flex>
                        </Flex>
                        {!!nextLevel && (
                          <Box mt="2" w="full">
                            <Progress
                              rounded="full"
                              className={styles.progress}
                              w="full"
                              h="1.5"
                              bg="truffle-border"
                              sx={{
                                '& > div': {
                                  backgroundColor: 'sherbet-green.200',
                                },
                              }}
                              aria-label="level-progress"
                              value={progressPercentage}
                            />
                          </Box>
                        )}
                      </Flex>
                    </Flex>
                  </Flex>
                </Box>
              </Flex>
            </Box>
          </Box>
        </Flex>
      </Flex>
      <Flex
        p="5"
        w="full"
        maxW="6xl"
        mx="auto"
        flexGrow="1"
        gap="7"
        flexDir="column"
      >
        <Box>
          <Box position="relative" overflowX="auto">
            <Box
              position="absolute"
              top="16px"
              left="0"
              right="0"
              height="2px"
              bg="truffle-border"
              zIndex="0"
              minWidth="calc(250px * 4 + 0.75rem * 3)"
            />
            <Grid
              templateColumns="repeat(4, minmax(250px, 1fr))"
              templateRows="1fr"
              gap="3"
              w={{ base: 'max-content', lg: 'full' }}
              minW="100%"
            >
              {displayTiers.map((tier, index) => (
                <VIPTier
                  key={tier.title}
                  icon={CheckIcon}
                  title={tier.title}
                  wager={tier.wager}
                  benefits={tier.benefits}
                  iconColor={tier.iconColor}
                  isActive={index + startIndex === currentTierIndex}
                  isCompleted={index + startIndex < currentTierIndex}
                  userAvatar={avatar}
                  userName={username}
                />
              ))}
            </Grid>
          </Box>
        </Box>
        <Grid
          templateColumns={{ base: '1fr', md: '1fr 1fr' }}
          gap="3"
          margin="0 auto"
          w="full"
        >
          <GridItem bg="sugar-dust" p="5" rounded="xl" w="full">
            <Flex
              flexDirection="column"
              justifyContent="space-between"
              h="full"
            >
              <Box mb="5">
                <Button rounded="full">The Benefits</Button>
              </Box>
              <VStack align="stretch" spacing={2}>
                {Object.keys(contentMap).map(item => (
                  <HStack
                    key={item}
                    justify="space-between"
                    fontSize="xl"
                    pb="2"
                    borderBottom="1px solid"
                    borderColor={
                      selectedItem === item ? 'vanilla-text' : 'truffle-border'
                    }
                    onClick={() => handleItemClick(item)}
                    cursor="pointer"
                    _hover={{ opacity: 0.9 }}
                  >
                    <Text
                      fontWeight="500"
                      color={
                        selectedItem === item
                          ? 'vanilla-text'
                          : 'candy-floss-text'
                      }
                      fontSize="xl"
                    >
                      {item}
                    </Text>
                    <TriangleDownIcon
                      transform="rotate(-90deg)"
                      color={
                        selectedItem === item
                          ? 'vanilla-text'
                          : 'truffle-border'
                      }
                    />
                  </HStack>
                ))}
              </VStack>
            </Flex>
          </GridItem>

          <GridItem bg="sugar-dust" p="5" rounded="xl" w="full">
            <Flex flexDir="column" h="full" justifyContent="start">
              <Heading mb="2" fontSize="xl">
                {selectedContent.title}
              </Heading>
              {Array.isArray(selectedContent.description) ? (
                selectedContent.description.map((paragraph, index) => (
                  <Text
                    key={index}
                    color="candy-floss-text"
                    mb={index < selectedContent.description.length - 1 ? 4 : 0}
                  >
                    {paragraph}
                  </Text>
                ))
              ) : (
                <Text color="candy-floss-text">
                  {selectedContent.description}
                </Text>
              )}
            </Flex>
          </GridItem>
        </Grid>
      </Flex>
    </Content>
  );
};
