import TagManager from 'react-gtm-module';

const tagManagerArgs = {
  gtmId: 'GTM-N3ZL79B',
};

TagManager.initialize(tagManagerArgs);

export const trackEvent = (
  event: string,
  data: { [key: string]: string | number } = {},
) => {
  if (window.fbq) {
    window.fbq('track', event, data);
  }
  if (window.dataLayer) {
    window.dataLayer.push({ event, ...data });
  }
  // else {
  //   TagManager.initialize(tagManagerArgs);
  //   // @ts-ignore
  //   window.dataLayer.push({ event, ...data });
  // }
};
