import { createIcon } from '@chakra-ui/icons';

export const BonusBuyIcon = createIcon({
  displayName: 'BonusBuyIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="M14.584 15.873a.355.355 0 0 1-.235-.088l-4.177-3.657a.088.088 0 0 0-.052-.017.098.098 0 0 0-.094.072l-.946 3.17a.356.356 0 0 1-.341.254h-.002a.356.356 0 0 1-.34-.259l-.903-3.182a.098.098 0 0 0-.095-.074.088.088 0 0 0-.05.016l-4.203 3.62a.356.356 0 0 1-.541-.448l2.704-4.702a.103.103 0 0 0-.019-.077.092.092 0 0 0-.077-.041l-3.28.069a.356.356 0 0 1-.201-.65l2.711-1.853a.106.106 0 0 0 0-.171.412.412 0 0 1-.02-.016L.13 4.322a.356.356 0 0 1 .346-.61l4.803 1.714a.104.104 0 0 0 .06-.132L4.264 2.167a.356.356 0 0 1 .557-.395L7.4 3.809a.09.09 0 0 0 .057.021.097.097 0 0 0 .093-.072l.947-3.17a.356.356 0 0 1 .34-.254h.003a.356.356 0 0 1 .34.259l.903 3.182a.097.097 0 0 0 .094.074.087.087 0 0 0 .05-.016l4.204-3.62a.356.356 0 0 1 .54.448l-2.703 4.702a.104.104 0 0 0 .052.107.093.093 0 0 0 .044.011l3.28-.069a.356.356 0 0 1 .2.65l-2.71 1.853a.107.107 0 0 0-.034.134.106.106 0 0 0 .033.038l2.686 1.89a.356.356 0 0 1-.205.647l-3.282-.115a.095.095 0 0 0-.08.041.104.104 0 0 0-.02.076l2.662 4.716a.357.357 0 0 1-.31.53Z"
    />
  ),
});
