/* eslint-disable max-lines */
import { Image } from '@chakra-ui/react';
import bigInt from 'big-integer';
import {
  ChatIcon,
  PlusIcon,
  PromotionsIcon,
  RakebackBoostIcon,
  RakebackIcon,
} from 'icons';

export const LEVEL_NAMES = {
  0: 'Newbie',
  1: 'Bronze 1',
  2: 'Bronze 2',
  3: 'Bronze 3',
  4: 'Silver 1',
  5: 'Silver 2',
  6: 'Silver 3',
  7: 'Gold 1',
  8: 'Gold 2',
  9: 'Gold 3',
  10: 'Platinum 1',
  11: 'Platinum 2',
  12: 'Platinum 3',
  13: 'Diamond 1',
  14: 'Diamond 2',
  15: 'Diamond 3',
  16: 'Champion 1',
  17: 'Champion 2',
  18: 'Champion 3',
  19: 'Grand Champion',
  20: 'Master Champion',
};

export const LEVEL_IMAGES = {
  0: 'https://spinkick.imgix.net/badges/level/newbie.svg',
  1: 'https://spinkick.imgix.net/badges/level/bronze-1.svg',
  2: 'https://spinkick.imgix.net/badges/level/bronze-2.svg',
  3: 'https://spinkick.imgix.net/badges/level/bronze-3.svg',
  4: 'https://spinkick.imgix.net/badges/level/silver-1.svg',
  5: 'https://spinkick.imgix.net/badges/level/silver-2.svg',
  6: 'https://spinkick.imgix.net/badges/level/silver-3.svg',
  7: 'https://spinkick.imgix.net/badges/level/gold-1.svg',
  8: 'https://spinkick.imgix.net/badges/level/gold-2.svg',
  9: 'https://spinkick.imgix.net/badges/level/gold-3.svg',
  10: 'https://spinkick.imgix.net/badges/level/platinum-1.svg',
  11: 'https://spinkick.imgix.net/badges/level/platinum-2.svg',
  12: 'https://spinkick.imgix.net/badges/level/platinum-3.svg',
  13: 'https://spinkick.imgix.net/badges/level/diamond-1.svg',
  14: 'https://spinkick.imgix.net/badges/level/diamond-2.svg',
  15: 'https://spinkick.imgix.net/badges/level/diamond-3.svg',
  16: 'https://spinkick.imgix.net/badges/level/champion-1.svg',
  17: 'https://spinkick.imgix.net/badges/level/champion-2.svg',
  18: 'https://spinkick.imgix.net/badges/level/champion-3.svg',
  19: 'https://spinkick.imgix.net/badges/level/grand-champion.svg',
  20: 'https://spinkick.imgix.net/badges/level/master-champion.svg',
};

export const LEVELS = [
  {
    number: 1,
    name: 'Bronze 1',
    minAmount: 2000,
    color: '#A06253',
    perks: ['6% Rakeback'],
    information: [
      {
        title: 'profile.level.title.reload-sundae',
        color: '#72aced',
        icon: RakebackIcon,
        description: 'profile.level.desc.reload-sundae',
      },
      {
        title: 'profile.level.title.rakeback',
        color: '#b9d6f6',
        icon: RakebackIcon,
        description: 'profile.level.desc.rakeback',
      },
      {
        title: 'profile.level.title.onetime-bonus',
        color: '#3F9671',
        icon: PromotionsIcon,
        description: 'profile.level.desc.onetime-bonus',
      },
    ],
    data: {
      instantRakeback: true,
      reloadSundae: true,
      lvlupBonus: true,
      tierUpBonus: true,
    },
  },
  {
    number: 2,
    name: 'Bronze 2',
    minAmount: 5000,
    color: '#A06253',
    perks: ['6% Rakeback', '100% deposit bonus'],
    depositBonusAmount: 50,
    information: [
      {
        title: 'profile.level.title.reload-sundae',
        color: '#72aced',
        icon: RakebackIcon,
        description: 'profile.level.desc.reload-sundae',
      },
      {
        title: 'profile.level.title.rakeback',
        color: '#b9d6f6',
        icon: RakebackIcon,
        description: 'profile.level.desc.rakeback',
      },
      {
        title: 'profile.level.title.onetime-bonus',
        color: '#3F9671',
        icon: PromotionsIcon,
        description: 'profile.level.desc.onetime-bonus',
      },
      {
        title: 'profile.level.title.increased-rakeback',
        color: '#F866A4',
        icon: RakebackIcon,
        description: 'profile.level.desc.increased-rakeback',
      },
      {
        title: 'profile.level.title.dep-bonus',
        color: 'sherbet-purple',
        icon: PlusIcon,
        description: 'profile.level.desc.dep-bonus',
      },
    ],
    data: {
      bonusIncrease: true,
    },
  },
  {
    number: 3,
    name: 'Bronze 3',
    minAmount: 9000,
    color: '#A06253',
    perks: [
      '6% Rakeback',
      '100% deposit bonus',
      'Temporary 20% Rakeback Boost',
    ],
    depositBonusAmount: 50,
    information: [
      {
        title: 'profile.level.title.reload-sundae',
        color: '#72aced',
        icon: RakebackIcon,
        description: 'profile.level.desc.reload-sundae',
      },
      {
        title: 'profile.level.title.rakeback',
        color: '#b9d6f6',
        icon: RakebackIcon,
        description: 'profile.level.desc.rakeback',
      },
      {
        title: 'profile.level.title.onetime-bonus',
        color: '#3F9671',
        icon: PromotionsIcon,
        description: 'profile.level.desc.onetime-bonus',
      },
      {
        title: 'profile.level.title.rakeboost',
        color: 'sherbet-orange.200',
        icon: RakebackBoostIcon,
        description: 'profile.level.desc.rakeboost',
      },
      {
        title: 'profile.level.title.dep-bonus',
        color: 'sherbet-purple',
        icon: PlusIcon,
        description: 'profile.level.desc.dep-bonus',
      },
    ],
  },
  {
    number: 4,
    name: 'Silver 1',
    minAmount: 15000,
    color: '#7498A3',
    perks: ['7% Rakeback', '100% deposit bonus'],
    depositBonusAmount: 100,
    information: [
      {
        title: 'profile.level.title.reload-sundae',
        color: '#72aced',
        icon: RakebackIcon,
        description: 'profile.level.desc.reload-sundae',
      },
      {
        title: 'profile.level.title.rakeback',
        color: '#b9d6f6',
        icon: RakebackIcon,
        description: 'profile.level.desc.rakeback',
      },
      {
        title: 'profile.level.title.onetime-bonus',
        color: '#3F9671',
        icon: PromotionsIcon,
        description: 'profile.level.desc.onetime-bonus',
      },
      {
        title: 'profile.level.title.increased-rakeback',
        color: '#F866A4',
        icon: RakebackIcon,
        description: 'profile.level.desc.increased-rakeback',
      },
      {
        title: 'profile.level.title.dep-bonus',
        color: 'sherbet-purple',
        icon: PlusIcon,
        description: 'profile.level.desc.dep-bonus',
      },
    ],
  },
  {
    number: 5,
    name: 'Silver 2',
    minAmount: 25000,
    color: '#7498A3',
    perks: ['7% Rakeback', '50% deposit bonus', 'Temporary 20% Rakeback Boost'],
    depositBonusAmount: 200,
    information: [
      {
        title: 'profile.level.title.reload-sundae',
        color: '#72aced',
        icon: RakebackIcon,
        description: 'profile.level.desc.reload-sundae',
      },
      {
        title: 'profile.level.title.rakeback',
        color: '#b9d6f6',
        icon: RakebackIcon,
        description: 'profile.level.desc.rakeback',
      },
      {
        title: 'profile.level.title.onetime-bonus',
        color: '#3F9671',
        icon: PromotionsIcon,
        description: 'profile.level.desc.onetime-bonus',
      },
      {
        title: 'profile.level.title.rakeboost',
        color: 'sherbet-orange.200',
        icon: RakebackBoostIcon,
        description: 'profile.level.desc.rakeboost',
      },
      {
        title: 'profile.level.title.dep-bonus',
        color: 'sherbet-purple',
        icon: PlusIcon,
        description: 'profile.level.desc.dep-bonus',
      },
    ],
  },
  {
    number: 6,
    name: 'Silver 3',
    minAmount: 50000,
    color: '#7498A3',
    perks: ['7% Rakeback', '50% deposit bonus', 'Temporary 20% Rakeback Boost'],
    depositBonusAmount: 300,
    information: [
      {
        title: 'profile.level.title.reload-sundae',
        color: '#72aced',
        icon: RakebackIcon,
        description: 'profile.level.desc.reload-sundae',
      },
      {
        title: 'profile.level.title.rakeback',
        color: '#b9d6f6',
        icon: RakebackIcon,
        description: 'profile.level.desc.rakeback',
      },
      {
        title: 'profile.level.title.onetime-bonus',
        color: '#3F9671',
        icon: PromotionsIcon,
        description: 'profile.level.desc.onetime-bonus',
      },
      {
        title: 'profile.level.title.rakeboost',
        color: 'sherbet-orange.200',
        icon: RakebackBoostIcon,
        description: 'profile.level.desc.rakeboost',
      },
      {
        title: 'profile.level.title.dep-bonus',
        color: 'sherbet-purple',
        icon: PlusIcon,
        description: 'profile.level.desc.dep-bonus',
      },
    ],
  },
  {
    number: 7,
    name: 'Gold 1',
    minAmount: 80000,
    color: '#C19356',
    perks: ['7% Rakeback', '100% deposit bonus'],
    depositBonusAmount: 300,
    information: [
      {
        title: 'profile.level.title.reload-sundae',
        color: '#72aced',
        icon: RakebackIcon,
        description: 'profile.level.desc.reload-sundae',
      },
      {
        title: 'profile.level.title.rakeback',
        color: '#b9d6f6',
        icon: RakebackIcon,
        description: 'profile.level.desc.rakeback',
      },
      {
        title: 'profile.level.title.onetime-bonus',
        color: '#3F9671',
        icon: PromotionsIcon,
        description: 'profile.level.desc.onetime-bonus',
      },
      {
        title: 'profile.level.title.dep-bonus',
        color: 'sherbet-purple',
        icon: PlusIcon,
        description: 'profile.level.desc.dep-bonus',
      },
    ],
  },
  {
    number: 8,
    name: 'Gold 2',
    minAmount: 130000,
    color: '#C19356',
    perks: ['7% Rakeback', '150% deposit bonus'],
    depositBonusAmount: 200,
    information: [
      {
        title: 'profile.level.title.reload-sundae',
        color: '#72aced',
        icon: RakebackIcon,
        description: 'profile.level.desc.reload-sundae',
      },
      {
        title: 'profile.level.title.rakeback',
        color: '#b9d6f6',
        icon: RakebackIcon,
        description: 'profile.level.desc.rakeback',
      },
      {
        title: 'profile.level.title.onetime-bonus',
        color: '#3F9671',
        icon: PromotionsIcon,
        description: 'profile.level.desc.onetime-bonus',
      },
      {
        title: 'profile.level.title.dep-bonus',
        color: 'sherbet-purple',
        icon: PlusIcon,
        description: 'profile.level.desc.dep-bonus',
      },
    ],
  },
  {
    number: 9,
    name: 'Gold 3',
    minAmount: 190000,
    color: '#C19356',
    perks: ['7% Rakeback', '50% deposit bonus', 'Temporary 20% Rakeback Boost'],
    depositBonusAmount: 500,
    information: [
      {
        title: 'profile.level.title.reload-sundae',
        color: '#72aced',
        icon: RakebackIcon,
        description: 'profile.level.desc.reload-sundae',
      },
      {
        title: 'profile.level.title.rakeback',
        color: '#b9d6f6',
        icon: RakebackIcon,
        description: 'profile.level.desc.rakeback',
      },
      {
        title: 'profile.level.title.onetime-bonus',
        color: '#3F9671',
        icon: PromotionsIcon,
        description: 'profile.level.desc.onetime-bonus',
      },
      {
        title: 'profile.level.title.rakeboost',
        color: 'sherbet-orange.200',
        icon: RakebackBoostIcon,
        description: 'profile.level.desc.rakeboost',
      },
      {
        title: 'profile.level.title.dep-bonus',
        color: 'sherbet-purple',
        icon: PlusIcon,
        description: 'profile.level.desc.dep-bonus',
      },
    ],
  },
  {
    number: 10,
    name: 'Platinum 1',
    minAmount: 250000,
    color: '#AF61E2',
    perks: ['9% Rakeback', '100% deposit bonus'],
    depositBonusAmount: 500,
    information: [
      {
        title: 'profile.level.title.reload-sundae',
        color: '#72aced',
        icon: RakebackIcon,
        description: 'profile.level.desc.reload-sundae',
      },
      {
        title: 'profile.level.title.rakeback',
        color: '#b9d6f6',
        icon: RakebackIcon,
        description: 'profile.level.desc.rakeback',
      },
      {
        title: 'profile.level.title.onetime-bonus',
        color: '#3F9671',
        icon: PromotionsIcon,
        description: 'profile.level.desc.onetime-bonus',
      },
      {
        title: 'profile.level.title.increased-rakeback',
        color: '#F866A4',
        icon: RakebackIcon,
        description: 'profile.level.desc.increased-rakeback',
      },
      {
        title: 'profile.level.title.dep-bonus',
        color: 'sherbet-purple',
        icon: PlusIcon,
        description: 'profile.level.desc.dep-bonus',
      },
    ],
  },
  {
    number: 11,
    name: 'Platinum 2',
    minAmount: 500000,
    color: '#AF61E2',
    perks: [
      '9% Rakeback',
      '100% deposit bonus',
      'Temporary 20% Rakeback Boost',
    ],
    depositBonusAmount: 500,
    information: [
      {
        title: 'profile.level.title.reload-sundae',
        color: '#72aced',
        icon: RakebackIcon,
        description: 'profile.level.desc.reload-sundae',
      },
      {
        title: 'profile.level.title.rakeback',
        color: '#b9d6f6',
        icon: RakebackIcon,
        description: 'profile.level.desc.rakeback',
      },
      {
        title: 'profile.level.title.onetime-bonus',
        color: '#3F9671',
        icon: PromotionsIcon,
        description: 'profile.level.desc.onetime-bonus',
      },
      {
        title: 'profile.level.title.rakeboost',
        color: 'sherbet-orange.200',
        icon: RakebackBoostIcon,
        description: 'profile.level.desc.rakeboost',
      },
      {
        title: 'profile.level.title.dep-bonus',
        color: 'sherbet-purple',
        icon: PlusIcon,
        description: 'profile.level.desc.dep-bonus',
      },
    ],
  },
  {
    number: 12,
    name: 'Platinum 3',
    minAmount: 1000000,
    color: '#AF61E2',
    perks: ['9% Rakeback', '50% deposit bonus', 'Temporary 20% Rakeback Boost'],
    depositBonusAmount: 1000,
    information: [
      {
        title: 'profile.level.title.reload-sundae',
        color: '#72aced',
        icon: RakebackIcon,
        description: 'profile.level.desc.reload-sundae',
      },
      {
        title: 'profile.level.title.rakeback',
        color: '#b9d6f6',
        icon: RakebackIcon,
        description: 'profile.level.desc.rakeback',
      },
      {
        title: 'profile.level.title.onetime-bonus',
        color: '#3F9671',
        icon: PromotionsIcon,
        description: 'profile.level.desc.onetime-bonus',
      },
      {
        title: 'profile.level.title.rakeboost',
        color: 'sherbet-orange.200',
        icon: RakebackBoostIcon,
        description: 'profile.level.desc.rakeboost',
      },
      {
        title: 'profile.level.title.dep-bonus',
        color: 'sherbet-purple',
        icon: PlusIcon,
        description: 'profile.level.desc.dep-bonus',
      },
    ],
  },
  {
    number: 13,
    name: 'Diamond 1',
    minAmount: 1500000,
    color: '#28C660',
    perks: ['9% Rakeback', '50% deposit bonus', 'Temporary 20% Rakeback Boost'],
    depositBonusAmount: 500,
    information: [
      {
        title: 'profile.level.title.reload-sundae',
        color: '#72aced',
        icon: RakebackIcon,
        description: 'profile.level.desc.reload-sundae',
      },
      {
        title: 'profile.level.title.rakeback',
        color: '#b9d6f6',
        icon: RakebackIcon,
        description: 'profile.level.desc.rakeback',
      },
      {
        title: 'profile.level.title.onetime-bonus',
        color: '#3F9671',
        icon: PromotionsIcon,
        description: 'profile.level.desc.onetime-bonus',
      },
      {
        title: 'profile.level.title.rakeboost',
        color: 'sherbet-orange.200',
        icon: RakebackBoostIcon,
        description: 'profile.level.desc.rakeboost',
      },
      {
        title: 'profile.level.title.dep-bonus',
        color: 'sherbet-purple',
        icon: PlusIcon,
        description: 'profile.level.desc.dep-bonus',
      },
      {
        title: 'profile.level.title.vip-host',
        color: '#3AA6C7',
        icon: ChatIcon,
        description: 'profile.level.desc.vip-host',
      },
    ],
    data: {
      vipHost: true,
      extraBonusHost: true,
    },
  },
  {
    number: 14,
    name: 'Diamond 2',
    minAmount: 2500000,
    color: '#28C660',
    perks: [
      '9% Rakeback',
      '100% deposit bonus',
      'Temporary 20% Rakeback Boost',
    ],
    depositBonusAmount: 1000,
    information: [
      {
        title: 'profile.level.title.reload-sundae',
        color: '#72aced',
        icon: RakebackIcon,
        description: 'profile.level.desc.reload-sundae',
      },
      {
        title: 'profile.level.title.rakeback',
        color: '#b9d6f6',
        icon: RakebackIcon,
        description: 'profile.level.desc.rakeback',
      },
      {
        title: 'profile.level.title.onetime-bonus',
        color: '#3F9671',
        icon: PromotionsIcon,
        description: 'profile.level.desc.onetime-bonus',
      },
      {
        title: 'profile.level.title.rakeboost',
        color: 'sherbet-orange.200',
        icon: RakebackBoostIcon,
        description: 'profile.level.desc.rakeboost',
      },
      {
        title: 'profile.level.title.dep-bonus',
        color: 'sherbet-purple',
        icon: PlusIcon,
        description: 'profile.level.desc.dep-bonus',
      },
      {
        title: 'profile.level.title.chat-perks',
        color: '#476CF0',
        icon: ChatIcon,
        description: 'profile.level.desc.chat-perks',
      },
      {
        title: 'profile.level.title.vip-host',
        color: '#3AA6C7',
        icon: ChatIcon,
        description: 'profile.level.desc.vip-host',
      },
    ],
  },
  {
    number: 15,
    name: 'Diamond 3',
    minAmount: 5000000,
    color: '#28C660',
    perks: ['11% Rakeback', '100% deposit bonus'],
    depositBonusAmount: 1000,
    information: [
      {
        title: 'profile.level.title.reload-sundae',
        color: '#72aced',
        icon: RakebackIcon,
        description: 'profile.level.desc.reload-sundae',
      },
      {
        title: 'profile.level.title.rakeback',
        color: '#b9d6f6',
        icon: RakebackIcon,
        description: 'profile.level.desc.rakeback',
      },
      {
        title: 'profile.level.title.onetime-bonus',
        color: '#3F9671',
        icon: PromotionsIcon,
        description: 'profile.level.desc.onetime-bonus',
      },
      {
        title: 'profile.level.title.increased-rakeback',
        color: '#F866A4',
        icon: RakebackIcon,
        description: 'profile.level.desc.increased-rakeback',
      },
      {
        title: 'profile.level.title.dep-bonus',
        color: 'sherbet-purple',
        icon: PlusIcon,
        description: 'profile.level.desc.dep-bonus',
      },
      {
        title: 'profile.level.title.chat-perks',
        color: '#476CF0',
        icon: ChatIcon,
        description: 'profile.level.desc.chat-perks',
      },
      {
        title: 'profile.level.title.vip-host',
        color: '#3AA6C7',
        icon: ChatIcon,
        description: 'profile.level.desc.vip-host',
      },
    ],
  },
  {
    number: 16,
    name: 'Champion 1',
    minAmount: 12000000,
    color: '#534EFC',
    perks: ['11% Rakeback'],
    information: [
      {
        title: 'profile.level.title.reload-sundae',
        color: '#72aced',
        icon: RakebackIcon,
        description: 'profile.level.desc.reload-sundae',
      },
      {
        title: 'profile.level.title.rakeback',
        color: '#b9d6f6',
        icon: RakebackIcon,
        description: 'profile.level.desc.rakeback',
      },
      {
        title: 'profile.level.title.onetime-bonus',
        color: '#3F9671',
        icon: PromotionsIcon,
        description: 'profile.level.desc.onetime-bonus',
      },
      {
        title: 'profile.level.title.chat-perks',
        color: '#476CF0',
        icon: ChatIcon,
        description: 'profile.level.desc.chat-perks',
      },
      {
        title: 'profile.level.title.vip-host',
        color: '#3AA6C7',
        icon: ChatIcon,
        description: 'profile.level.desc.vip-host',
      },
    ],
    data: {
      sherbetEvents: true,
    },
  },
  {
    number: 17,
    name: 'Champion 2',
    minAmount: 60000000,
    color: '#534EFC',
    perks: ['11% Rakeback'],
    information: [
      {
        title: 'profile.level.title.reload-sundae',
        color: '#72aced',
        icon: RakebackIcon,
        description: 'profile.level.desc.reload-sundae',
      },
      {
        title: 'profile.level.title.rakeback',
        color: '#b9d6f6',
        icon: RakebackIcon,
        description: 'profile.level.desc.rakeback',
      },
      {
        title: 'profile.level.title.onetime-bonus',
        color: '#3F9671',
        icon: PromotionsIcon,
        description: 'profile.level.desc.onetime-bonus',
      },
      {
        title: 'profile.level.title.chat-perks',
        color: '#476CF0',
        icon: ChatIcon,
        description: 'profile.level.desc.chat-perks',
      },
      {
        title: 'profile.level.title.vip-host',
        color: '#3AA6C7',
        icon: ChatIcon,
        description: 'profile.level.desc.vip-host',
      },
    ],
  },
  {
    number: 18,
    name: 'Champion 3',
    minAmount: 120000000,
    color: '#534EFC',
    perks: ['11% Rakeback'],
    information: [
      {
        title: 'profile.level.title.reload-sundae',
        color: '#72aced',
        icon: RakebackIcon,
        description: 'profile.level.desc.reload-sundae',
      },
      {
        title: 'profile.level.title.rakeback',
        color: '#b9d6f6',
        icon: RakebackIcon,
        description: 'profile.level.desc.rakeback',
      },
      {
        title: 'profile.level.title.onetime-bonus',
        color: '#3F9671',
        icon: PromotionsIcon,
        description: 'profile.level.desc.onetime-bonus',
      },
      {
        title: 'profile.level.title.chat-perks',
        color: '#476CF0',
        icon: ChatIcon,
        description: 'profile.level.desc.chat-perks',
      },
      {
        title: 'profile.level.title.vip-host',
        color: '#3AA6C7',
        icon: ChatIcon,
        description: 'profile.level.desc.vip-host',
      },
    ],
  },
  {
    number: 19,
    name: 'Grand Champion',
    minAmount: 500000000,
    color: '#43D3FF',
    perks: ['11% Rakeback'],
    information: [
      {
        title: 'profile.level.title.reload-sundae',
        color: '#72aced',
        icon: RakebackIcon,
        description: 'profile.level.desc.reload-sundae',
      },
      {
        title: 'profile.level.title.rakeback',
        color: '#b9d6f6',
        icon: RakebackIcon,
        description: 'profile.level.desc.rakeback',
      },
      {
        title: 'profile.level.title.onetime-bonus',
        color: '#3F9671',
        icon: PromotionsIcon,
        description: 'profile.level.desc.onetime-bonus',
      },
      {
        title: 'profile.level.title.chat-perks',
        color: '#476CF0',
        icon: ChatIcon,
        description: 'profile.level.desc.chat-perks',
      },
      {
        title: 'profile.level.title.vip-host',
        color: '#3AA6C7',
        icon: ChatIcon,
        description: 'profile.level.desc.vip-host',
      },
    ],
  },
  {
    number: 20,
    name: 'Master Champion',
    minAmount: 1000000000,
    color: '#F4472A',
    perks: ['15% Rakeback'],
    information: [
      {
        title: 'profile.level.title.reload-sundae',
        color: '#72aced',
        icon: RakebackIcon,
        description: 'profile.level.desc.reload-sundae',
      },
      {
        title: 'profile.level.title.rakeback',
        color: '#b9d6f6',
        icon: RakebackIcon,
        description: 'profile.level.desc.rakeback',
      },
      {
        title: 'profile.level.title.onetime-bonus',
        color: '#3F9671',
        icon: PromotionsIcon,
        description: 'profile.level.desc.onetime-bonus',
      },
      {
        title: 'profile.level.title.increased-rakeback',
        color: '#F866A4',
        icon: RakebackIcon,
        description: 'profile.level.desc.increased-rakeback',
      },
      {
        title: 'profile.level.title.chat-perks',
        color: '#476CF0',
        icon: ChatIcon,
        description: 'profile.level.desc.chat-perks',
      },
      {
        title: 'profile.level.title.vip-host',
        color: '#3AA6C7',
        icon: ChatIcon,
        description: 'profile.level.desc.vip-host',
      },
    ],
  },
];

export const LEVELS_MAP = LEVELS.reduce(
  (acc, l) => Object.assign(acc, { [l.number]: l }),
  {},
);

export const PermissionFlags = {
  None: 0,

  BanUser: 1 << 3,
  MuteUser: 1 << 6,
  TimeoutUser: 1 << 7,

  ClearMessages: 1 << 8,

  ViewUser: 1 << 9,
  ViewUserStats: 1 << 10,
  ViewUserHistory: 1 << 11,
  ViewUserInventory: 1 << 21,

  UnMuteUser: 1 << 17,
  UnTimeoutUser: 1 << 18,

  DeleteMessages: 1 << 24,

  CreateCoupon: 1 << 25,
  EditCoupon: 1 << 26,

  SupportAgent: 1 << 28,
  PinMessages: 1 << 29,
  HandlerBanUser: bigInt('8589934592'),
  SelfExcludeUsers: bigInt('17179869184'),
};

export const PublicFlags = {
  PrivateStats: 1 << 0,
};

export const USER_ROLE_COLORS = {
  Mod: 'sherbet-orange.200',
  Staff: '#476CF0',
};

export const KYC_STATUSES = {
  REQUIRED: 1,
  PENDING: 2,
  VERIFIED: 3,
  DENIED: 4,
  DUPLICATED: 5,
};

export const CHAT_COLORS = {
  c1: '#ffd899',
  c2: '#ffc466',
  c3: '#ff9d00',
  c4: '#fbcfd8',
  c5: '#f2708a',
  c6: '#e9113c',
  c7: '#d399ff',
  c8: '#bc66ff',
  c9: '#b9d6f6',
  c10: '#72aced',
  c11: '#1475e1',
  c12: '#bafea3',
  c13: '#75fd46',
  c14: '#00ccb3',
};

export const MAX_CHAT_COLORS = {
  sc2: '#00E0FF', // Vivid Cyan
  sc3: '#F0FF00', // Bright Yellow
  sc4: '#00FF60', // Bright Green
  sc5: '#9900CC', // Dark Magenta
  sc6: '#FF9900', // Vivid Orange
  sc7: '#3366FF', // Light Bright Blue
  sc9: '#FF5050', // Coral Red
  sc10: '#20C020', // Forest Green
  sc11: '#db4a3f', // Red
};

export const MERGED_CHAT_COLORS = { ...CHAT_COLORS, ...MAX_CHAT_COLORS };

export const AFFILIATES = {
  ogusers: {
    title: 'OGUsers Members',
    banner: (
      <Image
        h="10"
        w="10"
        rounded="full"
        src="https://spinkick.imgix.net/affiliate-images/ogusers.png?auto=format&w=100"
        alt="OGUsers"
        draggable="false"
      />
    ),
  },
  bitcointalk: {
    title: 'Bitcoin Talk Members',
    banner: (
      <Image
        h="10"
        w="10"
        rounded="full"
        src="https://spinkick.imgix.net/affiliate-images/bitcointalk.png?auto=format&w=100"
        alt="Bitcoin Talk"
        draggable="false"
      />
    ),
  },
  askgamblers: {
    title: 'AskGamblers Readers',
    banner: (
      <Image
        h="10"
        rounded="full"
        src="https://spinkick.imgix.net/affiliate-images/askgamblers.svg?auto=format"
        alt="AskGamblers"
        draggable="false"
      />
    ),
  },
  karasu7: {
    title: 'Karasu Followers',
    banner: (
      <Image
        h="10"
        w="10"
        rounded="full"
        src="https://spinkick.imgix.net/affiliate-images/karasu.png?auto=format&w=100"
        alt="Karasu"
        draggable="false"
      />
    ),
  },
  jasonuwu: {
    title: 'Jasonuwu Viewers',
    banner: (
      <Image
        h="10"
        w="10"
        rounded="full"
        src="https://spinkick.imgix.net/affiliate-images/jasonuwu.png?auto=format&w=100"
        alt="Jasonuwu"
        draggable="false"
      />
    ),
  },
  soulstealer: {
    title: 'Soulstealer Viewers',
    banner: (
      <Image
        h="10"
        w="10"
        rounded="full"
        src="https://spinkick.imgix.net/affiliate-images/soulstealer.png?auto=format&w=100"
        alt="Soulstealer"
        draggable="false"
      />
    ),
  },
  conker: {
    title: 'Conker Viewers',
    banner: (
      <Image
        h="10"
        w="10"
        rounded="full"
        src="https://spinkick.imgix.net/affiliate-images/conker.png?auto=format&w=100"
        alt="Conker"
        draggable="false"
      />
    ),
  },
  romix: {
    title: 'Romix Viewers',
    banner: (
      <Image
        h="10"
        w="10"
        rounded="full"
        src="https://spinkick.imgix.net/affiliate-images/romix.png?auto=format&w=100"
        alt="Romix"
        draggable="false"
      />
    ),
  },
  rubix: {
    title: 'Rubix Viewers',
    banner: (
      <Image
        h="10"
        w="10"
        rounded="full"
        src="https://spinkick.imgix.net/affiliate-images/rubix.jpg?auto=format&w=100"
        alt="Rubix"
        draggable="false"
      />
    ),
  },
  loco: {
    title: "LOCO's Viewers",
    banner: (
      <Image
        h="10"
        w="10"
        rounded="full"
        src="https://spinkick.imgix.net/affiliate-images/loco.jpg?auto=format&w=100"
        alt="LOCO"
        draggable="false"
      />
    ),
  },
  sajad: {
    title: "Sajad's Friends",
    banner: (
      <Image
        h="10"
        w="10"
        rounded="full"
        src="https://spinkick.imgix.net/affiliate-images/sajad.png?auto=format&w=100"
        alt="Sajad"
        draggable="false"
      />
    ),
  },
  '8lowie': {
    title: "8lowie's Friends",
    banner: (
      <Image
        h="10"
        w="10"
        rounded="full"
        src="https://spinkick.imgix.net/affiliate-images/8lowie.png?auto=format&w=100"
        alt="8lowie"
        draggable="false"
      />
    ),
  },
  REYTUBE: {
    title: 'REYTUBEの仲間たち',
    banner: (
      <Image
        h="10"
        w="10"
        rounded="full"
        src="https://spinkick.imgix.net/affiliate-images/reytube.jpeg?auto=format&w=100"
        alt="REYTUBE"
        draggable="false"
      />
    ),
  },
  cryptonews: {
    title: 'Cryptonews Readers',
    banner: (
      <Image
        h="10"
        src="https://spinkick.imgix.net/affiliate-images/crypto-news.svg?auto=format"
        alt="Cryptonews"
        draggable="false"
      />
    ),
  },
  insidebitcoins: {
    title: 'Inside Bitcoins Readers',
    banner: (
      <Image
        h="10"
        src="https://spinkick.imgix.net/affiliate-images/inside-bictoins.svg?auto=format"
        alt="InsideBitcoins"
        draggable="false"
      />
    ),
  },
  techopedia: {
    title: 'Techopedia Readers',
    banner: (
      <Image
        h="10"
        src="https://spinkick.imgix.net/affiliate-images/techopedia.svg?auto=format"
        alt="Techopedia"
        draggable="false"
      />
    ),
  },
  bus2com: {
    title: 'Business 2 Community Readers',
    banner: (
      <Image
        h="10"
        src="https://spinkick.imgix.net/affiliate-images/b2c.svg?auto=format"
        alt="Business2Community"
        draggable="false"
      />
    ),
  },
  kook: {
    title: 'Kook',
    banner: (
      <Image
        h="10"
        w="10"
        rounded="full"
        src="https://spinkick.imgix.net/affiliate-images/kook.jpg?auto=format"
        alt="Kook"
        draggable="false"
      />
    ),
  },
};

const getDisplayLevels = (currentLevel: number) => {
  const allLevels = Object.keys(LEVEL_NAMES).map(Number);
  const currentIndex = allLevels.indexOf(currentLevel);

  let startIndex = Math.max(0, currentIndex - 1);
  if (startIndex + 4 > allLevels.length) {
    startIndex = Math.max(0, allLevels.length - 4);
  }

  return allLevels.slice(startIndex, startIndex + 4);
};
