import { client, parseApiErrorClear } from 'helpers/client';
import { sanityClient } from 'helpers/sanity';

export const loadGames = () =>
  client.get('/handler/slots/20').then(({ data }) => data.payload);

export const loadGame = (id: string) =>
  client
    .get(`/handler/slots/20?command=${id}`)
    .then(({ data }) => data.payload);

export const loadRecentGames = () =>
  client.post(`/core/user/games/recent`).then(({ data }) => data);

export const loadTopGames = () =>
  client.post(`/core/user/games/top`).then(({ data }) => data);

export const startSession = (params: object) =>
  client
    .post(`/handler/slots/20/session`, params)
    .then(({ data }) => data)
    .catch(parseApiErrorClear);

export const startDemoSession = (params: object) =>
  client
    .put(`/handler/slots/20/demo`, params)
    .then(({ data }) => data)
    .catch(parseApiErrorClear);

export const loadFavorites = () =>
  client.post(`/core/user/favorites`).then(({ data }) => data);

export const addFavorites = (params: object) =>
  client.post(`/core/user/favorites/add`, params).then(({ data }) => data);

export const removeFavorites = (params: object) =>
  client.post(`/core/user/favorites/remove`, params).then(({ data }) => data);

export const loadPopularWithFriends = (hours: number, count: number) =>
  client
    .post(`/chat/popular-friends`, {
      LastHour: hours,
      PlayedFriendsCount: count,
    })
    .then(({ data }) => data.payload);

export const loadPopular = (hours: number, count: number) =>
  client
    .post(`/chat/popular`, {
      LastHour: hours,
      PlayedFriendsCount: count,
    })
    .then(({ data }) => data.payload);

export const loadGameWagersStats = (gameId: string, handlerId: number = 20) =>
  client
    .get(`/core/handler/game-wager-stats/${handlerId}/${gameId}`)
    .then(({ data }) => data.payload);

export const loadRecommendationsLobby = () =>
  client
    .post('/handler/slots/20/recommendations-lobby', {})
    .then(({ data }) => data.payload);

export const loadRecommendationsUserLobby = () =>
  client
    .post('/handler/slots/20/recommendations-user-lobby', {})
    .then(({ data }) => data.payload)
    .catch(() => []);

export const loadRecommendationsSimilar = (gameId: string, clientType = 0) =>
  client
    .post('/handler/slots/20/recommendations-similar-games', {
      clientType,
      gameId,
      limit: 14,
    })
    .then(({ data }) => data.payload)
    .catch(() => []);

export const loadGameSettings = () =>
  sanityClient.fetch(
    `*[_type == "game"] {
          id,
          description

      }`,
  );
