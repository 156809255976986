export const BUTTON_SOLID_COLOR_SCHEMES = {
  base: {
    transitionProperty: 'transform',
    transitionTimingFunction: 'cubic-bezier(.4,0,.2,1)',
    transitionDuration: '.15s',
    bg: 'sugar-dust',
    rounded: 'xl',
    color: 'candy-floss-text',
    _hover: {
      opacity: '0.9',
      bg: 'sugar-dust',
    },
    _disabled: {
      opacity: '0.5',
    },
    _active: {
      opacity: '0.9',
      bg: 'sugar-dust',
    },
  },
  danger: {
    bg: 'sherbet-red',
    _hover: {
      bg: 'sherbet-red',
      opacity: '0.9',
    },
    _disabled: {
      opacity: '0.5',
    },
    _active: {
      bg: 'sherbet-red',
      opacity: '0.9',
    },
    color: 'vanilla-text',
    rounded: 'full',
  },
  purple: {
    bg: 'sherbet-purple',
    rounded: 'full',
    color: 'vanilla-text',
    _hover: {
      bg: 'sherbet-purple !important',
      opacity: '0.9',
    },
    _disabled: {
      opacity: '0.5',
    },
    _active: {
      bg: 'sherbet-purple',
      opacity: '0.9',
    },
  },
  green: {
    bg: 'sherbet-green.100',
    rounded: 'xl',
    color: 'sherbet-green.200',
    _hover: {
      bg: 'sherbet-green.100',
      color: 'sherbet-green.200',
      opacity: '0.9',
    },
    _active: {
      bg: 'sherbet-green.100',
      color: 'sherbet-green.200',
      opacity: '0.9',
    },
  },
};

type ButtonProps = {
  color: string;
  colorScheme: keyof typeof BUTTON_SOLID_COLOR_SCHEMES | 'custom';
};

export const ButtonTheme = {
  sizes: {
    sm: {
      height: '32px',
      fontSize: '14px',
      rounded: 'md',
      minWidth: '32px',
    },
    md: {
      height: '40px',
      fontSize: '14px',
      rounded: 'md',
      minWidth: '40px',
    },
  },
  baseStyle: {
    rounded: 'xl',
    fontWeight: '500',
    transitionProperty: 'transform',
    transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.2, 1)',
    transitionDuration: '.15s',
    _active: {
      transform: 'scale(0.98)',
    },
    _disabled: { opacity: '0.5', cursor: 'not-allowed', pointerEvents: 'none' },
  },
  variants: {
    solid: (props: ButtonProps) => {
      if (props.colorScheme === 'custom') {
        return {
          color: props.color ?? 'vanilla-text',
        };
      }
      return (
        BUTTON_SOLID_COLOR_SCHEMES[props.colorScheme] ||
        BUTTON_SOLID_COLOR_SCHEMES.base
      );
    },
    link: {
      color: 'candy-floss-text',
      fontWeight: '500',
      _hover: {
        color: 'candy-floss-text',
        opacity: '0.9',
      },
      _active: {
        color: 'candy-floss-text',
        transform: 'none',
      },
    },
    ghost: {
      bg: 'none',
      rounded: 'xl',
      color: 'vanilla-text',
      _hover: {
        bg: 'var(--chakra-colors-sugar-dust) !important',
      },
      _active: {
        bg: 'none',
      },
      _focus: {
        bg: 'none',
      },
    },
  },
};
