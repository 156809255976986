import { Box, Grid } from '@chakra-ui/react';
import { Loading } from 'components/loading';
import { useLayout } from 'hooks/use-layout';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  getVersusGamesList,
  getVersusGamesLoaded,
  getVersusHistory,
} from 'services/games/modules/versus';
import { Game } from './components/game';

export const GamesList = () => {
  const games = useSelector(getVersusGamesList);
  const history = useSelector(getVersusHistory);
  const [, forceUpdate] = useState({});
  const { gap } = useLayout();
  const isLoaded = useSelector(getVersusGamesLoaded);

  useEffect(() => {
    const interval = setInterval(() => {
      forceUpdate({});
    }, 20000);

    return () => clearInterval(interval);
  }, []);

  if (!isLoaded) {
    return <Loading pos="relative" my="20" mx="auto" />;
  }

  const renderGridItems = () => {
    const totalSlots = 6;
    const items = [];

    // Add existing games
    for (let i = 0; i < Math.min(games.length, totalSlots); i++) {
      items.push(<Game key={games[i].gameId} game={games[i]} />);
    }

    // Fill remaining slots with placeholders
    for (let i = games.length; i < totalSlots; i++) {
      items.push(
        <Box
          key={`placeholder-${i}`}
          bg="sugar-dust"
          borderRadius="md"
          h="224px"
          w="full"
        />,
      );
    }

    return items;
  };

  return (
    <Grid
      w="full"
      userSelect="none"
      sx={{
        [`@media screen and (min-width: ${gap + 350}px)`]: {
          gridTemplateColumns: 'repeat(1, 1fr)',
        },
        [`@media screen and (min-width: ${gap + 700}px)`]: {
          gridTemplateColumns: 'repeat(1, 1fr)',
        },
        [`@media screen and (min-width: ${gap + 1050}px)`]: {
          gridTemplateColumns: 'repeat(2, 1fr)',
        },
      }}
      columnGap="3"
      rowGap="3"
    >
      {renderGridItems()}
    </Grid>
  );
};
