import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { CustomInput } from 'forms/custom-input';
import { logAction } from 'helpers/newrelic';
import { getHandlerIsBlocked, getUserCountry } from 'modules/handler';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { GoogleAuth } from 'services/auth/components/google-auth';
import { validateField } from 'services/auth/modules/auth/api';
import { Errors, FormSubmit } from 'types';
import * as Yup from 'yup';

type Props = {
  email?: string;
  setStep: (step: number) => void;
  setParams: (params: object) => void;
};

export const Step2: React.FC<Props> = ({ setStep, setParams, email = '' }) => {
  const isBlocked = useSelector(getHandlerIsBlocked);
  const country = useSelector(getUserCountry);
  const { t } = useTranslation();
  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      email,
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('errors.email.format')
        .required('errors.email.required'),
    }),
    onSubmit: async (params, { setErrors }) => {
      if (isBlocked) {
        setErrors({ email: t('errors.RESTRICTED_COUNTRY', { country }) });
        logAction('IS_BLOCKED_REGISTER', {
          email: params.email,
          country,
        });
        return;
      }
      try {
        await validateField('email', params.email);
        // Generate a random username based on the email address
        let baseUsername = params.email.split('@')[0];
        baseUsername = baseUsername.substring(
          0,
          Math.min(4, baseUsername.length),
        ); // Limit base to 4 chars
        const randomNumber = Math.floor(Math.random() * 1000)
          .toString()
          .padStart(3, '0'); // Ensure 3 digits
        const username = baseUsername + randomNumber.substring(0, 3); // Ensure total length is 7 chars
        setParams({ ...params, username }); // Update parent state
        setStep(3); // Move to the next step
      } catch (errors) {
        setErrors({ email: (errors as Errors).global });
      }
    },
  });

  const navigate = useNavigate();

  return (
    <FormControl as="form" onSubmit={formik.handleSubmit as FormSubmit}>
      <Box w="full" pos="relative">
        <Flex
          flexDirection="column"
          justifyContent="start"
          w="full"
          bg="toffee-base"
          mx="auto"
        >
          <Heading fontSize="2xl" mb="2">
            {t('signup.step3.title')}
          </Heading>
          <Text fontSize="lg" color="candy-floss-text" maxW="md" mb="5">
            {t('signup.step3.desc')}
          </Text>
          <Flex
            w="full"
            maxW="md"
            gap="5"
            mb="5"
            justifyContent="space-between"
            align="center"
          >
            <CustomInput
              name="email"
              type="email"
              placeholder={t('form.label.email')}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.email}
              isInvalid={!!formik.errors.email}
              errorMessage={formik.errors.email}
            />
          </Flex>
          <Flex align="center" gap="2" maxW="md">
            <Button onClick={() => navigate(-1)} rounded="full">
              {t('actions.back')}
            </Button>
            <Button
              colorScheme="purple"
              type="submit"
              isLoading={formik.isSubmitting}
            >
              {t('actions.continue')}
            </Button>
            <Box>
              <GoogleAuth isWelcome />
            </Box>
          </Flex>
        </Flex>
      </Box>
    </FormControl>
  );
};
