import { Link as ChakraLink, LinkProps } from '@chakra-ui/react';
import { isUrlExternal } from 'helpers/env';
import { applyLangToUrl } from 'helpers/lang';
import { MODAL_TYPE, useModal } from 'modals';
import { forwardRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import { getUserIsAuthorized } from 'services/user/modules/user';

type Props = LinkProps & {
  onClick?: React.MouseEventHandler;
  to?: string;
  href?: string;
  isExternal?: boolean;
  children: React.ReactNode;
  allowPrevent?: boolean;
  isTextOutline?: boolean;
  as?: React.ElementType;
};

const authorizedRoutes = ['/user'];

export const Link = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      onClick,
      to = '',
      href: hrefProp,
      isExternal,
      children,
      allowPrevent,
      isTextOutline = false,
      as = RouterLink,
      ...rest
    },
    ref,
  ) => {
    let href = hrefProp;
    const { i18n } = useTranslation();
    const isAuthorized = useSelector(getUserIsAuthorized);
    const { onOpen: openAuthModal } = useModal(MODAL_TYPE.AUTH);

    if (to && isUrlExternal(to)) {
      href = to;
    }

    const isTextChild = isTextOutline || typeof children === 'string';

    const focusVisible = isTextChild
      ? {
          boxShadow: 'none',
          color: 'vanilla-text',
          textDecoration: 'underline',
        }
      : undefined;

    if (href) {
      return (
        <ChakraLink
          target="_blank"
          href={href}
          _focusVisible={focusVisible}
          ref={ref}
          _hover={{ textDecoration: 'none' }}
          {...rest}
        >
          {children}
        </ChakraLink>
      );
    }

    const url = to[0] === '/' ? applyLangToUrl(to, i18n.language) : to;

    return (
      <ChakraLink
        onClick={e => {
          if (allowPrevent && e.defaultPrevented) {
            e.defaultPrevented = false;
          }
          if (window.location.pathname + window.location.search === url) {
            window.scrollTo(0, 0);
          }
          if (onClick) {
            onClick(e);
          }
          if (
            !isAuthorized &&
            authorizedRoutes.some(route => to.startsWith(route))
          ) {
            e.preventDefault();
            openAuthModal();
          }
        }}
        as={as}
        to={url}
        _focusVisible={focusVisible}
        _hover={{ textDecoration: 'none' }}
        ref={ref}
        {...rest}
        target={isExternal ? '_blank' : '_self'}
      >
        {children}
      </ChakraLink>
    );
  },
);
