import { Button, Flex, FormControl } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { CustomInput } from 'forms/custom-input';
import { PasswordInput } from 'forms/password-input';
import { t } from 'i18next';
import { reset2fa } from 'services/user/modules/user';
import { useActions } from 'store';
import { toastSuccess } from 'toasts';
import { Errors, FormSubmit } from 'types';
import * as Yup from 'yup';

export const ResetTwoFactorForm = () => {
  const actions = useActions({ reset2fa });

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      code: '',
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required('errors.password.required'),
      code: Yup.string().required('errors.required'),
    }),
    onSubmit: async ({ code, password }, { setErrors }) => {
      try {
        await actions.reset2fa(code, password);
        toastSuccess({
          title: t('2fa.disabled.title'),
        });
      } catch (errors) {
        setErrors(errors as Errors);
      }
    },
  });

  return (
    <FormControl
      as="form"
      onSubmit={formik.handleSubmit as FormSubmit}
      w="full"
    >
      <Flex direction="column" gap="3" mb="5">
        <PasswordInput
          label={t('form.label.password')}
          placeholder={t('form.label.password')}
          name="password"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.password}
          isInvalid={!!formik.errors.password}
          errorMessage={formik.errors.password}
          size="md"
          boxProps={{ w: 'full' }}
        />
        <CustomInput
          label={t('auth-modal.login.field.two-factor-code')}
          placeholder={t('auth-modal.login.field.two-factor-code')}
          type="text"
          name="code"
          required
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.code}
          isInvalid={!!formik.errors.code}
          errorMessage={formik.errors.code}
          size="md"
          boxProps={{ w: 'full' }}
        />
      </Flex>
      <Flex>
        <Button
          type="submit"
          colorScheme="purple"
          w={{ base: 'full', md: 'auto' }}
          isLoading={formik.isSubmitting}
        >
          {t('actions.disable')}
        </Button>
      </Flex>
    </FormControl>
  );
};
