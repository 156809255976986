import { createIcon } from '@chakra-ui/icons';

export const MyBetsIcon = createIcon({
  displayName: 'MyBetsIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M4.977 0h6.054c3.026 0 3.779.809 3.77 4.036v9.007c0 2.13-1.16 2.635-2.585 1.113-.657-.704-1.658-.648-2.234.12l-.809 1.081c-.649.857-1.697.857-2.346 0l-.809-1.08c-.568-.761-1.57-.817-2.226-.12l-.008.007c-1.417 1.522-2.586 1.017-2.586-1.113V4.036C1.198.809 1.951 0 4.978 0Zm.221 3a1 1 0 1 0 0 2h4.334a1 1 0 1 0 0-2H5.198Zm0 4.034a1 1 0 1 0 0 2h5.934a1 1 0 0 0 0-2H5.198Z"
      clipRule="evenodd"
    />
  ),
});
