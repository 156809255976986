import Countdown from 'react-countdown';
import { useTranslation } from 'react-i18next';

type Props = {
  endTime: number;
};

export const CountdownExpire: React.FC<Props> = ({ endTime, ...props }) => {
  const { t } = useTranslation();

  return (
    <Countdown
      key={endTime}
      date={endTime}
      renderer={({ days, hours, minutes }) => {
        if (days) {
          return t('time.days', { count: days + 1 });
        }
        if (hours) {
          return t('time.hours', { count: hours + 1 });
        }
        if (minutes) {
          return t('time.minutes', { count: minutes + 1 });
        }
        return t('time.few-sec');
      }}
      {...props}
    />
  );
};
