import SumsubWebSdk from '@sumsub/websdk-react';
import { Loading } from 'components/loading';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getUserEmail,
  getUserKYCFlags,
  loadKYCAccessToken,
} from 'services/user/modules/user';
import { useActions } from 'store';

export const SubSumSdk = () => {
  const [accessToken, setAccessToken] = useState('');
  const email = useSelector(getUserEmail);
  const actions = useActions({ loadKYCAccessToken });
  const kycFlags = useSelector(getUserKYCFlags);
  const navigate = useNavigate();

  useEffect(() => {
    actions.loadKYCAccessToken().then(setAccessToken);
  }, [actions]);

  useEffect(() => {
    if (kycFlags === 2) {
      navigate('/user/settings/verify');
    }
  }, [kycFlags, navigate]);

  if (!accessToken) {
    return <Loading color="sherbet-purple" minH="100vh" />;
  }

  return (
    <SumsubWebSdk
      accessToken={accessToken}
      expirationHandler={actions.loadKYCAccessToken}
      config={{
        email,
        lang: 'en',
        // uiConf: { customCss: 'https://dev.sherbet.com/verification.css' },
      }}
      options={{}}
      onMessage={console.log}
      onError={console.log}
    />
  );
};
