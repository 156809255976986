import { Box } from '@chakra-ui/react';
import styles from './style.module.scss';

export const CircleIndicator = ({ color = 'sherbet-orange.200', ...props }) => (
  <Box
    {...props}
    className={styles.blinkingIndicator}
    flexShrink="0"
    h="2"
    w="2"
    rounded="full"
    bg={color}
  />
);
