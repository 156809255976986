import { Box, Button, Flex, Heading, Text } from '@chakra-ui/react';
import { FormGlobalError } from 'forms/form-global-error';
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { HtmlContent } from 'services/cms/components/html-content';
import { getPage, loadPage } from 'services/cms/modules/pages';
import { getUserIsAuthorized } from 'services/user/modules/user';
import { useActions } from 'store';
import { Errors } from 'types';
import { Skeleton } from 'uikit/skeleton';
import { Step6 } from '../step6';

type Props = {
  onClose: () => void;
  onSubmit: () => void;
};

export const Step5: React.FC<Props> = ({ onSubmit, onClose }) => {
  const timeout = useRef<ReturnType<typeof setTimeout>>();
  const { t } = useTranslation();
  const page = useSelector(getPage('terms'));
  const actions = useActions({ loadPage });
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const isAuthrozied = useSelector(getUserIsAuthorized);

  const [isFakeLoading, setIsFakeLoading] = useState(false);
  const [isClosing, setIsClosing] = useState(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    setIsFakeLoading(true);
    timeout.current = setTimeout(() => {
      setIsFakeLoading(false);
    }, 5000);
    try {
      await onSubmit();
    } catch (e) {
      const errors = e as Errors;
      console.warn(e);
      if (errors.global) {
        setError(errors.global);
      } else {
        setError(Object.values(errors)[0]);
      }
      setIsFakeLoading(false);
      setIsLoading(false);
      clearTimeout(timeout.current);
    }
  };

  useEffect(() => {
    if (isAuthrozied && !isFakeLoading) {
      setIsClosing(true);
    }

    return undefined;
  }, [isAuthrozied, isFakeLoading]);

  useEffect(() => () => clearTimeout(timeout.current), []);

  useEffect(() => {
    actions.loadPage('terms');
  }, [actions]);

  const navigate = useNavigate();

  if (isFakeLoading || isLoading) {
    return <Step6 isClosing={isClosing} onClose={onClose} />;
  }

  return (
    <Box w="full" pos="relative">
      <Flex
        flexDirection="column"
        justifyContent="start"
        w="full"
        bg="toffee-base"
        mx="auto"
      >
        <Heading fontSize="2xl" mb="2">
          {t('signup.step7.title')}
        </Heading>
        <Text fontSize="lg" color="candy-floss-text" maxW="md" mb="5">
          {t('signup.step7.desc')}
        </Text>
        {page ? (
          <Box
            mb="5"
            bg="sugar-dust"
            rounded="xl"
            p="5"
            maxH={{ base: '144px', md: '172px' }}
            overflow="auto"
          >
            <HtmlContent content={page.body} />
          </Box>
        ) : (
          <Skeleton h={{ base: '144px', md: '172px' }} mb="5" rounded="xl" />
        )}
        <FormGlobalError fontSize="md" error={error} mb="5" />
        <Flex align="center" gap="2">
          <Button onClick={() => navigate(-1)} rounded="full">
            {t('actions.back')}
          </Button>
          {page && (
            <Button
              onClick={handleSubmit}
              colorScheme="purple"
              isLoading={isLoading}
            >
              Agree & Continue
            </Button>
          )}
        </Flex>
      </Flex>
    </Box>
  );
};
