import dayjs from 'dayjs';
import { useEffect, useState } from 'react';

const daySize = 24 * 60 * 60 * 1000;

const SIZE = 400;

const calculate = () => {
  const currentDateUTC = dayjs().utc();
  const startOfDayUTC = currentDateUTC.startOf('day');
  const difference = currentDateUTC.diff(startOfDayUTC) % daySize;

  const newNumber = (SIZE * difference) / daySize;

  return newNumber;
};

export const useTournamentPrize = (
  isAnimate: boolean,
  {
    currentPrizePool,
    maxPossiblePrizePool,
    roundEndDate,
  }: {
    currentPrizePool: number;
    maxPossiblePrizePool: number;
    roundEndDate: string;
  },
) => {
  const [number, setNumber] = useState(calculate);

  useEffect(() => {
    if (isAnimate) {
      setNumber(calculate());

      const interval = setInterval(() => {
        setNumber(calculate());
      }, 3000);

      return () => clearInterval(interval);
    }
    return () => {};
  }, [isAnimate]);

  if ((Number(roundEndDate) - Math.floor(Date.now() / 1000)) / 60 <= 60) {
    return currentPrizePool;
  }

  return Math.floor(Math.min(maxPossiblePrizePool, currentPrizePool + number));
};
