import { createIcon } from '@chakra-ui/icons';

export const ChatIcon = createIcon({
  displayName: 'ChatIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M11.225 0h-6.45C4.172 0 3.66 0 3.24.034 2.798.07 2.37.15 1.963.357A3.275 3.275 0 0 0 .53 1.788c-.207.408-.286.835-.322 1.277-.035.42-.035.932-.035 1.536v9.578c0 .174 0 .366.015.527.014.151.052.482.287.775.262.329.659.52 1.079.519.376 0 .658-.177.785-.26.134-.09.284-.21.42-.319l.025-.019 1.809-1.447c.4-.321.496-.391.59-.44.103-.052.212-.09.324-.113.104-.021.222-.026.735-.026h4.982c.603 0 1.116 0 1.536-.034.441-.036.869-.115 1.276-.323a3.275 3.275 0 0 0 1.432-1.431c.208-.408.286-.835.322-1.276.035-.42.035-.933.035-1.537V4.601c0-.604 0-1.116-.035-1.536-.036-.442-.114-.869-.322-1.277A3.275 3.275 0 0 0 14.037.357C13.63.149 13.202.07 12.761.034 12.341 0 11.828 0 11.225 0Zm-7.05 3.767a1 1 0 1 0 0 2h4.333a1 1 0 1 0 0-2H4.174Zm0 3.034a1 1 0 1 0 0 2h6.933a1 1 0 1 0 0-2H4.174Z"
      clipRule="evenodd"
    />
  ),
});
