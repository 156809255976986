import { createIcon } from '@chakra-ui/icons';

export const GameShowsIcon = createIcon({
  displayName: 'GameShowsIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path
        fill="currentColor"
        d="M9.332.74a.74.74 0 0 1 1.482 0v1.112a.74.74 0 1 1-1.482 0V.741Zm3.921.959a.74.74 0 0 1 1.048 1.048l-.786.785a.74.74 0 0 1-1.047-1.047l.785-.786Zm-.785 6.668a.74.74 0 0 0 0 1.047l.785.786a.74.74 0 1 0 1.048-1.048l-.786-.785a.741.741 0 0 0-1.047 0ZM5.8 2.747a.74.74 0 1 1 1.047-1.048l.786.786a.74.74 0 1 1-1.047 1.047L5.8 2.747Zm9.46 2.439h-1.112a.74.74 0 1 0 0 1.482h1.111a.74.74 0 0 0 0-1.482Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M8.496 4.454c.298-.097.618-.097.916 0 .215.07.379.188.509.298.118.1.246.229.379.361l.58.58a6.8 6.8 0 0 1 .36.379c.11.13.229.294.298.508.097.298.097.619 0 .916-.07.215-.188.38-.298.509a6.79 6.79 0 0 1-.36.379L4.001 15.26c-.132.132-.26.26-.379.36a1.5 1.5 0 0 1-.508.299 1.482 1.482 0 0 1-.916 0 1.5 1.5 0 0 1-.509-.298c-.118-.1-.247-.23-.379-.361l-.58-.58a6.789 6.789 0 0 1-.36-.379 1.498 1.498 0 0 1-.298-.508 1.482 1.482 0 0 1 0-.916c.07-.215.187-.38.297-.509.1-.118.23-.246.362-.379l6.877-6.877c.132-.132.26-.26.379-.361.13-.11.294-.228.508-.298Zm-.031 3.954c-.085-.028-.158-.101-.305-.248l-.328-.327c-.146-.147-.22-.22-.247-.305a.37.37 0 0 1 0-.23c.027-.084.1-.157.248-.304l.702-.7c.147-.147.22-.22.305-.247a.37.37 0 0 1 .228 0c.085.027.158.1.305.247l.325.326c.147.146.22.22.248.304a.37.37 0 0 1 0 .229c-.028.084-.1.158-.247.304l-.7.702c-.147.147-.22.22-.305.248a.37.37 0 0 1-.23 0Z"
        clipRule="evenodd"
      />
    </>
  ),
});
