import { WS_HANDLER_MESSAGE } from 'helpers/ws';
import { userWebsocket } from 'services/user/modules/user';
import { createReducer } from 'store';
import { loadCurrentGame, placeWager } from './actions';
import { JackpotType } from './types';

const initialState: JackpotType = {
  games: {},
  placeBetLoading: {},
};

const groupBy = (xs: [], key: string) =>
  xs.reduce((rv, x) => {
    ((rv[x[key]] = rv[x[key]] || []) as []).push(x);
    return rv;
  }, {});

const jackpotActionResolver = (
  state: JackpotType,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  { payload, handlerId }: any,
) => {
  state.games[handlerId] = { ...payload };

  if (payload.time !== undefined) {
    state.games[handlerId].data.time = new Date().getTime() + payload.time;
  }

  state.games[handlerId].data.groupedWagers = groupBy(
    state.games[handlerId].data.wagers,
    'userId',
  );
};

export const reducer = createReducer(initialState, {
  [loadCurrentGame.toString()]: (state, { payload, meta: { handlerId } }) => {
    state.games[handlerId] = { ...payload };

    if (payload.time !== undefined) {
      state.games[handlerId].data.time = new Date().getTime() + payload.time;
    }

    const { wagers } = state.games[handlerId].data;

    state.games[handlerId].data.groupedWagers = groupBy(wagers, 'userId');
  },

  [placeWager.toString()]: (state, { meta: { handlerId, loading } }) => {
    state.placeBetLoading[handlerId] = loading;
  },

  [WS_HANDLER_MESSAGE(userWebsocket, 'JACKPOT', 'GAMESTART')]:
    jackpotActionResolver,

  [WS_HANDLER_MESSAGE(userWebsocket, 'JACKPOT', 'GAMEFINAL')]: (
    state,
    { payload, handlerId },
  ) => {
    jackpotActionResolver(state, { payload, handlerId });
    state.games[handlerId].data.wagersClosed = true;
  },

  [WS_HANDLER_MESSAGE(userWebsocket, 'JACKPOT', 'GAMEOVER')]:
    jackpotActionResolver,

  [WS_HANDLER_MESSAGE(userWebsocket, 'JACKPOT', 'WAGER')]:
    jackpotActionResolver,
});
