import {
  JACKPOT_HIGH_HANDLER_ID,
  JACKPOT_LOW_HANDLER_ID,
  JACKPOT_MEDIUM_HANDLER_ID,
  ROULETTE_HANDLER_ID,
  VERSUS_HANDLER_ID,
} from 'constants/general';
import { realistic } from 'helpers/confetti';
import { WS_MESSAGE } from 'helpers/ws';
import { chatWebsocket, getChannelId, getChannels } from 'modules/chat';
import { Middleware } from 'redux';
import {
  getUserId,
  getUserIsAuthorized,
  getUserLevel,
  userWebsocket,
} from 'services/user/modules/user';
import { UserType } from 'types';

type Sound = { play: () => void };

const howler: {
  loaded: boolean;
  notificationSound?: Sound;
  claimSound?: Sound;
  levelSound?: Sound;
  newMessage?: Sound;
} = { loaded: false };

const getHowler = async () => {
  if (!howler.loaded) {
    const { Howler, Howl } = await import('howler');

    Howler.volume(0.5);

    howler.notificationSound = new Howl({
      src: ['/assets/audio/ding.wav'],
    });

    howler.claimSound = new Howl({
      src: ['/assets/audio/claim.wav'],
    });

    howler.levelSound = new Howl({
      src: ['/assets/audio/level.wav'],
    });

    howler.newMessage = new Howl({
      src: ['/assets/audio/new-message.wav'],
    });

    howler.loaded = true;
  }

  return howler;
};

let prevTitle: string | null;
let interval: NodeJS.Timeout;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const dingMiddleware: Middleware = store => next => (action: any) => {
  const state = store.getState();
  const isAuthorized = getUserIsAuthorized(state);

  if (!isAuthorized) {
    return next(action);
  }

  const { payload } = action;

  if (
    action.type === WS_MESSAGE(userWebsocket, 'INVENTORY') &&
    payload.creditChange > 0
  ) {
    if (payload.handlerId === 0) {
      getHowler().then(({ claimSound }) => claimSound?.play());
    } else if (
      [
        JACKPOT_LOW_HANDLER_ID,
        JACKPOT_MEDIUM_HANDLER_ID,
        JACKPOT_HIGH_HANDLER_ID,
        VERSUS_HANDLER_ID,
        ROULETTE_HANDLER_ID,
      ].includes(payload.handlerId)
    ) {
      getHowler().then(({ notificationSound }) => notificationSound?.play());
    }
  } else if (
    action.type === WS_MESSAGE(userWebsocket, 'LEVEL') &&
    action.payload?.level?.level > getUserLevel(store.getState())
  ) {
    getHowler().then(({ levelSound }) => levelSound?.play());
    realistic();
  } else if (
    action.type === WS_MESSAGE(chatWebsocket, 'CHAT') &&
    getChannels(state)[action.payload?.channel]?.private &&
    getChannelId(state) !== action.payload?.channel &&
    action.payload?.messages?.every(
      (m: { userId: 'string' }) => m.userId !== getUserId(state),
    )
  ) {
    if (document.hidden) {
      if (!prevTitle) {
        prevTitle = document.title;

        const callback = () => {
          if (document.hidden) {
            // hidden
          } else {
            clearInterval(interval);
            document.removeEventListener('visibilitychange', callback);
            if (prevTitle) {
              document.title = prevTitle;
            }

            prevTitle = null;
          }
        };

        document.addEventListener('visibilitychange', callback);
      }

      const user = Object.values(action.payload?.users)?.[0] as UserType;

      const newTitle = `${user?.name} messaged you`;

      clearInterval(interval);
      document.title = newTitle;

      interval = setInterval(() => {
        if (document.title === prevTitle) {
          document.title = newTitle;
        } else if (prevTitle) {
          document.title = prevTitle;
        }
      }, 3000);
    }
    getHowler().then(({ newMessage }) => newMessage?.play());
  }
  return next(action);
};
