import Filter from 'bad-words';

const OK_WORDS = ['damn', 'god', 'jesus', 'ass', 'hell'];
const filter = new Filter({ placeHolder: '*' });

// Additional bad words to be removed
const additionalBadWords = [
  'rollbit',
  'rollbot',
  'rollbots',
  'gamdom',
  'chips',
  'csgo500',
  'rolbit',
  'rustclash',
  'stakecom',
  'bcgame',
  'metaspins',
  'fortunejack',
  'katsubet',
  'cloudbet',
  'mbit',
  'hypeup',
  'hypedrop',
  'rollbits',
  'rlb',
  'k8',
  '7bit',
  'shuffle',
  'bitstarz',
  'roobet',
  'spinbet',
  'spinbit',
  'wildcasino',
  'bitcasino',
  'casumo',
  'mbitcasino',
  'kingbilly',
  'baocasino',
  'energycasino',
  'casinia',
  'nomini',
  'dasistcasino',
  'zetcasino',
  'wtfskins',
  'daddyskins',
  'csgoroll',
  'drakemoon',
  'hellcase',
  'skinhub',
  'csgofast',
  'csgolive',
  'csgopolygon',
  'csgoempire',
  'csgocrash',
  'csgobig',
  'csgomagic',
];

// Add additional bad words to the filter while excluding the good words
filter.addWords(...additionalBadWords);
filter.removeWords(...OK_WORDS);

export const clean = (str: string): string => filter.clean(str);
