import { Box, Button, Flex } from '@chakra-ui/react';
import cn from 'classnames';
import { getRouletteSettingsNumberWagerGroups } from 'modules/handler';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getRouletteRolls } from 'services/games/modules/roulette';
import { pickedGroupToColor, rouletteColors, toColor } from '../../utils';
import styles from './style.module.scss';

const INDEX_NAME_MAP = {
  0: 'red',
  1: 'green',
  2: 'black',
};

type Props = {
  showAmount?: number;
  bonusNumber?: number;
};

export const HistoryBar: React.FC<Props> = ({
  showAmount = 10,
  bonusNumber,
}) => {
  const rolls = useSelector(getRouletteRolls);
  const numberWagerGroups = useSelector(getRouletteSettingsNumberWagerGroups);

  const { red, green, black, bonus } = useMemo(
    () =>
      rolls.reduce(
        (acc, roll) => {
          const wagerGroup = numberWagerGroups?.[roll.rollNumber] as number;
          if (INDEX_NAME_MAP[wagerGroup as keyof typeof INDEX_NAME_MAP]) {
            acc[
              INDEX_NAME_MAP[
                wagerGroup as keyof typeof INDEX_NAME_MAP
              ] as keyof typeof acc
            ] += 1;
          }
          if (roll.bonusNumber !== undefined) {
            acc.bonus += 1;
          }
          return acc;
        },
        { red: 0, green: 0, black: 0, bonus: 0 },
      ),
    [rolls, numberWagerGroups],
  );

  return (
    <Box>
      <Flex
        opacity={rolls ? 1 : 0}
        justify="space-between"
        gap="1.5"
        flexWrap="wrap"
      >
        <Flex gap="2" align="center">
          <Button
            colorScheme="custom"
            rounded="full"
            size="sm"
            border="1px solid"
            color={pickedGroupToColor(0)}
            borderColor={pickedGroupToColor(0)}
          >
            {red}
          </Button>
          <Button
            colorScheme="custom"
            rounded="full"
            size="sm"
            border="1px solid"
            color={pickedGroupToColor(1)}
            borderColor={pickedGroupToColor(1)}
          >
            {green}
          </Button>
          <Button
            colorScheme="custom"
            rounded="full"
            size="sm"
            border="1px solid"
            color={
              rouletteColors[
                bonusNumber as keyof typeof rouletteColors
              ] as string
            }
            borderColor={
              rouletteColors[
                bonusNumber as keyof typeof rouletteColors
              ] as string
            }
          >
            {bonus}
          </Button>
          <Button
            colorScheme="custom"
            rounded="full"
            size="sm"
            color={pickedGroupToColor(2)}
            border="1px solid"
            borderColor={pickedGroupToColor(2)}
          >
            {black}
          </Button>
        </Flex>
        <Flex gap="1.5" overflow="auto">
          {rolls?.slice(0, showAmount).map((x, i) => (
            <Button
              colorScheme="custom"
              rounded="full"
              size="sm"
              color="vanilla-text"
              key={x.gameId}
              bg={toColor(x.rollNumber)}
              className={cn({
                [styles.rollpop]: i === 0,
              })}
            />
          ))}
        </Flex>
      </Flex>
    </Box>
  );
};
