/* eslint-disable react/no-danger */
import { Box, Flex, Text, Tooltip } from '@chakra-ui/react';
import cn from 'classnames';
import dayjs from 'dayjs';
import { EMOTES_MAP } from 'helpers/emotes';
import { jsonParse } from 'helpers/json';
import { DeleteIcon, MuteIcon } from 'icons';
import linkifyHtml from 'linkify-html';
import {
  ChatReplyIdType,
  deleteMessages,
  getChannel,
  getChannelMessages,
  getChatIsTimestampVisible,
  mute,
} from 'modules/chat';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  MERGED_CHAT_COLORS,
  PermissionFlags,
  ban,
  getUserName,
  getUserPermissionFlags,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { toastSuccess } from 'toasts';
import { BadgeType } from 'types';
import { Link } from 'uikit/link';
import { MoreDropdown } from 'uikit/more-dropdown';
import { ChatAvatar } from '../chat-avatar';
import styles from './style.module.scss';

function stripHtml(html: string) {
  const doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || '';
}

const parseMessage = (message: string) => {
  const text = (message || '')
    .split('</3')
    .map(msg => stripHtml(msg || '').replaceAll('\\n', '<br/>'))
    .join('</3');

  const mentions = text.match(/@\[(.*?)]\((.*?)(.*?)\)/gm) || [];

  const mentionString = mentions.reduce((acc: string, mention: string) => {
    const mentionUsername = mention.match(/.*?\[([^)]*)\].*/)?.[1];
    return acc.replace(
      mention,
      `<span class="mention" data-username="${mentionUsername}">@${mentionUsername}</span>`,
    );
  }, text);

  const emotes = text.match(/:\((.*?)(.*?)\)/gm) || [];

  const emoteFlag = '{{EMOTE}}';

  const emotesString = emotes.reduce((acc: string, emote: string) => {
    const emoteId = emote.match(/\((.*?)\)/)?.[1];
    return acc.replace(
      emote,
      `${emoteFlag}{"emoteId": "${emoteId}"}${emoteFlag}`,
    );
  }, mentionString);

  const linkifiedText = linkifyHtml(emotesString, {
    format: href =>
      href.replace('https://', '').replace('http://', '').replace('www.', ''),
    render: ({ tagName, attributes: { href }, content }) => {
      const isInternal = content.includes('sherbet.com');
      return `<${tagName} class="chat-link${
        isInternal ? ' chat-link-internal' : ''
      }"${
        isInternal ? '' : ' target="blank"'
      } href="${href}">${content}</${tagName}>`;
    },
  });

  if (emotes.length > 0) {
    return {
      text: linkifiedText
        .split(emoteFlag)
        .filter(t => t)
        .map((item, index) => {
          if (item.includes('{"emoteId"')) {
            const { emoteId }: { emoteId: string } = jsonParse(item, {});

            return (
              <Tooltip
                key={index}
                gutter={10}
                hasArrow
                fontSize="md"
                rounded="md"
                placement="top"
                bg="vanilla-text"
                fontWeight="500"
                py="1"
                px="2"
                color="toffee-base"
                label={emoteId}
              >
                <img
                  className="emote_chat_image"
                  draggable="false"
                  src={EMOTES_MAP[emoteId as keyof typeof EMOTES_MAP]?.icon}
                  alt={emoteId}
                />
              </Tooltip>
            );
          }

          return (
            <span key={index} dangerouslySetInnerHTML={{ __html: item }} />
          );
        }),
    };
  }

  return {
    text:
      linkifiedText.length < emotesString.length && !mentions.length ? (
        emotesString
      ) : (
        <span dangerouslySetInnerHTML={{ __html: linkifiedText }} />
      ),
  };
};

type Props = {
  channelId: number;
  id: string;
  userId: string;
  username: string;
  timestamp: string;
  message: string;
  avatar: string;
  nftId?: string;
  badges?: BadgeType[];
  chatColor?: string;
  setReplyId: (value: ChatReplyIdType) => void;
  replyToUser?: string;
  replyToMessage?: string;
  disabled?: boolean;
  index: number;
  isChatRainAnnouncement?: boolean;
  type?: string;
  level?: number;
};

export const Message: React.FC<Props> = ({
  channelId,
  id,
  userId,
  username,
  timestamp,
  message,
  avatar,
  type,
  nftId,
  badges,
  chatColor,
  setReplyId,
  replyToUser,
  replyToMessage,
  isChatRainAnnouncement,
  level,
}) => {
  const actions = useActions({ ban, deleteMessages, mute });
  const permissionFlags = useSelector(getUserPermissionFlags);
  const messages = useSelector(getChannelMessages);
  const isTimestampVisible = useSelector(getChatIsTimestampVisible);
  const { t } = useTranslation();
  const myUsername = useSelector(getUserName);
  const channel = useSelector(getChannel);

  const handleDelete = useCallback(() => {
    actions.deleteMessages({
      userName: username,
      channelId,
      startChatMessageId: id,
    });
  }, [channelId, id, username, actions]);

  const handleMute = useCallback(async () => {
    await actions.mute(username, id);
    toastSuccess({
      title: 'User muted',
      description: `${username} has been unmuted`,
    });
  }, [actions, username, id]);

  const permissions = useMemo(() => {
    if (!permissionFlags) return [];

    const hasDeletePermissions = permissionFlags
      .and(PermissionFlags.DeleteMessages)
      .eq(PermissionFlags.DeleteMessages);

    const hasMutePermissions = permissionFlags
      .and(PermissionFlags.MuteUser)
      .eq(PermissionFlags.MuteUser);

    const list = [];

    if (hasMutePermissions) {
      list.push({
        title: t('chat.mute'),
        icon: MuteIcon,
        onClick: handleMute,
      });
    }

    if (hasDeletePermissions) {
      list.push({
        title: t('actions.delete'),
        icon: DeleteIcon,
        onClick: handleDelete,
      });
    }

    return list;
  }, [permissionFlags, t, handleMute, handleDelete]);

  const handleScrollToReply = () => {
    if (replyToMessage) {
      document.getElementById(`message_${replyToMessage}`)?.scrollIntoView();
    }
  };

  const parsedMessage = useMemo(() => parseMessage(message), [message]);

  const replyMessage = useMemo(() => {
    const msg =
      replyToMessage && messages.find(m => m.id === replyToMessage)?.message;
    if (msg) {
      return parseMessage(msg);
    }

    return null;
  }, [messages, replyToMessage]);

  // const welcome = useWelcomeMessage();

  if (type === 'welcome') {
    if (channel?.private) {
      return null;
    }
    return null;
  }

  return (
    <Box
      className={cn(styles.general, { [styles.rain]: isChatRainAnnouncement })}
      id={`message_${id}`}
      cursor="pointer"
      onClick={() =>
        setReplyId({
          username,
          avatar,
          badges,
          messageId: id,
          message: parsedMessage.text,
          level,
          nftId,
        })
      }
    >
      <Box
        px="5"
        py="2"
        data-group
        position="relative"
        _hover={{
          zIndex: '1',
          bg: 'sugar-dust',
        }}
        bg={isChatRainAnnouncement ? 'sugar-dust' : undefined}
        borderLeft={
          isChatRainAnnouncement
            ? `1px solid ${
                MERGED_CHAT_COLORS[
                  chatColor as keyof typeof MERGED_CHAT_COLORS
                ] || '#7B54E9'
              }`
            : undefined
        }
      >
        {replyToUser && (
          <Box>
            {replyMessage && (
              <Box onClick={handleScrollToReply} cursor="pointer" mb="1">
                <Flex align="center">
                  <Text
                    noOfLines={1}
                    textOverflow="ellipsis"
                    color="candy-floss-text"
                    fontSize="sm"
                  >
                    {t('chat.reply-to')} {replyToUser}:&nbsp;
                    {replyMessage.text}
                  </Text>
                </Flex>
              </Box>
            )}
          </Box>
        )}
        {userId && (
          <Box
            alignItems="center"
            fontSize="14px"
            overflow="hidden"
            whiteSpace="nowrap"
            textOverflow="ellipsis"
            display="inline-flex"
            mr="1"
            verticalAlign="middle"
            maxW="full"
          >
            {permissions.length > 0 && (
              <Box mr="2" onClick={e => e.stopPropagation()}>
                <MoreDropdown
                  options={permissions}
                  placement="auto"
                  gutter={4}
                  buttonSize="xs"
                  buttonProps={{
                    rounded: 'md',
                  }}
                />
              </Box>
            )}
            {isTimestampVisible && (
              <Text fontSize="sm" color="candy-floss-text" mr="1" minW="32px">
                {dayjs(Number(timestamp) * 1000).format('h:mm')}
              </Text>
            )}
            <ChatAvatar
              avatar={avatar}
              badges={badges}
              username={username}
              nftId={nftId}
              level={level}
            />
            <Link
              to={`?modal=profile&username=${username}`}
              fontWeight="500"
              color={
                MERGED_CHAT_COLORS[
                  chatColor as keyof typeof MERGED_CHAT_COLORS
                ] || 'vanilla-text'
              }
              overflow="hidden"
              whiteSpace="nowrap"
              textOverflow="ellipsis"
              _hover={{ opacity: '0.9' }}
            >
              {`${username}:`}
            </Link>
          </Box>
        )}
        {parsedMessage && (
          <Text as="span" verticalAlign="middle">
            {parsedMessage.text}
          </Text>
        )}
      </Box>
    </Box>
  );
};
