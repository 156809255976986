import { Flex, FlexProps } from '@chakra-ui/react';
import cn from 'classnames';
import styles from './style.module.scss';

type Props = FlexProps & {
  children: React.ReactNode;
  className?: string;
};

export const Content: React.FC<Props> = ({ children, className, ...rest }) => (
  <Flex
    className={cn(styles.animated, className)}
    flexDirection="column"
    {...rest}
  >
    {children}
  </Flex>
);
