import { createIcon } from '@chakra-ui/icons';

export const PopularWithFriendsIcon = createIcon({
  displayName: 'PopularWithFriendsIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.218 9.748c0 2.555-2.393 4.47-5.109 4.47S0 12.304 0 9.11c0-1.084.393-2.537.743-3.616.177-.548.905-.552 1.107-.014.14.375.28.76.385 1.075.32.958.958 1.277.958 1.277s-.41-1.186 0-2.554c.577-1.924 1.618-2.92 2.355-3.41.376-.249.81.07.855.52.08.812.363 1.992 1.26 2.89.092.09.21.195.343.314.805.715 2.212 1.967 2.212 4.156Zm-.92-5.494c.78.782 1.871 1.963 2.363 3.746a2.591 2.591 0 1 0-2.362-3.746Zm4.985 9.965h-4.05a6.183 6.183 0 0 0 1.686-4.26c0-.213-.008-.418-.024-.618a4.571 4.571 0 0 1 3.022 1.423c.417.437.747.95.979 1.506.423 1.02-.509 1.949-1.613 1.949Z"
      clipRule="evenodd"
    />
  ),
});
