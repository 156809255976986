import { createIcon } from '@chakra-ui/icons';

export const MoreIcon = createIcon({
  displayName: 'MoreIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="M1.657 6.346C.743 6.346 0 7.088 0 8c0 .912.743 1.654 1.657 1.654a1.655 1.655 0 0 0 0-3.308Zm6.339 3.308a1.654 1.654 0 1 0 0-3.308 1.654 1.654 0 0 0 0 3.308Zm6.347-3.308a1.655 1.655 0 0 0 0 3.308C15.257 9.654 16 8.912 16 8c0-.912-.743-1.654-1.657-1.654Z"
    />
  ),
});
