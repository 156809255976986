import { TriangleDownIcon } from '@chakra-ui/icons';
import { Flex, IconButton } from '@chakra-ui/react';

type Props = {
  current: number;
  total: number;
  onClick: React.MouseEventHandler;
  isLoading?: boolean;
  mt?: string;
};

export const ShowMore: React.FC<Props> = ({
  current,
  total,
  onClick,

  mt = '5',
}) => {
  if (!current || current >= total) {
    return null;
  }

  return (
    <Flex mt={mt} justify="center">
      <IconButton
        aria-label="Show More"
        color="candy-floss-text"
        size="sm"
        onClick={onClick}
        rounded="full !important"
      >
        <TriangleDownIcon />
      </IconButton>
    </Flex>
  );
};
