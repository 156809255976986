import { createIcon } from '@chakra-ui/icons';

export const LTCIcon = createIcon({
  displayName: 'LTCIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <g clipPath="url(#a)">
        <path fill="#BFBBBB" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z" />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="m5.213 9.607-.713.277.344-1.38.722-.29L6.606 4h2.565l-.76 3.098.706-.285-.34 1.375-.714.285-.424 1.741H11.5L11.063 12H4.626l.587-2.393Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </>
  ),
});
