import { createIcon } from '@chakra-ui/icons';

export const BaseballIcon = createIcon({
  displayName: 'BaseballIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="M5.718 4.925a2.421 2.421 0 1 0 0-4.843 2.421 2.421 0 0 0 0 4.843Zm9.815-3.853a2.255 2.255 0 0 0-2.847-.506c-1.932 1.077-2.394 1.706-10.504 11.806l-.188-.188a1.076 1.076 0 1 0-1.48 1.48l2.077 2.077a1.076 1.076 0 0 0 1.49-1.49l-.139-.14C14.058 5.973 14.704 5.344 15.737 3.46a2.184 2.184 0 0 0-.204-2.389Z"
    />
  ),
});
