import {
  connect,
  disconnect,
  send as socketSend,
} from '@giantmachines/redux-websocket';
import { getToken } from 'helpers/client';
import { jsonParse } from 'helpers/json';
import { Dispatch, Middleware } from 'redux';
import { AnyDispatch } from 'types';

const promises: { [key: string]: (arg: unknown) => void } = {};

export const WS_HANDLER_MESSAGE = (
  wsType: string,
  handlerType: string,
  action: string,
) => `${wsType}::MESSAGE::${handlerType.toUpperCase()}/${action.toUpperCase()}`;
export const WS_MESSAGE = (wsType: string, action: string) =>
  `${wsType}::MESSAGE::${action.toUpperCase()}`;
export const WS = (wsType: string, action: string) =>
  `${wsType}::${action.toUpperCase()}`;

export const wsconnect = (type: string, url: string) => connect(url, type);
export const wsdisconnect = (type: string) => disconnect(type);

export const send = (type: string, args: unknown) => socketSend(args, type);

export const ws =
  (type: string, action: string, payload: unknown, id?: string) =>
  (dispatch: Dispatch) => {
    dispatch(
      socketSend(
        {
          action,
          payload,
          id,
        },
        type,
      ),
    );

    if (id) {
      return new Promise((resolve, reject) => {
        const timeout = setTimeout(() => {
          reject(new Error('TIMEOUT'));
        }, 10000);
        promises[id] = (...args) => {
          clearTimeout(timeout);
          resolve(...args);
        };
      });
    }

    return Promise.resolve();
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const wsMiddleware: Middleware<any, any, AnyDispatch> =
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  store => next => (action: any) => {
    if (!action.type || !action.type.endsWith('::MESSAGE')) {
      if (action.type?.endsWith('::OPEN')) {
        const token = getToken();
        if (token) {
          store.dispatch(
            ws(action.type.replace('::OPEN', ''), 'auth', {
              token,
            }),
          );
        }
      }
      return next(action);
    }

    const message = jsonParse(action.payload.message, {});

    let type;

    if (message.handlerType === undefined) {
      type = `${action.type}::${message.action.toUpperCase()}`;
    } else {
      type = `${
        action.type
      }::${message.handlerType.toUpperCase()}/${message.action.toUpperCase()}`;
    }

    if (typeof promises[message.id] === 'function') {
      promises[message.id](message);
      delete promises[message.id];
    }

    return store.dispatch({
      type,
      payload: message.payload === undefined ? null : message.payload,
      id: message.id,
      handlerId: message.handlerId,
      userId: store.getState().user?.profile?.id,
      username: store.getState().user?.profile?.name,
      games: store.getState().slots.games.data,
    });
  };
