/* eslint-disable @typescript-eslint/no-explicit-any */
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { bindActionCreators } from 'redux';

/**
 * name - string
 * payloadFunc - func for prepare promise to middleware must return Promise instance
 * metaFunc - func for prepare meta info to middleware and reducer return Object instance
 */
export const createAction = (
  name: string,
  payloadFunc?: (...params: any) => unknown,
  metaFunc?: (...params: any) => unknown,
) => {
  const action = (...args: unknown[]) => {
    const obj: {
      type: string;
      payload?: unknown;
      meta?: unknown;
    } = {
      type: name,
    };

    if (payloadFunc) {
      obj.payload = payloadFunc(...args);
    } else if (args.length === 1) {
      const [payload] = args;
      obj.payload = payload;
    }

    if (metaFunc) {
      obj.meta = metaFunc(...args);
    }

    return obj;
  };

  action.toString = () => name;

  return action;
};
export const useActions = (actions: {
  [key: string]: (...args: any) => any;
}) => {
  const dispatch = useDispatch();
  return useMemo(
    () => bindActionCreators(actions, dispatch),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );
};
