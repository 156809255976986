import { createIcon } from '@chakra-ui/icons';

export const SupportIcon = createIcon({
  displayName: 'SupportIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.173 9.94a1 1 0 0 1 1 1v1.32c0 .887-.373 1.632-.998 2.134-.601.482-1.37.691-2.132.691h-.609a1 1 0 0 1 0-2h.609c.415 0 .71-.115.88-.251.146-.117.25-.285.25-.574v-1.32a1 1 0 0 1 1-1Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M12.564 6.174a2.434 2.434 0 0 1 .812 4.73.608.608 0 0 1-.812-.573V6.174Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M13.564 7.579v2.06a1.436 1.436 0 0 0 0-2.06Zm-1-2.405a3.434 3.434 0 0 1 1.145 6.673 1.61 1.61 0 0 1-2.145-1.516V6.174a1 1 0 0 1 1-1Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M3.436 6.174a2.434 2.434 0 0 0-.812 4.73.608.608 0 0 0 .812-.573V6.174Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M1.222 5.982a3.434 3.434 0 0 1 2.215-.808 1 1 0 0 1 .999 1v4.157a1.611 1.611 0 0 1-1.389 1.593 1.61 1.61 0 0 1-.756-.077 3.434 3.434 0 0 1-1.07-5.865ZM2.436 7.58a1.434 1.434 0 0 0 0 2.06V7.58Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.066 1.936a5.564 5.564 0 0 1 9.498 3.934v1.217a1 1 0 1 1-2 0V5.87a3.564 3.564 0 1 0-7.128 0v1.217a1 1 0 1 1-2 0V5.87c0-1.476.586-2.89 1.63-3.934Zm3.63 10.541a1.608 1.608 0 1 0 0 3.217h2.434a1.304 1.304 0 0 0 1.304-1.304v-.609a1.304 1.304 0 0 0-1.304-1.304H7.696Z"
        clipRule="evenodd"
      />
    </>
  ),
});
