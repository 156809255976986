import { createIcon } from '@chakra-ui/icons';

export const RakebackIcon = createIcon({
  displayName: 'RakebackIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.727 1c-1.166 0-2.254.17-3.077.47a5.4 5.4 0 0 0-.076.028c-.38.145-.732.33-1.007.566C.277 2.314 0 2.69 0 3.182v1.09c0 .493.277.868.567 1.118.294.252.675.446 1.083.595.823.299 1.91.47 3.077.47.17 0 .336-.004.501-.011a.807.807 0 0 0 .198-.02.337.337 0 0 0 .112-.059c.038-.029.077-.08.155-.18a3.4 3.4 0 0 1 .472-.497c.474-.407 1.033-.676 1.534-.858.387-.14.813-.255 1.26-.343.185-.035.277-.053.34-.097a.352.352 0 0 0 .125-.153c.03-.07.03-.158.03-.336v-.72c0-.492-.276-.867-.567-1.117-.293-.252-.675-.446-1.083-.594-.822-.3-1.91-.47-3.077-.47Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M5.09 8.267a.36.36 0 0 0-.363-.358c-1.166 0-2.254-.17-3.077-.47-.408-.148-.79-.342-1.083-.594a1.882 1.882 0 0 1-.301-.326.151.151 0 0 0-.122-.064.144.144 0 0 0-.144.143V7.91c0 .493.277.868.567 1.118.294.252.675.446 1.083.594.823.3 1.91.47 3.077.47h.01a.37.37 0 0 0 .354-.355v-1.47Zm0 3.637a.361.361 0 0 0-.363-.358c-1.166 0-2.254-.171-3.077-.47-.408-.149-.79-.343-1.083-.595a1.883 1.883 0 0 1-.301-.326.152.152 0 0 0-.122-.064.144.144 0 0 0-.144.144v1.674c0 .493.277.868.567 1.118.294.252.675.446 1.083.594.823.3 1.91.47 3.077.47h.022c.232 0 .382-.166.36-.397l-.005-.063a2.796 2.796 0 0 1-.013-.267v-1.46Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.605 5.486c.52-.08 1.084-.122 1.668-.122 1.166 0 2.254.17 3.077.47l.076.028c.38.144.732.33 1.006.566.291.25.568.625.568 1.117V9c0 .493-.277.868-.568 1.118-.293.252-.674.446-1.082.594-.823.3-1.91.47-3.077.47s-2.255-.17-3.077-.47c-.408-.148-.79-.342-1.083-.594-.29-.25-.568-.625-.568-1.118V7.545c0-.492.277-.867.568-1.117.293-.252.675-.446 1.083-.595l.157-.054a7.587 7.587 0 0 1 1.252-.293Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        d="M6.689 11.182a.144.144 0 0 0-.144.143V13c0 .492.277.868.568 1.118.293.252.675.446 1.083.594.822.3 1.91.47 3.077.47 1.166 0 2.254-.17 3.077-.47.408-.148.79-.342 1.082-.594.291-.25.568-.626.568-1.118v-1.675a.144.144 0 0 0-.144-.143.151.151 0 0 0-.122.064 1.88 1.88 0 0 1-.302.326c-.293.252-.674.446-1.082.595-.823.299-1.91.47-3.077.47s-2.255-.171-3.077-.47c-.408-.149-.79-.343-1.083-.595a1.88 1.88 0 0 1-.302-.326.152.152 0 0 0-.122-.064Z"
      />
    </>
  ),
});
