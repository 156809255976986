import { createIcon } from '@chakra-ui/icons';

export const LiveIcon = createIcon({
  displayName: 'LiveIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3 1a3 3 0 0 0-3 3v8a3 3 0 0 0 3 3h10a3 3 0 0 0 3-3V4a3 3 0 0 0-3-3H3Zm2.24 5.206a1 1 0 0 0-1.417-1.412 4.545 4.545 0 0 0 0 6.412A1 1 0 0 0 5.24 9.794a2.545 2.545 0 0 1 0-3.588Zm6.937-1.412a1 1 0 1 0-1.417 1.412c.987.99.987 2.598 0 3.588a1 1 0 0 0 1.417 1.412 4.545 4.545 0 0 0 0-6.412ZM7.827 8c0-.102.08-.178.173-.178.092 0 .174.076.174.178A.176.176 0 0 1 8 8.179.176.176 0 0 1 7.826 8ZM8 5.822A2.176 2.176 0 0 0 5.826 8c0 1.2.97 2.178 2.174 2.178A2.176 2.176 0 0 0 10.174 8c0-1.2-.97-2.178-2.174-2.178Z"
      clipRule="evenodd"
    />
  ),
});
