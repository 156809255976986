import { PreviewSlider } from 'components/preview-slider';
import { TournamentIcon } from 'icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getTournamentsIsLoaded,
  getTournamentsList,
  loadTournaments,
} from 'services/games/modules/tournaments';
import { useActions } from 'store';
import { TournamentPreview } from '../tournament-preview';

export const TournamentsSlider = ({
  currentFeType,
}: {
  currentFeType: string;
}) => {
  const { t } = useTranslation();

  const list = useSelector(getTournamentsList);
  const isLoaded = useSelector(getTournamentsIsLoaded);
  const actions = useActions({
    loadTournaments,
  });

  useEffect(() => {
    if (!isLoaded) {
      actions.loadTournaments();
    }
  }, [actions, isLoaded]);

  // Filter out the current tournament and the Down But Not Out tournament
  const filteredList = list.filter(
    tournament =>
      tournament.feType !== currentFeType &&
      tournament.feType !== 'DOWNBUTNOTOUT',
  );

  return (
    <PreviewSlider
      icon={<TournamentIcon color="candy-floss-text" />}
      title={t('tournaments.more-tournaments')}
      titleLink="/tournaments"
      slides={filteredList}
      isLoaded={isLoaded}
      isIgnoreGap
      SlideComponent={TournamentPreview}
      gridSize="1-3"
    />
  );
};
