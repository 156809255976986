import { JackpotType } from './types';

type StateType = {
  jackpot: JackpotType;
};

export const getJackpot = (state: StateType) => state.jackpot;

export const getJackpotPlaceBetLoading = (state: StateType) =>
  getJackpot(state).placeBetLoading;

export const getJackpotGames = (state: StateType) => getJackpot(state).games;
