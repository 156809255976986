import {
  Box,
  Button,
  Flex,
  Heading,
  IconButton,
  Image,
  Text,
} from '@chakra-ui/react';
import { Content } from 'components/content';
import { PreviewSlider } from 'components/preview-slider';
import { Seo } from 'components/seo';
import dayjs from 'dayjs';
import { imageBuilder } from 'helpers/sanity';
import { t } from 'i18next';
import { BlogIcon, TwitterIcon } from 'icons';
import shuffle from 'lodash.shuffle';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Navigate, useParams } from 'react-router-dom';
import { BlogPreview } from 'services/cms/components/blog-preview';
import { HtmlContent } from 'services/cms/components/html-content';
import {
  getBlogIsLoaded,
  getBlogPost,
  getBlogPosts,
  loadPost,
  loadPosts,
} from 'services/cms/modules/blog';
import { useActions } from 'store';
import { ImageFallback } from 'uikit/image-fallback';
import { Link } from 'uikit/link';
import { Skeleton } from 'uikit/skeleton';
import { SkeletonList } from 'uikit/skeleton-list';

export const BlogPost = () => {
  const isLoaded = useSelector(getBlogIsLoaded);
  const { slug } = useParams();
  const post = useSelector(getBlogPost(slug));
  const actions = useActions({ loadPost, loadPosts });
  const posts = useSelector(getBlogPosts);

  useEffect(() => {
    if (!post) {
      actions.loadPost(slug, 'en');
    }
  }, [slug, actions, post]);

  useEffect(() => {
    if (!isLoaded) {
      actions.loadPosts('en');
    }
  }, [actions, isLoaded]);

  const randomPosts = useMemo(
    () =>
      shuffle(
        posts.filter(
          p =>
            p.slug !== slug &&
            p.mainImage &&
            (!p.endAt || new Date(p.endAt) > new Date()),
        ),
      ).slice(0, 6),
    [slug, posts],
  );

  const isPostLoaded = post !== undefined;

  if (post === null) return <Navigate to="/" />;

  return (
    <>
      {isPostLoaded && (
        <Seo
          title={post.title}
          image={
            post.mainImage
              ? `${imageBuilder.image(post.mainImage)}?q=80&auto=format&w=1200`
              : undefined
          }
        />
      )}
      <Content w="full" flexGrow="1">
        <Box w="full" borderBottom="1px solid" borderColor="truffle-border">
          <Flex
            flexDir="column"
            justifyContent="end"
            p="5"
            maxW="6xl"
            w="full"
            mx="auto"
            mt="auto"
            h="full"
          >
            <Flex
              justifyContent="space-between"
              gap="5"
              align={{ base: 'start', md: 'end' }}
              flexDirection={{ base: 'column', md: 'row' }}
            >
              <Box>
                <Skeleton isLoaded={isPostLoaded} maxW="sm">
                  <Heading fontWeight="500" fontSize="xl">
                    {post?.title || '|'}
                  </Heading>
                </Skeleton>
                <Skeleton isLoaded={isPostLoaded} mt="1" maxW="sm">
                  <Text mt="1" color="candy-floss-text" maxW="sm" noOfLines="1">
                    Posted{' '}
                    {post?.publishedAt
                      ? dayjs(post.publishedAt * 1000).fromNow()
                      : 'a while ago'}
                  </Text>
                </Skeleton>
              </Box>
              <Flex align="center" gap="2">
                {post?.button && (
                  <Button colorScheme="purple" as={Link} to={post.button.link}>
                    {t(post.button.title, post.button.title)}
                  </Button>
                )}
                <Link
                  rounded="full"
                  href={`https://x.com/intent/tweet?text=Have%20you%20seen%20the%20@Sherbet%20${encodeURIComponent(
                    post?.title,
                  )}%20blog%20post%3F%0A%0A${encodeURIComponent(
                    `https://sherbet.com/blog/${slug}`,
                  )}`}
                  isExternal
                >
                  <IconButton rounded="full" icon={<TwitterIcon />} />
                </Link>
              </Flex>
            </Flex>
          </Flex>
        </Box>
        <Box p="5" maxW="6xl" w="full" mx="auto">
          <Flex
            gap={{ base: '5', md: '14' }}
            align="start"
            flexDir={{ base: 'column-reverse', md: 'row' }}
          >
            <Box w="full" mx="auto">
              {isPostLoaded ? (
                post.body && <HtmlContent content={post.body} />
              ) : (
                <Flex direction="column" gap="2">
                  <SkeletonList>
                    <Skeleton h="20px" />
                  </SkeletonList>
                </Flex>
              )}
            </Box>
            <Box
              w="full"
              mx="auto"
              top="calc(var(--header-height) + 20px)"
              position={{ md: 'sticky' }}
            >
              {isPostLoaded ? (
                post.mainImage && (
                  <Image
                    draggable="false"
                    src={`${imageBuilder.image(
                      post.mainImage,
                    )}?q=80&auto=format&w=1200`}
                    mx="auto"
                    rounded="xl"
                    alt={post.title}
                    fallback={<ImageFallback type="post" rounded="xl" />}
                  />
                )
              ) : (
                <ImageFallback type="post" rounded="xl" maxH="400px" />
              )}
            </Box>
          </Flex>
        </Box>

        {isPostLoaded && randomPosts.length > 0 && (
          <Box borderTop="1px solid" borderColor="truffle-border">
            <Flex
              p="5"
              w="full"
              maxW="6xl"
              mx="auto"
              flexGrow="1"
              gap="7"
              flexDir="column"
            >
              <PreviewSlider
                titleLink="/blog"
                isLoaded={isLoaded}
                icon={<BlogIcon color="candy-floss-text" />}
                title={t('blog.more-blog-posts')}
                slides={randomPosts.map(item => ({
                  ...item,
                  prefix: '/blog',
                }))}
                SlideComponent={BlogPreview}
                gridSize="1-3"
              />
            </Flex>
          </Box>
        )}
      </Content>
    </>
  );
};
