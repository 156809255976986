import { Box } from '@chakra-ui/react';
// @ts-ignore
import AnimatedNumber from 'react-animated-numbers';
import styles from './style.module.scss';

type Props = {
  value: number;
};

export const Odometer: React.FC<Props> = ({ value }) => (
  <Box display="inline-flex" className={styles.number}>
    <AnimatedNumber
      animateToNumber={value}
      includeComma
      config={{ tension: 89, friction: 40 }}
      animationType="calm"
    />
  </Box>
);
