import { Box, Button, Divider, Flex, Image, Text } from '@chakra-ui/react';
import { Content } from 'components/content';
import { Loading } from 'components/loading';
import { formatCredit } from 'helpers/numeral';
import { NFTIcon } from 'icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { formatAddress, getNftUrl } from 'services/nft/helpers';
import { getNftDetails, loadNFTDetails } from 'services/nft/modules/nft';

import { useActions } from 'store';
import { EmptyResults } from 'uikit/empty-results';
import { ImageFallback } from 'uikit/image-fallback';
import { Link } from 'uikit/link';
import { NotificationTip } from 'uikit/notification-tip';
import { Tabs } from 'uikit/tabs';
import { MoreNFTsSlider } from './more-nfts-slider';

type Props = {
  onClose: () => void;
  contentProps: {
    nftId: number;
  };
};

export const NftDetails: React.FC<Props> = ({
  onClose,
  contentProps: { nftId },
}) => {
  const actions = useActions({ loadNFTDetails });
  const nft = useSelector(getNftDetails(nftId));
  const { t } = useTranslation();

  useEffect(() => {
    if (!nft) {
      actions.loadNFTDetails(nftId);
    }
  }, [actions, nftId, nft]);

  if (!nft) {
    return <Loading />;
  }

  const lastSale = nft.sales?.nftSales?.[0];

  const buyerAddress = lastSale?.buyerAddress;

  // getting the last sale price of the NFT by adding the seller fee, protocol fee, and royalty fee using the getLastSalePrice function that adds the fees and converts the last sale price from wei to ether
  const { lastSalePrice } = nft;
  const floorPrice = nft.metadata?.contractMetadata?.openSea?.floorPrice;

  let priceChange;

  if (lastSalePrice && floorPrice) {
    priceChange = (lastSalePrice / floorPrice) * 100 - 100;
  }

  // const fields = [
  //   { key: 'block', title: 'Block', textAlign: 'left' },
  //   { key: 'hash', title: 'Hash', textAlign: 'left' },
  //   { key: 'action', title: 'Action', textAlign: 'left' },
  //   { key: 'price', title: 'Price', textAlign: 'left' },
  // ];

  // const activityData = nft.sales.map(sale => ({
  //   block: sale.blockNumber,
  //   price:
  //     getLastSalePrice(sale.sellerFee, sale.protocolFee, sale.royaltyFee) ||
  //     '-',
  //   hash: formatAddress(sale.transactionHash),
  //   action: sale.taker,
  // }));

  const collectionName = nft.collection?.name;
  const isSherbetNft = true;

  return (
    <Box w="full">
      <Tabs
        index={0}
        tabs={[
          {
            text: t('profile.overview'),
          },
        ].map(({ text }) => ({
          children: text,
          Icon: NFTIcon,
        }))}
        onClose={onClose}
        hasClose
      />
      <Box>
        {nft ? (
          <Box>
            <Content maxW="6xl" p="5" mx="auto" flexGrow="1">
              <Flex flexDirection="column">
                <Flex
                  gap={{ base: '5', xl: '14' }}
                  align="start"
                  flexDir={{ base: 'column-reverse', xl: 'row' }}
                >
                  <Box maxW={{ base: 'full', xl: 'sm' }}>
                    <Image
                      src={nft.imageUrl}
                      fallback={<ImageFallback rounded="xl" />}
                      alt={`${collectionName} #${nft.id}`}
                      objectFit="cover"
                      w="full"
                      rounded="xl"
                      draggable="false"
                    />
                    <Flex align="start" justifyContent="space-between" mt="5">
                      <Box>
                        <Text fontWeight="500" noOfLines={1} fontSize="xl">
                          {nft.metadata?.title}
                        </Text>
                        <Text color="candy-floss-text" noOfLines={2}>
                          {nft.metadata?.description}
                        </Text>
                      </Box>
                    </Flex>
                    <Box my="2">
                      <Flex align="center" gap="2" overflowX="scroll">
                        {nft.metadata?.metadata.attributes
                          .filter(att => !att.display_type)
                          .map(({ trait_type: traitType, value }) => (
                            <NotificationTip
                              bg="sugar-dust"
                              color="candy-floss-text"
                              notifications={value}
                            />
                          ))}
                      </Flex>
                    </Box>
                    {isSherbetNft && (
                      <Box mt="5">
                        <Flex
                          mb="1"
                          align="center"
                          justifyContent="space-between"
                        >
                          <Text fontWeight="500" fontSize="xl">
                            {t('nft.boosts')}:
                          </Text>
                        </Flex>
                        <Flex flexDirection="column" gap="1">
                          <Flex
                            align="center"
                            justifyContent="space-between"
                            gap="5"
                          >
                            <Text color="candy-floss-text">
                              {t('nft.rakeback-boost')}:
                            </Text>
                            <Text fontWeight="500">
                              {`${formatCredit(nft.rakebackPercent * 100)}%`}
                            </Text>
                          </Flex>
                        </Flex>
                      </Box>
                    )}
                    <Box mt="5">
                      <Flex
                        mb="1"
                        align="center"
                        justifyContent="space-between"
                      >
                        <Text fontWeight="500" fontSize="xl">
                          {t('nft.details')}
                        </Text>
                      </Flex>
                      <Flex flexDirection="column" gap="1">
                        {nft.owner ? (
                          <Flex
                            align="center"
                            gap="5"
                            justifyContent="space-between"
                          >
                            <Text color="candy-floss-text">
                              {t('nft.owner')}:
                            </Text>
                            <Flex align="center" gap="1">
                              <Link
                                _hover={{ opacity: '0.9' }}
                                fontWeight="500"
                                to={`?modal=profile&username=${nft.owner.name}`}
                              >
                                {nft.owner.name}
                              </Link>
                            </Flex>
                          </Flex>
                        ) : (
                          buyerAddress && (
                            <Flex
                              align="center"
                              gap="5"
                              justifyContent="space-between"
                            >
                              <Text color="candy-floss-text">
                                {t('nft.owner')}:
                              </Text>
                              <Flex align="center" gap="1">
                                <Link
                                  _hover={{ opacity: '0.9' }}
                                  fontWeight="500"
                                  href={`https://etherscan.io/address/${buyerAddress}`}
                                >
                                  {formatAddress(buyerAddress)}
                                </Link>
                              </Flex>
                            </Flex>
                          )
                        )}
                        <Flex
                          align="center"
                          justifyContent="space-between"
                          gap="5"
                        >
                          <Text color="candy-floss-text">
                            {t('nft.supply')}:
                          </Text>
                          <Text fontWeight="500">
                            {formatCredit(
                              nft.collection?.attributes?.totalSupply || 0,
                            )}
                          </Text>
                        </Flex>
                        <Flex
                          align="center"
                          justifyContent="space-between"
                          gap="5"
                        >
                          <Text color="candy-floss-text">
                            {t('nft.network')}:
                          </Text>
                          <Text fontWeight="500">
                            {nft.metadata?.contractMetadata?.tokenType}
                          </Text>
                        </Flex>
                        <Flex
                          align="center"
                          justifyContent="space-between"
                          gap="5"
                        >
                          <Text color="candy-floss-text">
                            {t('nft.contract-address')}:
                          </Text>
                          <Link
                            _hover={{ opacity: '0.9' }}
                            href={`https://etherscan.io/address/${nft.collection?.contractAddress}`}
                            fontWeight="500"
                          >
                            {formatAddress(nft.collection?.contractAddress)}
                          </Link>
                        </Flex>
                      </Flex>
                    </Box>
                  </Box>
                  <Box w="full">
                    <Flex flexDirection="column" gap="5">
                      <Link
                        color="candy-floss-text"
                        _hover={{
                          opacity: '0.9',
                        }}
                        to="/nft/collections/0xa54e6b99f58b6165228abcdc681e9a16bcefdbe1"
                      >
                        <Box>
                          <Image
                            roundedTop="xl"
                            w="full"
                            objectFit="cover"
                            h="100px"
                            marginBottom="-40px"
                            src="https://i.seadn.io/gcs/files/900d0572642b3d115d62721ffdff4aae.png?auto=format&dpr=1&w=3840"
                            alt="Banner"
                            draggable="false"
                          />
                          <Box px="5" bg="sugar-dust" pb="5" roundedBottom="xl">
                            <Image
                              position="relative"
                              border="4px solid"
                              borderColor="burnt-sugar"
                              w="5rem"
                              rounded="full"
                              height="5rem"
                              src="https://i.seadn.io/gcs/files/4d33825adedcd80ae3eb513ea71b91f4.gif?auto=format&dpr=1&w=384"
                              alt="Sherbet Logo"
                              draggable="false"
                            />
                            <Flex align="center" mt="2">
                              <Text fontWeight="500" fontSize="xl">
                                {collectionName}
                              </Text>
                            </Flex>
                            <Text color="candy-floss-text" noOfLines={2}>
                              {
                                nft.metadata?.contractMetadata?.openSea
                                  ?.description
                              }
                            </Text>
                          </Box>
                        </Box>
                      </Link>
                      <Box p="5" rounded="xl" bg="sugar-dust">
                        {!!lastSalePrice && (
                          <Box mb="3">
                            <NotificationTip
                              bg="sugar-dust"
                              color="candy-floss-text"
                              notifications={t('nft.last-sale')}
                              mb="2"
                            />
                            <Flex gap="2" align="center">
                              <Text
                                fontSize="xl"
                                fontWeight="500"
                                color="vanilla-text"
                              >
                                {lastSalePrice} ETH
                              </Text>
                            </Flex>
                            <Text color="candy-floss-text">
                              {priceChange && priceChange > 0
                                ? t('nft.above')
                                : t('nft.below')}{' '}
                              {priceChange &&
                                formatCredit(Math.abs(priceChange))}
                              %{' '}
                            </Text>
                          </Box>
                        )}
                        <Flex align="center" gap="2">
                          <Button
                            rounded="full !important"
                            as={Link}
                            href={getNftUrl(nft.id)}
                            colorScheme="purple"
                            size={{ base: 'md', lg: 'sm' }}
                          >
                            {t('nft.buy-now')}
                          </Button>

                          {nft.owner && (
                            <Button
                              rounded="full !important"
                              as={Link}
                              to={`?modal=profile&username=${nft.owner.name}`}
                              size={{ base: 'md', lg: 'sm' }}
                            >
                              {t('nft.message-owner')}
                            </Button>
                          )}
                        </Flex>
                      </Box>
                    </Flex>
                  </Box>
                </Flex>
              </Flex>
            </Content>
            <Divider
              borderBottom="1px"
              opacity="1"
              borderColor="truffle-border"
            />
            <Content maxW="6xl" p="5" mx="auto" flexGrow="1">
              <MoreNFTsSlider collectionName={collectionName} />
            </Content>
          </Box>
        ) : (
          <EmptyResults
            icon={NFTIcon}
            title="search.no-results-title"
            description="search.no-results-description"
          />
        )}
      </Box>
    </Box>
  );
};
