import { createSelector } from '@reduxjs/toolkit';
import { SLOTS_HANDLER_ID } from 'constants/general';
import shuffle from 'lodash.shuffle';
import uniq from 'lodash.uniq';
import { getUserCountry } from 'modules/handler';
import { getQuests } from 'services/games/modules/quests';
import { GameType } from 'types';
import { QuestsType } from '../quests/types';
import { HANDLERS_ORIGIN_GAMES } from './constants';
import { SlotsType, SlotsUsersType } from './types';

type StateType = {
  slots: SlotsType;
  quests: QuestsType;
};

export const getSlots = (state: StateType) => state.slots;

export const getSlotsIsLoaded = (state: StateType) => state.slots.isLoaded;
export const getSlotsGamesIds = (state: StateType) => state.slots.games.ids;
export const getSlotsGamesData = (state: StateType) => state.slots.games.data;
export const getSlotsRecentGames = (state: StateType) =>
  state.slots.recentGames;
export const getSlotsTopGames = (state: StateType) => state.slots.topGames;
export const getSlotsFavoritesGames = (state: StateType) =>
  state.slots.favorites;
export const getSlotsIsFavoritesLoaded = (state: StateType) =>
  state.slots.isFavoritesLoaded;
export const getSlotsIsRecentLoaded = (state: StateType) =>
  state.slots.isRecentLoaded;
export const getSlotsIsTopLoaded = (state: StateType) =>
  state.slots.isTopLoaded;
export const getSlotsLiveCasinoIds = (state: StateType) =>
  state.slots.liveCasinoIds;
export const getSlotsSherbetOriginalsIds = (state: StateType) =>
  state.slots.sherbetOriginalsIds;
export const getSlotsFeatureBuyInIds = (state: StateType) =>
  state.slots.featureBuyInIds;
export const getSlotsMegawaysIds = (state: StateType) =>
  state.slots.megawaysIds;
export const getSlotsDropsAndWinsIds = (state: StateType) =>
  state.slots.dropsAndWinsIds;
export const getSlotsGameShowsIds = (state: StateType) =>
  state.slots.gameShowsIds;
export const getSlotsTableIds = (state: StateType) => state.slots.tableIds;
export const getSlotsCasinoIds = (state: StateType) => state.slots.slotsIds;
export const getSlotsNewReleaseIds = (state: StateType) =>
  state.slots.newReleaseIds;
export const getSlotsCominSoonIds = (state: StateType) =>
  state.slots.comingSoonIds;
export const getSlotsGamesCountByProvider = (state: StateType) =>
  state.slots.gamesCountByProvider;
export const getSlotsCategories = (state: StateType) => state.slots.categories;
export const getSlotsGame = (id: string) => (state: StateType) =>
  getSlotsGamesData(state)[id];

export const getSlotsRecommendationsUserLobby = (state: StateType) =>
  state.slots.recommendationsUserLobby;

export const getSlotsRecommendationsSimilarIds = (state: StateType) =>
  state.slots.recommendationsSimilar.ids;

export const getSlotsRecommendationsSimilarLoaded = (state: StateType) =>
  state.slots.recommendationsSimilar.isLoaded;

export const getSlotsRecommendationsUserLobbyIds = (state: StateType) =>
  getSlotsRecommendationsUserLobby(state).ids;

export const getSlotsIsFavoriteGame =
  (gameType: string) => (state: StateType) =>
    getSlotsFavoritesGames(state).some(g => g.gameType === gameType);

const prepareGames = (
  ids: string[],
  data: { [key: string]: GameType },
  userCountry: string,
) => ids.map(id => data[id]);
// .filter(game => !game.disabledCountries?.includes?.(userCountry));

export const getSlotsGamesList = createSelector(
  getSlotsGamesIds,
  getSlotsGamesData,
  getUserCountry,
  prepareGames,
);

export const getSlotsLiveCasinoGames = createSelector(
  getSlotsLiveCasinoIds,
  getSlotsGamesData,
  getUserCountry,
  (...args) => prepareGames(...args).slice(0, 10),
);

export const getSlotsSherbetOriginalsGames = createSelector(
  getSlotsSherbetOriginalsIds,
  getSlotsGamesData,
  getUserCountry,
  (...args) => prepareGames(...args).slice(0, 10),
);

export const getSlotsFeatureBuyInGames = createSelector(
  getSlotsFeatureBuyInIds,
  getSlotsGamesData,
  getUserCountry,
  (...args) => prepareGames(...args).slice(0, 10),
);

export const getSlotsMegawaysGames = createSelector(
  getSlotsMegawaysIds,
  getSlotsGamesData,
  getUserCountry,
  (...args) => prepareGames(...args).slice(0, 10),
);

export const getSlotsDropsAndWinsGames = createSelector(
  getSlotsDropsAndWinsIds,
  getSlotsGamesData,
  getUserCountry,
  (...args) => prepareGames(...args).slice(0, 10),
);

export const getSlotsGameShowsGames = createSelector(
  getSlotsGameShowsIds,
  getSlotsGamesData,
  getUserCountry,
  (...args) => prepareGames(...args).slice(0, 10),
);

export const getSlotsTableGames = createSelector(
  getSlotsTableIds,
  getSlotsGamesData,
  getUserCountry,
  (...args) => prepareGames(...args).slice(0, 10),
);

export const getSlotsCasinoGames = createSelector(
  getSlotsCasinoIds,
  getSlotsGamesData,
  getUserCountry,
  (...args) => prepareGames(...args).slice(0, 10),
);

export const getSlotsNewReleaseGames = createSelector(
  getSlotsNewReleaseIds,
  getSlotsGamesData,
  getUserCountry,
  (...args) =>
    prepareGames(...args)
      .sort((a, b) => {
        // Sort featured games first
        if (a.featured && !b.featured) return -1;
        if (!a.featured && b.featured) return 1;
        // Then sort by release date
        return Number(b.releasedOn || 0) - Number(a.releasedOn || 0);
      })
      .slice(0, 10),
);

export const getSlotsComingSoonGames = createSelector(
  getSlotsCominSoonIds,
  getSlotsGamesData,
  getUserCountry,
  (...args) => prepareGames(...args).slice(0, 10),
);

const createDisplayedCountryFilter =
  (userCountry: string) => (game: GameType) =>
    game?.displayed; // && !game.disabledCountries?.includes?.(userCountry);

export const getSlotsRecentGamesList = createSelector(
  getSlotsRecentGames,
  getSlotsGamesData,
  getUserCountry,
  (ids, data, userCountry) =>
    ids.map(id => data[id]).filter(createDisplayedCountryFilter(userCountry)),
);

export const getSlotsRecommendationsUserLobbyList = createSelector(
  getSlotsRecommendationsUserLobbyIds,
  getSlotsGamesData,
  getUserCountry,
  (ids, data, userCountry) =>
    ids
      .map(id => data[id])
      .filter(createDisplayedCountryFilter(userCountry))
      .slice(0, 10),
);

export const getSlotsRecommendationsSimilarList = createSelector(
  getSlotsRecommendationsSimilarIds,
  getSlotsGamesData,
  getUserCountry,
  (ids, data, userCountry) =>
    ids
      .map(id => data[id])
      .filter(createDisplayedCountryFilter(userCountry))
      .slice(0, 10),
);

export const getSlotsTopGamesList = createSelector(
  getSlotsTopGames,
  getSlotsGamesData,
  getUserCountry,
  (ids, data, userCountry) =>
    ids.map(id => data[id]).filter(createDisplayedCountryFilter(userCountry)),
);

export const getSlotsFavoritesGamesList = createSelector(
  getSlotsFavoritesGames,
  getSlotsGamesData,
  getUserCountry,
  (ids, data, userCountry) =>
    ids
      .map(({ gameType }) => data[gameType])
      .filter(createDisplayedCountryFilter(userCountry)),
);

export const getSlotsIsPopularLoaded = (state: StateType) =>
  state.slots.isPopularLoaded;
export const getSlotsPopular = (state: StateType) => state.slots.popular;

const getUniqGames = (
  list: SlotsUsersType,
  games: { [key: string]: GameType },
  userCountry: string,
) =>
  uniq(
    list.map(({ gameSubType, handlerId, users }) => ({
      game:
        gameSubType ||
        HANDLERS_ORIGIN_GAMES[handlerId as keyof typeof HANDLERS_ORIGIN_GAMES],
      users,
    })),
  )
    .map(({ game, users }) => {
      const item = games[game];

      if (item) {
        return {
          ...item,
          users,
        };
      }

      return item;
    })
    .filter(game => game);
// .filter(
//   game =>
//     game?.displayed && !game.disabledCountries?.includes?.(userCountry),
// );

export const getSlotsPopularGames = createSelector(
  getSlotsPopular,
  getSlotsGamesData,
  getUserCountry,
  (popular, games, userCountry) =>
    getUniqGames(popular, games, userCountry).slice(0, 10),
);

export const getSlotsIsFriendsPopularLoaded = (state: StateType) =>
  state.slots.isFriendsPopularLoaded;
export const getSlotsFriendsPopular = (state: StateType) =>
  state.slots.friendsPopular;
export const getSlotsFriendsPopularGames = createSelector(
  getSlotsFriendsPopular,
  getSlotsGamesData,
  getUserCountry,
  getUniqGames,
);

export const getSlotsFavoritesGamesIds = createSelector(
  getSlotsFriendsPopularGames,
  games => games.map(({ id }) => id),
);

export const getSlotsSliderCategories = createSelector(
  getSlotsCategories,
  categories => shuffle(categories).slice(0, 10),
);

export const getCasinoRandomGames = createSelector(
  getSlotsGamesIds,
  getSlotsGamesData,
  getUserCountry,
  (ids, data, userCountry) =>
    prepareGames(shuffle(ids), data, userCountry).slice(0, 10),
);

export const getSlotsGameQuests = (id: string, handlerId: number) =>
  createSelector(getQuests, quests =>
    quests.filter(c =>
      handlerId === SLOTS_HANDLER_ID
        ? c.subGameType === id
        : c.handler_Id === handlerId,
    ),
  );

export const getSlotsPlaylists = createSelector(
  state => state.slots.playlists,
  getSlotsGamesData,
  (playlists, games) =>
    Object.keys(playlists).reduce(
      (acc, key) =>
        Object.assign(acc, {
          [key]: {
            ...playlists[key],
            playlistGames: playlists[key].ids.map((id: string) => games[id]),
          },
        }),
      {},
    ),
);

export const getSlotsGameSettings = (id: string) => (state: StateType) =>
  state.slots.gameSettings[id];

export const getSlotsGameSettingsIsLoaded = (state: StateType) =>
  state.slots.gameSettingsIsLoaded;
