import { createIcon } from '@chakra-ui/icons';

export const ResponsibleGamblingIcon = createIcon({
  displayName: 'ResponsibleGamblingIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      d="M2.33333 14.5333H2.66667V15.2C2.66667 15.5333 2.93333 15.8667 3.33333 15.8667H5.46667C5.86667 15.8667 6.13333 15.6 6.13333 15.2V14.5333H10V15.2C10 15.5333 10.2667 15.8667 10.6667 15.8667H12.8C13.1333 15.8667 13.4667 15.6 13.4667 15.2V14.5333H13.6667C14.9333 14.5333 16 13.4667 16 12.2V2.33333C16 1.06667 14.9333 0 13.6667 0H2.33333C1.06667 0 0 1.06667 0 2.33333V12.1333C0 13.4667 1.06667 14.5333 2.33333 14.5333ZM8 4.06667C9 4.06667 9.8 4.86667 9.8 5.86667C9.8 6.6 9.4 7.2 8.8 7.46667V8.86667C8.8 9.33333 8.46667 9.66667 8 9.66667C7.53333 9.66667 7.2 9.33333 7.2 8.86667V7.46667C6.6 7.13333 6.2 6.53333 6.2 5.86667C6.2 4.86667 7 4.06667 8 4.06667Z"
      fill="currentColor"
    />
  ),
});
