import { createAction } from 'store';
import * as api from './api';

export const loadHandlers = createAction(
  'handler/loadHandlers',
  () => () => api.loadHandlers(),
  () => ({ _error: true }),
);

export const loadUserCountry = createAction(
  'handler/loadUserCountry',
  () => () => api.loadUserCountry(),
);
