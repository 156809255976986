import { createSelector } from '@reduxjs/toolkit';
import uniqBy from 'lodash.uniqby';
import { BlogType } from './types';

type StateType = {
  blog: BlogType;
};

export const getBlogIsLoaded = (state: StateType) => state.blog.isLoaded;

export const getBlogPosts = (state: StateType) => state.blog.posts;

export const getBlogPost = (slug: string) => (state: StateType) =>
  state.blog.data[slug];

export const getBlogCategories = createSelector(getBlogPosts, posts =>
  uniqBy(
    posts.reduce((acc, post) => acc.concat(post.tags || []), [] as string[]),
    'name',
  ).filter(i => i),
);
