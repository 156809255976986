import { Flex, FlexProps } from '@chakra-ui/react';
import { PreloaderIcon } from 'icons';

import styles from './style.module.scss';

type Props = FlexProps & {
  color?: string;
};

export const Loading: React.FC<Props> = ({
  color = 'vanilla-text',
  ...props
}) => (
  <Flex
    justifyContent="center"
    alignItems="center"
    zIndex="10"
    position="absolute"
    left="0"
    top="0"
    h="full"
    right="0"
    bottom="0"
    {...props}
  >
    <Flex className={styles.loader}>
      <PreloaderIcon
        width="full"
        height="40px"
        color={color}
        overflow="visible"
      />
    </Flex>
  </Flex>
);
