import { Flex, FlexProps } from '@chakra-ui/react';

type Props = FlexProps & {
  notifications?: React.ReactNode;
  isSuccess?: boolean;
};

export const NotificationTip: React.FC<Props> = ({
  notifications,
  isSuccess,
  ...props
}) =>
  notifications ? (
    <Flex
      justifyContent="center"
      alignItems="center"
      whiteSpace="nowrap"
      flexShrink="0"
      display="inline-flex"
      align="center"
      gap="1"
      zIndex="1"
      color={isSuccess ? '#48C054' : 'candy-floss-text'}
      bg={isSuccess ? '#48C0541A' : 'sugar-dust'}
      borderRadius="4px"
      maxW="full"
      className="noti-tip"
      padding="0 0.5em"
      minW="1.8em"
      rounded="4px"
      lineHeight="1.5"
      fontWeight="500"
      fontSize="sm"
      {...props}
    >
      {notifications}
    </Flex>
  ) : null;
