import { useToastError } from 'hooks/use-toast-error';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useBonuses } from 'services/user/hooks/use-bonuses';
import { useActions } from 'store';

import { Box, Flex, Switch, Text } from '@chakra-ui/react';
import { InfoIcon } from 'icons';
import {
  activateBonus,
  deactivateBonus,
  loadUser,
} from 'services/user/modules/user';
import { BonusType, Errors } from 'types';
import { Link } from 'uikit/link';

export const DepositBonusCard = () => {
  const { t } = useTranslation();

  const data = useBonuses();
  const bonus = data.find(
    (item: BonusType) => item.bonusType === 'Welcome Deposit Bonus',
  ) as BonusType;
  const isSwitchEnabled = bonus && bonus.status === 1;
  const [isLoading, setIsLoading] = useState(false);

  const actions = useActions({
    activateBonus,
    deactivateBonus,
    loadUser,
  });

  const toastErrorCommon = useToastError();
  // const details = useSelector(getUserAffiliateCampaignDetails);

  const setIsSwitchEnabled = async (state: boolean) => {
    setIsLoading(true);
    try {
      if (state) {
        if (!isSwitchEnabled) {
          await actions.activateBonus({
            bonusBalanceId: bonus.id,
            isDepositReload: bonus.isDepositReload,
          });
          await actions.loadUser();
        }
      } else if (isSwitchEnabled) {
        await actions.deactivateBonus({
          bonusBalanceId: bonus.id,
          isDepositReload: bonus.isDepositReload,
        });
        await actions.loadUser();
      }
    } catch (errors) {
      toastErrorCommon(errors as Errors);
    }
    setIsLoading(false);
  };

  // const status = bonus.subLevel + 1; // frist/second/third bonus

  // const getMaxBonus = number => {
  //   const item = details.depositReloadsPackage[number - 1];
  //   return item.reloadsMax * item.reloadsPercent;
  // };

  // const maxBonus = getMaxBonus(status);

  // const getReloadPercent = number => {
  //   const item = details.depositReloadsPackage[number - 1];
  //   return item.reloadsPercent * 100;
  // };

  // const reloadPercent = getReloadPercent(status);

  return (
    <Flex
      rounded="xl"
      alignItems="center"
      p="3"
      px="4"
      w="full"
      justifyContent="space-between"
      position="relative"
      bg={isSwitchEnabled ? 'sherbet-green.100' : 'none'}
      border="1px dashed"
      borderColor={isSwitchEnabled ? 'sherbet-green.200' : 'truffle-border'}
      align="start"
      gap="3"
    >
      <Box w="full">
        <Flex align="start" gap="5" w="full" justifyContent="space-between">
          <Box>
            <Flex align="center" gap="2">
              <Text
                fontWeight="500"
                color={
                  isSwitchEnabled ? 'sherbet-green.200' : 'candy-floss-text'
                }
              >
                {t('bonus.welcome-deposit-bonus')}
              </Text>
              <Link _hover={{ opacity: 0.9 }} to="/promotions/welcome-package">
                <InfoIcon
                  opacity="0.5"
                  _hover={{ opacity: 1 }}
                  color={
                    isSwitchEnabled ? 'sherbet-green.200' : 'candy-floss-text'
                  }
                  cursor="pointer"
                />
              </Link>
            </Flex>
          </Box>
          <Flex align="center" gap="2">
            <Switch
              aria-label="Toggle Bonus"
              isDisabled={isLoading}
              isChecked={isSwitchEnabled}
              onChange={() => {
                if (isSwitchEnabled) {
                  setIsSwitchEnabled(false);
                } else {
                  setIsSwitchEnabled(true);
                }
              }}
            />
          </Flex>
        </Flex>
      </Box>
    </Flex>
  );
};
