import { Box, Button, Flex, FormControl } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { CustomInput } from 'forms/custom-input';
import { PasswordInput } from 'forms/password-input';
import { useCopy } from 'hooks/use-copy';
import { t } from 'i18next';
import { CopyIcon } from 'icons';
import { Suspense, lazy, useEffect, useState } from 'react';
import { get2faKeyUri } from 'services/auth/modules/auth';
import { enable2fa } from 'services/user/modules/user';
import { useActions } from 'store';
import { toastSuccess } from 'toasts';
import { Errors, FormSubmit } from 'types';
import { Skeleton } from 'uikit/skeleton';
import * as Yup from 'yup';

const QRCode = lazy(() =>
  import('react-qrcode-logo').then(module => ({ default: module.QRCode })),
);

export const TwoFactorForm = () => {
  const actions = useActions({ enable2fa, get2faKeyUri });
  const [isLoading, setIsLoading] = useState(true);
  const [qrCodeData, setQrCodeData] = useState<{
    sharedKey: string;
    authenticatorUri: string;
  } | null>(null);

  const { onCopy: onCopyTag } = useCopy(qrCodeData?.sharedKey ?? '', {
    description: t('settings.auth-code-copied'),
  });

  useEffect(() => {
    setIsLoading(true);
    actions
      .get2faKeyUri()
      .then(setQrCodeData)
      .finally(() => setIsLoading(false));
  }, [actions]);

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      code: '',
      password: '',
    },
    validationSchema: Yup.object({
      password: Yup.string().required('errors.password.required'),
      code: Yup.string().required('errors.required'),
    }),
    onSubmit: async ({ code, password }, { setErrors }) => {
      try {
        await actions.enable2fa(code, password);
        toastSuccess({
          title: t('2fa.added-title'),
        });
      } catch (errors) {
        setErrors(errors as Errors);
      }
    },
  });

  return (
    <FormControl as="form" onSubmit={formik.handleSubmit as FormSubmit}>
      <Flex direction="column">
        <Box display="inline-block">
          <Skeleton
            mb="3"
            rounded="xl"
            display="inline-block"
            isLoaded={!isLoading}
          >
            <Box
              rounded="xl"
              bg="vanilla-text"
              overflow="hidden"
              display="block"
              h="166px"
              w="166px"
            >
              <Suspense>
                <QRCode
                  value={qrCodeData?.authenticatorUri ?? ''}
                  size={150}
                  bgColor="rgba(252, 248, 248, 1)"
                  quietZone={8}
                  eyeRadius={8}
                  eyeColor="#7B54E9"
                  removeQrCodeBehindLogo
                  logoPadding={5}
                  logoImage="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNTkiIGhlaWdodD0iMTU5IiBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMTU5IDE1OSI+PHJlY3Qgd2lkdGg9IjE1OSIgaGVpZ2h0PSIxNTkiIGZpbGw9IiM3QjU0RTkiIHJ4PSIzMyIvPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik03OC44NTMgNzUuMDI5YTEuMzU1IDEuMzU1IDAgMCAwIC45MDEtMS41ODcgNDcuMjI0IDQ3LjIyNCAwIDAgMC0xLjA5OC00LjA1NGMtNC4yNDQtMTMuMDYtMTUuNjEyLTI0LjY1Ni0zMi40NTgtMTkuMzFDMzAuMDggNTUuMTkyIDI2LjU2OCA3MS40NiAzMC40NCA4My4zNzNjLjQxNiAxLjI4MiAxLjMwNyAzLjc0MyAyLjAzMyA1LjQzNWExLjM1IDEuMzUgMCAwIDAgMS42Ni43NDhsNDQuNzItMTQuNTI4Wk01MC4wMTkgNjEuNzI2YzguNzc4LTIuODUyIDExLjg2NyA2LjExMiAxMS44NjcgNi4xMTJMNDMuNDc4IDczLjgycy0yLjIzNi05LjI0MSA2LjU0Mi0xMi4wOTNoLS4wMDFabTc4LjIwMS0zLjMzMi0xMy41OTYtMS45MSAyLjEyOS0xNS4xNWExLjM1MyAxLjM1MyAwIDAgMC0xLjE1Mi0xLjUyOWwtMTIuNzUyLTEuNzkyYTEuMzU0IDEuMzU0IDAgMCAwLTEuNTI5IDEuMTUybC0yLjEzIDE1LjE1LTEzLjQ1LTEuODlhMS4zNTQgMS4zNTQgMCAwIDAtMS41MjggMS4xNTJsLTEuMzM1IDkuNDkzYTEuMzUzIDEuMzUzIDAgMCAwIDEuMTUyIDEuNTI5bDEzLjQ1IDEuODktMi4xMyAxNS4xNWExLjM1NCAxLjM1NCAwIDAgMCAxLjE1MiAxLjUyOWwxMi43NTMgMS43OTFhMS4zNTQgMS4zNTQgMCAwIDAgMS41MjktMS4xNTFsMi4xMjktMTUuMTUgMTMuNTk3IDEuOTFhMS4zNTIgMS4zNTIgMCAwIDAgMS41MjgtMS4xNTFsMS4zMzUtOS40OTRhMS4zNTMgMS4zNTMgMCAwIDAtMS4xNTItMS41MjhaTTc4LjI2NyAxMjBhNDQuMjEgNDQuMjEgMCAwIDEtMzUuNDg3LTE3Ljg3NCAxLjM1NSAxLjM1NSAwIDAgMSAuMzE4LTEuOTE5bDguMDY5LTUuNmExLjM1MiAxLjM1MiAwIDAgMSAxLjg0OS4yOTggMzEuNjgxIDMxLjY4MSAwIDAgMCAyNS4yNTEgMTIuNTc0IDMxLjY3NyAzMS42NzcgMCAwIDAgMjUuMjUxLTEyLjU3NCAxLjM1MyAxLjM1MyAwIDAgMSAxLjg1LS4yOTdsOC4wNjkgNS41OTljLjYyNy40MzUuNzczIDEuMzA2LjMxOCAxLjkxOUE0NC4yMSA0NC4yMSAwIDAgMSA3OC4yNjggMTIwWiIvPjwvc3ZnPg=="
                  logoWidth={42}
                />
              </Suspense>
            </Box>
          </Skeleton>
        </Box>
        <Flex gap="3" flexDirection="column" mb="5">
          <CustomInput
            onClick={onCopyTag}
            cursor="copy"
            _hover={{ opacity: '0.9' }}
            label={t('settings.auth-code')}
            placeholder={t('settings.auth-code')}
            name="code-2"
            type="text"
            wordBreak="break-all"
            color="vanilla-text"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={qrCodeData?.sharedKey || ''}
            isInvalid={!!formik.errors.code}
            errorMessage={formik.errors.code}
            readOnly
            boxProps={{ w: 'full' }}
            rightElement={<CopyIcon color="candy-floss-text" />}
          />
          <Flex gap="3" w="full" direction={{ base: 'column', lg: 'column' }}>
            <PasswordInput
              label={t('form.label.password')}
              placeholder={t('form.label.password')}
              name="password"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.password}
              isInvalid={!!formik.errors.password}
              errorMessage={formik.errors.password}
              size="md"
              boxProps={{ w: 'full' }}
            />
            <CustomInput
              label={t('auth-modal.login.field.two-factor-code')}
              placeholder={t('auth-modal.login.field.two-factor-code')}
              type="text"
              name="code"
              required
              inputMode="numeric"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.code}
              isInvalid={!!formik.errors.code}
              errorMessage={formik.errors.code}
              size="md"
              boxProps={{ w: 'full' }}
            />
          </Flex>
        </Flex>
        <Flex>
          <Button
            type="submit"
            colorScheme="purple"
            w={{ base: 'full', md: 'auto' }}
            isLoading={formik.isSubmitting}
          >
            {t('settings.enable')}
          </Button>
        </Flex>
      </Flex>
    </FormControl>
  );
};
