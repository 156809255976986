import { createIcon } from '@chakra-ui/icons';

export const FavouritesIcon = createIcon({
  displayName: 'FavouritesIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="M8.495.45a1.151 1.151 0 0 0-.99 0c-.271.128-.42.353-.496.476a5.877 5.877 0 0 0-.236.447L5.12 4.723l-3.7.542a5.75 5.75 0 0 0-.498.086 1.2 1.2 0 0 0-.606.325 1.151 1.151 0 0 0-.305.942c.04.297.206.508.3.618.097.113.226.24.353.362l2.676 2.607-.632 3.682a5.95 5.95 0 0 0-.072.5c-.011.144-.022.413.121.677.166.305.46.519.802.582.295.055.547-.039.68-.094.138-.057.298-.141.454-.223L8 13.589l3.308 1.74c.156.082.316.166.453.223.134.055.386.149.681.094a1.15 1.15 0 0 0 .802-.582c.143-.264.132-.533.12-.677-.011-.149-.042-.327-.071-.5l-.632-3.682 2.677-2.607c.126-.123.255-.249.352-.362a1.2 1.2 0 0 0 .3-.618 1.151 1.151 0 0 0-.305-.942 1.197 1.197 0 0 0-.606-.325 5.949 5.949 0 0 0-.498-.086l-3.7-.541-1.654-3.351a5.877 5.877 0 0 0-.236-.447 1.197 1.197 0 0 0-.496-.477Z"
    />
  ),
});
