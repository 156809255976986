import { Box } from '@chakra-ui/react';
import { IS_PRERENDER } from 'constants/general';
import { LazyLoadImage } from 'react-lazy-load-image-component';

type Props = {
  src: string;
  alt: string;
  w?: string;
  h?: string;
};

const getPixel = (n?: string) => {
  if (!n) {
    return n;
  }
  if (Number.isNaN(Number(n))) {
    return n;
  }

  return `${Number(n) * 4}px`;
};

export const LazyImage: React.FC<Props> = ({ src, alt, w, h }) => (
  <Box
    as="span"
    position="relative"
    sx={{
      img: {
        width: getPixel(w),
        height: getPixel(h),
      },
    }}
  >
    <LazyLoadImage
      width={getPixel(w)}
      height={getPixel(h)}
      threshold={100}
      visibleByDefault={IS_PRERENDER}
      src={src}
      alt={alt}
      draggable="false"
    />
  </Box>
);
