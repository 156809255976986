export const MODAL_TYPE = {
  AUTH: 'auth',
  PROFILE_MODAL: 'profile',
  WALLET_MODAL: 'wallet',
  REMOVE_FRIEND_MODAL: 'remove-friend',
  GLOBAL_SEARCH: 'search',
  BONUS_VIEW: 'bonus',
  NFT: 'nft',
  BONUSES: 'bonuses',
  TIP: 'tip',
  VIP: 'vip',
  PROMO_MODAL: 'promo',
  AUTH_GOOGLE: 'auth-google',
  AUTH_TELEGRAM: 'auth-telegram',
};

export const AUTHORIZED_REQUIRED_MODALS = [
  MODAL_TYPE.WALLET_MODAL,
  MODAL_TYPE.PROFILE_MODAL,
  MODAL_TYPE.BONUSES,
  MODAL_TYPE.TIP,
];
export const UNAUTHORIZED_REQUIRED_MODALS = [
  MODAL_TYPE.AUTH_GOOGLE,
  MODAL_TYPE.AUTH_TELEGRAM,
];

export const PAGE_MODALS = [
  MODAL_TYPE.PROFILE_MODAL,
  MODAL_TYPE.WALLET_MODAL,
  MODAL_TYPE.AUTH,
  MODAL_TYPE.GLOBAL_SEARCH,
  MODAL_TYPE.NFT,
  MODAL_TYPE.BONUSES,
  MODAL_TYPE.TIP,
  MODAL_TYPE.AUTH_GOOGLE,
];
