import { client, functionsClient, parseApiError } from 'helpers/client';

export const loadRate = () =>
  client.get(`/handler/ethereum/11/rate`).then(({ data }) => data.payload);

export const loadDepositAddress = (currencyKey: string) =>
  client
    .post(`/handler/ethereum/11/deposit`, {
      assetId: currencyKey,
    })
    .then(({ data }) => data.payload);

export const withdraw = ({ currencyKey, ...params }: { currencyKey: string }) =>
  client
    .post(`/handler/ethereum/11/withdraw`, params)
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const loadTransactionLink = (params: object) =>
  client
    .post('/handler/ethereum/11/crypto-tx-details', params)
    .then(({ data }) => data.payload)
    .then(result => {
      if (
        [
          'TRANSACTION_NOT_FOUND',
          'PENDING_CONFIRMATION',
          'PENDING_BLOCKCHAIN',
        ].includes(result.txHash)
      ) {
        return Promise.reject();
      }
      return result;
    })
    .catch(parseApiError);

export const loadWallets = () =>
  client.get('/handler/ethereum/11/wallet').then(({ data }) => data.payload);

export const checkBuyStatus = () =>
  client.get('https://api-payments.guardarian.com/v1/status');

export const createTransaction = ({
  amount,
  address,
}: {
  amount: string;
  address: string;
}) =>
  client
    .post(
      'https://api-payments.guardarian.com/v1/transaction',
      {
        from_amount: amount,
        from_currency: 'USD',
        to_currency: 'ETH',
        to_network: 'ETH',
        payout_address: address,
      },
      { headers: { 'x-api-key': '5d68008c-dccf-4010-b384-7d4aa84bed7d' } },
    )
    .then(d => d.data)
    .catch(e => Promise.reject(e.response.data));

export const createTransactionArkpay = ({ amount }: { amount: number }) =>
  functionsClient
    .post('/handleArkpayCreateTransaction', { amount })
    .then(d => d.data);

export const handleArkpayPayTransaction = (params: object) =>
  functionsClient.post('/handleArkpayPayTransaction', params).then(d => d.data);

export const getLatestBillingAddress = () =>
  functionsClient.get('/getBillingAddress').then(d => d.data);
