import { StateType as ChatStateType } from 'modules/chat';
import { StateType as State } from './types';

type StateType = {
  wagers: State;
  chat: ChatStateType;
};

export const getFeed = (type: string) => (state: StateType) =>
  state.wagers.feed[type];

export const getFeedItemByID = (id?: string | number) => (state: StateType) =>
  getFeed('all')(state).find(
    item =>
      (item.subGameType === id || item.handlerType === id) &&
      item.wagerAmount < item.winAmount &&
      item.isNew &&
      item.time &&
      item.time > Date.now() - 5000,
  );

export const getFeedLoaded = (type: string) => (state: StateType) =>
  state.wagers.feedIsLoaded[type];

export const getWagersTotal = (state: StateType) => state.wagers.total;

export const getWagersTotalIsLoaded = (state: StateType) =>
  state.wagers.totalIsLoaded;
