import { createIcon } from '@chakra-ui/icons';

export const GoogleIcon = createIcon({
  displayName: 'GoogleIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path
        fill="#4285F4"
        d="M15.68 8.17c0-.57-.05-1.11-.15-1.64H8v3.09h4.31c-.19 1-.75 1.85-1.6 2.41v2.01h2.59c1.51-1.39 2.39-3.44 2.39-5.88l-.01.01Z"
      />
      <path
        fill="#34A853"
        d="M8 15.99c2.16 0 3.97-.72 5.29-1.94l-2.59-2.01c-.72.48-1.63.76-2.71.76-2.08 0-3.85-1.41-4.48-3.3H.85v2.07c1.32 2.61 4.02 4.41 7.15 4.41v.01Z"
      />
      <path
        fill="#FBBC04"
        d="M3.52 9.51c-.16-.48-.25-.99-.25-1.52s.09-1.04.25-1.52V4.4H.85a8.012 8.012 0 0 0 0 7.18l2.67-2.07Z"
      />
      <path
        fill="#E94235"
        d="M8 3.17c1.17 0 2.23.4 3.06 1.2l2.29-2.29C11.96.79 10.15 0 8 0 4.87-.01 2.17 1.78.85 4.4l2.67 2.07c.63-1.89 2.39-3.3 4.48-3.3Z"
      />
    </>
  ),
});
