import { SLOTS_HANDLER_ID } from 'constants/general';
import { createAction } from 'store';
import * as api from './api';

export const SlotsClientType = {
  mobile: 0,
  desktop: 1,
  detect: 2,
};

export const loadGames = createAction(
  'slots/loadGames',
  () => () => api.loadGames(),
);

export const loadRecentGames = createAction(
  'slots/loadRecentGames',
  () => () => api.loadRecentGames(),
);

export const loadTopGames = createAction(
  'slots/loadTopGames',
  () => () => api.loadTopGames(),
);

export const startSession = createAction(
  'slots/startSession',
  (gameId, clientType) => () => api.startSession({ gameId, clientType }),
);

export const startDemoSession = createAction(
  'slots/startSession',
  (gameId, clientType) => () => api.startDemoSession({ gameId, clientType }),
);

export const loadFavorites = createAction(
  'slots/loadFavorites',
  () => () => api.loadFavorites(),
);

export const addFavorites = createAction(
  'slots/addFavorites',
  (gameType, handlerId = SLOTS_HANDLER_ID) =>
    () =>
      api.addFavorites({ gameType, handlerId }),
  (gameType, handlerId = SLOTS_HANDLER_ID) => ({ gameType, handlerId }),
);

export const removeFavorites = createAction(
  'slots/removeFavorites',
  (gameType, handlerId = SLOTS_HANDLER_ID) =>
    () =>
      api.removeFavorites({ gameType, handlerId }),
  (gameType, handlerId = SLOTS_HANDLER_ID) => ({ gameType, handlerId }),
);

export const loadPopularWithFriends = createAction(
  'slots/loadPopularWithFriends',
  (hours = 24 * 7, count = 1) =>
    () =>
      api.loadPopularWithFriends(hours, count),
);

export const loadPopular = createAction(
  'slots/loadPopular',
  (hours = 24 * 7, count = 2) =>
    () =>
      api.loadPopular(hours, count),
);

export const loadGameWagersStats = createAction(
  'slots/loadGameWagersStats',
  (id, handlerId) => () => api.loadGameWagersStats(id, handlerId),
);

export const loadRecommendationsLobby = createAction(
  'slots/loadRecommendationsLobby',
  () => () => api.loadRecommendationsLobby(),
);

export const loadRecommendationsUserLobby = createAction(
  'slots/loadGameWagersloadRecommendationsUserLobbyStats',
  () => () => api.loadRecommendationsUserLobby(),
);

export const loadRecommendationsSimilar = createAction(
  'slots/loadRecommendationsSimilar',
  (gameId: string, clientType: number) => () =>
    api.loadRecommendationsSimilar(gameId, clientType),
);

export const loadGameSettings = createAction(
  'slots/loadGameSettings',
  () => () => api.loadGameSettings(),
);
