/* eslint-disable max-lines */
export const EMOTES_LIST = [
  {
    display: '5head',
    id: '5head',
    icon: 'https://spinkick.imgix.net/chat-emotes/5head.png',
  },
  {
    display: 'abdulpls',
    id: 'abdulpls',
    icon: 'https://spinkick.imgix.net/chat-emotes/abdulpls.gif?fm=gifv',
  },
  {
    display: 'gachibass',
    id: 'gachibass',
    icon: 'https://spinkick.imgix.net/chat-emotes/gachibass.gif?fm=gifv',
  },
  {
    display: 'blobdance',
    id: 'blobdance',
    icon: 'https://spinkick.imgix.net/chat-emotes/blobdance.gif?fm=gifv',
  },
  {
    display: 'catjam',
    id: 'catjam',
    icon: 'https://spinkick.imgix.net/chat-emotes/catjam.gif?fm=gifv',
  },
  {
    display: 'ezpepe',
    id: 'ezpepe',
    icon: 'https://spinkick.imgix.net/chat-emotes/ezpepe.png',
  },
  {
    display: 'kekw',
    id: 'kekw',
    icon: 'https://spinkick.imgix.net/chat-emotes/kekw.png',
  },
  {
    display: 'lulw',
    id: 'lulw',
    icon: 'https://spinkick.imgix.net/chat-emotes/lulw.png',
  },
  {
    display: 'modcheck',
    id: 'modcheck',
    icon: 'https://spinkick.imgix.net/chat-emotes/modcheck.gif?fm=gifv',
  },
  {
    display: 'monkas',
    id: 'monkas',
    icon: 'https://spinkick.imgix.net/chat-emotes/monkas.png',
  },
  {
    display: 'monkaw',
    id: 'monkaw',
    icon: 'https://spinkick.imgix.net/chat-emotes/monkaw.png',
  },
  {
    display: 'omegalul',
    id: 'omegalul',
    icon: 'https://spinkick.imgix.net/chat-emotes/omegalul.png',
  },
  {
    display: 'peepoclap',
    id: 'peepoclap',
    icon: 'https://spinkick.imgix.net/chat-emotes/peepoclap.gif?fm=gifv',
  },
  {
    display: 'peepowave',
    id: 'peepowave',
    icon: 'https://spinkick.imgix.net/chat-emotes/peepowave.gif?fm=gifv',
  },
  {
    display: 'peepohypers',
    id: 'peepohypers',
    icon: 'https://spinkick.imgix.net/chat-emotes/peepohypers.png',
  },
  {
    display: 'peepoleave',
    id: 'peepoleave',
    icon: 'https://spinkick.imgix.net/chat-emotes/peepoleave.gif?fm=gifv',
  },
  {
    display: 'pepega',
    id: 'pepega',
    icon: 'https://spinkick.imgix.net/chat-emotes/pepega.png',
  },
  {
    display: 'pepehands',
    id: 'pepehands',
    icon: 'https://spinkick.imgix.net/chat-emotes/pepehands.png',
  },
  {
    display: 'pogslide',
    id: 'pogslide',
    icon: 'https://spinkick.imgix.net/chat-emotes/pogslide.gif?fm=gifv',
  },
  {
    display: 'diamond',
    id: 'diamond',
    icon: 'https://spinkick.imgix.net/chat-emotes/diamond.gif?fm=gifv',
  },
  {
    display: 'peepogiggles',
    id: 'peepogiggles',
    icon: 'https://spinkick.imgix.net/chat-emotes/peepogiggles.png',
  },
  {
    display: 'pepeiloveyou',
    id: 'pepeiloveyou',
    icon: 'https://spinkick.imgix.net/chat-emotes/pepe-iloveyou.png',
  },
  {
    display: 'pepebusiness',
    id: 'pepebusiness',
    icon: 'https://spinkick.imgix.net/chat-emotes/pepe-business.png',
  },
  {
    display: 'pepejam',
    id: 'pepejam',
    icon: 'https://spinkick.imgix.net/chat-emotes/pepejam.png',
  },
  {
    display: 'pepelaugh',
    id: 'pepelaugh',
    icon: 'https://spinkick.imgix.net/chat-emotes/pepelaugh.png',
  },
  {
    display: 'pepepls',
    id: 'pepepls',
    icon: 'https://spinkick.imgix.net/chat-emotes/pepepls.gif?fm=gifv',
  },
  {
    display: 'poggers',
    id: 'poggers',
    icon: 'https://spinkick.imgix.net/chat-emotes/poggers.png',
  },
  {
    display: 'pogu',
    id: 'pogu',
    icon: 'https://spinkick.imgix.net/chat-emotes/pogu.png',
  },
  {
    display: 'popcat',
    id: 'popcat',
    icon: 'https://spinkick.imgix.net/chat-emotes/popcat.gif?fm=gifv',
  },
  {
    display: 'rainbowpls',
    id: 'rainbowpls',
    icon: 'https://spinkick.imgix.net/chat-emotes/rainbowpls.gif?fm=gifv',
  },
  {
    display: 'ricardoflick',
    id: 'ricardoflick',
    icon: 'https://spinkick.imgix.net/chat-emotes/ricardoflick.gif?fm=gifv',
  },
  {
    display: 'sadge',
    id: 'sadge',
    icon: 'https://spinkick.imgix.net/chat-emotes/sadge.png',
  },
  {
    display: 'weirdchamp',
    id: 'weirdchamp',
    icon: 'https://spinkick.imgix.net/chat-emotes/weirdchamp.png',
  },
  {
    display: 'jellypat',
    id: 'jellypat',
    icon: 'https://spinkick.imgix.net/chat-emotes/jellypat.gif?fm=gifv',
  },
  {
    display: 'marshmallowfire',
    id: 'marshmallowfire',
    icon: 'https://spinkick.imgix.net/chat-emotes/marshmallowfire.gif?fm=gifv',
  },
  {
    display: 'arm-hype',
    id: 'arm-hype',
    icon: 'https://spinkick.imgix.net/chat-emotes/sherbet-max/arm-hype.png',
    isOnlyMax: true,
  },
  {
    display: 'basketball',
    id: 'basketball',
    icon: 'https://spinkick.imgix.net/chat-emotes/sherbet-max/basketball.png',
    isOnlyMax: true,
  },
  {
    display: 'bruh',
    id: 'bruh',
    icon: 'https://spinkick.imgix.net/chat-emotes/sherbet-max/bruh.png',
    isOnlyMax: true,
  },
  {
    display: 'candycane',
    id: 'candycane',
    icon: 'https://spinkick.imgix.net/chat-emotes/sherbet-max/candycane.png',
    isOnlyMax: true,
  },
  {
    display: 'dap',
    id: 'dap',
    icon: 'https://spinkick.imgix.net/chat-emotes/sherbet-max/dap.png',
    isOnlyMax: true,
  },
  {
    display: 'drake-yes',
    id: 'drake-yes',
    icon: 'https://spinkick.imgix.net/chat-emotes/sherbet-max/drake-yes.png',
    isOnlyMax: true,
  },
  {
    display: 'gem',
    id: 'gem',
    icon: 'https://spinkick.imgix.net/chat-emotes/sherbet-max/gem.png',
    isOnlyMax: true,
  },
  {
    display: 'old-man-stare',
    id: 'old-man-stare',
    icon: 'https://spinkick.imgix.net/chat-emotes/sherbet-max/old-man-stare.png',
    isOnlyMax: true,
  },
  {
    display: 'pepecrowded',
    id: 'pepecrowded',
    icon: 'https://spinkick.imgix.net/chat-emotes/sherbet-max/pepecrowded.png',
    isOnlyMax: true,
  },
  {
    display: 'catspin',
    id: 'catspin',
    icon: 'https://spinkick.imgix.net/chat-emotes/sherbet-max/catspin.gif?fm=gifv',
    isOnlyMax: true,
  },
  {
    display: 'dogspin',
    id: 'dogspin',
    icon: 'https://spinkick.imgix.net/chat-emotes/sherbet-max/dogspin.gif?fm=gifv',
    isOnlyMax: true,
  },
  {
    display: 'stonks',
    id: 'stonks',
    icon: 'https://spinkick.imgix.net/chat-emotes/sherbet-max/stonks.gif?fm=gifv',
    isOnlyMax: true,
  },
  {
    display: 'nodders',
    id: 'nodders',
    icon: 'https://spinkick.imgix.net/chat-emotes/sherbet-max/nodders.gif?fm=gifv',
    isOnlyMax: true,
  },
  {
    display: 'oooo',
    id: 'oooo',
    icon: 'https://spinkick.imgix.net/chat-emotes/sherbet-max/OOOO.gif?fm=gifv',
    isOnlyMax: true,
  },
  {
    display: 'weirdchamp',
    id: 'weirdchamp',
    icon: 'https://spinkick.imgix.net/chat-emotes/sherbet-max/weirdchamp.gif?fm=gifv',
    isOnlyMax: true,
  },
  {
    display: 'patrickboo',
    id: 'patrickboo',
    icon: 'https://spinkick.imgix.net/chat-emotes/sherbet-max/patrickboo.gif?fm=gifv',
    isOnlyMax: true,
  },
  {
    display: 'sussy',
    id: 'sussy',
    icon: 'https://spinkick.imgix.net/chat-emotes/sherbet-max/sussy.gif?fm=gifv',
    isOnlyMax: true,
  },
  {
    display: 'wesmart',
    id: 'wesmart',
    icon: 'https://spinkick.imgix.net/chat-emotes/sherbet-max/wesmart.gif?fm=gifv',
    isOnlyMax: true,
  },
];

type EmoteType = {
  display: string;
  id: string;
  icon: string;
  isOnlyMax: boolean;
};

export const EMOTES_MAP = EMOTES_LIST.reduce(
  (acc, item) => Object.assign(acc, { [item.id]: item }),
  {} as { [key: string]: EmoteType },
);
