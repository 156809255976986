import { createIcon } from '@chakra-ui/icons';

export const SlotsIcon = createIcon({
  displayName: 'SlotsIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M13.43 1.277a.89.89 0 0 0-.19-.971 1.045 1.045 0 0 0-1.017-.27c-2.425.654-4.349 1.737-5.8 3.248-1.27 1.324-2.14 2.937-2.688 4.79A4.001 4.001 0 0 0 4.5 16a4 4 0 0 0 1.276-7.792c.482-1.466 1.187-2.685 2.136-3.673.75-.782 1.673-1.44 2.801-1.962-.683 1.675-1.236 3.6-.61 5.678a3.996 3.996 0 0 0-1.16.673A4.979 4.979 0 0 1 10 12a4.98 4.98 0 0 1-1.058 3.076 4 4 0 1 0 3.19-7.026c-.763-2.007.019-3.81.923-5.895.124-.287.25-.579.376-.878Z"
      clipRule="evenodd"
    />
  ),
});
