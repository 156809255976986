import { createIcon } from '@chakra-ui/icons';

export const ExploreIcon = createIcon({
  displayName: 'ExploreIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M16 8A8 8 0 1 1 0 8a8 8 0 0 1 16 0Zm-5.926-2.844-3.4 1.133a.828.828 0 0 0-.203.083.38.38 0 0 0-.099.1.828.828 0 0 0-.083.202l-1.133 3.4c-.124.371-.186.557-.142.681a.38.38 0 0 0 .231.23c.124.045.31-.017.682-.141L9.326 9.71a.83.83 0 0 0 .203-.083.38.38 0 0 0 .099-.1.83.83 0 0 0 .083-.202l1.133-3.4c.124-.371.186-.557.142-.681a.38.38 0 0 0-.23-.23c-.125-.045-.31.017-.682.141Z"
      clipRule="evenodd"
    />
  ),
});
