import { Box, Divider, Flex, FormLabel, Text } from '@chakra-ui/react';
import { CustomInput } from 'forms/custom-input';
import { CustomSelect } from 'forms/custom-select';
import { logAction } from 'helpers/newrelic';
import { useCopy } from 'hooks/use-copy';
import { usePip } from 'hooks/use-pip';
import { CopyIcon } from 'icons';
import { Suspense, lazy, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { DepositBonusCard } from 'services/user/components/deposit-bonus-card';
import {
  NETWORK_NAMES,
  getCurrencyByNetwork,
  getCurrencyByNetworks,
  getDepositAddressByType,
  getDepositAddressLoadingByType,
  getRateIsLoaded,
  loadDepositAddress,
  loadRate,
  useGetCurrencyNetworks,
} from 'services/user/modules/currencies';
import { getUserBonusWelcomeBonusAvailableToDeposit } from 'services/user/modules/user';
import { useActions } from 'store';
import { BeatLoader } from 'uikit/beat-loader';
import { Skeleton } from 'uikit/skeleton';

const QRCode = lazy(() =>
  import('react-qrcode-logo').then(module => ({ default: module.QRCode })),
);

export const DepositView = ({ name, shortcut }) => {
  const { t } = useTranslation();
  const networks = useGetCurrencyNetworks(shortcut);
  const currenciesNetworks = useSelector(getCurrencyByNetworks);
  const [network, setNetwork] = useState(() => networks[0]?.assetName || '');
  const { isPiP } = usePip({ id: 'iframe-deposit', hasTabListener: true });

  const info = useSelector(getCurrencyByNetwork(network));
  const depositAddress = useSelector(getDepositAddressByType(network));
  const depositAddressLoading = useSelector(
    getDepositAddressLoadingByType(network),
  );
  // const level = useSelector(getUserLevel);

  const firstNetwork = networks[0]?.assetName;

  useEffect(() => {
    if (!network && firstNetwork) {
      setNetwork(firstNetwork);
    }
  }, [network, firstNetwork]);

  const { onCopy: onCopyTag } = useCopy(info?.tag, {
    description: t('wallet.tag-copied'),
  });
  const { onCopy } = useCopy(depositAddress, {
    description: t('wallet.address-copied'),
  });

  const link = depositAddress;

  const actions = useActions({
    loadDepositAddress,
    loadRate,
  });
  const rateIsLoaded = useSelector(getRateIsLoaded);

  useEffect(() => {
    if (!rateIsLoaded) {
      actions.loadRate();
    }
  }, [actions, rateIsLoaded]);

  useEffect(() => {
    if (network) {
      actions.loadDepositAddress(network).catch(console.warn);
    }
  }, [network, actions]);

  let depositAddressContent = <BeatLoader />;

  if (!depositAddressLoading && depositAddress) {
    const firstPart = depositAddress.slice(0, 10);
    const lastPart = depositAddress.slice(-10);
    depositAddressContent = (
      <Text fontWeight="500" wordBreak="break-all" fontSize="14px">
        <Text as="span" sx={{ color: 'sherbet-green.200' }}>
          {firstPart.slice(0, 4)}
        </Text>
        <Text as="span">{firstPart.slice(4)}</Text>
        <Text as="span">...</Text>
        <Text as="span">{lastPart.slice(0, 6)}</Text>
        <Text as="span" sx={{ color: 'sherbet-green.200' }}>
          {lastPart.slice(6)}
        </Text>
      </Text>
    );
  }

  const isWelcomeBonusAvailableToDeposit = useSelector(
    getUserBonusWelcomeBonusAvailableToDeposit,
  );

  const activeBlockchainName = currenciesNetworks[network]?.blockchainName;

  // const isEmailConfirmed = useSelector(getUserEmailConfirmed);

  // if (!isEmailConfirmed && !GET_IS_TELEGRAM()) {
  //   return <VerifyEmailForm isRounded={false} />;
  // }

  return (
    <Box>
      <Flex
        direction="column"
        id="iframe-deposit"
        padding={isPiP ? '5' : undefined}
      >
        <Box p="5">
          <Text color="candy-floss-text">
            {t('deposit.send-description', {
              name,
              network:
                NETWORK_NAMES[activeBlockchainName] || activeBlockchainName,
            })}
          </Text>
        </Box>
        <Divider
          borderColor="truffle-border"
          borderBottomWidth="1px"
          opacity="1"
        />
        <Flex
          justifyContent="space-between"
          gap="5"
          direction="column"
          padding={isPiP ? '0' : '5'}
          align={{ base: 'center', md: 'start' }}
        >
          {!isPiP && (
            <Flex align="center" w="full">
              <Skeleton
                zIndex="1"
                pos="relative"
                display={{ base: 'none', md: 'block' }}
                rounded="xl"
                isLoaded={!depositAddressLoading && !!depositAddress}
              >
                <Box
                  rounded="xl"
                  bg="vanilla-text"
                  overflow="hidden"
                  display="block"
                  h="166px"
                  w="166px"
                  onClick={
                    !depositAddressLoading && !!depositAddress
                      ? () => {
                          onCopy();
                          logAction('QR_COPY');
                        }
                      : undefined
                  }
                >
                  <Suspense>
                    <QRCode
                      value={link}
                      size="150"
                      ecLevel="Q"
                      bgColor="rgba(252, 248, 248, 1)"
                      quietZone="8"
                      eyeColor="#7B54E9"
                      eyeRadius={8}
                      removeQrCodeBehindLogo
                      logoPadding={5}
                      logoImage="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxNTkiIGhlaWdodD0iMTU5IiBmaWxsPSJub25lIiB2aWV3Qm94PSIwIDAgMTU5IDE1OSI+PHJlY3Qgd2lkdGg9IjE1OSIgaGVpZ2h0PSIxNTkiIGZpbGw9IiM3QjU0RTkiIHJ4PSIzMyIvPjxwYXRoIGZpbGw9IiNmZmYiIGQ9Ik03OC44NTMgNzUuMDI5YTEuMzU1IDEuMzU1IDAgMCAwIC45MDEtMS41ODcgNDcuMjI0IDQ3LjIyNCAwIDAgMC0xLjA5OC00LjA1NGMtNC4yNDQtMTMuMDYtMTUuNjEyLTI0LjY1Ni0zMi40NTgtMTkuMzFDMzAuMDggNTUuMTkyIDI2LjU2OCA3MS40NiAzMC40NCA4My4zNzNjLjQxNiAxLjI4MiAxLjMwNyAzLjc0MyAyLjAzMyA1LjQzNWExLjM1IDEuMzUgMCAwIDAgMS42Ni43NDhsNDQuNzItMTQuNTI4Wk01MC4wMTkgNjEuNzI2YzguNzc4LTIuODUyIDExLjg2NyA2LjExMiAxMS44NjcgNi4xMTJMNDMuNDc4IDczLjgycy0yLjIzNi05LjI0MSA2LjU0Mi0xMi4wOTNoLS4wMDFabTc4LjIwMS0zLjMzMi0xMy41OTYtMS45MSAyLjEyOS0xNS4xNWExLjM1MyAxLjM1MyAwIDAgMC0xLjE1Mi0xLjUyOWwtMTIuNzUyLTEuNzkyYTEuMzU0IDEuMzU0IDAgMCAwLTEuNTI5IDEuMTUybC0yLjEzIDE1LjE1LTEzLjQ1LTEuODlhMS4zNTQgMS4zNTQgMCAwIDAtMS41MjggMS4xNTJsLTEuMzM1IDkuNDkzYTEuMzUzIDEuMzUzIDAgMCAwIDEuMTUyIDEuNTI5bDEzLjQ1IDEuODktMi4xMyAxNS4xNWExLjM1NCAxLjM1NCAwIDAgMCAxLjE1MiAxLjUyOWwxMi43NTMgMS43OTFhMS4zNTQgMS4zNTQgMCAwIDAgMS41MjktMS4xNTFsMi4xMjktMTUuMTUgMTMuNTk3IDEuOTFhMS4zNTIgMS4zNTIgMCAwIDAgMS41MjgtMS4xNTFsMS4zMzUtOS40OTRhMS4zNTMgMS4zNTMgMCAwIDAtMS4xNTItMS41MjhaTTc4LjI2NyAxMjBhNDQuMjEgNDQuMjEgMCAwIDEtMzUuNDg3LTE3Ljg3NCAxLjM1NSAxLjM1NSAwIDAgMSAuMzE4LTEuOTE5bDguMDY5LTUuNmExLjM1MiAxLjM1MiAwIDAgMSAxLjg0OS4yOTggMzEuNjgxIDMxLjY4MSAwIDAgMCAyNS4yNTEgMTIuNTc0IDMxLjY3NyAzMS42NzcgMCAwIDAgMjUuMjUxLTEyLjU3NCAxLjM1MyAxLjM1MyAwIDAgMSAxLjg1LS4yOTdsOC4wNjkgNS41OTljLjYyNy40MzUuNzczIDEuMzA2LjMxOCAxLjkxOUE0NC4yMSA0NC4yMSAwIDAgMSA3OC4yNjggMTIwWiIvPjwvc3ZnPg=="
                      logoWidth="42"
                    />
                  </Suspense>
                </Box>
              </Skeleton>
            </Flex>
          )}
        </Flex>
        {!isPiP && (
          <Divider
            mb="5"
            borderColor="truffle-border"
            borderBottomWidth="1px"
            opacity="1"
          />
        )}
        <Flex flexDirection="column" gap="3" p="5" pt={isPiP ? '5' : '0'}>
          <CustomSelect
            name="network"
            label={t('nft.network')}
            value={network}
            disabled={isPiP}
            onChange={e => setNetwork(e.target.value)}
            options={networks.map(({ assetName, blockchainName }) => ({
              value: assetName,
              title: NETWORK_NAMES[blockchainName] || blockchainName,
            }))}
          />
          <Box>
            <FormLabel
              color="candy-floss-text"
              display="inline-flex"
              fontSize="base"
              mb="2"
            >
              {t('deposit.your-address', { name })}
            </FormLabel>
            <Flex
              onClick={onCopy}
              cursor="copy"
              _hover={{ opacity: '0.9' }}
              justifyContent="space-between"
              px="4"
              border="1px solid"
              borderColor="truffle-border"
              py="2"
              gap="2"
              minH="40px"
              bg="sugar-dust"
              rounded="xl"
              alignItems="center"
              w="full"
            >
              {depositAddressContent}
              {!isPiP && <CopyIcon color="candy-floss-text" />}
            </Flex>
          </Box>
          {info?.tag && (
            <CustomInput
              onClick={onCopyTag}
              cursor="copy"
              _hover={{ opacity: '0.9' }}
              label={t('wallet.tag')}
              placeholder={t('wallet.tag')}
              required
              fontWeight="500"
              wordBreak="break-all"
              color="vanilla-text"
              fontSize={{ base: 'base', md: 'lg' }}
              rightElement={<CopyIcon color="candy-floss-text" />}
              value={depositAddressLoading ? t('wallet.loading') : info.tag}
              readOnly
            />
          )}
          {!isPiP && isWelcomeBonusAvailableToDeposit && (
            <Box mt="2">
              <DepositBonusCard />
            </Box>
          )}
        </Flex>
      </Flex>

      {isPiP && (
        <Flex
          pos="absolute"
          top="0"
          right="0"
          left="0"
          bottom="0"
          justifyContent="center"
          alignItems="center"
        >
          <Text textAlign="center">In Pop Out Mode</Text>
        </Flex>
      )}
    </Box>
  );
};
