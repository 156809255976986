import { MaintenanceWrapper } from 'components/maintenance-wrapper';
import { IS_WITHDRAWAL_MAINTENANCE } from 'constants/general';
import { useLayoutEffect, useRef, useState } from 'react';
import { CurrenciesList } from 'services/user/components/currencies-list';
import { WALLETS_TABS } from 'services/user/modules/currencies';
import { WithdrawView } from '../withdraw-view';

const WALLETS_TABS_KEYS = WALLETS_TABS.map(t => t.shortcut);

export const Withdraw = ({ shortcut: curr = 'ETH', onClose }) => {
  const [currencyTabIndex, setCurrencyTabIndex] = useState(() => {
    const index = WALLETS_TABS_KEYS.indexOf(curr);
    if (index === -1) return 0;
    return index;
  });
  const activeCurrencyRef = useRef();

  const activeCurrencyTab = WALLETS_TABS[currencyTabIndex];

  useLayoutEffect(() => {
    activeCurrencyRef.current?.scrollIntoView();
  }, []);

  return (
    <MaintenanceWrapper isActive={IS_WITHDRAWAL_MAINTENANCE}>
      <CurrenciesList
        isWithdraw
        active={currencyTabIndex}
        onSelect={setCurrencyTabIndex}
      >
        <WithdrawView
          key={currencyTabIndex}
          onClose={onClose}
          {...activeCurrencyTab}
        />
      </CurrenciesList>
    </MaintenanceWrapper>
  );
};
