import { client, parseApiError } from 'helpers/client';

export const loadGames = () =>
  client.get(`/handler/versus/5`).then(({ data }) => data.payload);

export const createGame = ({
  total,
  amount,
  position,
  players,
}: {
  total: number;
  amount: number;
  position: number;
  players: number;
}) =>
  client
    .post(`/handler/versus/5`, {
      inputTotal: total,
      inputAmount: amount,
      inputItems: [],
      position,
      players,
    })
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const placeWager = ({
  gameId,
  amount,
  position,
  isBotInvite,
}: {
  gameId: string;
  amount: number;
  position: number;
  isBotInvite: boolean;
}) =>
  client
    .post(`/handler/versus/5/${gameId}`, {
      inputTotal: amount,
      inputAmount: amount,
      inputItems: [],
      position,
      isBotInvite,
    })
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const loadVersusHistory = ({
  before,
  limit,
  id,
}: {
  before: string;
  limit: number;
  id: string;
}) =>
  client
    .get(`/handler/versus/5/history?limit=${limit}&before=${before}&id=${id}`)
    .then(({ data }) => data.payload);
