import { createIcon } from '@chakra-ui/icons';

export const TipIcon = createIcon({
  displayName: 'TipIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="M1 0a1 1 0 1 0 0 2h10a1 1 0 1 0 0-2H1Zm2.07 14c.436.753.99 1.428 1.638 2H1a1 1 0 1 1 0-2h2.07Zm-1.055-3.5a7.97 7.97 0 0 0 .383 2H1a1 1 0 1 1 0-2h1.015ZM2.582 7a7.947 7.947 0 0 0-.52 2H1a1 1 0 1 1 0-2h1.582Zm2.753-3.5a8.045 8.045 0 0 0-1.95 2H1a1 1 0 0 1 0-2h4.335ZM10 4a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm.61 9.196v.35a.654.654 0 0 1-1.303 0v-.355a5.002 5.002 0 0 1-1.303-.355.77.77 0 0 1 .272-1.483c.547.19 1.115.308 1.691.354.933 0 .933-.327.933-.463 0-.235-.644-.333-1.02-.431-.916-.23-2.302-.59-2.302-2.013a2.03 2.03 0 0 1 1.73-1.953v-.392a.655.655 0 0 1 1.303 0v.338c.387.035.768.12 1.134.25a.764.764 0 0 1-.245 1.484 6.06 6.06 0 0 0-1.386-.24c-.441 0-.96.12-.96.458 0 .23.317.339 1.091.519.895.212 2.253.545 2.253 2.023-.022.993-.763 1.713-1.887 1.91Z"
    />
  ),
});
