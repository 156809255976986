import { createIcon } from '@chakra-ui/icons';

export const USDCIcon = createIcon({
  displayName: 'USDCIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <g clipPath="url(#a)">
        <path fill="#3E73C4" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z" />
        <path
          fill="#fff"
          d="M10.011 9.062c0-1.062-.64-1.426-1.92-1.578-.914-.122-1.096-.364-1.096-.789 0-.425.305-.698.914-.698.548 0 .853.182 1.005.638a.229.229 0 0 0 .214.151h.487a.208.208 0 0 0 .214-.212v-.03a1.52 1.52 0 0 0-1.372-1.245v-.728c0-.122-.091-.212-.243-.243h-.458c-.121 0-.213.091-.243.243v.698c-.915.121-1.493.728-1.493 1.487 0 1.001.609 1.396 1.889 1.547.853.152 1.127.334 1.127.82 0 .485-.426.819-1.005.819-.793 0-1.067-.334-1.158-.789-.03-.121-.122-.182-.214-.182h-.518a.208.208 0 0 0-.213.213v.03c.122.758.61 1.304 1.615 1.457v.728c0 .121.092.213.244.243h.457c.122 0 .213-.091.244-.243v-.729c.914-.152 1.523-.789 1.523-1.608Z"
        />
        <path
          fill="#fff"
          d="M6.446 12.248c-2.377-.85-3.596-3.49-2.712-5.826a4.498 4.498 0 0 1 2.712-2.701c.122-.06.183-.151.183-.304v-.424c0-.121-.061-.212-.183-.243-.03 0-.091 0-.122.03a5.447 5.447 0 0 0-3.565 6.859 5.468 5.468 0 0 0 3.565 3.55c.122.061.244 0 .274-.121.03-.03.03-.061.03-.121v-.425c0-.091-.09-.213-.182-.274Zm3.23-9.467c-.122-.062-.244 0-.274.12-.03.031-.03.062-.03.122v.425c0 .122.09.243.182.304 2.377.85 3.596 3.49 2.712 5.826a4.498 4.498 0 0 1-2.712 2.701c-.122.06-.182.152-.182.303v.425c0 .122.06.213.182.243.03 0 .092 0 .122-.03a5.449 5.449 0 0 0 3.565-6.859 5.513 5.513 0 0 0-3.565-3.58Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </>
  ),
});
