import axios from 'axios';
import cookies from 'js-cookie';
import { isLocalhost, isSafari } from '../env';
import { INTERCOM_TOKEN, REFRESH_TOKEN, TOKEN } from './constants';

export const getToken = () => cookies.get(TOKEN);
export const getRefreshToken = () => cookies.get(REFRESH_TOKEN);
export const getIntercomToken = () => cookies.get(INTERCOM_TOKEN);

export const setToken = (token: string) =>
  cookies.set(TOKEN, token, {
    expires: 365,
    secure: !isLocalhost() || !isSafari(),
    sameSite: 'none',
  });
export const setRefreshToken = (token: string) =>
  cookies.set(REFRESH_TOKEN, token, { expires: 365 });
export const setIntercomToken = (token: string) =>
  cookies.set(INTERCOM_TOKEN, token, {
    expires: 365,
    secure: true,
    sameSite: 'none',
  });

export const removeIntercomToken = () => cookies.remove(INTERCOM_TOKEN);

export const removeToken = () => cookies.remove(TOKEN);
export const removeRefreshToken = () => cookies.remove(REFRESH_TOKEN);

export const getApiBaseUrl = (pathname: string) => {
  if (pathname.startsWith('/handler')) {
    const [, , pathHandler] = pathname.split('/');

    if (pathHandler) {
      const pathHandlerProxy =
        process.env[`REACT_APP_API_URL_${pathHandler.toUpperCase()}`];

      if (pathHandlerProxy) {
        return pathHandlerProxy;
      }
    }
  }

  const [, path] = pathname.split('/');
  const pathProxy = process.env[`REACT_APP_API_URL_${path.toUpperCase()}`];

  if (pathProxy) {
    return pathProxy;
  }

  return process.env.REACT_APP_API_URL;
};

type ErrorResponse = {
  response: {
    data?: {
      errorCodes?: { [key: string]: string };
    };
  };
};

export const parseApiError = ({
  response: { data: { errorCodes } = {} } = {},
}: ErrorResponse) => {
  const errors: { [key: string]: string } = {};

  if (errorCodes) {
    Object.entries(errorCodes).forEach(([key, value]) => {
      errors[key.toLowerCase()] = value;
    });
  }

  throw errors;
};

export const parseApiErrorClear = ({
  response: { data: { errorCodes = {} } = {} },
}: ErrorResponse) => {
  const errors: { [key: string]: string } = {};

  if (errorCodes) {
    Object.entries(errorCodes).forEach(([key, value]) => {
      errors[key] = value;
    });
  }

  throw errors;
};

const defaultHeaders: {
  Authorization?: string;
  'Content-Type': string;
} = {
  'Content-Type': 'application/json',
};

export const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 50000,
  headers: defaultHeaders,
});

export const functionsClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL_FUNCTIONS,
  timeout: 50000,
  headers: defaultHeaders,
});

functionsClient.interceptors.request.use(
  config => {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${getToken()}`;
    }

    return config;
  },
  error => Promise.reject(error),
);

client.interceptors.request.use(
  config => {
    if (config.headers) {
      config.headers.Authorization = `Bearer ${getToken()}`;
    }

    if (process.env.NODE_ENV === 'development') {
      const baseURL = config.url && getApiBaseUrl(config.url);

      return {
        ...config,
        baseURL,
      };
    }

    return config;
  },
  error => Promise.reject(error),
);

export const addRefreshInterceptor = (action: () => Promise<string>) => {
  const refreshPromise: { current: Promise<string> | null } = { current: null };

  client.interceptors.response.use(
    response => response,
    async error => {
      const status = error.response?.status;

      if (status === 401 && getRefreshToken()) {
        try {
          let newToken;
          if (!refreshPromise.current) {
            refreshPromise.current = action();
            newToken = await refreshPromise.current;
            refreshPromise.current = null;
          } else {
            newToken = await refreshPromise.current;
          }

          error.config.headers.Authorization = `Bearer ${newToken}`;
          return client.request(error.config);
        } catch (e) {
          console.warn('refresh fail', e);
        }
      }

      if (error.code === 'ERR_NETWORK') {
        return Promise.reject({
          response: { data: { errorCodes: { global: 'ERR_NETWORK' } } },
        });
      }

      return Promise.reject(error);
    },
  );
};
