import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { CustomInput } from 'forms/custom-input';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { validateField } from 'services/auth/modules/auth/api';
import {
  AFFILIATES,
  getViewUser,
  loadSlimUser,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { Errors, FormSubmit } from 'types';
import { UserAvatar } from 'uikit/user-avatar';
import * as Yup from 'yup';

type Props = {
  setStep: (step: number) => void;
  setParams: (params: object) => void;
};

export const Step1: React.FC<Props> = ({ setParams, setStep }) => {
  const { t } = useTranslation();
  const stag = localStorage.getItem('stag') || '';

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      stag,
    },
    validationSchema: Yup.object({
      stag: Yup.string().max(60),
    }),
    onSubmit: async (params, { setErrors }) => {
      try {
        await validateField('stag', params.stag);
        setParams(params);
        setStep(2);
      } catch (errors) {
        setErrors({ stag: (errors as Errors).global });
      }
    },
  });

  const actions = useActions({
    loadSlimUser,
  });

  const user = useSelector(getViewUser);

  useEffect(() => {
    if (stag) {
      actions.loadSlimUser(stag);
    }
  }, [actions, stag]);

  const navigate = useNavigate();

  let imageContent = null;

  if (formik.values.stag) {
    const affiliateData =
      AFFILIATES[formik.values.stag as keyof typeof AFFILIATES];
    if (affiliateData) {
      imageContent = affiliateData.banner;
    } else if (formik.values.stag === stag) {
      if (user) {
        imageContent = <UserAvatar name={user.name} icon={user.icon} />;
      } else {
        imageContent = <UserAvatar isSkeleton />;
      }
    }
  }

  return (
    <FormControl as="form" onSubmit={formik.handleSubmit as FormSubmit}>
      <Box w="full" pos="relative">
        <Flex
          flexDirection="column"
          justifyContent="start"
          w="full"
          bg="toffee-base"
          mx="auto"
        >
          {imageContent && <Box mb="5">{imageContent}</Box>}
          <Heading fontSize="2xl" mb="2">
            {formik.values.stag
              ? t('signup.step6.title', { stag: formik.values.stag })
              : t('signup.step6.title2')}
          </Heading>
          <Text fontSize="lg" color="candy-floss-text" maxW="md" mb="5">
            {formik.values.stag
              ? t('signup.step6.desc', { stag: formik.values.stag })
              : t('signup.step6.desc2')}
          </Text>

          <Flex
            w="full"
            maxW="md"
            mb="5"
            gap="5"
            justifyContent="space-between"
            align="center"
          >
            <CustomInput
              placeholder={t('register-form.affiliate-code')}
              type="text"
              name="stag"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.stag}
              isInvalid={!!formik.errors.stag}
              errorMessage={formik.errors.stag}
            />
          </Flex>
          <Flex align="center" gap="2">
            <Button
              type="submit"
              colorScheme="purple"
              isLoading={formik.isSubmitting}
            >
              {t('actions.continue')}
            </Button>
          </Flex>
        </Flex>
      </Box>
    </FormControl>
  );
};
