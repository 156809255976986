import { client, parseApiError, parseApiErrorClear } from 'helpers/client';

export const AUTH_PATH = '/auth';

export const googleAuth = (params: {
  accessToken?: string;
  displayName?: string;
  stag?: string;
  afdp?: string;
  idToken?: string;
  code: string;
}) =>
  client
    .post(`${AUTH_PATH}/google`, params)
    .then(({ data }) => data)
    .catch(parseApiError);

export const telegramAuth = ({
  queryId,
  telegramUserObject,
  authDate,
  hash,
  preferredUserName,
  stag,
}: {
  queryId: string;
  telegramUserObject: string;
  authDate: string;
  hash: string;
  preferredUserName: string;
  stag: string;
}) =>
  client
    .post(`${AUTH_PATH}/telegram`, {
      queryId,
      telegramUserObject,
      authDate,
      hash,
      preferredUserName,
      stag,
    })
    .then(({ data }) => data)
    .catch(parseApiError);

export const loginUser = ({
  email,
  password,
  token,
  loginCode,
}: {
  email: string;
  password: string;
  token: string;
  loginCode: string;
}) =>
  client
    .post(`${AUTH_PATH}/login`, {
      Email: email,
      Password: password,
      Token: token,
      LoginCode: loginCode,
    })
    .then(({ data }) => data)
    .catch(parseApiError);

export const registerUser = ({
  email,
  password,
  username,
  confirmUrl,
  stag,
  token,
  afdp,
}: {
  email: string;
  password: string;
  username: string;
  confirmUrl: string;
  stag: string;
  token: string;
  afdp: string;
}) =>
  client
    .post(`${AUTH_PATH}/register`, {
      Email: email,
      Password: password,
      UserName: username,
      EmailConfirmReturnUrl: confirmUrl,
      Stag: stag,
      Token: token,
      afdp,
    })
    .then(({ data }) => data)
    .catch(parseApiError);

export const loginUser2fa = ({
  twoFactorCode,
  identityCookie,
}: {
  twoFactorCode: string;
  identityCookie: string;
}) =>
  client
    .post(`${AUTH_PATH}/login-with-2fa`, {
      twoFactorCode,
      identityCookie,
    })
    .then(({ data }) => data)
    .catch(parseApiErrorClear);

export const loginUser2faRecoveryCode = ({
  recoveryCode,
  identityCookie,
}: {
  recoveryCode: string;
  identityCookie: string;
}) =>
  client
    .post(`${AUTH_PATH}/login-with-2fa-recovery-code`, {
      recoveryCode,
      identityCookie,
    })
    .then(({ data }) => data)
    .catch(parseApiErrorClear);

export const refreshToken = (token?: string, tokenForRefresh?: string) =>
  client
    .post(`${AUTH_PATH}/refreshToken`, {
      Token: token,
      refreshToken: tokenForRefresh,
    })
    .then(({ data }) => data);

export const forgotPassword = ({
  email,
  returnUrl,
}: {
  email: string;
  returnUrl: string;
}) =>
  client
    .post(`${AUTH_PATH}/forgot-password`, {
      Email: email,
      ReturnUrl: returnUrl,
    })
    .then(({ data }) => data)
    .catch(parseApiError);

export const resetPassword = ({
  email,
  password,
  code,
}: {
  email: string;
  password: string;
  code: string;
}) =>
  client
    .post(`${AUTH_PATH}/reset-password`, {
      Email: email,
      Password: password,
      Code: code,
    })
    .then(({ data }) => data)
    .catch(parseApiError);

export const get2faKeyUri = () =>
  client
    .get(`${AUTH_PATH}/get-2fa-key-uri`)
    .then(({ data }) => data)
    .catch(({ response: { data } }) => {
      throw data;
    });

export const validateField = (field: string, value: string) =>
  client
    .post(`/auth/validate/${field}`, { [field]: value })
    .then(({ data }) => data)
    .catch(parseApiError);
