import { PreviewGrid } from 'components/preview-grid';
import { ShowMore } from 'components/show-more';
import { CasinoIcon } from 'icons';
import { useState } from 'react';
import { ProviderPreview } from 'services/games/components/provider-preview';
import { PROVIDERS } from 'services/games/modules/slots';
import { EmptyResults } from 'uikit/empty-results';

export const ProvidersGrid = ({ limit = 28, search = '' }) => {
  const [page, setPage] = useState(1);
  const handleShowMore = () => setPage(prev => prev + 1);

  const providersList = search
    ? PROVIDERS.filter(p => p.id.includes(search.toLowerCase().trim()))
    : PROVIDERS;

  const list = limit ? providersList.slice(0, page * limit) : providersList;

  if (!list.length) {
    return <EmptyResults icon={CasinoIcon} />;
  }

  return (
    <>
      <PreviewGrid Item={ProviderPreview} list={list} />
      <ShowMore
        current={list.length}
        total={providersList.length}
        onClick={handleShowMore}
      />
    </>
  );
};
