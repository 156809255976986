import { WS_MESSAGE } from 'helpers/ws';
import { userWebsocket } from 'services/user/modules/user';
import { createReducer } from 'store';
import { WagerItemType } from 'types';
import { loadLiveWagers, loadWagerTotal } from './actions';
import { StateType } from './types';

const initialState: StateType = {
  feed: {
    all: [],
    'high-rollers': [],
    'lucky-wins': [],
    'only-wins': [],
    individual: [],
  },
  feedIsLoaded: {
    all: false,
    'high-rollers': false,
    'lucky-wins': false,
    'only-wins': false,
    individual: false,
  },
  feedError: {},
  total: 0,
  totalIsLoaded: false,
};

const LIVE_WAGERS_FEED_LENGTH = 10;

const createWsReducer =
  (type: string) =>
  (state: StateType, { payload }: { payload: WagerItemType[] }) => {
    const time = Date.now();
    state.feed[type] = payload
      .map(item => ({ ...item, time }) as WagerItemType)
      .concat(
        state.feed[type].map(item => ({
          ...item,
          isNew: false,
        })),
      )
      .slice(0, LIVE_WAGERS_FEED_LENGTH);

    if (type === 'all') {
      const list = payload.filter(i => i.wagerAmount < i.winAmount);
      if (list.length) {
        state.feed['only-wins'] = list
          .map(item => ({ ...item, time }) as WagerItemType)
          .concat(
            state.feed['only-wins'].map(item => ({ ...item, isNew: false })),
          )
          .slice(0, LIVE_WAGERS_FEED_LENGTH);
      }
    }
  };

export const reducer = createReducer(initialState, {
  [WS_MESSAGE(userWebsocket, 'WAGERFEED')]: createWsReducer('all'),
  [WS_MESSAGE(userWebsocket, 'WAGERFEED_HIGHROLLERS')]:
    createWsReducer('high-rollers'),
  [WS_MESSAGE(userWebsocket, 'WAGERFEED_LUCKYWINS')]:
    createWsReducer('lucky-wins'),
  [WS_MESSAGE(userWebsocket, 'WAGERFEED_INDIVIDUAL')]:
    createWsReducer('individual'),
  [WS_MESSAGE(userWebsocket, 'TOTALWAGERS')]: (state, { payload }) => {
    state.total = payload;
  },
  [loadLiveWagers.toString()]: (
    state: StateType,
    {
      payload,
      meta: { type, error },
    }: { payload: WagerItemType[]; meta: { type: string; error: string } },
  ) => {
    if (error) {
      state.feedIsLoaded[type] = true;
      state.feedError[type] = true;
    } else {
      state.feed[type] = payload
        .slice(0, LIVE_WAGERS_FEED_LENGTH)
        .map(item => ({ ...item, isNew: false, time: 0 }));
      state.feedIsLoaded[type] = true;
      delete state.feedError[type];
    }
  },
  [loadWagerTotal.toString()]: (state: StateType, { payload }) => {
    state.total = payload;
    state.totalIsLoaded = true;
  },
});
