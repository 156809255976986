import { Box, Button, Flex, FormControl } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { CustomInput } from 'forms/custom-input';
import { formatCredit, toNumber } from 'helpers/numeral';
import { getChannelId } from 'modules/chat';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { KYCRequiredForm } from 'services/user/components/kyc-required-form';
import { VerifyEmailForm } from 'services/user/components/verify-email-form';
import { sendRainTip } from 'services/user/modules/tip';
import {
  get2faInfo,
  getUser2FAEnabled,
  getUserEmailConfirmed,
  getUserRequiredVerify,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { toastError, toastSuccess } from 'toasts';
import { Errors, FormSubmit } from 'types';
import * as Yup from 'yup';

type Props = {
  onClose: () => void;
};

export const ChatRainForm: React.FC<Props> = ({ onClose }) => {
  const { t } = useTranslation();
  const [emailCodeId, setEmailCodeId] = useState(false);
  const is2FAEnabled = useSelector(getUser2FAEnabled);
  const isEmailConfirmed = useSelector(getUserEmailConfirmed);
  const isVerifyRequired = useSelector(getUserRequiredVerify);
  const actions = useActions({ sendRainTip, get2faInfo });
  const channelId = useSelector(getChannelId);

  useEffect(() => {
    actions.get2faInfo();
  }, [actions]);

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      numberOfUsers: '',
      tip: '',
      emailCode: '',
      twoFactorCode: '',
    },
    validationSchema: Yup.object({
      numberOfUsers: Yup.string().required(t('validations.required')),
      tip: Yup.string().required(t('validations.required')),
      ...(is2FAEnabled
        ? {
            twoFactorCode: Yup.string().required(t('validations.required')),
          }
        : {}),
      ...(emailCodeId
        ? {
            emailCode: Yup.string().required(t('validations.required')),
          }
        : {}),
    }),
    onSubmit: async ({
      numberOfUsers: numberOfUsersString,
      tip,
      twoFactorCode,
      emailCode,
    }) => {
      try {
        let result;
        const amount = toNumber(tip);
        const numberOfUsers = toNumber(numberOfUsersString);
        if (amount < 5) {
          toastError({
            title: 'Tip error',
            description: 'Minimum amount is 5$',
          });
          return;
        }
        if (numberOfUsers < 2) {
          toastError({
            title: 'Tip error',
            description: 'Minimum user count is 2',
          });
          return;
        }
        if (numberOfUsers > 25) {
          toastError({
            title: 'Tip error',
            description: 'Maximum user count is 25',
          });
          return;
        }
        // const token = await getToken();
        if (emailCodeId) {
          result = await actions.sendRainTip({
            loginCode: emailCode,
            loginCodeId: emailCodeId,
          });
        } else if (is2FAEnabled) {
          result = await actions.sendRainTip({
            numberOfUsers,
            amount,
            twoFactorCode,
            channelId,
          });
        } else {
          result = await actions.sendRainTip({
            numberOfUsers,
            amount,
            channelId,
          });
        }

        if (result?.isSentLoginCode) {
          setEmailCodeId(result.loginCodeId);
        } else {
          toastSuccess({
            title: t('tip.sent-success'),
            description: `You rained $${tip} to ${numberOfUsers} people`,
          });
          onClose();
        }
      } catch (errors: unknown) {
        const { withdrawhold, global: globalError } = errors as Errors;
        if (globalError === 'NOT_ENOUGH_BALANCE_BY_WITHDRAW_HOLD') {
          toastError({
            title: t('errors.BE.NOT_ENOUGH_BALANCE_BY_WITHDRAW_HOLD'),
            description: t(
              'errors.BE.NOT_ENOUGH_BALANCE_BY_WITHDRAW_HOLD_DESCRIPTION',
              {
                amount: `$${formatCredit(Number(withdrawhold))}`,
              },
            ),
          });
        } else if (errors) {
          const error = globalError || Object.values(errors)[0];
          toastError({
            title: t(`errors.BE.${error}`),
            description: t(`errors.BE.${error}_DESCRIPTION`, ''),
          });
        }
      }
    },
  });

  if (!isEmailConfirmed) {
    return <VerifyEmailForm isRounded={false} isChat />;
  }

  if (isVerifyRequired) {
    return <KYCRequiredForm isRounded={false} isChat />;
  }

  return (
    <Box borderTop="1px solid" borderColor="truffle-border" bg="toffee-base">
      <FormControl as="form" onSubmit={formik.handleSubmit as FormSubmit} p="5">
        <Flex flexDirection="column" gap="3" mb="5">
          {emailCodeId ? (
            <CustomInput
              key="emailCodeId"
              label="form.label.verification-code"
              placeholder="form.label.verification-code"
              type="text"
              name="emailCode"
              required
              autoComplete="one-time-code"
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.emailCode}
              isInvalid={!!formik.errors.emailCode && formik.touched.emailCode}
              errorMessage={formik.errors.emailCode}
            />
          ) : (
            <>
              <Flex gap="3" flexDirection={{ base: 'row', lg: 'column' }}>
                <CustomInput
                  label="Number Of Users"
                  placeholder="Number Of Users"
                  type="text"
                  name="numberOfUsers"
                  inputMode="decimal"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.numberOfUsers}
                  isInvalid={
                    !!formik.errors.numberOfUsers &&
                    formik.touched.numberOfUsers
                  }
                  errorMessage={formik.errors.numberOfUsers}
                />
                <CustomInput
                  label="wallet.amount-usd"
                  placeholder="wallet.amount-usd"
                  name="tip"
                  type="text"
                  inputMode="decimal"
                  required
                  autoComplete="transaction-amount"
                  value={formik.values.tip}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.tip && formik.touched.tip}
                  errorMessage={formik.errors.tip}
                />
              </Flex>

              {is2FAEnabled && (
                <CustomInput
                  key="2fa"
                  label="form.label.two-factor-code"
                  placeholder="form.label.two-factor-code"
                  type="text"
                  inputMode="numeric"
                  name="twoFactorCode"
                  required
                  autoComplete="one-time-code"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.twoFactorCode}
                  isInvalid={
                    !!formik.errors.twoFactorCode &&
                    formik.touched.twoFactorCode
                  }
                  errorMessage={formik.errors.twoFactorCode}
                />
              )}
            </>
          )}
        </Flex>
        <Button
          w="full"
          type="submit"
          colorScheme="purple"
          isLoading={formik.isSubmitting}
        >
          {t('chat.start-rain')}
        </Button>
      </FormControl>
    </Box>
  );
};
