import { createIcon } from '@chakra-ui/icons';

export const CasinoIcon = createIcon({
  displayName: 'CasinoIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.801 1.717c.412 0 .77.136 1.076.408.306.272.458.621.458 1.047v6.157l-2.83-7.612h1.296Zm3.394 1.332.82.365c.426.182.72.49.879.922.159.433.14.862-.058 1.287l-1.64 3.51V3.049ZM6.89 2.278a1.635 1.635 0 0 0-1.316-.045L1.18 3.803a1.811 1.811 0 0 0-1.002.896c-.21.419-.234.862-.075 1.33l2.586 7.116c.17.458.474.784.912.978.439.195.882.212 1.33.053l4.425-1.615a1.72 1.72 0 0 0 .98-.897 1.65 1.65 0 0 0 .05-1.33L7.802 3.28a1.68 1.68 0 0 0-.911-1.001Z"
      clipRule="evenodd"
    />
  ),
});
