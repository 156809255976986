import { useMemo } from 'react';
import { NFTType } from 'types';

export type NFTSortType = 'decrease' | 'rare_down' | 'rare_up' | 'increase';

export const useNftFilter = (
  nfts: NFTType[],
  sort: NFTSortType,
  search: string,
) => {
  const list = useMemo(() => {
    let items = nfts.concat([]);
    if (search) {
      items = nfts.filter(n => n.id.toString().includes(search));
    }

    if (sort === 'decrease') {
      return items.reverse();
    }

    if (sort === 'rare_down') {
      return items.sort((a, b) => b.rarityScore - a.rarityScore);
    }

    if (sort === 'rare_up') {
      return items.sort((a, b) => a.rarityScore - b.rarityScore);
    }

    return items;
  }, [nfts, search, sort]);

  return list;
};
