/* eslint-disable @typescript-eslint/no-explicit-any */
import { INTERCOM_APP_ID } from 'constants/general';
import { getIntercomToken } from 'helpers/client';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getUserLevel, getUserName } from 'services/user/modules/user';

let intercomUserName: string;

export const initializeIntercom = (
  userName: string,
  level: string,
  onLoad: () => void,
  isNeedShow = true,
) => {
  const i: any = function i() {
    i.c(arguments); // eslint-disable-line
  };
  i.q = [];
  i.c = (args: any) => {
    i.q.push(args);
  };
  window.Intercom = i;

  const s = document.createElement('script');
  s.type = 'text/javascript';
  s.async = true;
  s.src = `https://widget.intercom.io/widget/${INTERCOM_APP_ID}`;
  window.Intercom('onShow', onLoad);
  const x = document.getElementsByTagName('script')[0];
  if (x.parentNode) {
    x.parentNode.insertBefore(s, x);
  }

  window.Intercom('boot', {
    app_id: INTERCOM_APP_ID,
    ...(userName
      ? {
          name: userName,
          user_id: userName,
          level,
        }
      : {}),
    user_hash: getIntercomToken(),
    hide_default_launcher: true,
  });
  if (isNeedShow) {
    window.Intercom?.('show');
  }

  intercomUserName = userName;
};

const show = (userName: string, level: string, params: any) => {
  if (userName && intercomUserName !== userName) {
    window.Intercom('update', {
      name: userName,
      user_id: userName,
      user_hash: getIntercomToken(),
      level,
    });
    intercomUserName = userName;
  }

  if (params?.action) {
    window.Intercom?.(params.action, params.content);
  } else {
    window.Intercom?.('show');
  }
};

const hide = () => {
  window.Intercom?.('hide');
};

export const useSupportLazyInit = () => {
  const userName = useSelector(getUserName);
  const level = useSelector(getUserLevel);
  const stringLevel = level.toString();

  useEffect(() => {
    const timeout = setTimeout(() => {
      initializeIntercom(userName, stringLevel, () => {}, false);
    }, 3000);

    return () => {
      clearTimeout(timeout);
    };
  }, [userName, stringLevel]);
};

export const useSupport = () => {
  const userName = useSelector(getUserName);
  const level = useSelector(getUserLevel);
  const stringLevel = level.toString();
  const [isLoading, setIsLoading] = useState(false);

  const handleSupport = async (params?: any) => {
    const callback = () => {
      const elementCount =
        document.querySelector('.intercom-app')?.childElementCount;
      if (elementCount && elementCount > 2) {
        hide();
      } else {
        show(userName, stringLevel, params);
      }
    };
    if (!window.Intercom) {
      setIsLoading(true);
      initializeIntercom(userName, stringLevel, () => {
        setIsLoading(false);
      });
    } else {
      callback();
    }
  };

  return { toggle: handleSupport, isLoading };
};
