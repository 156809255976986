import { createIcon } from '@chakra-ui/icons';

export const RollbitLogo = createIcon({
  displayName: 'RollbitLogo',
  viewBox: '0 0 1359 266',
  path: (
    <svg
      width="1359"
      height="266"
      viewBox="0 0 1359 266"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_di_1537_40)">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M499.27 132.875C499.27 62.8032 441.974 6 371.296 6C367.997 6 364.727 6.12371 361.491 6.3668L195.365 15.0626L346.615 31.3751L293.558 32.0825C277.821 44.0303 264.963 59.5184 256.197 77.3469L217.117 83.0309L251.405 88.399C246.179 102.24 243.321 117.226 243.321 132.875C243.321 148.524 246.179 163.51 251.405 177.351L217.117 182.719L256.197 188.403C264.961 206.227 277.813 221.71 293.544 233.657L346.615 234.375C294.251 238.268 264.017 244.578 211.686 250.687L361.491 259.383C364.727 259.626 367.997 259.75 371.296 259.75C441.974 259.75 499.27 202.946 499.27 132.875ZM323.305 132.875C323.305 159.152 344.791 180.453 371.296 180.453C397.8 180.453 419.286 159.152 419.286 132.875C419.286 106.598 397.8 85.2969 371.296 85.2969C344.791 85.2969 323.305 106.598 323.305 132.875Z"
          fill="#9494a5"
        />
      </g>
      <path
        d="M1084.3 250.682C1079.25 250.682 1075.15 246.588 1075.15 241.54V24.1997C1075.15 19.1504 1079.25 15.0571 1084.3 15.0571H1148.28C1153.33 15.0571 1157.42 19.1504 1157.42 24.1997V241.54C1157.42 246.588 1153.33 250.682 1148.28 250.682H1084.3Z"
        fill="#9494a5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M169.109 160.057C187.446 148.577 198.903 124.072 201.102 96.6191C198.903 39.2479 163.259 15.0571 95.9807 15.0571H9.14107C4.09262 15.0571 0 19.1504 0 24.1997V241.54C0 246.588 4.09262 250.682 9.14107 250.682H68.2405C73.4641 250.682 77.6987 246.447 77.6987 241.222V173.65H95.9807L121.24 244.606C122.538 248.249 125.986 250.682 129.852 250.682H207.39C210.795 250.682 213.004 247.092 211.471 244.052L169.109 160.057ZM95.9804 73.9643C110.093 73.9643 114.263 77.82 114.263 96.6204C114.263 116.706 109.451 119.276 95.9804 119.276H77.6984V73.9643H95.9804Z"
        fill="#9494a5"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M1061.44 178.182C1061.44 223.349 1032.4 250.682 965.463 250.682H869.482C864.434 250.682 860.341 246.588 860.341 241.54V24.1997C860.341 19.1504 864.434 15.0571 869.482 15.0571H965.463C1025.45 15.0571 1053.87 40.8187 1052.3 78.495C1053.87 101.453 1042.19 117.161 1024.88 123.807V128.338C1048.82 135.069 1061.44 153.604 1061.44 178.182ZM983.745 178.183C983.745 164.181 981.241 160.058 970.033 160.058H938.039V191.777H970.033C981.241 191.777 983.745 188.922 983.745 178.183ZM965.463 78.495C976.082 78.495 979.174 81.7113 979.174 92.0897C979.174 102.467 976.699 105.683 965.463 105.683H938.039V78.495H965.463Z"
        fill="#9494a5"
      />
      <path
        d="M765.549 185.021H837.267C842.401 185.021 846.529 189.248 846.406 194.381L845.277 241.757C845.157 246.72 841.102 250.682 836.138 250.682H695.802C690.753 250.682 686.661 246.588 686.661 241.54V24.1997C686.661 19.1504 690.753 15.0571 695.802 15.0571H756.408C761.457 15.0571 765.549 19.1504 765.549 24.1997V185.021Z"
        fill="#9494a5"
      />
      <path
        d="M591.87 185.021H663.588C668.722 185.021 672.848 189.248 672.726 194.381L671.596 241.757C671.478 246.72 667.421 250.682 662.458 250.682H522.123C517.074 250.682 512.98 246.588 512.98 241.54V24.1997C512.98 19.1504 517.074 15.0571 522.123 15.0571H582.729C587.777 15.0571 591.87 19.1504 591.87 24.1997V185.021Z"
        fill="#9494a5"
      />
      <path
        d="M1358.31 71.6729C1358.42 76.805 1354.3 81.0325 1349.16 81.0325H1304.5V241.54C1304.5 246.588 1300.4 250.682 1295.35 250.682H1234.61C1229.57 250.682 1225.48 246.588 1225.48 241.54V81.0325H1180.5C1175.36 81.0325 1171.24 76.805 1171.36 71.6729L1172.49 23.9833C1172.6 19.0197 1176.66 15.0571 1181.62 15.0571H1348.04C1353.01 15.0571 1357.07 19.0197 1357.18 23.9834L1358.31 71.6729Z"
        fill="#9494a5"
      />
    </svg>
  ),
});
