import { Flex, Text } from '@chakra-ui/react';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { UserType } from 'types';
import { pickedGroupToColor } from '../../utils';
import { PlayerList } from './components/player-list';

type Props = {
  bonusNumber: number;
  entries: {
    userId: string;
    wagerGroup: number;
    amount: number;
  }[];
  users: { [key: string]: UserType };
  settings: { wagerGroups: number[] };
};

export const PlayerLists: React.FC<Props> = ({
  bonusNumber,
  entries = [],
  settings,
  users,
}) => {
  const { t } = useTranslation();
  const wagerGroupInfos = useMemo(
    () =>
      entries.reduce(
        (acc, entry) => {
          const { wagerGroup } = entry;
          acc[wagerGroup] = {
            entries: acc[wagerGroup].entries.concat([entry]),
            total: (acc[wagerGroup].total += entry.amount),
          };
          return acc;
        },
        settings.wagerGroups.reduce(
          (acc, groupId) => ({
            ...acc,
            [groupId]: { entries: [], total: 0 },
          }),
          {},
        ) as {
          [key: number]: {
            entries: {
              userId: string;
              wagerGroup: number;
              amount: number;
            }[];
            total: number;
          };
        },
      ),
    [settings, entries],
  );

  return (
    <Flex
      flexDirection="column"
      gap="2"
      overflow="auto"
      justifyContent="space-between"
    >
      <PlayerList
        {...wagerGroupInfos[0]}
        users={users}
        bonusNumber={bonusNumber}
        title={
          <span style={{ color: pickedGroupToColor(0) }}>
            {t('roulette.red')}
          </span>
        }
      />
      <PlayerList
        total={wagerGroupInfos[1].total + wagerGroupInfos[3].total}
        entries={wagerGroupInfos[1].entries.concat(wagerGroupInfos[3].entries)}
        users={users}
        bonusNumber={bonusNumber}
        hasCount
        title={
          <>
            <span style={{ color: pickedGroupToColor(1) }}>
              {t('roulette.green')}
            </span>{' '}
            <Text as="span" color="candy-floss-text">
              +
            </Text>{' '}
            <span style={{ color: pickedGroupToColor(3, bonusNumber) }}>
              {t('roulette.bonus')}
            </span>
          </>
        }
      />
      <PlayerList
        {...wagerGroupInfos[2]}
        users={users}
        bonusNumber={bonusNumber}
        title={t('roulette.black')}
      />
    </Flex>
  );
};
