import { createIcon } from '@chakra-ui/icons';

export const BNBIcon = createIcon({
  displayName: 'BNBIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <g clipPath="url(#a)">
        <path fill="#F3BA2F" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z" />
        <path
          fill="#fff"
          d="M6.058 7.202 8 5.26l1.943 1.943 1.13-1.13L8 3 4.928 6.072l1.13 1.13ZM3 8l1.13-1.13L5.26 8 4.13 9.13 3 8Zm3.058.798L8 10.74l1.943-1.943 1.13 1.13L8 13 4.928 9.928l-.002-.002 1.132-1.128ZM10.74 8l1.13-1.13L13 8l-1.13 1.13L10.74 8Zm-1.594-.001h.001V8L8 9.147 6.854 8.002 6.853 8l.002-.002.201-.2.098-.098L8 6.853 9.146 8v-.001Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </>
  ),
});
