import { VERSUS_HANDLER_ID } from 'constants/general';
import { createAction } from 'store';
import * as api from './api';

export const loadGames = createAction(
  'versus/loadGames',
  () => () => api.loadGames(),
  () => ({ handlerId: VERSUS_HANDLER_ID }),
);

export const placeWager = createAction(
  'versus/placeWager',
  ({ gameId, amount, position, isBotInvite }) =>
    () =>
      api.placeWager({
        gameId,
        amount,
        position,
        isBotInvite,
      }),
);

export const setFinished = createAction('versus/setFinished', gameId => gameId);

export const createGame = createAction(
  'versus/createGame',
  ({ total, amount, position, players }) =>
    () =>
      api.createGame({
        total,
        amount,
        position,
        players,
      }),
);

export const loadVersusHistory = createAction(
  'versus/loadVersusHistory',
  ({ before = null, id = null, limit = 10 } = {}) =>
    () =>
      api.loadVersusHistory({ id, before, limit }),
);
