import { createIcon } from '@chakra-ui/icons';

export const CopyIcon = createIcon({
  displayName: 'CopyIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path
        fill="currentColor"
        d="M11.996 2H5a3 3 0 0 0-3 3v6.996c-1.298-.064-2-.825-2-2.178V2.182C0 .768.768 0 2.182 0h7.636c1.353 0 2.114.702 2.178 2Z"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M6.182 4h7.636C15.233 4 16 4.768 16 6.182v7.636C16 15.233 15.232 16 13.818 16H6.182C4.768 16 4 15.232 4 13.818V6.182C4 4.768 4.768 4 6.182 4Z"
        clipRule="evenodd"
      />
    </>
  ),
});
