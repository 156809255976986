import { Box, Button, Flex, Image, Text } from '@chakra-ui/react';
import { imageBuilder } from 'helpers/sanity';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { loadStreamers } from 'services/cms/modules/streamer/api';
import { CircleIndicator } from 'uikit/circle-indicator';
import { Link } from 'uikit/link';
import { NotificationTip } from 'uikit/notification-tip';

interface Stream {
  id: string;
  avatar: any;
  username: string;
  title: string;
  tags: string[];
  thumbnail: any;
  isOnline: boolean;
}

export const KickStyleStreamSection: React.FC = () => {
  const [streams, setStreams] = useState<Stream[]>([]);
  const [selectedStream, setSelectedStream] = useState<Stream | null>(null);
  const [isMuted, setIsMuted] = useState(true);
  const [isVisible, setIsVisible] = useState(true);
  const [hasOnlineStreamers, setHasOnlineStreamers] = useState(false);

  useEffect(() => {
    const fetchStreamers = async () => {
      const fetchedStreams = await loadStreamers();
      const sortedStreams = fetchedStreams.sort((a: Stream, b: Stream) => {
        if (a.isOnline === b.isOnline) {
          return 0;
        }
        return a.isOnline ? -1 : 1;
      });
      setStreams(sortedStreams);
      setSelectedStream(sortedStreams[0]);
      setHasOnlineStreamers(
        sortedStreams.some((stream: Stream) => stream.isOnline),
      );
    };
    fetchStreamers();
  }, []);

  const handleStreamChange = useCallback((stream: Stream) => {
    setSelectedStream(stream);
    setIsMuted(true);
  }, []);

  const toggleMute = useCallback(() => {
    setIsMuted(prevMuted => !prevMuted);
  }, []);

  const hideSection = useCallback(() => {
    setIsVisible(false);
  }, []);

  const streamElements = useMemo(
    () =>
      streams.map(stream => (
        <Box
          key={stream.id}
          position="relative"
          cursor="pointer"
          h="24"
          w="full"
          rounded="xl"
          transition="transform 0.15s cubic-bezier(0.4, 0, 0.2, 1)"
          border="4px solid"
          borderColor={
            stream.id === selectedStream?.id ? 'burnt-sugar' : 'burnt-sugar'
          }
          outline={
            stream.id === selectedStream?.id
              ? '1px solid var(--chakra-colors-sherbet-purple)'
              : 'none'
          }
          _active={{ transform: 'scale(0.98)' }}
          onClick={() => handleStreamChange(stream)}
        >
          <Box
            position="absolute"
            top="0"
            left="0"
            right="0"
            bottom="0"
            bgImage={`url(${imageBuilder.image(stream.thumbnail).url()})`}
            bgSize="cover"
            bgRepeat="no-repeat"
            bgPos="center"
            opacity={stream.isOnline ? 1 : 0.25}
            _hover={stream.isOnline ? { opacity: '0.9' } : undefined}
          />
        </Box>
      )),
    [streams, selectedStream, handleStreamChange],
  );

  if (!isVisible || !selectedStream || !hasOnlineStreamers) return null;

  return (
    <Flex
      flexDirection="column"
      overflow="hidden"
      display={{ base: 'none', lg: 'flex' }}
    >
      <Flex justifyContent="end" w="full" mb="3">
        <Button variant="link" onClick={hideSection}>
          Hide
        </Button>
      </Flex>
      <Box w="full" bg="sugar-dust" p="5" overflow="hidden">
        <Flex
          align="center"
          gap="14"
          justifyContent="space-between"
          w="full"
          h="full"
        >
          <Flex
            flexDirection="column"
            gap="7"
            h="full"
            justifyContent="space-between"
            w="50%"
          >
            <Box>
              <Image
                src={imageBuilder.image(selectedStream.avatar).url()}
                boxSize="40px"
                rounded="full"
                mb="3"
              />
              <Box>
                <Box display="inline-flex">
                  <Link
                    _hover={{ opacity: 0.9, textDecoration: 'underline' }}
                    to={`https://kick.com/${selectedStream.username}`}
                  >
                    <Text fontSize="xl" fontWeight="500" noOfLines={1}>
                      {selectedStream.title}
                    </Text>
                  </Link>
                </Box>
                <Flex align="center" gap="2">
                  {selectedStream.isOnline ? (
                    <>
                      <CircleIndicator color="sherbet-red" />
                      <Text color="candy-floss-text">
                        Streaming from {selectedStream.username}
                      </Text>
                    </>
                  ) : (
                    <Text color="candy-floss-text">
                      {selectedStream.username} is offline
                    </Text>
                  )}
                </Flex>
                <Flex mt="2" gap="2">
                  {selectedStream.tags.map(tag => (
                    <NotificationTip key={tag} notifications={tag} />
                  ))}
                </Flex>
              </Box>
            </Box>

            <Flex align="center" gap="3">
              {streamElements}
            </Flex>
          </Flex>

          <Box position="relative" width="50%" h="full" overflow="hidden">
            <Box
              as="iframe"
              src={`https://player.kick.com/${selectedStream.username}?muted=${isMuted}`}
              width="100%"
              h="full"
              overflow="hidden"
            />
            {isMuted && (
              <Box overflow="hidden">
                <Box
                  overflow="hidden"
                  position="absolute"
                  top={0}
                  left={0}
                  right={0}
                  bottom={0}
                  onClick={toggleMute}
                  cursor="pointer"
                />

                <Box
                  overflow="hidden"
                  position="absolute"
                  top={0}
                  left={0}
                  bottom={0}
                  width="150%"
                  background="linear-gradient(to left, rgba(0,0,0,0), oklch(24.4% 0.023 280.8))"
                  pointerEvents="none"
                />
              </Box>
            )}
          </Box>
        </Flex>
      </Box>
    </Flex>
  );
};
