import { Box, Flex } from '@chakra-ui/react';
import styles from './style.module.scss';

export const BeatLoader = () => (
  <Flex gap="1" align="center">
    <Box
      className={styles.first}
      h="6px"
      w="6px"
      bg="currentColor"
      rounded="full"
    />
    <Box
      className={styles.second}
      h="6px"
      w="6px"
      bg="currentColor"
      rounded="full"
    />
    <Box
      className={styles.third}
      h="6px"
      w="6px"
      bg="currentColor"
      rounded="full"
    />
  </Flex>
);
