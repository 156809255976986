import { createIcon } from '@chakra-ui/icons';

export const BonusesIcon = createIcon({
  displayName: 'BonusesIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          d="M5.793 1.659a1.38 1.38 0 0 0 0 2.758h1.38v3.31H1.378A1.38 1.38 0 0 1 0 6.349v-.551c0-.762.618-1.38 1.38-1.38h2.348a2.483 2.483 0 0 1 3.996-2.94 2.483 2.483 0 0 1 3.996 2.94h2.9c.762 0 1.38.618 1.38 1.38v.551a1.38 1.38 0 0 1-1.38 1.38H8.277v-3.31h1.38a1.38 1.38 0 1 0-1.38-1.38v1.38H7.172v-1.38a1.38 1.38 0 0 0-1.379-1.38Zm1.38 7.172h-6.07v4.966a1.655 1.655 0 0 0 1.656 1.655h4.413v-6.62Zm1.103 0v6.62h4.965a1.655 1.655 0 0 0 1.656-1.654V8.83H8.276Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </>
  ),
});
