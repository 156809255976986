/* eslint-disable arrow-body-style */
import { Box, Button, Flex, Text } from '@chakra-ui/react';
import { PreviewSlider } from 'components/preview-slider';
import { Link } from 'uikit/link';

const grid = [{ pixels: 0, count: 1 }];

const colors = ['#24a389', '#3a3994', '#d46529'];

const SlidePreview = ({ title, button, link, text, style, index }) => (
  <Flex
    style={style}
    p="1"
    minH={{ base: '144px', md: '172px' }}
    maxH={{ base: '144px', md: '172px' }}
    w="full"
  >
    <Flex
      bg={colors[index]}
      flexDirection="column"
      rounded="xl"
      w="full"
      p="5"
      h="full"
      flexDir="column"
      justifyContent="space-between"
    >
      <Box>
        <Text
          color="vanilla-text !important"
          fontSize="lg"
          fontWeight="500"
          mb="1 !important"
        >
          {title}
        </Text>
        <Text color="vanilla-text !important" opacity="0.75 !important">
          {text}
        </Text>
      </Box>
      <Box>
        <Button
          mt="auto"
          as={Link}
          to={link}
          rounded="full"
          size="sm"
          bg="vanilla-text"
          color={`${colors[index]}!important`}
          _hover={{
            opacity: '0.9',
          }}
        >
          {button}
        </Button>
      </Box>
    </Flex>
  </Flex>
);

export const Slider = ({ title, slides }) => {
  return (
    <Box className="custom">
      <PreviewSlider
        title={title}
        hasButtons
        slides={slides}
        isIgnoreGap
        grid={grid}
        SlideComponent={SlidePreview}
        hasHeader
      />
    </Box>
  );
};
