import { createAction } from 'store';
import * as api from './api';

export const loadFeed = createAction(
  'social/loadFeed',
  ({ before, limit = 25 } = {}) =>
    () =>
      api.loadFeed({ before, limit }),
  params => params,
);

export const addReaction = createAction(
  'social/addReaction',
  ({ isAdd, reaction, postId }) =>
    () =>
      api.addReaction({ postId, reaction: isAdd ? reaction : 0 }),
  params => params,
);

// export const getFeed = (before = null, limit = 50) =>
//   api(
//     'SOCIAL',
//     SocialActionTypes.GET_FEED,
//     `/social/feed`,
//     'GET',
//     {
//       before,
//       limit,
//     },
//     {},
//   );

// export const getUserFeed = (before = null, limit = 50) =>
//   api(
//     'SOCIAL',
//     SocialActionTypes.GET_MY_FEED,
//     `/social/user/feed`,
//     'GET',
//     {
//       before,
//       limit,
//     },
//     {},
//   );

// export const socialComment = (postId, commentId, message) =>
//   api('SOCIAL', SocialActionTypes.COMMENT, `/social/feed/comment`, 'POST', {
//     postId,
//     commentId,
//     message,
//   });

// export const socialReact = ({ postId, commentId, reaction }) =>
//   api('SOCIAL', SocialActionTypes.REACT, `/social/feed/react`, 'POST', {
//     postId,
//     commentId,
//     reaction,
//   });

// export const deleteComment = (postId, commentId) =>
//   api(
//     'SOCIAL',
//     SocialActionTypes.DELETE_COMMENT,
//     `/user/feed/${postId}/${commentId}/delete`,
//     'POST',
//     {},
//   );

// export const setPostVisibility = (postId, commentId, visible) =>
//   api(
//     'SOCIAL',
//     SocialActionTypes.SET_POST_VISIBILITY,
//     `user/feed/${postId}/visibility`,
//     'POST',
//     {
//       postId,
//       commentId,
//       visible,
//     },
//   );
