import { client, parseApiError } from 'helpers/client';

export const loadFeed = ({
  limit,
  before = null,
}: {
  limit: number;
  before?: string | null;
}) =>
  client
    .get(`/chat/social/feed?limit=${limit}&before=${before}`)
    .then(({ data }) => data.payload);

export const addReaction = (params: object) =>
  client
    .post('/chat/social/feed/react', params)
    .then(({ data }) => data)
    .catch(parseApiError);
