import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getSlotsIsFavoritesLoaded,
  getSlotsIsFriendsPopularLoaded,
  getSlotsIsRecentLoaded,
  loadFavorites,
  loadPopularWithFriends,
  loadRecentGames,
} from 'services/games/modules/slots';
import { getUserIsAuthorized } from 'services/user/modules/user';
import { useActions } from 'store';
import { Casino } from './casino';

export const Connector = ({
  isProvidersList,
}: {
  isProvidersList?: boolean;
}) => {
  const { category } = useParams();
  const isFavorites = category === 'favourites';
  const isRecent = category === 'recent';
  const isAuthorized = useSelector(getUserIsAuthorized);
  const isRecentLoaded = useSelector(getSlotsIsRecentLoaded);
  const isFavoritesLoaded = useSelector(getSlotsIsFavoritesLoaded);
  const isPopularLoaded = useSelector(getSlotsIsFriendsPopularLoaded);

  const actions = useActions({
    loadPopularWithFriends,
    loadRecentGames,
    loadFavorites,
  });

  useEffect(() => {
    if (isRecent && !isRecentLoaded && isAuthorized) {
      actions.loadRecentGames();
    }
  }, [isAuthorized, isRecentLoaded, isRecent, actions]);

  useEffect(() => {
    if (isFavorites && !isFavoritesLoaded && isAuthorized) {
      actions.loadFavorites();
    }
  }, [isFavorites, isFavoritesLoaded, isAuthorized, actions]);

  useEffect(() => {
    if (!isPopularLoaded && isAuthorized) {
      actions.loadPopularWithFriends();
    }
  }, [actions, isPopularLoaded, isAuthorized]);

  return (
    <Casino
      isProvidersList={isProvidersList}
      isRecentLoaded={isRecent ? isRecentLoaded : undefined}
      isFavoritesLoaded={isFavorites ? isFavoritesLoaded : undefined}
    />
  );
};
