import { StateType as State } from './types';

type StateType = {
  nft: State;
};

export const getRandomNfts = (state: StateType) => state.nft.randomNfts;
export const getIsRandomNftsLoaded = (state: StateType) =>
  state.nft.randomIsLoaded;

export const getTotalNftsStaked = (state: StateType) =>
  state.nft.totalNftsStaked;
export const getNftInfoIsLoaded = (state: StateType) =>
  state.nft.nftInfoIsLoaded;

export const getNfts = (state: StateType) => state.nft.nfts;
export const getNftIsLoaded = (state: StateType) => state.nft.isLoaded;

export const getSelectedNft = (state: StateType) => state.nft.selectedNft;

export const getOwnedNfts = (state: StateType) => state.nft.ownedNfts;
export const getOwnedNftsIsLoaded = (state: StateType) =>
  state.nft.ownedNftsIsLoaded;

export const getVerifiedAddresses = (state: StateType) =>
  state.nft.verifiedAddresses;
export const getVerifiedAddressesIsLoaded = (state: StateType) =>
  state.nft.verifiedAddressesIsLoaded;

export const getNftStakingReward = (state: StateType) =>
  state.nft.stakingReward;

export const getOwnedStakedNftCount = (state: StateType) =>
  getOwnedNfts(state).filter(nft => nft.isStaked).length;

export const getNftDetails = (id: number) => (state: StateType) =>
  state.nft.nftsDetails[id];

export const getNftSales = (state: StateType) => state.nft.nftSales;
export const getNftSalesIsLoaded = (state: StateType) =>
  state.nft.nftSalesIsLoaded;
