/* eslint-disable react/no-unused-prop-types */
import {
  Box,
  Button,
  Flex,
  Popover,
  PopoverAnchor,
  PopoverContent,
} from '@chakra-ui/react';
import { ENFlagIcon } from 'icons';
import { changeChannel, getChannels, getNewMessages } from 'modules/chat';
import { useSelector } from 'react-redux';
import { useActions } from 'store';
import { NotificationTip } from 'uikit/notification-tip';
import { UserAvatar } from 'uikit/user-avatar';

const icons = {
  English: ENFlagIcon,
};

type Props = {
  onClose: () => void;
};

type PrivateGroupType = {
  title: React.ReactNode;
  value: number;
  avatar: string;
  name: string;
  lastMessageTimestamp: number;
};

type PublicGroupType = {
  title: React.ReactNode;
  value: number;
  chatName: string;
};

export const ChatRoomSwitcher: React.FC<Props> = ({ onClose }) => {
  const channels = useSelector(getChannels);
  const newMessages = useSelector(getNewMessages);
  const actions = useActions({ changeChannel });

  const defaultGroups: {
    public: PublicGroupType[];
    private: PrivateGroupType[];
  } = {
    public: [],
    private: [],
  };

  const chatGroups = Object.entries(channels).reduce(
    (
      acc,
      [id, { name, private: priv, avatar, visible, lastMessageTimestamp }],
    ) => {
      if (priv) {
        if (visible) {
          acc.private.push({
            title: (
              <Flex as="span" align="center">
                {name}
                <NotificationTip ml="2" notifications={newMessages[id]} />
              </Flex>
            ),
            value: Number(id),
            avatar,
            name,
            lastMessageTimestamp,
          });
        }
      } else {
        acc.public.push({
          title: (
            <Flex as="span" align="center">
              {name}
              <NotificationTip ml="2" notifications={newMessages[id]} />
            </Flex>
          ),
          chatName: name,
          value: Number(id),
        });
      }
      return acc;
    },
    defaultGroups,
  );

  const groups = [
    { title: 'Chat Rooms', options: chatGroups.public },
    {
      title: 'Private',
      options: chatGroups.private
        .sort((a, b) => b.lastMessageTimestamp - a.lastMessageTimestamp)
        .slice(0, 3),
      type: 'private',
    },
  ];

  const renderOptions = (
    opt: PublicGroupType[] | PrivateGroupType[],
    type?: string,
  ) =>
    opt.map(
      ({
        value,
        title,
        avatar,
        name,
        chatName,
      }: {
        value: number;
        title: React.ReactNode;
        avatar?: string;
        name?: string;
        chatName?: string;
      }) => {
        const Icon = icons[chatName as keyof typeof icons];

        return (
          <Box key={value}>
            <Button
              data-group
              onClick={() => {
                actions.changeChannel(value);
                onClose();
              }}
              w="full"
              rounded="full"
              bg="none"
              border="none"
              justifyContent="left"
              leftIcon={
                type === 'private' ? (
                  <UserAvatar
                    size="xs"
                    icon={avatar}
                    name={name}
                    flexShrink="0"
                  />
                ) : (
                  Icon && <Icon />
                )
              }
            >
              {title}
            </Button>
          </Box>
        );
      },
    );

  return (
    <Popover isOpen gutter={0} matchWidth>
      <PopoverContent
        border="none"
        w="100%"
        borderRadius="none"
        variants={{
          exit: {
            scale: 1,
          },
          enter: {
            scale: 1,
          },
        }}
      >
        <Box
          borderBottom="1px solid"
          borderColor="truffle-border"
          p="2"
          bg="toffee-base"
        >
          <Box maxH="300px" overflow="auto">
            {groups.map(({ title, options, type }) => (
              <Box key={title}>{renderOptions(options, type)}</Box>
            ))}
          </Box>
        </Box>
      </PopoverContent>
      <PopoverAnchor>
        <Box />
      </PopoverAnchor>
    </Popover>
  );
};
