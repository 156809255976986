import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { toastSuccess } from 'toasts';

export const DepositRedirect = () => {
  const { t } = useTranslation();
  useEffect(() => {
    toastSuccess({ title: t('wallet.deposit-success') });
  }, [t]);

  return <Navigate to="/" />;
};
