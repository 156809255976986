import {
  Modal,
  ModalOverlay,
  ModalProps,
  useBreakpointValue,
} from '@chakra-ui/react';
import { RemoveFriendModal } from 'services/user/modals/remove-friend-modal';
import { MODAL_TYPE } from './constants';
import './styles.scss';

const MODAL_CONTENT = {
  [MODAL_TYPE.REMOVE_FRIEND_MODAL]: RemoveFriendModal,
};

export type ModalType = {
  id: string;
  type: keyof typeof MODAL_TYPE;
  isOpen: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contentProps: any;
  modalProps: ModalProps;
  onCloseComplete: () => void;
  onClose: () => void;
};

type Props = {
  modal: ModalType;
};

export const ModalView: React.FC<Props> = ({
  modal: {
    type,
    isOpen,
    onClose,
    contentProps,
    modalProps = {},
    onCloseComplete,
  },
}) => {
  const Content = MODAL_CONTENT[type] ?? (() => null);
  const size = useBreakpointValue(
    { base: 'full', lg: 'max-content' },
    { ssr: false },
  );

  return (
    <Modal
      closeOnOverlayClick
      closeOnEsc
      size={size}
      scrollBehavior="inside"
      blockScrollOnMount
      isCentered
      preserveScrollBarGap
      onCloseComplete={onCloseComplete}
      trapFocus={false}
      {...modalProps}
      motionPreset="none"
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalOverlay />
      <Content {...contentProps} onClose={onClose} />
    </Modal>
  );
};
