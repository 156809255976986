import { Dispatch, Middleware } from 'redux';

const isPromise = (val: unknown) => val instanceof Promise;

type MetaType = {
  _loading?: boolean;
  _error?: boolean;
  [prop: string]: unknown;
};

const dispatchPromise = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dispatch: Dispatch<any>,
  action: {
    meta: MetaType;
    payload: Promise<unknown>;
  },
) => {
  const { _loading, _error, ...meta } = action.meta || {};

  return action.payload
    .then(result => {
      try {
        dispatch({
          ...action,
          meta: {
            ...meta,
            loading: false,
          },
          payload: result,
        });
      } catch (e) {
        console.error(e);
      }

      return result;
    })
    .catch(error => {
      if (_error) {
        dispatch({
          ...action,
          payload: error,
          meta: {
            ...meta,
            loading: false,
            error: true,
          },
        });
      } else if (_loading) {
        dispatch({
          ...action,
          payload: null,
          meta: {
            ...meta,
            loading: false,
            error: true,
          },
        });
      }

      throw error;
    });
};

export const reduxThunkPromiseMiddleware: Middleware =
  ({ dispatch }) =>
  next =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (action: any) => {
    if (!isPromise(action && action.payload)) {
      return next(action);
    }

    if (action.meta?._loading) {
      const { _loading, _error, ...meta } = action.meta || {};
      dispatch({
        ...action,
        payload: null,
        meta: {
          ...meta,
          loading: true,
        },
      });
    }

    return dispatchPromise(dispatch, action);
  };
