import { createIcon } from '@chakra-ui/icons';

export const QuoteIcon = createIcon({
  displayName: 'QuoteIcon',
  viewBox: '0 0 91 72',
  path: (
    <>
      <path
        d="M52.668 40.001C51.568 40.001 50.668 39.101 50.668 38.001V2C50.668 0.9 51.568 0 52.668 0H88.668C89.768 0 90.668 0.9 90.668 2V38.001C90.668 39.101 90.208 40.774 89.647 41.72L73.17 69.438C72.608 70.383 71.249 71.116 70.15 71.065L60.505 70.624C59.406 70.573 58.712 69.656 58.962 68.585L65.185 41.946C65.435 40.875 64.74 39.999 63.64 39.999L52.668 40.001Z"
        fill="currentColor"
      />
      <path
        d="M2 40.001C0.9 40.001 0 39.101 0 38.001V2C0 0.9 0.9 0 2 0H38C39.1 0 40 0.9 40 2V38.001C40 39.101 39.54 40.774 38.978 41.721L22.503 69.438C21.941 70.384 20.582 71.117 19.483 71.066L9.838 70.625C8.739 70.574 8.045 69.657 8.295 68.586L14.518 41.946C14.768 40.875 14.073 39.999 12.973 39.999L2 40.001Z"
        fill="currentColor"
      />
    </>
  ),
});
