import { EMAIL_CONFIRMATION_LINK } from 'constants/general';
import {
  getRefreshToken,
  getToken,
  removeRefreshToken,
  setIntercomToken,
  setRefreshToken,
  setToken,
} from 'helpers/client';
import { ws } from 'helpers/ws';
import { chatWebsocket } from 'modules/chat';
import { loadUser, userWebsocket } from 'services/user/modules/user';
import { createAction } from 'store';
import { AnyDispatch } from 'types';
import * as api from './api';

const socketAuthorization = (dispatch: AnyDispatch, token: string) => {
  dispatch(
    ws(userWebsocket, 'auth', {
      token,
    }),
  );
  dispatch(
    ws(chatWebsocket, 'auth', {
      token,
    }),
  );
};

export const telegramAuth = createAction(
  'auth/telegramAuth',
  ({ displayName } = {}) =>
    async (dispatch: AnyDispatch) => {
      const {
        query_id: queryId,
        user,
        auth_date: authDate,
        hash,
      } = window.Telegram.WebApp.initDataUnsafe;
      const { jwtToken, refreshToken, intercomToken, requireDisplayName } =
        await api.telegramAuth({
          queryId,
          telegramUserObject: JSON.stringify(user),
          authDate,
          hash,
          preferredUserName: displayName || String(user.id) || '',
          stag: localStorage.getItem('stag') || '',
        });

      if (requireDisplayName) {
        return {
          requireDisplayName,
        };
      }
      setToken(jwtToken);
      setRefreshToken(refreshToken);
      setIntercomToken(intercomToken);
      await dispatch(loadUser());
      socketAuthorization(dispatch, jwtToken);
      return {};
    },
);

export const googleAuth = createAction(
  'auth/googleAuth',
  params => async (dispatch: AnyDispatch) => {
    const {
      jwtToken,
      refreshToken,
      intercomToken,
      requireDisplayName,
      accessToken,
      requireLoginWith2FA,
      identityCookie,
    } = await api.googleAuth(params);

    if (requireDisplayName) {
      return {
        requireDisplayName,
        accessToken,
      };
    }
    if (requireLoginWith2FA) {
      return {
        requireLoginWith2FA,
        identityCookie,
      };
    }
    setToken(jwtToken);
    setRefreshToken(refreshToken);
    setIntercomToken(intercomToken);
    await dispatch(loadUser());
    socketAuthorization(dispatch, jwtToken);
    return {};
  },
);

export const loginUser = createAction(
  'auth/loginUser',
  ({ email, password, token, loginCode }) =>
    async (dispatch: AnyDispatch) => {
      const {
        jwtToken,
        requireLoginWith2FA,
        requireLoginCode,
        refreshToken,
        intercomToken,
        identityCookie,
      } = await api.loginUser({
        email,
        password,
        token,
        loginCode,
      });
      if (requireLoginCode) {
        return {
          requireLoginCode: true,
          identityCookie,
        };
      }
      if (requireLoginWith2FA) {
        return {
          identityCookie,
        };
      }
      setToken(jwtToken);
      setRefreshToken(refreshToken);
      setIntercomToken(intercomToken);
      await dispatch(loadUser());
      socketAuthorization(dispatch, jwtToken);
      return {};
    },
);

export const loginUser2fa = createAction(
  'auth/loginUser2fa',
  ({ twoFactorCode, identityCookie }) =>
    async (dispatch: AnyDispatch) => {
      const { jwtToken, refreshToken, intercomToken } = await api.loginUser2fa({
        twoFactorCode,
        identityCookie,
      });
      setToken(jwtToken);
      setRefreshToken(refreshToken);
      setIntercomToken(intercomToken);
      await dispatch(loadUser());
      socketAuthorization(dispatch, jwtToken);
    },
);

export const loginUser2faRecoveryCode = createAction(
  'auth/loginUser2faRecoveryCode',
  ({ recoveryCode, identityCookie }) =>
    async (dispatch: AnyDispatch) => {
      const { jwtToken, refreshToken, intercomToken } =
        await api.loginUser2faRecoveryCode({
          recoveryCode,
          identityCookie,
        });
      setToken(jwtToken);
      setRefreshToken(refreshToken);
      setIntercomToken(intercomToken);
      await dispatch(loadUser());
      socketAuthorization(dispatch, jwtToken);
    },
);

export const registerUser = createAction(
  'auth/registerUser',
  ({ email, password, username, stag, afdp, token }) =>
    async (dispatch: AnyDispatch) => {
      const { jwtToken, refreshToken, intercomToken } = await api.registerUser({
        email,
        password,
        username,
        confirmUrl: `${window.location.origin}${EMAIL_CONFIRMATION_LINK}`,
        stag,
        afdp,
        token,
      });
      setToken(jwtToken);
      setIntercomToken(intercomToken);
      setRefreshToken(refreshToken);
      await dispatch(loadUser());
      socketAuthorization(dispatch, jwtToken);
    },
);

export const refreshToken = createAction(
  'auth/refreshToken',
  () => async (dispatch: AnyDispatch) => {
    try {
      const { jwtToken, refreshToken: tokenForRefresh } =
        await api.refreshToken(getToken(), getRefreshToken());
      setToken(jwtToken);
      setRefreshToken(tokenForRefresh);
      socketAuthorization(dispatch, jwtToken);
      return jwtToken;
    } catch (e) {
      removeRefreshToken();
      throw e;
    }
  },
);

export const forgotPassword = createAction(
  'auth/forgotPassword',
  email => () =>
    api.forgotPassword({
      email,
      returnUrl: `${window.location.origin}?modal=auth&tab=resetPassword&email=${email}&code={code}`,
    }),
);

export const resetPassword = createAction(
  'auth/resetPassword',
  ({ email, password, code }) =>
    () =>
      api.resetPassword({
        email,
        password,
        code: code.replaceAll('-', '+').replaceAll('_', '/'),
      }),
);

export const get2faKeyUri = createAction(
  'auth/get2faKeyUri',
  () => () => api.get2faKeyUri(),
);
