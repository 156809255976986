import { createReducer } from 'store';
import { changeRightSidebarTab } from './actions';
import { StateType } from './types';

const initialState: StateType = {
  rightSidebar: {
    tab: 0,
  },
};

export const reducer = createReducer(initialState, {
  [changeRightSidebarTab.toString()]: (
    state,
    { payload }: { payload: 0 | 1 },
  ) => {
    state.rightSidebar.tab = payload;
  },
});
