import { Box } from '@chakra-ui/react';
import { Content } from 'components/content';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { HtmlContent } from 'services/cms/components/html-content';
import { getPage, loadPage } from 'services/cms/modules/pages';
import { useActions } from 'store';
import { Skeleton } from 'uikit/skeleton';
import { SkeletonList } from 'uikit/skeleton-list';

type Props = {
  type: string;
  title?: string;
};

export const ContentPage: React.FC<Props> = ({ type }) => {
  const page = useSelector(getPage(type));
  const actions = useActions({ loadPage });

  useEffect(() => {
    actions.loadPage(type);
  }, [actions, type]);

  return (
    <Content>
      <Box w="full" maxW="6xl" p="5" mx="auto" flexGrow="1">
        {page ? (
          <Box bg="sugar-dust" p="5" rounded="xl">
            <HtmlContent content={page.body} />
          </Box>
        ) : (
          <Skeleton>
            <Skeleton h="36px" maxWidth="400px" />
            <SkeletonList count={8}>
              <>
                <Skeleton h="27px" mt="6" mb="2" maxWidth="400px" />
                <Skeleton h="72px" mb="2" />
              </>
            </SkeletonList>
          </Skeleton>
        )}
      </Box>
    </Content>
  );
};
