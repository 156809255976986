export const MAX_VERSUS_PLAYERS = 4;
export const MIN_VERSUS_PLAYERS = 2;

export const SPIN_PERCENT = 100;

export const CURSOR_OFFSET = 50;

export const SPINS_PER_SEC = 4;

export const SAFE_OFFSET = 1;

export const VERSUS_COLORS = [
  '#A63D3D',
  '#3F9671',
  '#2F99BA',
  'var(--chakra-colors-sherbet-orange-200)',
];
