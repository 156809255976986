import { sanityClient } from 'helpers/sanity';

export const loadStreamers = () =>
  sanityClient.fetch(
    `*[_type == "streamer"] {
      id,
      username,
      avatar,
      title,
      tags,
      thumbnail,
      isOnline
    }`,
  );
