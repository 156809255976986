import { createAction } from 'store';
import * as api from './api';

export const createCoupon = createAction(
  'coupon/createCoupon',
  (
    code,
    amount,
    uses = 1,
    minLevel = null,
    maxLevel = null,
    minDepositRequirement = null,
    minDepositRequirementHours = null,
    requirePhone = false,
    requireEmail = false,
    lockId = null,
    expireTime = null,
  ) =>
    () =>
      api.createCoupon({
        code: code.toUpperCase(),
        amount,
        uses,
        expireTime,
        minLevel,
        maxLevel,
        requireEmail,
        requirePhone,
        lockId,
        minDepositRequirement,
        minDepositRequirementHours,
      }),
  (
    code,
    amount,
    uses = 1,
    minLevel = null,
    maxLevel = null,
    minDepositRequirement = null,
    minDepositRequirementHours = null,
    requirePhone = false,
    requireEmail = false,
    lockId = null,
    expireTime = null,
  ) => ({
    code,
    amount,
    uses,
    minLevel,
    maxLevel,
    minDepositRequirement,
    minDepositRequirementHours,
    requirePhone,
    requireEmail,
    lockId,
    expireTime,
  }),
);

export const redeemCoupon = createAction(
  'coupon/redeemCoupon',
  (code, token) => () => api.redeemCoupon(code, token),
);
