import { Box } from '@chakra-ui/react';
import { useLayoutEffect, useRef } from 'react';

const keyframes = [
  {
    transform: 'translateX(0%)',
  },
  {
    transform: 'translateX(-100%)',
  },
];

type Props = {
  time?: number;
  duration: number;
};

export const Timer: React.FC<Props> = ({ time, duration }) => {
  const ref = useRef<HTMLDivElement>(null);

  useLayoutEffect(() => {
    if (!time || !ref.current) {
      return;
    }

    const animation = ref.current.animate(keyframes, {
      fill: 'forwards',
      duration,
    });

    animation.currentTime = time;
  }, [time, duration]);

  return (
    <Box overflow="hidden" rounded="full">
      <Box ref={ref} bg="sherbet-green.200" height="1.5" />
    </Box>
  );
};
