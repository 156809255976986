import { ErrorBoundary } from 'components/error-boundary';
import { useCallback, useMemo, useState } from 'react';
import { PAGE_MODALS } from './constants';
import { ModalType, ModalView } from './modal-view';
import { ModalContext } from './use-modal';

type Props = {
  children: React.ReactNode;
};

export const ModalProvider: React.FC<Props> = ({ children }) => {
  const [modalSequence, setModalSequence] = useState<ModalType[]>([]);

  const setModal = useCallback(
    (modal: ModalType) =>
      setModalSequence(modals =>
        modals.some(m => m.id === modal.id)
          ? modals.map(m => (m.id === modal.id ? modal : m))
          : [...modals, modal],
      ),
    [],
  );

  const getModal = useCallback(
    (id: string) => modalSequence.find(m => m.id === id),
    [modalSequence],
  );

  const updateModal = useCallback(
    (id: string, data: ModalType) =>
      setModalSequence(modals =>
        modals.map(m => (m.id !== id ? m : { ...m, ...data })),
      ),
    [],
  );

  const removeModal = useCallback(
    (id: string) => setModalSequence(modals => modals.filter(m => m.id !== id)),
    [],
  );

  const removeAllModals = useCallback(() => {
    setModalSequence([]);
  }, []);

  const value = useMemo(
    () => ({
      setModal,
      getModal,
      removeModal,
      updateModal,
      removeAllModals,
      pageModal: modalSequence.filter(modal =>
        PAGE_MODALS.includes(modal.type),
      )?.[0],
    }),
    [
      setModal,
      getModal,
      removeModal,
      updateModal,
      removeAllModals,
      modalSequence,
    ],
  );

  return (
    <ModalContext.Provider value={value}>
      {modalSequence
        .filter(modal => !PAGE_MODALS.includes(modal.type))
        .map(m => (
          <ErrorBoundary key={m.id}>
            <ModalView modal={m} />
          </ErrorBoundary>
        ))}
      {children}
    </ModalContext.Provider>
  );
};
