import { Box, Flex } from '@chakra-ui/react';
import { Content } from 'components/content';
import { NFTIcon } from 'icons';
import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { PromoSlider } from 'services/cms/components/promo-slider';
import { NFTGrid } from 'services/nft/components/nft-grid';
import { NftSearch } from 'services/nft/components/nft-search';
import { useNftFilter } from 'services/nft/hooks/use-nft-filter';
import { NFTSortType } from 'services/nft/hooks/use-nft-filter/use-nft-filter';
import { getNftIsLoaded, getNfts, loadNfts } from 'services/nft/modules/nft';
import { useActions } from 'store';
import { EmptyResults } from 'uikit/empty-results';
import { NFTTraitsSlider } from '../nft/components/nft-traits-slider';

export const NftCollection = () => {
  const [search, setSearch] = useState('');
  const [sort] = useState<NFTSortType>('increase');
  const nfts = useSelector(getNfts);
  const isLoaded = useSelector(getNftIsLoaded);
  const actions = useActions({ loadNfts });
  const { collection } = useParams();

  const filteredByCollection = useMemo(
    () => nfts.filter(nft => nft.collection.contractAddress === collection),
    [collection, nfts],
  );
  const list = useNftFilter(filteredByCollection, sort, search);

  useEffect(() => {
    if (!isLoaded) {
      actions.loadNfts();
    }
  }, [actions, isLoaded]);

  const content = <NFTGrid nfts={list} limit={20} isLoaded={isLoaded} />;

  return (
    <Content w="full" maxW="6xl" p="5" mx="auto">
      <PromoSlider />
      <Flex flexDirection="column" gap="5" mt="7">
        <NftSearch search={search} setSearch={setSearch} />
        <NFTTraitsSlider />
      </Flex>
      <Flex direction="column" mt="5">
        <Box>
          {!isLoaded || list.length ? (
            content
          ) : (
            <EmptyResults
              icon={NFTIcon}
              title="search.no-results-title"
              description="search.no-results-description"
            />
          )}
        </Box>
      </Flex>
    </Content>
  );
};
