import {
  Box,
  Button,
  Flex,
  Heading,
  ModalBody,
  ModalContent,
  Text,
} from '@chakra-ui/react';
import { removeFriend } from 'modules/chat';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useActions } from 'store';

type Props = {
  onClose: () => void;
  username: string;
};

export const RemoveFriendModal: React.FC<Props> = ({ onClose, username }) => {
  const [isLoading, setIsLoading] = useState(false);
  const actions = useActions({ removeFriend });
  const { t } = useTranslation();

  const handleRemoveFriend = async () => {
    setIsLoading(true);
    try {
      await actions.removeFriend(username);
      onClose();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <ModalContent width={{ base: 'full', lg: 'lg' }}>
      <ModalBody p="5">
        <Flex direction="column">
          <Box mb="5">
            <Heading
              fontWeight="500"
              fontSize={{ base: 'lg', md: 'xl' }}
              mb="1"
            >
              {t('friends.remove-as-friend', { username })}
            </Heading>
            <Text color="candy-floss-text">
              {t('friends.remove-as-friend-description')}
            </Text>
          </Box>
          <Box>
            <Button
              w="full"
              colorScheme="purple"
              onClick={handleRemoveFriend}
              isLoading={isLoading}
            >
              {t('friends.remove')}
            </Button>
          </Box>
        </Flex>
      </ModalBody>
    </ModalContent>
  );
};
