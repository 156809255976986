import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import styles from 'services/cms/components/html-content/style.module.scss';

export const Details = () => {
  const { t, i18n } = useTranslation();

  if (i18n.language === 'ja') {
    return (
      <Box className={styles.content}>
        <p>
          数千ドルもの賞金プールが増え続けています。
          毎週、ペダルを踏み込んで金属を主張する時間です
          リーダーボード上のあなたの順位。
        </p>
        <p>
          私たちはシャーベットの賭け金上位 10 名に毎年数千ドルを差し上げます。
          週、リーダーボード上の順位に基づいて分割され、分散されます
          すぐに引き出し可能な賞品として。
        </p>
        <p>
          ウィークリー レースは Sherbet
          のすべてのプレイヤーが利用でき、オプトインする必要があります。
        </p>
        <p>
          シャーベットに賭けるたびにポイントが獲得でき、シャーベットの成長に役立ちます。
          毎週のレースの賞金プール。
          賭け金が大きければ大きいほど、より多くのポイントを獲得できます 得る。
          獲得したポイントが多ければ多いほど、より高い順位が得られます
          リーダーボードとより多くのお金を提供します。
        </p>
        {/* <Accordion
          title={t('register-form.terms')}
          options={[
            '最低ベット要件 $0.20 が適用されます。 0.20 ドル未満のベットはトーナメントに貢献しません。',
            'プレーヤーは、ポイントがリーダーボードに表示される前に、対象となるベットを少なくとも 50 回行う必要があります。',
            '「リアルマネーでの賭けのみが対象となります。 ボーナス残高は賭けの対象外となります。',
            'オプトインすると、プレーヤーはこのキャンペーンからの賞金に関する電子メールの受信に同意したことになります。',
            '「プレイヤーが不審または不正行為に関与した場合、シャーベットはKYCプロセスを実施し、プレイヤーのベットをキャンセルし、現在のプロモーションからプレイヤーを削除し、将来のすべてのプロモーションから除外し、賞金をクレジットしない権利を留保します。」',
            '「シャーベットが詐欺、不正行為、プロモーションルールの操作、またはプロモーションの悪用を発見した場合、該当するプレイヤーとその関連アカウントは現在および将来のプロモーションから停止されます。」',
            '「これらの利用規約は、Sherbet の利用規約に追加されるものです。」',
          ]}
        /> */}
      </Box>
    );
  }

  return (
    <Box className={styles.content}>
      <p>
        With an ever-growing prize pool of thousands of dollars up for grabs
        weekly, it's time to put the pedal to the metal and claim your spot on
        the leaderboard.
      </p>
      <p>
        We give thousands of dollars to the top ten wagerers on Sherbet every
        week, split based on their position on the leaderboard, and distributed
        as an instantly withdrawable prize.
      </p>
      <p>
        The Weekly Race is available to all players on Sherbet and requires you
        to opt in.
      </p>
      <p>
        Every bet you place on Sherbet earns you points and helps to grow the
        Weekly Race prize pool. The bigger your bets, the more points you will
        earn. The more points you earn, the higher you'll place on the
        leaderboard and the more money we give away.
      </p>
    </Box>
  );
};
