import { createIcon } from '@chakra-ui/icons';

export const TransactionsIcon = createIcon({
  displayName: 'TransactionsIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M5.596.293a1 1 0 0 1 0 1.414L4.192 3.111h6.03a1 1 0 1 1 0 2h-6.03l1.404 1.404A1 1 0 0 1 4.182 7.93L1.07 4.82a1 1 0 0 1 0-1.415L4.18.293a1 1 0 0 1 1.415 0Zm4.808 7.778a1 1 0 0 1 1.414 0l3.111 3.11a1 1 0 0 1 0 1.415l-3.11 3.111a1 1 0 0 1-1.415-1.414l1.404-1.404h-6.03a1 1 0 1 1 0-2h6.03l-1.404-1.404a1 1 0 0 1 0-1.414Z"
      clipRule="evenodd"
    />
  ),
});
