import { Box } from '@chakra-ui/react';
import { Content } from 'components/content';
import { QuestsIcon } from 'icons';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
// import { useSelector } from 'react-redux';
import {
  getTournamentsIsLoaded,
  getTournamentsList,
  loadTournaments,
} from 'services/games/modules/tournaments';
import { useActions } from 'store';
import { EmptyResults } from 'uikit/empty-results';
import { PlaylistsSlider } from '../casino/components/playlists-slider';

export const Playlists = () => {
  const list = useSelector(getTournamentsList);
  const isLoaded = useSelector(getTournamentsIsLoaded);
  const actions = useActions({
    loadTournaments,
  });

  useEffect(() => {
    if (!isLoaded) {
      actions.loadTournaments();
    }
  }, [actions, isLoaded]);

  return (
    <Content>
      <Box maxW="6xl" mx="auto" flexGrow="1" w="full" p="5">
        {isLoaded && !list.length ? (
          <EmptyResults icon={QuestsIcon} />
        ) : (
          <PlaylistsSlider />
        )}
      </Box>
    </Content>
  );
};
