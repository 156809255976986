import { Loading } from 'components/loading';
import { applyLangToUrl } from 'helpers/lang';
import { Max } from 'pages/max';
import { ProfileVip } from 'pages/vip/vip';
import { Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Navigate, useRoutes } from 'react-router-dom';
import { AffiliateRedirect } from 'services/auth/pages/affiliate-redirect';
import { ProfileRedirect } from 'services/auth/pages/profile-redirect';
import { UserSettingsRedirect } from 'services/auth/pages/user-settings-redirect';
import { AML } from 'services/cms/pages/aml';
import { Blog } from 'services/cms/pages/blog';
import { BlogPost } from 'services/cms/pages/blog-post';
import { BudgetCalculator } from 'services/cms/pages/budget-calculator';
import { FAQ } from 'services/cms/pages/faq';
import { Licenses } from 'services/cms/pages/licenses';
import { Privacy } from 'services/cms/pages/privacy';
import { Promo } from 'services/cms/pages/promo';
import { PromoPost } from 'services/cms/pages/promo-post';
import { Providers } from 'services/cms/pages/providers';
import { RecognizingSigns } from 'services/cms/pages/recognizing-signs';
import { SelfAssessment } from 'services/cms/pages/self-assessment';
import { SelfExclusion } from 'services/cms/pages/self-exclusion';
import { SherbetSafe } from 'services/cms/pages/sherbet-safe';
import { StatusSwap } from 'services/cms/pages/status-swap';
import { Terms } from 'services/cms/pages/terms';
import { Casino } from 'services/games/pages/casino';
import { CasinoGame } from 'services/games/pages/casino-game';
import { DailyScramble } from 'services/games/pages/daily-scramble';

import { Fairness } from 'services/games/pages/fairness';
import { Jackpot } from 'services/games/pages/jackpot';
import { Playlists } from 'services/games/pages/playlists';
import { Quests } from 'services/games/pages/quests';
import { Roulette } from 'services/games/pages/roulette';
import { Tournaments } from 'services/games/pages/tournaments';
import { Versus } from 'services/games/pages/versus';
import { WeeklyRace } from 'services/games/pages/weekly-race';
import { NFT } from 'services/nft/pages/nft';
import { NftCollection } from 'services/nft/pages/nft-collection';
import { NFTTerms } from 'services/nft/pages/nft-terms';
import { DepositRedirect } from 'services/user/pages/deposit-redirect';
import { SocialRedirect } from 'services/user/pages/social-redirect';

type RouteType = {
  children?: RouteType[];
  path: string;
  element?: React.ReactNode;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const applyRoute: any = (routes: RouteType[], lang: string) =>
  routes
    .map(({ children, ...rest }) => ({
      ...rest,
      path:
        rest.path?.[0] === '/' ? applyLangToUrl(rest.path, lang) : rest.path,
      ...(children && { children: applyRoute(children, lang) }),
    }))
    .concat([{ path: '*', element: <Navigate to="/" /> }]);

const getRoutes = (lang: string) => {
  const routes = applyRoute(
    [
      { path: '/', element: <Casino /> },
      {
        path: '/nft',
        element: <NFT />,
      },
      {
        path: '/nft/collections/:collection',
        element: <NftCollection />,
      },
      {
        path: '/nft/collections',
        element: <NFT isCollections />,
      },
      {
        path: '/tournaments/daily-scramble',
        element: <DailyScramble />,
      },
      {
        path: '/tournaments/weekly-race',
        element: <WeeklyRace />,
      },

      {
        path: '/casino/game/*',
        children: [
          {
            path: 'roulette',
            element: <Roulette />,
          },
          {
            path: 'jackpot',
            element: <Jackpot />,
          },
          {
            path: 'versus',
            element: <Versus />,
          },
          {
            path: ':gameId',
            element: <CasinoGame />,
          },
          // {
          //   path: 'cookiecrash',
          //   element: <CookieCrash />,
          // },
        ],
      },
      {
        path: '/responsible-gambling/sherbet-safe',
        element: <SherbetSafe />,
      },
      {
        path: '/responsible-gambling/faq',
        element: <FAQ />,
      },
      {
        path: '/responsible-gambling/recognizing-signs',
        element: <RecognizingSigns />,
      },
      {
        path: '/responsible-gambling/self-assessment',
        element: <SelfAssessment />,
      },
      {
        path: '/responsible-gambling/budget-calculator',
        element: <BudgetCalculator />,
      },
      {
        path: '/casino/quests',
        element: <Quests />,
      },
      {
        path: '/tournaments',
        element: <Tournaments />,
      },
      {
        path: '/casino',
        element: <Navigate to="/" />,
      },
      {
        path: '/casino/lobby',
        element: <Navigate to="/" />,
      },
      {
        path: '/casino/category/:category',
        element: <Casino />,
      },
      {
        path: '/casino/providers',
        element: <Casino isProvidersList />,
      },

      {
        path: '/casino/provider/:provider',
        element: <Casino />,
      },
      {
        path: '/casino/tag/:tag',
        element: <Casino />,
      },
      {
        path: '/playlists',
        element: <Playlists />,
      },
      {
        path: '/playlist/:playlist',
        element: <Casino />,
      },
      {
        path: '/policies/aml',
        element: <AML />,
      },
      {
        path: '/policies/self-exclusion',
        element: <SelfExclusion />,
      },
      {
        path: '/policies/privacy',
        element: <Privacy />,
      },
      {
        path: '/policies/terms',
        element: <Terms />,
      },
      {
        path: '/licenses',
        element: <Licenses />,
      },
      {
        path: '/policies/nft-terms',
        element: <NFTTerms />,
      },
      {
        path: '/policies/providers',
        element: <Providers />,
      },
      {
        path: '/vip',
        element: <ProfileVip />,
      },
      {
        path: '/policies/fairness',
        element: (
          <Suspense fallback={<Loading />}>
            <Fairness />
          </Suspense>
        ),
      },
      {
        path: '/blog',
        element: <Blog />,
      },
      {
        path: '/blog/category/:tag',
        element: <Blog />,
      },
      {
        path: '/blog/author/:author',
        element: <Blog />,
      },
      {
        path: '/blog/:slug',
        element: <BlogPost />,
      },
      {
        path: '/promotions',
        element: <Promo />,
      },
      {
        path: '/promotions/category/:tag',
        element: <Promo />,
      },
      {
        path: '/promotions/author/:author',
        element: <Promo />,
      },
      {
        path: '/promotions/:slug',
        element: <PromoPost />,
      },
      {
        path: '/promotions/status-swap',
        element: <StatusSwap />,
      },
      { path: '/deposit-redirect', element: <DepositRedirect /> },
      {
        path: '/affiliate/:name',
        element: <AffiliateRedirect />,
      },
      {
        path: '/social/discord',
        element: <SocialRedirect />,
      },
      {
        path: '/max',
        element: (
          <Suspense fallback={<Loading />}>
            <Max />
          </Suspense>
        ),
      },
      {
        path: '/profile/:username',
        element: <ProfileRedirect />,
      },
      {
        path: '/user/settings',
        element: <UserSettingsRedirect />,
      },
      {
        path: '/user/settings/verify',
        element: <UserSettingsRedirect subtab="verify" />,
      },
    ],
    lang,
  );

  return routes;
};

export const Routes = () => {
  const { i18n } = useTranslation();

  const routes = useMemo(() => getRoutes(i18n.language), [i18n.language]);

  return useRoutes(routes);
};
