/* eslint-disable react/jsx-no-useless-fragment */
import { Box, Flex } from '@chakra-ui/react';
import { Content } from 'components/content';
import { Loading } from 'components/loading';
import { MaintenanceWrapper } from 'components/maintenance-wrapper';
import {
  IS_DEPOSIT_MAINTENANCE,
  LOCAL_STORAGE_WALLET_LAST_SHORTCUT,
} from 'constants/general';
import { MetamaskIcon, TransactionsIcon } from 'icons';
import { Suspense, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { CurrenciesList } from 'services/user/components/currencies-list';
import { WALLETS_TABS } from 'services/user/modules/currencies';
import { getUserBonusesLength } from 'services/user/modules/user';
import { BonusView } from '../bonuses/components/bonus-view';
import { RedeemView } from '../bonuses/components/redeem-view';
import { ProfileVip } from '../profile/components/profile-vip';
import { DepositView } from './components/deposit-view';
import { GiftCardView } from './components/gift-card-view';
import { NftView } from './components/nft-view';
import { TransactionsView } from './components/transactions-view';
import { Web3WalletView } from './components/web3-wallet-view';
import { Withdraw } from './components/withdraw';

export const Wallet = ({
  contentProps: { tab = 'deposit', shortcut },
  onClose,
}) => {
  const { t } = useTranslation();
  const LIST = useMemo(
    () => [
      // {
      //   iconContent: (
      //     <Flex gap="1">
      //       <Image
      //         h="14px"
      //         src="https://spinkick.imgix.net/footer-logos/visa.svg"
      //         alt="Visa"
      //         draggable="false"
      //       />
      //       <Image
      //         h="14px"
      //         src="https://spinkick.imgix.net/footer-logos/mastercard.svg"
      //         alt="Mastercard"
      //         draggable="false"
      //       />
      //     </Flex>
      //   ),
      //   name: (
      //     <Flex as="span" gap="2" alignItems="center">
      //       {t('wallet.credit-card')}
      //     </Flex>
      //   ),
      //   shortcut: 'USD',
      // },
      {
        Icon: MetamaskIcon,
        name: (
          <Flex as="span" gap="2" alignItems="center">
            Web3 Wallet
          </Flex>
        ),
        shortcut: 'WEB3',
      },
      ...WALLETS_TABS,
    ],
    [t],
  );

  const TABS = [
    {
      type: 'transactions',
      icon: TransactionsIcon,
      text: 'profile.transactions.title',
    },
  ];

  const [tabIndex, setTabIndex] = useState(
    TABS.findIndex(tabItem => tabItem.type === tab),
  );

  const [searchParams, setSearchParams] = useSearchParams();

  const [currencyTabIndex, setCurrencyTabIndex] = useState(0);

  useLayoutEffect(() => {
    if (tab === 'deposit') {
      localStorage.setItem(
        LOCAL_STORAGE_WALLET_LAST_SHORTCUT,
        LIST[currencyTabIndex].shortcut,
      );
    }
  }, [currencyTabIndex, tab, LIST]);

  const bonusesLength = useSelector(getUserBonusesLength);

  useEffect(() => {
    setTabIndex(TABS.findIndex(i => i.type === tab));
  }, [tab]);

  const handleChangeTab = index => {
    setTabIndex(index);
    setSearchParams({
      ...Object.fromEntries(searchParams),
      tab: TABS[index].type,
    });
  };

  const tabName = TABS[tabIndex]?.type;

  const openBuy = () => setTabIndex(2);

  const activeCurrencyTab = LIST[currencyTabIndex];

  const renderView = () => {
    // if (activeCurrencyTab.shortcut === 'USD') {
    //   return (
    //     <Suspense fallback={<Loading pos="relative" my="14" />}>
    //       <CardView onClose={onClose} />
    //     </Suspense>
    //   );
    // }
    if (activeCurrencyTab.shortcut === 'GIFT') {
      return <GiftCardView />;
    }
    if (activeCurrencyTab.shortcut === 'WEB3') {
      return (
        <Suspense fallback={<Loading pos="relative" my="14" />}>
          <Web3WalletView />
        </Suspense>
      );
    }
    return (
      <DepositView
        key={currencyTabIndex}
        {...activeCurrencyTab}
        openBuy={openBuy}
        onClose={onClose}
      />
    );
  };

  const isDepositStopper = false;

  let content;

  if (tabName === 'nft') {
    content = (
      <Box w="full" flexGrow="1">
        <NftView />
      </Box>
    );
  } else if (tabName === 'transactions') {
    content = (
      <Box w="full" flexGrow="1">
        <TransactionsView />
      </Box>
    );
  } else {
    content = (
      <Content w="full" maxW="6xl" mx="auto" flexGrow="1" key={tabName}>
        <Flex p="5" flexDirection="column">
          <Flex
            gap={{ base: '5', xl: '14' }}
            align="start"
            flexDir={{ base: 'column-reverse', xl: 'row' }}
          >
            <Box w="full">
              {tabName === 'deposit' && (
                <MaintenanceWrapper isActive={IS_DEPOSIT_MAINTENANCE}>
                  <>
                    {isDepositStopper ? (
                      <BonusView isDepositStopper />
                    ) : (
                      <CurrenciesList
                        active={currencyTabIndex}
                        onSelect={setCurrencyTabIndex}
                        list={LIST}
                      >
                        {renderView()}
                      </CurrenciesList>
                    )}
                  </>
                </MaintenanceWrapper>
              )}
              {tabName === 'withdraw' && (
                <Withdraw onClose={onClose} shortcut={shortcut} />
              )}
              {tabName === 'gift' && <GiftCardView />}
            </Box>
            {((tabName === 'deposit' && !isDepositStopper) ||
              tabName === 'withdraw') && (
              <Box
                w="full"
                display={{ base: 'none', xl: 'block' }}
                position="sticky"
                top="calc(var(--header-height) + 78px)"
              >
                <Flex flexDirection="column" gap="5">
                  <ProfileVip />
                  <RedeemView />
                </Flex>
              </Box>
            )}
          </Flex>
        </Flex>
      </Content>
    );
  }

  return (
    <Flex direction="column" flexGrow="1">
      {content}
    </Flex>
  );
};
