import { createIcon } from '@chakra-ui/icons';

export const JAFlagIcon = createIcon({
  displayName: 'JAFlagIcon',
  viewBox: '0 0 512 512',
  path: (
    <>
      <mask id="FlagFlag0.6921127425552249">
        <circle cx="256" cy="256" r="256" fill="#fff" />
      </mask>
      <g mask="url(#FlagFlag0.6921127425552249)">
        <path fill="#eee" d="M0 0h512v512H0z" />
        <circle cx="256" cy="256" r="111.3" fill="#d80027" />
      </g>
    </>
  ),
});
