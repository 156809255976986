import { createIcon } from '@chakra-ui/icons';

export const ENFlagIcon = createIcon({
  displayName: 'ENFlagIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <g clipPath="url(#a)">
        <path d="M8 .001a8 8 0 1 1 0 16 8 8 0 0 1 0-16Z" fill="#fff" />
        <path
          d="M1.655 12.872a7.971 7.971 0 0 1-1.378-2.784H4.44l-2.784 2.784Zm14.069-2.784a7.972 7.972 0 0 1-1.378 2.784l-2.784-2.784h4.162ZM.277 5.914A7.972 7.972 0 0 1 1.655 3.13L4.44 5.914H.277Zm12.596 8.433a7.972 7.972 0 0 1-2.783 1.379v-4.162l2.784 2.783ZM3.133 1.655A7.972 7.972 0 0 1 5.916.277v4.161L3.133 1.655Zm2.783 14.071a7.972 7.972 0 0 1-2.783-1.379l2.783-2.783v4.162ZM10.09.277a7.971 7.971 0 0 1 2.783 1.378L10.09 4.438V.277Zm1.473 5.637 2.783-2.784a7.97 7.97 0 0 1 1.378 2.784h-4.162Z"
          fill="#1A2FFF"
        />
        <path
          d="M15.932 9.044H9.044v6.89a8.092 8.092 0 0 1-2.087 0v-6.89H.067a8.08 8.08 0 0 1 0-2.086h6.89V.068a8.08 8.08 0 0 1 2.086 0v6.89h6.89a8.074 8.074 0 0 1 0 2.086Z"
          fill="#F1323E"
        />
        <path
          d="m10.086 5.914 3.57-3.57c.164.164.32.336.47.514L11.07 5.914h-.984Zm-4.172 0-3.57-3.57a8.02 8.02 0 0 1 .513-.47L5.914 4.93v.984Zm-.003 4.174-3.57 3.57a8.01 8.01 0 0 1-.47-.514l3.056-3.056h.984Zm4.175 0 3.57 3.57c-.164.164-.336.32-.514.47l-3.056-3.056v-.984Z"
          fill="#F1323E"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path
            fill="#fff"
            transform="matrix(1 0 0 -1 0 16.001)"
            d="M0 0h16v16H0z"
          />
        </clipPath>
      </defs>
    </>
  ),
});
