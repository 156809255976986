import { createIcon } from '@chakra-ui/icons';

export const BTCIcon = createIcon({
  displayName: 'BTCIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <g clipPath="url(#a)">
        <path fill="#F7931A" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z" />
        <path
          fill="#fff"
          d="M11.595 7.01c.156-1.048-.642-1.611-1.733-1.987l.354-1.42-.864-.216-.345 1.383c-.227-.057-.46-.11-.692-.163l.347-1.392L7.798 3l-.354 1.42c-.188-.043-.373-.085-.552-.13l.001-.005-1.192-.298-.23.923s.642.147.628.156c.35.088.413.32.403.503l-.403 1.618a.769.769 0 0 1 .09.028l-.092-.022-.565 2.266c-.043.106-.151.266-.396.205a13.36 13.36 0 0 1-.628-.156l-.43.989 1.126.28c.209.053.414.107.615.159l-.357 1.436.863.215.354-1.42c.236.063.465.123.689.178l-.353 1.414.864.216.358-1.434c1.474.28 2.582.167 3.048-1.166.376-1.073-.018-1.693-.794-2.096.565-.13.99-.502 1.104-1.269ZM9.62 9.779c-.267 1.073-2.074.493-2.66.348l.475-1.903c.586.146 2.464.436 2.185 1.555Zm.267-2.784c-.243.976-1.747.48-2.235.358l.43-1.725c.488.122 2.059.348 1.805 1.367Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </>
  ),
});
