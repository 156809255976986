import { createReducer } from 'store';
import {
  addVerifyAddress,
  loadNFTDetails,
  loadNftInfo,
  loadNftSales,
  loadNfts,
  loadOwnedNfts,
  loadRandomNfts,
  loadVerifiedAddresses,
  selectNft,
  stakeNfts,
} from './actions';
import { StateType } from './types';

const initialState: StateType = {
  randomNfts: [],
  randomIsLoaded: false,
  totalNftsStaked: 0,
  nftInfoIsLoaded: false,
  nfts: [],
  isLoaded: false,
  ownedNfts: [],
  ownedNftsIsLoaded: false,
  selectedNft: null,
  verifiedAddresses: [],
  verifiedAddressesIsLoaded: false,
  stakingReward: 0,
  nftsDetails: {},
  nftSales: [],
  nftSalesIsLoaded: false,
};

export const reducer = createReducer(initialState, {
  [loadRandomNfts.toString()]: (state, { payload: { payload } }) => {
    state.randomNfts = payload;
    state.randomIsLoaded = true;
  },
  [loadNftInfo.toString()]: (state, { payload: { payload } }) => {
    state.totalNftsStaked = payload.totalNftsStaked;
    state.nftInfoIsLoaded = true;
  },
  [loadNfts.toString()]: (state, { payload: { payload } }) => {
    state.nfts = payload;
    state.isLoaded = true;
  },
  [loadOwnedNfts.toString()]: (state, { payload: { payload } }) => {
    state.ownedNftsIsLoaded = true;
    state.ownedNfts = payload.nfts || [];
    state.selectedNft = payload.currentNft;
    state.stakingReward = payload.totalStakeRewards;
  },
  [loadVerifiedAddresses.toString()]: (state, { payload }) => {
    state.verifiedAddresses = payload || [];
    state.verifiedAddressesIsLoaded = true;
  },
  [selectNft.toString()]: (state, { meta: { nftId } }) => {
    state.selectedNft = nftId;
  },
  [addVerifyAddress.toString()]: (state, { payload }) => {
    state.verifiedAddresses = [...state.verifiedAddresses, payload];
  },
  [stakeNfts.toString()]: (state, { payload: { nftIds, isStaked } }) => {
    state.ownedNfts = [
      ...state.ownedNfts.filter(ownedNft => !nftIds.includes(ownedNft.id)),
      ...state.ownedNfts
        .filter(ownedNft => nftIds.includes(ownedNft.id))
        .map(ownedNft => ({ ...ownedNft, isStaked })),
    ];
  },
  [loadNFTDetails.toString()]: (state, { payload, meta: { id } }) => {
    state.nftsDetails[id] = payload;
  },
  [loadNftSales.toString()]: (state, { payload }) => {
    state.nftSalesIsLoaded = true;
    state.nftSales = payload;
  },
});
