import { ROULETTE_HANDLER_ID } from 'constants/general';
import { client, parseApiError } from 'helpers/client';

export const loadGame = () =>
  client
    .get(`/handler/roulette/${ROULETTE_HANDLER_ID}`)
    .then(({ data }) => data.payload);

export const loadPreviousRolls = () =>
  client
    .get(`/handler/roulette/${ROULETTE_HANDLER_ID}/rolls`)
    .then(({ data }) => data.payload);

export const placeBet = ({
  gameId,
  pickedGroup,
  total,
  amount,
}: {
  gameId: string;
  pickedGroup: number;
  total: number;
  amount: number;
}) =>
  client
    .post(`/handler/roulette/${ROULETTE_HANDLER_ID}/${gameId}`, {
      wagerGroup: pickedGroup,
      inputTotal: total,
      inputAmount: amount,
      inputItems: [],
    })
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const loadRouletteHistory = ({
  before,
  id,
  limit,
}: {
  before: string;
  id: string;
  limit: number;
}) =>
  client
    .get(
      `/handler/roulette/16/history?limit=${limit}&before=${before}&id=${id}`,
    )
    .then(({ data }) => data.payload);
