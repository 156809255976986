import { Box, Flex, Grid, Heading, Image, Text } from '@chakra-ui/react';
import { Content } from 'components/content';
import { ShowMore } from 'components/show-more';
import { imageBuilder } from 'helpers/sanity';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getBlogIsLoaded,
  getBlogPosts,
  loadPosts,
} from 'services/cms/modules/blog';
import { useActions } from 'store';
import { ImageFallback } from 'uikit/image-fallback';
import { Link } from 'uikit/link';
import { Skeleton } from 'uikit/skeleton';
import { SkeletonList } from 'uikit/skeleton-list';

const LIMIT = 12;

export const Blog = () => {
  const [page, setPage] = useState(1);
  const isLoaded = useSelector(getBlogIsLoaded);
  const postsList = useSelector(getBlogPosts);
  const actions = useActions({ loadPosts });
  const { tag, author } = useParams();
  const { t } = useTranslation();

  let posts = postsList;

  if (author) {
    posts = posts.filter(post => post.authorSlug === author);
  } else if (tag) {
    posts = posts.filter(post =>
      post.tags?.some(tg => tg?.slug?.current === tag),
    );
  }

  const handleShowMore = () => setPage(prev => prev + 1);

  const list = posts.slice(0, page * LIMIT);

  useEffect(() => {
    actions.loadPosts('en');
  }, [actions]);

  return (
    <Content>
      <Box w="full" maxW="6xl" mx="auto" flexGrow="1">
        <Content key={tag} p="5">
          <Grid
            templateColumns={{
              base: 'repeat(1, 1fr)',
              md: 'repeat(2, 1fr)',
              xl: 'repeat(3, 1fr)',
            }}
            columnGap="3"
            rowGap="5"
            alignItems="start"
          >
            {isLoaded ? (
              list.map(post => (
                <Flex
                  flexDirection="column"
                  key={post._id}
                  opacity={
                    post.endAt && new Date(post.endAt) < new Date()
                      ? '0.5'
                      : undefined
                  }
                >
                  {post.mainImage && (
                    <Link
                      to={`/blog/${post.slug}`}
                      mb="3"
                      transitionProperty="all"
                      transitionTimingFunction="cubic-bezier(0.4, 0, 0.2, 1)"
                      transitionDuration=".15s"
                      _hover={{
                        transform: 'translateY(-0.125rem)',
                        opacity: '0.9',
                      }}
                      _active={{
                        transform: 'translateY(0px)',
                      }}
                    >
                      <Image
                        objectFit="cover"
                        rounded="xl"
                        alt={post.title}
                        draggable="false"
                        src={`${imageBuilder.image(
                          post.mainImage,
                        )}?q=80&auto=format&w=800`}
                        w="full"
                        fallback={<ImageFallback type="post" rounded="xl" />}
                      />
                    </Link>
                  )}
                  <Box flexGrow="1">
                    <Heading
                      as={Link}
                      to={`/blog/${post.slug}`}
                      fontWeight="500"
                      fontSize={{ base: 'lg', md: 'xl' }}
                      noOfLines="1"
                      mb="1"
                      _hover={{
                        opacity: '0.9',
                      }}
                    >
                      {post.title}
                    </Heading>

                    <Text color="candy-floss-text" noOfLines="2">
                      {post.description}
                    </Text>
                  </Box>
                </Flex>
              ))
            ) : (
              <SkeletonList count={6}>
                <Flex flexDirection="column">
                  <ImageFallback type="post" rounded="xl" mb="3" />
                  <Box flexGrow="1">
                    <Skeleton>
                      <Heading
                        fontWeight="500"
                        fontSize={{ base: 'lg', md: 'xl' }}
                        noOfLines="1"
                        mb="1"
                      >
                        |
                      </Heading>
                    </Skeleton>

                    <Skeleton h="42px" />
                  </Box>
                </Flex>
              </SkeletonList>
            )}
          </Grid>
          {isLoaded && (
            <ShowMore
              current={list.length}
              total={posts.length}
              onClick={handleShowMore}
            />
          )}
        </Content>
      </Box>
    </Content>
  );
};
