import { OriginalsIcon } from 'icons';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { GameSlider } from 'services/games/components/game-slider';
import {
  getSlotsIsLoaded,
  getSlotsSherbetOriginalsGames,
} from 'services/games/modules/slots';

export const OriginalsSlider = () => {
  const games = useSelector(getSlotsSherbetOriginalsGames);
  const isLoaded = useSelector(getSlotsIsLoaded);
  const { t } = useTranslation();

  const manualGames = [
    {
      id: 'dice',
      title: 'Dice',
      image: 'https://spinkick.imgix.net/thumbnails/dice.jpg',
      bg: 'rgb(63, 81, 128)',
      meta: { isTest: true },
      provider: 'sherbet',
      disabledCountries: [],
      categories: [],
      tags: [],
    },
    {
      id: 'mines',
      title: 'Mines',
      image: 'https://spinkick.imgix.net/thumbnails/mines.jpg',
      bg: 'rgb(63, 81, 128)',
      meta: { isTest: true },
      provider: 'sherbet',
      disabledCountries: [],
      categories: [],
      tags: [],
    },
    {
      id: 'plinko',
      title: 'Plinko',
      image: 'https://spinkick.imgix.net/thumbnails/plinko.jpg',
      bg: 'rgb(63, 81, 128)',
      meta: { isTest: true },
      provider: 'sherbet',
      disabledCountries: [],
      categories: [],
      tags: [],
    },
    {
      id: 'crash',
      title: 'Crash',
      image: 'https://spinkick.imgix.net/thumbnails/crash.jpg',
      bg: 'rgb(63, 81, 128)',
      meta: { isTest: true },
      provider: 'sherbet',
      disabledCountries: [],
      categories: [],
      tags: [],
    },
  ];

  const updatedGames = [...games, ...manualGames];

  return (
    <GameSlider
      icon={<OriginalsIcon color="candy-floss-text" />}
      titleLink="/casino/category/originals"
      slides={updatedGames}
      isLoaded={isLoaded}
      title={t('casino.category.originals')}
    />
  );
};
