import { createIcon } from '@chakra-ui/icons';

export const HockeyIcon = createIcon({
  displayName: 'HockeyIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="M15.897.619a.75.75 0 0 0-.438-.27L13.467.01a.659.659 0 0 0-.776.54l-.911 4.723a.665.665 0 0 0 .1.505.75.75 0 0 0 .44.27l1.991.338h.101c.304 0 .608-.236.642-.54l.91-4.722a.538.538 0 0 0-.067-.506Zm-2.025 6.915a12.246 12.246 0 0 1-1.316-.237c-.236-.067-.473-.1-.675-.135a.665.665 0 0 0-.506.102.75.75 0 0 0-.27.438c-.034.405-.304.81-.71 1.113-.37.304-.877.506-1.45.742-.54.203-1.08.371-1.62.574-1.182.405-2.43.81-3.578 1.416-.372.203-.743.439-.946.844-.135.27-.202.573-.202.944.034.776.405 1.552 1.046 2.024.54.405 1.215.641 1.992.641.168 0 .337 0 .506-.034.776-.1 1.485-.37 2.16-.674 1.384-.607 2.936-1.35 4.152-2.564 1.451-1.45 2.092-2.935 2.059-4.587-.034-.304-.304-.574-.642-.607Zm-8.337-.439c0-1.552-1.248-2.8-2.767-2.8C1.248 4.295 0 5.543 0 7.095c0 1.552 1.249 2.8 2.768 2.8 1.519 0 2.767-1.248 2.767-2.8Z"
    />
  ),
});
