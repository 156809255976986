import { Box } from '@chakra-ui/react';
import { IS_PRERENDER } from 'constants/general';
import { getImageLoaded, setImageLoaded } from 'helpers/image';
import { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { ImageFallback } from 'uikit/image-fallback';

type Props = {
  provider: string;
  w: string;
  h: string;
};

const mapping = {
  bombaylive: 'live88',
};

export const ProviderImage: React.FC<Props> = ({ provider, w, h }) => {
  const [isImageLoaded, setIsImageLoaded] = useState(false);

  const url = `https://spinkick.imgix.net/providers/small/${mapping[provider as keyof typeof mapping] || provider}.svg`;

  return (
    <Box as="span" position="relative">
      <LazyLoadImage
        width={w}
        height={h}
        threshold={100}
        visibleByDefault={IS_PRERENDER || getImageLoaded(url)}
        src={url}
        alt={provider}
        draggable="false"
        onLoad={() => {
          setImageLoaded(url);
          setIsImageLoaded(true);
        }}
      />
      {!isImageLoaded && (
        <Box as="span" pos="absolute" left="0" right="0" top="0" bottom="0">
          <ImageFallback type="square" />
        </Box>
      )}
    </Box>
  );
};
