import { createContext } from 'react';

type LayoutContextType = {
  isPageAnimation: boolean;
  setIsPageAnimation: (v: boolean) => void;
};

export const AnimationContext = createContext<LayoutContextType>({
  isPageAnimation: false,
  setIsPageAnimation: () => {},
});
