import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserName } from 'services/user/modules/user';

type Props = {
  subtab?: string;
};

export const UserSettingsRedirect: React.FC<Props> = ({ subtab }) => {
  const username = useSelector(getUserName);
  const navigate = useNavigate();

  useEffect(() => {
    if (username) {
      navigate(
        `/?modal=profile&username=${username}&tab=settings${subtab ? `&subtab=${subtab}` : ''}`,
      );
    }
  }, [username, navigate, subtab]);

  return null;
};
