import { Box, Button, Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { Transactions } from 'services/user/components/transactions';

export const TransactionsView = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();

  const params = Object.fromEntries(searchParams);

  const tabs = [
    {
      children: t('transactions.all'),
      subtab: 'all',
    },
    {
      children: t('transactions.deposits'),
      subtab: undefined,
    },
    {
      children: t('transactions.withdrawals'),
      subtab: 'withdrawals',
    },
    {
      children: t('transactions.bets'),
      subtab: 'bets',
    },

    {
      children: 'Tips Sent',
      subtab: 'tips-sent',
    },
    {
      children: 'Tips Received',
      subtab: 'tips-received',
    },
    {
      children: 'Rain',
      subtab: 'rain',
    },
    {
      children: 'Coupon Redeemed',
      subtab: 'coupon-redeemed',
    },
    {
      children: 'Rakeback Received',
      subtab: 'rakeback',
    },
    {
      children: 'Tournament Payout',
      subtab: 'tournament-payout',
    },
    {
      children: 'Quest Payout',
      subtab: 'quest-payout',
    },
  ];

  const changeSubTab = nextSubtab => {
    const { subtab: oldSubtab, ...newParams } = params;

    if (nextSubtab) {
      newParams.subtab = nextSubtab;
    }

    setSearchParams(newParams);
  };

  return (
    <Box maxW="6xl" mx="auto" flexGrow="1" w="full" px="5">
      <Flex align="center" w="full">
        <Box w="full">
          <Flex
            gap="2"
            align="center"
            overflow="auto"
            zIndex="2"
            maxW="6xl"
            mx="auto"
            width="100%"
            // px="5"
            py="5"
            pb="7"
          >
            {tabs.map(({ children, subtab }, index) => {
              const isActive = subtab === params.subtab;
              return (
                <Button
                  colorScheme="custom"
                  border="1px solid"
                  color={isActive ? 'sherbet-purple' : 'candy-floss-text'}
                  borderColor={isActive ? 'sherbet-purple' : 'truffle-border'}
                  rounded="full"
                  _hover={
                    isActive ? {} : { borderColor: 'truffle-border-hover' }
                  }
                  bg="toffee-base"
                  flexShrink="0"
                  gap="2"
                  draggable="false"
                  onClick={() => changeSubTab(subtab)}
                  key={index}
                >
                  {children}
                </Button>
              );
            })}
          </Flex>
        </Box>
      </Flex>

      <Transactions type={params.subtab} />
    </Box>
  );
};
