import { createIcon } from '@chakra-ui/icons';

export const SportsIcon = createIcon({
  displayName: 'SportsIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="M15.68 5.779A7.993 7.993 0 0 0 10.492.424c-1.501 1.644-2.066 4.43-1.25 7.233a8.438 8.438 0 0 0 3.077 4.533 5.818 5.818 0 0 0 1.867.896 7.993 7.993 0 0 0 1.496-7.307ZM7.872 8.063A9.345 9.345 0 0 1 8.95.07a7.993 7.993 0 0 0-7.873 3.95A10.276 10.276 0 0 1 4.31 9.074a10.276 10.276 0 0 1 0 6 7.992 7.992 0 0 0 8.751-.879 7.572 7.572 0 0 1-1.57-.868 9.865 9.865 0 0 1-3.619-5.27v.007ZM.449 5.385a7.958 7.958 0 0 0 2.615 8.912A8.98 8.98 0 0 0 2.938 9.5 9.049 9.049 0 0 0 .466 5.38l-.017.006Z"
    />
  ),
});
