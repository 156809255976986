import { createIcon } from '@chakra-ui/icons';

export const ArrowRightIcon = createIcon({
  displayName: 'ArrowRightIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M3.763.335a1.143 1.143 0 0 1 1.617 0l6.857 6.857c.446.446.446 1.17 0 1.616L5.38 15.665a1.143 1.143 0 0 1-1.617-1.616L9.813 8l-6.05-6.049a1.143 1.143 0 0 1 0-1.616Z"
      clipRule="evenodd"
    />
  ),
});
