import { Box, Grid, GridItem } from '@chakra-ui/react';
import cn from 'classnames';
import { useLayout } from 'hooks/use-layout';
import { SkeletonList } from 'uikit/skeleton-list';
import styles from './style.module.scss';

type Props = {
  isLoaded?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  list: any[];
  itemProps?: object;
  Item: React.ElementType;
  size?: 'm' | 'l' | 'xl' | 'md';
  hasInfiniteScroll?: boolean;
  hasMore?: boolean;
  onNext?: () => void;
  skeletonCount?: number;
};

export const PreviewGrid: React.FC<Props> = ({
  isLoaded = true,
  list,
  itemProps = {},
  Item,
  size = 'm',
  skeletonCount = 12,
}) => {
  const { layout } = useLayout();

  return (
    <Box m="-4px">
      <Grid
        className={cn(styles.general, styles[size], styles[`layout${layout}`])}
        columnGap="1"
        rowGap="3"
      >
        {!isLoaded ? (
          <SkeletonList count={skeletonCount}>
            <Item isSkeleton className={styles.skelet} />
          </SkeletonList>
        ) : (
          list.map((item, index) => (
            <GridItem key={index} overflow="hidden">
              {item.PreviewComponent ? (
                <item.PreviewComponent {...item} {...itemProps} />
              ) : (
                <Item {...item} {...itemProps} />
              )}
            </GridItem>
          ))
        )}
      </Grid>
    </Box>
  );
};
