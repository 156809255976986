import { Button, Flex } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router';
import { Link } from 'uikit/link';

export const NFTTraitsSlider = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const containerRef = useRef<HTMLDivElement>(null);
  const [isDragging, setIsDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [scrollLeft, setScrollLeft] = useState(0);

  useEffect(() => {
    const container = containerRef.current;

    if (!container) {
      return () => {};
    }

    const handleMouseDown = (e: MouseEvent) => {
      setIsDragging(true);
      setStartX(e.pageX - container.offsetLeft);
      setScrollLeft(container.scrollLeft);
    };

    const handleMouseUp = () => {
      setIsDragging(false);
    };

    const handleMouseMove = (e: MouseEvent) => {
      if (!isDragging) return;
      const x = e.pageX - container.offsetLeft;
      const walk = x - startX;
      container.scrollLeft = scrollLeft - walk;
    };

    container.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);
    document.addEventListener('mousemove', handleMouseMove);

    return () => {
      container.removeEventListener('mousedown', handleMouseDown);
      document.removeEventListener('mouseup', handleMouseUp);
      document.removeEventListener('mousemove', handleMouseMove);
    };
  }, [isDragging, startX, scrollLeft]);

  const items = [
    { title: 'Zombie', to: '#' },
    { title: 'Skeleton', to: '#' },
    { title: 'Marshmallow', to: '#' },
    { title: 'Half-Eaten Ice Pop', to: '#' },
    { title: 'Chocolate Strawberry', to: '#' },
    { title: 'Cake', to: '#' },
    { title: 'Juice Box', to: '#' },
    { title: 'Pancakes', to: '#' },
    { title: 'Candy Cane', to: '#' },
    { title: 'Popcorn', to: '#' },
    { title: 'Icecream', to: '#' },
    { title: 'Toffee', to: '#' },
    { title: 'Chocolate', to: '#' },
    { title: 'Cookie', to: '#' },
    { title: 'Pink Gummy', to: '#' },
    { title: 'Cotton Candy', to: '#' },
    { title: 'Cupcake', to: '#' },
    { title: 'Muffin', to: '#' },
    { title: 'Yellow Gummy', to: '#' },
    { title: 'Chocolate Donut', to: '#' },
    { title: 'Blue Gummy', to: '#' },
    { title: 'Blue Donut', to: '#' },
    { title: 'Pink Donut', to: '#' },
  ];

  return (
    <Flex
      gap="2"
      m="-1"
      p="1"
      align="center"
      overflow="auto"
      zIndex="2"
      ref={containerRef}
      cursor={isDragging ? 'grabbing' : undefined}
    >
      {items.map(({ title, to, ...rest }, index) => {
        const isActive = to === pathname;
        return (
          <Button
            colorScheme="custom"
            border="1px solid"
            color={isActive ? 'sherbet-purple' : 'candy-floss-text'}
            borderColor={isActive ? 'sherbet-purple' : 'truffle-border'}
            rounded="full"
            _hover={isActive ? {} : { borderColor: 'truffle-border-hover' }}
            bg="toffee-base"
            flexShrink="0"
            gap="2"
            allowPrevent
            draggable="false"
            as={Link}
            // @ts-ignore
            state={{
              disableScroll: true,
            }}
            to={to}
            cursor={isDragging ? 'grabbing' : undefined}
            key={index}
            onClick={
              isActive
                ? e => {
                    e.preventDefault();
                    navigate('/');
                  }
                : undefined
            }
            {...rest}
          >
            {title}
          </Button>
        );
      })}
    </Flex>
  );
};
