import { NFT_HANDLER_ID } from 'constants/general';
import { client, parseApiError } from 'helpers/client';
// import { Network, Alchemy } from 'alchemy-sdk';

export const loadRandomNfts = () =>
  client.get('/handler/nft/list-18').then(({ data }) => data);

export const loadNftInfo = () =>
  client.get('/handler/nft/info').then(({ data }) => data);

export const loadNfts = () =>
  client.get('/handler/nft/filter-7777').then(({ data }) => data);

export const loadOwnedNfts = () =>
  client.get(`/handler/nft/${NFT_HANDLER_ID}/nfts`).then(({ data }) => data);

export const loadVerifiedAddresses = () =>
  client
    .get(`/handler/nft/${NFT_HANDLER_ID}/addresses`)
    .then(({ data }) => data.payload);

export const getNonce = (address: string) =>
  client
    .post(`/handler/nft/${NFT_HANDLER_ID}/nonce`, { address })
    .then(({ data }) => data);

export const selectNft = (nftId: number) =>
  client
    .post(`/handler/nft/${NFT_HANDLER_ID}/select`, { nftId })
    .then(({ data }) => data);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const verifyAddress = (message: any, signature: string) =>
  client
    .post(`/handler/nft/${NFT_HANDLER_ID}/verify`, { message, signature })
    .then(({ data }) => data)
    .catch(parseApiError);

export const loadNFTDetails = (id: number) =>
  client.get(`/handler/nft/detail-${id}`).then(({ data }) => data.payload);

export const loadNftSales = () =>
  client.get(`/handler/nft/sales-20`).then(({ data }) => data.payload);
