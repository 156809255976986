import { createIcon } from '@chakra-ui/icons';

export const NNFlagIcon = createIcon({
  displayName: 'NNFlagIcon',
  viewBox: '0 0 512 512',
  path: (
    <>
      <g clipPath="url(#clip0_4_7245)">
        <path
          d="M256 512C397.385 512 512 397.385 512 256C512 114.615 397.385 0 256 0C114.615 0 0 114.615 0 256C0 397.385 114.615 512 256 512Z"
          fill="#F0F0F0"
        />
        <path
          d="M8.81885 322.783C23.6308 377.742 56.2759 425.376 100.174 459.105V322.783H8.81885Z"
          fill="#D80027"
        />
        <path
          d="M233.739 511.026C241.077 511.658 248.498 512 256 512C374.279 512 473.805 431.779 503.181 322.783H233.739V511.026Z"
          fill="#D80027"
        />
        <path
          d="M503.181 189.217C473.805 80.221 374.279 0 256 0C248.498 0 241.077 0.342 233.739 0.974V189.217H503.181Z"
          fill="#D80027"
        />
        <path
          d="M100.174 52.895C56.2759 86.625 23.6308 134.258 8.81885 189.217H100.174V52.895Z"
          fill="#D80027"
        />
        <path
          d="M509.833 222.609H200.349H200.348V6.08496C176.69 11.331 154.261 19.834 133.565 31.127V222.607V222.608H2.167C0.742 233.539 0 244.683 0 256C0 267.317 0.742 278.461 2.167 289.391H133.564H133.565V480.872C154.261 492.164 176.69 500.669 200.348 505.914V289.394V289.392H509.833C511.256 278.461 512 267.317 512 256C512 244.683 511.256 233.539 509.833 222.609Z"
          fill="#0052B4"
        />
      </g>
      <defs>
        <clipPath id="clip0_4_7245">
          <rect width="512" height="512" fill="white" />
        </clipPath>
      </defs>
    </>
  ),
});
