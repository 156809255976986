import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Collapse,
  Flex,
  Grid,
  StatDownArrow,
  StatUpArrow,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { InfoIcon, StatisticsIcon } from 'icons';
import React, { useEffect, useState } from 'react';
import Draggable, { DraggableCore } from 'react-draggable'; // Update this import
import { useSelector } from 'react-redux';
import { useTournamentPrize } from 'services/games/hooks/use-tournament-prize';
import { getTournamentsList } from 'services/games/modules/tournaments';
import { Countdown } from 'uikit/countdown';

interface StatsElementProps {
  isVisible: boolean;
  onClose: () => void;
}

export const Stats: React.FC<StatsElementProps> = ({ isVisible, onClose }) => {
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const { isOpen, onToggle } = useDisclosure({ defaultIsOpen: true });
  const tournaments = useSelector(getTournamentsList);
  const weeklyRace = tournaments?.find(tour => tour?.feType === 'WEEKLY');
  const actualPrize = useTournamentPrize(
    !!weeklyRace,
    (weeklyRace as {
      currentPrizePool: number;
      maxPossiblePrizePool: number;
      roundEndDate: string;
    }) || {
      currentPrizePool: 0,
      maxPossiblePrizePool: 0,
      roundEndDate: '',
    },
  );

  const [animatedTimeProgress, setAnimatedTimeProgress] = useState(0);
  const [animatedPositionProgress, setAnimatedPositionProgress] = useState(0);

  useEffect(() => {
    const savedPosition = localStorage.getItem('statsElementPosition');
    if (savedPosition) {
      setPosition(JSON.parse(savedPosition));
    }
  }, []);

  useEffect(() => {
    if (isOpen) {
      setAnimatedTimeProgress(75);
      setAnimatedPositionProgress(0);
    } else {
      setAnimatedTimeProgress(0);
      setAnimatedPositionProgress(0);
    }
  }, [isOpen]);

  const handleDrag = (e: any, data: { x: number; y: number }) => {
    setPosition({ x: data.x, y: data.y });
    localStorage.setItem(
      'statsElementPosition',
      JSON.stringify({ x: data.x, y: data.y }),
    );
  };

  const handleRefresh = () => {
    // Implement refresh logic here
  };

  if (!isVisible) return null;

  const renderCountdown = ({ days, hours, minutes, seconds }: any) => {
    if (days > 0) return `${days}d`;
    if (hours > 0) return `${hours}h`;
    if (minutes > 0) return `${minutes}m`;
    return `${seconds}s`;
  };

  const formattedPrize = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    notation: 'compact',
    maximumFractionDigits: 0,
  }).format(actualPrize || 0);

  return (
    <Draggable
      position={position}
      onDrag={handleDrag}
      handle=".drag-handle" // Add this line
    >
      <Box
        position="fixed"
        width="266px"
        backgroundColor="burnt-sugar"
        borderRadius="xl"
        border="1px solid"
        borderColor="truffle-border"
        zIndex={9999}
      >
        <Flex
          h="40px"
          justifyContent="space-between"
          alignItems="center"
          px="4"
          borderBottom="1px solid"
          borderColor="truffle-border"
          className="drag-handle" // Add this line
          cursor="move" // Move cursor style here
        >
          <Flex align="center" gap="2">
            <StatisticsIcon color="candy-floss-text" />
            <Text fontWeight="500" color="white">
              Live Stats
            </Text>
          </Flex>
          <Flex gap="3">
            <InfoIcon
              color="candy-floss-text"
              cursor="pointer"
              onClick={onClose}
            />
          </Flex>
        </Flex>
        <Box m="2" bg="sugar-dust" p="5">
          <Grid templateColumns="repeat(2, 1fr)" gap="0">
            <Box
              borderRight="1px solid"
              borderBottom="1px solid"
              borderColor="truffle-border"
              p={0}
              pr="3"
              pb="3"
            >
              <Text color="candy-floss-text">Profit:</Text>
              <Text fontWeight="500" color="sherbet-green.200">
                $0.00
              </Text>
            </Box>
            <Box
              borderBottom="1px solid"
              borderColor="truffle-border"
              p={0}
              pl="3"
              pb="3"
            >
              <Text color="candy-floss-text">Wins:</Text>
              <Text fontWeight="500" color="sherbet-green.200">
                0
              </Text>
            </Box>
            <Box
              borderRight="1px solid"
              borderColor="truffle-border"
              p={0}
              pr="3"
              pt="3"
            >
              <Text color="candy-floss-text">Wagered:</Text>
              <Text fontWeight="500">$0.00</Text>
            </Box>
            <Box p={0} pl="3" pt="3">
              <Text color="candy-floss-text">Losses:</Text>
              <Text fontWeight="500" color="sherbet-red">
                0
              </Text>
            </Box>
          </Grid>
        </Box>

        <Box m="2" bg="sugar-dust">
          <Flex
            h="40px"
            px="4"
            justifyContent="space-between"
            alignItems="center"
            onClick={onToggle}
            cursor="pointer"
          >
            <Text color="white" fontWeight="500">
              {formattedPrize} Weekly Race
            </Text>
            {isOpen ? (
              <StatUpArrow color="vanilla-text" />
            ) : (
              <StatDownArrow color="candy-floss-text" />
            )}
          </Flex>
          <Collapse in={isOpen}>
            <Box borderTop="1px solid" borderColor="truffle-border">
              <Box p="5">
                <Flex justifyContent="space-between">
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <CircularProgress
                      value={animatedTimeProgress}
                      trackColor="truffle-border"
                      color="sherbet-green.200"
                      size="80px"
                    >
                      <CircularProgressLabel
                        color="candy-floss-text"
                        fontSize="md"
                        fontWeight="500"
                      >
                        <Countdown
                          date={
                            ((weeklyRace?.roundEndDate || 0) as number) * 1000
                          }
                          renderer={renderCountdown}
                        />
                      </CircularProgressLabel>
                    </CircularProgress>
                  </motion.div>
                  <Box h="80px" w="1px" bg="truffle-border" />
                  <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    transition={{ duration: 0.5 }}
                  >
                    <CircularProgress
                      value={animatedPositionProgress}
                      trackColor="truffle-border"
                      color="sherbet-green.200"
                      size="80px"
                    >
                      <CircularProgressLabel
                        color="candy-floss-text"
                        fontSize="md"
                        fontWeight="500"
                      >
                        N/A
                      </CircularProgressLabel>
                    </CircularProgress>
                  </motion.div>
                </Flex>
                <Flex
                  pb="2"
                  justifyContent="space-between"
                  mt="3"
                  borderBottom="1px solid"
                  borderColor="truffle-border"
                >
                  <Text color="candy-floss-text">Prize:</Text>
                  <Text fontWeight="500" color="sherbet-green.200">
                    $
                    {actualPrize.toLocaleString('en-US', {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </Text>
                </Flex>
                <Flex justifyContent="space-between" mt={2}>
                  <Text color="candy-floss-text">Wagered:</Text>
                  <Text fontWeight="500">$0.00</Text>
                </Flex>
              </Box>
            </Box>
          </Collapse>
        </Box>
      </Box>
    </Draggable>
  );
};
