import { DEFAULT_LANG, URL_LANGS } from 'constants/general';

export const applyLangToUrl = (url: string, lang: string) => {
  if (!lang || lang === DEFAULT_LANG) return url;

  return `/${lang}${url === '/' ? '' : url}`;
};

export const getLangFromPathname = (pathname: string) => {
  const lang = pathname.replace(/^\/([^/]*).*$/, '$1');
  if (URL_LANGS.includes(lang)) {
    return lang;
  }
  return DEFAULT_LANG;
};

export const getPathnameWithoutLang = (pathname: string) => {
  const lang = getLangFromPathname(pathname);
  if (URL_LANGS.includes(lang)) {
    return pathname.replace(`/${lang}`, '') || '/';
  }

  return pathname;
};

export const removeTrailingSlash = (str: string) => str.replace(/\/+$/, '');

export const langForContent = (lang: string) => {
  if (['es', 'pt', 'no', 'fr', 'de'].includes(lang)) {
    return 'en';
  }

  return lang;
};
