import { createIcon } from '@chakra-ui/icons';

export const RakebackBoostIcon = createIcon({
  displayName: 'RakebackBoostIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="M10.344 2.3C10.694.334 8.214-.826 6.93.704L1.435 7.247c-1.066 1.27-.163 3.206 1.495 3.206h3.302L5.656 13.7c-.35 1.966 2.13 3.126 3.415 1.596l5.494-6.543c1.066-1.27.163-3.206-1.495-3.206H9.768l.576-3.247Z"
    />
  ),
});
