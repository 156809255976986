import { createIcon } from '@chakra-ui/icons';

export const GamdomLogo = createIcon({
  displayName: 'GamdomLogo',
  viewBox: '0 0 119 29',
  path: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="119"
      height="29"
      viewBox="0 0 119 29"
      fill="none"
    >
      <path
        d="M30.0128 14.1347C29.951 10.1921 33.1043 6.85699 37.6361 6.91881C38.7963 6.91881 39.7892 7.06066 40.6257 7.36617C41.4804 7.64986 42.1096 7.91536 42.4951 8.15904V10.5158C42.1278 10.2103 41.5386 9.88664 40.7057 9.54112C39.8728 9.17378 38.8545 8.99193 37.6361 8.99193C34.2827 8.99193 32.3114 11.2469 32.3114 14.1347C32.3114 17.0007 34.3627 19.2775 37.2724 19.2775C38.8799 19.2775 40.2002 18.6483 41.0112 18.0372V15.0476H36.8832V12.9745H43.2262V21.0451H41.0112V20.3322C40.1965 20.8414 38.8981 21.3469 37.2724 21.3469C32.9988 21.3506 29.951 18.0773 30.0128 14.1347Z"
        fill="#9494a5"
      />
      <path
        d="M44.9719 15.7988C44.9719 12.7291 47.2486 10.2305 50.4602 10.2305C51.9841 10.2305 53.2643 10.8197 53.9954 11.5507V10.536H56.1703V21.0434H53.9553L53.9772 19.9668C53.2861 20.6797 52.1696 21.3489 50.4602 21.3489C47.2486 21.3489 44.9719 18.8684 44.9719 15.7988ZM54.119 15.7806C54.119 13.5038 52.5733 12.1836 50.6238 12.1836C48.6525 12.1836 47.1686 13.7911 47.1686 15.7806C47.1686 17.7737 48.6525 19.3994 50.6238 19.3994C52.5733 19.3994 54.119 18.0756 54.119 15.7806Z"
        fill="#9494a5"
      />
      <path
        d="M74.9118 14.5824V21.0454H72.715V14.8042C72.715 13.1167 72.104 12.1601 70.6638 12.1601C69.3435 12.1601 68.0196 13.1567 68.0196 15.1279V21.0418H65.8229V14.8006C65.8229 13.113 65.2118 12.1565 63.7716 12.1565C62.5532 12.1565 61.3129 13.0112 61.1493 14.717V21.0381H58.9525V10.5307H61.1493V11.7928C61.2729 11.5091 61.9821 10.207 64.0953 10.207C65.5792 10.207 66.7394 10.7962 67.4086 11.9346C67.896 11.0217 68.9944 10.207 70.9874 10.207C73.3479 10.2107 74.9118 11.6946 74.9118 14.5824Z"
        fill="#9494a5"
      />
      <path
        d="M76.5375 15.8003C76.5375 12.7306 78.8143 10.232 82.0258 10.232C83.5497 10.232 84.83 10.8212 85.561 11.5522V7.24234L87.7578 6.875V21.0413H85.521L85.5428 19.9647C84.8518 20.6776 83.7352 21.3468 82.0258 21.3468C78.8143 21.3504 76.5375 18.87 76.5375 15.8003ZM85.6847 15.7821C85.6847 13.5053 84.1389 12.1851 82.1895 12.1851C80.2182 12.1851 78.6725 13.7927 78.6725 15.7821C78.6725 17.7752 80.2182 19.401 82.1895 19.401C84.1389 19.401 85.6847 18.0771 85.6847 15.7821Z"
        fill="#9494a5"
      />
      <path
        d="M89.5871 15.7988C89.5871 12.7291 92.1476 10.2305 95.3409 10.2305C98.5306 10.2305 101.095 12.7291 101.095 15.7988C101.095 18.8684 98.5342 21.3489 95.3409 21.3489C92.1476 21.3489 89.5871 18.8684 89.5871 15.7988ZM98.9779 15.7988C98.9779 13.8057 97.414 12.2017 95.3409 12.2017C93.2678 12.2017 91.7039 13.8093 91.7039 15.7988C91.7039 17.77 93.2678 19.3776 95.3409 19.3776C97.414 19.3776 98.9779 17.7737 98.9779 15.7988Z"
        fill="#9494a5"
      />
      <path
        d="M119 14.5824V21.0454H116.804V14.8042C116.804 13.1167 116.193 12.1601 114.752 12.1601C113.432 12.1601 112.108 13.1567 112.108 15.1279V21.0418H109.911V14.8006C109.911 13.113 109.3 12.1565 107.86 12.1565C106.642 12.1565 105.401 13.0112 105.238 14.717V21.0381H103.045V10.5307H105.241V11.7928C105.365 11.5091 106.074 10.207 108.187 10.207C109.671 10.207 110.832 10.7962 111.501 11.9346C111.988 11.0217 113.087 10.207 115.08 10.207C117.436 10.2107 119 11.6946 119 14.5824Z"
        fill="#9494a5"
      />
      <path
        d="M11.0384 17.5879H11.0348V24.4437H11.0384V17.5879Z"
        fill="#9494a5"
      />
      <path
        d="M11.0384 17.5879H11.0348V24.4437H11.0384V17.5879Z"
        fill="#9494a5"
      />
      <path
        d="M10.4773 14.0804L3.61781 17.3247C3.41414 17.4229 3.2832 17.6338 3.2832 17.8629V24.1623C3.2832 24.3951 3.41414 24.6024 3.61781 24.7006L10.4809 27.9484C10.6555 28.0321 10.8446 28.0721 11.0337 28.0721V13.9531C10.8446 13.9568 10.6555 14.0004 10.4773 14.0804Z"
        fill="#9494a5"
      />
      <path
        d="M10.7293 0.404771L7.51049 1.9978V6.27859L3.74251 8.1262V3.80904L0.367342 5.48572C0.141845 5.59846 0 5.8276 0 6.07491V14.3237C0 14.8074 0.512822 15.1275 0.94563 14.9129L10.4492 10.2502C10.6347 10.1593 10.8347 10.1157 11.0348 10.1157V0.332031C10.9293 0.332031 10.8238 0.357489 10.7293 0.404771Z"
        fill="#9494a5"
      />
      <path
        d="M18.4547 17.3252L11.5916 14.0809C11.4134 13.9936 11.2243 13.9536 11.0352 13.9573V28.0762C11.2243 28.0762 11.4207 28.0362 11.5953 27.9526L18.4547 24.7047C18.6584 24.6065 18.7893 24.3956 18.7893 24.1664V17.8671C18.7893 17.6343 18.6584 17.4234 18.4547 17.3252Z"
        fill="#9494a5"
      />
      <path
        d="M21.7062 5.48599L18.3311 3.80931V8.13012L14.5594 6.2825V2.0017L11.3407 0.408677C11.2461 0.361396 11.137 0.335938 11.0352 0.335938V10.1196C11.2352 10.1196 11.4352 10.1669 11.6207 10.2541L21.1243 14.9168C21.5607 15.1314 22.0699 14.8114 22.0699 14.3276V6.07882C22.0736 5.82786 21.9317 5.59873 21.7062 5.48599Z"
        fill="#9494a5"
      />
    </svg>
  ),
});
