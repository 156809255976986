import { logError } from 'helpers/newrelic';
import { Component } from 'react';

type Props = {
  children: React.ReactNode;
  displayOnError?: React.ReactNode;
};

type State = {
  isError: boolean;
};

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      isError: false,
    };
  }

  componentDidCatch(e: Error) {
    console.warn(e);
    this.setState({ isError: true });
    logError('ERROR_BOUNDARY', e);
  }

  render() {
    const { children, displayOnError = null } = this.props;
    const { isError } = this.state;
    if (isError) {
      return displayOnError;
    }

    return children;
  }
}
