import { TriangleDownIcon, TriangleUpIcon } from '@chakra-ui/icons';
import { Flex, Tooltip } from '@chakra-ui/react';
import { Content } from 'components/content';
import { NavLink } from '../nav-link';

export const NavMenu = ({
  onToggle,
  isOpened,
  title,
  icon,
  children,
  hasLeft,
}) => {
  const flexComponent = (
    <Flex direction="column">
      <NavLink
        icon={icon}
        onClick={onToggle}
        isOpened={hasLeft}
        isActive={isOpened}
        rightIcon={
          hasLeft &&
          (isOpened ? (
            <TriangleDownIcon color="vanilla-text" />
          ) : (
            <TriangleUpIcon transform="rotate(90deg)" />
          ))
        }
      >
        {title}
      </NavLink>
      <Content key={isOpened}>{isOpened && children}</Content>
    </Flex>
  );

  return !hasLeft ? (
    <Tooltip
      gutter={10}
      hasArrow
      placement="right"
      fontSize="md"
      rounded="md"
      bg="vanilla-text"
      fontWeight="500"
      py="1"
      px="2"
      color="toffee-base"
      label={title}
    >
      <div>{flexComponent}</div>
    </Tooltip>
  ) : (
    flexComponent
  );
};
