import { SocialType } from './types';

type StateType = {
  social: SocialType;
};

export const getSocialIsLoaded = (state: StateType) => state.social.isLoaded;
export const getSocialUsers = (state: StateType) => state.social.users;
export const getSocialPosts = (state: StateType) => state.social.posts;
export const getSocialReactions = (state: StateType) => state.social.reactions;
export const getSocialTotal = (state: StateType) => state.social.totalCount;
