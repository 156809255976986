import { PreviewSlider } from 'components/preview-slider';
import { CategoriesIcon } from 'icons';
import { useTranslation } from 'react-i18next';
import { ProviderPreview } from 'services/games/components/provider-preview';
import { PROVIDERS } from 'services/games/modules/slots';

export const ProvidersSlider = ({ slides = PROVIDERS }) => {
  const { t } = useTranslation();
  return (
    <PreviewSlider
      icon={<CategoriesIcon color="candy-floss-text" />}
      titleLink="/casino/providers"
      title={t('casino.providers')}
      SlideComponent={ProviderPreview}
      slides={slides}
    />
  );
};
