import { createIcon } from '@chakra-ui/icons';

export const RouletteIcon = createIcon({
  displayName: 'RouletteIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Zm-.253-4.214c.03.123.137.214.263.214a.276.276 0 0 0 .263-.214c.098-.396.284-.814.56-1.255a4.97 4.97 0 0 1 .854-1.01 6.264 6.264 0 0 1 1.126-.844c.333-.192.657-.331.972-.418A.286.286 0 0 0 12 7.987a.282.282 0 0 0-.21-.268A4.732 4.732 0 0 1 10.323 7a6.424 6.424 0 0 1-1-.885 5.276 5.276 0 0 1-.719-1.042 4.086 4.086 0 0 1-.328-.858A.28.28 0 0 0 8.009 4a.278.278 0 0 0-.266.212 4.556 4.556 0 0 1-.67 1.413A5.391 5.391 0 0 1 5.698 7c-.478.33-.974.57-1.489.716a.284.284 0 0 0-.209.27c0 .13.09.24.215.274.526.145 1.051.416 1.577.813.604.451 1.07.94 1.396 1.469.275.44.461.854.559 1.244Z"
      clipRule="evenodd"
    />
  ),
});
