import { Box } from '@chakra-ui/react';
import { PreviewSlider } from 'components/preview-slider';
import { OriginalsIcon } from 'icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getVersusGamesList, loadGames } from 'services/games/modules/versus';
import { Game } from 'services/games/pages/versus/components/games-list/components/game';
import { useActions } from 'store';

type VersusPreviewProps = {
  style: React.CSSProperties;
};

const VersusPreview: React.FC<VersusPreviewProps> = ({ style, ...game }) => (
  <Box style={style} p="1">
    <Game game={game} isFinished={undefined} />
  </Box>
);

export const VersusSlider = () => {
  const { t } = useTranslation();
  const actions = useActions({ loadGames });
  const games = useSelector(getVersusGamesList);

  useEffect(() => {
    actions.loadGames();
  }, [actions]);

  if (!games.length) {
    return null;
  }

  return (
    <PreviewSlider
      icon={<OriginalsIcon color="candy-floss-text" />}
      title={t('sidebar.versus')}
      titleLink="/casino/game/versus"
      SlideComponent={VersusPreview}
      slides={games}
      gridSize="1-3"
    />
  );
};
