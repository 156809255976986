import { createIcon } from '@chakra-ui/icons';

export const HistoryIcon = createIcon({
  displayName: 'HistoryIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.396 2.604a5.396 5.396 0 1 0 4.998 7.434 1 1 0 0 1-.944-1.68l1.422-1.42a1 1 0 0 1 1.414 0l1.421 1.421a1 1 0 0 1-1.175 1.591 7.396 7.396 0 1 1-.764-5.707 1 1 0 0 1-1.722 1.017 5.393 5.393 0 0 0-4.65-2.656Zm0 .843a1 1 0 0 1 1 1v3.018l1.687 1.124a1 1 0 1 1-1.11 1.664L6.841 8.832A1 1 0 0 1 6.396 8V4.447a1 1 0 0 1 1-1Z"
      clipRule="evenodd"
    />
  ),
});
