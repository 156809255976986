import { TabListProps, TabProps } from '@chakra-ui/react';
import { isUrlExternal } from 'helpers/env';
import { applyLangToUrl } from 'helpers/lang';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router';
import { getUserIsAuthorized } from 'services/user/modules/user';
import { Tabs } from 'uikit/tabs';
import { TabType } from 'uikit/tabs/components/tab';

type TabTypeExtended = TabType & {
  to?: string;
  onClick?: (string: unknown) => void;
};

type Props = {
  tabProps?: TabProps;
  tabListProps?: TabListProps;
  tabs?: TabTypeExtended[];
  rightContent?: React.ReactNode;
};

export const RouterTabs: React.FC<Props> = ({
  tabs = [],
  tabListProps,
  tabProps,
  ...props
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const isAuthorized = useSelector(getUserIsAuthorized);
  const {
    i18n: { language },
  } = useTranslation();

  const tabIndex = useMemo(() => {
    const newTabIndex = tabs.findLastIndex(tab =>
      (location.pathname + location.search).includes(
        applyLangToUrl(tab.to ?? '', language),
      ),
    );
    return newTabIndex > -1 ? newTabIndex : 0;
  }, [location, tabs, language]);

  const handleChange = (index: number) => {
    const tab = tabs[index];
    if (tab) {
      if (tab.onClick) {
        tab.onClick(tab?.to);
      } else if (tab?.to && isUrlExternal(tab?.to)) {
        window.open(tab.to);
      } else {
        navigate(applyLangToUrl(tab?.to ?? '', language), {
          state: { disableScroll: true },
        });
      }
    }
  };

  return (
    <Tabs
      isAuthorized={isAuthorized}
      index={tabIndex}
      onChange={handleChange}
      tabListProps={tabListProps}
      tabProps={tabProps}
      tabs={tabs.map(({ to, ...rest }) => rest)}
      {...props}
    />
  );
};
