import { UseToastOptions, createStandaloneToast } from '@chakra-ui/toast';
import { theme } from 'theme';
import { Toast } from './components/toast';

const { ToastContainer, toast } = createStandaloneToast({
  theme,
  defaultOptions: {
    isClosable: true,
    position: 'bottom-left',
    duration: null,
  },
});

export { ToastContainer };

export const toastClose = toast.close;

export const toastCloseAll = toast.closeAll;

type ToastType = UseToastOptions & {
  title?: React.ReactNode;
  description?: React.ReactNode;
  image?: string;
  iconNode?: React.ReactElement<unknown>;
  icon?: string;
  name?: string;
  ignoreHidden?: boolean;
  Content?: React.FunctionComponent<{ onClose: () => void }>;
};

const createToast =
  () =>
  ({
    title,
    description,
    image,
    icon,
    iconNode,
    name,
    ignoreHidden,
    Content,
    ...props
  }: ToastType) => {
    if (ignoreHidden || !document.hidden) {
      toast({
        status: 'success',
        duration: 5000,
        render: ({ onClose }) => (
          <Toast
            title={title}
            description={description}
            image={image}
            icon={icon}
            iconNode={iconNode}
            name={name}
            onClose={onClose}
            Content={Content}
          />
        ),
        containerStyle: { margin: '0 0 20px 20px', maxWidth: 'none' },
        ...props,
      });
    }
  };

export const toastError = createToast();

export const toastSuccess = createToast();

export const toastInfo = createToast();
