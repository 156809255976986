import { Box, Flex, Grid, Image, Text } from '@chakra-ui/react';
import { Content } from 'components/content';
import { PreviewSlider } from 'components/preview-slider';
import {
  CategoriesIcon,
  FavouritesIcon,
  HeartIcon,
  NFTIcon,
  OriginalsIcon,
  RakebackIcon,
} from 'icons';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NftPreview } from 'services/nft/components/nft-preview';
import { useNftFilter } from 'services/nft/hooks/use-nft-filter';
import { getNftIsLoaded, getNfts, loadNfts } from 'services/nft/modules/nft';

import { formatCredit } from 'helpers/numeral';
import { PromoSlider } from 'services/cms/components/promo-slider';
import { NftSearch } from 'services/nft/components/nft-search';
import { useActions } from 'store';
import { EmptyResults } from 'uikit/empty-results';
import { Link } from 'uikit/link';
import { NFTTraitsSlider } from './components/nft-traits-slider';

const FAVORITES_NFTS = [
  572, 3686, 3864, 706, 7478, 879, 2700, 3348, 1620, 3138, 661, 832, 4337, 2927,
];
const LOVE_NFTS = [
  5627, 3968, 7701, 879, 2833, 675, 1211, 4194, 547, 6193, 6062, 4920, 7386,
  4701,
];

type Props = {
  isCollections?: boolean;
};

export const NFT: React.FC<Props> = ({ isCollections }) => {
  const [search, setSearch] = useState('');
  const [sort] = useState('increase' as const);
  const nfts = useSelector(getNfts);
  const isLoaded = useSelector(getNftIsLoaded);
  const actions = useActions({ loadNfts });
  const { t } = useTranslation();

  const list = useNftFilter(nfts, sort, search);

  const raresNfts = useMemo(
    () => list.filter(nft => nft.rakebackPercent && nft.rakebackPercent >= 0.1),
    [list],
  );
  const randomNfts = useMemo(() => list.slice(0, 24), [list]);
  const favorites = useMemo(
    () => list.filter(nft => FAVORITES_NFTS.includes(nft.id)),
    [list],
  );
  const loves = useMemo(
    () => list.filter(nft => LOVE_NFTS.includes(nft.id)),
    [list],
  );

  useEffect(() => {
    if (!isLoaded) {
      actions.loadNfts();
    }
  }, [isLoaded, actions]);

  let content = (
    <Flex gap="5" direction="column">
      {!search ? (
        <Flex gap="7" direction="column">
          <PreviewSlider
            title="Sherbet NFTs"
            icon={<OriginalsIcon color="candy-floss-text" />}
            slides={randomNfts}
            SlideComponent={NftPreview}
            titleLink="/nft/collections/0xa54e6b99f58b6165228abcdc681e9a16bcefdbe1"
            size="md"
            isLoaded={isLoaded}
          />
          <PreviewSlider
            title={t('nft.max-rakeback')}
            icon={<RakebackIcon color="candy-floss-text" />}
            slides={raresNfts}
            SlideComponent={NftPreview}
            size="md"
            isLoaded={isLoaded}
          />
          <PreviewSlider
            title={t('nft.slider2')}
            icon={<HeartIcon color="candy-floss-text" />}
            slides={loves}
            SlideComponent={NftPreview}
            size="md"
            isLoaded={isLoaded}
          />
          <PreviewSlider
            title={t('nft.slider3')}
            icon={<FavouritesIcon color="candy-floss-text" />}
            slides={favorites}
            SlideComponent={NftPreview}
            size="md"
            isLoaded={isLoaded}
          />
        </Flex>
      ) : (
        <PreviewSlider
          title={t('casino.results', {
            results: formatCredit(list.length),
          })}
          icon={<CategoriesIcon color="candy-floss-text" />}
          slides={randomNfts}
          SlideComponent={NftPreview}
          size="md"
          isLoaded={isLoaded}
        />
      )}
    </Flex>
  );

  if (isCollections) {
    content = (
      <Grid
        mb="7"
        columnGap="3"
        rowGap="5"
        w="full"
        templateColumns={{
          base: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          '2xl': 'repeat(3, 1fr)',
        }}
      >
        <Link
          color="candy-floss-text"
          _hover={{
            opacity: '0.9',
          }}
          to="/nft/collections/0xa54e6b99f58b6165228abcdc681e9a16bcefdbe1"
        >
          <Box>
            <Image
              roundedTop="xl"
              w="full"
              objectFit="cover"
              h="100px"
              marginBottom="-40px"
              alt="Banner"
              src="https://i.seadn.io/gcs/files/900d0572642b3d115d62721ffdff4aae.png?auto=format&dpr=1&w=3840"
              draggable="false"
            />
            <Box px="5" bg="sugar-dust" pb="5" roundedBottom="xl">
              <Image
                position="relative"
                border="4px solid"
                borderColor="burnt-sugar"
                w="5rem"
                rounded="full"
                height="5rem"
                alt="Sherbet Logo"
                src="https://i.seadn.io/gcs/files/4d33825adedcd80ae3eb513ea71b91f4.gif?auto=format&dpr=1&w=384"
                draggable="false"
              />
              <Flex align="center" mt="2">
                <Text fontWeight="500" fontSize="xl">
                  Sherbet
                </Text>
              </Flex>
              <Text color="candy-floss-text" noOfLines={2}>
                An all-new cryptocurrency casino experience. Play an
                ever-expanding catalog of games using BTC, LTC, ETH, and more.
                Connect your Sherbet NFT to your Sherbet.com account to unlock
                exclusive benefits, including increased rakeback, profit share,
                and additional rewards.
              </Text>
            </Box>
          </Box>
        </Link>
      </Grid>
    );
  }

  return (
    <Content w="full" maxW="6xl" p="5" mx="auto" flexGrow="1">
      <Flex direction="column">
        <PromoSlider />
        <Flex flexDirection="column" gap="5" mt="7">
          <NftSearch search={search} setSearch={setSearch} />
          <NFTTraitsSlider />
        </Flex>
        <Box mt="7">
          {!isLoaded || list.length ? (
            content
          ) : (
            <EmptyResults
              icon={NFTIcon}
              title="No NFTs found"
              description="Try searching for something else"
            />
          )}
        </Box>
      </Flex>
    </Content>
  );
};
