export const rouletteColors = [
  '#A3709D',
  '#4fa0d8',
  '#1150b3',
  '#b19453',
  '#405C6F',
  '#649013',
  '#7346cf',
  '#a5a100',
  '#bb40b8',
  '#36725F',
  '#dc823c',
  '#81504e',
  '#c93c6f',
  '#884121',
  '#cd5050',
];

const red = '#DF3B3B';
const green = '#48C054';
const black = '#272A35';

export const ENTRIES = [1, 8, 2, 9, 3, 10, 4, 0, 11, 5, 12, 6, 13, 7, 14];
export const ENTRIES_LOOKUP = [
  7, 0, 2, 4, 6, 9, 11, 13, 1, 3, 5, 8, 10, 12, 14,
];

export const pickedGroupToColor = (
  pickedGroup: number,
  bonusNumber?: number,
) => {
  switch (pickedGroup) {
    case 0:
      return red;
    case 1:
      return green;
    case 2:
      return black;
    case 3:
      return bonusNumber ? rouletteColors[bonusNumber] : '';
    default:
      return '';
  }
};

export const toColor = (x: number) => {
  if (x === 0) {
    return green;
  }
  if (x < 8) {
    return red;
  }
  return black;
};

export const alphabet = (idx: number) => (idx + 10).toString(36).toUpperCase();
