/* eslint-disable max-lines */
import { Flex } from '@chakra-ui/react';
import { BonusPopupContext } from 'contexts/bonus-popup-context';
import { BonusesIcon } from 'icons';
import { useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useBonuses } from 'services/user/hooks/use-bonuses';
import {
  getUserFreespins,
  loadUser,
  redeemRakeback,
  startFreeSpin,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { EmptyResults } from 'uikit/empty-results';
import { BonusCard } from './components/bonus-card';
import { FreeSpinCard } from './components/free-spin-card';
import { ReloadSundayCard } from './components/reload-sunday-card';
import { WeeklyBoostCard } from './components/weekly-boost-card';

const sortFreeSpins = (a, b) => {
  if (a.status === b.status) {
    return 0;
  }

  // If 'active' status comes first, place it at the top
  if (a.status === 'active') {
    return -1;
  }

  // Otherwise, 'active' status comes later, place it below
  return 1;
};

export const BonusView = ({ onClose = () => {}, isDepositStopper, onSkip }) => {
  const { t } = useTranslation();
  const actions = useActions({
    loadUser,
    startFreeSpin,
    redeemRakeback,
  });
  const freespins = useSelector(getUserFreespins);

  const { setIsHidden, isAvailable, setIsAvailable } =
    useContext(BonusPopupContext);

  useEffect(() => {
    actions.loadUser();
  }, [actions]);

  useEffect(() => {
    if (!isAvailable) {
      const setBonusAvailable = () => {
        localStorage.setItem('bonus_displayed', 'true');
        setIsHidden(false);
        setIsAvailable(true);
      };
      document.addEventListener('visibilitychange', setBonusAvailable);
      document.documentElement.addEventListener(
        'mouseleave',
        setBonusAvailable,
      );

      return () => {
        setBonusAvailable();
        document.removeEventListener('visibilitychange', setBonusAvailable);
        document.documentElement.removeEventListener(
          'mouseleave',
          setBonusAvailable,
        );
      };
    }

    return undefined;
  }, [isAvailable, setIsAvailable, setIsHidden]);

  let data = useBonuses({ onClose });

  let list = [];

  const welcomeBonus = data.find(
    item => item.bonusType === 'Welcome Deposit Bonus',
  );
  const reloadSundayBonus = data.find(
    item => item.bonusType === 'Sunday Deposit Reload',
  );
  const weeklyBoostBonus = data.find(
    item => item.bonusType === 'Weekly Bonus For Top Spenders',
  );

  const excludeList = [
    'Welcome Deposit Bonus',
    'Sunday Deposit Reload',
    'Weekly Bonus For Top Spenders',
  ];

  data = data.filter(item => !excludeList.includes(item.bonusType));

  if (!isDepositStopper) {
    list = freespins
      .filter(spin => spin.freespinType !== 'MAX')
      .map(spin => <FreeSpinCard key={spin.id} {...spin} />);
    list = list.concat(
      data.map((item, index) => <BonusCard key={index} {...item} />),
    );
  } else {
    list = data
      .filter(item => item.isDepositReload)
      .map((item, index) => <BonusCard key={index} {...item} />);
  }
  return (
    <Flex flexDir="column" gap="5" w="full" px="5" h="full">
      {reloadSundayBonus && <ReloadSundayCard bonus={reloadSundayBonus} />}
      {weeklyBoostBonus && <WeeklyBoostCard bonus={weeklyBoostBonus} />}
      {list.length > 0 ||
      reloadSundayBonus ||
      weeklyBoostBonus ||
      welcomeBonus ? (
        list
      ) : (
        <Flex align="center" w="full" h="full" justifyContent="center">
          <EmptyResults
            icon={BonusesIcon}
            title="You have no bonuses right now"
            description="Check back here again later"
          />
        </Flex>
      )}
    </Flex>
  );
};
