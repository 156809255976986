import { createIcon } from '@chakra-ui/icons';

export const LogoutIcon = createIcon({
  displayName: 'LogoutIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M0 8C0 4.004 3.129.7 7.067.7c1.293 0 2.506.36 3.548.986A1 1 0 1 1 9.585 3.4a4.868 4.868 0 0 0-2.518-.7C4.304 2.7 2 5.037 2 8c0 2.963 2.304 5.3 5.067 5.3.917 0 1.775-.254 2.518-.7a1 1 0 1 1 1.03 1.714 6.867 6.867 0 0 1-3.548.986C3.129 15.3 0 11.996 0 8Zm11.493-3.507a1 1 0 0 1 1.414 0l2.8 2.8a1 1 0 0 1 0 1.414l-2.8 2.8a1 1 0 0 1-1.414-1.414L12.586 9H5.9a1 1 0 1 1 0-2h6.686l-1.093-1.093a1 1 0 0 1 0-1.414Z"
      clipRule="evenodd"
    />
  ),
});
