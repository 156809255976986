import { createSelector } from '@reduxjs/toolkit';
import { shallowEqual } from 'react-redux';
import { VersusType } from './types';

type StateType = {
  versus: VersusType;
};

export const getVersus = (state: StateType) => state.versus;

export const getVersusGames = (state: StateType) => getVersus(state).games;

export const getVersusGame = (id: string) => (state: StateType) =>
  getVersusGames(state)[id];

export const getVersusUsers = (state: StateType) => getVersus(state).users;

export const getVersusGamesList = createSelector(
  getVersusGames,
  games =>
    Object.entries(games ?? {})
      .sort(([, a], [, b]) => a.timestamp - b.timestamp)
      .map(([, value]) => value),
  {
    memoizeOptions: {
      resultEqualityCheck: shallowEqual,
    },
  },
);

export const getVersusGamesLoaded = (state: StateType) =>
  getVersus(state).gamesLoaded;

export const getVersusHistory = (state: StateType) => getVersus(state).history;
export const getVersusTotalCount = (state: StateType) =>
  getVersus(state).totalCount;
