import { Box, Flex, Image, Progress, Text } from '@chakra-ui/react';
import { formatCredit } from 'helpers/numeral';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  LEVELS,
  LEVEL_IMAGES,
  LEVEL_NAMES,
  getUserLevel,
  getUserLevelInfo,
} from 'services/user/modules/user';
import styles from './style.module.scss';

export const ProfileVip = () => {
  const level = useSelector(getUserLevel);
  const levelInfo = useSelector(getUserLevelInfo);
  const { t } = useTranslation();

  const currentLevel = LEVEL_NAMES[level as keyof typeof LEVEL_NAMES];

  const nextLevel = LEVEL_NAMES[(level + 1) as keyof typeof LEVEL_NAMES];
  const { perks } = LEVELS[level as keyof typeof LEVEL_NAMES] || {
    perks: [],
  };

  const min = levelInfo
    ? Number(levelInfo.levelEndExp) - Number(levelInfo.levelExp)
    : 0;
  const max = levelInfo ? Number(levelInfo.levelEndExp) : 0;
  const value = levelInfo ? Number(levelInfo.exp) : 0;

  let msg = '';

  if (perks.length) {
    const isRakeBoost = perks.includes('Temporary 20% Rakeback Boost');
    const bonus = perks.find(perk => perk.includes('deposit bonus'));
    msg = `Reach ${nextLevel} to unlock a level up bonus`;
    if (bonus) {
      if (isRakeBoost) {
        msg += `, ${bonus}`;
      } else {
        msg += ` and ${bonus}`;
      }
    }
    if (isRakeBoost) {
      msg += ', and Rakeboost';
    }
    if (
      level > 0 &&
      perks.length === 1 &&
      perks.at(0) !==
        LEVELS[(level - 1) as keyof typeof LEVEL_NAMES]?.perks?.at(0)
    ) {
      msg += ` and ${perks[0]}`;
    }
  }

  return (
    <Box>
      <Flex direction={{ base: 'column', xl: 'row' }}>
        <Flex overflow="hidden" w="full">
          <Flex p="5" direction="column" justifyContent="center" w="full">
            {!!nextLevel && (
              <Box w="full">
                <Progress
                  rounded="full"
                  className={styles.progress}
                  w="full"
                  h="1.5"
                  bg="truffle-border"
                  sx={{
                    '& > div': {
                      backgroundColor: 'sherbet-green.200',
                    },
                  }}
                  aria-label="level-progress"
                  min={min}
                  max={max}
                  value={value}
                />
                <Flex
                  justifyContent="space-between"
                  align="center"
                  gap="5"
                  mt="3"
                >
                  {/* <Flex align="center" gap="2">
                    <Image
                      src={LEVEL_IMAGES[level as keyof typeof LEVEL_IMAGES]}
                      alt="vip"
                      width="18px"
                      height="18px"
                      rounded="base"
                    />
                    <Text color="candy-floss-text">{currentLevel}</Text>
                  </Flex> */}
                  <Flex align="center" gap="2">
                    <Image
                      src={
                        LEVEL_IMAGES[(level + 1) as keyof typeof LEVEL_IMAGES]
                      }
                      alt="vip"
                      width="18px"
                      height="18px"
                      rounded="base"
                    />
                    <Text color="candy-floss-text">
                      {levelInfo &&
                        formatCredit(
                          (Number(levelInfo.levelEndExp) -
                            Number(levelInfo.exp)) /
                            100,
                        )}{' '}
                      XP {t('vip.until')} {nextLevel}
                    </Text>
                  </Flex>
                </Flex>
              </Box>
            )}
            {!!msg && (
              <Flex
                bg="sugar-dust"
                rounded="xl"
                p="3"
                px="4"
                mt="5"
                align="start"
              >
                <Text color="candy-floss-text">{msg}</Text>
              </Flex>
            )}
          </Flex>
        </Flex>
      </Flex>
    </Box>
  );
};
