import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { FormGlobalError } from 'forms/form-global-error';
import { PasswordInput } from 'forms/password-input';
import { MODAL_TYPE, useModal } from 'modals';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Result } from 'services/auth/components/result';
import { resetPassword } from 'services/auth/modules/auth';
import { useActions } from 'store';
import { Errors, FormSubmit } from 'types';
import * as Yup from 'yup';

type Props = {
  code?: string;
  email?: string;
};

export const ResetPasswordForm: React.FC<Props> = ({ code, email }) => {
  const [isSuccess, setIsSuccess] = useState(false);
  const { onOpen } = useModal(MODAL_TYPE.AUTH);

  const actions = useActions({ resetPassword });
  const { t } = useTranslation();

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      password: '',
      confirmPassword: '',
      global: '',
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .min(6, 'errors.password.min')
        .required('errors.password.required'),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref('password'), ''], 'errors.password.match')
        .required('errors.password.required'),
    }),
    onSubmit: async ({ password }, { setErrors }) => {
      try {
        await actions.resetPassword({ email, password, code });
        setIsSuccess(true);
      } catch (errors) {
        setErrors(errors as Errors);
      }
    },
  });

  if (isSuccess) {
    return (
      <Box>
        <Result
          headingText={t('form.title.password-reset')}
          text={t('reset-password.success')}
          buttonText={t('form.button.back-to-login')}
          onClick={() => onOpen({ tab: 'login' })}
        />
      </Box>
    );
  }

  return (
    <Box>
      <Heading fontSize="2xl" mb="2">
        {t('form.button.reset-password')}
      </Heading>
      <Text fontSize="lg" color="candy-floss-text" mb="5">
        {t('signup-form.description')}
      </Text>
      <FormControl as="form" onSubmit={formik.handleSubmit as FormSubmit}>
        <Flex direction="column" gap="3" mb="5">
          <PasswordInput
            name="password"
            placeholder="form.label.password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.password}
            isInvalid={!!formik.errors.password && formik.touched.password}
            errorMessage={formik.errors.password}
          />
          <PasswordInput
            label="form.label.confirm-password"
            name="confirmPassword"
            placeholder="form.label.confirm-password"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.confirmPassword}
            isInvalid={
              !!formik.errors.confirmPassword && formik.touched.confirmPassword
            }
            errorMessage={formik.errors.confirmPassword}
          />
          <FormGlobalError fontSize="md" error={formik.errors.global} />
        </Flex>
        <Flex align="center" flexDir={{ base: 'column', md: 'row' }} gap="5">
          <Button
            w={{ base: 'full', md: 'auto' }}
            type="submit"
            colorScheme="purple"
            isLoading={formik.isSubmitting}
            rounded="full"
          >
            {t('form.button.reset-password')}
          </Button>
        </Flex>
      </FormControl>
    </Box>
  );
};
