import { Box, Button, Flex, IconButton, Text, Tooltip } from '@chakra-ui/react';
import {
  GET_IS_TELEGRAM,
  IS_PIP_AVAILABLE,
  IS_PRERENDER,
  SLOTS_HANDLER_ID,
} from 'constants/general';
import { AnimatePresence, motion } from 'framer-motion';
import { track } from 'helpers/heap';
import { useMobile } from 'hooks/use-mobile';
import { usePip } from 'hooks/use-pip';
import { CasinoIcon, CheckIcon, PopoutIcon, SherbetLogoIcon } from 'icons';
import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RemoveScroll } from 'react-remove-scroll';
import { GameLayout } from 'services/games/layout/game-layout';
import {
  SlotsClientType,
  getSlotsGame,
  startDemoSession,
  startSession,
} from 'services/games/modules/slots';
import {
  getUserBalance,
  getUserBonusSessionActive,
  getUserIsAuthorized,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { EmptyResults } from 'uikit/empty-results';
import { Skeleton } from 'uikit/skeleton';

import { useStats } from 'contexts/stats-context/stats-context';
import styles from './style.module.scss';

const errors = {
  153: {
    title: 'Game not available in your region',
    description: 'If you are using a VPN, please disable it and try again',
  },
  404: {
    title: 'Game not found',
    description: 'This game could not be found',
  },
  BONUS_SESSION_DISABLED_GAME: {
    title: 'Unavailable during bonus session',
    description: 'Leave your current bonus session to play',
  },
  general: {
    title: 'Game not available',
    description: 'This game is not available right now',
  },
};

export const Game = ({
  gameId,
  isMulti,
  onClose,
  onMulti,
  isLoaded,
  hasStatsElement = true,
}) => {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [iframeUrl, setIframeUrl] = useState(null);
  const [error, setError] = useState(false);
  const [isTheatre, setIsTheatre] = useState(false);
  const [isStarted, setIsStarted] = useState(false);
  const timeout = useRef();
  const balance = useSelector(getUserBalance);
  const { isPiP, buttonRef } = usePip({ id: 'iframe-game' });

  const isMobile = useMobile();
  const isBonusSession = useSelector(getUserBonusSessionActive);
  const [isMount, setIsMount] = useState(false);

  const actions = useActions({
    startSession,
    startDemoSession,
  });

  const isAuthorized = useSelector(getUserIsAuthorized);
  const game = useSelector(getSlotsGame(gameId));
  const [isReal, setIsReal] = useState(true);

  const isDemo = game?.hasDemo && !isReal;

  const handleTheatreMode = () => setIsTheatre(prev => !prev);

  // Use user agent to detect actual mobile devices
  const isMobileDevice = useMemo(
    () => /Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
    [],
  );

  const launchGame = useCallback(
    ({ game_url: gameUrl }, id) => {
      track('launch_game', { gameId: id });
      if (isMobileDevice) {
        if (GET_IS_TELEGRAM()) {
          setIsStarted(true);
          setIframeUrl(gameUrl);
        } else {
          window.location.href = gameUrl;
        }
      } else {
        setIframeUrl(gameUrl);
      }
    },
    [isMobileDevice],
  );

  const onLaunchGame = useCallback(async () => {
    if (game) {
      const action = isDemo ? actions.startDemoSession : actions.startSession;
      setIsLoading(true);
      setError(null);
      try {
        const data = await action(
          gameId,
          isMobile ? SlotsClientType.mobile : SlotsClientType.desktop,
        );
        launchGame(data.payload, gameId);
      } catch (e) {
        const keys = Object.keys(e);
        setError(errors[e.global] || errors[keys[0]] || errors.general);
      } finally {
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => setIsLoading(false), 500);
      }
    }
  }, [game, gameId, isDemo, isMobile, launchGame, actions]);

  useEffect(() => {
    if (!isMobileDevice && (isAuthorized || isDemo) && !IS_PRERENDER) {
      onLaunchGame();
    }
  }, [onLaunchGame, isMobileDevice, isAuthorized, isDemo, isBonusSession]);

  useEffect(() => {
    if (game?.hasDemo) {
      if (!isAuthorized) {
        setIsReal(false);
      } else if (balance <= 0) {
        setIsReal(false);
      }
    }

    setIsMount(true);
  }, [isAuthorized, game?.hasDemo]);

  useEffect(() => () => clearTimeout(timeout.current), []);

  const handleChangeDemo = () => {
    setIsReal(real => !real);
  };

  const { isStatsVisible, toggleStatsElement } = useStats();

  const gameContent = (
    <Flex
      flexDirection="column"
      maxW={
        isTheatre
          ? 'calc((100vh - 65px) / 0.5625)'
          : 'calc((100vh - 65px - 65px) / 0.5625)'
      }
      mx="auto"
      w="full"
      px={isTheatre || isMulti ? '0' : '5'}
      pt={isTheatre || isMulti ? '0' : '5'}
    >
      <Flex
        className={styles.content}
        flex="auto"
        overflow="hidden"
        position="relative"
        paddingTop="56.3%"
        backgroundSize="cover"
        justifyContent="center"
        bgColor="sugar-dust"
      >
        <Flex
          direction="column"
          position="absolute"
          top="0"
          left="0"
          right="0"
          bottom="0"
          h="full"
          m="auto"
          zIndex="10"
          id="fullscreen"
          className={styles.fullScreen}
        >
          <Box
            key={`${gameId}_${isDemo}`}
            id={`game_wrapper_${gameId}`}
            className={styles.game}
            pos="relative"
          >
            {iframeUrl && (
              <iframe
                id="iframe-game"
                src={iframeUrl}
                allowFullScreen
                title="game"
                style={{
                  height: '100%',
                  width: '100%',
                  position: 'relative',
                  zIndex: '5',
                }}
              />
            )}
            {isPiP && (
              <Flex
                pos="absolute"
                top="0"
                right="0"
                left="0"
                bottom="0"
                justifyContent="center"
                alignItems="center"
              >
                <EmptyResults
                  icon={PopoutIcon}
                  title={t('popout.in-mode')}
                  description="Close the popout to return to the game"
                />
              </Flex>
            )}
          </Box>

          <AnimatePresence>
            {isLoading && (
              <motion.div
                initial={{ opacity: 1, y: 0 }}
                exit={{
                  opacity: 0,
                  y: '-8px',
                  transition: { duration: 0.18, ease: 'easeOut', delay: 2 },
                }}
                className={styles.animated}
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  right: 0,
                  bottom: 0,
                  backgroundColor: 'var(--colors-sherbet-purple)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  zIndex: 20,
                }}
              >
                <SherbetLogoIcon color="white" h="32px" w="auto" />
              </motion.div>
            )}
          </AnimatePresence>

          {isMount &&
            !isLoading &&
            isMobileDevice &&
            (isAuthorized || isDemo) &&
            !error && (
              <Flex
                position="absolute"
                w="full"
                h="full"
                left="0"
                right="0"
                top="0"
                bottom="0"
                p="5"
                justify="center"
                bg="sugar-dust"
                align="center"
              >
                <Flex align="center" flexDirection="column" textAlign="center">
                  <Flex align="center">
                    <Button colorScheme="purple" onClick={onLaunchGame}>
                      {t('actions.play-game')}
                    </Button>
                  </Flex>
                </Flex>
              </Flex>
            )}
          {isMount && !isAuthorized && !isDemo && (
            <Flex
              position="absolute"
              w="full"
              h="full"
              left="0"
              right="0"
              top="0"
              bottom="0"
              p="5"
              justify="center"
              align="center"
            >
              <EmptyResults
                icon={CasinoIcon}
                title={t('casino.signin-to-play')}
                description={t('casino.need-account')}
              />
            </Flex>
          )}
          {error && (
            <Flex
              position="absolute"
              w="full"
              h="full"
              left="0"
              right="0"
              top="0"
              bottom="0"
              p="5"
              justify="center"
              align="center"
            >
              <EmptyResults
                icon={CasinoIcon}
                title={error.title}
                description={error.description}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
      {/* <Flex align="center" mt="5">
        {isMulti && (
          <IconButton
            variant="link"
            size="sm"
            minW="0"
            onClick={onClose}
            zIndex="1000"
          >
            <CloseIcon />
          </IconButton>
        )}
      </Flex> */}
    </Flex>
  );

  if (isMobile && isStarted) {
    return (
      <RemoveScroll>
        <Box pos="fixed" top="0" left="0" right="0" bottom="0" zIndex="100000">
          <iframe
            src={iframeUrl}
            loading="lazy"
            allowFullScreen
            seamless
            title="game"
            style={{
              height: '100%',
              width: '100%',
            }}
          />
        </Box>
      </RemoveScroll>
    );
  }

  if (isMulti) {
    return gameContent;
  }

  return (
    <GameLayout
      isLoaded={isLoaded}
      gameId={gameId}
      handlerId={SLOTS_HANDLER_ID}
      hasTheatreMode
      isTheatreMode={isTheatre}
      handleTheatreMode={handleTheatreMode}
      onMulti={onMulti}
      hasStatsElement={hasStatsElement}
      toggleStatsElement={toggleStatsElement}
      rightContent={
        <Flex align="center" gap="5">
          {game?.hasDemo && (
            <Flex
              alignItems="center"
              cursor="pointer"
              onClick={handleChangeDemo}
              color="candy-floss-text"
              _hover={{ opacity: 0.9 }}
            >
              <Text
                mr="2"
                color="candy-floss-text"
                lineHeight="14px"
                fontWeight="500"
              >
                {t('casino.demo-mode')}
              </Text>
              <CheckIcon color={isReal ? 'candy-floss-text' : 'green.500'} />
            </Flex>
          )}
          {IS_PIP_AVAILABLE && (
            <Tooltip
              gutter="10"
              hasArrow
              fontSize="md"
              rounded="md"
              placement="top"
              bg="vanilla-text"
              fontWeight="500"
              px="2"
              color="toffee-base"
              label={t('actions.pop-out')}
            >
              <IconButton
                aria-label="Pop Out"
                ref={buttonRef}
                size="sm"
                minW="0"
                color="candy-floss-text"
                variant="link"
                rounded="full"
                icon={<PopoutIcon />}
              />
            </Tooltip>
          )}
        </Flex>
      }
    >
      <Flex
        flexDirection="column"
        maxW={isTheatre ? 'full' : '6xl'}
        w="full"
        mx="auto"
      >
        {isLoaded ? (
          gameContent
        ) : (
          <Box
            maxW="calc((100vh - 65px - 65px) / 0.5625)"
            p="5"
            pb="0"
            mx="auto"
            w="full"
          >
            <Skeleton rounded="0" paddingTop="56.25%" />
          </Box>
        )}
      </Flex>
    </GameLayout>
  );
};
