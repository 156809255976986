import bigInt from 'big-integer';
import { ETHIcon } from 'icons';

export const getNftUrl = (id: number) => {
  if (process.env.REACT_APP_NFT_HOST === 'testnets.opensea.io') {
    return `https://testnets.opensea.io/assets/sepolia/0x98C766a421833F4C0c41b6Ad6406e963A73c24CB/${id}`;
  }
  return `https://blur.io/asset/0xa54e6b99f58b6165228abcdc681e9a16bcefdbe1/${id}`;
};

// function to add the seller fee, protocol fee, and royalty fee to get the last sale price and convert it from wei to ether
export const getLastSalePrice = (
  sellerInfo: { amount: number },
  protocolInfo: { amount: number },
  royaltyInfo: { amount: number },
) => {
  const sellerFee = sellerInfo?.amount || 0;
  const protocolFee = protocolInfo?.amount || 0;
  const royaltyFee = royaltyInfo?.amount || 0;
  const lastSalePriceInBigInt =
    // @ts-ignore
    bigInt(sellerFee) + bigInt(protocolFee) + bigInt(royaltyFee);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const lastSalePrice = lastSalePriceInBigInt / (bigInt(10 ** 18) as any); // because 10 ** 18 is the number of wei in 1 ether

  return lastSalePrice;
};

export const formatAddress = (address: string) =>
  address && `${address.slice(0, 4)}...${address.slice(-4)}`;

export const iconsByAsset = {
  ETH: ETHIcon,
};
