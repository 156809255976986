import { createIcon } from '@chakra-ui/icons';

export const ChipsLogo = createIcon({
  displayName: 'ChipsLogo',
  viewBox: '0 0 57 52',
  path: (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 52" fill="#9494a5">
      <path d="M14.18 36.04s7.13-7.78 13.87-.13a11.62 11.62 0 012.37-2.08 8.08 8.08 0 0012.86 4.13c2.2 2.88 6.5 7.55 8.69 3.32 3.128-6.064-2.729-6.328-3.244-6.34h-.036c5.03-5.67 12.89 6.78 1.79 14-10.51 6.8-21.21-3.69-22.39-4.91-1.18 1.22-11.88 11.71-22.38 4.91-11.14-7.22-3.26-19.67 1.74-14 0 0-6.55 0-3.27 6.34 3 5.83 10-5.24 10-5.24zM35.84 0a10.45 10.45 0 0110.48 10.45v7.49a10.5 10.5 0 01-.32 2.59h2a4 4 0 014 3.97v.17a4 4 0 01-4 4h-2.27a8.08 8.08 0 00-15 0H7.7a4 4 0 01-4-4v-.14a4 4 0 014-4h2a10.5 10.5 0 01-.32-2.59v-7.49A10.45 10.45 0 0119.84 0z"></path>
    </svg>
  ),
});
