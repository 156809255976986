import { useBreakpointValue } from '@chakra-ui/react';

export const useMobile = () => {
  const isMobile = useBreakpointValue(
    { base: true, lg: false },
    { ssr: false },
  );

  return isMobile;
};
