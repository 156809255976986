import { createIcon } from '@chakra-ui/icons';

export const PromotionsIcon = createIcon({
  displayName: 'PromotionsIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.444.168a2.206 2.206 0 0 0-2.882 1.194L3.037 2.63a.752.752 0 0 1-.407.406l-1.268.525A2.205 2.205 0 0 0 .168 6.443l.525 1.27a.752.752 0 0 1 0 .576L.168 9.557a2.206 2.206 0 0 0 1.194 2.88l1.269.526a.75.75 0 0 1 .406.407l.526 1.27a2.206 2.206 0 0 0 2.88 1.193l1.27-.526a.75.75 0 0 1 .575 0l1.267.525a2.205 2.205 0 0 0 2.882-1.194l.526-1.269a.752.752 0 0 1 .407-.405l1.268-.526a2.204 2.204 0 0 0 1.194-2.882l-.525-1.268v-.001a.749.749 0 0 1 0-.575l.525-1.268a2.205 2.205 0 0 0-1.194-2.882l-1.268-.525a.75.75 0 0 1-.42-.437l-.512-1.238A2.206 2.206 0 0 0 9.557.17l-1.27.523a.751.751 0 0 1-.575.001L6.444.168ZM5.152 10.52l.262-1.529-1.109-1.079A1.014 1.014 0 0 1 4.05 6.87a.995.995 0 0 1 .817-.69l1.537-.224.682-1.394a1.026 1.026 0 0 1 1.828 0l.682 1.394 1.537.225c.382.052.697.322.817.69.12.374.022.771-.255 1.041l-1.11 1.08.263 1.52c.067.39-.09.773-.405.997a1.01 1.01 0 0 1-1.072.083L8 10.872l-1.379.72a1 1 0 0 1-1.064-.075 1.017 1.017 0 0 1-.405-.997Z"
      clipRule="evenodd"
    />
  ),
});
