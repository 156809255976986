import { createIcon } from '@chakra-ui/icons';

export const TRXIcon = createIcon({
  displayName: 'TRXIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <g clipPath="url(#a)">
        <path fill="#EF0027" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z" />
        <path
          fill="#fff"
          d="M10.966 4.956 3.75 3.628l3.798 9.556 5.291-6.447-1.873-1.78Zm-.116.585 1.104 1.05-3.019.546 1.915-1.596ZM8.279 7.028l-3.182-2.64 5.201.958-2.019 1.682Zm-.226.467-.52 4.29-2.797-7.042 3.317 2.752Zm.48.227 3.343-.605-3.835 4.672.492-4.067Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </>
  ),
});
