import { IS_PRERENDER, ROULETTE_HANDLER_ID } from 'constants/general';
import { HandlerType } from './types';

type StateType = {
  handler: HandlerType;
};

export const getHandlerState = (state: StateType) => state.handler;

export const getUserCountry = (state: StateType) =>
  getHandlerState(state).countryCode;

export const getHandlers = (state: StateType) =>
  getHandlerState(state).handlers;

export const getHandlerIdsByType = (type: string) => (state: StateType) =>
  getHandlerState(state).handlerTypeIds?.[type] || [];

export const getHandler = (id: number) => (state: StateType) =>
  getHandlers(state)[id];

export const getHandlerSettings = (id: number) => (state: StateType) =>
  getHandler(id)(state)?.settings;

export const getHandlerIsLoaded = (state: StateType) =>
  getHandlerState(state).isLoaded;

export const getHandlerIsReady = (state: StateType) =>
  getHandlerState(state).isReady;

export const getHandlerIsBlocked = (state: StateType) =>
  !IS_PRERENDER && getHandlerState(state).blocked;

export const getHandlerIsFatalError = (state: StateType) =>
  getHandlerState(state).isFatalError;

export const getRouletteSettingsNumberWagerGroups = (state: StateType) =>
  getHandlerSettings(ROULETTE_HANDLER_ID)(state).numberWagerGroups;
