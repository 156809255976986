/* eslint-disable react/jsx-no-useless-fragment */
import { Box, Flex } from '@chakra-ui/react';
import { Content } from 'components/content';
import { Loading } from 'components/loading';
import { MaintenanceWrapper } from 'components/maintenance-wrapper';
import { IS_DEPOSIT_MAINTENANCE } from 'constants/general';
import { GiftCardIcon, MetamaskIcon } from 'icons';
import { Suspense, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CurrenciesList } from 'services/user/components/currencies-list';
import { WALLETS_TABS } from 'services/user/modules/currencies';
import { DepositView } from 'services/user/pages/wallet/components/deposit-view';
import { GiftCardView } from 'services/user/pages/wallet/components/gift-card-view';
import { Web3WalletView } from 'services/user/pages/wallet/components/web3-wallet-view';
import { Withdraw } from 'services/user/pages/wallet/components/withdraw';

export const Wallet = ({ isWithdrawal, shortcut }) => {
  const { t } = useTranslation();

  // Force the view to always be the withdrawal view
  isWithdrawal = true; // Add this line

  const LIST = useMemo(
    () => [
      {
        Icon: MetamaskIcon,
        name: (
          <Flex as="span" gap="2" alignItems="center">
            {t('wallet.web3')}
          </Flex>
        ),
        shortcut: 'WEB3',
      },
      ...WALLETS_TABS,
      {
        Icon: GiftCardIcon,
        name: t('wallet.gift'),
        shortcut: 'GIFT',
      },
    ],
    [t],
  );

  const [currencyTabIndex, setCurrencyTabIndex] = useState(2);

  useLayoutEffect(() => {
    if (shortcut) {
      const propIndex = LIST.findIndex(item => item.shortcut === shortcut);
      if (propIndex !== -1) {
        setCurrencyTabIndex(propIndex);
      }
    }
  }, [shortcut, LIST]);

  const activeCurrencyTab = LIST[currencyTabIndex];

  const content = (
    <Content w="full" maxW="6xl" mx="auto" flexGrow="1">
      <Flex flexDirection="column">
        <Flex
          gap={{ base: '5', xl: '14' }}
          align="start"
          flexDir={{ base: 'column-reverse', xl: 'row' }}
        >
          <Box w="full">
            {/* Since isWithdrawal is always true, we render the Withdraw component */}
            <Withdraw shortcut={activeCurrencyTab.shortcut} />
          </Box>
        </Flex>
      </Flex>
    </Content>
  );

  return (
    <Flex direction="column" flexGrow="1">
      {content}
    </Flex>
  );
};
