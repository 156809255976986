import { createReducer } from 'store';
import {
  loadAffiliates,
  loadAffiliatesStats,
  redeemAffiliate,
} from './actions';
import { AffiliatesType, AfiiliateType, StatsType } from './types';

const initialState: AffiliatesType = {
  stats: null,
  list: [],
  isStatsLoaded: false,
};

export const reducer = createReducer(initialState, {
  [loadAffiliatesStats.toString()]: (
    state: AffiliatesType,
    { payload }: { payload: StatsType },
  ) => {
    state.stats = payload;
    state.isStatsLoaded = true;
  },
  [loadAffiliates.toString()]: (
    state: AffiliatesType,
    {
      payload,
      meta: { after },
    }: { payload: AfiiliateType; meta: { after: string } },
  ) => {
    if (after) {
      state.list = state.list.concat(payload);
    } else {
      state.list = payload;
    }
  },
  [redeemAffiliate.toString()]: (
    state: AffiliatesType,
    { meta: { amount } }: { meta: { amount: number } },
  ) => {
    state.stats.affiliateAmountAvailable = 0;
    state.stats.affiliateAmountClaimed += amount;
  },
});
