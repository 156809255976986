import { createIcon } from '@chakra-ui/icons';

export const BasketballIcon = createIcon({
  displayName: 'BasketballIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="M11.198 4.965A8.746 8.746 0 0 0 16 7.572a7.957 7.957 0 0 0-1.755-4.607 20.58 20.58 0 0 0-3.195 1.823l.148.177ZM1.395 6.292a5.51 5.51 0 0 0-1.24.143 8.003 8.003 0 0 0 1.474 6.402 27.073 27.073 0 0 1 3.921-5.19 7.66 7.66 0 0 0-4.155-1.355Zm11.793-4.396A7.957 7.957 0 0 0 8.683.004a8.277 8.277 0 0 0 1.458 3.664 22.864 22.864 0 0 1 3.046-1.772Zm-6.603 4.71a30.418 30.418 0 0 1 2.407-2.075A9.644 9.644 0 0 1 7.277.026a8.003 8.003 0 0 0-6.66 4.888 8.923 8.923 0 0 1 5.968 1.692Zm3.59-.64c-.103-.103-.194-.217-.292-.326a33.077 33.077 0 0 0-2.178 1.87c.138.125.275.245.406.382a9.358 9.358 0 0 1 2.801 7.546 8.003 8.003 0 0 0 5.025-6.443h-.148a10.175 10.175 0 0 1-5.614-3.035v.006ZM6.688 8.52a25.272 25.272 0 0 0-4.042 5.408 8.003 8.003 0 0 0 6.74 1.949c.572-2.16-.263-4.893-2.286-6.98-.143-.143-.28-.257-.412-.383v.006Z"
    />
  ),
});
