import { Flex, Text } from '@chakra-ui/react';
import { PlayerRow } from 'components/player-row';
import dayjs from 'dayjs';
import { formatCredit } from 'helpers/numeral';
import { SportsIcon } from 'icons';
import uniqBy from 'lodash.uniqby';
import { getHandlers } from 'modules/handler';
import { getFeed, getFeedLoaded, loadLiveWagers } from 'modules/wagers';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ORIGINALS_GAMES_TYPES,
  getSlotsGamesData,
} from 'services/games/modules/slots';
import { useActions } from 'store';
import { WagerItemType } from 'types';
import { Link } from 'uikit/link';
import { ProviderImage } from 'uikit/provider-image';
import { Table } from 'uikit/table';

type Props = {
  type: string;
  userId: string;
  isInfiniteLoop: boolean;
};

export const List: React.FC<Props> = ({ type, userId, isInfiniteLoop }) => {
  const [started, setStarted] = useState(false);
  const [isMounted, setIsMounted] = useState(false);
  const queue = useRef<WagerItemType[]>([]);
  const interval = useRef<ReturnType<typeof setTimeout>>();
  const handlers = useSelector(getHandlers);
  const slots = useSelector(getSlotsGamesData);
  const wagerFeed = useSelector(getFeed(type));
  const isFeedLoaded = useSelector(getFeedLoaded(type));
  const actions = useActions({ loadLiveWagers });
  const { t } = useTranslation();
  const [list, setList] = useState(wagerFeed);
  const [isLoaded, setIsLoaded] = useState(isFeedLoaded);

  useEffect(() => {
    if (isMounted && wagerFeed.length) {
      const listIds = list.map(({ implementationId }) => implementationId);
      queue.current = uniqBy(
        wagerFeed
          .filter(
            ({ implementationId, isNew }) =>
              isNew && !listIds.includes(implementationId),
          )
          .concat(queue.current),
        ({ implementationId }) => implementationId,
      ).slice(0, 100);
    }
  }, [isMounted, list, wagerFeed]);

  useEffect(() => {
    if (isLoaded) {
      setIsMounted(true);
    }
  }, [isLoaded]);

  useEffect(() => {
    if (isFeedLoaded && !isLoaded) {
      setList(wagerFeed.slice(0, 10));
      setIsLoaded(true);
    }
  }, [isFeedLoaded, wagerFeed, isLoaded]);

  useEffect(() => {
    const setupInterval = () =>
      setInterval(
        () => {
          if (queue.current.length) {
            const item = queue.current.pop() as WagerItemType;
            setStarted(true);
            setList(prev =>
              uniqBy(
                [item].concat(prev),
                ({ implementationId }) => implementationId,
              ).slice(0, 10),
            );
          } else if (isInfiniteLoop && list.length) {
            setStarted(true);
            const item = list.at(-1) as WagerItemType;
            setList(prev =>
              uniqBy(
                [item].concat(prev),
                ({ implementationId }) => implementationId,
              ).slice(0, 10),
            );
          }
        },
        isInfiniteLoop ? 1000 : 500,
      );

    interval.current = setupInterval();

    return () => clearInterval(interval.current);
  }, [isInfiniteLoop, list]);

  useEffect(() => {
    if (!isFeedLoaded) {
      actions.loadLiveWagers(type, userId);
    }
  }, [actions, isFeedLoaded, type, userId]);

  const fields = [
    {
      key: 'game',
      title: t('wagers.game'),
      minWidth: '180px',
      textAlign: 'left' as const,
    },
    {
      key: 'player',
      title: t('wagers.player'),
      minWidth: '120px',
      textAlign: 'left' as const,
      display: { base: 'none', lg: 'table-cell' },
    },
    {
      key: 'wager',
      title: t('wagers.wager'),
      textAlign: 'center' as const,
      display: { base: 'none', lg: 'table-cell' },
    },
    {
      key: 'multiplier',
      title: t('wagers.multiplier'),
      textAlign: 'center' as const,
      display: { base: 'none', lg: 'table-cell' },
    },
    {
      key: 'payout',
      title: t('wagers.payout'),
      textAlign: 'right' as const,
    },
  ];

  const data = useMemo(
    () =>
      list.map(
        ({
          user,
          handlerId,
          timestamp,
          wagerAmount,
          winAmount,
          subGameType,
          implementationId,
        }) => {
          const isWin = wagerAmount < winAmount;
          const multiplier = winAmount / wagerAmount;
          const game = handlers[handlerId];

          let subTitle: React.ReactNode = handlers[handlerId]?.name;
          let Icon;
          let provider = 'sherbet';
          if (game?.type === 'slots' && subGameType) {
            const slotGame = slots[subGameType];
            if (slotGame) {
              if (slotGame.provider) {
                provider = slotGame.provider;
              }

              subTitle = (
                <Link
                  to={`/casino/game/${slotGame.id}`}
                  _hover={{ color: '#F7F8F8' }}
                >
                  {slotGame.title}
                </Link>
              );
            }
          } else if (game?.type === 'sports') {
            Icon = SportsIcon;
            subTitle = (
              <Link to="/sports" _hover={{ color: '#F7F8F8' }}>
                {subTitle}
              </Link>
            );
          } else if (ORIGINALS_GAMES_TYPES.includes(game?.type)) {
            subTitle = (
              <Link
                to={`/casino/game/${game.type}`}
                _hover={{ color: '#F7F8F8' }}
              >
                {subTitle}
              </Link>
            );
          }

          return {
            bg: type === 'all' && multiplier >= 100 ? undefined : undefined,
            game: (
              <Flex align="center" gap="1.5">
                {provider && (
                  <ProviderImage provider={provider} h="14px" w="14px" />
                )}
                {Icon && <Icon />}
                {subTitle}
              </Flex>
            ),
            player: <PlayerRow user={user} hasAvatar={!!user?.icon} />,
            time: dayjs(Number(timestamp) * 1000).format('HH:mm'),
            wager: `$${formatCredit(wagerAmount)}`,
            multiplier: (
              <Text color={isWin ? 'sherbet-green.200' : 'candy-floss-text'}>
                {formatCredit(multiplier)}x
              </Text>
            ),
            payout: (
              <Text color={isWin ? 'sherbet-green.200' : 'candy-floss-text'}>
                ${formatCredit(winAmount)}
              </Text>
            ),
            key: implementationId,
          };
        },
      ),
    [list, handlers, type, slots],
  );

  return (
    <Table
      fields={fields}
      data={data}
      isLoading={!isLoaded}
      loadingSkeletonRows={10}
      animate="slide"
      animateStarted={started}
    />
  );
};
