import { createIcon } from '@chakra-ui/icons';

export const InfoIcon = createIcon({
  displayName: 'InfoIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M2.366 2.34a7.986 7.986 0 1 0 11.295 11.293A7.987 7.987 0 0 0 2.366 2.34Zm5.968 1.01c.673 0 1.214.556 1.214 1.23a1.234 1.234 0 0 1-1.214 1.23 1.24 1.24 0 0 1-1.231-1.247c0-.674.556-1.214 1.23-1.214v.001Zm1.736 8.243a.8.8 0 0 1-.17.287c-.437.455-.976.724-1.618.724-.303 0-.59 0-.893-.05-.488-.068-1.112-.675-1.028-1.316l.202-1.315c.135-.758.27-1.533.404-2.292 0-.05.018-.1.018-.151 0-.32-.102-.438-.421-.473-.136-.017-.27-.033-.405-.066-.151-.05-.236-.186-.22-.304.017-.135.102-.22.27-.253.085-.017.186-.017.287-.017h2.326c.287 0 .456.135.456.422 0 .236-.035.471-.085.708-.152.876-.32 1.736-.472 2.612-.05.287-.118.573-.151.86-.018.135 0 .286.033.421.05.186.186.287.371.27.152-.017.304-.067.455-.134.119-.051.219-.136.337-.17.202-.066.354.051.303.237Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </>
  ),
});
