import { Box, Flex, Text } from '@chakra-ui/react';
import { PlayerRow } from 'components/player-row';
import { formatCredit } from 'helpers/numeral';
import { getChatBlocked } from 'modules/chat';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { pickedGroupToColor } from 'services/games/pages/roulette/utils';
import { UserType } from 'types';

type Props = {
  title: React.ReactNode;
  total: number;
  bonusNumber: number;
  hasCount?: boolean;
  users: { [key: string]: UserType };
  entries: {
    userId: string;
    wagerGroup: number;
    amount: number;
  }[];
};

export const PlayerList: React.FC<Props> = ({
  entries,
  title,
  total,
  users,
  bonusNumber,
  hasCount,
}) => {
  const blocked = useSelector(getChatBlocked);

  const usersList = useMemo(
    () => entries.filter(i => !blocked.includes(users[i.userId]?.name)),
    [entries, blocked, users],
  );

  return (
    <Box flex="1">
      <Flex
        justify="space-between"
        align="center"
        rounded="xl"
        bg="sugar-dust"
        h="10"
        px="4"
      >
        <Text fontWeight="500" color="candy-floss-text">
          {title}
        </Text>
        <Text fontWeight="500" color="candy-floss-text">
          ${formatCredit(total)}
        </Text>
      </Flex>
      {usersList.length > 0 && (
        <Flex direction="column" gap="1" mt="1" rounded="xl">
          {usersList.map(x => {
            const entryUser = users[x.userId];
            return (
              <Flex
                flexShrink="0"
                key={`${x.wagerGroup}_${x.userId}`}
                justify="space-between"
                align="center"
                rounded="xl"
                bg="sugar-dust"
                h="10"
                px="4"
              >
                <Flex gap="2" align="center">
                  <PlayerRow user={entryUser} />
                </Flex>
                <Text
                  fontWeight="500"
                  color={
                    hasCount
                      ? pickedGroupToColor(x.wagerGroup, bonusNumber)
                      : 'candy-floss-text'
                  }
                >
                  ${formatCredit(x.amount)}
                </Text>
              </Flex>
            );
          })}
        </Flex>
      )}
    </Box>
  );
};
