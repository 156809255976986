import { createIcon } from '@chakra-ui/icons';

export const DOGEIcon = createIcon({
  displayName: 'DOGEIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <g clipPath="url(#a)">
        <path fill="#C3A634" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z" />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M6.624 7.305h2.157v1.143H6.624v2.409h1.36c.539 0 .98-.072 1.322-.218.344-.146.613-.348.808-.605a2.2 2.2 0 0 0 .398-.908c.072-.37.107-.748.105-1.126a5.704 5.704 0 0 0-.105-1.126 2.199 2.199 0 0 0-.398-.907 1.935 1.935 0 0 0-.808-.605c-.342-.147-.783-.219-1.321-.219H6.623v2.163-.001ZM5.241 8.448H4.5V7.305h.741V4h3.274c.605 0 1.129.105 1.571.314a2.93 2.93 0 0 1 1.085.857c.28.362.488.786.625 1.271C11.931 6.928 12 7.447 12 8a5.734 5.734 0 0 1-.205 1.557 3.67 3.67 0 0 1-.625 1.272 2.92 2.92 0 0 1-1.084.857c-.442.21-.966.314-1.57.314H5.24V8.448Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </>
  ),
});
