import { Circle, Flex, FlexProps, Heading, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type Props = FlexProps & {
  title?: string;
  description?: string;
  icon?: React.ElementType;
  children?: React.ReactNode;
};

export const EmptyResults: React.FC<Props> = ({
  title = 'search.no-results-title',
  description = 'search.no-results-title',
  icon: Icon,
  children,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <Flex
      flexDirection="column"
      justifyContent="center"
      align="center"
      {...props}
    >
      {Icon && (
        <Circle bg="rgba(223, 59, 59, 0.15)" mb="3" size="10" rounded="full">
          <Icon color="sherbet-red" />
        </Circle>
      )}
      <Heading
        fontWeight="500"
        fontSize={{ base: 'base', md: 'lg' }}
        textAlign="center"
        mb="1"
      >
        {t(title)}
      </Heading>
      <Text textAlign="center" color="candy-floss-text">
        {t(description)}
      </Text>
      {children}
    </Flex>
  );
};
