import { PreviewSlider } from 'components/preview-slider';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getPromoIsLoaded,
  getPromoPosts,
  loadPromos,
} from 'services/cms/modules/promo';
import { useActions } from 'store';
import { BlogPreview } from '../blog-preview';

export const PromoSlider = () => {
  const postsList = useSelector(getPromoPosts);
  const isLoaded = useSelector(getPromoIsLoaded);
  const actions = useActions({ loadPromos });
  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (!isLoaded) {
      actions.loadPromos(i18n.language);
    }
  }, [isLoaded, i18n.language, actions]);

  const sortedPosts = [...postsList].sort(
    (a, b) => (a.weight || 999) - (b.weight || 999),
  );

  return (
    <PreviewSlider
      autoplay
      isLoaded={isLoaded}
      hasButtons={false}
      isIgnoreGap
      slides={sortedPosts
        .slice(0, 6)
        .map(item => ({ ...item, prefix: '/promotions' }))}
      SlideComponent={props => <BlogPreview {...props} isPromoSlider={true} />}
      gridSize="1-3"
    />
  );
};
