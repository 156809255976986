import { Box, Button, Flex } from '@chakra-ui/react';
import { Loading } from 'components/loading';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { ProfileOverview } from 'services/user/pages/profile-overview';
import { ProfileVerify } from 'services/user/pages/profile-verify';
import { PreferencesSection } from './components/preferences-section';
import { SecuritySection } from './components/security-section';
import { SessionsSection } from './components/sessions-section';

export const Settings = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const tabs = [
    {
      children: t('profile.title'),
      element: (
        <Suspense fallback={<Loading pos="relative" my="14" />}>
          <ProfileOverview />
        </Suspense>
      ),
      name: '',
    },
    {
      children: t('profile.settings.security'),
      element: <SecuritySection />,
      name: 'security',
    },
    {
      children: t('profile.settings.preferences'),
      element: <PreferencesSection />,
      name: 'preferences',
    },
    {
      children: t('profile.settings.sessions'),
      element: <SessionsSection />,
      name: 'sessions',
    },
    {
      children: t('profile.verify.title'),
      element: <ProfileVerify />,
      name: 'verify',
    },
  ];

  const { subtab = '' } = Object.fromEntries(searchParams);
  const tabIndex = tabs.findIndex(item => item.name === subtab) || 0;
  const { element } = tabs[tabIndex];

  return (
    <>
      <Flex
        gap="2"
        align="center"
        overflow="auto"
        zIndex="2"
        maxW="6xl"
        mx="auto"
        width="100%"
        px="5"
        pt="5"
        pb="2"
      >
        {tabs.map(({ children }, index) => {
          const isActive = tabIndex === index;
          return (
            <Button
              colorScheme="custom"
              border="1px solid"
              color={isActive ? 'sherbet-purple' : 'candy-floss-text'}
              borderColor={isActive ? 'sherbet-purple' : 'truffle-border'}
              rounded="full"
              _hover={isActive ? {} : { borderColor: 'truffle-border-hover' }}
              bg="toffee-base"
              flexShrink="0"
              gap="2"
              draggable="false"
              onClick={() => {
                const newTab = tabs[index].name;
                const { subtab: _, ...params } =
                  Object.fromEntries(searchParams);

                if (newTab) {
                  params.subtab = newTab;
                }

                setSearchParams(params);
              }}
              key={index}
            >
              {children}
            </Button>
          );
        })}
      </Flex>
      <Box maxW="6xl" mx="auto" width="100%" px="5" py="5">
        {element}
      </Box>
    </>
  );
};
