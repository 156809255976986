import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useScript from 'react-script-hook';
import { getUserName } from 'services/user/modules/user';

export const useSignal = () => {
  const [isReady, setIsReady] = useState(false);
  const username = useSelector(getUserName);
  const [isScriptLoading] = useScript({
    src: 'https://cdn.onesignal.com/sdks/web/v16/OneSignalSDK.page.js',
    checkForExisting: true,
  });

  useEffect(() => {
    if (!isScriptLoading) {
      window.OneSignalDeferred = window.OneSignalDeferred || [];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.OneSignalDeferred.push((OneSignal: any) => {
        OneSignal.init({
          appId: '2b1795de-6a45-40ab-8c46-9954362f32d4',
          safari_web_id:
            'web.onesignal.auto.0919e572-6b9a-4fc1-8566-50f787b3e729',
          notifyButton: {
            enable: false,
          },
        });
        setIsReady(true);
      });
    }
  }, [isScriptLoading]);

  useEffect(() => {
    if (isReady && username) {
      window.OneSignal?.login(username);
    }
  }, [username, isReady]);

  useEffect(() => {
    if (isReady) {
      setTimeout(() => {
        window.OneSignal?.Notifications.requestPermission();
      }, 2000);
    }
  }, [isReady]);
};
