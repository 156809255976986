import { createIcon } from '@chakra-ui/icons';

export const VerifiedIcon = createIcon({
  displayName: 'VerifiedIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <g clipPath="url(#a)">
        <path
          fill="currentColor"
          fillRule="evenodd"
          d="M14.777 7.997c0 .337.086.667.25.961l.47.72a.914.914 0 0 1-.449 1.383l-.78.31a1.97 1.97 0 0 0-1.13 1.553l-.054.838a.911.911 0 0 1-1.174.854l-.817-.208a1.969 1.969 0 0 0-1.857.587l-.534.646a.913.913 0 0 1-1.452 0l-.534-.646a1.97 1.97 0 0 0-1.83-.587l-.817.197a.912.912 0 0 1-1.174-.854l-.054-.838a1.97 1.97 0 0 0-1.11-1.553l-.78-.31a.914.914 0 0 1-.447-1.382l.448-.71a1.97 1.97 0 0 0 0-1.921l-.448-.71a.913.913 0 0 1 .448-1.383l.763-.315a1.97 1.97 0 0 0 1.132-1.553l.053-.838a.913.913 0 0 1 1.174-.854l.812.214a1.97 1.97 0 0 0 1.862-.593L7.282.36a.913.913 0 0 1 1.452 0l.534.646a1.97 1.97 0 0 0 1.825.593l.795-.208a.913.913 0 0 1 1.175.854l.053.838a1.97 1.97 0 0 0 1.132 1.553l.779.31a.912.912 0 0 1 .448 1.382l-.448.71a1.97 1.97 0 0 0-.25.96Zm-6.418 2.978 3.219-5.161A.98.98 0 0 0 10 4.65L6.737 7.986 5.432 6.982A.974.974 0 0 0 4.054 8.36l2.099 2.776a1.315 1.315 0 0 0 2.162-.093l.044-.068Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="currentColor" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </>
  ),
});
