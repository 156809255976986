import {
  Box,
  Button,
  Flex,
  Heading,
  IconProps,
  Image,
  Text,
} from '@chakra-ui/react';
import { cloneElement } from 'react';
import { ImageFallback } from 'uikit/image-fallback';
import { UserAvatar } from 'uikit/user-avatar';

type Props = {
  image?: string;
  title?: React.ReactNode;
  iconNode?: React.ReactElement<unknown>;
  icon?: string;
  level?: number;
  name?: string;
  description?: React.ReactNode;
  onClose: () => void;
  Content?: React.FunctionComponent<{ onClose: () => void }>;
};

export const Toast: React.FC<Props> = ({
  image,
  title,
  iconNode,
  icon,
  name,
  description,
  onClose,
  Content,
}) => {
  // const { t } = useTranslation();

  let img;
  if (name && icon) {
    img = <UserAvatar icon={icon} name={name} size="3xs" />;
  } else if (image) {
    img = (
      <Image
        fallback={<ImageFallback rounded="full" h="14px" w="14px" />}
        src={image}
        maxW="14px"
        maxH="14px"
        w="full"
        draggable="false"
        alt="Notification Image"
        flexShrink="0"
      />
    );
  } else if (iconNode) {
    img = cloneElement(iconNode, { fontSize: '14px' } as IconProps);
  }

  return (
    <Box
      bg="burnt-sugar"
      p="4"
      rounded="xl"
      boxShadow="0 4px 12px rgba(0,0,0,.1)"
      border="1px solid"
      borderColor="truffle-border"
      overflow="hidden"
      position="relative"
      w={{ base: 'calc(100vw - 40px)', lg: 'md' }}
      sx={{
        WebkitTransform: 'translate3d(0, 0, 0)', // Requires for Safari
      }}
    >
      <Flex align="start" gap="3" rounded="md" justifyContent="space-between">
        <Flex align="start" gap="3" rounded="md" justifyContent="space-between">
          {img}
          <Flex direction="column" alignItems="start" gap="1.5">
            {title && (
              <Heading
                fontWeight="500"
                noOfLines={1}
                lineHeight="normal"
                fontSize="md"
              >
                {title}
              </Heading>
            )}
            {description && (
              <Text noOfLines={2} lineHeight="normal" color="candy-floss-text">
                {description}
              </Text>
            )}
          </Flex>
        </Flex>
        <Button
          color="candy-floss-text"
          onClick={onClose}
          cursor="pointer"
          lineHeight="normal"
          variant="link"
          fontWeight="500"
          minW="0"
        >
          <svg width="14" height="14" viewBox="0 0 16 16" fill="currentColor">
            <path d="M2.96967 2.96967C3.26256 2.67678 3.73744 2.67678 4.03033 2.96967L8 6.939L11.9697 2.96967C12.2626 2.67678 12.7374 2.67678 13.0303 2.96967C13.3232 3.26256 13.3232 3.73744 13.0303 4.03033L9.061 8L13.0303 11.9697C13.2966 12.2359 13.3208 12.6526 13.1029 12.9462L13.0303 13.0303C12.7374 13.3232 12.2626 13.3232 11.9697 13.0303L8 9.061L4.03033 13.0303C3.73744 13.3232 3.26256 13.3232 2.96967 13.0303C2.67678 12.7374 2.67678 12.2626 2.96967 11.9697L6.939 8L2.96967 4.03033C2.7034 3.76406 2.6792 3.3474 2.89705 3.05379L2.96967 2.96967Z" />
          </svg>
        </Button>
      </Flex>
      {Content && <Content onClose={onClose} />}
    </Box>
  );
};
