import { Box, Flex, Switch, Text, VStack } from '@chakra-ui/react';
import { SETTINGS_ENUM } from 'constants/general';
import { isFlagChecked } from 'helpers/bit';
import { ChangeEvent, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  getUserSettingsFlags,
  getUserSettingsLoading,
  saveSettings,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { toastSuccess } from 'toasts';

const SETTINGS_CHECKBOXES_ACCESSIBILITY = [
  {
    title: 'settings.privacy.bet-confirm',
    description: 'settings.privacy.bet-confirm-description',
    settingId: SETTINGS_ENUM.BET_CONFIRM,
  },
];

export const PreferencesSection = () => {
  const { t } = useTranslation();
  const settingsFlags = useSelector(getUserSettingsFlags);
  const settingsLoading = useSelector(getUserSettingsLoading);
  const actions = useActions({ saveSettings });

  const handleSaveSettings =
    (settingId: number) => async (e: ChangeEvent<HTMLInputElement>) => {
      await actions.saveSettings({ [settingId]: e.target.checked });
      toastSuccess({
        title: t('settings.settings-updated'),
        description: t('settings.settings-updated-description'),
      });
    };

  const SETTINGS_CHECKBOXES_PRIVACY = [
    {
      title: 'settings.enable-ghost-mode',
      description: 'settings.privacy.ghost-mode-description',
      settingId: SETTINGS_ENUM.GHOST_MODE,
    },
    {
      title: 'settings.hide-stats',
      description: 'settings.privacy.hide-stats-description',
      settingId: SETTINGS_ENUM.STAT_PRIVACY,
    },
    {
      title: 'settings.friend-toast',
      description: 'settings.friend-toast-description',
      settingId: SETTINGS_ENUM.ONLINE_FRIENDS,
    },
  ];

  return (
    <Box flexDirection="column" gap="5">
      <Box bg="sugar-dust" p="5" rounded="xl">
        <Text fontWeight="500" mb="5">
          {t('settings.general')}
        </Text>
        <VStack spacing="5">
          {SETTINGS_CHECKBOXES_PRIVACY.map(
            ({ title, description, settingId }, index) => (
              <Fragment key={index}>
                <Flex
                  gap="5"
                  justify="space-between"
                  align="start"
                  key={settingId}
                  w="full"
                >
                  <Flex direction="column">
                    <Text fontWeight="500">{t(title)}</Text>
                    <Text color="candy-floss-text">{t(description)}</Text>
                  </Flex>
                  <Switch
                    colorScheme="purple"
                    isChecked={isFlagChecked(settingsFlags, settingId)}
                    disabled={settingsLoading?.[settingId]}
                    onChange={handleSaveSettings(settingId)}
                  />
                </Flex>
              </Fragment>
            ),
          )}
        </VStack>
      </Box>

      <Box
        bg="sugar-dust"
        p="5"
        rounded="xl"
        gap="5"
        display="flex"
        flexDirection="column"
        mt="5"
      >
        <Text fontWeight="500">{t('settings.accessibility')}</Text>
        {SETTINGS_CHECKBOXES_ACCESSIBILITY.map(
          ({ title, description, settingId }, index) => (
            <Flex
              gap="5"
              justify="space-between"
              align="start"
              key={index}
              w="full"
            >
              <Flex direction="column">
                <Text fontWeight="500">{t(title)}</Text>
                <Text color="candy-floss-text">{t(description)}</Text>
              </Flex>
              <Switch
                colorScheme="purple"
                size="md"
                isChecked={isFlagChecked(settingsFlags, settingId)}
                disabled={settingsLoading?.[settingId]}
                onChange={handleSaveSettings(settingId)}
              />
            </Flex>
          ),
        )}
      </Box>
    </Box>
  );
};
