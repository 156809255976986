import { createIcon } from '@chakra-ui/icons';

export const MMAIcon = createIcon({
  displayName: 'MMAIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="M1.925 9.145a.334.334 0 0 0-.472 0l-1.26 1.26a.68.68 0 0 0 0 .95l3.778 3.779a.673.673 0 0 0 .951 0l1.26-1.26a.346.346 0 0 0 .075-.37.32.32 0 0 0-.076-.108L1.925 9.145Zm12.601-.619L12.7 8.79a.155.155 0 0 1-.098-.02.16.16 0 0 1-.067-.075.18.18 0 0 1 0-.196l2.336-2.417a1.086 1.086 0 0 0 .311-.768 1.108 1.108 0 0 0-.311-.768 1.04 1.04 0 0 0-.792-.311.162.162 0 0 1-.089-.025.167.167 0 0 1-.062-.07.18.18 0 0 1 0-.183 1.104 1.104 0 0 0-.112-1.37 1.034 1.034 0 0 0-1.332-.152.168.168 0 0 1-.192 0 .18.18 0 0 1-.08-.183 1.04 1.04 0 0 0-.302-.764 1.042 1.042 0 0 0-.745-.317c-.28 0-.548.114-.746.317l-.442.453a.169.169 0 0 1-.263-.076.18.18 0 0 1-.007-.107A.915.915 0 0 0 9.43.98a1.041 1.041 0 0 0-.738-.31c-.277 0-.542.112-.74.31L4.51 4.52C3.322 5.738 3.005 6.638 3.19 7.633a.682.682 0 0 0 .178.338l4.095 4.202c.996 1.021 2.928.338 4.478 0 .925-.205 1.839-.46 2.737-.765.745-.25 1.372-.737 1.319-1.536a1.469 1.469 0 0 0-.47-.978 1.396 1.396 0 0 0-1-.368Z"
    />
  ),
});
