import { Box, Button, Flex, Tooltip } from '@chakra-ui/react';
import { getPathnameWithoutLang } from 'helpers/lang';
import { SportsIcon } from 'icons';
import { useLocation } from 'react-router-dom';
import { Countdown } from 'uikit/countdown';
import { Link } from 'uikit/link';
import { NotificationTip } from 'uikit/notification-tip';

export const NavLink = ({
  to,
  children,
  isOpened,
  icon = <SportsIcon />,
  tip,
  onClick,
  isLoading,
  rightIcon,
  isActive,
  countdownDate,
}) => {
  const { pathname: p, search } = useLocation();
  const pathname = getPathnameWithoutLang(p);

  const renderCountdown = ({ days, hours, minutes, seconds }) => {
    if (days > 0) return `${days}d`;
    if (hours > 0) return `${hours}h`;
    if (minutes > 0) return `${minutes}m`;
    return `${seconds}s`;
  };

  let content = (
    <Flex gap="2" alignItems="center" justifyContent="space-between" w="full">
      <Flex gap="2" alignItems="center">
        <Flex color="candy-floss-text">{icon}</Flex>
        {children}
      </Flex>
      {isOpened && countdownDate && (
        <Box ml="auto" fontSize="md" color="sherbet-green.200" fontWeight="500">
          <Countdown date={countdownDate} renderer={renderCountdown} />
        </Box>
      )}
    </Flex>
  );

  if (!isOpened) {
    content = (
      <Flex gap="2" alignItems="center">
        <Flex color="candy-floss-text">{icon}</Flex>
      </Flex>
    );
  }

  if (!to && onClick) {
    return (
      <Button
        variant="link"
        w="full"
        onClick={onClick}
        rounded="none"
        p="3"
        px="5"
        h="40px"
        display="flex"
        alignItems="center"
        justifyContent={!isOpened ? 'center' : 'space-between'}
        _hover={{ bg: 'sugar-dust' }}
        fontWeight="500"
        textAlign={isOpened ? undefined : 'center'}
        isLoading={isLoading}
        {...(isActive
          ? {
              bg: 'sugar-dust',
              color: 'vanilla-text',
              borderLeft: '1px solid',
              borderColor: 'sherbet-purple',
              paddingLeft: '19px',
            }
          : {})}
      >
        {content}
        {rightIcon}
      </Button>
    );
  }

  return isOpened ? (
    <Link
      to={to}
      className={
        pathname + search === to || (to.startsWith('?') && to === search)
          ? 'active'
          : undefined
      }
      p="3"
      px="5"
      h="40px"
      display="flex"
      alignItems="center"
      justifyContent={!isOpened ? 'center' : 'space-between'}
      _hover={{ bg: 'sugar-dust' }}
      color="candy-floss-text"
      sx={{
        '&.active': {
          bg: 'sugar-dust',
          color: 'vanilla-text',
          borderLeft: '1px solid',
          borderColor: 'sherbet-purple',
          paddingLeft: '19px',
        },
      }}
      fontWeight="500"
      textAlign={isOpened ? undefined : 'center'}
    >
      {content}
      {!!tip && isOpened && (
        <NotificationTip
          notifications={tip}
          ml="2"
          bg="sherbet-purple"
          color="#171824e6"
          rounded="full"
          fontWeight="500"
        />
      )}
    </Link>
  ) : (
    <Tooltip
      gutter={10}
      hasArrow
      placement="right"
      fontSize="md"
      rounded="md"
      bg="vanilla-text"
      fontWeight="500"
      py="1"
      px="2"
      color="toffee-base"
      label={children}
    >
      <Link
        to={to}
        className={
          pathname + search === to || (to.startsWith('?') && to === search)
            ? 'active'
            : undefined
        }
        p="3"
        px="5"
        h="40px"
        display="flex"
        alignItems="center"
        justifyContent={!isOpened ? 'center' : 'space-between'}
        _hover={{ bg: 'sugar-dust' }}
        color="candy-floss-text"
        sx={{
          '&.active': {
            bg: 'sugar-dust',
            color: 'vanilla-text',
            borderLeft: '1px solid',
            borderColor: 'sherbet-purple',
            paddingLeft: '19px',
          },
        }}
        fontWeight="500"
        textAlign={isOpened ? undefined : 'center'}
      >
        {content}
        {!!tip && isOpened && (
          <NotificationTip
            notifications={tip}
            ml="2"
            bg="sherbet-purple"
            color="#171824e6"
            rounded="full"
            fontWeight="500"
          />
        )}
      </Link>
    </Tooltip>
  );
};
