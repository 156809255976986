import { client, parseApiError } from 'helpers/client';

export const loadCurrentGame = (handlerId: number) =>
  client.get(`/handler/jackpot/${handlerId}`).then(({ data }) => data.payload);

export const placeWager = ({
  handlerId,
  gameId,
  total,
  amount,
}: {
  handlerId: string;
  gameId: string;
  total: number;
  amount: number;
}) =>
  client
    .post(`/handler/jackpot/${handlerId}/${gameId}`, {
      inputTotal: total,
      inputAmount: amount,
      inputItems: [],
    })
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const loadJackpotHistory = ({
  handlerId,
  before,
  id,
  limit,
}: {
  handlerId: number;
  before: string;
  id: string;
  limit: number;
}) =>
  client
    .get(
      `/handler/jackpot/${handlerId}/history?limit=${limit}&before=${before}&id=${id}`,
    )
    .then(({ data }) => data.payload);
