import { createAction } from 'store';
import * as api from './api';

export const loadAffiliatesStats = createAction(
  'affiliates/loadAffiliatesStats',
  params => () => api.loadAffiliatesStats(params),
);

export const loadAffiliates = createAction(
  'affiliates/loadAffiliates',
  params => () => api.loadAffiliates(params),
  params => params,
);

export const redeemAffiliate = createAction(
  'affiliates/redeemAffiliate',
  () => () => api.redeemAffiliate(),
  amount => ({ amount }),
);
