import { createIcon } from '@chakra-ui/icons';

export const LiveCasinoIcon = createIcon({
  displayName: 'LiveCasinoIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="M16 4.956a1.963 1.963 0 1 0-3.404 1.323l-1.9 3.253-1.52-4.666a2.372 2.372 0 1 0-2.804 0l-1.52 4.666-1.71-2.961a1.957 1.957 0 1 0-1.901.264l1.07 6.953A1.587 1.587 0 0 0 3.563 15.1c2.8.425 5.646.425 8.445 0a1.588 1.588 0 0 0 1.25-1.312l1.06-6.914A1.957 1.957 0 0 0 16 4.956Z"
    />
  ),
});
