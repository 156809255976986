import { Text, TextProps } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type Props = TextProps & {
  error?: string | null;
};

export const FormGlobalError: React.FC<Props> = ({ error, ...props }) => {
  const { t } = useTranslation();
  if (!error) {
    return null;
  }
  return (
    <Text color="sherbet-red" fontSize="md" {...props}>
      {t(`errors.BE.${error}`, t(error))}
    </Text>
  );
};
