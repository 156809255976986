import { TriangleDownIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  IconButton,
  Image,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuGroup,
  MenuItem,
  MenuList,
  MenuListProps,
  PlacementWithLogical,
} from '@chakra-ui/react';
import xor from 'lodash.xor';
import { useTranslation } from 'react-i18next';
import { UserAvatar } from 'uikit/user-avatar';

type OptionType = {
  value: string | number;
  title: string;
  icon?: React.ElementType;
  image?: string;
  avatar?: string;
  name?: string;
  onClick?: (value: string | number) => void;
  hasCheckbox?: boolean;
};

type GroupType = {
  title: string;
  options: OptionType[];
};

type Props = {
  isMultiple?: boolean;
  options?: OptionType[];
  groups?: GroupType[];
  value?: string | number | (string | number)[];
  onChange: (value: string | number | (string | number)[]) => void;
  trigger?:
    | ((data: OptionType | undefined) => React.ReactNode)
    | React.ReactNode;
  hasArrow?: boolean;
  triggerProps?: MenuButtonProps;
  listProps?: MenuListProps;
  closeOnSelect?: boolean;
  dynamicTriggerTitle?: React.ReactNode;
  placement?: PlacementWithLogical | undefined;
  itemType?: string;
  isIconButton?: boolean;
  icon?: React.ReactElement;
};

export const Dropdown: React.FC<Props> = ({
  isMultiple = false,
  options = [],
  groups,
  value: v,
  onChange,
  trigger,
  hasArrow,
  triggerProps = {},
  listProps = {},
  closeOnSelect = true,
  dynamicTriggerTitle,
  placement,
  itemType,
  isIconButton = false,
  icon,
}) => {
  const { t } = useTranslation();
  const Item = itemType === 'static' ? Flex : MenuItem;

  const renderOptions = (opt: OptionType[]) =>
    opt.map(
      ({
        value,
        title,
        icon: Icon,
        image,
        avatar,
        name,
        onClick,
        hasCheckbox,
      }) => (
        <Item
          data-group
          key={value}
          onClick={() =>
            onClick
              ? onClick(value)
              : onChange(
                  isMultiple ? xor(v as (string | number)[], [value]) : value,
                )
          }
        >
          {Icon && <Icon mr="2" />}
          {image && (
            <Image
              alt="Dropdown Image"
              h="3"
              draggable="false"
              src={image}
              mr="2"
              rounded="sm"
            />
          )}
          {name && <UserAvatar size="xs" icon={avatar} name={name} mr="2" />}
          <Flex gap="5" align="center" justifyContent="space-between" w="full">
            <Flex align="center">
              {isMultiple && hasCheckbox !== false && (
                <Flex onClick={e => e.stopPropagation()} mr="2">
                  <Checkbox
                    colorScheme="purple"
                    isChecked={(v as (string | number)[]).includes(value)}
                    onChange={() =>
                      onChange(xor(v as (string | number)[], [value]))
                    }
                  />
                </Flex>
              )}
              {typeof title === 'string' ? t(title) : title}
            </Flex>
          </Flex>
        </Item>
      ),
    );

  const optionsList = groups
    ? groups.reduce((acc: OptionType[], group) => acc.concat(group.options), [])
    : options;

  const selectedOption = optionsList.find(i => i.value === v);

  let triggerContent: React.ReactNode;

  if (isIconButton) {
    triggerContent = (
      <IconButton
        aria-label={
          typeof dynamicTriggerTitle === 'string'
            ? dynamicTriggerTitle
            : 'Dropdown'
        }
        icon={icon || <TriangleDownIcon />}
      />
    );
  } else if (dynamicTriggerTitle) {
    triggerContent = (
      <Flex align="center">
        {dynamicTriggerTitle}:{' '}
        <Box as="span" color="vanilla-text" ml={1}>
          {selectedOption?.title && t(selectedOption.title)}
        </Box>
      </Flex>
    );
  } else if (typeof trigger === 'function') {
    triggerContent = trigger(selectedOption);
  } else {
    triggerContent = trigger;
  }

  return (
    <Menu
      closeOnSelect={closeOnSelect}
      placement={placement}
      autoSelect={false}
    >
      {({ isOpen }) => (
        <>
          <MenuButton
            as={isIconButton ? IconButton : Button}
            rounded="full"
            w="max-content"
            leftIcon={!isIconButton ? icon : undefined}
            rightIcon={
              hasArrow && !isIconButton ? <TriangleDownIcon /> : undefined
            }
            {...triggerProps}
            _active={{ transform: 'none' }}
            icon={isIconButton ? icon : undefined}
          >
            {!isIconButton && triggerContent}
          </MenuButton>
          {isOpen && (
            <MenuList sx={{ overflow: 'auto' }} {...listProps}>
              {groups
                ? groups.map(({ title, options: opts }) => (
                    <MenuGroup
                      title={title}
                      key={title}
                      color="vanilla-text"
                      fontWeight="500"
                    >
                      {renderOptions(opts)}
                    </MenuGroup>
                  ))
                : renderOptions(options)}
            </MenuList>
          )}
        </>
      )}
    </Menu>
  );
};
