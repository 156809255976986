import { Box, BoxProps, Image } from '@chakra-ui/react';
import { GHOST_USER } from 'constants/general';
import { NftImageTooltip } from 'services/nft/components/nft-image-tooltip';
import { ImageFallback } from 'uikit/image-fallback';
import { resolveAvatarUrl } from './utils';

export const sizes = {
  '3xs': 14,
  '2xs': 16,
  xs: 18,
  s: 28,
  sm: 32,
  m: 40,
  md: 48,
  lg: 64,
  '2lg': 80,
  xl: 96,
  '2xl': 128,
} as const;

type Props = BoxProps & {
  icon?: string;
  name?: string;
  size?: keyof typeof sizes;
  nftId?: string;
  hasHover?: boolean;
  isGhost?: boolean;
  isSkeleton?: boolean;
};

export const UserAvatar: React.FC<Props> = ({
  icon,
  size = 'md',
  name,
  nftId = undefined,
  hasHover = true,
  isGhost = false,
  isSkeleton = false,
  ...props
}) => {
  const width = `${sizes[size]}px`;
  const rounded = 'full';

  const isAvatarEmpty = !isGhost && !icon;

  let content;

  if (isSkeleton) {
    content = <ImageFallback rounded={rounded} w={`${width}px`} />;
  } else if (isAvatarEmpty) {
    content = (
      <Image
        _hover={{ opacity: 0.9 }}
        cursor={hasHover ? 'pointer' : undefined}
        src={`https://spinkick.imgix.net/avatars/default-avatar.png?auto=format&w=${
          sizes[size] * 2
        }`}
        alt="ava-placeholder"
        rounded="full"
        w="full"
        h="full"
        bg="sugar-dust"
      />
    );
  } else {
    content = (
      <NftImageTooltip nftId={nftId}>
        <Image
          src={resolveAvatarUrl(
            isGhost ? GHOST_USER.icon : icon,
            sizes[size] * 3,
          )}
          alt={name}
          cursor={hasHover ? 'pointer' : undefined}
          _hover={hasHover ? { opacity: '0.9' } : undefined}
          bg="transparent"
          sx={{
            img: {
              objectFit: 'fill',
              WebkitUserDrag: 'none',
            },
          }}
          rounded={rounded}
          w="full"
          h="full"
          draggable="false"
          fallback={<ImageFallback rounded={rounded} w={`${width}px`} />}
        />
      </NftImageTooltip>
    );
  }

  return (
    <Box
      display="inline-flex"
      alignItems="center"
      justifyContent="center"
      verticalAlign="middle"
      w={width}
      h={width}
      {...props}
    >
      {content}
    </Box>
  );
};
