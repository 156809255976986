import { logAction } from 'helpers/newrelic';
import { useEffect } from 'react';
import useScript from 'react-script-hook';

/**
 * TODO refactor to use only where need, to prevent global script loading
 */
export const useSeon = () => {
  const [isScriptLoading] = useScript({
    src: 'https://cdn.seondf.com/js/v5/agent.js',
    checkForExisting: true,
  });

  useEffect(() => {
    if (!isScriptLoading) {
      window.seon?.config({
        host: 'seondf.com',
        session_id: Date.now(),
        audio_fingerprint: true,
        canvas_fingerprint: true,
        webgl_fingerprint: true,
      });
    }
  }, [isScriptLoading]);

  const getSeonSession = () =>
    new Promise((resolve, reject) => {
      if (!window.seon) {
        reject('SEON NOT WORKS');
        logAction('SEON_ERROR');
      } else {
        logAction('SEON_SUCCESS');
        window.seon.getBase64Session((data: string) => {
          if (data) {
            resolve(data);
          } else {
            reject(new Error('empty'));
          }
        });
      }
    });

  return {
    getSeonSession,
  };
};
