import { Tooltip } from '@chakra-ui/react';
import { Link } from 'uikit/link';

type Props = {
  children: React.ReactNode;
  nftId?: string;
};

export const NftImageTooltip: React.FC<Props> = ({ children, nftId }) => {
  if (!nftId) {
    return children;
  }
  return (
    <Tooltip
      gutter={10}
      hasArrow
      fontSize="md"
      rounded="md"
      placement="top"
      bg="vanilla-text"
      fontWeight="500"
      py="1"
      px="2"
      color="toffee-base"
      label={`Sherbet #${nftId}`}
    >
      <Link
        display="flex"
        flexShrink="0"
        h="full"
        w="full"
        to={`?modal=nft&nftId=${nftId}`}
        onClick={e => e.stopPropagation()}
      >
        {children}
      </Link>
    </Tooltip>
  );
};
