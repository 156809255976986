import { Box, Checkbox, Flex, Image, Text } from '@chakra-ui/react';
import { formatCredit } from 'helpers/numeral';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { ImageFallback } from 'uikit/image-fallback';
import { NotificationTip } from 'uikit/notification-tip';
import { Skeleton } from 'uikit/skeleton';

type Props = {
  id: number;
  imageUrl: string;
  rakebackPercent: number;
  isSkeleton?: boolean;
  className?: string;
  isStaked?: boolean;
  collection?: {
    name: string;
    contractAddress?: string;
  };
  isActiveCheckbox?: boolean;
  hasCheckbox?: boolean;
  style?: object;
  onClick?: () => void;
};

export const NftPreview: React.FC<Props> = ({
  id,
  imageUrl,
  rakebackPercent,
  isSkeleton = false,
  className,
  isStaked,
  collection,
  isActiveCheckbox,
  hasCheckbox,
  style,
  onClick,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const props: {
    onClick?: () => void;
    cursor?: string;
  } = {};
  if (!isSkeleton) {
    // props.isExternal = true;
    props.onClick = () => navigate(`?modal=nft&nftId=${id}`);
    props.cursor = 'pointer';
    // props.href = getNftUrl(id);
  }

  if (onClick) {
    props.onClick = onClick;
  }

  return (
    <Box className={className} style={style} {...props} p="1">
      <Flex
        direction="column"
        bg="sugar-dust"
        rounded="xl"
        p="2"
        _hover={{
          opacity: '0.9',
        }}
      >
        <Image
          src={isSkeleton ? '' : `${imageUrl}?w=200`}
          fallback={<ImageFallback rounded="xl" />}
          alt={`#${id}`}
          objectFit="cover"
          rounded="xl"
          draggable="false"
        />
      </Flex>
      <Box mt="2">
        <Flex align="center" mb="2" gap="2">
          {hasCheckbox && (
            <Flex onClick={e => e.stopPropagation()} align="center">
              <Checkbox isChecked={isActiveCheckbox} onChange={onClick} />
            </Flex>
          )}
          <Text flexShrink="0" fontWeight="500" noOfLines={1} maxW="full">
            Sherbet{' '}
            <Box as="span" color="candy-floss-text">
              #{id}
            </Box>
          </Text>
          <Box borderBottom="1px solid" flex="1" borderColor="truffle-border" />
        </Flex>
        <Skeleton isLoaded={!isSkeleton}>
          <Flex align="center" gap="1" overflowX="scroll">
            <NotificationTip
              bg="sugar-dust"
              color="candy-floss-text"
              notifications={collection?.name}
            />
            {(rakebackPercent || isSkeleton) && (
              <NotificationTip
                bg="sugar-dust"
                color="candy-floss-text"
                notifications={
                  isSkeleton ? '...' : `${formatCredit(rakebackPercent * 100)}%`
                }
              />
            )}
          </Flex>
        </Skeleton>
      </Box>
    </Box>
  );
};
