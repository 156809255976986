import { Box, Flex, Text } from '@chakra-ui/react';
import { Loading } from 'components/loading';
import { generateUrl } from 'helpers/image';
import { formatCredit } from 'helpers/numeral';
import { FriendsIcon } from 'icons';
import { getFriendsExistsList } from 'modules/chat';
import { getHandlers } from 'modules/handler';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSelector } from 'react-redux';
import {
  ORIGINALS_GAMES_TYPES,
  getSlotsGamesData,
} from 'services/games/modules/slots';
import {
  addReaction,
  getSocialIsLoaded,
  getSocialPosts,
  getSocialTotal,
  getSocialUsers,
  loadFeed,
} from 'services/user/modules/social';
import { LEVEL_NAMES } from 'services/user/modules/user';
import { useActions } from 'store';
import { EmptyResults } from 'uikit/empty-results';
import { Post } from './components/post';

export const FriendFeed = () => {
  const [isFullLoaded, setIsFullLoaded] = useState(false);
  const actions = useActions({ loadFeed, addReaction });
  const friends = useSelector(getFriendsExistsList);
  const slots = useSelector(getSlotsGamesData);
  const isLoaded = useSelector(getSocialIsLoaded);
  const posts = useSelector(getSocialPosts);
  const handlers = useSelector(getHandlers);
  const users = useSelector(getSocialUsers);
  const total = useSelector(getSocialTotal);
  const { t } = useTranslation();

  const handleShowMore = async () => {
    try {
      const {
        data: { posts: newPosts },
      } = await actions.loadFeed({
        before: posts[posts.length - 1].id,
      });
      if (!newPosts.length) {
        setIsFullLoaded(true);
      }
    } catch (e) {
      console.warn(e);
    }
  };

  useEffect(() => {
    if (!isLoaded) {
      actions.loadFeed();
    }
  }, [actions, isLoaded]);

  if (!isLoaded) {
    return <Loading pos="relative" mt="5" />;
  }

  if (!posts.length) {
    return (
      <Flex h="full" align="center" justifyContent="center">
        <EmptyResults
          icon={FriendsIcon}
          title="friends.activity-empty-list"
          description="friends.propose-add"
        />
      </Flex>
    );
  }

  return (
    <Box pos="relative">
      <InfiniteScroll
        dataLength={posts.length}
        next={handleShowMore}
        hasMore={!isFullLoaded && posts.length < total}
        loader={null}
        scrollableTarget="scrollableDiv"
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        {posts.map(post => {
          const user =
            users[post.userId] ||
            friends.find(f => f.user?.id === post.userId)?.user;

          if (!user) {
            return null;
          }
          const handler = handlers[post.handlerId];

          if (!handler) {
            return null;
          }

          let image;
          let title;
          let won: React.ReactNode;
          let bg;

          if (post.postType === 'LevelUp') {
            title = `${LEVEL_NAMES[post.postContent?.newLevel as keyof typeof LEVEL_NAMES]}`;
            won = (
              <>
                <Text as="span">Reached</Text>{' '}
              </>
            );
          } else if (post.postContent?.winAmount !== undefined) {
            won = (
              <>
                <Text as="span">Won</Text>{' '}
                <Text as="span">
                  ${formatCredit(post.postContent.winAmount)}
                </Text>{' '}
                <Text as="span">playing</Text>{' '}
              </>
            );
          }

          let description;

          if (post.postType === 'LevelUp') {
            title = `${LEVEL_NAMES[post.postContent?.newLevel as keyof typeof LEVEL_NAMES]}`;
          } else if (handler.type === 'slots' && post.gameMode) {
            const slotGame = slots[post.gameMode];
            if (slotGame) {
              image = slotGame.image && generateUrl(slotGame.image, { w: 112 });
              title = slotGame.title;
              description = t(
                `casino.provider.${slotGame.provider}`,
                slotGame.provider || '',
              );
              bg = slotGame.bg;
            }
          } else if (ORIGINALS_GAMES_TYPES.includes(handler.type)) {
            image = generateUrl(slots[handler.type].image, {
              w: 112,
            });
            title = slots[handler.type].title;
            description = 'Sherbet';
          } else if (handler.type === 'raffle') {
            const img = {
              22: 'https://spinkick.imgix.net/tournaments/weekly-race.png?auto=format&w=1000',
              23: 'https://spinkick.imgix.net/tournaments/daily-scramble.png?auto=format&w=1000',
            }[post.handlerId];
            image = img && generateUrl(img, { w: 112 });
            title = `Win ${handler.name}`;
          }

          return (
            <Post
              bg={bg}
              key={post.id}
              post={post}
              user={user}
              image={image}
              title={title}
              description={description}
              won={won}
            />
          );
        })}
      </InfiniteScroll>
    </Box>
  );
};
