import { createIcon } from '@chakra-ui/icons';

export const TONIcon = createIcon({
  displayName: 'TONIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <g clipPath="url(#a)">
        <path fill="#0098EA" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z" />
        <path
          fill="#fff"
          d="M10.732 4.465H5.268a1.307 1.307 0 0 0-1.136 1.96l3.372 5.844c.22.381.772.381.992 0l3.372-5.845c.505-.874-.132-1.96-1.136-1.96Zm-3.23 6.051-.735-1.421-1.772-3.17a.31.31 0 0 1 .272-.462h2.234v5.054-.001Zm3.501-4.59-1.771 3.17-.734 1.42V5.462h2.233a.31.31 0 0 1 .272.463Z"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </>
  ),
});
