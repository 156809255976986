import { Button, Flex, FormControl } from '@chakra-ui/react';
import { useFormik } from 'formik';
import { PasswordInput } from 'forms/password-input';
import { t } from 'i18next';
import { changePassword } from 'services/user/modules/user';
import { useActions } from 'store';
import { toastSuccess } from 'toasts';
import { Errors, FormSubmit } from 'types';
import * as Yup from 'yup';

export const SecuritySectionPasswordForm = () => {
  const actions = useActions({ changePassword });

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      oldPassword: '',
      newPassword: '',
    },
    validationSchema: Yup.object({
      newPassword: Yup.string()
        .min(6, 'errors.password.min')
        .matches(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/, 'errors.password.rules')
        .required('errors.password.required'),
    }),
    onSubmit: async ({ oldPassword, newPassword }, { setErrors }) => {
      try {
        await actions.changePassword({ oldPassword, newPassword });
        toastSuccess({
          title: t('update-password.updated'),
          description: t('update-password.updated-description'),
        });
      } catch (errors) {
        setErrors(errors as Errors);
      }
    },
  });

  return (
    <FormControl as="form" onSubmit={formik.handleSubmit as FormSubmit}>
      <Flex direction="column" gap="5">
        <Flex gap="3" w="full" direction="column">
          <PasswordInput
            label={t('settings.old-password')}
            placeholder={t('settings.old-password')}
            name="oldPassword"
            autoComplete="oldPassword"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.oldPassword}
            isInvalid={
              !!formik.errors.oldPassword && !!formik.touched.oldPassword
            }
            errorMessage={formik.errors.oldPassword}
            size="md"
            boxProps={{ w: 'full' }}
          />
          <PasswordInput
            label={t('settings.new-password')}
            placeholder={t('settings.new-password')}
            name="newPassword"
            autoComplete="newPassword"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.newPassword}
            isInvalid={
              !!formik.errors.newPassword && !!formik.touched.newPassword
            }
            errorMessage={formik.errors.newPassword}
            size="md"
            boxProps={{ w: 'full' }}
          />
        </Flex>
        <Flex
          align="center"
          w={{ base: 'full', md: 'auto' }}
          flexDir={{ base: 'column', md: 'row' }}
          gap="2"
        >
          <Button
            type="submit"
            colorScheme="purple"
            w={{ base: 'full', md: 'auto' }}
            isLoading={formik.isSubmitting}
          >
            {t('settings.change-password')}
          </Button>
        </Flex>
      </Flex>
    </FormControl>
  );
};
