import { COUPON_HANDLER_ID } from 'constants/general';
import { client, parseApiError } from 'helpers/client';

export const createCoupon = (params: object) =>
  client
    .post(`/handler/coupon/${COUPON_HANDLER_ID}/create`, {
      ...params,
      handlerId: COUPON_HANDLER_ID,
    })
    .then(({ data }) => data)
    .catch(parseApiError);

export const redeemCoupon = (code: string, token: string) =>
  client
    .post(`/handler/coupon/${COUPON_HANDLER_ID}/redeem`, { code, token })
    .then(({ data }) => data)
    .catch(parseApiError);
