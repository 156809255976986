import { PreviewGrid } from 'components/preview-grid';
import { ShowMore } from 'components/show-more';
import { useState } from 'react';
import { NftPreview } from 'services/nft/components/nft-preview';

type Props = {
  limit: number;
  isLoaded?: boolean;
  nfts: unknown[];
};

export const NFTGrid: React.FC<Props> = ({ nfts, limit, isLoaded }) => {
  const [page, setPage] = useState(1);
  const list = limit ? nfts.slice(0, page * limit) : nfts;
  const handleShowMore = () => setPage(prev => prev + 1);

  return (
    <>
      <PreviewGrid
        Item={NftPreview}
        list={list}
        size="md"
        isLoaded={isLoaded}
      />
      <ShowMore
        current={list.length}
        total={nfts.length}
        onClick={handleShowMore}
      />
    </>
  );
};
