import { Box, Flex, Text } from '@chakra-ui/react';
import { CountdownExpire } from 'components/countdown-expire';
import { t } from 'i18next';
import { NotificationTip } from 'uikit/notification-tip';

export const BonusCard = ({
  name,
  description,
  endTime,
  transferrableAmount,
  cancelContent,
  wager,
  wager2,
  reloadPercentage,
  reloadMinAmount,
  reloadMaxAmount,
  activatedAmount,
  action,
}) => (
  <Flex
    flexDirection="column"
    ml={cancelContent ? '0' : '-5'}
    paddingLeft={cancelContent ? '0' : '20px'}
  >
    <Flex flexDirection="column">
      <Box
        bg="sugar-dust"
        bgPos="center"
        bgSize="cover"
        bgImage="https://i.imgur.com/xYA1aZJ.png"
        rounded="xl"
        w="full"
        h="32"
        pos="relative"
        mb="2"
      >
        <NotificationTip
          notifications={t('bonus-popup.deposit-bonus')}
          bg="rgb(0, 131, 138)"
          position="absolute"
          top="3"
          color="vanilla-text"
          left="3"
        />
      </Box>
      <Flex align="center" justifyContent="space-between" mb="1">
        <Text color="candy-floss-text">
          {t('bonus.expire-in')} <CountdownExpire endTime={endTime} /> • Wagers:{' '}
          {wager}
        </Text>
      </Flex>
      <Text mb="1" fontWeight="500">
        {reloadPercentage * 100}% {name}
      </Text>
      {transferrableAmount ? (
        <Text color="candy-floss-text">
          Your bonus has been wagered successfully and can be transferred to
          real balance.
        </Text>
      ) : !activatedAmount ? (
        <Text color="candy-floss-text">
          {description || 'You have received a deposit bonus.'} It has a minimum
          deposit requirement of ${reloadMinAmount === 0 ? 10 : reloadMinAmount}
          , and a maximum of ${reloadMaxAmount}.
        </Text>
      ) : (
        <Text color="candy-floss-text">
          Your ${activatedAmount} bonus is locked and you must wager $
          {wager2 * activatedAmount} before it can be withdrawn.
        </Text>
      )}
    </Flex>
    <Box w="full" mt="3">
      <Flex align="center" gap="2">
        {action}
        {cancelContent}
      </Flex>
    </Box>
  </Flex>
);
