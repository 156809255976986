import { createSelector } from '@reduxjs/toolkit';
import uniqBy from 'lodash.uniqby';
import { PromoType } from './types';

type StateType = {
  promo: PromoType;
};

export const getPromoIsLoaded = (state: StateType) => state.promo.isLoaded;

export const getPromoPosts = (state: StateType) => state.promo.posts;

export const getPromoPost = (slug: string) => (state: StateType) =>
  state.promo.data[slug];

export const getPromoCategories = createSelector(getPromoPosts, posts =>
  uniqBy(
    posts.reduce((acc, post) => [...acc, ...(post.tags || [])], [] as string[]),
    'name',
  ).filter(i => i),
);
