import { Box, Button, Flex } from '@chakra-ui/react';
import { Loading } from 'components/loading';
import { Suspense, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Staking } from 'services/nft/pages/staking';

export const NftView = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const { t } = useTranslation();

  const tabs = [
    // {
    //   children: t('wallet.deposit'),
    // },
    {
      children: t('wallet.withdraw'),
    },
  ];
  return (
    <Box>
      <Flex align="center" w="full">
        <Box w="full">
          <Flex
            gap="2"
            align="center"
            overflow="auto"
            zIndex="2"
            maxW="6xl"
            mx="auto"
            width="100%"
            px="5"
            py="5"
            pb="7"
          >
            {tabs.map(({ children }, index) => {
              const isActive = tabIndex === index;
              return (
                <Button
                  colorScheme="custom"
                  border="1px solid"
                  color={isActive ? 'sherbet-purple' : 'candy-floss-text'}
                  borderColor={isActive ? 'sherbet-purple' : 'truffle-border'}
                  rounded="full"
                  _hover={
                    isActive ? {} : { borderColor: 'truffle-border-hover' }
                  }
                  bg="toffee-base"
                  flexShrink="0"
                  gap="2"
                  draggable="false"
                  onClick={() => setTabIndex(index)}
                  key={index}
                >
                  {children}
                </Button>
              );
            })}
          </Flex>
        </Box>
      </Flex>

      <Box>
        <Suspense fallback={<Loading pos="relative" />}>
          <Staking view={tabIndex ? 'unstake' : 'stake'} />
        </Suspense>
      </Box>
    </Box>
  );
};
