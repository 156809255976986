import { Flex } from '@chakra-ui/react';
import { useEffect, useRef, useState } from 'react';
import { Message } from '../message';

const generateRandomString = (minLength: number, maxLength: number) => {
  const possibleChars =
    'ABCD EFGH IJK LMNOP QR STUV WX YZabc de fghijk lmnopq rs tuvwx yz012 3456789';
  const length =
    Math.floor(Math.random() * (maxLength - minLength + 1)) + minLength;
  let randomString = '';

  for (let i = 0; i < length; i += 1) {
    const randomIndex = Math.floor(Math.random() * possibleChars.length);
    randomString += possibleChars.charAt(randomIndex);
  }

  return randomString;
};

const getRandomNumber = (min: number, max: number) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

export const FakeMessages = () => {
  const [messages, setMessages] = useState(() =>
    new Array(10).fill({}).map(() => ({
      name: generateRandomString(5, 10),
      message: generateRandomString(10, 40),
      level: getRandomNumber(1, 20),
    })),
  );
  const scrollRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const interval = setInterval(
      () => {
        setMessages(prev =>
          prev
            .concat([
              {
                name: generateRandomString(5, 10),
                message: generateRandomString(20, 60),
                level: getRandomNumber(1, 20),
              },
            ])
            .slice(-50),
        );
      },
      Math.floor(Math.random() * (8000 - 2000 + 1)) + 2000,
    ); // Random number between 2 and 4 seconds
    return () => clearInterval(interval);
  }, []);

  const scrollToDown = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollToDown();
  }, [messages]);

  return (
    <Flex
      flexDirection="column"
      p="3"
      px="0"
      width="full"
      opacity="0.25"
      h="full"
      overflow="auto"
      ref={scrollRef}
      filter="blur(4px)"
      pointerEvents="none"
    >
      {messages.map(({ message, level }, index) => (
        <Message
          key={index}
          id={String(index)}
          index={index}
          channelId={1}
          message={message}
          userId="1"
          timestamp={String(Date.now())}
          username="Test"
          avatar=""
          badges={[]}
          level={level}
          setReplyId={() => {}}
        />
      ))}
    </Flex>
  );
};
