import { createSelector } from '@reduxjs/toolkit';
import { CookieCrashMultipliers, CookieCrashType } from './types';

type State = {
  cookiecrash: CookieCrashType;
};

export const getSeedPair = (state: State) => state.cookiecrash.seedPair;

export const getIsMultipliersLoaded = (state: State) =>
  state.cookiecrash.isMultipliersLoaded;

const getRawMultipliers = (state: State) => state.cookiecrash.multipliers;

export const getMultipliers = createSelector(getRawMultipliers, multipliers => {
  const difficultyMap = {
    0: 'Easy',
    1: 'Medium',
    2: 'Hard',
    3: 'Extreme',
  } as const;

  const result: Partial<CookieCrashMultipliers> = {};

  multipliers.forEach(item => {
    const difficultyLabel = difficultyMap[item.difficulty];

    if (!result[difficultyLabel]) {
      result[difficultyLabel] = [];
    }

    (result[difficultyLabel] as number[])[item.level - 1] = parseFloat(
      item.multiplier,
    );
  });

  return result as CookieCrashMultipliers;
});

export const getActiveGame = (state: State) => state.cookiecrash.activeGame;
