import { cloneElement } from 'react';

type Props = {
  count?: number;
  children: React.ReactElement<
    unknown,
    string | React.JSXElementConstructor<unknown>
  >;
};

export const SkeletonList: React.FC<Props> = ({ count = 5, children }) =>
  Array.from(Array(count).keys()).map(i => cloneElement(children, { key: i }));
