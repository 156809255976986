import { createIcon } from '@chakra-ui/icons';

export const ConnectionIcon = createIcon({
  displayName: 'ConnectionIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      d="M15.175 7.328a.769.769 0 0 1-.587-.277c-1.763-2.024-4.097-3.148-6.582-3.148-2.486 0-4.82 1.107-6.583 3.13-.316.364-.843.364-1.174 0a1.017 1.017 0 0 1 0-1.331C2.327 3.315 5.069 2 8.006 2c2.937 0 5.678 1.315 7.757 3.684.316.363.316.969 0 1.35-.166.19-.377.294-.588.294Zm-1.4 2.006c.316-.363.316-.969 0-1.35-1.552-1.763-3.585-2.75-5.77-2.75-2.184 0-4.217.97-5.769 2.75-.316.364-.316.97 0 1.35.317.363.844.363 1.175 0 1.22-1.4 2.862-2.18 4.594-2.18 1.733 0 3.36.78 4.595 2.18.165.19.376.277.587.277a.77.77 0 0 0 .587-.277Zm-2.004 2.283c.316-.363.316-.968 0-1.349-1.01-1.159-2.35-1.799-3.766-1.799-1.415 0-2.771.64-3.765 1.8-.317.362-.317.968 0 1.348.316.364.843.364 1.175 0 .692-.795 1.611-1.245 2.605-1.245.98 0 1.913.432 2.606 1.245.166.19.377.277.588.277a.712.712 0 0 0 .557-.277Zm-2.064 1.038c-.933-1.072-2.47-1.072-3.404 0l.798.917c.498.57 1.311.57 1.808 0l.798-.917Z"
    />
  ),
});
