import { Box, Button, GridItem, Text } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  cancelSession,
  getSessionsList,
  loadSessionsList,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { toastSuccess } from 'toasts';
import { Table } from 'uikit/table';

export const SessionsSection = () => {
  const [loadingId, setLoadingId] = useState<boolean | string>(false);
  const sessionsList = useSelector(getSessionsList);
  const actions = useActions({ cancelSession, loadSessionsList });
  const { t } = useTranslation();

  const onCancelSessions = (id: string) => async () => {
    setLoadingId(id);
    await actions.cancelSession(id);
    toastSuccess({
      title: t('settings.sessions-ended'),
    });
    setLoadingId(false);
  };

  useEffect(() => {
    actions.loadSessionsList();
  }, [actions]);

  const fields = [
    {
      key: 'ipAddress',
      title: t('settings.ip'),
      textAlign: 'left' as const,
    },
    {
      key: 'browserInfo',
      title: t('settings.browser'),
      textAlign: 'left' as const,
    },
    {
      key: 'countryCode',
      title: t('settings.country'),
      textAlign: 'left' as const,
    },
    {
      key: 'action',
      title: t('settings.action'),
      textAlign: 'right' as const,
    },
  ];

  const data = [...sessionsList]
    .sort((a, b) => Number(b.lastTimeUsed) - Number(a.lastTimeUsed))
    .map(item => ({
      ...item,
      action: item.isCurrentSession ? (
        <Text fontWeight="500">{t('settings.current')}</Text>
      ) : (
        <Button
          onClick={onCancelSessions(item.id)}
          isLoading={loadingId === item.id}
          variant="link"
          color="sherbet-red"
          _hover={{ opacity: '0.9', textDecor: 'underline' }}
        >
          {t('settings.end-session')}
        </Button>
      ),
    }));

  return (
    <Box flexDirection="column" gap={{ base: '3', md: '5' }}>
      <GridItem>
        <Table
          data={data}
          fields={fields}
          isLoading={!sessionsList.length}
          loadingSkeletonRows={5}
        />
      </GridItem>
    </Box>
  );
};
