import { createIcon } from '@chakra-ui/icons';

export const AffiliateIcon = createIcon({
  displayName: 'AffiliateIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M15.171 2.502c-.506-.941-1.325-1.75-2.408-1.76-.752 0-3.489.54-6.165 1.067-1.264.25-2.514.496-3.536.681-.576.105-1.007.183-1.37.362A2.896 2.896 0 0 0 .181 4.66c-.243.857-.242 2.172 0 3.025.224.79.773 1.446 1.51 1.808.171.085.358.147.569.2l.272 3.803c.06.85.45 1.642 1.372 1.715.772.061 1.59.063 2.364-.002 1.115-.094 1.381-1.05 1.309-1.99l-.199-2.58c.552.1 1.127.24 1.712.382 1.224.296 2.489.603 3.665.581 1.087-.006 1.908-.816 2.415-1.759.526-.976.829-2.275.829-3.67 0-1.396-.303-2.695-.829-3.671ZM12 9.242c1.105 0 2-1.343 2-3 0-1.656-.895-3-2-3s-2 1.344-2 3c0 1.657.895 3 2 3Z"
      clipRule="evenodd"
    />
  ),
});
