import { createIcon } from '@chakra-ui/icons';

export const USDTIcon = createIcon({
  displayName: 'USDTIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <g clipPath="url(#a)">
        <path fill="#26A17B" d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16Z" />
        <path
          fill="#fff"
          fillRule="evenodd"
          d="M8.961 8.692V8.69c-.055.004-.339.02-.971.02-.505 0-.86-.014-.986-.02v.001C5.06 8.607 3.61 8.268 3.61 7.863c0-.404 1.451-.743 3.395-.83v1.322c.127.01.491.03.994.03.604 0 .906-.024.963-.03v-1.32c1.94.087 3.387.425 3.387.83 0 .404-1.447.742-3.387.828Zm0-1.795V5.714h2.707V3.91h-7.37v1.804h2.706v1.182c-2.2.101-3.854.537-3.854 1.059 0 .522 1.654.958 3.854 1.059v3.791h1.957V9.013c2.197-.1 3.847-.536 3.847-1.058 0-.521-1.65-.957-3.847-1.058Z"
          clipRule="evenodd"
        />
      </g>
      <defs>
        <clipPath id="a">
          <path fill="#fff" d="M0 0h16v16H0z" />
        </clipPath>
      </defs>
    </>
  ),
});
