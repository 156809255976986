import { Button, ButtonProps, IconButton, Tooltip } from '@chakra-ui/react';
import { useGoogleLogin } from '@react-oauth/google';
import { useToastError } from 'hooks/use-toast-error';
import { GoogleIcon } from 'icons';
import { MODAL_TYPE, useModal } from 'modals';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { googleAuth } from 'services/auth/modules/auth';
import { useActions } from 'store';
import { Errors } from 'types';

type Props = ButtonProps & {
  isWelcome: boolean;
  onClose?: () => void;
  url?: string | null;
};

export const GoogleAuth: React.FC<Props> = ({
  isWelcome,
  onClose,
  url,
  ...props
}) => {
  const actions = useActions({ googleAuth });
  const { t } = useTranslation();
  const { onOpen } = useModal(MODAL_TYPE.AUTH_GOOGLE);
  const { onOpen: onOpenLogin } = useModal(MODAL_TYPE.AUTH);
  const toastError = useToastError();
  const navigate = useNavigate();
  const login = useGoogleLogin({
    flow: 'auth-code',
    redirect_uri: 'postmessage',
    onSuccess: async ({ code }) => {
      try {
        const data = await actions.googleAuth({
          code,
        });
        if (data.requireDisplayName) {
          onOpen({ accessToken: data.accessToken });
        } else if (data.requireLoginWith2FA) {
          onOpenLogin({ identityCookie: data.identityCookie });
        } else if (url) {
          navigate(url);
        } else {
          onClose?.();
        }
      } catch (errors) {
        toastError(errors as Errors);
      }
    },
  });

  if (isWelcome) {
    return (
      <Tooltip
        gutter={10}
        hasArrow
        fontSize="md"
        rounded="md"
        placement="bottom"
        bg="vanilla-text"
        fontWeight="500"
        py="1"
        px="2"
        color="toffee-base"
        label="Continue with Google"
      >
        <IconButton
          aria-label="Continue with Google"
          icon={<GoogleIcon />}
          rounded="full"
          onClick={() => login()}
          {...props}
        />
      </Tooltip>
    );
  }
  return (
    <Button
      rounded="full"
      w="full"
      onClick={() => login()}
      leftIcon={<GoogleIcon />}
      {...props}
    >
      {t('register-form.continue-google')}
    </Button>
  );
};
