import { createIcon } from '@chakra-ui/icons';

export const SearchIcon = createIcon({
  displayName: 'SearchIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 7a5 5 0 1 1 10 0A5 5 0 0 1 2 7Zm5-7a7 7 0 1 0 3.358 13.143c.067.095.143.186.228.271l2 2a2 2 0 1 0 2.828-2.828l-2-2a2.016 2.016 0 0 0-.27-.228A7 7 0 0 0 7 0Z"
      clipRule="evenodd"
    />
  ),
});
