import { createIcon } from '@chakra-ui/icons';

export const EmoteIcon = createIcon({
  displayName: 'EmoteIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M0 8c0-4.407 3.587-8 8-8 4.407 0 8 3.593 8 8 0 4.413-3.593 8-8 8-4.413 0-8-3.587-8-8Zm11.724 2.239a1 1 0 0 0-1.407-.04l-.017.014a3.35 3.35 0 0 1-.558.364A3.767 3.767 0 0 1 8 11a3.767 3.767 0 0 1-1.742-.423 3.353 3.353 0 0 1-.558-.364l-.017-.015a1 1 0 0 0-1.373 1.455l.69-.724c-.69.724-.688.725-.688.725v.001l.004.003.007.007.02.018.06.053a5.356 5.356 0 0 0 .932.615A5.768 5.768 0 0 0 8 13a5.77 5.77 0 0 0 2.664-.648 5.351 5.351 0 0 0 .932-.616l.06-.053.02-.018.008-.007.003-.003.001-.001.002-.001a1 1 0 0 0 .034-1.414ZM7 5.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM10.5 7a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z"
      clipRule="evenodd"
    />
  ),
});
