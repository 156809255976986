import { Button, Flex, IconButton, useBreakpointValue } from '@chakra-ui/react';
import { LobbyIcon, CategoriesIcon, TransactionsIcon } from 'icons';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSlotsCategories } from 'services/games/modules/slots';
import { Dropdown } from 'uikit/dropdown';
import { SearchInput } from 'uikit/search-input';

const SORT_ITEMS = [
  {
    title: 'casino.sort.featured',
    value: 'featured',
  },
  {
    title: 'casino.sort.popular',
    value: 'popular',
  },
  {
    title: 'casino.sort.a-z',
    value: 'alphabet',
  },
  {
    title: 'casino.sort.z-a',
    value: 'alphabetZ',
  },
];

export const CasinoSearch = ({
  onChangeSearch,
  search,
  sort,
  onChangeSort,
  providers,
  onChangeProviders,
  placeholder,
  hasProviders = true,
  hasSort = true,
  hasSearch = true,
  hasLucky = true,
  isFullWidth,
  availableProviders,
  onFocus,
  hasClose = false,
  isOpened,
  autoFocus,
  ...props
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const categoriesData = useSelector(getSlotsCategories);

  const [activeCategory, setActiveCategory] = useState(null);
  const [isSearchActive, setIsSearchActive] = useState(false);

  const providerOptions = availableProviders
    ? availableProviders.map(({ title, count }) => ({
        title: t(`casino.provider.${title}`),
        value: title,
        count,
      }))
    : [];

  const categories = useMemo(
    () =>
      [
        {
          icon: <LobbyIcon />,
          title: t('nav-bar.lobby'),
          to: '/',
        },
        {
          title: t('casino.category.originals'),
          to: '/casino/category/originals',
          'aria-label': 'Originals',
        },
        {
          title: t('casino.category.slots'),
          category: 'slots',
        },
        {
          title: t('casino.category.live-casino'),
          category: 'live-casino',
        },
        {
          title: t('casino.category.game-shows'),
          category: 'game-shows',
        },
        {
          title: t('casino.category.new-releases'),
          category: 'new-releases',
        },
      ].filter(({ category }) => {
        if (category) {
          return (
            category === 'new-releases' || categoriesData.includes(category)
          );
        }
        return true;
      }),
    [t, categoriesData],
  );

  const handleCategoryClick = category => {
    if (category.to === '/') {
      setActiveCategory(null);
      navigate('/');
      if (isSearchActive) {
        onChangeSearch(''); // Clear the search
        setIsSearchActive(false);
      }
    } else {
      setActiveCategory(category);
      if (category.to) {
        navigate(category.to);
      } else {
        navigate(`/casino/category/${category.category}`);
      }
    }
  };

  useEffect(() => {
    if (location.pathname === '/') {
      setActiveCategory(null);
    } else {
      const currentCategory = categories.find(
        cat =>
          cat.to === location.pathname ||
          (cat.category &&
            location.pathname.includes(`/casino/category/${cat.category}`)),
      );
      setActiveCategory(currentCategory || null);
    }
  }, [location.pathname, categories]);

  useEffect(() => {
    setIsSearchActive(search.length >= 3);
  }, [search]);

  const isLobbyActive = location.pathname === '/';

  const isSmallScreen = useBreakpointValue({ base: true, md: false });

  return (
    <Flex flexDirection="column" gap="5" w="full" {...props}>
      <Flex
        w="full"
        gap={hasProviders || hasLucky || hasSort ? 2 : undefined}
        pos="relative"
        zIndex="2"
        align="flex-end"
      >
        {hasSearch && (
          <SearchInput
            value={search}
            onChange={onChangeSearch}
            placeholder={placeholder}
            isFocused={isOpened}
            onFocus={onFocus}
            hasClose={hasClose}
            autoFocus={autoFocus}
            hasKeyCode
          />
        )}
      </Flex>
      <Flex justify="space-between" align="center">
        <Flex gap="2" p="1" m="-1" align="center" overflow="auto" zIndex="2">
          {categories.map((category, index) => {
            const isActive =
              category === activeCategory ||
              (category.to === '/' && isLobbyActive);
            const isLobby = category.to === '/';
            const shouldShow = !isSearchActive
              ? !activeCategory || isActive || isLobby
              : isLobby;
            if (!shouldShow) return null;

            const buttonProps = {
              key: index,
              colorScheme: 'custom',
              border: '1px solid',
              color:
                isActive && !isSearchActive
                  ? 'sherbet-purple'
                  : 'candy-floss-text',
              borderColor:
                isActive && !isSearchActive
                  ? 'sherbet-purple'
                  : 'truffle-border',
              rounded: 'full',
              _hover:
                isActive && !isSearchActive
                  ? {}
                  : { borderColor: 'truffle-border-hover' },
              bg: 'toffee-base',
              flexShrink: '0',
              gap: '2',
              draggable: 'false',
              onClick: () => handleCategoryClick(category),
              'aria-label': category.title,
            };

            if (isSmallScreen && isLobby) {
              return <IconButton {...buttonProps} icon={<LobbyIcon />} />;
            }

            return (
              <Button {...buttonProps}>
                {category.icon}
                {category.title}
              </Button>
            );
          })}
        </Flex>
        {(activeCategory?.category || isSearchActive) && (
          <Flex gap="2">
            {hasSort && (
              <Dropdown
                options={SORT_ITEMS}
                value={sort}
                onChange={onChangeSort}
                dynamicTriggerTitle="Sort By"
                isIconButton={isSmallScreen}
                icon={<TransactionsIcon />}
              />
            )}
            {hasProviders && providerOptions.length > 1 && (
              <Dropdown
                closeOnSelect={false}
                isMultiple
                options={providerOptions}
                value={providers}
                onChange={onChangeProviders}
                trigger="Provider"
                isIconButton={isSmallScreen}
                icon={<CategoriesIcon />}
              />
            )}
          </Flex>
        )}
      </Flex>
    </Flex>
  );
};
