import { Box } from '@chakra-ui/react';
import { PortableText } from '@portabletext/react';
import { TypedObject } from '@portabletext/types';
import { Accordion } from './components/accordion';
import { Slider } from './components/slider';
import styles from './style.module.scss';

type Props = {
  content: TypedObject;
};

const components = {
  types: {
    // @ts-ignore
    accordion: ({ value }) => <Accordion {...value} />,
    // @ts-ignore
    slider: ({ value }) => <Slider {...value} />,
  },
};

export const HtmlContent: React.FC<Props> = ({ content }) => (
  <Box className={styles.content}>
    <PortableText value={content} components={components} />
  </Box>
);
