import { ChakraComponent, IconProps, useClipboard } from '@chakra-ui/react';
import { CheckIcon } from 'icons';
import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toastInfo } from 'toasts';

export const useCopy = (
  value: string,
  {
    icon: Icon = CheckIcon,
    iconProps,
    description = '',
  }: {
    icon?: ChakraComponent<'svg', IconProps>;
    iconProps?: object;
    description?: string;
  },
) => {
  const { onCopy, hasCopied, setValue } = useClipboard(value);
  const { t } = useTranslation();

  useEffect(() => {
    setValue(value);
  }, [value, setValue]);

  useEffect(() => {
    if (hasCopied) {
      toastInfo({
        title: t('actions.copied'),
        description,
      });
    }
  }, [hasCopied, description, Icon, iconProps, t]);

  return useMemo(
    () => ({
      onCopy,
    }),
    [onCopy],
  );
};
