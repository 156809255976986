import { TriangleDownIcon } from '@chakra-ui/icons';
import {
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  Select,
} from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';

type Option = {
  value: string;
  title: string;
};

type Props = {
  label: string | React.ReactNode;
  name: string;
  labelProps: FormLabelProps;
  boxProps: FormControlProps;
  isInvalid: boolean;
  errorMessage?: string;
  placeholder?: string;
  required: boolean;
  disabled: boolean;
  options: Option[];
};

export const CustomSelect: React.FC<Props> = ({
  label,
  name,
  labelProps,
  boxProps,
  isInvalid,
  errorMessage,
  placeholder,
  required,
  disabled,
  options,
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <FormControl {...boxProps} isInvalid={isInvalid}>
      {!!label && (
        <FormLabel
          htmlFor={name}
          color="candy-floss-text"
          display="inline-flex"
          fontSize="base"
          mb="2"
          textTransform="none"
          {...labelProps}
        >
          {typeof label === 'string' ? t(label) : label}
        </FormLabel>
      )}
      <Select
        icon={
          <TriangleDownIcon
            w="14px !important"
            h="14px !important"
            color="candy-floss-text !important"
          />
        }
        variant="secondary"
        bg="none"
        id={name}
        name={name}
        placeholder={placeholder && t(placeholder)}
        required={required}
        disabled={disabled}
        {...props}
      >
        {options.map(option => (
          <option value={option.value} key={option.value}>
            {option.title}
          </option>
        ))}
      </Select>
      <FormErrorMessage fontSize="md">
        {errorMessage &&
          t(`errors.BE.${errorMessage}`, t(errorMessage as string))}
      </FormErrorMessage>
    </FormControl>
  );
};
