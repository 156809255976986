import {
  LOCAL_STATE,
  LOCAL_STATE_VERSION,
  LOCAL_VERSION,
} from 'constants/general';
import { useMobile } from 'hooks/use-mobile';
import { getHandlerState } from 'modules/handler';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import {
  getUser,
  getUserAffiliateCampaignDetails,
  getUserIsAuthorized,
  getUserIsLoaded,
  initialState,
} from 'services/user/modules/user';

export const usePersistState = () => {
  const isAuthorized = useSelector(getUserIsAuthorized);
  const isUserLoaded = useSelector(getUserIsLoaded);
  const user = useSelector(getUser);
  const isMobile = useMobile();
  const handler = useSelector(getHandlerState);
  const affiliateCampaignDetails = useSelector(getUserAffiliateCampaignDetails);

  const stateUser = useMemo(
    () => ({
      ...initialState,
      profile: user,
      isLoaded: isUserLoaded,
      affiliateCampaignDetails,
      isAuthorized,
    }),
    [isAuthorized, isUserLoaded, user, affiliateCampaignDetails],
  );

  useEffect(() => {
    try {
      localStorage.setItem(LOCAL_STATE_VERSION, LOCAL_VERSION);
      localStorage.setItem(
        LOCAL_STATE,
        JSON.stringify({
          user: stateUser,
          // slots: isMobile ? undefined : slots,
          handler: {
            ...handler,
            isReady: false,
          },
        }),
      );
    } catch (e) {
      console.warn(e);
    }
  }, [stateUser, handler, isMobile]);
};
