import { Box, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getSlotsGamesCountByProvider } from 'services/games/modules/slots';
import { Link } from 'uikit/link';
import { ProviderImage } from 'uikit/provider-image';

type Props = {
  id: string;
  style: React.CSSProperties;
};

export const ProviderPreview: React.FC<Props> = ({ id, style }) => {
  const { t } = useTranslation();

  const gamesCountByProvider = useSelector(getSlotsGamesCountByProvider);

  return (
    <Box style={style} p="1">
      <Link
        height="116px"
        to={`/casino/provider/${id}`}
        allowPrevent
        _hover={{
          borderColor: 'truffle-border-hover',
        }}
        p="3"
        border="1px solid"
        borderColor="truffle-border"
        rounded="xl"
        alignItems="start"
        flexDirection="column"
        display="flex"
        justifyContent="left"
      >
        <ProviderImage provider={id} h="40px" w="40px" />
        <Text color="vanilla-text" noOfLines={1} fontWeight="500" mt="2">
          {t(`casino.provider.${id}`, id)}
        </Text>
        <Text color="candy-floss-text" noOfLines={1}>
          {gamesCountByProvider[id] || 0} {t('footer.games')}
        </Text>
      </Link>
    </Box>
  );
};
