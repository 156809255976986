import { createAction } from 'store';
import * as api from './api';

export const loadGame = createAction(
  'roulette/loadGame',
  () => () => api.loadGame(),
);

export const loadPreviousRolls = createAction(
  'roulette/loadPreviousRolls',
  () => () => api.loadPreviousRolls(),
);

export const addRoll = createAction(
  'roulette/addRoll',
  (gameId, rollNumber, bonusNumber) => ({
    roll: {
      gameId,
      rollNumber,
      bonusNumber,
    },
  }),
);

export const placeBet = createAction(
  'roulette/placeBet',
  ({ gameId, pickedGroup, total, amount }) =>
    () =>
      api.placeBet({ gameId, pickedGroup, total, amount }),
  ({ pickedGroup }) => ({ pickedGroup, _loading: true, _error: true }),
);

export const loadRouletteHistory = createAction(
  'roulette/loadRouletteHistory',
  ({ limit = 10, before = null, id = null } = {}) =>
    () =>
      api.loadRouletteHistory({ limit, before, id }),
);
