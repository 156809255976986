import { createIcon } from '@chakra-ui/icons';

export const RecentIcon = createIcon({
  displayName: 'RecentIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0Zm1 3.889C9 3.398 8.552 3 8 3s-1 .398-1 .889v4.666c0 .317.189.609.496.768l3 1.556c.477.247 1.09.104 1.368-.32s.117-.968-.36-1.216L9 8.045V3.889Z"
      clipRule="evenodd"
    />
  ),
});
