import {
  Tab as ChakraTab,
  Flex,
  IconProps,
  TabProps,
  useMultiStyleConfig,
  useTab,
} from '@chakra-ui/react';
import { Ref, forwardRef } from 'react';
import { NotificationTip } from 'uikit/notification-tip';

export type TabType = TabProps & {
  Icon?: React.ElementType;
  children: React.ReactNode;
  tip?: React.ReactNode;
  iconProps?: IconProps;
  isReadOnly?: boolean;
  isSelected?: boolean;
  isAnimated?: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref?: any;
  onClick?: React.MouseEventHandler;
};

export const Tab: React.FC<TabType> = forwardRef(
  (
    {
      Icon,
      children,
      tip,
      iconProps,
      isReadOnly = false,
      isSelected,
      isAnimated,
      ...props
    },
    ref: Ref<HTMLElement>,
  ) => {
    const tabProps = useTab({ ...props, ref });
    // const isSelected = !!tabProps['aria-selected'];
    const styles = useMultiStyleConfig('Tabs', tabProps);

    return (
      <ChakraTab
        flexShrink="0"
        fontWeight="500"
        pos="relative"
        // transition="none"
        __css={styles.tab}
        _hover={
          isSelected ? { color: 'vanilla-text' } : { color: 'vanilla-text' }
        }
        _active={{ bg: 'none' }}
        _selected={{
          '& > :not(svg)': { color: 'vanilla-text' },
          borderColor: 'transparent',
        }}
        p="0"
        _after={
          isSelected && !isAnimated
            ? {
                content: '""',
                pos: 'absolute',
                bottom: '-4px',
                w: 'full',
                h: '1px',
                bg: 'sherbet-purple',
              }
            : undefined
        }
        {...(isReadOnly && {
          pointerEvents: 'none',
        })}
        {...tabProps}
        onClick={props.onClick}
      >
        <Flex
          justify="center"
          align="center"
          gap="2"
          data-group
          py="5"
          px="4"
          color="candy-floss-text"
          _hover={{ opacity: '0.9' }}
        >
          {Icon && (
            <Icon
              _groupHover={{ opacity: '0.9' }}
              color={isSelected ? 'sherbet-purple' : 'inherit'}
              {...iconProps}
            />
          )}
          <Flex h="16px" lineHeight="4" gap="2" align="center">
            {children}
            {tip && <NotificationTip notifications={tip} />}
          </Flex>
        </Flex>
      </ChakraTab>
    );
  },
);
