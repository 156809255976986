import {
  Box,
  Button,
  ButtonProps,
  Flex,
  Heading,
  HeadingProps,
  Text,
  TextProps,
} from '@chakra-ui/react';

type Props = {
  headingText?: string;
  text?: string;
  buttonText?: string;
  onClick?: () => void;
  isLoading?: boolean;
  headingProps?: HeadingProps;
  textProps?: TextProps;
  buttonProps?: ButtonProps;
};

export const Result: React.FC<Props> = ({
  headingText,
  text,
  buttonText,
  onClick,
  isLoading,
  headingProps,
  textProps,
  buttonProps,
  ...props
}) => (
  <Flex direction="column" {...props}>
    <Box mb="5">
      <Heading
        fontWeight="500"
        fontSize={{ base: 'lg', md: 'xl' }}
        mb="1"
        {...headingProps}
      >
        {headingText}
      </Heading>
      <Text color="candy-floss-text" {...textProps}>
        {text}
      </Text>
    </Box>
    <Box pb="0">
      <Button
        w={{ base: 'full', md: 'auto' }}
        colorScheme="purple"
        onClick={onClick}
        isLoading={isLoading}
        {...buttonProps}
      >
        {buttonText}
      </Button>
    </Box>
  </Flex>
);
