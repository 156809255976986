import { AxiosError } from 'axios';

import { functionsClient } from 'helpers/client';

export const getSeeds = () =>
  functionsClient.get('/casino/seeds').then(d => d.data);

export const changeSeedPair = ({ clientSeed }: { clientSeed: string }) =>
  functionsClient
    .post('/casino/seeds/rotate', { clientSeed })
    .then(d => d.data);

export const getMultipliers = () =>
  functionsClient.get('/casino/multipliers/cookiecrash').then(d => d.data);

export const startGame = ({
  betAmount,
  difficulty,
}: {
  betAmount: number;
  difficulty: number;
}) =>
  functionsClient
    .post('/casino/games/cookiecrash', { betAmount, difficulty })
    .then(d => d.data);

export const getActiveGame = () =>
  functionsClient
    .get('/casino/games/cookiecrash')
    .then(d => d.data)
    .catch(error => {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 404) {
          return null;
        }
      }

      throw error;
    });

export const eatCookie = (gameId: string) =>
  functionsClient
    .post(`/casino/games/cookiecrash/${gameId}/eat`)
    .then(d => d.data);

export const endGame = (gameId: string) =>
  functionsClient
    .post(`/casino/games/cookiecrash/${gameId}/end`)
    .then(d => d.data)
    .catch(error => {
      if (error instanceof AxiosError) {
        if (error?.response?.status === 400) {
          return error?.response?.data;
        }
      }

      throw error;
    });
