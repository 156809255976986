import { createAction } from 'store';
import * as api from './api';

export const loadPage = createAction(
  'pages/loadPage',
  type => () => api.loadPage(type),
);

export const loadBanner = createAction(
  'pages/loadBanner',
  id => () => api.loadBanner(id),
);

export const loadSidebar = createAction(
  'pages/loadSidebar',
  () => () => api.loadSidebar(),
);
