import { TriangleDownIcon } from '@chakra-ui/icons';
import { Box, Button, Flex, IconButton, Text, filter } from '@chakra-ui/react';
import { Loading } from 'components/loading';
import dayjs from 'dayjs';
import { getTxExplorerLink } from 'helpers/blockchain';
import { formatCredit } from 'helpers/numeral';
import { TransactionsIcon } from 'icons';
import { getHandlers } from 'modules/handler';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import {
  ORIGINALS_GAMES_TYPES,
  getSlotsGamesData,
} from 'services/games/modules/slots';
import {
  getCurrencyByNetworks,
  getRateIsLoaded,
  loadRate,
  loadTransactionLink,
} from 'services/user/modules/currencies';
import {
  cancelWithdrawal,
  loadTransactionHistory,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { toastError, toastInfo } from 'toasts';
import { EmptyResults } from 'uikit/empty-results';
import { Link } from 'uikit/link';
import { Table } from 'uikit/table';

const TRANSACTIONS_LIMIT = 10;

const types = {
  all: '',
  bets: 'Bet',
  deposit: 'Deposit',
  withdrawals: 'Withdrawal',
  'tips-sent': 'Tip Sent',
  'tips-received': 'Tip Received',
  rain: 'Rain',
  rakeback: 'Rakeback Received',
  'tournament-payout': 'RACE-WIN',
  'quest-payout': 'Quest Prize Received',
  'coupon-redeemed': 'Coupon',
};

type ItemType = unknown;

type TransactionType = {
  id: string;
  timestamp: string;
  credit: string;
  amount: number;
  items: ItemType[];
  isDebit: boolean;
  handlerId: number;
  subGameType: string;
  balanceType: number;
  transactionType: string;
  implementationId: string;
  verified?: boolean;
  withdrawalId?: string;
  isRefunded: boolean;
};

type Props = {
  username?: string;
  type?: string;
};

export const Transactions: React.FC<Props> = ({
  username,
  type: paramsType = 'deposit',
  ...props
}) => {
  const [transactions, setTransactions] = useState<TransactionType[]>([]);
  const [search, setSearch] = useState('');
  const [total, setTotal] = useState(0);
  const [filters, setFilters] = useState({ search: '' });
  const [isLoading, setLoading] = useState(true);
  const rateIsLoaded = useSelector(getRateIsLoaded);

  const actions = useActions({
    loadTransactionHistory,
    loadTransactionLink,
    loadRate,
    cancelWithdrawal,
  });
  const handlers = useSelector(getHandlers);
  const slots = useSelector(getSlotsGamesData);
  const { t } = useTranslation();
  const type = types[paramsType as keyof typeof types];
  const currenciesNetworks = useSelector(getCurrencyByNetworks);

  const fields = [
    {
      key: 'id',
      title: 'ID',
      textAlign: 'left' as const,
    },
    {
      key: 'time',
      title: t('transactions.time'),
      textAlign: 'left' as const,
    },
    {
      key: 'transactionType',
      title: t('transactions.type'),
      textAlign: 'left' as const,
    },
    {
      key: 'amount',
      title: t('transactions.amount'),
      textAlign: 'center' as const,
    },
    {
      key: 'total',
      title: t('transactions.balance'),
      textAlign: 'right' as const,
    },
    {
      key: 'action',
      // TODO: Replace with translation string
      title: 'Action',
      textAlign: 'right' as const,
    },
  ];

  useEffect(() => {
    if (!rateIsLoaded) {
      actions.loadRate();
    }
  }, [actions, rateIsLoaded]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      const payload = await actions.loadTransactionHistory({
        limit: TRANSACTIONS_LIMIT,
        username,
        search: filters.search,
        type,
      });
      setTransactions(payload.transactions);
      setTotal(payload.totalCount);
      setLoading(false);
    })();
  }, [filters.search, type, actions, username]);

  const handleLoadMore = useCallback(async () => {
    setLoading(true);
    const payload = await actions.loadTransactionHistory({
      limit: TRANSACTIONS_LIMIT,
      before: transactions.at(-1)?.id,
      search: filters.search,
      type,
      username,
    });
    setTransactions(prev => [...prev, ...payload.transactions]);
    setLoading(false);
  }, [transactions, filters.search, type, actions, username]);

  const onSearch = useCallback(() => {
    setFilters({ search });
  }, [search]);

  const handleLink = useMemo(
    () =>
      (transactionType: 'Deposit' | 'Withdrawal', id: string) =>
      async () => {
        const transType = {
          Deposit: 'deposit',
          Withdrawal: 'withdraw',
        }[transactionType];
        const w = window.open();
        try {
          const result = await actions.loadTransactionLink({
            transactionType: transType,
            transactionId: id,
          });
          const url = getTxExplorerLink(
            result.assetId,
            result.txHash,
            currenciesNetworks,
          );
          if (url && w) {
            w.location = url;
            w.focus();
            return;
          }
        } catch (e) {
          console.warn(e);
        }
        w?.close();
        toastInfo({
          title: t('transactions.link-not-ready'),
          ignoreHidden: true,
        });
      },
    [actions, t, currenciesNetworks],
  );

  const handleCancel = useMemo(
    () => (id: string) => async () => {
      try {
        const result = await actions.cancelWithdrawal(id);

        if (!result) {
          toastError({
            title: t('errors.BE.SOMETHING_WRONG'),
          });
          return;
        }

        let updatedTransactions = [...transactions];

        const i = updatedTransactions.findIndex(t => t.withdrawalId === id);

        updatedTransactions[i] = {
          ...updatedTransactions[i],
          isRefunded: true,
        };

        setTransactions(updatedTransactions);

        toastInfo({
          // TODO: Replace with translation string
          title: 'Your withdrawal has been cancelled',
        });
      } catch (e) {
        toastError({
          title: t('errors.BE.SOMETHING_WRONG'),
        });
      }
    },
    [actions, toastError, toastInfo, transactions, setTransactions],
  );

  const data = useMemo(
    () =>
      transactions.map(
        ({
          id,
          timestamp,
          credit,
          amount,
          isDebit,
          handlerId,
          subGameType,
          balanceType,
          transactionType,
          implementationId,
          verified,
          withdrawalId,
          isRefunded,
        }) => {
          const date = dayjs(Number(timestamp) * 1000).format(
            'HH:mm MM/DD/YYYY',
          );

          let amountContent;

          if (isDebit) {
            amountContent = `-$${formatCredit(amount)}`;
          } else {
            amountContent = `+$${formatCredit(amount)}`;
          }

          if (balanceType !== 0) {
            amountContent = (
              <Flex align="center" justify="center" gap="1" as="span">
                {amountContent} (Bonus)
              </Flex>
            );
          }

          const handler = handlers[handlerId];

          let handlerContent: React.ReactNode = handler?.name;

          if (handlerId === undefined) {
            if (transactionType === 'Affiliate Bonus Received') {
              handlerContent = t('profile.affiliates');
            } else {
              handlerContent = t('profile.transactions.rakeback');
            }
          } else if (handlerId === 11) {
            handlerContent = 'Wallet';
          } else if (subGameType) {
            const game = slots[subGameType];

            handlerContent = (
              <Link to={`/casino/game/${subGameType}`}>{game?.title}</Link>
            );
          } else if (ORIGINALS_GAMES_TYPES.includes(handler?.type)) {
            handlerContent = (
              <Link to={`/casino/game/${handler.type}`}>{handler.name}</Link>
            );
          }

          let link;

          if (
            (transactionType === 'Deposit' && implementationId) ||
            transactionType === 'Withdrawal'
          ) {
            link = (
              <Flex align="center" as="span" gap="2" textDecor="underline">
                <Button
                  variant="link"
                  fontWeight="400"
                  onClick={handleLink(transactionType, id)}
                  _hover={{ opacity: '0.9' }}
                >
                  #{id}
                </Button>
              </Flex>
            );
          }

          const showCancelButton =
            transactionType === 'Withdrawal' &&
            !!withdrawalId &&
            !verified &&
            !isRefunded;

          return {
            id: link || <Text color="candy-floss-text">#{id}</Text>,
            time: date,
            handler: handlerContent,
            transactionType: t(
              `transactions.type.${transactionType}`,
              transactionType,
            ),
            amount: !isDebit ? (
              <Text color="sherbet-green.200">{amountContent}</Text>
            ) : (
              amountContent
            ),
            total: credit ? `$${formatCredit(credit)}` : null,
            // TODO: Replace "Cancel" with translation string
            action: showCancelButton && (
              <Flex
                align="right"
                justifyContent="end"
                as="span"
                gap="2"
                textDecor="underline"
              >
                <Button
                  variant="link"
                  fontWeight="400"
                  _hover={{ opacity: '0.9' }}
                  onClick={handleCancel(withdrawalId)}
                >
                  Cancel
                </Button>
              </Flex>
            ),
          };
        },
      ),
    [transactions, handlers, t, slots, handleLink, handleCancel],
  );

  const emptyContent = (
    <EmptyResults
      icon={TransactionsIcon}
      title="transactions.empty-list"
      description="transactions.no-transactions"
    />
  );

  if (isLoading) {
    return <Loading pos="relative" my="14" />;
  }

  if (!transactions.length && !search && !filter) {
    return <Box {...props}>{emptyContent}</Box>;
  }

  return (
    <Box {...props} mb="5">
      {/* <Flex
        align="center"
        w={{ base: 'full', md: 'auto' }}
        flexDir={{ base: 'column', md: 'row' }}
        gap="2"
        mb="5"
      >
        <SearchInput
          value={search}
          onChange={setSearch}
          placeholder={t('transactions.search-placeholder')}
          onSubmit={onSearch}
        />
        <Button
          w={{ base: 'full', md: 'auto' }}
          colorScheme="purple"
          flexShrink="0"
          onClick={onSearch}
          isLoading={isLoading}
        >
          {t('actions.search')}
        </Button>
      </Flex> */}
      {data.length ? <Table fields={fields} data={data} /> : emptyContent}
      {transactions.length < total && (
        <Flex justify="center" mt="5">
          <IconButton
            aria-label="Show More"
            color="candy-floss-text"
            size="sm"
            onClick={handleLoadMore}
            isLoading={isLoading}
            rounded="full !important"
          >
            <TriangleDownIcon />
          </IconButton>
        </Flex>
      )}
    </Box>
  );
};
