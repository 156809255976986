import { convertToUrl } from 'helpers/url-text';
import uniq from 'lodash.uniq';
import { GamePayloadType } from './types';

function removeQuotes(text: string) {
  return text.replace(/^"(.{0,3}|[^"]+)"|"(?:.{0,3}|[^"]+)"$/, '$1');
}

export const normalizeGame = (game: GamePayloadType) => {
  const value = game;

  let categories: string[] = [];

  if (value.categories) {
    categories = categories.concat(value.categories);
  }
  if (value.features) {
    categories = categories.concat(value.features);
  }
  if (value.themes) {
    categories = categories.concat(value.themes);
  }

  const titleOverride = value.settings.overrides?.find(
    o => o.key === 'Title',
  )?.value;

  return {
    ...value,
    title: titleOverride || value.title,
    tags: [],
    description: value.description ? removeQuotes(value.description) : '',
    provider: value.producerId || value.provider || '',
    categories: uniq(categories.map(convertToUrl)),
    image:
      value.customImage ||
      value.providerImage ||
      `https://cdn.softswiss.net/i/s3/${value.id.replace(':', '/')}.webp`,
    disabledCountries: value.restrictedCountries || [],
    bg: value.customColor,
    hasDemo: value.demoGameSupported,
    releasedOn: value.releasedOn && value.releasedOn * 1000,
  };
};
