/* eslint-disable max-lines */
import {
  Box,
  Button,
  Flex,
  IconButton,
  IconProps,
  ListItem,
  Popover,
  PopoverAnchor,
  PopoverContent,
  Text,
  UnorderedList,
} from '@chakra-ui/react';
import { BigInteger } from 'big-integer';
import cn from 'classnames';
import { MIN_WAGER_CHAT } from 'constants/general';
import getUrls from 'get-urls';
import { clean } from 'helpers/bad-words';
import { isCommandAvailable } from 'helpers/command';
import { formatCredit } from 'helpers/numeral';
import { EmoteIcon, SettingsIcon } from 'icons';
import {
  ChatReplyIdType,
  UserChatMentionInfoType,
  getChannel,
  sendMessage,
} from 'modules/chat';
import {
  FunctionComponent,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Mention, MentionsInput } from 'react-mentions';
import { useSelector } from 'react-redux';
import {
  PermissionFlags,
  getUserIsAuthorized,
  getUserLevelInfo,
  getUserPermissionFlags,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { toastError } from 'toasts';
import { Errors } from 'types';
import { UserAvatar } from 'uikit/user-avatar';
import { ChatAvatar } from '../chat-avatar';
import { ChatRainForm } from './components/chat-rain-form';
import { ChatSettings } from './components/chat-settings';
import { EmotePicker } from './components/emote-picker';
import { useChatCommands } from './hooks/use-chat-commands';
import styles from './style.module.scss';

const EXCLUDE_DOMAINS = [
  'twitter.com',
  'x.com',
  'sherbet.com',
  'discord.com',
  'kick.com',
  'twitch.com',
];

type Props = {
  channelId: number;
  onSend: () => void;
  uniqUsers: UserChatMentionInfoType[];
  replyId: ChatReplyIdType;
  setReplyId: (val: null) => void;
  disabled: boolean;
  isLowWager: boolean;
};

export const ChatSection: React.FC<Props> = ({
  channelId,
  onSend,
  uniqUsers,
  replyId,
  setReplyId,
  disabled,
  isLowWager,
}) => {
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [rateTime, setRateTime] = useState(0);
  const { t } = useTranslation();
  const [openedPopover, setOpenedPopover] = useState<string | null>(null);
  const levelInfo = useSelector(getUserLevelInfo);
  const userPermissions = useSelector(getUserPermissionFlags);
  const isAdmin = isCommandAvailable(
    userPermissions,
    PermissionFlags.MuteUser as unknown as BigInteger,
  );
  const channel = useSelector(getChannel);

  const isAuthorized = useSelector(getUserIsAuthorized);

  const actions = useActions({ sendMessage });

  const messageInputRef = useRef<HTMLInputElement>(null);

  const handleOpenChatRain = () => {
    setOpenedPopover('CHAT_RAIN');
  };

  const commands = useChatCommands({ onRainOpen: handleOpenChatRain });

  const closePopover = () => setOpenedPopover(null);

  const handleChange = useCallback((e: { target: { value: string } }) => {
    setMessage(e.target.value.slice(0, 200));
  }, []);

  useLayoutEffect(() => {
    if (replyId) {
      setTimeout(() => {
        messageInputRef.current?.focus();
      }, 100);
    }
  }, [replyId]);

  // const sendCommand = useCallback(
  //   async (command: string, commandArgs: string[]) => {
  //     const commandKey = command.match(/{(.*?)}/)?.[1] ?? command.slice(1);
  //     const { handler, args, unlimitedArgs } =
  //       commands.find(cmd => cmd.command === commandKey) ?? {};
  //     if (!handler) {
  //       toastError({
  //         description: t('chat.command-matches', { command: commandKey }),
  //       });
  //       return Promise.resolve();
  //     }

  //     if (args && !unlimitedArgs && commandArgs.length !== args.length) {
  //       toastError({
  //         description: t('chat.command-arguments', {
  //           command,
  //           length: args.length,
  //         }),
  //       });
  //       return Promise.resolve();
  //     }

  //     return handler(commandArgs);
  //   },
  //   [commands, t],
  // );

  const handleSendMessage = useCallback(
    async (msg: string) => {
      let preparedMessage = msg;

      try {
        preparedMessage = clean(msg || '').trim();
      } catch (e) {
        console.warn(e);
      }

      if (!preparedMessage.length) return;
      if (
        !isAdmin &&
        getUrls(preparedMessage.replace(/\[([^}]+)\]/, ''), {
          exclude: EXCLUDE_DOMAINS,
        }).size > 0
      ) {
        toastError({
          title: t('EXTARNAL_LINK_UNAVAILABLE'),
          description: t('EXTARNAL_LINK_UNAVAILABLE_DESCRIPTION'),
        });
        return;
      }
      setIsLoading(true);
      setMessage('');
      onSend();
      if (preparedMessage.startsWith('/')) {
        const [command, ...commandArgs] = preparedMessage.split(' ');
        try {
          // await sendCommand(command, commandArgs);
        } catch (errors) {
          toastError({
            title: t(`errors.BE.${(errors as Errors).global}`),
            description: t(
              `errors.BE.${(errors as Errors).global}_DESCRIPTION`,
              '',
            ),
          });
        }
      } else {
        try {
          const result = await actions.sendMessage({
            channel: channelId,
            message: preparedMessage,
            replyToUser: replyId?.username,
            replyToMessage: replyId?.messageId,
          });
          if (result.action === 'error') {
            toastError({
              title: t('errors.BE.NOT_ALLOWED'),
              description: t('errors.BE.NOT_ALLOWED_DESCRIPTION'),
            });
          } else {
            if (replyId) {
              setReplyId(null);
            }
            if (result?.payload?.status === 'RATE_LIMITED') {
              const [time] = result.payload.args;
              if (time) {
                const [h, m, s] = time.split(':');
                const seconds = Math.trunc(h * 3600 + m * 60 + s);
                toastError({
                  title: 'Message rate limit',
                  description: t('chat.rate-limit', { seconds }),
                });
                setRateTime(seconds);
              }
            }
          }
        } catch (e) {
          console.warn(e);
        }
      }
      setIsLoading(false);
    },
    [channelId, onSend, t, actions, isAdmin, replyId, setReplyId],
  );

  const handleKeyDown = useCallback(
    (
      e:
        | React.KeyboardEvent<HTMLInputElement>
        | React.KeyboardEvent<HTMLTextAreaElement>,
    ) => {
      if (e.keyCode === 13 && e.shiftKey === false) {
        e.preventDefault();
        handleSendMessage?.(message);
      }
    },
    [handleSendMessage, message],
  );

  const handleOpenChatSettings = () => {
    setOpenedPopover('CHAT_SETTINGS');
  };

  const handleOpenEmotePicker = () => {
    setOpenedPopover('EMOTE_PICKER');
  };

  const handleAddEmote = (emote: string) => {
    setMessage(prev => `${prev}${emote} `);
  };

  const displayMentionTransform = (_: unknown, display: string) =>
    `@${display}`;
  const displayCommandTransform = (_: unknown, display: string) =>
    `/${display}`;

  const renderMentions = (
    { display, icon }: { display: string; icon: string },
    search: string,
    highlightedDisplay: unknown,
    index: unknown,
    focused: boolean,
  ) => (
    <Button
      w="full"
      bg="none"
      rounded="full"
      border="none"
      justifyContent="left"
      _hover={{ bg: 'sugar-dust' }}
      _focus={{ bg: 'sugar-dust' }}
      {...(focused && { bg: 'sugar-dust' })}
      leftIcon={<UserAvatar icon={icon} size="xs" name={display} />}
    >
      {display}
    </Button>
  );

  const renderCommands = (
    {
      description,
      icon: Icon,
    }: { description: string; icon?: FunctionComponent<IconProps> },
    search: string,
    highlightedDisplay: unknown,
    index: unknown,
    focused: boolean,
  ) => (
    <Button
      w="full"
      justifyContent="left"
      bg="none"
      border="none"
      rounded="full"
      leftIcon={Icon ? <Icon color="vanilla-text" /> : <>/</>}
      _hover={{ bg: 'sugar-dust' }}
      _focus={{ bg: 'sugar-dust' }}
      {...(focused && { bg: 'sugar-dust' })}
    >
      {description}
    </Button>
  );
  const renderSuggestionsContainer = (children: React.ReactNode) => (
    <Flex direction="column">
      <Flex
        flexDirection="column"
        maxHeight="300"
        overflow="auto"
        scrollBehavior="smooth"
      >
        <Flex flexDirection="column">{children}</Flex>
      </Flex>
    </Flex>
  );

  let placeholder = t('chat.send-message');
  if (!isAuthorized) {
    placeholder = t('chat.must-signed');
  } else if (isLowWager) {
    placeholder = t('chat.must-wager', {
      amount: formatCredit(MIN_WAGER_CHAT - Number(levelInfo?.exp || 0) / 100),
    });
  }

  const hasBottomRow = isAuthorized;
  const isBot = channel?.isBot;

  return (
    <Popover
      isOpen={Boolean(openedPopover || replyId)}
      onClose={() => setOpenedPopover(null)}
      gutter={0}
      matchWidth
    >
      <PopoverContent
        border="none"
        rounded="none"
        w="100%"
        bg="toffee-base"
        variants={{
          exit: {
            scale: 1,
          },
          enter: {
            scale: 1,
          },
        }}
        boxShadow="none !important"
        sx={{
          '&': {
            visibility: 'visible !important',
          },
        }}
      >
        {openedPopover === 'CHAT_SETTINGS' && (
          <ChatSettings onClose={closePopover} />
        )}
        {openedPopover === 'CHAT_RULES' && (
          <Box borderTop="1px solid" borderColor="truffle-border" p="5">
            <Box mb="5">
              <UnorderedList color="#B6B7D0">
                <ListItem>{t('chat.rule1')}</ListItem>
                <ListItem>{t('chat.rule2')}</ListItem>
                <ListItem>{t('chat.rule3')}</ListItem>
                <ListItem>{t('chat.rule4')}</ListItem>
                <ListItem>{t('chat.rule5')}</ListItem>
              </UnorderedList>
            </Box>
            <Button
              aria-label="Accept Rules"
              colorScheme="purple"
              w="full"
              onClick={() => {
                localStorage.setItem('CHAT_RULES', '1');
                setOpenedPopover(null);
              }}
            >
              {t('chat.accept-rules')}
            </Button>
          </Box>
        )}
        {openedPopover === 'CHAT_RAIN' && (
          <ChatRainForm onClose={closePopover} />
        )}
        {openedPopover === 'EMOTE_PICKER' && (
          <EmotePicker addEmote={handleAddEmote} />
        )}
        {!openedPopover && replyId && (
          <Box
            borderTop="1px solid"
            borderColor="truffle-border"
            p="5"
            bg="toffee-base"
          >
            <Flex align="center" justify="space-between" gap="5" mb="2">
              <Flex align="center" gap="2">
                <Text color="candy-floss-text">
                  {t('chat.reply-to')} {replyId.username}
                </Text>
              </Flex>
              <Button
                onClick={() => setReplyId(null)}
                cursor="pointer"
                variant="link"
              >
                {t('actions.cancel')}
              </Button>
            </Flex>
            <Box
              alignItems="center"
              overflow="hidden"
              whiteSpace="nowrap"
              display="inline-flex"
              verticalAlign="middle"
              maxW="full"
            >
              <ChatAvatar
                avatar={replyId.avatar}
                badges={replyId.badges}
                username={replyId.username}
                nftId={replyId.nftId}
                level={replyId.level}
                mr="2"
              />
              <Text
                fontWeight="500"
                overflow="hidden"
                whiteSpace="nowrap"
                textOverflow="ellipsis"
                verticalAlign="bottom"
              >
                {replyId.message}
              </Text>
            </Box>
          </Box>
        )}
      </PopoverContent>
      <PopoverAnchor>
        <Box>
          <Box
            p="5"
            borderTop="1px solid"
            borderColor="truffle-border"
            opacity={isBot ? '0.5' : undefined}
            pointerEvents={isBot ? 'none' : undefined}
          >
            <Flex
              key={rateTime}
              className={cn({
                [styles.shakeX]: rateTime,
                [styles.disabled]: disabled,
              })}
            >
              <Flex w="full" direction="column">
                <Box position="relative">
                  <MentionsInput
                    allowSuggestionsAboveCursor
                    className="mention-input"
                    value={message}
                    onChange={handleChange}
                    onKeyDown={handleKeyDown}
                    onFocus={() => {
                      const accepted = localStorage.getItem('CHAT_RULES');
                      if (!accepted) {
                        if (openedPopover !== 'CHAT_RULES') {
                          setOpenedPopover('CHAT_RULES');
                        }
                      }
                    }}
                    disabled={disabled}
                    placeholder={placeholder}
                    singleLine
                    customSuggestionsContainer={renderSuggestionsContainer}
                    inputRef={messageInputRef}
                  >
                    <Mention
                      trigger="@"
                      displayTransform={displayMentionTransform}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      renderSuggestion={renderMentions as any}
                      data={uniqUsers}
                      appendSpaceOnAdd
                    />
                    <Mention
                      trigger="/"
                      displayTransform={displayCommandTransform}
                      // eslint-disable-next-line @typescript-eslint/no-explicit-any
                      renderSuggestion={renderCommands as any}
                      markup="/{__display__}"
                      data={commands}
                      appendSpaceOnAdd
                    />
                  </MentionsInput>
                </Box>
              </Flex>
            </Flex>
            {hasBottomRow && !isLowWager && (
              <Flex justifyContent="end" w="full" mt="3" align="center">
                <IconButton
                  size="sm"
                  color="candy-floss-text"
                  rounded="full"
                  aria-label="Settings"
                  variant="ghost"
                  icon={<SettingsIcon />}
                  isDisabled={!!openedPopover}
                  onClick={handleOpenChatSettings}
                />
                <IconButton
                  mr="2"
                  size="sm"
                  color="candy-floss-text"
                  rounded="full"
                  variant="ghost"
                  aria-label="Emote"
                  icon={<EmoteIcon />}
                  isDisabled={!!openedPopover}
                  onClick={handleOpenEmotePicker}
                />
                <Button
                  rounded="full !important"
                  size={{ base: 'md', lg: 'sm' }}
                  colorScheme="purple"
                  isLoading={isLoading}
                  isDisabled={!message}
                  onClick={() => handleSendMessage(message)}
                >
                  {t('nav-bar.chat')}
                </Button>
              </Flex>
            )}
          </Box>
        </Box>
      </PopoverAnchor>
    </Popover>
  );
};
