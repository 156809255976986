import { createIcon } from '@chakra-ui/icons';

export const SoccerIcon = createIcon({
  displayName: 'SoccerIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.897 7.93c.29-.21.665-.27 1.005-.157l2.322.767c.368.121.646.425.736.801l.61 2.56c.078.325.006.667-.196.933a7.209 7.209 0 0 1-.722.827 7.905 7.905 0 0 1-3.032 1.905c-.316.11-.664.072-.95-.103l-2.254-1.384a1.116 1.116 0 0 1-.532-.952v-2.44c0-.358.17-.694.46-.904L9.897 7.93Zm.849 2.143-1.63 1.183v1.247l1.248.766a5.672 5.672 0 0 0 1.727-1.204c.061-.06.12-.121.18-.185l-.338-1.415-1.187-.392Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.096 7.771c.34-.11.71-.05.999.158l2.56 1.853c.29.21.461.546.461.905v2.44c0 .387-.2.747-.53.95l-2.24 1.384a1.116 1.116 0 0 1-.939.11 7.764 7.764 0 0 1-3.059-1.91 7.214 7.214 0 0 1-.722-.827 1.116 1.116 0 0 1-.198-.928l.603-2.575c.09-.381.371-.687.743-.808l2.322-.752Zm-1.035 2.682-.333 1.425a4.14 4.14 0 0 0 .198.204 5.585 5.585 0 0 0 1.718 1.19l1.24-.767v-1.248l-1.64-1.187-1.183.383Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M4.923 2.727c.392-.03.77.148.996.468l1.407 1.987c.204.288.26.654.15.99l-.974 3.006c-.11.34-.378.607-.718.717l-2.322.752c-.372.12-.78.038-1.075-.218L.385 8.695A1.116 1.116 0 0 1 0 7.827a7.916 7.916 0 0 1 1.464-4.433c.192-.27.495-.44.825-.466l2.634-.201ZM3.01 5.112a5.672 5.672 0 0 0-.744 2.257l1.108.96 1.181-.382.626-1.932-.717-1.014-1.454.111Zm7.11-1.914c.226-.312.599-.485.984-.456l2.612.193c.333.025.637.197.83.47A7.932 7.932 0 0 1 16 7.841c.007.332-.134.65-.385.868l-2.002 1.734c-.297.257-.708.34-1.081.216l-2.322-.767a1.116 1.116 0 0 1-.712-.715L8.523 6.17c-.11-.34-.05-.712.16-1.001l1.436-1.972Zm1.438 1.816-.736 1.01.625 1.927 1.183.39 1.105-.957a5.675 5.675 0 0 0-.738-2.263l-1.439-.107Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.654 6.828A1.116 1.116 0 0 1 1.849 7L3.85 8.744c.312.272.448.694.354 1.097l-.603 2.574a1.116 1.116 0 0 1-1.972.425A7.92 7.92 0 0 1 0 8v-.156c0-.438.255-.835.654-1.016ZM6.832.656c.29.32.37.778.205 1.176L6.033 4.266c-.161.39-.527.655-.947.687l-2.635.201a1.116 1.116 0 0 1-1-1.752 7.77 7.77 0 0 1 .89-1.047A7.86 7.86 0 0 1 5.68.338a1.118 1.118 0 0 1 1.151.318ZM9.897 7.93c.29-.21.665-.27 1.005-.157l2.322.767c.368.121.646.425.736.801l.61 2.56c.078.325.006.667-.196.933a7.209 7.209 0 0 1-.722.827 7.905 7.905 0 0 1-3.032 1.905c-.316.11-.664.072-.95-.103l-2.254-1.384a1.116 1.116 0 0 1-.532-.952v-2.44c0-.358.17-.694.46-.904L9.897 7.93Zm.849 2.143-1.63 1.183v1.247l1.248.766a5.672 5.672 0 0 0 1.727-1.204c.061-.06.12-.121.18-.185l-.338-1.415-1.187-.392Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M10.12 3.198c.226-.312.599-.485.984-.456l2.612.193c.333.025.637.197.83.47A7.932 7.932 0 0 1 16 7.841c.007.332-.134.65-.385.868l-2.002 1.734c-.297.257-.708.34-1.081.216l-2.322-.767a1.116 1.116 0 0 1-.712-.715L8.523 6.17c-.11-.34-.05-.712.16-1.001l1.436-1.972Zm1.438 1.816-.736 1.01.625 1.927 1.183.39 1.105-.957a5.675 5.675 0 0 0-.738-2.263l-1.439-.107Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M9.175.657c.29-.32.74-.444 1.153-.318a7.914 7.914 0 0 1 3.313 1.996c.355.34.654.703.915 1.085a1.117 1.117 0 0 1-1.005 1.742l-2.612-.194a1.116 1.116 0 0 1-.948-.684L8.97 1.836c-.166-.4-.087-.859.204-1.18Zm6.173 6.193c.397.182.652.58.652 1.016V8c0 1.77-.571 3.451-1.628 4.837a1.116 1.116 0 0 1-1.974-.421l-.603-2.56a1.116 1.116 0 0 1 .356-1.1l2.002-1.734c.33-.286.797-.353 1.194-.171Zm-7.935 5.328c.36-.222.813-.222 1.172-.001l2.248 1.384a1.116 1.116 0 0 1-.226 2.007 8.06 8.06 0 0 1-5.213-.002 1.116 1.116 0 0 1-.22-2.004l2.24-1.384Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="m6.824 2.35-.556 1.346.723 1.015h2.026l.733-1.006-.564-1.354A5.935 5.935 0 0 0 8 2.233c-.402 0-.798.039-1.176.116ZM5.68.34A7.957 7.957 0 0 1 8 0c.789 0 1.575.111 2.325.338.32.097.579.331.707.64l1.02 2.448c.15.36.101.771-.128 1.086l-1.437 1.972c-.21.289-.545.46-.902.46h-3.17c-.361 0-.7-.176-.91-.47L4.091 4.487a1.116 1.116 0 0 1-.122-1.073L4.974.981C5.1.672 5.36.437 5.679.339Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M5.352 5.485a1.116 1.116 0 0 1 1.063-.774h3.17c.485 0 .914.313 1.063.774l.967 3.007c.148.459-.016.961-.407 1.245L8.656 11.59c-.391.284-.92.284-1.312 0L4.792 9.737a1.116 1.116 0 0 1-.407-1.245l.967-3.007Zm1.876 1.458-.47 1.462L8 9.307l1.242-.902-.47-1.462H7.228Z"
        clipRule="evenodd"
      />
    </>
  ),
});
