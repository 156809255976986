import { Box } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import styles from 'services/cms/components/html-content/style.module.scss';

export const Details = () => {
  const { t, i18n } = useTranslation();

  if (i18n.language === 'ja') {
    return (
      <Box className={styles.content}>
        <p>
          他のプレイヤー (および時計) と争って最高点を目指しましょう 乗算可能。
        </p>
        <p>
          賞金を最大限に活用した上位 5 人のプレイヤーに毎日 250
          米ドルをプレゼントします。 乗数。 参加方法は次のとおりです。
        </p>
        <ul>
          <li>参加しているカジノ ゲームに 0.20 米ドル以上のベットをする</li>
          <li>
            セッション中に到達する単一の最大マルチプライヤーは次のとおりです。
            リーダーボードに追加されました
          </li>
          <li>トップ 10 以内に入ると 250 米ドルのシェアを獲得</li>
          <li>
            リーダーボード上のプレイヤーが 1,000
            倍のマルチプライヤーに達した場合、または
            さらに、リーダーボードの報酬はすべてのメンバーに対して即座に 2
            倍になります 参加選手たち。
          </li>
        </ul>
        <p style={{ marginTop: '-4px' }}>
          250
          ドルの賞金プールは、リーダーボードに基づいて勝者の間で分配されます。
          ポジションを取得し、引き出し可能な残高として自動的に分配されます。
        </p>
        {/* <Accordion
          title={t('register-form.terms')}
          options={[
            '最低ベット要件 $0.20 が適用されます。 0.20 ドル未満のベットはトーナメントに貢献しません。',
            '「プレーヤーは、マルチプライヤーがリーダーボードに表示される前に、対象となるベットを少なくとも 20 回行う必要があります。」',
            '「リアルマネーでの賭けのみが対象となります。 ボーナス残高は賭けの対象外となります。',
            'オプトインすると、プレーヤーはこのキャンペーンからの賞金に関する電子メールの受信に同意したことになります。',
            '「プレイヤーが不審または不正行為に関与した場合、シャーベットはKYCプロセスを実施し、プレイヤーのベットをキャンセルし、現在のプロモーションからプレイヤーを削除し、将来のすべてのプロモーションから除外し、賞金をクレジットしない権利を留保します。」 ',
            '「シャーベットが詐欺、不正行為、プロモーションルールの操作、またはプロモーションの悪用を発見した場合、該当するプレイヤーとその関連アカウントは現在および将来のプロモーションから停止されます。」',
            'これらの利用規約は、Sherbet の利用規約に追加されるものです。',
          ]}
        /> */}
      </Box>
    );
  }

  return (
    <Box className={styles.content}>
      <p>
        Scramble against other players (and the clock) to reach the highest
        multiplier possible.
      </p>
      <p>
        We give away $250 USD daily to the top five players who max out their
        multipliers. Here is how to get involved:
      </p>
      <ul>
        <li>
          Place bets of $0.20 USD or more on any participating casino games
        </li>
        <li>
          The single highest multiplier you reach during your session will be
          added to the leaderboard
        </li>
        <li>Finish in the top five to win a share of $250 USD</li>
        <li>
          If any player on the leaderboard reaches a multiplier of 1,000x or
          more, the leaderboard rewards are instantly doubled for all
          participating players.
        </li>
      </ul>
      <p style={{ marginTop: '-4px' }}>
        The $250 prize pool is split amongst winners based on their leaderboard
        position and automatically distributed as withdrawable balance.
      </p>
    </Box>
  );
};
