import {
  IconButton,
  Menu,
  MenuButton,
  MenuButtonProps,
  MenuGroup,
  MenuItem,
  MenuList,
  PlacementWithLogical,
  Text,
} from '@chakra-ui/react';
import { MoreIcon } from 'icons';

type Option = {
  title: React.ReactNode;
  value?: string | number;
  icon?: React.ElementType;
  onClick?: React.MouseEventHandler;
  custom?: React.ReactNode;
};

type Props = {
  buttonSize?: string;
  buttonProps?: MenuButtonProps;
  options: Option[];
  onChange?: (value: number | string) => void;
  placement?: PlacementWithLogical;
  gutter?: number;
};

export const MoreDropdown: React.FC<Props> = ({
  options,
  onChange,
  buttonSize = 'sm',
  buttonProps,
  placement,
  gutter,
  ...rest
}) => {
  const handleChange = (value: string | number | undefined) => () =>
    value && onChange?.(value);

  return (
    <Menu autoSelect={false} placement={placement} gutter={gutter} {...rest}>
      {({ isOpen }) => (
        <>
          <MenuButton
            rounded="full"
            aria-label="More"
            as={IconButton}
            size={buttonSize}
            icon={<MoreIcon />}
            _active={{ tranform: 'none' }}
            {...buttonProps}
          />
          {isOpen && (
            <MenuList sx={{ overflow: 'auto' }}>
              <MenuGroup>
                {options.map(
                  ({ title, icon: Icon, value, onClick, custom }, index) =>
                    custom || (
                      <MenuItem
                        data-group
                        key={index}
                        onClick={onClick || handleChange(value)}
                        height="32px"
                      >
                        {Icon && (
                          <Icon
                            mr="2"
                            _groupFocus={{ color: 'vanilla-text' }}
                          />
                        )}
                        <Text as="span" _groupFocus={{ color: 'vanilla-text' }}>
                          {title}
                        </Text>
                      </MenuItem>
                    ),
                )}
              </MenuGroup>
            </MenuList>
          )}
        </>
      )}
    </Menu>
  );
};
