import { createAction } from 'store';
import * as api from './api';

export const loadPosts = createAction(
  'blog/loadPosts',
  lang => () => api.loadPosts(lang),
);

export const loadPost = createAction(
  'blog/loadPost',
  (slug, lang) => () => api.loadPost(slug, lang),
  slug => ({ slug }),
);
