import { Box, Divider, Text } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { EmailForm } from 'services/user/components/email-form';

export const VerifyEmailForm = ({
  isRounded = true,
  isChat = false,
  isRedeem = false,
}) => {
  const { t } = useTranslation();
  return (
    <Box
      rounded={isRounded ? 'xl' : 'none'}
      borderTop={isChat ? '1px solid' : undefined}
      borderBottom={isRedeem ? '1px solid' : undefined}
      borderColor={isChat || isRedeem ? 'truffle-border' : undefined}
    >
      <Box p="5">
        {isRounded && (
          <Text fontSize={{ base: 'base', md: 'lg' }} fontWeight="500">
            {t('wallet.email')}
          </Text>
        )}
        {isRounded ? (
          <Text color="candy-floss-text">{t('wallet.email-verify-first')}</Text>
        ) : (
          <Text color="candy-floss-text">
            {t('errors.finish-email-verification')}
          </Text>
        )}
      </Box>
      <Divider
        borderColor="truffle-border"
        borderBottomWidth="1px"
        opacity="1"
      />
      <Box p="5">
        <EmailForm />
      </Box>
    </Box>
  );
};
