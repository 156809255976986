import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/ja';
import duration from 'dayjs/plugin/duration';
import isToday from 'dayjs/plugin/isToday';
import relativeTime from 'dayjs/plugin/relativeTime';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import 'focus-visible/dist/focus-visible';
import 'i18n';
import { App } from 'layout/app';
import { createRoot } from 'react-dom/client';
import 'swiper/css'; // eslint-disable-line

dayjs.extend(relativeTime);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(duration);
dayjs.extend(isToday);
dayjs.extend(weekday);

if (process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  console.log(
    '%cBefore you paste that code...',
    'font-size:24px;font-weight:500; color: red;',
  );
  console.log(
    '%cThe console is intended for developers only. Code snippets, scripts, and other tools from untrusted sources could compromise your Sherbet account. Only use the console if you know what you are doing.',
    'font-size: 14px;font-weight:400',
  );
}
const isTelegram = window.location.hash.indexOf('#tgWebAppData') === 0;
const rootElement = document.getElementById('root');

if (rootElement) {
  const renderApp = () => {
    const root = createRoot(rootElement);
    root.render(<App />);
  };
  if (isTelegram) {
    if (window.isTelegramLoaded) {
      renderApp();
    } else {
      const interval = setInterval(() => {
        if (window.isTelegramLoaded) {
          clearInterval(interval);
          renderApp();
        }
      }, 200);
    }
  } else {
    renderApp();
  }
}
