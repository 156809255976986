import { createIcon } from '@chakra-ui/icons';

export const FiveHundredLogo = createIcon({
  displayName: 'FiveHundredLogo',
  viewBox: '0 0 291 197',
  path: (
    <svg
      width="291"
      height="197"
      viewBox="0 0 291 197"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_1539_61)">
        <path
          d="M146.516 83.8921L133.909 113.108H140.773L143.061 107.331H156.367L158.655 113.108H165.706L153.332 83.8921H146.516ZM145.115 102.117L149.737 90.515L154.313 102.117H145.115Z"
          fill="#9494a5"
        />
        <path
          d="M84.9307 109.773C84.2769 98.547 81.6621 89.8106 77.0399 83.5633C72.4172 77.3156 65.367 74.2157 55.9823 74.2157C50.6129 74.2157 45.9904 75.3902 42.0684 77.7856C38.1464 80.1341 35.2983 83.0464 33.4306 86.3813H28.995V25.5528H82.6426V2.06689H2.66138V110.525H27.5943C27.6877 107.613 28.388 105.123 29.742 103.01C31.0961 100.896 33.0104 99.2516 35.4383 98.0775C37.8662 96.903 40.7144 96.3392 43.9828 96.3392C48.6984 96.3392 52.2472 97.5137 54.6282 99.8153C57.0097 102.164 58.3633 105.687 58.5971 110.431C59.1575 118.557 59.3907 126.636 59.2973 134.762C59.2039 142.888 58.8773 151.25 58.3633 159.939C58.0367 165.012 56.6361 168.77 54.1147 171.307C51.5934 173.843 47.7179 175.111 42.4419 175.111C37.1659 175.111 33.2905 173.843 30.816 171.307C28.3413 168.77 26.8939 164.965 26.5204 159.939C26.3336 157.121 26.2402 153.974 26.2402 150.498C26.1936 147.022 26.2402 143.452 26.2869 139.789C26.3803 136.125 26.4737 132.789 26.6604 129.736H0.373527C0.140072 134.246 0 138.567 0 142.701C0 146.834 0.0466908 151.484 0.140072 156.604C0.560288 171.072 4.10879 181.406 10.7856 187.653C17.509 193.9 28.2946 197 43.2357 197C52.7137 197 60.3712 195.638 66.1143 192.961C71.8573 190.283 76.1993 185.915 79.0473 179.949C81.9423 173.984 83.8099 166.187 84.697 156.651C85.0241 151.813 85.2109 146.881 85.3508 141.855C85.4911 136.782 85.4911 131.615 85.3977 126.308C85.3508 120.859 85.164 115.41 84.9307 109.773Z"
          fill="#9494a5"
        />
        <path
          d="M243.213 103.244L227.011 83.8921H221.081V113.108H227.338V93.8031L243.446 113.108H249.469V83.8921H243.213V103.244Z"
          fill="#9494a5"
        />
        <path
          d="M230.419 67.7333C230.56 56.8828 230.746 46.1264 230.886 35.4168C231.12 26.3512 236.162 21.795 245.968 21.795C255.772 21.795 260.815 26.3512 261.048 35.4168C261.515 47.5356 261.796 60.3589 261.936 73.8398H287.896C287.896 72.2427 287.896 70.5987 287.896 68.9547C287.803 59.1375 287.616 49.4615 287.289 39.973C286.962 27.0558 283.273 17.1447 276.13 10.2868C269.033 3.42894 258.994 0 245.968 0C232.894 0 222.809 3.42894 215.712 10.2868C208.661 17.1447 204.973 27.0558 204.646 39.973C204.459 50.9644 204.319 62.2848 204.272 73.7929H230.373C230.373 71.8201 230.373 69.753 230.419 67.7333Z"
          fill="#9494a5"
        />
        <path
          d="M129.054 67.7333C129.194 56.8828 129.381 46.1264 129.521 35.4168C129.754 26.3512 134.797 21.795 144.602 21.795C154.407 21.795 159.449 26.3512 159.683 35.4168C160.15 47.5356 160.43 60.3589 160.57 73.8398H186.53C186.53 72.2427 186.53 70.5987 186.53 68.9547C186.437 59.1375 186.25 49.4615 185.923 39.973C185.596 27.0558 181.908 17.1447 174.764 10.2868C167.667 3.42894 157.628 0 144.602 0C131.528 0 121.443 3.42894 114.346 10.2868C107.296 17.1447 103.607 27.0558 103.28 39.973C103.094 50.9644 102.954 62.2848 102.907 73.7929H129.007C129.007 71.8201 129.007 69.753 129.054 67.7333Z"
          fill="#9494a5"
        />
        <path
          d="M210.856 83.8921H204.272V113.108H210.856V83.8921Z"
          fill="#9494a5"
        />
        <path
          d="M261.048 161.348C260.628 170.508 255.632 175.064 245.968 175.064C236.162 175.064 231.12 170.508 230.886 161.348C230.746 151.813 230.56 141.855 230.419 131.615C230.373 128.844 230.373 125.884 230.373 123.066H204.319C204.366 134.528 204.506 145.801 204.693 156.792C205.02 169.663 208.708 179.527 215.758 186.478C222.809 193.43 232.894 196.906 246.014 196.906C259.041 196.906 269.08 193.43 276.177 186.478C283.273 179.527 287.009 169.616 287.336 156.792C287.662 147.304 287.849 137.721 287.942 127.998C287.942 126.354 287.942 124.71 287.942 123.066H261.889C261.702 135.843 261.469 148.619 261.048 161.348Z"
          fill="#9494a5"
        />
        <path
          d="M288.363 92.5345C287.569 90.7024 286.495 89.1526 285.048 87.7901C283.647 86.4279 281.966 85.3946 280.099 84.6432C278.184 83.8913 276.13 83.5156 273.936 83.5156C271.741 83.5156 269.687 83.8913 267.772 84.6432C265.858 85.3946 264.224 86.4279 262.823 87.7901C261.422 89.1526 260.302 90.7024 259.508 92.5345C258.714 94.3661 258.341 96.3389 258.341 98.4998C258.341 100.613 258.714 102.586 259.508 104.465C260.302 106.297 261.376 107.941 262.823 109.256C264.224 110.619 265.904 111.699 267.772 112.45C269.687 113.202 271.741 113.578 273.936 113.578C276.13 113.578 278.184 113.202 280.099 112.45C282.013 111.699 283.647 110.619 285.048 109.256C286.449 107.894 287.569 106.297 288.363 104.465C289.157 102.634 289.53 100.613 289.53 98.4998C289.577 96.292 289.157 94.3192 288.363 92.5345ZM282.106 102.164C281.64 103.338 281.032 104.324 280.239 105.17C279.445 106.015 278.511 106.72 277.437 107.189C276.364 107.659 275.243 107.941 274.075 107.941C272.862 107.941 271.741 107.706 270.667 107.189C269.593 106.72 268.66 106.015 267.819 105.17C266.978 104.324 266.325 103.291 265.858 102.164C265.391 100.99 265.158 99.7681 265.158 98.406C265.158 97.0439 265.391 95.8225 265.858 94.6949C266.325 93.5678 266.978 92.5345 267.772 91.6888C268.613 90.8435 269.547 90.139 270.621 89.6691C271.694 89.1995 272.862 88.9645 274.075 88.9645C275.243 88.9645 276.41 89.1995 277.437 89.6691C278.511 90.139 279.445 90.7966 280.239 91.6888C281.032 92.5345 281.686 93.5678 282.106 94.6949C282.573 95.8225 282.807 97.0908 282.807 98.406C282.807 99.7212 282.573 101.036 282.106 102.164Z"
          fill="#9494a5"
        />
        <path
          d="M192.6 98.3119C191.666 97.6542 190.639 97.1378 189.565 96.7152C188.491 96.2921 187.37 95.9164 186.25 95.5876C185.129 95.2588 184.149 94.93 183.215 94.6481C182.281 94.3193 181.534 93.8967 180.973 93.3798C180.414 92.8634 180.133 92.2058 180.133 91.407C180.133 90.5618 180.46 89.9511 181.114 89.5285C181.768 89.1054 182.655 88.8709 183.822 88.8709C184.616 88.8709 185.503 88.9647 186.483 89.1997C187.51 89.4342 188.538 89.763 189.658 90.1861C190.779 90.6087 191.853 91.1725 192.833 91.8301L195.355 86.5223C193.907 85.5828 192.226 84.784 190.265 84.2207C188.304 83.61 186.343 83.3281 184.335 83.3281C182.048 83.3281 180.086 83.7038 178.406 84.4083C176.725 85.1129 175.417 86.0993 174.484 87.4145C173.55 88.7297 173.083 90.233 173.083 92.0177C173.083 93.521 173.363 94.7424 173.923 95.7288C174.484 96.7152 175.231 97.5135 176.164 98.1242C177.098 98.7818 178.079 99.2983 179.153 99.6744C180.227 100.05 181.347 100.426 182.468 100.708C183.588 100.99 184.569 101.318 185.503 101.647C186.436 101.976 187.184 102.399 187.744 102.915C188.304 103.432 188.585 104.09 188.585 104.935C188.585 105.922 188.211 106.673 187.464 107.19C186.717 107.706 185.643 107.941 184.289 107.941C183.308 107.941 182.188 107.753 180.927 107.378C179.713 107.002 178.499 106.532 177.285 105.874C176.071 105.217 174.997 104.418 173.97 103.526L171.355 108.74C172.429 109.726 173.643 110.572 175.044 111.229C176.445 111.934 177.892 112.45 179.48 112.873C181.02 113.249 182.608 113.437 184.196 113.437C186.343 113.437 188.304 113.108 189.985 112.403C191.713 111.699 193.066 110.713 194.047 109.397C195.074 108.082 195.541 106.485 195.541 104.559C195.541 103.009 195.261 101.741 194.748 100.755C194.281 99.8151 193.533 98.9695 192.6 98.3119Z"
          fill="#9494a5"
        />
        <path
          d="M110.797 112.403C112.619 113.155 114.626 113.531 116.821 113.531C118.221 113.531 119.622 113.343 121.069 112.873C122.47 112.45 123.824 111.84 125.132 111.041C126.439 110.243 127.56 109.35 128.586 108.27L124.712 104.183C123.638 105.217 122.47 106.062 121.116 106.72C119.762 107.331 118.455 107.659 117.147 107.659C115.887 107.659 114.766 107.424 113.692 106.955C112.619 106.485 111.685 105.827 110.891 104.982C110.097 104.136 109.443 103.15 109.023 102.023C108.603 100.895 108.37 99.6743 108.37 98.3591C108.37 97.0439 108.603 95.7756 109.023 94.648C109.443 93.5209 110.097 92.5345 110.891 91.6888C111.685 90.8436 112.619 90.186 113.692 89.716C114.766 89.2465 115.934 89.0115 117.147 89.0115C118.501 89.0115 119.856 89.3403 121.21 90.0448C122.564 90.7024 123.731 91.6419 124.712 92.8633L128.54 88.307C127.606 87.3206 126.486 86.428 125.271 85.7235C124.011 85.019 122.704 84.4552 121.303 84.0325C119.902 83.6568 118.501 83.4688 117.054 83.4688C114.86 83.4688 112.852 83.8445 110.984 84.5963C109.117 85.3478 107.482 86.428 106.082 87.7432C104.681 89.1053 103.56 90.7024 102.813 92.4876C102.02 94.2723 101.646 96.2451 101.646 98.3591C101.646 100.52 102.02 102.492 102.813 104.371C103.607 106.203 104.681 107.847 106.035 109.162C107.343 110.572 108.976 111.652 110.797 112.403Z"
          fill="#9494a5"
        />
        <path
          d="M159.73 161.348C159.309 170.508 154.313 175.064 144.648 175.064C134.844 175.064 129.801 170.508 129.567 161.348C129.427 151.813 129.24 141.855 129.1 131.615C129.054 128.844 129.054 125.884 129.054 123.066H102.907C102.954 134.528 103.094 145.801 103.28 156.792C103.607 169.663 107.296 179.527 114.346 186.478C121.397 193.43 131.482 196.906 144.602 196.906C157.628 196.906 167.667 193.43 174.764 186.478C181.861 179.527 185.596 169.616 185.923 156.792C186.25 147.304 186.437 137.721 186.53 127.998C186.53 126.354 186.53 124.71 186.53 123.066H160.476C160.337 135.843 160.15 148.619 159.73 161.348Z"
          fill="#9494a5"
        />
      </g>
      <defs>
        <clipPath id="clip0_1539_61">
          <rect width="291" height="197" fill="white" />
        </clipPath>
      </defs>
    </svg>
  ),
});
