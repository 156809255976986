import { createIcon } from '@chakra-ui/icons';

export const RedeemIcon = createIcon({
  displayName: 'RedeemIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M6.444.168a2.206 2.206 0 0 0-2.882 1.194L3.037 2.63a.752.752 0 0 1-.407.406l-1.268.525A2.205 2.205 0 0 0 .168 6.443l.525 1.27a.752.752 0 0 1 0 .576L.168 9.557a2.206 2.206 0 0 0 1.194 2.88l1.269.526a.75.75 0 0 1 .406.407l.526 1.27a2.206 2.206 0 0 0 2.88 1.193l1.27-.526a.75.75 0 0 1 .575 0l1.267.525a2.205 2.205 0 0 0 2.882-1.194l.526-1.269a.752.752 0 0 1 .407-.405l1.268-.526a2.204 2.204 0 0 0 1.194-2.882l-.525-1.268v-.001a.749.749 0 0 1 0-.575l.525-1.268a2.205 2.205 0 0 0-1.194-2.882l-1.268-.525a.75.75 0 0 1-.42-.437l-.512-1.238A2.206 2.206 0 0 0 9.557.17l-1.27.523a.751.751 0 0 1-.575.001L6.444.168Zm5.263 5.54a1 1 0 0 0-1.414-1.415l-6 6a1 1 0 1 0 1.414 1.414l6-6ZM5.75 4.374a1.375 1.375 0 1 0 0 2.75 1.375 1.375 0 0 0 0-2.75Zm4.5 4.5a1.375 1.375 0 1 0 0 2.75 1.375 1.375 0 0 0 0-2.75Z"
      clipRule="evenodd"
    />
  ),
});
