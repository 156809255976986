import { Box, Flex, Text } from '@chakra-ui/react';
import { PlayerRow } from 'components/player-row';
import { formatCredit } from 'helpers/numeral';
import { CalendarDayIcon, RafflesIcon } from 'icons';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { EmptyResults } from 'uikit/empty-results';
import { Table } from 'uikit/table';

const prizePoolPercentagesWeekly = [0.4, 0.2, 0.1];
const prizePoolPercentagesDaily = [0.6, 0.1, 0.1, 0.1, 0.1];

export const RaceTable = ({
  entries,
  isMultiplier,
  currentPrizePool,
  maxWinnersPerRound,
  overlayMessage = null,
  feType = undefined,
}) => {
  const { t } = useTranslation();
  const fields = [
    {
      key: 'place',
      title: t('race.place'),
      textAlign: 'left',
      display: { base: 'none', md: 'table-cell' },
    },
    {
      key: 'player',
      title: t('race.player'),
      width: 'full',
      minWidth: '120px',
      textAlign: 'left',
    },
    {
      key: 'score',
      title: isMultiplier ? t('race.multiplier') : t('race.points'),
      textAlign: 'right',
      minWidth: '120px',
    },
    { key: 'prize', title: t('race.prize'), textAlign: 'right' },
  ];

  const prizePoolPercentages = isMultiplier
    ? prizePoolPercentagesDaily
    : prizePoolPercentagesWeekly;

  const downButNotOutPrizes = [
    15000, // 1st Place
    10000, // 2nd Place
    7500, // 3rd Place
    5000, // 4th Place
    4000, // 5th Place
    3000, // 6th Place
    2500, // 7th Place
    1500, // 8th Place
    1000, // 9th Place
    500, // 10th Place
  ];

  const data = useMemo(() => {
    if (overlayMessage) {
      return Array.from({ length: 10 }, (_, index) => ({
        place: (
          <Text align="start" color="candy-floss-text">
            {t('number.ordinal', {
              count: index + 1,
              ordinal: true,
            })}
          </Text>
        ),
        player: (
          <PlayerRow
            user={{
              name: `Player${index + 1}`,
              icon: 'https://example.com/avatar.png',
            }}
            hasAvatar
            isProfileAvailable={false}
          />
        ),
        score: '1000',
        prize: <Text color="sherbet-green.200">$0</Text>,
      }));
    }

    const listOfData = [];
    for (let i = 0; i < Math.max(maxWinnersPerRound, entries.length); i += 1) {
      const item = entries[i];

      if (item) {
        const { user, score, prize, rank } = item;

        let calculatedPrize;
        if (feType === 'DOWNBUTNOTOUT') {
          calculatedPrize = formatCredit(downButNotOutPrizes[i] || 0);
        } else {
          calculatedPrize =
            isMultiplier || prize === '0'
              ? formatCredit(prize)
              : formatCredit(
                  currentPrizePool * (prizePoolPercentages[i] || 0.042857),
                );
        }

        listOfData[i] = {
          place: (
            <Text align="start" color="candy-floss-text">
              {t('number.ordinal', {
                count: rank,
                ordinal: true,
              })}
            </Text>
          ),
          player: (
            <PlayerRow
              user={user}
              hasAvatar={!!user?.icon}
              isProfileAvailable={false}
            />
          ),
          score: isMultiplier
            ? `${formatCredit(score, {
                minimumFractionDigits: 0,
              })}x`
            : formatCredit(score),
          prize: <Text color="sherbet-green.200">${calculatedPrize}</Text>,
        };
      } else {
        let calculatedPrize;
        if (feType === 'DOWNBUTNOTOUT') {
          calculatedPrize = formatCredit(downButNotOutPrizes[i] || 0);
        } else {
          calculatedPrize = formatCredit(
            currentPrizePool * (prizePoolPercentages[i] || 0.042857),
          );
        }

        listOfData[i] = {
          place: (
            <Text align="start" color="candy-floss-text">
              {t('number.ordinal', {
                count: i + 1,
                ordinal: true,
              })}
            </Text>
          ),
          player: '-',
          score: '-',
          prize: <Text color="candy-floss-text">${calculatedPrize}</Text>,
        };
      }
    }

    return listOfData;
  }, [
    entries,
    t,
    isMultiplier,
    currentPrizePool,
    maxWinnersPerRound,
    prizePoolPercentages,
    feType,
    downButNotOutPrizes,
    overlayMessage,
  ]);

  if (overlayMessage) {
    return (
      <Box position="relative" overflow="hidden" rounded="xl">
        <Box filter="blur(4px)" overflow="hidden" rounded="xl">
          <Table fields={fields} data={data} />
        </Box>
        <Flex
          position="absolute"
          top="0"
          left="0"
          flexDir="column"
          width="100%"
          height="100%"
          justifyContent="center"
          alignItems="center"
          pointerEvents="none"
        >
          <EmptyResults
            icon={CalendarDayIcon}
            title="Coming Soon"
            description={overlayMessage}
          />
        </Flex>
      </Box>
    );
  }

  if (!data.length) {
    return (
      <EmptyResults
        icon={RafflesIcon}
        title={t('race.empty')}
        description={t('race.empty-description')}
      />
    );
  }

  return <Table fields={fields} data={data} />;
};
