export const formatCredit = (
  amount: number | string | null | undefined,
  { forceFraction = false, minimumFractionDigits = 2 } = {},
) => {
  if (amount === null || amount === undefined) {
    return '';
  }
  const numberAmount = Number(amount);
  if (numberAmount % 1 !== 0 || forceFraction) {
    return numberAmount.toLocaleString(undefined, {
      minimumFractionDigits,
      maximumFractionDigits: 2,
    });
  }
  return numberAmount.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });
};

export const toNumber = (value: string | number) => {
  if (typeof value === 'string') {
    return Number(value?.replace(',', '.'));
  }
  return value;
};

export const toFixedNumber = (value: string | number) =>
  Number((toNumber(value) || 0).toFixed(2));

export const getRandomFloat = (min: number, max: number) =>
  Math.random() * (max - min) + min;

export const getRandomNumber = (from: number, to: number) =>
  from + Math.floor(Math.random() * to);

export const formatLargeNumber = (num: number) => {
  const suffixes = ['', 'K', 'M', 'B', 'T'];

  if (num < 1000) {
    return formatCredit(num);
  }

  const numStr = Math.round(num).toString();

  const magnitude = Math.floor((numStr.length - 1) / 3) * 3;
  const index = magnitude / 3;

  let formattedNum = (num / 10 ** magnitude).toFixed(1);

  // Remove unnecessary decimal part if it's .0
  formattedNum = formattedNum.replace(/\.0$/, '');

  // Handle case where the result is less than 1
  if (formattedNum.endsWith('.')) {
    formattedNum = formattedNum.slice(0, -1);
  }

  // Append the suffix
  formattedNum += suffixes[index];

  return formattedNum;
};
