import { createIcon } from '@chakra-ui/icons';

export const DeleteIcon = createIcon({
  displayName: 'DeleteIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8.61 0H7.39c-.383 0-.714 0-.986.022a2.221 2.221 0 0 0-.85.216c-.41.209-.743.543-.953.953-.14.275-.192.561-.215.849-.02.244-.022.535-.022.87H1.727c-.401 0-1 .59-1 1.09 0 .5.599 1 1 1h.455v6.812c0 .585 0 1.069.032 1.462.033.409.105.785.285 1.138.279.547.724.992 1.271 1.27.353.18.729.252 1.138.286C5.3 16 5.785 16 6.37 16h3.26c.585 0 1.069 0 1.462-.032.409-.034.785-.105 1.138-.285a2.909 2.909 0 0 0 1.271-1.271c.18-.353.252-.73.285-1.138.032-.393.032-.877.032-1.462V5h.41c.4 0 1.045-.598 1.045-1s-.644-1.09-1.046-1.09h-2.59c0-.335-.003-.626-.023-.87a2.222 2.222 0 0 0-.215-.849 2.182 2.182 0 0 0-.954-.953 2.222 2.222 0 0 0-.849-.216A13.02 13.02 0 0 0 8.61 0ZM7.477 7a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0V7Zm3 0a1 1 0 1 0-2 0v4a1 1 0 1 0 2 0V7Z"
      clipRule="evenodd"
    />
  ),
});
