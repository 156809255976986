import { createIcon } from '@chakra-ui/icons';

export const PlusIcon = createIcon({
  displayName: 'PlusIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16ZM9 5a1 1 0 0 0-2 0v2H5a1 1 0 0 0 0 2h2v2a1 1 0 1 0 2 0V9h2a1 1 0 1 0 0-2H9V5Z"
      clipRule="evenodd"
    />
  ),
});
