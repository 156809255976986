import { WS_HANDLER_MESSAGE } from 'helpers/ws';
import { userWebsocket } from 'services/user/modules/user';
import { createReducer } from 'store';
import { loadGames, loadVersusHistory, setFinished } from './actions';
import { VersusType } from './types';

const initialState: VersusType = {
  games: {},
  gamesLoaded: false,
  users: {},
  history: [],
  totalCount: 0,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const versusActionResolver = (state: VersusType, { payload }: any) => {
  if (state.games === undefined) {
    state.games = {};
  }

  const { data } = payload;

  state.games[data.gameId] = { ...data };

  if (data.time !== undefined) {
    state.games[data.gameId].time = new Date().getTime() + data.time;
  }

  state.users = {
    ...state.users,
    ...payload.users,
  };
};

export const reducer = createReducer(initialState, {
  [loadGames.toString()]: (state, { payload }) => {
    state.gamesLoaded = true;
    if (payload) {
      state.games = {};

      for (let i = 0; i < payload.data.length; i += 1) {
        const game = payload.data[i];
        state.games[game.gameId] = game;

        if (game.time !== undefined) {
          state.games[game.gameId].time = new Date().getTime() + game.time;
        }
      }

      state.users = {
        ...state.users,
        ...payload.users,
      };
    }
  },

  [WS_HANDLER_MESSAGE(userWebsocket, 'VERSUS', 'NEWGAME')]: (
    state,
    { payload },
  ) => {
    if (state.games === undefined) {
      state.games = {};
    }

    const time = new Date().getTime();

    const { data } = payload;

    state.games[data.gameId] = { ...data };

    if (data.time !== undefined) {
      state.games[data.gameId].time = time + data.time;
    }

    state.users = {
      ...state.users,
      ...payload.users,
    };

    const games = Object.values(state.games);
    const weight = 0.05;

    for (let i = games.length - 1; i >= 0; i -= 1) {
      const game = games[i];

      if (game.time === undefined) {
        continue;
      }

      const msec = time - game.time;

      if (msec < 9000) {
        continue;
      }

      const value = game.value * game.players;

      const expireTime = Math.max(5000, 120000 / Object.keys(games).length);

      const valueMod = 1 + Math.min(3, value * weight);

      let modExpireTime = expireTime * valueMod - msec;
      if (modExpireTime < 0) {
        modExpireTime = 0;
      }

      if (msec > modExpireTime + 9000) {
        delete state.games[game.gameId];
      }
    }
  },

  [WS_HANDLER_MESSAGE(userWebsocket, 'VERSUS', 'GAMEOVER')]:
    versusActionResolver,

  [WS_HANDLER_MESSAGE(userWebsocket, 'VERSUS', 'WAGER')]: versusActionResolver,

  [WS_HANDLER_MESSAGE(userWebsocket, 'VERSUS', 'LEAVE')]: (
    state,
    { payload },
  ) => {
    if (state.games === undefined) {
      state.games = {};
    }

    if (!payload.game) {
      delete state.games[payload.gameId];
      return;
    }

    const { data } = payload.game;

    if (data.wagers.length === 0) {
      delete state.games[data.gameId];
    } else {
      state.games[data.gameId] = { ...data };

      if (data.time !== undefined) {
        state.games[data.gameId].time = new Date().getTime() + data.time;
      }
      state.users = {
        ...state.users,
        ...payload.users,
      };
    }
  },
  [loadVersusHistory.toString()]: (state, { payload }) => {
    state.totalCount = payload.totalCount;
    state.history = payload.data;
    state.users = {
      ...state.users,
      ...payload.users,
    };
  },
  [setFinished.toString()]: (state, { payload: gameId }) => {
    const game = state.games[gameId];
    if (game) {
      delete state.games[gameId];
      state.history.unshift(game);
    }
  },
});
