import { createContext, useCallback, useContext } from 'react';
import { useSearchParams } from 'react-router-dom';

export type PageModalType = {
  name: string;
};

type SetModalArgsType = {
  type: string;
  id: string;
  isOpen: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  contentProps: any;
  onClose: () => void;
};

export const ModalContext = createContext<
  | {
      setModal: (args: SetModalArgsType) => void;
      removeModal: (id: string) => void;
      pageModal: PageModalType;
    }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  | any
>({});

type UseModalParamsType = {
  history?: boolean;
  id?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
};

export const useModal = (
  type: string,
  { history = true, id = '', ...staticContentProps }: UseModalParamsType = {},
) => {
  const modalId = type + id;
  const [, setSearchParams] = useSearchParams();

  const { setModal, getModal, removeModal } = useContext(ModalContext);

  const onClose = useCallback(() => {
    if (history) {
      setSearchParams('');
    } else {
      removeModal(modalId);
    }
  }, [removeModal, modalId, setSearchParams, history]);

  const onOpen = useCallback(
    (contentProps = {}) => {
      if (history) {
        setSearchParams({
          modal: type,
          ...staticContentProps,
          ...contentProps,
          ...(id && { id }),
        });
      } else {
        setModal({
          type,
          id: modalId,
          isOpen: true,
          contentProps: {
            ...staticContentProps,
            ...contentProps,
            id,
          },
          onClose,
        });
      }
    },
    [
      setSearchParams,
      staticContentProps,
      history,
      id,
      setModal,
      modalId,
      onClose,
      type,
    ],
  );

  return { onOpen, onClose, modal: getModal(modalId) };
};
