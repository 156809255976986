import { createIcon } from '@chakra-ui/icons';

export const OriginalsIcon = createIcon({
  displayName: 'OriginalsIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M7.297.35 5.439 1.896a1.5 1.5 0 0 1-.806.34l-2.892.3A1.5 1.5 0 0 0 .446 4.411l.844 3.207a1.5 1.5 0 0 1 0 .764l-.844 3.207a1.5 1.5 0 0 0 1.295 1.874l2.892.3a1.5 1.5 0 0 1 .806.34l1.858 1.548a1.5 1.5 0 0 0 1.92 0l1.865-1.554a1.5 1.5 0 0 1 .789-.338l2.563-.296a1.5 1.5 0 0 0 1.308-1.736l-.578-3.481a1.499 1.499 0 0 1 0-.492l.578-3.481a1.5 1.5 0 0 0-1.308-1.736l-2.563-.296a1.5 1.5 0 0 1-.789-.338L9.217.35a1.5 1.5 0 0 0-1.92 0Zm3.898 8.767v.079h-.001c-.032 1.251-.833 1.973-2.125 1.973H6.21a.85.85 0 1 1 0-1.699h2.545c.194 0 .338-.11.351-.314-.01-.162-.103-.265-.24-.3H7.478c-1.291 0-2.092-.721-2.125-1.973h-.001v-.079h.001c.033-1.251.834-1.973 2.125-1.973h2.86a.85.85 0 0 1 0 1.699H7.794c-.195 0-.339.11-.352.314a.31.31 0 0 0 .24.3h1.386c1.292 0 2.093.721 2.125 1.973h.002Z"
      clipRule="evenodd"
    />
  ),
});
