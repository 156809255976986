import { createIcon } from '@chakra-ui/icons';

export const NFTIcon = createIcon({
  displayName: 'NFTIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M8 0c-.36 0-.72.116-1.021.347l-.854.656-1.07-.141c-.752-.1-1.476.32-1.767 1.02l-.41.996-.995.41A1.677 1.677 0 0 0 .862 5.056l.14 1.069-.655.854a1.675 1.675 0 0 0 0 2.042l.656.854-.141 1.069c-.1.752.32 1.477 1.02 1.767l.001.001.994.41.411.996c.29.7 1.015 1.12 1.768 1.02l1.07-.14.853.655a1.67 1.67 0 0 0 2.042 0l.854-.656 1.07.141c.752.1 1.476-.32 1.767-1.02l.41-.996.995-.41a1.676 1.676 0 0 0 1.021-1.768l-.14-1.069.655-.854a1.672 1.672 0 0 0 0-2.042l-.655-.854.14-1.069c.1-.752-.32-1.477-1.02-1.767l-.002-.001-.993-.41-.411-.996a1.677 1.677 0 0 0-1.768-1.02l-1.07.14-.853-.655A1.67 1.67 0 0 0 8 0Zm-.81 10.92-.465-2.635 3.397 2.65a.999.999 0 0 0 .28.182c.099.054.188.056.312.052l.051-.01a.782.782 0 0 0 .332-.129.761.761 0 0 0 .246-.22.938.938 0 0 0 .123-.307.71.71 0 0 0 .011-.339L10.63 5.36a.709.709 0 0 0-.126-.316.86.86 0 0 0-.218-.227.692.692 0 0 0-.289-.127.71.71 0 0 0-.339-.011 1.129 1.129 0 0 0-.315.126.94.94 0 0 0-.245.221.73.73 0 0 0-.144.292.71.71 0 0 0-.011.34l.455 2.582-3.336-2.608a.896.896 0 0 0-.726-.191.783.783 0 0 0-.333.13.817.817 0 0 0-.242.237.94.94 0 0 0-.123.306.758.758 0 0 0-.012.34l.841 4.77a.71.71 0 0 0 .127.315c.07.094.14.188.238.242a.938.938 0 0 0 .306.124.708.708 0 0 0 .34.01 1.13 1.13 0 0 0 .314-.126.9.9 0 0 0 .245-.22 1 1 0 0 0 .141-.31.71.71 0 0 0 .011-.339Z"
      clipRule="evenodd"
    />
  ),
});
