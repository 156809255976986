import { Box, Flex, Text } from '@chakra-ui/react';
import dayjs from 'dayjs';
import { applyLangToUrl } from 'helpers/lang';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { matchPath } from 'react-router';
import { getSlotsGamesData } from 'services/games/modules/slots';
import { Link } from 'uikit/link';
import { UserAvatar } from 'uikit/user-avatar';

type Props = {
  avatar: string;
  username: string;
  status: string;
  route?: string;
  online: boolean;
  onMessage: () => void;
  lastUpdatedAt: number;
};

export const Friend: React.FC<Props> = ({
  avatar,
  username,
  status,
  route,
  online,
  lastUpdatedAt,
}) => {
  const slots = useSelector(getSlotsGamesData);

  const { t, i18n } = useTranslation();

  const routeToText = () => {
    if (!route) {
      return t('friends.online');
    }
    if (route.startsWith('/casino/quests')) {
      return t('friends.route12');
    }
    if (route.startsWith('/casino')) {
      const result = matchPath(
        { path: applyLangToUrl('/casino/game/:gameId', i18n.language) },
        route,
      );
      const gameId = result?.params?.gameId;
      const game = gameId && slots[gameId];
      if (game) {
        return (
          <>
            Playing{' '}
            <Text as="span" color="candy-floss-text">
              {game.title}
            </Text>
          </>
        );
      }
      return 'Exploring the casino';
    }
    if (route === '/nft') {
      return t('friends.route1');
    }
    if (route === '/nft/staking') {
      return t('friends.route2');
    }
    if (route.startsWith('/user')) {
      return t('friends.route3');
    }
    if (route === '/sports') {
      return t('friends.route4');
    }
    if (route.startsWith('/tournaments')) {
      return t('friends.route5');
    }
    if (route.startsWith('/policies')) {
      return t('friends.route6');
    }
    if (route === '/friends') {
      return t('friends.route7');
    }
    if (route === '/vip') {
      return t('friends.route8');
    }
    if (route.startsWith('/promotions')) {
      return t('friends.route9');
    }
    if (route.startsWith('/blog')) {
      return t('friends.route10');
    }
    if (route === '/') {
      return t('friends.route11');
    }
    return route.split('/')[1];
  };

  const routeText = routeToText();

  const isFriend = status === 'friends';

  let friendText;

  if (isFriend) {
    if (username.includes('Sherbot')) {
      friendText = t('friends.explore');
    } else if (online) {
      friendText = routeText;
    } else if (lastUpdatedAt) {
      friendText = dayjs(lastUpdatedAt).fromNow();
    } else {
      friendText = t('time.while-ago');
    }
  }
  return (
    <Link to={`?modal=profile&username=${username}`} aria-label="View Profile">
      <Flex
        gap="3"
        justifyContent="space-between"
        align="center"
        _hover={{
          zIndex: '1',
          bg: 'sugar-dust',
        }}
        py="2"
        px="5"
        mx="0"
        borderLeft={
          isFriend && online
            ? `1px solid var(--colors-sherbet-green-200)`
            : undefined
        }
      >
        <Flex w="full" align="center" justifyContent="space-between" gap="5">
          <Box>
            <Flex align="center" gap="2">
              <Flex align="center" gap="1">
                <Text color="vanilla-text" fontWeight="500" noOfLines={1}>
                  {username}
                </Text>
              </Flex>
            </Flex>
            <Text color="candy-floss-text" noOfLines={1}>
              {friendText}
              {status === 'request' && t('friends.added')}
              {status === 'pending' && t('friends.request')}
              {!status && t('friends.not-friend')}
            </Text>
          </Box>
          <Box position="relative">
            <UserAvatar mr="3" icon={avatar} name={username} size="sm" />
          </Box>
        </Flex>
      </Flex>
    </Link>
  );
};
