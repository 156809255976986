export const jsonParse = (
  json: string,
  defaultValue: object | [] | string | number | null | undefined,
) => {
  try {
    return JSON.parse(json);
  } catch (e) {
    return defaultValue;
  }
};
