import { createAction } from 'store';
import * as api from './api';

export const loadPromos = createAction(
  'promo/loadPromos',
  lang => () => api.loadPromos(lang),
);

export const loadPromo = createAction(
  'promo/loadPromo',
  (slug, lang) => () => api.loadPromo(slug, lang),
  slug => ({
    slug,
  }),
);
