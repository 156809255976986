import { LANGS } from 'constants/general';
import { getPathnameWithoutLang, removeTrailingSlash } from 'helpers/lang';
import { memo } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router';

export const Meta = memo(() => {
  const location = useLocation();
  const { i18n } = useTranslation();

  const pathname = removeTrailingSlash(location.pathname);

  const path = getPathnameWithoutLang(pathname);

  return (
    <Helmet>
      <html lang={i18n.language} />
      <link
        rel="alternate"
        href={`https://sherbet.com${path}`}
        hrefLang="x-default"
      />
      <link rel="alternate" href={`https://sherbet.com${path}`} hrefLang="en" />
      {LANGS.slice(1).map(lang => (
        <link
          key={lang}
          rel="alternate"
          href={`https://sherbet.com/${lang}${path}`}
          hrefLang={lang}
        />
      ))}
      <link rel="canonical" href={`https://sherbet.com${pathname}`} />
    </Helmet>
  );
});
