import { createIcon } from '@chakra-ui/icons';

export const TableGamesIcon = createIcon({
  displayName: 'TableGamesIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path
        d="M0 13C0 11.3431 1.34315 10 3 10H13C14.6569 10 16 11.3431 16 13C16 14.6569 14.6569 16 13 16H3C1.34315 16 0 14.6569 0 13Z"
        fill="currentColor"
      />
      <path
        d="M9 5C9 4.44772 9.44772 4 10 4C10.5523 4 11 4.44772 11 5V10C11 10.5523 10.5523 11 10 11C9.44772 11 9 10.5523 9 10V5Z"
        fill="currentColor"
      />
      <path
        d="M14 4C14 6.20914 12.2091 8 10 8C7.79086 8 6 6.20914 6 4C6 1.79086 7.79086 0 10 0C12.2091 0 14 1.79086 14 4Z"
        fill="currentColor"
      />
      <path
        d="M1 7C1 6.44772 1.44772 6 2 6H4C4.55228 6 5 6.44772 5 7C5 7.55228 4.55228 8 4 8H2C1.44772 8 1 7.55228 1 7Z"
        fill="currentColor"
      />
    </>
  ),
});
