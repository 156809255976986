import { PreviewSlider } from 'components/preview-slider';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { NftPreview } from 'services/nft/components/nft-preview';
import {
  getIsRandomNftsLoaded,
  getRandomNfts,
  loadRandomNfts,
} from 'services/nft/modules/nft';
import { useActions } from 'store';

type Props = {
  collectionName: string;
};

export const MoreNFTsSlider: React.FC<Props> = ({ collectionName }) => {
  const randomNfts = useSelector(getRandomNfts);
  const isLoaded = useSelector(getIsRandomNftsLoaded);
  const actions = useActions({ loadRandomNfts });
  const { t } = useTranslation();

  useEffect(() => {
    if (!isLoaded) {
      actions.loadRandomNfts();
    }
  }, [isLoaded, actions]);

  return (
    <PreviewSlider
      isLoaded={isLoaded}
      title={t('nft.more-from', { collection: collectionName })}
      titleLink="/nft"
      description={t('nft.expore-more-from-collection')}
      slides={randomNfts}
      SlideComponent={NftPreview}
      size="md"
    />
  );
};
