import {
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { CustomInput } from 'forms/custom-input';
import { formatCredit, toNumber } from 'helpers/numeral';
import { useToastError } from 'hooks/use-toast-error';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { KYCRequiredForm } from 'services/user/components/kyc-required-form';
import { VerifyEmailForm } from 'services/user/components/verify-email-form';
import { sendTip } from 'services/user/modules/tip';
import {
  get2faInfo,
  getUser2FAEnabled,
  getUserEmailConfirmed,
  getUserRequiredVerify,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { toastError, toastSuccess } from 'toasts';
import { Errors, FormSubmit } from 'types';
import * as Yup from 'yup';

type Props = {
  onClose: () => void;
  username?: string;
  tip?: string;
};

export const TipView: React.FC<Props> = ({
  onClose,
  username: defaultUsername = '',
  tip: defaultTip = '',
}) => {
  const { t } = useTranslation();
  const [emailCodeId, setEmailCodeId] = useState(false);
  const is2FAEnabled = useSelector(getUser2FAEnabled);
  const isEmailConfirmed = useSelector(getUserEmailConfirmed);
  const isVerifyRequired = useSelector(getUserRequiredVerify);
  const actions = useActions({ sendTip, get2faInfo });
  const toastErrorCommon = useToastError();

  useEffect(() => {
    actions.get2faInfo();
  }, [actions]);

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      tipu: defaultUsername,
      tip: defaultTip,
      twoFactorCode: '',
      emailCode: '',
    },
    validationSchema: Yup.object({
      tipu: Yup.string()
        .min(3, t('validations.min-characters', { n: 3 }))
        .max(14, t('validations.max-characters', { n: 14 }))
        .required(t('validations.required')),
      tip: Yup.string().required(t('validations.required')),
      ...(is2FAEnabled
        ? {
            twoFactorCode: Yup.string().required(t('validations.required')),
          }
        : {}),
      ...(emailCodeId
        ? {
            emailCode: Yup.string().required(t('validations.required')),
          }
        : {}),
    }),
    onSubmit: async ({ tipu: username, tip, twoFactorCode, emailCode }) => {
      try {
        let result;
        const amount = toNumber(tip);
        if (emailCodeId) {
          result = await actions.sendTip({
            loginCode: emailCode,
            loginCodeId: emailCodeId,
          });
        } else if (is2FAEnabled) {
          result = await actions.sendTip({
            username,
            amount,
            twoFactorCode,
          });
        } else {
          result = await actions.sendTip({
            username,
            amount,
          });
        }

        if (result?.isSentLoginCode) {
          setEmailCodeId(result.loginCodeId);
        } else {
          toastSuccess({
            title: t('tip.sent-success'),
            description: t('tip.send-details', { username, tip }),
            icon: result?.user?.icon,
            name: result?.user?.name,
          });
          onClose();
        }
      } catch (errors) {
        if (
          (errors as Errors)?.global === 'NOT_ENOUGH_BALANCE_BY_WITHDRAW_HOLD'
        ) {
          const { withdrawhold } = errors as Errors;
          toastError({
            title: t('errors.BE.NOT_ENOUGH_BALANCE_BY_WITHDRAW_HOLD'),
            description: t(
              'errors.BE.NOT_ENOUGH_BALANCE_BY_WITHDRAW_HOLD_DESCRIPTION',
              {
                amount: `$${formatCredit(Number(withdrawhold))}`,
              },
            ),
          });
        } else if (errors) {
          toastErrorCommon(errors as Errors);
        }
      }
    },
  });

  if (!isEmailConfirmed) {
    return <VerifyEmailForm />;
  }

  if (isVerifyRequired) {
    return <KYCRequiredForm />;
  }

  return (
    <Box bg="sugar-dust" rounded="xl">
      <FormControl as="form" onSubmit={formik.handleSubmit as FormSubmit}>
        <Flex direction="column">
          <Flex display="column" p="5">
            <Heading
              fontWeight="500"
              fontSize={{ base: 'lg', md: 'xl' }}
              mb="1"
            >
              {t('tip.send-title')}
            </Heading>
            <Text color="candy-floss-text">{t('tip.send-description')}</Text>
          </Flex>
          <Divider
            borderColor="truffle-border"
            borderBottomWidth="1px"
            opacity="1"
          />
          <Flex flexDirection="column" gap="3" p="5">
            {emailCodeId ? (
              <>
                <Text pb="2" color="candy-floss-text">
                  {t('wallet.email-code')}
                </Text>
                <CustomInput
                  key="emailCodeId"
                  label="form.label.verification-code"
                  placeholder="form.label.verification-code"
                  type="text"
                  name="emailCode"
                  required
                  autoComplete="one-time-code"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.emailCode}
                  isInvalid={
                    !!formik.errors.emailCode && formik.touched.emailCode
                  }
                  errorMessage={formik.errors.emailCode}
                />
              </>
            ) : (
              <>
                <CustomInput
                  label="form.label.username"
                  placeholder="form.label.username"
                  type="text"
                  name="tipu"
                  autoComplete="tipu"
                  required
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.tipu}
                  isInvalid={!!formik.errors.tipu && formik.touched.tipu}
                  errorMessage={formik.errors.tipu}
                />
                <CustomInput
                  label="form.label.amount"
                  placeholder="form.label.amount"
                  name="tip"
                  type="text"
                  inputMode="decimal"
                  required
                  autoComplete="transaction-amount"
                  value={formik.values.tip}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  isInvalid={!!formik.errors.tip && formik.touched.tip}
                  errorMessage={formik.errors.tip}
                />
                {is2FAEnabled && (
                  <CustomInput
                    key="2fa"
                    label="form.label.two-factor-code"
                    placeholder="form.label.two-factor-code"
                    type="text"
                    inputMode="numeric"
                    name="twoFactorCode"
                    required
                    autoComplete="one-time-code"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.twoFactorCode}
                    isInvalid={
                      !!formik.errors.twoFactorCode &&
                      formik.touched.twoFactorCode
                    }
                    errorMessage={formik.errors.twoFactorCode}
                  />
                )}
              </>
            )}
          </Flex>
          <Divider
            borderColor="truffle-border"
            borderBottomWidth="1px"
            opacity="1"
          />
          <Flex p="5" align={{ base: 'center', md: 'start' }}>
            <Button
              w={{ base: 'full', md: 'auto' }}
              type="submit"
              colorScheme="purple"
              isLoading={formik.isSubmitting}
            >
              {t('tip.send-tip')}
            </Button>
          </Flex>
        </Flex>
      </FormControl>
    </Box>
  );
};
