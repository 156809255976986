/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Flex,
  FormControl,
  FormControlProps,
  FormErrorMessage,
  FormLabel,
  FormLabelProps,
  Input,
  InputGroup,
  InputLeftElement,
  InputLeftElementProps,
  InputProps,
  InputRightElement,
  InputRightElementProps,
} from '@chakra-ui/react';
import { FormikErrors } from 'formik';
import { useMobile } from 'hooks/use-mobile';
import React from 'react';
import { useTranslation } from 'react-i18next';

export type CustomInputProps = InputProps & {
  label?: string | React.ReactNode;
  name?: string;
  labelProps?: FormLabelProps;
  boxProps?: FormControlProps;
  isInvalid?: boolean;
  errorMessage?:
    | string
    | string[]
    | FormikErrors<any>
    | FormikErrors<any>[]
    | undefined;
  size?: string;
  fontSize?: string;
  leftElement?: React.ReactNode;
  leftElementProps?: InputLeftElementProps;
  rightElement?: React.ReactNode;
  rightElementProps?: InputRightElementProps;
  placeholder?: string;
  required?: boolean;
  disabled?: boolean;
  autoFocus?: boolean;
  labelRightContent?: React.ReactNode;
  readOnly?: boolean;
};

export const CustomInput: React.FC<CustomInputProps> = ({
  label,
  name,
  labelProps,
  boxProps,
  isInvalid,
  errorMessage,
  size,
  fontSize,
  leftElement,
  leftElementProps,
  rightElement,
  rightElementProps,
  placeholder,
  required,
  disabled,
  autoFocus,
  labelRightContent,
  readOnly,
  ...props
}) => {
  const { t } = useTranslation();
  const isMobile = useMobile();

  let labelContent = label && (
    <FormLabel
      htmlFor={name}
      color="candy-floss-text"
      display="inline-flex"
      fontSize="base"
      mb={labelRightContent ? '0' : '2'}
      fontWeight="500"
      textTransform="none"
      {...labelProps}
    >
      {typeof label === 'string' ? t(label) : label}
    </FormLabel>
  );

  if (labelRightContent) {
    labelContent = (
      <Flex justifyContent="space-between" alignItems="center" mb="2">
        {labelContent}
        {labelRightContent}
      </Flex>
    );
  }

  return (
    <FormControl {...boxProps} isInvalid={isInvalid}>
      {labelContent}
      <InputGroup size={size} fontSize={fontSize}>
        {leftElement && (
          <InputLeftElement w="auto" left="4" {...leftElementProps}>
            {leftElement}
          </InputLeftElement>
        )}
        <Input
          rounded="xl"
          bg="transparent"
          id={name}
          name={name}
          variant="secondary"
          placeholder={placeholder && t(placeholder)}
          required={required}
          disabled={disabled}
          readOnly={readOnly}
          type="text"
          autoCorrect="off"
          spellCheck="false"
          autoCapitalize="none"
          _placeholder={{ color: 'candy-floss-text', opacity: '0.5' }}
          autoFocus={isMobile ? undefined : autoFocus}
          {...props}
        />
        {rightElement && (
          <InputRightElement w="auto" right="4" {...rightElementProps}>
            {rightElement}
          </InputRightElement>
        )}
      </InputGroup>
      <FormErrorMessage fontSize="md" color="sherbet-red">
        {errorMessage &&
          t(`errors.BE.${errorMessage}`, t(errorMessage as string))}
      </FormErrorMessage>
    </FormControl>
  );
};
