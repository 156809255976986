import { Button, ButtonProps } from '@chakra-ui/react';
import { SETTINGS_ENUM } from 'constants/general';
import { isFlagChecked } from 'helpers/bit';
import { MouseEvent, MouseEventHandler, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { getUserSettingsFlags } from 'services/user/modules/user';

type Props = ButtonProps & {
  confirmText?: React.ReactNode;
  children: React.ReactNode;
  onClick: MouseEventHandler<HTMLButtonElement>;
  isForce?: boolean;
};

export const ConfirmButton: React.FC<Props> = ({
  confirmText,
  children,
  onClick,
  isForce = false,
  ...props
}) => {
  const { t } = useTranslation();
  const [confirm, setConfirm] = useState(false);

  const settingsFlags = useSelector(getUserSettingsFlags);
  const isBetConfirm =
    isForce || isFlagChecked(settingsFlags, SETTINGS_ENUM.BET_CONFIRM);

  const text = confirmText || t('actions.confirm');

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (!onClick) return;
    if (!isBetConfirm || confirm) onClick(e);
    if (isBetConfirm) {
      setConfirm(!confirm);
    }
  };

  return (
    <Button onClick={handleClick} {...props}>
      {confirm ? text : children}
    </Button>
  );
};
