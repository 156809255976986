import { createIcon } from '@chakra-ui/icons';

export const ArrowLeftIcon = createIcon({
  displayName: 'ArrowLeftIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M12.237.335c.446.446.446 1.17 0 1.616L6.187 8l6.05 6.049a1.143 1.143 0 0 1-1.617 1.616L3.763 8.808a1.143 1.143 0 0 1 0-1.616L10.62.335a1.143 1.143 0 0 1 1.617 0Z"
      clipRule="evenodd"
    />
  ),
});
