import { createIcon } from '@chakra-ui/icons';

export const BlackjackIcon = createIcon({
  displayName: 'BlackjackIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M10.84 0H5.16c-.581 0-1.061 0-1.452.032-.406.034-.78.105-1.13.285a2.9 2.9 0 0 0-1.262 1.271c-.179.353-.25.73-.283 1.138C1 3.12 1 3.603 1 4.188v7.624c0 .585 0 1.069.032 1.462.033.409.104.785.283 1.138.277.547.719.992 1.262 1.27.35.18.724.252 1.13.286.39.032.87.032 1.452.032h5.682c.581 0 1.061 0 1.452-.032.406-.034.78-.105 1.13-.285a2.898 2.898 0 0 0 1.262-1.271c.179-.353.25-.73.283-1.138.032-.393.032-.877.032-1.462V4.188c0-.585 0-1.068-.032-1.462-.033-.409-.104-.785-.283-1.138a2.899 2.899 0 0 0-1.262-1.27c-.35-.18-.724-.252-1.13-.286C11.903 0 11.423 0 10.841 0ZM6.22 6.2c0-.947.78-1.7 1.722-1.7s1.721.753 1.721 1.7v.002c0 .056-.003.113-.009.169.708.208 1.23.853 1.23 1.63 0 .946-.778 1.7-1.72 1.7-.142 0-.28-.017-.412-.05.015.101.031.199.049.293.038.205.08.374.127.503.05.138.089.188.097.196a.5.5 0 0 1-.35.857H7.209a.5.5 0 0 1-.35-.856.61.61 0 0 0 .097-.196c.047-.13.089-.3.127-.504a8.48 8.48 0 0 0 .049-.292 1.722 1.722 0 0 1-.412.049A1.711 1.711 0 0 1 5 8c0-.777.523-1.422 1.23-1.63a1.652 1.652 0 0 1-.01-.17Zm.5 3.5v-.5l-.001.5Z"
      clipRule="evenodd"
    />
  ),
});
