import { Box, Flex, GridItem, Text } from '@chakra-ui/react';
import { CheckIcon } from 'icons';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { get2FA, get2faInfo } from 'services/user/modules/user';
import { useActions } from 'store';
import { ResetTwoFactorForm } from './components/reset-two-factor-form';
import { SecuritySectionPasswordForm } from './components/security-section-password-form';
import { TwoFactorForm } from './components/two-factor-form';

export const SecuritySection = () => {
  const { t } = useTranslation();

  const actions = useActions({
    get2faInfo,
  });

  const twoFA = useSelector(get2FA);

  useEffect(() => {
    if (!twoFA) {
      actions.get2faInfo();
    }
  }, [twoFA, actions]);

  return (
    <Box flexDirection="column" gap="5">
      <Box bg="sugar-dust" p="5" rounded="xl">
        <Text fontWeight="500" mb="5">
          {t('form.label.password')}
        </Text>
        <SecuritySectionPasswordForm />
      </Box>
      <GridItem color="vanilla-text" fontWeight="500" mt="5" />
      <GridItem bg="sugar-dust" p="5" rounded="xl">
        <Flex gap="2" align="center" mb="5">
          <Text fontWeight="500">2FA</Text>
          {twoFA?.is2faEnabled && <CheckIcon color="sherbet-green.200" />}
        </Flex>
        {twoFA?.is2faEnabled ? <ResetTwoFactorForm /> : <TwoFactorForm />}
      </GridItem>
    </Box>
  );
};
