import { createReducer } from 'store';
import { PostType } from 'types';
import { loadPost, loadPosts } from './actions';
import { BlogType } from './types';

const initialState: BlogType = {
  posts: [],
  data: {},
  isLoaded: false,
};

export const reducer = createReducer(initialState, {
  [loadPosts.toString()]: (state, { payload }: { payload: PostType[] }) => {
    state.posts = payload.sort(
      (a, b) =>
        new Date(b.publishedAt).getTime() - new Date(a.publishedAt).getTime(),
    );
    state.isLoaded = true;
  },
  [loadPost.toString()]: (state, { payload, meta: { slug } }) => {
    const [post] = payload;
    state.data[slug] = post || null;
  },
});
