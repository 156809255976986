export type GridItemType = {
  pixels: number;
  count: number;
};

export const generateBreakpoints = (
  grid: GridItemType[],
  gap: number,
  touchRatio?: number,
) =>
  grid.reduce(
    (acc, { pixels, count }) => ({
      ...acc,
      [pixels + gap]: {
        slidesPerView: count,
        // slidesPerGroup: Math.floor(count),
        touchRatio,
      },
    }),
    {},
  );
