import {
  Box,
  Button,
  Flex,
  FormControl,
  Heading,
  Text,
} from '@chakra-ui/react';
import { useFormik } from 'formik';
import { CustomInput } from 'forms/custom-input';
import { FormGlobalError } from 'forms/form-global-error';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Result } from 'services/auth/components/result';
import { forgotPassword } from 'services/auth/modules/auth';
import { useActions } from 'store';
import { Errors, FormSubmit } from 'types';
import * as Yup from 'yup';

export const ResetPasswordRequestForm = () => {
  const actions = useActions({ forgotPassword });
  const { t } = useTranslation();
  const [sentEmail, setSentEmail] = useState<string>('');

  const formik = useFormik({
    validateOnBlur: false,
    validateOnChange: false,
    initialValues: {
      email: '',
      global: '',
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('errors.email.format')
        .required('errors.email.required'),
    }),
    onSubmit: async ({ email }, { setErrors }) => {
      try {
        await actions.forgotPassword(email);
        setSentEmail(email);
      } catch (errors) {
        setErrors(errors as Errors);
      }
    },
  });

  if (sentEmail) {
    return (
      <Box>
        <Result
          headingText={t('reset-password-request.email-sent')}
          text={t('reset-password-request.send-link-to')}
          buttonText={t('reset-password-request.open-email')}
          onClick={() => {
            window.open('mailto:');
          }}
        />
      </Box>
    );
  }

  return (
    <Box>
      <Heading fontSize="2xl" mb="2">
        {t('actions.forgot-password')}
      </Heading>
      <Text fontSize="lg" color="candy-floss-text" mb="5">
        {t('signup-form.description')}
      </Text>
      <FormControl as="form" onSubmit={formik.handleSubmit as FormSubmit}>
        <Flex direction="column" gap="3" mb="5">
          <CustomInput
            label={t('form.label.email')}
            placeholder={t('form.label.email')}
            type="email"
            name="email"
            inputMode="email"
            autoComplete="email"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            isInvalid={!!formik.errors.email && formik.touched.email}
            errorMessage={formik.errors.email}
          />
          <FormGlobalError fontSize="md" error={formik.errors.global} />
        </Flex>
        <Flex align="center" flexDir={{ base: 'column', md: 'row' }} gap="5">
          <Button
            w={{ base: 'full', md: 'auto' }}
            type="submit"
            colorScheme="purple"
            isLoading={formik.isSubmitting}
            rounded="full"
          >
            {t('form.button.reset-password')}
          </Button>
        </Flex>
      </FormControl>
    </Box>
  );
};
