import { Flex } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { SearchInput } from 'uikit/search-input';

type Props = {
  search: string;
  setSearch: (string: string) => void;
};

export const NftSearch: React.FC<Props> = ({ search, setSearch, ...props }) => {
  const { t } = useTranslation();

  return (
    <Flex w="full" align="center" gap="2" pos="relative" zIndex="2" {...props}>
      <SearchInput
        value={search}
        onChange={setSearch}
        placeholder={t('nft.search-placeholder')}
      />
    </Flex>
  );
};
