import { createAction } from 'store';
import * as api from './api';

export const loadRate = createAction(
  'currencies/loadRate',
  () => () => api.loadRate(),
);

export const loadDepositAddress = createAction(
  'currencies/loadDepositAddress',
  currencyKey => api.loadDepositAddress(currencyKey),
  currencyKey => ({ currencyKey, _loading: true }),
);

export const loadTransactionLink = createAction(
  'currencies/loadTransactionLink',
  params => api.loadTransactionLink(params),
);

export const withdraw = createAction(
  'currencies/withdraw',
  ({
    amount,
    token,
    walletId,
    address,
    loginCode,
    loginCodeId,
    twoFactorCode,
    assetId,
    ...rest
  }) =>
    () =>
      api.withdraw({
        amount,
        cryptoAmount: '0',
        token,
        fee: 0,
        walletId,
        address,
        loginCode,
        loginCodeId,
        twoFactorCode,
        assetId,
        ...rest,
      }),
);

export const checkBuyStatus = createAction(
  'currencies/checkBuyStatus',
  () => () => api.checkBuyStatus(),
);

export const createTransaction = createAction(
  'currencies/createTransaction',
  ({ amount, address }) =>
    () =>
      api.createTransaction({ amount, address }),
);

export const createTransactionArkpay = createAction(
  'currencies/createTransactionArkpay',
  ({ amount }) =>
    () =>
      api.createTransactionArkpay({ amount }),
);

export const handleArkpayPayTransaction = createAction(
  'currencies/handleArkpayPayTransaction',
  params => () => api.handleArkpayPayTransaction(params),
);

export const getLatestBillingAddress = createAction(
  'currencies/getBillingAddress',
  () => () => api.getLatestBillingAddress(),
);
