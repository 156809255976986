import { getFunctioningUrl } from 'helpers/env';
import { ws, wsconnect } from 'helpers/ws';
import { createAction } from 'store';
import { v4 as uuid } from 'uuid';
import * as api from './api';
import { MAX_MESSAGES_COUNT } from './constants';

export const chatWebsocket = 'chat/websocket';

export const websocketConnect = () =>
  wsconnect(
    chatWebsocket,
    getFunctioningUrl(process.env.REACT_APP_API_WS_CHAT_URL || '', 'wss:'),
  );

export const changeRoute = createAction(
  'chat/changeRoute',
  route => () => api.changeRoute(route),
);

export const loadChannelMessages = createAction(
  'chat/loadChannelMessages',
  ({ channel, before = null, after = null, limit = MAX_MESSAGES_COUNT }) =>
    api.loadChannelMessages(channel, { before, after, limit }),
  ({ channel }) => ({ channel }),
);

export const sendMessage = ({
  channel,
  message,
  id = uuid(),
  pin,
  replyToUser,
  replyToMessage,
}: {
  channel?: number;
  message?: string;
  id?: string;
  pin?: boolean;
  replyToUser?: string;
  replyToMessage?: string;
} = {}) =>
  ws(
    chatWebsocket,
    'chat',
    {
      channel,
      message,
      pin,
      ...(replyToUser ? { replyToUser } : {}),
      ...(replyToMessage ? { replyToMessage } : {}),
    },
    id,
  );

export const deleteMessages = createAction(
  'chat/deleteMessages',
  ({ userName, channelId, startChatMessageId, endChatMessageId = undefined }) =>
    () =>
      api.deleteMessages({
        userName,
        startChatMessageId,
        endChatMessageId,
        channelId,
      }),
  ({ channelId, startChatMessageId }) => ({
    channelId,
    startChatMessageId,
  }),
);

export const mute = createAction(
  'chat/mute',
  (userName, chatMessageId) => () =>
    api.mute({
      userName,
      chatMessageId,
    }),
);

export const removeMute = createAction(
  'chat/removeMute',
  userName => () => api.removeMute(userName),
);

export const timeout = createAction(
  'chat/timeout',
  (userName, chatMessageId, timeoutSeconds) => () =>
    api.timeout({
      userName,
      chatMessageId,
      timeoutSeconds,
    }),
);

export const removeTimeout = createAction(
  'chat/removeTimeout',
  userName => () => api.removeTimeout(userName),
);

export const loadFriends = createAction(
  'chat/loadFriends',
  () => () => api.loadFriends(),
);

export const friendInvite = createAction('chat/friendInvite', userName =>
  api.friendInvite(userName),
);

export const acceptFriendInvite = createAction(
  'chat/acceptFriendInvite',
  userName => () => api.acceptFriendInvite(userName),
);

export const removeFriend = createAction(
  'chat/removeFriend',
  userName => () => api.removeFriend(userName),
  userName => ({ userName }),
);

export const addBlock = createAction(
  'chat/addBlock',
  userName => () => api.addBlock(userName),
  userName => ({ userName }),
);

export const removeBlock = createAction(
  'chat/removeBlock',
  userName => () => api.removeBlock(userName),
  userName => ({ userName }),
);

export const changeChannel = createAction('chat/changeChannel', channel => ({
  channel,
}));

export const createPublicGroup = createAction(
  'chat/createPublicGroup',
  ({ name, description }) =>
    () =>
      api.createPublicGroup({ name, description }),
);

export const clearNotification = createAction('chat/clearNotification');

export const changeTimestampVisible = createAction(
  'chat/changeTimestampVisible',
);

export const loadChatNotification = createAction(
  'chat/loadChatNotification',
  lang => () => api.loadChatNotification(lang),
);
