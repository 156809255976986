import { Flex } from '@chakra-ui/react';
import { MIN_WAGER_CHAT } from 'constants/general';
import { logAction } from 'helpers/newrelic';
import uniq from 'lodash.uniq';
import { MODAL_TYPE, useModal } from 'modals';
import {
  ChatReplyIdType,
  UserChatMentionInfoType,
  clearNotification,
  getChannelId,
  getIsValidChannel,
  getMessages,
  getMessagesIsLoaded,
  getUsers,
  loadChannelMessages,
} from 'modules/chat';
import {
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  getUserAvatar,
  getUserId,
  getUserIsAuthorized,
  getUserLevelInfo,
} from 'services/user/modules/user';
import { useActions } from 'store';
import { ChatSection } from './components/chat-section';
import { FakeMessages } from './components/fake-messages';
import { Message } from './components/message';

const SCROLL_THRESHOLD = 180;

type Props = {
  open?: boolean;
};

export const Chat: React.FC<Props> = ({ open }) => {
  const [scrollBarWidth, setScrollBarWidth] = useState(0);
  const [replyId, setReplyId] = useState<ChatReplyIdType>(null);
  const messages = useSelector(getMessages);
  const users = useSelector(getUsers);
  const channelId = useSelector(getChannelId);
  const isValidChannel = useSelector(getIsValidChannel);
  const isLoaded = useSelector(getMessagesIsLoaded);
  const myUserId = useSelector(getUserId);
  const isAuthorized = useSelector(getUserIsAuthorized);
  const levelInfo = useSelector(getUserLevelInfo);
  const avatar = useSelector(getUserAvatar);
  const navigate = useNavigate();
  const actions = useActions({
    loadChannelMessages,
    clearNotification,
  });
  const scrollRef = useRef<HTMLDivElement>(null);
  const scrollInfo = useRef<boolean | undefined>();

  const { onOpen: openProfileModal } = useModal(MODAL_TYPE.PROFILE_MODAL);

  const getIsScrolledToBottom = () => {
    if (!scrollRef.current) return false;
    const scrollMaxY =
      scrollRef.current.scrollHeight - scrollRef.current.offsetHeight;

    return scrollRef.current.scrollTop + SCROLL_THRESHOLD >= scrollMaxY;
  };

  const scrollToDown = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  const handleOnSend = useCallback(() => {
    scrollInfo.current = true;
  }, []);

  // FIXME
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChatClick: any = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLLinkElement;
      if (target.classList.contains('mention')) {
        const { username } = target.dataset;
        openProfileModal({ username });
      }
      if (target.classList.contains('chat-link-internal')) {
        e.preventDefault();
        try {
          const url = new URL(target.href);
          navigate(url.pathname);
        } catch (error) {
          // console.log('invalid url', error);
          logAction('INVALID_URL_ERROR', {
            url: target.href,
          });
        }
      }
    },
    [navigate, openProfileModal],
  );

  useEffect(() => {
    if (isValidChannel && !isLoaded && open) {
      actions.loadChannelMessages({ channel: channelId });
    }
  }, [isValidChannel, isLoaded, channelId, open, actions]);

  const lastMessageId = messages[messages.length - 1]?.id;

  useLayoutEffect(() => {
    if (scrollRef.current) {
      const newScrollBarWidth =
        scrollRef.current.offsetWidth - scrollRef.current.clientWidth;
      if (scrollBarWidth !== newScrollBarWidth) {
        setScrollBarWidth(newScrollBarWidth);
      }
    }
  }, [lastMessageId, scrollBarWidth]);

  useLayoutEffect(() => {
    if (!isLoaded) {
      scrollInfo.current = false;
    } else {
      scrollToDown();
      const timeout = setTimeout(() => {
        scrollToDown();
      }, 500);
      return () => clearTimeout(timeout);
    }
    return undefined;
  }, [isLoaded]);

  useLayoutEffect(() => {
    if (isLoaded) {
      if (!getIsScrolledToBottom()) {
        if (scrollInfo.current) {
          scrollInfo.current = false;
          scrollToDown();
        }
      } else {
        scrollToDown();
      }
    }
  }, [lastMessageId, isLoaded]);

  useLayoutEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.addEventListener('click', handleChatClick);

      return () => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        scrollRef.current?.removeEventListener('click', handleChatClick);
      };
    }

    return undefined;
  }, [handleChatClick]);

  useLayoutEffect(() => {
    if (scrollRef.current) {
      const resizeObserver = new ResizeObserver(() => {
        if (getIsScrolledToBottom()) {
          scrollToDown();
        }
      });
      resizeObserver?.observe(scrollRef.current);
      return () => resizeObserver?.disconnect();
    }
    return undefined;
  }, []);

  const uniqUsers = useMemo(
    () =>
      uniq(
        messages.map(m => m.userId).filter(id => id && id !== myUserId),
      ).reduce(
        (acc: UserChatMentionInfoType[], id) =>
          acc.concat([
            {
              id,
              display: users[id].name,
              icon: users[id].icon,
              level: users[id].level,
            },
          ]),
        [],
      ),
    [messages, users, myUserId],
  );

  const isLowWager =
    !!levelInfo &&
    isAuthorized &&
    Number(levelInfo.exp) / 100 < MIN_WAGER_CHAT &&
    !avatar;
  const disabled = !isAuthorized || isLowWager;

  return (
    <>
      {disabled ? (
        <FakeMessages />
      ) : (
        <Flex
          flexDirection="column"
          py="3"
          width="full"
          h="full"
          overflow="auto"
          ref={scrollRef}
        >
          {messages.map(
            (
              {
                id,
                message,
                timestamp,
                userId,
                replyToUser,
                replyToMessage,
                isChatRainAnnouncement,
                type,
              },
              index,
            ) => {
              const user = users[userId] ?? {};
              return (
                <Message
                  key={id}
                  id={id}
                  index={index}
                  channelId={channelId}
                  message={message}
                  userId={userId}
                  timestamp={timestamp}
                  username={user.name}
                  avatar={user.icon}
                  badges={user.badges}
                  nftId={user.nftId}
                  chatColor={user.chatColor}
                  level={user.level}
                  setReplyId={setReplyId}
                  replyToUser={replyToUser}
                  replyToMessage={replyToMessage}
                  disabled={disabled}
                  type={type}
                  isChatRainAnnouncement={isChatRainAnnouncement}
                />
              );
            },
          )}
        </Flex>
      )}
      <ChatSection
        channelId={channelId}
        onSend={handleOnSend}
        uniqUsers={uniqUsers}
        replyId={replyId}
        setReplyId={setReplyId}
        disabled={disabled}
        isLowWager={isLowWager}
      />
    </>
  );
};
