import {
  BoxProps,
  Table as ChakraTable,
  FlexProps,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useBreakpointValue,
} from '@chakra-ui/react';
import cn from 'classnames';
import { Skeleton } from 'uikit/skeleton';
import styles from './style.module.scss';

type TableFieldProps = BoxProps;

const TableField: React.FC<TableFieldProps> = ({
  children,
  display,
  as,
  ...props
}) => {
  const computedDisplay = useBreakpointValue(
    typeof display === 'string' ? { base: display } : display ?? {},
    { ssr: false },
  );

  if (computedDisplay === 'none') return null;

  const Component = as ?? Td;

  return (
    <Component display={display} {...props}>
      {children}
    </Component>
  );
};

const MotionTr = (props: BoxProps) => (
  <Tr {...props} className={styles.appearTr} />
);

type SlideTrProps = {
  initial?: number;
  animate?: string;
};

const SlideTr: React.FC<SlideTrProps> = ({ initial, animate, ...rest }) => (
  <Tr {...rest} className={styles.slideTr} />
);

type Field = FlexProps & {
  title: string;
  key: string;
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Data = any & {
  key: string;
  bg?: string;
};

type Props = {
  fields: Field[];
  data: Data[];
  isLoading?: boolean;
  animate?: string;
  animateStarted?: boolean;
  loadingSkeletonRows?: number;
};

export const Table: React.FC<Props> = ({
  fields = [],
  data = [],
  isLoading,
  animate,
  animateStarted,
  loadingSkeletonRows = 10,
}) => {
  const TrComponent =
    // eslint-disable-next-line no-nested-ternary
    animate === 'slide' ? (animateStarted ? SlideTr : Tr) : MotionTr;

  return (
    <TableContainer
      w="full"
      zIndex="0"
      rounded="xl"
      pos="relative"
      className={cn(styles.general, {
        [styles.emptyTable]: data.length === 0,
      })}
    >
      <ChakraTable variant="unstyled">
        <Thead color="candy-floss-text" pos="relative" zIndex="2">
          <Tr>
            {fields.map(({ title, key, ...rest }) => (
              <TableField
                as={Th}
                fontSize="sm"
                fontWeight="500"
                key={key}
                {...rest}
              >
                {title}
              </TableField>
            ))}
          </Tr>
        </Thead>
        <Tbody>
          {isLoading
            ? Array(loadingSkeletonRows)
                .fill(null)
                .map((_, index) => (
                  <Tr key={index}>
                    {fields.map(({ display }, i) => (
                      <TableField key={i} display={display}>
                        <Skeleton h="21px" rounded="full" />
                      </TableField>
                    ))}
                  </Tr>
                ))
            : data.map((row, index) => (
                <TrComponent
                  key={row.key || index}
                  bg={row.bg}
                  className={cn({ [styles.customBg]: row.bg })}
                >
                  {fields.map(({ key, title, ...rest }) => {
                    const item = row[key];
                    return (
                      <TableField color="candy-floss-text" key={key} {...rest}>
                        {item}
                      </TableField>
                    );
                  })}
                </TrComponent>
              ))}
        </Tbody>
      </ChakraTable>
    </TableContainer>
  );
};
