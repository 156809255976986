import { Box } from '@chakra-ui/react';
import { Content } from 'components/content';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { BonusView } from './components/bonus-view';
import { RedeemView } from './components/redeem-view';

export const Bonuses = ({
  onClose,
  contentProps: { code, tab = 'bonuses' },
}) => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();

  const handleChangeTab = index => {
    setSearchParams({
      ...Object.fromEntries(searchParams),
      tab: index === 1 ? 'redeem' : 'bonuses',
    });
  };

  return (
    <Box>
      {tab !== 'redeem' ? (
        <Content w="full" maxW="6xl" mx="auto" flexGrow="1" gap="5" p="5">
          <BonusView onClose={onClose} />
        </Content>
      ) : (
        <Content w="full" maxW="6xl" mx="auto" flexGrow="1" gap="5" p="5">
          <RedeemView
            onClose={onClose}
            onChangeTab={handleChangeTab}
            defaultCode={code}
          />
        </Content>
      )}
    </Box>
  );
};
