import { createIcon } from '@chakra-ui/icons';

export const EyeCloseIcon = createIcon({
  displayName: 'EyeCloseIcon',
  viewBox: '0 0 16 16',
  path: (
    <>
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M7 3.1a1 1 0 0 1 1-1c2.124 0 3.847.94 5.108 1.993 1.26 1.05 2.12 2.263 2.55 2.944l.018.029c.084.13.217.34.284.63.053.235.053.528 0 .764a1.942 1.942 0 0 1-.302.659c-.17.268-.402.614-.697.997a1 1 0 1 1-1.586-1.219c.242-.314.434-.598.575-.82-.375-.59-1.1-1.595-2.123-2.449C10.79 4.763 9.506 4.1 8 4.1a1 1 0 0 1-1-1Zm-1.86.626a1 1 0 0 1-.278 1.386C3.478 6.034 2.51 7.354 2.05 8.078c.375.59 1.1 1.595 2.123 2.45 1.037.865 2.321 1.527 3.827 1.527.765 0 1.269-.042 1.687-.15.398-.102.765-.277 1.223-.612a1 1 0 1 1 1.18 1.615c-.62.454-1.215.756-1.903.934-.667.172-1.373.213-2.187.213-2.124 0-3.846-.939-5.108-1.992C1.632 11.012.772 9.8.342 9.12l-.018-.03a1.93 1.93 0 0 1-.284-.63 1.822 1.822 0 0 1 0-.764c.066-.29.2-.5.283-.63l.018-.03c.516-.817 1.671-2.43 3.411-3.588a1 1 0 0 1 1.387.278Z"
        clipRule="evenodd"
      />
      <path
        fill="currentColor"
        fillRule="evenodd"
        d="M.893.893a1 1 0 0 1 1.414 0l4.891 4.89 7.909 7.91a1 1 0 1 1-1.414 1.414l-4.3-4.3a3.133 3.133 0 0 1-4.2-4.2l-4.3-4.3a1 1 0 0 1 0-1.414ZM7.67 9.084a1.136 1.136 0 0 1-.755-.754l.755.754Z"
        clipRule="evenodd"
      />
    </>
  ),
});
