import { client, parseApiError } from 'helpers/client';

export const sendTip = ({
  username,
  amount,
  password,
  token,
  loginCode,
  loginCodeId,
  twoFactorCode,
}: {
  username: string;
  amount: number;
  password: string;
  token?: string;
  loginCode?: string;
  loginCodeId?: string;
  twoFactorCode?: string;
}) =>
  client
    .post(`/handler/tip/9`, {
      username,
      inputAmount: amount,
      inputTotal: amount,
      inputItems: [],
      password,
      token,
      loginCode,
      loginCodeId,
      twoFactorCode,
    })
    .then(({ data }) => data.payload)
    .catch(parseApiError);

export const sendRainTip = ({
  amount,
  numberOfUsers,
  password,
  loginCode,
  loginCodeId,
  twoFactorCode,
  channelId,
}: {
  amount: number;
  numberOfUsers: number;
  password: string;
  loginCode?: string;
  loginCodeId?: string;
  twoFactorCode?: string;
  channelId: string;
}) =>
  client
    .post(`/handler/tip/9/chatrain`, {
      inputAmount: amount,
      inputTotal: amount,
      numberOfUsers,
      channelId,
      inputItems: [],
      password,
      loginCode,
      loginCodeId,
      twoFactorCode,
    })
    .then(({ data }) => data.payload)
    .catch(parseApiError);
