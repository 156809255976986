import { createIcon } from '@chakra-ui/icons';

export const GiftCardIcon = createIcon({
  displayName: 'GiftCardIcon',
  viewBox: '0 0 16 16',
  path: (
    <path
      fill="currentColor"
      fillRule="evenodd"
      d="M2 .696a2 2 0 0 0-2 2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2v-6a2 2 0 0 0-2-2H2Zm6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm-4.5-3a.75.75 0 1 1-1.5 0 .75.75 0 0 1 1.5 0Zm9.75.75a.75.75 0 1 0 0-1.5.75.75 0 0 0 0 1.5Zm-3.75-.75a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM.187 12.383a.64.64 0 0 1 .453-.187h14.72a.64.64 0 0 1 .64.64v.857c0 1.077-1.033 1.83-2.048 1.554A50.61 50.61 0 0 0 .64 13.476a.64.64 0 0 1-.453-1.093Z"
      clipRule="evenodd"
    />
  ),
});
