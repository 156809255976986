import { Image, Text } from '@chakra-ui/react';
import { generateUrl } from 'helpers/image';
import { useMobile } from 'hooks/use-mobile';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { GameSlider } from 'services/games/components/game-slider';
import {
  SlotsClientType,
  getSlotsRecentGamesList,
  getSlotsRecommendationsSimilarList,
  getSlotsRecommendationsSimilarLoaded,
  loadRecommendationsSimilar,
} from 'services/games/modules/slots';
import { useActions } from 'store';
import { Link } from 'uikit/link';

export const MegawaysSlider = () => {
  const games = useSelector(getSlotsRecentGamesList);
  const list = useSelector(getSlotsRecommendationsSimilarList);
  const isMobile = useMobile();
  const actions = useActions({ loadRecommendationsSimilar });
  const isLoaded = useSelector(getSlotsRecommendationsSimilarLoaded);

  const gamesList = games.filter(g => g.provider !== 'sherbet');

  const randomIndex = Math.floor(Math.random() * gamesList.length);
  const game = gamesList[randomIndex];

  useEffect(() => {
    if (game && !isLoaded) {
      actions.loadRecommendationsSimilar(
        game.id,
        isMobile ? SlotsClientType.mobile : SlotsClientType.desktop,
      );
    }
  }, [game, isMobile, isLoaded, actions]);

  if (!list.length) {
    return null;
  }

  return (
    <GameSlider
      icon={
        game && (
          <Image
            h="14px"
            w="14px"
            objectFit="cover"
            rounded="base"
            src={generateUrl(game.image, { w: 32 })}
            alt="Icon"
          />
        )
      }
      title={
        <>
          <Text fontSize="xl" as="span" color="candy-floss-text">
            More Like:
          </Text>{' '}
          <Link _hover={{ opacity: 0.9 }} to={`/casino/game/${game?.id}`}>
            {game?.title}
          </Link>
        </>
      }
      isLoaded={isLoaded}
      slides={list}
    />
  );
};
